import React from 'react'
import { Link } from 'react-router-dom'

const Plans = () => {
  return (
    <> <div className="modal fade plan-modal" id="plans-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="plan-modal-header">
          <div className="plan-close">
            <button type="button" className="btn" data-bs-dismiss="modal">
              <i className="ti ti-x" />
            </button>
          </div>
          <h4>Subscription Plans</h4>	
          <h6>
            Explore our flexible subscription plans to suit your needs and access exclusive content and features.
          </h6>
          <ul className="nav">
            <li>One-Time Payments</li>
            <li>
              <div className="active-switch">
                <label className="switch">
                  <input type="checkbox" defaultChecked />
                  <span className="sliders round" />
                </label>
              </div>
            </li>
            <li>Subsription</li>
          </ul>
        </div>
        <div className="modal-body">
          <div className="modal-plan-list">
            <ul className="nav">
              <li>
                <div className="plan-box">
                  <h4>1 month</h4>
                  <h2>$26.97 <span>10% Off</span></h2>
                  <p>1 month</p>
                  <Link to="#" className="btn btn-primary rounded">Select a plan</Link>
                </div>
              </li>
              <li>
                <div className="plan-box">
                  <h4>6 months</h4>
                  <h2>$53.94  <span>15% Off</span></h2>
                  <p>6 months</p>
                  <Link to="#" className="btn btn-primary rounded">Select a plan</Link>
                </div>
              </li>
              <li>
                <div className="plan-box">
                  <h4>1 year</h4>
                  <h2>$95.90  <span>20% Off</span></h2>
                  <p>1 year</p>
                  <Link to="#" className="btn btn-primary rounded">Select a plan</Link>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div></>
  )
}

export default Plans