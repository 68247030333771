import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { stethoscopeImg } from '../../../../../../utils/imagepath';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { end_points } from '../../../../../../services/end_point/end_points';
import { useDispatch, useSelector } from 'react-redux';
import { setVitalsUpdated } from '../../../../../../core/redux/commonSlice';
import {
  capitalizeWords,
  roundToFixed,
} from '../../../../../../utils/constant';
import AudioPlayer from './audioPlayer';
import { SocketContext } from '../../../../../../services/socket/socketioClient';
import ContinuousAudioPlayer from './continuousAudioPlayer';
import PacsModelPopup from './pacsModelPopup';
import { toast } from 'react-toastify';
interface vitalsCallProps {
  vitalBool: boolean;
  setVitalsBool: (bool: boolean) => void;
}
interface normalVitalsProps {
  spirometerValue: any;
  keys: any[];
  type?: string;
}
export const NormalVitals: React.FC<normalVitalsProps> = ({
  spirometerValue = {},
  keys = [],
  type = '',
}) => {
  return (
    <>
      {type === 'prescription' ? '' : <h6>Spirometer</h6>}
      <div className="vitals-table mb-3">
        <div className="table-responsive">
          <table className="table">
            <thead>
              <tr>
                <th>Parameter</th>
                <th>Predicted</th>
                <th>LLN</th>
                <th>Best</th>
                <th>Pre %</th>
                <th>z-score</th>
              </tr>
            </thead>
            <tbody>
              {keys?.includes('FEV1_Lt') ? (
                <tr>
                  <td>FEV1 (Lt)</td>
                  <td>{spirometerValue?.FEV1_Lt?.FEV1_P || ''}</td>
                  <td>
                    {roundToFixed(spirometerValue?.FEV1_Lt?.FEV1LLN) || ''}
                  </td>
                  <td>{roundToFixed(spirometerValue?.FEV1_Lt?.FEV1) || ''}</td>
                  <td>{spirometerValue?.FEV1_Lt?.FEV1_P_PER || ''}</td>
                  <td>{spirometerValue?.FEV1_Lt?.FEV1_Z || ''}</td>
                </tr>
              ) : (
                <></>
              )}
              {keys?.includes('FVC_Lt') ? (
                <tr>
                  <td>FVC (Lt)</td>
                  <td>{spirometerValue?.FVC_Lt?.FVC_P || ''}</td>
                  <td>{roundToFixed(spirometerValue?.FVC_Lt?.FVCLLN) || ''}</td>
                  <td>{roundToFixed(spirometerValue?.FVC_Lt?.FVC) || ''}</td>
                  <td>{spirometerValue?.FVC_Lt?.FVC_P_PER || ''}</td>
                  <td>{spirometerValue?.FVC_Lt?.FVC_Z || ''}</td>
                </tr>
              ) : (
                <></>
              )}
              {keys?.includes('FEV1_FVC_ratio') ? (
                <tr>
                  <td>FEV1/FVC ratio (%)</td>
                  <td>{spirometerValue?.FEV1_FVC_ratio?.ratio_P || ''}</td>
                  <td>
                    {roundToFixed(
                      spirometerValue?.FEV1_FVC_ratio?.FVC_FEV1LLN,
                    ) || ''}
                  </td>
                  <td>
                    {roundToFixed(spirometerValue?.FEV1_FVC_ratio?.ratio) || ''}
                  </td>
                  <td>{spirometerValue?.FEV1_FVC_ratio?.ratio_P_PER || ''}</td>
                  <td>{spirometerValue?.FEV1_FVC_ratio?.ratio_Z || ''}</td>
                </tr>
              ) : (
                <></>
              )}
              {keys?.includes('PEF_Lt_sec') ? (
                <tr>
                  <td>PEF (Lt/min)</td>
                  <td></td>
                  <td></td>
                  <td>
                    {roundToFixed(spirometerValue?.PEF_Lt_sec?.PEF) || ''}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                <></>
              )}
              {keys?.includes('MEF75_25') ? (
                <tr>
                  <td>MEF75-25 (FEF25-75) (Lt/sec)</td>
                  <td>{spirometerValue?.MEF75_25?.FEF2575_P || ''}</td>
                  <td>
                    {roundToFixed(spirometerValue?.MEF75_25?.FEF2575LLN) || ''}
                  </td>
                  <td>
                    {roundToFixed(spirometerValue?.MEF75_25?.FEF2575) || ''}
                  </td>
                  <td>{spirometerValue?.MEF75_25?.FEF2575_P_PER || ''}</td>
                  <td>{spirometerValue?.MEF75_25?.FEF2575_Z || ''}</td>
                </tr>
              ) : (
                <></>
              )}
              {keys?.includes('Duration_Exhale') ? (
                <tr>
                  <td>Duration Exhale (sec)</td>
                  <td></td>
                  <td></td>
                  <td>
                    {spirometerValue?.Duration_Exhale?.duration_exhl || ''}
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                <></>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};
const VitalsCall: React.FC<vitalsCallProps> = ({
  vitalBool,
  setVitalsBool,
}) => {
  const doctor_id: any = useSelector(
    (state: any) => state?.login?.userDetails?.id,
  );
  const [imageView, setImageView] = useState<any>('');

  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const [loading, setLoading] = useState<any>({
    auto_scope: false,
    derma_scope: false,
  });
  const [showAddNote, setShowAddNote] = useState<any>({
    auto_scope: false,
    derma_scope: false,
  });
  const [newSymptom, setNewSymptom] = useState<any>({
    auto_scope: '',
    derma_scope: '',
  });

  const { getData, postData } = useContext(ApiServiceContext);
  const [vitalData, setVitalData] = useState<any>([]);
  const { handleSocketEvents, socket } = useContext(SocketContext);
  const appointmentId: any =
    // '66c49db43704b0a382e22a27';
    useSelector((state: any) => state.common.editData?._id);
  const patient_id: any =
    // '66c49db43704b0a382e22a27';
    useSelector((state: any) => state.common.editData?.patient_id);

  const vitalsDataFromDevice = useSelector(
    (state: any) => state.common?.vitalsData,
  );

  const dispatch = useDispatch();
  const [newAudioArray, setNewAudioArray] = useState<any>('');
  const getUrl = (url: any, appointmentId: any) => {
    if (appointmentId) {
      url += `/${appointmentId}`;
    }
    return url;
  };

  // -----------------------------------------

  const getVitalsData = async () => {
    try {
      // showLoader();
      const vitalResponse = await getData(
        getUrl(end_points.getVitalsListbyAppIdApi.url, appointmentId),
      );
      if (vitalResponse.status === 200) {
        // dispatch;
        let data = vitalResponse?.data?.data || {};
        const {
          deletedAt,
          _id,
          createdAt,
          updatedAt,
          others,
          stethoscope_details,
          pacs,

          ...value
        } = data;
        const rest = value;
        // console.log(rest, 'rest', stethoscope_details, others);

        const structureData =
          rest && Object.keys(rest).length > 0
            ? Object.keys(rest)?.map((val: any) => {
                let spirometerValue = '';
                if (val === 'spirometer') {
                  const spirometerRaw = data?.spirometer || '';

                  const spirometerParsed =
                    spirometerRaw && JSON.parse(spirometerRaw);

                  spirometerValue =
                    spirometerParsed?.value &&
                    JSON.parse(spirometerParsed.value);
                }
                return {
                  label: capitalizeWords((val || '')?.replace('_', ' ')),
                  type: val,
                  data: val === 'spirometer' ? spirometerValue : rest[val],
                  pacs: false,
                };
              })
            : [];

        let includeOthers: any = structureData;

        if (others && others?.average_heart_rate) {
          const stethoscope_Details: any = {
            label: 'stethoscope',
            type: 'stethoscope',
            data: {
              average_heart_rate: others.average_heart_rate || '',
              rr_max: others?.rr_max || '',
              rr_min: others?.rr_min || '',
              br: others?.br || '',
              hrv: others?.hrv || '',
            },
            pacs: false,
          };

          includeOthers = [...includeOthers, stethoscope_Details];
        }
        if (others?.systolic_blood_pressure) {
          const blood_pressure_Details: any = {
            label: 'blood_pressure',
            type: 'blood_pressure',
            data: {
              systolic_blood_pressure: others?.systolic_blood_pressure || '',
              diastolic_pressure: others?.diastolic_pressure || '',
              // data_points: others?.data_points ||
              // measuring_time: others?.measuring_time || '',
            },
            pacs: false,
          };
          const filteredArray = (includeOthers || [])?.filter(
            (val: any) => val?.label !== 'Blood Pressure',
          );
          includeOthers = [...filteredArray, blood_pressure_Details];
        }
        if (others?.systolic_blood_pressure) {
          const blood_oxygen_Details: any = {
            label: 'blood_oxygen',
            type: 'blood_oxygen',
            data: {
              blood_oxygen: others?.blood_oxygen || '',
              heart_rate: others?.heart_rate || '',
            },
            pacs: false,
          };
          const filteredArray = (includeOthers || [])?.filter(
            (val: any) => val?.label !== 'Blood Oxygen',
          );
          includeOthers = [...filteredArray, blood_oxygen_Details];
        }
        if (others?.sugar_value) {
          const blood_glucose_Details: any = {
            label: 'blood_glucose',
            type: 'blood_glucose',
            data: {
              sugar_value: others?.sugar_value || '',
              glucose_sum: others?.glucose_sum || '',
              bg_count: others?.bg_count || '',
            },
            pacs: false,
          };
          const filteredArray = (includeOthers || [])?.filter(
            (val: any) => val?.label !== 'Blood Glucose',
          );
          includeOthers = [...filteredArray, blood_glucose_Details];
        }
        const structurePacsData =
          pacs && Object.keys(pacs).length > 0
            ? Object.keys(pacs)?.map((val: any) => {
                return {
                  label: capitalizeWords((val || '')?.replace('_', ' ')),
                  type: val,
                  data: pacs[val],
                  pacs: true,
                };
              })
            : [];
        const combinedArray = [...includeOthers, ...structurePacsData];
        if (
          vitalsDataFromDevice &&
          !Array.isArray(vitalsDataFromDevice) &&
          Object.keys(vitalsDataFromDevice)?.length > 0
        ) {
          const deviceKeys = Object.keys(vitalsDataFromDevice)?.map(
            (key) => vitalsDataFromDevice[key]?.slug,
          );
          const combinedSlugs = combinedArray?.map((v) => v?.type);

          const missingSlugs = deviceKeys.filter(
            (slug) => !combinedSlugs.includes(slug),
          );
        }

        dispatch(setVitalsUpdated(''));

        setVitalData(combinedArray);
      }
      // {{
      //   "slug": "stethoscope_audio",
      //   "label": "stethoscope",
      //   "value": {"value":"audio file"}
      // }}
    } catch (error) {
      console.log(error);
    } finally {
      setVitalsBool(false);
      // hideLoader();
    }
  };
  // {
  //   "label": "Blood Pressure",
  //   "type": "blood_pressure",
  //   "data": "value"
  // }
  function convertShortArrayToFloat32Array(shortArray = []) {
    const floatArray = new Float32Array(shortArray.length);
    for (let i = 0; i < shortArray.length; i++) {
      floatArray[i] = shortArray[i] / 32768; // Normalize to -1.0 to 1.0 range
    }
    return floatArray;
  }
  const callBack = (vitalsDataFromDevice = '') => {
    try {
      let vitals: any = vitalsDataFromDevice;
      const { label, slug, value } = vitals || {};

      if (slug === 'stethoscope_audio') {
        let validValue: any = value && JSON.parse(value);
        validValue = validValue?.value ? JSON.parse(validValue?.value) : [];

        // Append new audio data to the queue when newAudioArray updates
        const audioData: any =
          convertShortArrayToFloat32Array(validValue) || [];
        validValue && setNewAudioArray([...audioData]);
      } else {
        setNewAudioArray('');
      }
      setVitalData((prev: any) => {
        let initialData = prev || [];

        if (vitalsDataFromDevice) {
          const { label, slug, value } = vitals || {};
          let pacsSlug = [
            'stethoscope_audio',
            'stethoscope',
            'screenshot',
            'graph',
            'ecg_image',
            'stethoscope_audio_file',
            'auto_scope',
            'derma_scope',
          ];
          let validValue: any = value && JSON.parse(value);
          if (validValue && slug === 'stethoscope_audio') {
            validValue = validValue?.value ? JSON.parse(validValue?.value) : [];
          }

          const existingIndex = (initialData || [])?.findIndex(
            (item: any) => item?.type === slug,
          );
          const existingStethoscope_audioIndex: number = (
            initialData || []
          )?.findIndex((item: any) => item?.type === 'stethoscope_audio');
          if (existingIndex !== -1) {
            const updatedData = initialData?.map((item: any, index: any) => {
              return index === existingIndex
                ? item?.type === 'stethoscope_audio'
                  ? {
                      ...item,
                      data: [...validValue],
                    }
                  : slug === 'temperature' ||
                      slug === 'ecg_image' ||
                      slug === 'stethoscope_audio_file' ||
                      slug === 'auto_scope' ||
                      slug === 'derma_scope'
                    ? { ...item, data: validValue?.value }
                    : { ...item, data: validValue }
                : item;
            });
            if (slug !== 'stethoscope_audio') {
              dispatch(setVitalsUpdated(updatedData));
            }
            return updatedData;
          } else {
            let updatedData = [
              ...initialData,
              {
                label: label,
                type: slug,
                data:
                  slug === 'stethoscope'
                    ? validValue
                    : slug === 'stethoscope_audio' ||
                        slug === 'stethoscope_audio_file'
                      ? validValue?.value
                      : slug === 'temperature'
                        ? validValue?.value
                        : slug === 'ecg_image' ||
                            slug === 'auto_scope' ||
                            slug === 'derma_scope'
                          ? validValue?.value
                          : slug === 'blood_oxygen'
                            ? validValue
                            : slug === 'blood_glucose'
                              ? validValue
                              : slug === 'ecg'
                                ? validValue
                                : slug === 'blood_pressure'
                                  ? validValue
                                  : slug === 'weighing_scale'
                                    ? validValue
                                    : slug === 'spirometer'
                                      ? validValue
                                      : '',
                pacs:
                  slug === 'ecg'
                    ? false
                    : pacsSlug?.some((v) => v === slug)
                      ? true
                      : false,
              },
            ];
            if (
              slug === 'stethoscope_audio_file' &&
              existingStethoscope_audioIndex !== -1
            ) {
              updatedData?.splice(existingStethoscope_audioIndex, 1);
            }
            if (slug !== 'stethoscope_audio') {
              dispatch(setVitalsUpdated(updatedData));
            }
            return updatedData;
          }
        }
        if (slug !== 'stethoscope_audio') {
          dispatch(setVitalsUpdated(prev));
        }
        if (slug === 'stethoscope_audio_file') {
          setNewAudioArray('');
        }
        return prev;
      });
    } catch (error) {
      console.log(error, 'error');
    }
  };
  useEffect(() => {
    appointmentId && vitalBool && getVitalsData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId, vitalBool]);
  useEffect(() => {
    if (doctor_id) {
      handleSocketEvents('vitalsCall', `CC_vitals_${doctor_id}`, '', callBack);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [doctor_id]);
  const pacs: any =
    vitalData?.length > 0 ? vitalData?.filter((v: any) => v?.pacs) : [];

  function getLabel(type: string) {
    if (type === 'stethoscope_audio') return 'Stethoscope';
    if (type === 'ecg') return 'ECG';
    if (type === 'ecg_image') return 'ECG';

    return capitalizeWords(type?.replace('_', ' ') || '');
  }
  const handleAddNote = (type = '') => {
    setShowAddNote((pre: any) => {
      return { ...pre, [type]: true };
    });
  };

  const getScopeData = async () => {
    try {
      let url = end_points.getPatientAutoScopeApi.url;
      let dermaUrl = end_points.getPatientDermoScopeApi.url;
      if (patient_id) {
        url += `/${patient_id}/${appointmentId}`;
        dermaUrl += `/${patient_id}/${appointmentId}`;
      }
      const [response, responseDerma] = await Promise.all([
        getData(url),
        getData(dermaUrl),
      ]);

      if (response?.status === 200 || responseDerma?.status === 200) {
        let newSymptomsNotes = {
          auto_scope: Array.isArray(response?.data?.data?.autoscope)
            ? response?.data?.data?.autoscope?.[
                ((response?.data?.data?.autoscope || [])?.length || 1) - 1
              ]?.autoscope_add_note || ''
            : '',
          derma_scope: Array.isArray(responseDerma?.data?.data?.dermoscope)
            ? responseDerma?.data?.data?.dermoscope?.[
                ((responseDerma?.data?.data?.dermoscope || [])?.length || 1) - 1
              ]?.dermoscope_add_note || ''
            : '',
        };

        setShowAddNote({
          auto_scope: !!newSymptomsNotes?.auto_scope,
          derma_scope: !!newSymptomsNotes?.derma_scope,
        });
        setNewSymptom({ ...newSymptomsNotes });
      }
    } catch (error) {}
  };
  useEffect(() => {
    patient_id && getScopeData();
  }, [patient_id, vitalBool]);
  const newSymptomSubmit = async (type = '') => {
    try {
      setLoading((prev: any) => ({ ...prev, [type]: true }));
      let url =
        type === 'auto_scope'
          ? 'patient-details/autoscope/create'
          : type === 'derma_scope'
            ? 'patient-details/dermoscope/create'
            : '';
      if (patient_id) {
        url += `/${patient_id}/${appointmentId}`;
      }

      if (!url) return;
      let keyName: string =
        type === 'auto_scope' ? 'autoscope_add_note' : 'dermoscope_add_note';
      const response = await postData(url, {
        [keyName]: newSymptom[type],
      });
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 2000,
        });
      }
    } catch (error) {
    } finally {
      setLoading((prev: any) => ({ ...prev, [type]: false }));
    }
  };
  const vitalsNormal = (vitalData || [])?.filter((v: any) => !v?.pacs) || [];
  const getAddNoteType = (val: any = '') => {
    let types =
      val?.type === 'dermoscope'
        ? 'derma_scope'
        : val?.type === 'autoscope'
          ? 'auto_scope'
          : val?.type;
    return types;
  };
  return (
    <div
      className="tab-pane fade"
      id="vitals_tab"
      role="tabpanel"
      aria-labelledby="vitals-tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="live-stethoscope d-none">
            <Link
              to={'#'}
              onClick={() => {
                let array = new Array(80).fill(0);
                setNewAudioArray(array);
                setVitalData([
                  {
                    type: 'stethoscope_audio',
                    label: 'Stethoscope',
                    data: newAudioArray,
                    pacs: true,
                  },
                ]);
              }}
              className="btn btn-primary"
            >
              Live Stethoscope
            </Link>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>Vitals</h4>
            <div className="vitals-info">
              <div className="vitals-head">
                <div className="row">
                  <div className="col">
                    <h5>Device Name</h5>
                  </div>
                  <div className="col">
                    <h5>Value</h5>
                  </div>
                </div>
              </div>
              <div className="vitals-body vitals-body-info">
                {vitalsNormal?.length > 0 ? (
                  vitalsNormal.map((v: any, index: number) => {
                    if (v?.type === 'spirometer') {
                      let spirometerValue: any = v?.data;

                      let keys =
                        (spirometerValue &&
                          Object.keys(spirometerValue)?.map((key) => key)) ||
                        [];

                      return (
                        <NormalVitals
                          spirometerValue={spirometerValue}
                          keys={keys}
                        />
                      );
                    } else
                      return (
                        <div key={index}>
                          <div className="row">
                            <div className="col">
                              {v?.type === 'ecg' || v?.type === 'ecg_image'
                                ? 'ECG'
                                : getLabel(v?.type)}
                            </div>
                            <div className="col">
                              {typeof v?.data === 'string' ? v?.data : ''}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              {v?.data &&
                              typeof v?.data === 'object' &&
                              !Array.isArray(v?.data) &&
                              Object.keys(v?.data)?.length > 0 ? (
                                Object.keys(v?.data).map(
                                  (key: any, subIndex: number) => (
                                    <div className="row" key={subIndex}>
                                      <div className="col">
                                        <div className="device-list">
                                          <h6>
                                            {' '}
                                            {capitalizeWords(
                                              (key || '')?.replaceAll('_', ' '),
                                            )}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="col">
                                        <div className="device-list">
                                          <p> {v?.data?.[key]}</p>
                                        </div>
                                      </div>
                                    </div>
                                  ),
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <hr />
                        </div>
                      );
                  })
                ) : (
                  <div className="row">
                    <div className="col">No data sent</div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>PACS</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pacs && pacs?.length > 0 ? (
                      pacs?.map((val: any) => {
                        return (
                          <>
                            <tr>
                              <td>
                                <>
                                  <div className="pb-2">
                                    {val?.type === 'ecg' ||
                                    val?.type === 'ecg_image'
                                      ? 'ECG'
                                      : (val?.type === 'stethoscope_audio' &&
                                            newAudioArray) ||
                                          val?.type === 'stethoscope_audio_file'
                                        ? 'Stethoscope'
                                        : getLabel(val?.type)}
                                  </div>
                                  {(val?.type === 'auto_scope' ||
                                    val?.type === 'derma_scope' ||
                                    val?.type === 'dermoscope' ||
                                    val?.type === 'autoscope') && (
                                    <div className="add-symptoms mt-2">
                                      <Link
                                        to="#"
                                        className={`add-note ${showAddNote[getAddNoteType(val)] ? '' : 'add-note-link'}`}
                                        onClick={() => {
                                          handleAddNote(getAddNoteType(val));
                                        }}
                                      >
                                        Add Note
                                      </Link>
                                    </div>
                                  )}
                                </>
                              </td>
                              <td>
                                {(val?.type === 'stethoscope_audio' &&
                                  newAudioArray) ||
                                val?.type === 'stethoscope_audio_file' ||
                                val?.type === 'stethoscope' ? (
                                  val?.data && typeof val?.data === 'string' ? (
                                    <AudioPlayer
                                      url={
                                        val?.data
                                        // 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3'
                                      }
                                    />
                                  ) : (
                                    newAudioArray && (
                                      <ContinuousAudioPlayer
                                        newAudioArray={newAudioArray}
                                      />
                                    )
                                  )
                                ) : (
                                  <>
                                    {typeof val?.data === 'string' && (
                                      // <img src={val?.data} alt="screenshot" />
                                      // <a href={val?.data} target="blank">
                                      //   View
                                      // </a>
                                      <Link
                                        to="#"
                                        className="add-note-link"
                                        data-bs-target="#PacsModelPopupID"
                                        data-bs-toggle="modal"
                                        onClick={() => {
                                          setImageView(val?.data);
                                        }}
                                      >
                                        View
                                      </Link>
                                    )}
                                  </>
                                )}

                                {(val?.type === 'auto_scope' ||
                                  val?.type === 'derma_scope' ||
                                  val?.type === 'dermoscope' ||
                                  val?.type === 'autoscope') && (
                                  <div className="add-symptoms">
                                    {/* <Link
                                      to="#"
                                      className="add-note add-note-link"
                                      onClick={() => {
                                        handleAddNote(getAddNoteType(val));
                                      }}
                                    >
                                      Add Note
                                    </Link> */}
                                    {showAddNote[getAddNoteType(val)] && (
                                      <div className="add-symptoms-input">
                                        <input
                                          type="text"
                                          value={
                                            newSymptom[getAddNoteType(val)]
                                          }
                                          onChange={(e) =>
                                            setNewSymptom((pre: any) => {
                                              return {
                                                ...pre,
                                                [getAddNoteType(val)]:
                                                  e.target.value,
                                              };
                                            })
                                          }
                                          className="form-control"
                                          placeholder="Add note"
                                        />
                                        <Link
                                          to="#"
                                          className="add-note-link"
                                          onClick={() => {
                                            if (loading[getAddNoteType(val)]) {
                                            } else {
                                              newSymptomSubmit(
                                                getAddNoteType(val),
                                              );
                                            }
                                          }}
                                        >
                                          {loading[getAddNoteType(val)]
                                            ? '...loading'
                                            : 'Save'}
                                        </Link>
                                      </div>
                                    )}
                                  </div>
                                )}
                              </td>
                            </tr>
                          </>
                        );
                      })
                    ) : (
                      <tr>
                        <td>No data sent</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PacsModelPopup srcValue={imageView} />
    </div>
  );
};

export default VitalsCall;
