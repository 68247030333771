import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { defaultProfileTwoImage, doctor01 } from '../../../../utils/imagepath';
import { routes } from '../../../../utils/routes';
import { end_points } from '../../../../services/end_point/end_points';
import {
  setCallJoined,
  setDoctorOnlineStatus,
  setEditData,
  setPastData,
  setPrescriptionDrugTableData,
  setScheduleDoctorUpdate,
  setSymtomsFlowData,
  setVitalsUpdated,
} from '../../../../core/redux/commonSlice';
import { useDispatch, useSelector } from 'react-redux';
import { SpinnerContext } from '../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../utils/shared.module';
import PrimeDataTable from '../../../../components/dataTable';
import { consultType, userRole } from '../../../../assets/global';
import {
  capitalizeFirstLetter,
  formatAppointmentStart,
  patientScheduleSlotFormat,
} from '../../../../utils/constant';
import DoctorScheduledSlots from '../../admin/doctor/doctor-scheduled-slots';
import PrescriptionModel from './joinConsultation/components/prescriptionModel';
interface typePropDash {
  typeProp: string;
}
const EmergencyList: React.FC<typePropDash> = ({ typeProp = 'Upcoming' }) => {
  const [listData] = useState<any>([
    {
      id: 1,
      fullname: 'John Doe',
      Location: 'Hyderabad',
      Vitals: 'H/r: 128BPM Temp: 102C BP: 180/120',
      appointmentStart: '2022-08-15T10:30',
    },
    {
      id: 2,
      fullname: 'Billy',
      Location: 'Hyderabad',
      Vitals: 'H/r: 102BPM Temp: 101C BP: 160/110',
      appointmentStart: '2022-08-15T10:30',
    },
  ]);
  const [metricsData, setMetricsData] = useState<any>([]);
  const userDetails = useSelector((state: any) => state?.login?.userDetails);
  const doctorOnlineStatus = useSelector(
    (state: any) => state?.common?.doctorOnlineStatus,
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rows, setRows] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, putData } = useContext(ApiServiceContext);
  const [appoitmentId, setAppitmentId] = useState<any>('');
  const [isOffline, setIsOffline] = useState<boolean>(
    doctorOnlineStatus || metricsData?.status === 'online' ? true : false,
  );
  const [loading, setLoading] = useState(false);

  const sessionDetail = useSelector(
    (state: any) => state?.common?.sessionDetails,
  );
  const doctorId = useSelector((state: any) => state?.login?.userDetails?.id);
  const newSchedule = useSelector(
    (state: any) => state?.common?.newScheduleDoctor,
  );
  const savedData: any = useSelector((state: any) => state?.common?.flowData);

  const updateStatus = async () => {
    try {
      if (loading) {
        return;
      }
      setLoading(true);
      let url = end_points.updateDoctorStatus.url;
      if (url) {
        url += `/${doctorId}`;
      }
      const response = await putData(url);

      if (response.status === 200) {
        const status =
          response?.data?.data?.availability_status === 'offline'
            ? false
            : true;
        setIsOffline(status);
        dispatch(setDoctorOnlineStatus(status));
      }
      setLoading(false);
    } catch (error) {
      console.error('Error updating status:', error);
    } finally {
    }
  };

  const pastConsultsColumns = [
    {
      header: 'Patient Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Location',
      field: 'Location',
      key: 'Location',
      sortField: 'Location',
    },
    {
      header: 'Vitals',
      field: 'Vitals',
      key: 'Vitals',
      sortField: 'Vitals',
    },
    {
      header: 'Action',
      field: 'Action',
      key: 'Action',
      sortField: 'Action',
      body: (data: any) => {
        return (
          <div className="data-btn">
            <Link to={routes.emergencyDetail.path} className="btn btn-primary">
              View
            </Link>
          </div>
        );
      },
    },
  ];

  const getDoctorConsults = async (
    page: number = 1,
    limit: number = 10,
    loading: boolean = true,
  ) => {
    try {
      loading && showLoader();
      let url = '';
      if (!url) {
        return;
      }
      typeProp === consultType.upcoming && (url += `/:id`);
      if (page && limit) {
        url += `?page=${page}&limit=${limit}`;
      }
      // typeProp === consultType.past && (url += `&view=past`);
      typeProp === consultType.criticalPatient && (url += `&view=past`);

      const response = await getData(url);
      if (response.status === 200) {
        typeProp === consultType.upcoming
          ? setListData(response?.data?.data?.todaysRecords)
          : typeProp === consultType.criticalPatient
            ? setListData(response?.data?.data)
            : setListData(response?.data?.data?.patientsList);
        typeProp === consultType.upcoming
          ? setTotalRecords(response?.data?.data?.totalRecord)
          : typeProp === consultType.criticalPatient
            ? setTotalRecords(response?.data?.totalRecord)
            : setTotalRecords(response?.data?.data?.totalRecords);

        dispatch(setEditData(undefined));
        dispatch(setScheduleDoctorUpdate(''));
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading && hideLoader();
    }
  };

  const getMetrics = async (loading = true) => {
    try {
      loading && showLoader();
      const url = end_points.doctorDashboardMetrics.url;

      const response = await getData(url);
      if (response.status === 200) {
        setMetricsData(response?.data?.data);
        let doctorStatus: boolean =
          response?.data?.data.status === 'online' ? true : false;
        setIsOffline(doctorStatus);
        dispatch(setDoctorOnlineStatus(doctorStatus));
      }
    } catch (error) {
      console.error(error);
    } finally {
      loading && hideLoader();
    }
  };
  useEffect(() => {
    dispatch(setCallJoined(undefined));
    if (currentPage && typeProp !== consultType.scheduled) {
      getDoctorConsults(currentPage, rows);
      getMetrics();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, typeProp]);

  const getGreeting = () => {
    const currentHour = new Date().getHours();

    if (currentHour < 12) {
      return 'Good Morning';
    } else if (currentHour >= 12 && currentHour < 18) {
      return 'Good Afternoon';
    } else {
      return 'Good Evening';
    }
  };
  const refresh = () => {
    getDoctorConsults(currentPage, rows);
  };
  useEffect(() => {
    if (newSchedule?.appointment_id) {
      getDoctorConsults(currentPage, rows, false);
      getMetrics(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSchedule?.appointment_id]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Doctor Dashboard */}
          <div className="row">
            <div className="col-xl-3 col-lg-12 theiaStickySidebar">
              <div className="stickybar">
                <div className="card">
                  <div className="card-body">
                    <div className="dash-header doctor-dash-header">
                      <h4>{getGreeting()}</h4>
                    </div>
                    <div className="doctor-dashboard-img">
                      <img
                        src={
                          userDetails?.profile_image
                            ? userDetails?.profile_image
                            : defaultProfileTwoImage
                        }
                        className="img-fluid"
                        alt="Doctor"
                      />
                    </div>
                    <div className="doctor-online">
                      <h6>Dr. {userDetails.fullname}</h6>
                      <div className="doctor-switch">
                        <div className={`onoffswitch`}>
                          <input
                            title="status"
                            type="checkbox"
                            name="onoffswitch"
                            className={`onoffswitch-checkbox disabled`}
                            id="offline"
                            checked={isOffline}
                            onChange={() => {
                              updateStatus();
                            }}
                          />
                          <label
                            className="onoffswitch-label"
                            htmlFor="offline"
                          >
                            <span className="onoffswitch-inner" />
                            <span
                              className={` ${isOffline ? 'onoffswitch-switch onoffswitch-switch-online' : 'onoffswitch-switch'}`}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card">
                  <div className="card-body">
                    <div className="dash-header doctor-dash-header">
                      <h4>Self Training</h4>
                    </div>
                    <div className="red-flag-list">
                      <h6>Some Videos tutorials to train yourself</h6>
                      <ul className="nav">
                        <li>
                          <span /> <p>How to use this application</p>
                        </li>
                        <li>
                          <span /> <p>Things to do during an ongoing consult</p>
                        </li>
                        <li>
                          <span /> <p>how to minimise your consult time</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-9 col-lg-12">
              {typeProp != consultType.scheduled ? (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="doctor-header">
                        <h4>
                          {typeProp !== consultType.criticalPatient
                            ? `${capitalizeFirstLetter(typeProp)} Consults`
                            : 'Critical Patient Management - Patient List'}{' '}
                        </h4>
                        <ul className="nav">
                          {typeProp === consultType.criticalPatient && (
                            <li>
                              <Link
                                to={routes.emergencyList.path}
                                className="btn btn-primary"
                              >
                                Back
                              </Link>
                            </li>
                          )}
                          <li>
                            <Link
                              to={'#'}
                              className="btn btn-primary"
                              onClick={refresh}
                            >
                              Refresh
                            </Link>
                          </li>
                        </ul>
                      </div>
                      {/* Doctor Table */}
                      <PrimeDataTable
                        column={pastConsultsColumns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                        onRowClickSetState={false}
                        rowClassName={'highlight'}
                        type={userRole.Doctor}
                      />
                      {/* /Doctor Table */}
                    </div>
                  </div>
                </>
              ) : (
                <div className="row">
                  <div className="col-md-12">
                    <DoctorScheduledSlots />
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* /Doctor Dashboard */}
        </div>
        <PrescriptionModel
          appoitmentId={appoitmentId}
          setAppoitmentId={setAppitmentId}
        />
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EmergencyList;
