import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { Link } from "react-router-dom";
import { logoWhite, trendUp } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";


const FinancialPerformance = () => {
  const [financialMetrics, setFinancialMetrics] = useState<any>({
    series: [
      {
        name: "Total Ad Revenue",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Subscription Revenue",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Subscription Revenue",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    colors: ["#FCC69D", "#89D0CD", "#FCED8F"],
    chart: {
      type: "bar",
      height: 350,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        endingShape: "rounded",
      },
    },
    responsive: [
      {
        plotOptions: {
          bar: {
            columnWidth: "65%",
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [overallSummary, setOverallSummary] = useState<any>({
    series: [
      {
        name: "Total Revenue",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Profit Margins",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    colors: ["#FCC69D", "#89D0CD"],
    chart: {
      type: "bar",
      height: 300,
    },
    legend: {
      position: "bottom",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "55%",
        endingShape: "rounded",
      },
    },
    responsive: [
      {
        plotOptions: {
          bar: {
            columnWidth: "95%",
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val:any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [subscribers, setSubscribers] = useState<any>({
    series: [17, 15],
    chart: {
      height: 300,
      type: "donut",
    },
    colors: ["#FCC69D", "#89D0CD"],
    labels: ["Subscribe", "Unsubscribe"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
        },
      },
    ],
  });
  const [selectedMonth1, setSelectedMonth1] = useState(null);
  const [selectedMonth2, setSelectedMonth2] = useState(null);
  const [selectedRevenue1, setSelectedRevenue1] = useState(null);
  const [selectedRevenue2, setSelectedRevenue2] = useState(null);


  const revenue = [
    { label: "All Time", value: "All Time" },
    { label: "Time", value: "Time" },
  ];

  const monthOptions = [
    { label: "May 2024", value: "May 2024" },
    { label: "June 2024", value: "June 2024" },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Account Performance</Link>
              </li>
              <li>Financial Performance</li>
            </ul>
          </div>
          <div className="row flex-body d-flex row-gap-30 chart-set">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Overview</h3>
                    <p>
                      Comprehensive data insights guiding strategic decisions
                      and business growth.
                    </p>
                  </div>
                  <Dropdown
                    value={selectedMonth1}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth1(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row row-gap-30">
                    <div className="col-md-4">
                      <div className="card-insights">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Revenue
                          <Link to="#" className="ms-auto">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$3,671</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights danger-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Subscription Revenue
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$2,100</h3>
                          <p>
                            +6.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights info-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Ad Revenue
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>4500</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights warning-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Revenue from Special Events
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$976</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights info-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total CEU/CME Revenue
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$1,000</h3>
                          <p>
                            +6.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Financial Metrics
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedRevenue2}
                    options={revenue}
                    onChange={(e) => setSelectedRevenue2(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="financial-metrics">
                        <ReactApexChart
                          height={300}
                          options={financialMetrics}
                          series={financialMetrics.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Overall Financial Summary
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedMonth2}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth2(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="overall-summary">
                        <ReactApexChart
                          height={300}
                          options={overallSummary}
                          series={overallSummary.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 d-flex">
              <div className="custom-card flex-fill w-100">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Subscribers/Non-Subscriber Revenue</h3>
                  </div>
                  <Dropdown
                    value={selectedRevenue1}
                    options={revenue}
                    onChange={(e) => setSelectedRevenue1(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="subscribers">
                      <ReactApexChart
                          height={300}
                          options={subscribers}
                          series={subscribers.series}
                          type="donut"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default FinancialPerformance;
