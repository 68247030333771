import React, { useEffect, useState } from "react";
import NpiProfessionalInsurance from "./npi-professional-insurance";
import PrimaryCredentials from "./primary-credentials";
import { useSelector } from "react-redux";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { getData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";

const LicenseInsuranceSettings = () => {
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const [npiFormData, setNpiformData] = useState({});
  const [primaryData, setprimaryData] = useState({});

  const GetParentDetails = async () => {
    try {
      showLoader();
      const response = await getData(
        `${end_points.settings_details_listing?.url}`
      );
      if (response?.status < 400) {
        setNpiformData(response?.data?.data[0]?.professional_insurance_details);
        setprimaryData(response?.data?.data[0]?.primary_credential_setting[0]);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (userDetails?._id !== undefined && userDetails?._id !== null) {
      GetParentDetails();
    }
  }, [userDetails]);

  return (
    <div
      className="tab-pane fade"
      id="license-insurance"
      role="tabpanel"
      aria-labelledby="license-insurance-tab"
    >
      <NpiProfessionalInsurance
        userDetails={userDetails}
        npiFormData={npiFormData}
        GetParentDetails={GetParentDetails}
      />
      <PrimaryCredentials
        userDetails={userDetails}
        primaryData={primaryData}
        GetParentDetails={GetParentDetails}
      />
    </div>
  );
};
export default LicenseInsuranceSettings;
