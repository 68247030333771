import React from "react";
import { Link } from "react-router-dom";
import {
  addMoneyIcon,
  cardImg,
  downloadCloud,
  paymentIcon,
  withdrawlIcon,
} from "../../utils/imagepath";
import OwlCarousel from "react-owl-carousel";

const Billing = () => {
  const billingSlider = {
    loop: false,
    margin: 24,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    navContainer: ".slide-nav",
    navText: [
      '<i class="fas fa-arrow-left-long"></i>',
      '<i class="fas fa-arrow-right-long"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li className="f-500">Billing</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="billing-tabs">
                <ul className="nav">
                  <li>
                    <Link to="#wallet" className="active">
                      Wallet
                    </Link>
                  </li>
                  <li>
                    <Link to="#new-card">Add New Card</Link>
                  </li>
                  <li>
                    <Link to="#transaction-history">Transaction History</Link>
                  </li>
                  <li>
                    <Link to="#transaction-history">Tax Forms</Link>
                  </li>
                </ul>
              </div>
              <div className="billing-info">
                <div className="row">
                  <div className="col-lg-4 col-md-12 d-flex">
                    <div className="billing-card-slider w-100">
                      <div className="billing-slider">
                        <OwlCarousel {...billingSlider}>
                          <div className="credit-card">
                            <img src={cardImg} alt="Card Img" />
                          </div>
                          <div className="credit-card">
                            <img src={cardImg} alt="Card Img" />
                          </div>
                        </OwlCarousel>
                      </div>
                      <div className="slide-nav-center">
                        <div className="owl-nav slide-nav nav-control" />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-8 col-md-12 d-flex">
                    <div className="balance-card w-100">
                      <div className="balance-card-border">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="balance-info">
                              <h6>Your Balance</h6>
                              <p>
                                $524.00{" "}
                                <span>
                                  <i className="fas fa-arrow-up" /> 23.12%
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="balance-info">
                              <h6>Currency</h6>
                              <p>USD/ US Dollar</p>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="balance-info balance-info-right">
                              <h6>Status</h6>
                              <p>Active</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="balance-details">
                        <label className="custom_check">
                          <input type="checkbox" />
                          <span className="checkmark" /> Make wallet primary
                          method for rebills
                        </label>
                        <div className="balance-btns">
                          <div className="row">
                            <div className="col-md-6">
                              <button className="btn btn-primary rounded w-100">
                                <img src={addMoneyIcon} alt="Icon" /> Add Money
                              </button>
                            </div>
                            <div className="col-md-6">
                              <button className="btn btn-withdrawl rounded w-100">
                                <img src={withdrawlIcon} alt="Icon" /> Withdrawl
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="billing-add-card" id="new-card">
                <div className="row">
                  <div className="col-md-12">
                    <div className="add-card-bill">
                      <div className="add-card-header">
                        <h4>Add Card</h4>
                        <div className="add-card-btns">
                          <Link to="#" className="btn btn-cancel rounded">
                            Cancel
                          </Link>
                          <Link to="#" className="btn btn-primary rounded">
                            Save
                          </Link>
                        </div>
                      </div>
                      <div className="card-details-info">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="card-text">
                              <p>Card details</p>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="card-form-info">
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="form-wrap">
                                    <label>Name on card</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Susan Reynolds"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label>Expiry</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="06 / 2024"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-8">
                                  <div className="form-wrap">
                                    <label>Card number</label>
                                    <div className="payment-form-wrap">
                                      <img src={paymentIcon} alt="Icon" />
                                      <input
                                        type="text"
                                        className="form-control"
                                        placeholder="**** **** **** ****"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4">
                                  <div className="form-wrap">
                                    <label>CVV</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="•••"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="add-card-header billing-card-header">
                        <h4>Billing Address</h4>
                        <p>
                          Update your billing address to ensure accurate and
                          timely invoices for all your subscriptions and
                          payments.
                        </p>
                      </div>
                      <div className="billing-form">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-wrap">
                              <label>First Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="First Name"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Address"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>City</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>Phone</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Phone"
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-wrap">
                              <label>Last Name</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Last Name"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>Email Address</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="susab@turningwell.com"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>State</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="State"
                              />
                            </div>
                            <div className="form-wrap">
                              <label>Zip Code</label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder={264976}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="add-card-bill history-card mb-0"
                id="#transaction-history"
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="add-card-header">
                      <h4>Transaction history</h4>
                      <div className="add-card-btns">
                        <Link to="#" className="btn btn-cancel rounded me-0">
                          Download all
                        </Link>
                      </div>
                    </div>
                    <div className="add-card-table">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Invoice
                                  <i className="fas fa-arrow-down" />
                                </label>
                              </th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th />
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Monthly
                                  Subscription
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>Dec 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Wellness Course
                                  Payment
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>Nov 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Mental Health
                                  Workshop
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>Oct 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> E-Book Purchase
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>Sep 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Annual
                                  Membership Fee
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>Aug 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Webinar
                                  Registration
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>July 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" /> Counseling
                                  Session
                                </label>
                              </td>
                              <td>USD $10.00</td>
                              <td>June 1, 2022</td>
                              <td>
                                <Link to="#">
                                  <img src={downloadCloud} alt="Icon" />
                                </Link>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Billing;
