import React, { useContext, useEffect, useRef, useState } from 'react';
import { ApiServiceContext } from '../../../../../../utils/shared.module';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { end_points } from '../../../../../../services/end_point/end_points';
import { logo, logoBig } from '../../../../../../utils/imagepath';
import {
  capitalizeFirstLetter,
  capitalizeWords,
  dateFormat_DD_MM_YYYY,
} from '../../../../../../utils/constant';
import moment from 'moment';
import { NormalVitals } from './vitalsCall';
interface presProps {
  appoitmentId: any;
  showPreview?: string;
  setAppoitmentId: (data: any) => void;
}
const PrescriptionModel: React.FC<presProps> = ({
  appoitmentId,
  showPreview = 'OPEN',
  setAppoitmentId = (data: any) => {},
}) => {
  const { getData, putData, postData } = useContext(ApiServiceContext);
  const { showLoader, hideLoader, isLoading } = useContext(SpinnerContext);
  const [prescriptionUrl, setPrescriptionUrl] = useState<any>('');
  const closeRef = useRef<any>(null);
  const [spirometer, setSpirometer] = useState('');
  const sendPatient = async () => {
    try {
      showLoader();
      let url = end_points.sendToPatientApi.url;
      if (appoitmentId?._id) url += `/${appoitmentId?._id}`;

      const response = await postData(url);
      if (response.status === 200) {
        setAppoitmentId('');
        if (closeRef.current) {
          closeRef.current.click();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };
  const getPrescriptionData = async (ids: any) => {
    try {
      showLoader();

      let url = end_points.prescriptionPreviewApi.url;
      if (ids) url += `/${ids}`;
      const response = await putData(url);

      if (response.status === 200) {
        const data = response?.data?.data;
        let details: any = {};

        details.patient_details = [data?.patient_details] || [];
        details.symptoms = data?.symptoms || {};
        details.vitals = { normal: [], labTest: [] };
        details.prescription = [];
        details.general = [];
        details.doctorNotes = {
          diagnosis: [],
          differential_diagnosis: [],
          provisional_diagnosis: [],
        };
        details.doctor = { name: '', destination: '' };
        details.date = data?.date;
        details.address = data?.address || [];
        // Populate vitals.normal
        if (data?.vital) {
          // console.log(data.vital, 'data.vital');

          const {
            _id,
            updatedAt,
            deletedAt,
            createdAt,
            others,
            stethoscope_details,
            ecg,
            stethoscope,
            screenshot,
            graph,
            auto_scope,
            derma_scope,
            dermoscope,
            autoscope,
            spirometer,
            ...vitals
          } = data.vital;
          spirometer && setSpirometer(spirometer);
          details.vitals.normal = Object.keys(vitals).map(
            (val: any, index: any) => ({
              id: index + 1,
              name: val ? capitalizeWords(val.replace('_', ' ')) : val,
              value: vitals[val],
            }),
          );
        }
        const getTimings = (val = []) => {
          if (Array.isArray(val)) {
            let timings: any = val?.map((val: any) => val?.name)?.join(',');
            return timings;
          }
        };

        // Populate vitals.labTest
        details.vitals.labTest =
          data?.investigation?.tests?.map((val: any, index: any) => ({
            id: index + 1,
            name: val?.name ? capitalizeFirstLetter(val?.name) : val?.name,
          })) || [];
        // Populate prescription
        details.prescription =
          data?.prescription?.drugs?.map((val: any, index: any) => ({
            id: index + 1,
            name: val?.medicine || 'NA',
            value:
              val?.frequency +
                '(' +
                getTimings(val?.timings) +
                ')' +
                ', ' +
                val?.instructions || 'NA',
          })) || [];

        // Populate general information
        details.general =
          data?.gender_advice?.map((val: any, index: any) => ({
            id: index + 1,
            name: val,
          })) || [];

        // Populate doctorNotes
        details.doctorNotes.diagnosis =
          data?.diagnosis?.diagnosis?.map((val: any, index: any) => ({
            id: index + 1,
            name: val?.name ? capitalizeFirstLetter(val?.name) : val?.name,
          })) || [];
        details.doctorNotes.differential_diagnosis =
          data?.diagnosis?.differential_diagnosis?.map(
            (val: any, index: any) => ({
              id: index + 1,
              name: val?.name ? capitalizeFirstLetter(val?.name) : val?.name,
            }),
          ) || [];
        details.doctorNotes.provisional_diagnosis =
          data?.diagnosis?.provisional_diagnosis?.map(
            (val: any, index: any) => ({
              id: index + 1,
              name: val?.name ? capitalizeFirstLetter(val?.name) : val?.name,
            }),
          ) || [];
        details.final = [
          ...details.doctorNotes.diagnosis,
          ...details.doctorNotes.differential_diagnosis,
          ...details.doctorNotes.provisional_diagnosis,
        ];

        // Populate doctor information
        if (data?.doctor?.name) {
          details.doctor.name = data.doctor?.name || '';
          details.doctor.destination = data.doctor?.degree || '';
        }
        details.prescription_expiry = data?.prescription_expiry;
        setPrescriptionUrl(details);
        // setAppoitmentId('');
      } else {
        setPrescriptionUrl({});
      }
    } catch (error) {
      console.log(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    const ids = appoitmentId?._id;
    appoitmentId && showPreview === 'OPEN' && getPrescriptionData(ids);
  }, [appoitmentId, showPreview]);

  const spirometerData = () => {
    if (spirometer) {
      let spirometerParsed: any = spirometer && JSON.parse(spirometer);
      const spirometerValue =
        spirometerParsed?.value && JSON.parse(spirometerParsed?.value);

      let keys =
        (spirometerValue && Object.keys(spirometerValue)?.map((key) => key)) ||
        [];

      return (
        <div className="row">
          <NormalVitals
            spirometerValue={spirometerValue}
            keys={keys}
            type="prescription"
          />
        </div>
      );
    }
  };
  return (
    <div
      className="modal custom-modal fade"
      id="prescription_preview"
      data-keyboard="false"
      data-backdrop="static"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="medical-modal-header prescription-modal-header">
            <h5>Prescription Preview</h5>
            <button
              type="button"
              className="close"
              data-bs-dismiss="modal"
              ref={closeRef}
            >
              <span>×</span>
            </button>
          </div>
          <div className="modal-body prescription-modal-body">
            {/* <div className="row">
              <div className="col-md-12">
                {appoitmentId && (
                  <div className="prescription-grid">
                    {prescriptionUrl && (
                      // <PlainTextPDF pdfUrl={prescriptionUrl} />
                      <iframe
                        src={prescriptionUrl}
                        width="100%"
                        height="500px"
                        title="Prescription Preview"
                        frameBorder="0"
                      />
                    )}
                  </div>
                )}
              </div>
            </div> */}
            {prescriptionUrl?.patient_details?.length > 0 ? (
              <div className="row">
                <div className="col-md-12">
                  <div className="prescription-grid">
                    <div className="prescription-head">
                      <div className="prescription-head-list">
                        {prescriptionUrl?.address?.length > 0
                          ? prescriptionUrl?.address?.map(
                              (item: any, index: number) => (
                                <p key={index}>{capitalizeFirstLetter(item)}</p>
                              ),
                            )
                          : null}
                        {/* <p>LifeSync Smart Health</p>
                      <p>Gurgaon</p>
                      <p>Haryana</p>
                      <p>India</p> */}
                      </div>
                      <div className="prescription-logo">
                        <img src={logoBig} alt="Logo" />
                      </div>
                    </div>
                    {prescriptionUrl?.patient_details?.length > 0 ? (
                      <div className="prescription-details">
                        <div className="prescription-title">
                          <h4>Patient Detials</h4>
                        </div>
                        <div className="prescription-info">
                          <ul>
                            {prescriptionUrl?.patient_details?.map(
                              (item: any, index: number) => (
                                <>
                                  <li>
                                    <span>Name :</span>{' '}
                                    {item?.name
                                      ? capitalizeFirstLetter(item?.name)
                                      : ''}
                                  </li>
                                  <li>
                                    <span>Age :</span> {item?.age}
                                  </li>
                                  <li>
                                    <span>Gender :</span>{' '}
                                    {item?.gender
                                      ? capitalizeFirstLetter(item?.gender)
                                      : ''}
                                  </li>{' '}
                                </>
                              ),
                            )}
                          </ul>
                          {prescriptionUrl?.date ? (
                            <div className="prescription-date">
                              <p>
                                Date -{' '}
                                {moment(
                                  prescriptionUrl?.date,
                                  'DD-MM-YYYY',
                                ).format('DD/MM/YYYY')}
                              </p>
                            </div>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {prescriptionUrl?.symptoms?.length > 0 ? (
                      <div className="prescription-details">
                        <div className="prescription-title">
                          <h4>Symptoms</h4>
                        </div>
                        <div className="prescription-info">
                          <ul>
                            {prescriptionUrl?.symptoms?.map(
                              (item: any, index: any) => {
                                return (
                                  <li>
                                    {index + 1}. {item?.name}
                                  </li>
                                );
                              },
                            )
                            // <li>2. Heat rash/prickly heat</li>
                            // <li>3. Headache</li>
                            // <li>4. body pain</li>
                            }
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    <div className="prescription-details prescription-details-info">
                      <div className="prescription-info">
                        <div className="prescription-title">
                          <h4>Vitals</h4>
                        </div>
                        <ul>
                          {prescriptionUrl?.vitals?.normal?.length > 0 ? (
                            <>
                              {prescriptionUrl?.vitals?.normal?.map(
                                (item: any, index: any) => {
                                  return (
                                    <li>
                                      {item?.id}. {item?.name} -&gt;{' '}
                                      {item?.value}
                                    </li>
                                  );
                                  // <li>2. Blood Pressure -&gt; 114/76 mmHg, 67 bpm</li>
                                  // <li>3. Blood Glucose -&gt; 112.9 mg/dL</li>
                                },
                              )}
                            </>
                          ) : (
                            <li>N/A</li>
                          )}
                        </ul>
                      </div>

                      {prescriptionUrl?.vitals?.labTest?.length > 0 ? (
                        <div className="prescription-info prescription-lab-test">
                          <div className="prescription-title">
                            <h4>Lab Test</h4>
                          </div>
                          <ul>
                            {prescriptionUrl?.vitals?.labTest?.map(
                              (item: any, index: any) => {
                                return (
                                  <li>
                                    {item?.id}. {item?.name}
                                  </li>
                                );
                              },
                            )}
                            {/* <li>1. Complete Blood Count</li>
                          <li>2. Urinalysis</li> */}
                          </ul>
                        </div>
                      ) : null}
                      {spirometer && (
                        <div>
                          <p
                            className="ml-2"
                            style={{
                              marginLeft: '20px',
                            }}
                          >
                            {Number(
                              prescriptionUrl?.vitals?.normal?.length || 0,
                            ) + 1}
                            . Spirometer
                          </p>
                          {spirometerData()}
                        </div>
                      )}
                    </div>
                    {prescriptionUrl?.prescription?.length > 0 ? (
                      <div className="prescription-details">
                        <div className="prescription-title">
                          <h4>Prescription</h4>
                        </div>
                        <div className="prescription-info">
                          <ul className="sub-prescription-list">
                            {prescriptionUrl?.prescription?.map((item: any) => {
                              return (
                                <li>
                                  {item?.id}. {item?.name}
                                  <p>
                                    {item?.value !== 'N/A' ? item?.value : ''}
                                    {/* 3 times a day(Morning,After noon,Night) Take
                                  the pill if the temperature exceeds 99 degrees */}
                                  </p>
                                </li>
                              );
                            })

                            // <li>
                            //   2. Nasivion nasal drops
                            //   <p>
                            //     2 times a day(Morning,Night) 2 drops twice a day
                            //   </p>
                            // </li>
                            }
                          </ul>
                        </div>
                      </div>
                    ) : null}
                    <div className="prescription-details">
                      <div className="prescription-title">
                        <h4>Self Care</h4>
                      </div>
                      <div className="prescription-info">
                        <ul>
                          {prescriptionUrl?.general?.length > 0 ? (
                            prescriptionUrl?.general?.map((item: any) => {
                              return (
                                <li>
                                  {item?.id}. {item?.name}
                                </li>
                                // <li>2. Avoid oily foods</li>
                              );
                            })
                          ) : (
                            <li>N/A</li>
                          )}
                        </ul>
                      </div>
                    </div>
                    {prescriptionUrl?.doctorNotes ? (
                      <div className="prescription-details">
                        <div className="prescription-title">
                          <h4>Doctor's Notes / Diagnosis</h4>
                        </div>
                        <div className="prescription-info">
                          <ul>
                            {prescriptionUrl?.final?.length > 0
                              ? prescriptionUrl?.final?.map(
                                  (item: any, index: any) => (
                                    // <div className="prescription-info">
                                    <>
                                      <li key={index}>
                                        {index + 1}. {item?.name}
                                      </li>
                                    </>
                                    // </div>
                                  ),
                                )
                              : 'N/A'}
                          </ul>
                          {/* <ul>
                            {Object.keys(
                              prescriptionUrl?.doctorNotes || {},
                            ).map((key) => (
                              // <div className="prescription-info">
                              <>
                                {prescriptionUrl?.doctorNotes[key]?.map(
                                  (item: any) => (
                                    <li key={item?.id}>
                                      {item?.id}. {item?.name}
                                    </li>
                                  ),
                                )}
                              </>
                              // </div>
                            ))}
                          </ul> */}
                        </div>
                      </div>
                    ) : null}
                    {prescriptionUrl?.doctor?.name ? (
                      <div className="prescription-md">
                        <h6>{prescriptionUrl?.doctor?.name}</h6>
                        <p>{prescriptionUrl?.doctor?.destination}</p>
                      </div>
                    ) : null}
                    <div className="prescription-bottom-content">
                      <div className="prescription-logo">
                        <img src={logoBig} alt="Logo" />
                      </div>
                      <div className="prescription-bottom-text">
                        <p>For Demo Purpose Only</p>
                        {prescriptionUrl?.prescription_expiry ? (
                          <p style={{ display: 'block' }}>
                            {prescriptionUrl?.prescription_expiry}
                          </p>
                        ) : null}
                        {/* <p> This prescription valid only until 02-09-2024</p> */}
                      </div>
                    </div>
                    <div className="prescription-btn">
                      <button
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => {
                          sendPatient();
                        }}
                      >
                        Send to Patient
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              !isLoading && <p className="p-2">No prescription found</p>
            )}
            {/* <div className="prescription-btn">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setAppoitmentId('');
                  if (closeRef.current) {
                    closeRef.current.click();
                  }
                }}
              >
                Send to Patient
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrescriptionModel;
