interface EndPoint {
  url?: string;
  list?: string;
  add?: string;
  edit?: string;
  delete?: string;
  service?: string;
}

export const end_points: Record<string, EndPoint> = {
  list_announcement: {
    url: "/announcement/list",
    service: "ms1",
  },
  add_announcement: {
    url: "/announcement/create",
    service: "ms1",
  },
  update_announcement: {
    url: "/announcement/update", //:id
  },
  status_announcement: {
    url: "/announcement/updateStatus", //:id
  },
  delete_announcement: {
    url: "/announcement/delete", //:id
  },
  authLoginApi: {
    url: "/auth/login",
    service: "ms1",
  },
  authRegisterApi: {
    url: "/auth/register",
    service: "ms1",
  },
  verifyOtpApi: {
    url: "/auth/verify-otp",
    service: "ms1",
  },
  generateEmail: {
    url: "/auth/generate-mail",
    service: "ms1",
  },
  verifyEmail: {
    url: "/auth/verify-mail",
    service: "ms1",
  },
  verifyEmailStatus: {
    url: "/about/verified-email",
    service: "ms1",
  },
  resendOtpApi: {
    url: "/auth/resend-otp",
    service: "ms1",
  },
  forgot_password_mail: {
    url: "/auth/forgot-password-mail",
    service: "ms1",
  },
  decrypt_user_details: {
    url: "/auth/decrypt-user-detail",
    service: "ms1",
  },
  password_reset_mail: {
    url: "/auth/forgot-password",
    service: "ms1",
  },
  userprofile_education: {
    url: "about/education/create",
    service: "ms1",
  },
  userprofile_board_cirtification: {
    url: "about/broad-certificate/create",
    service: "ms1",
  },
  userprofile_board_cirtification_update: {
    url: "about/broad-certificate/update",
    service: "ms1",
  },
  user_about_list: {
    url: "about/lists",
    service: "ms1",
  },
  settings_professional_insurance: {
    url: "/about/professional-insurance/update",
    service: "ms1",
  },
  settings_license: {
    url: "/about/license",
    service: "ms1",
  },
  settings_parental_control: {
    url: "/about/parental-settings",
    service: "ms1",
  },
  settings_parental_control_listing: {
    url: "/about/parental-settings/list",
    service: "ms1",
  },
  settings_details_listing: {
    url: "/about/lists",
    service: "ms1",
  },
  profile_image_upload: {
    url: "/about/upload-profile-image",
    service: "ms1",
  },

  userprofile_fellowships: {
    url: "about/fellow-ships/create",
    service: "ms1",
  },
  userprofile_fellowships_update: {
    url: "about/fellow-ships/update",
    service: "ms1",
  },
  userprofile_residency: {
    url: "about/residency/create",
    service: "ms1",
  },
  userprofile_residency_update: {
    url: "about/residency/update",
    service: "ms1",
  },
  userprofile_internship: {
    url: "about/internship/create",
    service: "ms1",
  },
  userprofile_internship_update: {
    url: "about/internship/update",
    service: "ms1",
  },
  userprofile_finance_create: {
    url: "about/finance/create",
    service: "ms1",
  },
  userprofile_communities: {
    url: "about/communities-served",
    service: "ms1",
  },
  userprofile_sharedexperience: {
    url: "about/shared-experience/list",
    service: "ms1",
  },
  userprofile_sharedexperience_update: {
    url: "about/update-shared-experience",
    service: "ms1",
  },
  getAvailablePackagesApi: {
    url: "/stripe/product",
    service: "ms1",
  },
  profile_about_email: {
    url: "about/email",
    service: "ms1",
  },
  profile_about_phone: {
    url: "/about/phone",
    service: "ms1",
  },
  profile_about_availablity: {
    url: "/about/availability/create",
    service: "ms1",
  },
  profile_about_clinical: {
    url: "/about/update-clinical-specialities",
    service: "ms1",
  },
  profile_about_treatment_update: {
    url: "/about/treatment-modality/update",
    service: "ms1",
  },
  profile_about_addlink_create: {
    url: "/about/create-url",
    service: "ms1",
  },
  profile_about_addlink_update: {
    url: "/about/update-url",
    service: "ms1",
  },
  profile_about_addidentity_update: {
    url: "/about/my-identity/update",
    service: "ms1",
  },
  get_primary_credentials: {
    url: "/about/primary-credentials",
    service: "ms1",
  },
  profile_gallery_images_upload: {
    url: "about/upload-gallery-image",
    service: "ms1",
  },
  profile_gallery_images_lists: {
    url: "about/gallery/lists",
    service: "ms1",
  },
  profile_gallery_images_delete: {
    url: "/about/delete/gallery-image",
  },
  profile_about_location: {
    url: "/about/location/create",
  },

  countriesListApi: {
    url: "/master/countrieslist",
    service: "ms1",
  },
  masterHealthRoleApi: {
    url: "/master/mentalhealth-role/list",
    service: "ms1",
  },
  masterClinicalRoleApi: {
    url: "/master/clinical-role/list",
    service: "ms1",
  },
  createSubscriptionApi: {
    url: "/v1/stripe/subscription/create",
    service: "ms1",
  },
  profile_about_personal_info: {
    url: "/about/basic-information",
    service: "ms1",
  },
  mater_designation_list: {
    url: "/master/designation/list",
    service: "ms1",
  },
  authEmailCheckApi: {
    url: "/auth/email-check",
    service: "ms1",
  },
  master_clinical_specialties: {
    url: "/master/clinical-specialties/list",
    service: "ms1",
  },
  master_clinical_expertise: {
    url: "/master/clinical-expertise/list",
    service: "ms1",
  },
  master_clinical_experience: {
    url: "/master/clinical-experience/list",
    service: "ms1",
  },
  master_category: {
    url: "/master/category/list",
    service: "ms1",
  },
  master_subCategory: {
    url: "master/sub-category/list", //:id
    service: "ms1",
  },
  master_group: {
    url: "/master/group/list",
    service: "ms1",
  },
  master_insurance_methods: {
    url: "master/finance-insurance/list",
    service: "ms1",
  },
  treatmentModalityListApi: {
    url: "about/treatment-modality/list",
    service: "ms1",
  },
  getSpecialitiesListApi: {
    url: "about/clinical-specialities/list",
    service: "ms1",
  },
  uploadVideoBunny: {
    url: "/videos/create-video",
    service: "ms1",
  },
  createVideo: {
    url: "/videos/create",
    service: "ms1",
  },
  listAddedVideos: {
    url: "/videos/lists",
    service: "ms1",
  },
  deleteAddedVideos: {
    url: "videos/delete", //:id
    service: "ms1",
  },
  statusUpdateVideos: {
    url: "videos/updateStatus", //:id
    service: "ms1",
  },
  master_myIdentity_getfaith: {
    url: "master/faith/list",
    service: "ms1",
  },
  master_myIdentity_getrace: {
    url: "master/races/list",
    service: "ms1",
  },
};
