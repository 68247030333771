import React from "react";
import { Link } from "react-router-dom";
import { alertNotify, avatar01, avatar02, avatar03, avatar04, deleteIcon, logoWhite } from "../../utils/imagepath";


const Notifications = () => {
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>
                      <Link to="#">Directory</Link>
                    </li>
                    <li>Notifications</li>
                  </ul>
                </div>
              </div>
              <div className="alert-flex-wrapper mb-3 mb-lg-4">
                <div className="alert-wrapper alert-notify d-md-flex align-items-center">
                  <div className="alert-info flex-style col-gap-10 mb-3 mb-md-0">
                    <img src={alertNotify} alt="" />
                    <p>
                      Activate alerts for patient interactions. Enable
                      notifications now.
                    </p>
                  </div>
                  <div className="ms-lg-auto d-flex align-items-center mt-lg-0">
                    <Link to="#" className="btn btn-push-notify">
                      Allow push notifications
                    </Link>
                    <Link to="#" className="ms-3">
                      Dismiss
                    </Link>
                  </div>
                </div>
              </div>
              <div className="tab-set">
                <div className="d-flex">
                  <div className="d-flex align-items-center">
                    <label className="ms-auto custom_check">
                      <input type="checkbox" />
                      <span className="checkmark" />
                    </label>
                    <img
                      src={deleteIcon}
                      className="ms-2"
                      alt=""
                    />
                  </div>
                  <nav className="ms-4 w-100">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                      <button
                        className="nav-link active"
                        id="view-all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#view-all"
                        type="button"
                        role="tab"
                        aria-controls="view-all"
                        aria-selected="true"
                      >
                        View All
                      </button>
                      <button
                        className="nav-link"
                        id="nav-followers-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-followers"
                        type="button"
                        role="tab"
                        aria-controls="nav-followers"
                        aria-selected="false"
                      >
                        Followers
                      </button>
                      <button
                        className="nav-link"
                        id="nav-subscribed-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#nav-subscribed"
                        type="button"
                        role="tab"
                        aria-controls="nav-subscribed"
                        aria-selected="false"
                      >
                        Subscribed
                      </button>
                    </div>
                  </nav>
                </div>
                <div className="tab-content p-0 my-5" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="view-all"
                    role="tabpanel"
                    aria-labelledby="view-all-tab"
                  >
                    <div className="notification-wrapper">
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar01}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>@willie_son</strong> subscribed you
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar02}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>Caroline</strong> commented on your blog
                              “Take a Look Back at the Most Absurd Carpet Ever”{" "}
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                            <div className="blockquote">
                              <p>
                                Amazing! Fast, to the point, professional and
                                really amazing to work with them!!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar03}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>Jack Charminder</strong> just followed you
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar04}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>@willie_son</strong> subscribed you
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar02}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>Caroline</strong> commented on your blog
                              “Take a Look Back at the Most Absurd Carpet Ever”{" "}
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                            <div className="blockquote">
                              <p>
                                Amazing! Fast, to the point, professional and
                                really amazing to work with them!!
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar04}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>@willie_son</strong> subscribed you
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                      <div className="notify-item flex-style">
                        <div className="notify-info flex-style">
                          <img
                            src={avatar01}
                            className="rounded"
                            alt=""
                          />
                          <div>
                            <p>
                              <strong>@willie_son</strong> subscribed you
                            </p>
                            <span>
                              <i className="ti ti-clock-hour-3 me-2" />
                              Wednesday 03:42 PM
                            </span>
                          </div>
                        </div>
                        <div className="ms-auto flex-style flex-icon">
                          <Link to="#">
                            <i className="ti ti-eye-off" />
                          </Link>
                          <Link to="#">
                            <i className="ti ti-trash" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-followers"
                    role="tabpanel"
                    aria-labelledby="nav-followers-tab"
                  ></div>
                </div>
              </div>
              <div className="pagination-div mt-40">
                <Link to="#" className="previous-next-link disabled">
                  <i className="ti ti-chevron-left me-1" />
                  Previous
                </Link>
                <ul>
                  <li>
                    <Link to="#" className="active">
                      1
                    </Link>
                  </li>
                  <li>
                    <Link to="#">2</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">3</Link>
                  </li>
                  <li>
                    <Link to="#">...</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">8</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">9</Link>
                  </li>
                  <li>
                    <Link to="#">10</Link>
                  </li>
                </ul>
                <Link to="#" className="previous-next-link">
                  Next
                  <i className="ti ti-chevron-right ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Notifications;
