import React from "react";
import { Link } from "react-router-dom";

const ProviderSubscriptionPlans = () => {
  return (
    <div
      className="tab-pane fade"
      id="subscription-plan"
      role="tabpanel"
      aria-labelledby="subscription-plan-tab"
    >
      <div className="custom-card custom-card-info custom-card-account">
        <div className="card-head card-head-info d-flex align-items-center">
          <div className="row align-items-center">
            <div className="col-md-12">
              <h6>Choose a Subscription Plan</h6>
              <p>
                Select a plan that will be the best fit for your business needs.
              </p>
            </div>
          </div>
        </div>
        <div className="row account-custom-row">
          <div className="col-md-6 account-custom-col">
            <div className="form-set">
              <div className="account-plan-blk">
                <div className="flex-plan-info flex-column">
                  <div className="left-info text-start">
                    <h5>Standard Account</h5>
                    <p>Limited</p>
                  </div>
                  <div className="right-info">
                    <h3>
                      $29.95<span>/month</span>
                    </h3>
                  </div>
                </div>
                <div className="plan-btn-blk w-100">
                  <Link to="#" className="btn btn-outline-primary">
                    Your Current Plan
                  </Link>
                </div>
                <ul className="subscription-features-list">
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Access to basic tools and resources
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Limited number of monthly intes
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic customer support
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Listing in the standard directory
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Access to community forums
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic analytics and reporting
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Email notifications for important updates
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Ability to create and manage a provider profile
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic scheduling and calendar integration
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-6 account-custom-col">
            <div className="form-set">
              <div className="account-plan-blk">
                <div className="flex-plan-info flex-column">
                  <div className="left-info text-start">
                    <h5 className="d-flex align-items-center">
                      Enhanced{" "}
                      <span className="ms-1 plan-badge">Most Popular</span>
                    </h5>
                    <p>Everything you needed</p>
                  </div>
                  <div className="right-info">
                    <h3>
                      $99.95<span>/month</span>
                    </h3>
                  </div>
                </div>
                <div className="plan-btn-blk w-100">
                  <Link to="#" className="btn btn-outline-primary">
                    Upgrade Your Plan
                  </Link>
                </div>
                <ul className="subscription-features-list">
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Access to basic tools and resources
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Limited number of monthly intes
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic customer support
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Listing in the standard directory
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Access to community forums
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic analytics and reporting
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Email notifications for important updates
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Ability to create and manage a provider profile
                  </li>
                  <li className="d-flex align-items-center">
                    <i className="ti ti-circle-check" />
                    Basic scheduling and calendar integration
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderSubscriptionPlans;
