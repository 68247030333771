import * as yup from "yup";
import {
  addressMaxLength,
  email,
  firstNameMinLength,
  licneseNumberPattern,
  maxLength_3,
  nameMaxLength,
  onlyAlphabet,
  onlyAlphabetwithoutSpace,
  onlyNumber,
  userNameMinLength,
  validatePhoneNumber,
} from "../../../utils/patterns/regex.pattern";
// Import your patterns here

export const userNameSchema = yup
  .string()
  .trim()
  .min(maxLength_3, "User name must be minimum 3 characters")
  .matches(
    onlyAlphabetwithoutSpace,
    "User name must only contain alphabetic characters except -_."
  )
  .max(nameMaxLength, "User name must be maximum 30 characters")
  .required("User name is required");

export const nameSchema = yup
  .string()
  .trim()
  .min(firstNameMinLength, "Name must be minimum 3 characters")
  .matches(onlyAlphabet, "Name must only contain alphabetic characters")
  .max(nameMaxLength, "Name must be maximum 30 characters")
  .required("Name is required");

export const titleSchema = yup.string().trim().required("Title is required");

export const firstNameSchema = yup
  .string()
  .trim()
  .min(firstNameMinLength, "First name must be minimum 3 characters")
  .matches(onlyAlphabet, "First name must only contain alphabetic characters")
  .max(nameMaxLength, "First name must be maximum 30 characters")
  .required("First name is required");

export const lastNameSchema = yup
  .string()
  .trim()
  .min(1, "Last name must be minimum 1 character")
  .matches(onlyAlphabet, "Last name must only contain alphabetic characters")
  .max(nameMaxLength, "Last name must be maximum 30 characters")
  .required("Last name is required");

export const companyNameSchema = yup
  .string()
  .trim()
  .min(userNameMinLength, "Company name must be minimum 5 characters")
  .matches(onlyAlphabet, "Company name must only contain alphabetic characters")
  .max(nameMaxLength, "Company name must be maximum 30 characters")
  .required("Company name is required");

export const addressSchema = yup
  .string()
  .trim()
  .max(addressMaxLength, "Address must be maximum 100 characters")
  // .matches(alphaNumeric, 'Address must only contain alphabetic characters')
  .required("Address is required");

export const credentialsSchema = yup
  .string()
  .trim()
  .max(addressMaxLength, "Credentials must be maximum 100 characters")
  // .matches(alphaNumeric, 'Credentials must only contain alphabetic characters')
  .required("Credentials is required");

export const extensionSchema = yup
  .string()
  .trim()
  .max(addressMaxLength, "Extension must be maximum 100 characters")
  .matches(onlyNumber, "Credentials must only contain number characters")
  .required("Extension is required");

export const emailSchema = yup
  .string()
  .email("Please enter a valid email")
  .trim()
  .matches(email, "Please enter a valid email")
  .required("Email is required");

export const passwordSchema = yup
  .string()
  .trim()
  .matches(/[A-Z]/, "Password must have at least one uppercase letter")
  .matches(/[a-z]/, "Password must have at least one lowercase letter")
  .matches(/\d/, "Password must contain a number")
  .matches(
    /[~`!@#$%^&*()\-_=+\{\}\[\]|\\;:"<>,./?]/,
    'Password must contain a special character(~`!@#$%^&*()-_=+{}[]|\\;:"<>,./?)'
  )
  .min(5, "Password must be at least 5 characters")
  .required("Password is required");

export const confirmPasswordSchema = yup
  .string()
  .oneOf([yup.ref("new_password"), null], "Passwords must match")
  .required("Confirm Password is required");

export const countrySchema = yup.string().required("Country is required");

export const mentalHealthRoleSchema = yup
  .string()
  .required("Mental health role is required");

export const clinicalRoleSchema = yup
  .string()
  .required("Clinical role is required");

export const clinicalTypeSchema = yup
  .string()
  .required("Credential type is required");

export const dropdownSchema = yup
  .string()
  .required("Dropdown role is required");

export const licenseNumberSchema = yup
  .string()
  .trim()
  .matches(licneseNumberPattern, "Please enter a valid  license number")
  .min(5, "License number must have minimum 5 characters")
  .max(12, "License number only allowed maximum 12 characters only")
  .required("License number is required");

export const preLicenseNumberSchema = yup
  .string()
  .trim()
  .matches(licneseNumberPattern, "Please enter a valid  license number")
  .min(5, "License number must have minimum 5 characters")
  .max(12, "License number only allowed maximum 12 characters only")
  .required("License number is required")
  .nullable();

export const licenseStateSchema = yup
  .string()
  .required("License State is required");

export const licenseExpMonthSchema = yup
  .string()
  .trim()
  .required("License expiry month is required");

export const licenseExpYearSchema = yup
  .string()
  .trim()
  .required("License expiry year is required");

export const licenseExpDateSchema = yup
  .string()
  .trim()
  .required("License expiry date is required");

export const credentialTypeSchema = yup
  .string()
  .trim()
  .min(5, "Credential type must have minimum 5 characters")
  .required("Credential type is required");

export const licenseSchema = yup
  .string()
  .min(5, "License must have minimum 5 characters")
  .required("License expiry year is required");

export const educationSchema = yup
  .string()
  .trim()
  .min(5, "Education must have minimum 5 characters")
  .required("Education is required");

export const degreeSchema = yup
  .string()
  .trim()
  .min(5, "Degree must have minimum 5 characters")
  .required("Degree or diploma is required");

export const yearGraduatedSchema = yup
  .string()
  .trim()
  .min(4, "Year must have minimum 4 characters")
  .required("Year graduated is required");

export const yearBeganPractiseSchema = yup
  .string()
  .trim()
  .min(4, "Year must have minimum 4 characters")
  .required("Year began practice is required");

export const programTypeSchema = yup
  .string()
  .trim()
  .min(4, "Program must have minimum 4 characters")
  .required("Program type is required");

export const programInstitutionSchema = yup
  .string()
  .trim()
  .min(4, "Program institution must have minimum 4 characters")
  .required("Program institution is required");

export const cirtificationNameSchema = yup
  .string()
  .trim()
  .min(4, "Cirtification name must have minimum 4 characters")
  .required("Cirtification name is required");

export const cirtificationSpecialitySchema = yup
  .string()
  .trim()
  .min(4, "Speciality must have minimum 4 characters")
  .required("Speciality is required");

export const yearObtainedSchema = yup
  .string()
  .trim()
  .min(4, "Year must have minimum 4 characters")
  .required("Year obtained is required");

export const sessionCostSchema = yup
  .string()
  .trim()
  .required("Session cost is required");

export const paymentMethodSchema = yup
  .array()
  .min(1, "At least one payment method is required");

export const insurancePaymentMethodSchema = yup
  .array()
  .min(1, "At least one insurance payment method is required");

export const outOfNetworkSchema = yup
  .boolean()
  .oneOf([true], "You must accept clients claiming on their OON benefits");

export const clinicalSpecialitySchema = yup
  .array()
  .required("Atleast one clinical speciality is required")
  .min(1, "Atleast one clinical speciality is required");
export const clinicalExpertiseSchema = yup
  .array()
  .required("Atleast one clinical expertise is required")
  .min(1, "Atleast one clinical expertise is required");
export const clinicalExperienceSchema = yup
  .array()
  .required("Atleast one clinical expertise is required")
  .min(1, "Atleast one clinical experience is required");

export const TreatmentModalitySchema = yup
  .array()
  .required("Atleast one treatment modality is required")
  .min(1, "Atleast one treatment modality is required");
export const TreatmentExpertiseSchema = yup
  .array()
  .required("Atleast one treatment expertise is required")
  .min(1, "Atleast one treatment expertise is required");
export const TreatmentExperienceSchema = yup
  .array()
  .required("Atleast one treatment experience is required")
  .min(1, "Atleast one treatment experience is required");
export const urlSchema = yup
  .string()
  .matches(
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/\S*)?$/,
    "Please enter a valid URL"
  )
  .required("URL is required");
export const textSchema = yup
  .string()
  .required("Text to be displayed is required")
  .min(5, "Text must have minimum 5 characters");
export const participantsSchema = yup
  .array()
  .min(1, "At least one participant is required")
  .required();

export const agesSchema = yup
  .array()
  .min(1, "At least one age group is required")
  .required();

export const ethnicitySchema = yup
  .array()
  .min(1, "At least one ethnicity is required")
  .required();

export const uploadFileSchema = yup
  .mixed()
  .required("File is required")
  .test("fileSize", "File size is too large", (value) => {
    return value && value[0] && value[0].size <= 5000000; // 5MB limit
  })
  .test("fileType", "Unsupported file format", (value) => {
    return (
      value &&
      value[0] &&
      ["image/jpeg", "image/png", "image/jpg"].includes(value[0].type)
    );
  });
export const captionSchema = yup.string().required("Caption text is required");
export const descriptionSchema = yup
  .string()
  .required("Description is required");
export const AltSchema = yup.string().required("Alternative text is required");
export const sharedExperienceSchema = yup
  .string()
  .required("Shared experience is required")
  .min(5, "Shared experience must have minimum 5 characters");
export const npiNumberSchema = yup
  .string()
  .matches(/^\d{10}$/, "NPI number must be exactly 10 digits")
  .required("NPI number is required");
export const certificationNumberSchema = yup
  .string()
  .trim()
  .required("Certification number is required")
  .matches(licneseNumberPattern, "Please enter a valid  certification number")
  .min(5, "Certification number must have minimum 5 characters");
export const insuranceExpiryDateSchema = yup
  .string()
  .trim()
  .required("Expiry date is required");
export const designationSchema = yup
  .string()
  .trim()
  .required("Designation is required");
export const appointmentModeSchema = yup
  .array()
  .min(1, "Please select atleast one appointment mode")
  .required("Appointment mode is required");

export const personalStatementSchema = yup
  .string()
  .trim()
  .required("Personal statement is required");
export const isLicenseAvailableSchema = yup
  .string()
  .trim()
  .required("Please select an option");
export const supervisorSchema = yup
  .string()
  .trim()
  .required("Supervisor name is required");
export const supervisorLicenseSchema = yup
  .string()
  .trim()
  .required("Supervisor license number is required")
  .matches(
    licneseNumberPattern,
    "Please enter a valid  Supervisor license number"
  )
  .min(5, "Supervisor license number must have minimum 5 characters");
export const supervisorLicenseStateSchema = yup
  .string()
  .trim()
  .required("Supervisor license sate is required");
export const supervisorLicenseExpMonthSchema = yup
  .string()
  .trim()
  .required("Supervisor license expiry month is required");

export const supervisorLicenseExpYearSchema = yup
  .string()
  .trim()
  .required("Supervisor license expiry year is required");

export const preLicenseNumber = yup.string().when("pre_license_info", {
  is: (val: boolean) => {
    return val == true;
  },
  then: (s) => {
    return s
      .required("License number is required")
      .matches(licneseNumberPattern, "Please enter a valid license number")
      .min(5, "License number must have minimum 5 characters");
  },
  otherwise: (s) => s.nullable(),
});
export const otherDropDownSchema = yup
  .string()
  .trim()
  .required("Please select an option");

export const acceptTermsSchema = yup
  .boolean()
  .required("You must accept the terms and conditions")
  .oneOf([true], "You must accept the terms and conditions");
export const preLicenseSchema = yup.boolean().optional();

export const phoneNumberSchema = yup
  .string()
  .trim()
  .test("is-valid-phone", "Invalid phone number", (value) =>
    validatePhoneNumber(value)
  )
  .required("Mobile number is required");

export const availablitySchema = yup
  .string()
  .trim()
  .required("Mobile number is required");

export const primaryLocationSchema = yup.object().shape({
  address: yup.string().required("Primary location address is required"),
  latitude: yup.number().required("Latitude of location is required"),
  longitude: yup.number().required("Longitude of location is required"),
});

export const secondaryLocationSchema = yup.object().shape({
  address: yup.string().required("Secondary location address is required"),
  latitude: yup.number().required("Latitude of location is required"),
  longitude: yup.number().required("Longitude of location is required"),
});

export const addIdentityAgeSchema = yup
  .number()
  .transform((value, originalValue) => {
    return originalValue.trim() === "" ? undefined : value;
  })
  .required("Age is required")
  .min(18, "Age must be above 18")
  .max(100, "Age must not exceed 100")
  .typeError("Age must be a number");

export const addIdentityGenderSchema = yup
  .string()
  .required("Gender is requied");
export const addIdentityRaceSchema = yup.string().required("Race is requied");
export const addIdentityFaithSchema = yup.string().required("Faith is requied");
export const addIdentitySexualitySchema = yup
  .string()
  .required("Sexuality is requied");
