import React from 'react';
import { routes } from '../../../../utils/routes';
import { Link } from 'react-router-dom';
interface typePropDash {
  typeProp: string;
}
const EmergencyDetail: React.FC<typePropDash> = ({ typeProp = 'Upcoming' }) => {
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid pb-0">
          {/* Doctor Dashboard */}
          <div className="row">
            <div className="col-xl-12 col-lg-12">
              <div className="card">
                <div className="card-body vitals-patient-card-body">
                  <div className="vitals-patient-list">
                    <ul className="nav">
                      <li>
                        <span>Patient:</span> John Doe
                      </li>
                      <li>
                        <span>Age:</span> 42
                      </li>
                      <li>
                        <span>Blood Group:</span> O-ve
                      </li>
                      <li>
                        <span>Gender:</span> M
                      </li>
                      <li>
                        <span>Condition:</span> Heart Palpitations, Sweating,
                        Heavy Breathing, Discomfort in chest, Nausea
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5 col-lg-12 d-flex">
                <div className="card vitals-video-card w-100">
                  <div className="card-body"></div>
                </div>
              </div>
              <div className="col-xl-7 col-lg-12">
                <div className="doctor-header vitals-doctor-header">
                  <h4>Vitals</h4>
                  <ul className="nav">
                    <li>
                      <Link
                        to={routes.emergencyList.path}
                        className="btn btn-primary"
                      >
                        Back
                      </Link>
                    </li>
                  </ul>
                </div>

                {/* Card for Vitals */}
                <div className="card">
                  <div className="card-body">
                    {/* Vitals Header Row */}
                    <div className="row">
                      <div className="col-xl-7 col-lg-12">
                        <div className="prescription-table prescription-table-info prescription-table-infos">
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th>BP:</th>
                                  <th>Heart Rate:</th>
                                  <th>Temperature:</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Vitals Data Row */}
                    {/* <div className="d-flex justify-content-between p-2">
                            <span>120/80 mmHg</span>
                            <span>75 bpm</span>
                            <span>98.6°F</span>
                          </div> */}
                  </div>
                </div>

                {/* Speak Button */}
                <div className="press-btn">
                  <a href="javascript:void(0);" className="btn btn-primary">
                    Press to Speak
                  </a>
                </div>
              </div>
            </div>
          </div>
          {/* /Doctor Dashboard */}
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EmergencyDetail;
