import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  registerData: {},
  userDetails: {},
  editData: {},
  token: "",
  userDirectoryDetails: {},
};

const CommonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    // Auth related
    setRegisterData: (state, { payload }) => {
      state.registerData = payload;
    },
    setLoginAuthToken: (state, { payload }) => {
      state.token = payload;
    },
    removeAuthToken: (state) => {
      state.token = "";
      localStorage.removeItem("token");
      window.location.href = "/provider/login";
    },
    setUserDetails: (state, { payload }) => {
      state.userDetails = payload;
    },
    setEditData: (state, { payload }) => {
      state.editData = payload;
    },
    setUserDirectoryDetails: (state, { payload }) => {
      state.userDirectoryDetails = payload;
    },
  },
});

export const {
  setRegisterData,
  setUserDetails,
  setEditData,
  setLoginAuthToken,
  removeAuthToken,
  setUserDirectoryDetails,
} = CommonSlice.actions;

export default CommonSlice.reducer;
