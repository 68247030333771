import React from 'react'
import { profileTag, viewIcon17, viewIcon18, viewIcon19, viewProfile } from '../../../../utils/imagepath'
import { Link } from 'react-router-dom'

const Subscribe = () => {
  return (
    <>  <div className="modal fade subscribe-modal" id="subscribe-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="subscribe-modal-header">
          <div className="subscribe-modal-content">
            <div className="view-profile-left">
              <div className="view-profile-head">
                <ul className="nav">
                  <li>
                    <h4>
                      Dr. Susan Reynolds, MD
                      <img src={profileTag} alt="Icon" />
                    </h4>
                  </li>
                  <li>
                    <ul className="rating">
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <span>(58 reviews)</span>
                      </li>
                    </ul>
                  </li>
                </ul>
              </div>
              <div className="view-profile-psychiatrist">
                <p>Psychiatrist</p>
                <p>123 Wellness St, Suite 200, Springfield, IL</p>
                <ul className="nav">
                  <li>
                    <img src={viewIcon17} alt="Icon" /> In-Person
                  </li>
                  <li>
                    <img src={viewIcon18} alt="Icon" /> Virtual Consultations
                  </li>
                  <li>
                    <img src={viewIcon19} alt="Icon" /> 555-123-4567
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="subscribe-close">
            <button type="button" className="btn" data-bs-dismiss="modal">
              <i className="ti ti-x" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="subscribe-modal-grid">
            <div className="subscribe-grid-img">
              <img src={viewProfile} className="img-fluid" alt="Profile" />	
            </div>	
            <div className="subscribe-grid-text">
              <p>Your health is your most valuable asset; invest in it wisely and consistently. Together, we can achieve a balanced and vibrant life- <span>Dr. Susan Reynolds</span></p>
            </div>
          </div>	
          <div className="subscribe-modal-list">
            <h4>Gain exclusive insights when you subscribe</h4>
            <ul className="nav">
              <li>
                <i className="fas fa-check" /> access your personal dashboard, calendar, and events
              </li>
              <li>
                <i className="fas fa-check" /> follow your favorite providers
              </li>
              <li>
                <i className="fas fa-check" /> watch and read free content
              </li>
              <li>
                <i className="fas fa-check" /> subscribe to your favorite providers for a no-ad experience and unlimited content
              </li>
            </ul>
          </div>	
          <div className="subscribe-modal-btn">
            <Link to="#" className="btn btn-primary rounded" data-bs-toggle="modal" data-bs-target="#plans-modal">
              Subscribe
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div></>
  )
}

export default Subscribe