import React from "react";
import { Link, useLocation } from "react-router-dom";
import { logoWhite } from "../../utils/imagepath";

const ClientFooter = () => {
  const location = useLocation();
  const pathname: any = location?.pathname;

  return (
    <>
      {/*Footer */}
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-12">
                <div className="footer-signup">
                  <div className="footer-breadcrumb">
                    <ul>
                      <li>
                        <Link to="#" className="active">
                          Home
                        </Link>
                      </li>
                      <li>
                        {pathname.includes("/view-profile") ? (
                          <Link to="#">Individual provider page</Link>
                        ) : (
                          <Link to="#">Blogs</Link>
                        )}
                      </li>
                    </ul>
                  </div>
                  <h6>Be The First To Know.</h6>
                  <p>
                    Sign up to get news, insights, and more from TurningWell.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="footer-subscription">
                  <div className="subscription-input">
                    <input type="text" placeholder="Enter your email..." />
                    <Link to="#" className="btn btn-subscribe">
                      Subscribe
                    </Link>
                  </div>
                  <p>
                    By clicking subscribe you're confirming that you agree with
                    our <Link to="#">privacy policy.</Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-logo">
                  <img src={logoWhite} className="logo-white" alt="img" />
                </div>
                <div className="copy-right">
                  <h6>© 2024 TurningWell. All rights reserved</h6>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h6>Product</h6>
                  <ul>
                    <li>
                      <Link to="#">Home</Link>
                    </li>
                    <li>
                      <Link to="#">Directory</Link>
                    </li>
                    <li>
                      <Link to="#">List your practice</Link>
                    </li>
                    <li>
                      <Link to="#">Resources</Link>
                    </li>
                    <li>
                      <Link to="#">Releases</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h6>Company</h6>
                  <ul>
                    <li>
                      <Link to="#">About</Link>
                    </li>
                    <li>
                      <Link to="#">Press</Link>
                    </li>
                    <li>
                      <Link to="#">Careers</Link>
                    </li>
                    <li>
                      <Link to="#">Contact</Link>
                    </li>
                    <li>
                      <Link to="#">Partners</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h6>Support</h6>
                  <ul>
                    <li>
                      <Link to="#">Help Center</Link>
                    </li>
                    <li>
                      <Link to="#">Terms of service</Link>
                    </li>
                    <li>
                      <Link to="#">Legal</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Status</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-6 col-12">
                <div className="footer-widget">
                  <h6>Social</h6>
                  <ul>
                    <li>
                      <Link to="#">Facebook</Link>
                    </li>
                    <li>
                      <Link to="#">Twitter</Link>
                    </li>
                    <li>
                      <Link to="#">Instagram</Link>
                    </li>
                    <li>
                      <Link to="#">LinkedIn</Link>
                    </li>
                    <li>
                      <Link to="#">Youtube</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/*/footer */}
    </>
  );
};

export default ClientFooter;
