import React, { useState } from "react";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";

const Review = () => {
  const [rangeText, setRangeText] = useState("");
  const [view, setView] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };

  const viewOptions = [
    { label: "Most Recent", value: "most_recent" },
    { label: "Most Viewed", value: "most_viewed" },
  ];

  const options = [
    { label: "Weekly", value: "weekly" },
    { label: "Inprogress", value: "inprogress" },
  ];

  const [value, setValue] = useState([
    "Emilywright@gmail.com",
    "Jake Reynolds",
  ]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Directory</Link>
              </li>
              <li>Review</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Request Reviews Via Email</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Sender email</label>
                        <input
                          type="text"
                          placeholder="Enter Your Email"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Recipient</label>
                        <Chips
                          className="w-100"
                          value={value}
                          onChange={(e: any) => setValue(e.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3">
                        <label className="form-label">Email Subject</label>
                        <input
                          type="text"
                          placeholder="Enter subject"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 col-12">
                      <div className="mb-3">
                        <label className="form-label">
                          Frequency of Email Send-Out
                        </label>
                        <Dropdown
                          value={selectedOption}
                          options={options}
                          onChange={(e) => setSelectedOption(e.value)}
                          placeholder="Select"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Email Messages</label>
                        <textarea
                          className="form-control"
                          placeholder="Type your message here"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-start mt-3">
                        <Link to="#" className="btn btn-submit">
                          Send Email
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4>Email Log</h4>
                  <div className="filter-div">
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar" />
                        </span>
                        <DateRangePicker initialSettings={initialSettings}>
                          <input
                            type="text"
                            className="form-control bookingrange"
                            value={
                              rangeText ||
                              `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                            }
                            readOnly
                          />
                        </DateRangePicker>
                      </div>
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Descending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Viewed
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Added
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="table-responsive">
                    <table className="table table-email">
                      <thead>
                        <tr>
                          <th>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </th>
                          <th>Recipient Email</th>
                          <th>Reminder</th>
                          <th>Date Sent</th>
                          <th>Request Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>example@example.com</td>
                          <td>Every 3 days</td>
                          <td>May 19, 2024</td>
                          <td>
                            <span className="badge-content-white dot-danger">
                              Not Started
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>another@example.com</td>
                          <td>Every 3 days</td>
                          <td>May 19, 2024</td>
                          <td>
                            <span className="badge-content-white dot-posted">
                              Completed
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>richie@example.com</td>
                          <td>Once a month</td>
                          <td>May 30, 2024</td>
                          <td>
                            <span className="badge-content-white dot-review">
                              Started
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>example@example.com</td>
                          <td>Every 3 days</td>
                          <td>May 19, 2024</td>
                          <td>
                            <span className="badge-content-white dot-danger">
                              Not Started
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>another@example.com</td>
                          <td>Every 3 days</td>
                          <td>May 19, 2024</td>
                          <td>
                            <span className="badge-content-white dot-posted">
                              Completed
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <label className="ms-auto custom_check">
                              <input type="checkbox" />
                              <span className="checkmark" />
                            </label>
                          </td>
                          <td>richie@example.com</td>
                          <td>Once a month</td>
                          <td>May 30, 2024</td>
                          <td>
                            <span className="badge-content-white dot-review">
                              Started
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-calendar" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-x" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-div">
                    <Link to="#" className="previous-next-link disabled">
                      <i className="ti ti-chevron-left me-1" />
                      Previous
                    </Link>
                    <ul>
                      <li>
                        <Link to="#" className="active">
                          1
                        </Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">3</Link>
                      </li>
                      <li>
                        <Link to="#">...</Link>
                      </li>
                      <li>
                        <Link to="#">8</Link>
                      </li>
                      <li>
                        <Link to="#">9</Link>
                      </li>
                      <li>
                        <Link to="#">10</Link>
                      </li>
                    </ul>
                    <Link to="#" className="previous-next-link">
                      Next
                      <i className="ti ti-chevron-right ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4>Reviews</h4>
                  <Link
                    to="#"
                    className="btn btn-sm ms-auto btn-outline-primary"
                  >
                    <i className="ti ti-upload me-2" />
                    Export All Reviews
                  </Link>
                </div>
                <div className="card-body pt-2">
                  <div className="rating-highlights">
                    <div className="row">
                      <div className="col-md-4">
                        <div className="rating-patients h-100 flex-fill flex-style">
                          <div className="count-start">
                            <h2>4.8</h2>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                          </div>
                          <div className="info-end">
                            <p>Insights from Patients</p>
                            <h4>overall Rating</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="rating-clients h-100 flex-fill flex-style">
                          <div className="count-start h-100">
                            <h2>58</h2>
                          </div>
                          <div className="info-end">
                            <p>Here From our Clients</p>
                            <h4>Total Reviews</h4>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="rating-clients h-100 flex-fill flex-style">
                          <div className="count-start  flex-column border-0">
                            <h2>
                              48
                              <span>
                                <i className="ti ti-trending-up" />
                              </span>
                            </h2>
                            <h6>Positive Reviews</h6>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="rating-clients h-100 flex-fill flex-style">
                          <div className="count-start  flex-column border-0">
                            <h2>10</h2>
                            <h6>Negative Reviews</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-header">
                  <h4>Latest Reviews</h4>
                  <div className="select-head">
                    <Dropdown
                      value={view}
                      options={viewOptions}
                      onChange={(e) => setView(e.value)}
                      placeholder="Select"
                      className="select w-100 chart-dropdown"
                    />
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              5 days ago
                              <i className="mx-2 ti ti-point-filled" />
                              Lisa
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            Dr. Reynolds listened to my concerns and created a
                            personalized treatment plan. I felt supported
                            throughout my journey. Highly recommend!
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              5 days ago
                              <i className="mx-2 ti ti-point-filled" />
                              Lisa
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            Dr. Reynolds listened to my concerns and created a
                            personalized treatment plan. I felt supported
                            throughout my journey. Highly recommend!
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              5 days ago
                              <i className="mx-2 ti ti-point-filled" />
                              Lisa
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            Dr. Reynolds listened to my concerns and created a
                            personalized treatment plan. I felt supported
                            throughout my journey. Highly recommend!
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              April 1, 2024
                              <i className="mx-2 ti ti-point-filled" />
                              Tony Starc
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            After years of struggling with anxiety, I found
                            relief with Dr. Reynolds' help. She provided the
                            guidance and tools I needed to take control of my
                            life.
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              March 24, 2024
                              <i className="mx-2 ti ti-point-filled" />
                              Tony Starc
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            Dr. Reynolds has a deep understanding of mental
                            health. Her compassionate approach and expertise
                            made a huge difference in my recovery.
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              March 24, 2024
                              <i className="mx-2 ti ti-point-filled" />
                              Tony Starc
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            It's rare to find a psychiatrist who genuinely
                            cares. Dr. Reynolds made me feel comfortable and
                            respected. She's a trustworthy partner in mental
                            health.It's rare to find a psychiatrist who
                            genuinely cares. Dr. Reynolds made me feel
                            comfortable and respected. She's a trustworthy
                            partner in mental health.
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="review-div">
                        <div className="review-date">
                          <div className="rating-name">
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                            </ul>
                            <span className="flex-style">
                              5 days ago
                              <i className="mx-2 ti ti-point-filled" />
                              Lisa
                            </span>
                          </div>
                          <div className="review-chat">
                            <ul>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-message" />
                                </Link>
                              </li>
                              <li>
                                <Link to="#">
                                  <i className="ti ti-flag" />
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="review-content">
                          <p>
                            Dr. Reynolds listened to my concerns and created a
                            personalized treatment plan. I felt supported
                            throughout my journey. Highly recommend!
                          </p>
                        </div>
                        <div className="review-featured">
                          <Link to="#">
                            <i className="ti ti-star-filled me-2" />
                            Make Featured
                          </Link>
                        </div>
                      </div>
                      <div className="pagination-div">
                        <Link to="#" className="previous-next-link disabled">
                          <i className="ti ti-chevron-left me-1" />
                          Previous
                        </Link>
                        <ul>
                          <li>
                            <Link to="#" className="active">
                              1
                            </Link>
                          </li>
                          <li>
                            <Link to="#">2</Link>
                          </li>
                          <li>
                            <Link to="#">3</Link>
                          </li>
                          <li>
                            <Link to="#">...</Link>
                          </li>
                          <li>
                            <Link to="#">8</Link>
                          </li>
                          <li>
                            <Link to="#">9</Link>
                          </li>
                          <li>
                            <Link to="#">10</Link>
                          </li>
                        </ul>
                        <Link to="#" className="previous-next-link">
                          Next
                          <i className="ti ti-chevron-right ms-1" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Review;
