import React, { useEffect, useState } from "react";
import { credentialsProvider, logo } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { getData, postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { routes } from "../../utils/routes/routes";
import { yup } from "../../utils/shared.module";
import ErrorMessage from "../../components/errorMessage";

import {
  alphaNumeric,
  currentYear,
  filteredMonthOptions,
  licenseMaxLength,
  licneseNumberPattern,
  monthOptions,
  nameMaxLength,
  onlyAlphabet,
  yearOptions,
} from "../../utils/patterns/regex.pattern";
import {
  acceptTermsSchema,
  clinicalRoleSchema,
  clinicalTypeSchema,
  dropdownSchema,
  licenseExpMonthSchema,
  licenseExpYearSchema,
  licenseNumberSchema,
  licenseStateSchema,
  nameSchema,
  preLicenseNumberSchema,
  preLicenseSchema,
} from "./components/yupSchema";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setRegisterData } from "../../core/redux/commonSlice";
import {
  authLicenseOption,
  clinicalRoleTypeOption,
} from "../../utils/constants/index.";
import TermsAndConditionsModel from "./components/termsAndConditions";
import states from "../../global/stateList";
const formSchema = yup.object({
  clinicalRole: clinicalRoleSchema,
  // dropdown: dropdownSchema,
  licenseNumber: licenseNumberSchema,
  licenseState: licenseStateSchema,
  licenseExpMonth: licenseExpMonthSchema,
  licenseExpYear: licenseExpYearSchema,
  acceptTerms: acceptTermsSchema,
});
const formSchemaTwo = yup.object({
  clinicalRoleTwo: clinicalRoleSchema,
  // dropdown: dropdownSchema,
  licenseNumberTwo: licenseNumberSchema,
  licenseStateTwo: licenseStateSchema,
  licenseExpMonthTwo: licenseExpMonthSchema,
  licenseExpYearTwo: licenseExpYearSchema,
  acceptTermsTwo: preLicenseSchema,
  supervisorName: nameSchema,
  preLicenseNumber: preLicenseNumberSchema,
});
const formSchemaThree = yup.object({
  clinicalRoleThree: clinicalRoleSchema,
  clinicalType: clinicalTypeSchema,
});
const CredentialsProvider = () => {
  const dispatch = useDispatch();
  const registerData = useSelector((state: any) => state.common.registerData);
  const licensed = registerData?.credentialProvider?.license;
  const [clinicalRoleOption, setClinicalRoleOption] = useState<any>(null);
  const [license, setLicense] = useState(
    licensed ? licensed : authLicenseOption.license
  );
  const [showTerms, setShowTerms] = useState(false);
  const getFormSchema: any = (license: string) => {
    if (license === authLicenseOption.license) {
      return formSchema;
    } else if (license === authLicenseOption.pre_License) {
      return formSchemaTwo;
    } else {
      return formSchemaThree;
    }
  };
  const getSelectedCountry = () => {
    return registerData?.provider?.country
      ? registerData?.provider?.country?.toLowerCase()
      : "in";
  };
  const getStates = (country: any) => {
    const code =
      typeof country === "string" ? country.toLocaleLowerCase() : country.code;

    return states[code] || [];
  };
  let stateList = getStates(getSelectedCountry());
  const stateListOption = stateList.map((state: any) => {
    return { label: state, value: state };
  });

  const clinicalList = async () => {
    try {
      const response = await getData(end_points.masterClinicalRoleApi.url);
      if (response.status === 200) {
        const option = response?.data?.data?.map((val: any) => {
          return { label: val?.name || "", value: val?._id || "" };
        });
        setClinicalRoleOption(option);
      }
    } catch (error) {}
  };
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(getFormSchema(license)),
    defaultValues: {
      clinicalRole: "",
      clinicalType: "",
      acceptTerms: false,
      clinicalRoleThree: "",
      licenseNumber: "",
      licenseState: "",
      licenseExpMonth: "",
      licenseExpYear: "",
      acceptTermsTwo: false,
      clinicalRoleTwo: "",
      licenseNumberTwo: "",
      licenseStateTwo: "",
      licenseExpMonthTwo: "",
      licenseExpYearTwo: "",
      supervisorName: "",
      preLicenseNumber: null,
    },
  });
  const navigate = useNavigate();
  const selectedYear = watch("licenseExpYear");
  const watchlicenseExpYearTwo = watch("licenseExpYearTwo");
  const watchPreLicenses = watch("acceptTermsTwo");

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const updateData: any = {
        ...registerData,
        credentialProvider: {
          ...registerData.credentialProvider,
          ...data,
          license: license,
        },
      };
      dispatch(setRegisterData(updateData));
    } catch (error) {
    } finally {
      hideLoader();
      navigate(routes.billingProvider);
    }
  };

  useEffect(() => {
    if (registerData?.credentialProvider) {
      const {
        clinicalRole,
        licenseNumber,
        licenseState,
        licenseExpMonth,
        licenseExpYear,
        acceptTerms,
        clinicalRoleTwo,
        licenseNumberTwo,
        licenseStateTwo,
        licenseExpMonthTwo,
        licenseExpYearTwo,
        acceptTermsTwo,
        supervisorName,
        clinicalType,
        clinicalRoleThree,
        preLicenseNumber,
      } = registerData.credentialProvider;
      if (license === authLicenseOption.license) {
        setValue("acceptTerms", acceptTerms);
        setValue("clinicalRole", clinicalRole);
        setValue("licenseNumber", licenseNumber);
        setValue("licenseState", licenseState);
        setValue("licenseExpMonth", licenseExpMonth);
        setValue("licenseExpYear", licenseExpYear);
        trigger([
          "clinicalRole",
          "licenseNumber",
          "licenseState",
          "licenseExpMonth",
          "licenseExpYear",
          "acceptTerms",
        ]);
        setValue("supervisorName", "");
        setValue("clinicalRoleTwo", "");
        setValue("licenseNumberTwo", "");
        setValue("licenseStateTwo", "");
        setValue("licenseExpMonthTwo", "");
        setValue("licenseExpYearTwo", "");
        setValue("clinicalType", "");
        setValue("clinicalRoleThree", "");
        setValue("preLicenseNumber", null);

        trigger([
          "supervisorName",
          "clinicalRoleTwo",
          "licenseNumberTwo",
          "licenseStateTwo",
          "licenseExpMonthTwo",
          "licenseExpYearTwo",
          "acceptTermsTwo",
          "clinicalType",
          "clinicalRoleThree",
          "preLicenseNumber",
        ]);
      } else if (supervisorName && license === authLicenseOption.pre_License) {
        setValue("supervisorName", supervisorName);
        trigger("supervisorName");
        setValue("acceptTerms", false);
        setValue("clinicalRole", "");
        setValue("licenseNumber", "");
        setValue("licenseState", "");
        setValue("licenseExpMonth", "");
        setValue("licenseExpYear", "");

        setValue("acceptTermsTwo", acceptTermsTwo);
        setValue("clinicalRoleTwo", clinicalRoleTwo);
        setValue("licenseNumberTwo", licenseNumberTwo);
        setValue("licenseStateTwo", licenseStateTwo);
        setValue("licenseExpMonthTwo", licenseExpMonthTwo);
        setValue("licenseExpYearTwo", licenseExpYearTwo);
        setValue("preLicenseNumber", preLicenseNumber);

        trigger([
          "clinicalRoleTwo",
          "licenseNumberTwo",
          "licenseStateTwo",
          "licenseExpMonthTwo",
          "licenseExpYearTwo",
          "acceptTermsTwo",
          "preLicenseNumber",
        ]);
      } else if (
        clinicalType &&
        license === authLicenseOption.certified_License
      ) {
        setValue("clinicalType", clinicalType);
        trigger("clinicalType");
        setValue("acceptTerms", acceptTerms);
        setValue("clinicalRoleThree", clinicalRoleThree);
        setValue("licenseNumber", "");
        setValue("licenseState", "");
        setValue("licenseExpMonth", "");
        setValue("licenseExpYear", "");
        setValue("acceptTermsTwo", false);
        setValue("clinicalRoleTwo", "");
        setValue("licenseNumberTwo", "");
        setValue("licenseStateTwo", "");
        setValue("licenseExpMonthTwo", "");
        setValue("licenseExpYearTwo", "");
        setValue("preLicenseNumber", null);
      }
    }
  }, [registerData?.credentialProvider]);

  const [selectedLicenseState, setSelectedLicenseState] = useState(null);

  const licenseState = [
    { label: "--Select One--", value: "1" },
    { label: "--Select Two--", value: "2" },
  ];
  useEffect(() => {
    clinicalList();
  }, []);

  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap">
              <div className="login-header m-0">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body pt-1">
                <div className="login-title">
                  <h4>Credentials</h4>
                  <p>
                    Include just one credential here so we can verify you.
                    Later, you will be able to add more.
                  </p>
                </div>
                <div className="custom-radio mb-0">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="form-check custom-cred">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio1"
                          value={authLicenseOption.license}
                          checked={license === authLicenseOption.license}
                          onChange={(e: any) => {
                            setLicense(e.target.value);
                            clearErrors();
                            reset();
                          }}
                          name="optradio"
                          defaultValue="option1"
                          defaultChecked
                        />
                        <label className="form-check-label" htmlFor="radio1">
                          I am licensed, my license information is below
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-check custom-cred">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio2"
                          value={authLicenseOption.pre_License}
                          checked={license === authLicenseOption.pre_License}
                          onChange={(e: any) => {
                            setLicense(e.target.value);
                            clearErrors();
                            reset();
                          }}
                          name="optradio"
                          defaultValue="option2"
                        />
                        <label className="form-check-label" htmlFor="radio2">
                          I am pre-licensed or under supervision
                        </label>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-check custom-cred">
                        <input
                          type="radio"
                          className="form-check-input"
                          id="radio3"
                          value={authLicenseOption.certified_License}
                          onChange={(e: any) => {
                            setLicense(e.target.value);
                            clearErrors();
                            reset();
                          }}
                          checked={
                            license === authLicenseOption.certified_License
                          }
                          name="optradio"
                          defaultValue="option3"
                        />
                        <label className="form-check-label" htmlFor="radio3">
                          I have a certification. My profession had no license.
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Login Form */}
                {license === authLicenseOption.license && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-set">
                          <label>Clincial Role</label>
                          <Controller
                            name="clinicalRole"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={
                                  clinicalRoleOption ? clinicalRoleOption : []
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("clinicalRole"); // Trigger validation onChange
                                }}
                                placeholder="Select one"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage value={errors.clinicalRole} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-set">
                          <label>License Number</label>
                          <Controller
                            name="licenseNumber"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your license number"
                                {...field}
                                onKeyDown={(event) => {
                                  const regex = licneseNumberPattern;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={licenseMaxLength}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.licenseNumber} />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-set">
                          <label>License State</label>
                          <Controller
                            name="licenseState"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={stateListOption}
                                filter={
                                  stateListOption?.length > 0 ? true : false
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("licenseState");
                                }}
                                placeholder="Select one"
                                className="select w-100"
                              />
                            )}
                          />

                          <ErrorMessage value={errors.licenseState} />
                        </div>
                      </div>
                    </div>
                    <div className="form-set mb-0">
                      <label>License Expiration</label>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-set mb-0">
                            <Controller
                              name="licenseExpMonth"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  value={field.value}
                                  options={
                                    selectedYear
                                      ? filteredMonthOptions(
                                          Number(selectedYear)
                                        )
                                      : monthOptions
                                  }
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    trigger("licenseExpMonth");
                                  }}
                                  placeholder="Month"
                                  className="select w-100"
                                />
                              )}
                            />
                            <ErrorMessage value={errors.licenseExpMonth} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-set mb-0">
                            <Controller
                              name="licenseExpYear"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  value={field.value}
                                  options={yearOptions}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    if (
                                      Number(e.value) === Number(currentYear)
                                    ) {
                                      const month = filteredMonthOptions(
                                        Number(currentYear)
                                      ).map((val) => val.value);
                                      if (
                                        !month.includes(
                                          getValues("licenseExpMonth")
                                        )
                                      )
                                        setValue("licenseExpMonth", "");
                                      trigger("licenseExpMonth");
                                    }
                                    trigger("licenseExpYear");
                                  }}
                                  placeholder="Year"
                                  className="select w-100"
                                />
                              )}
                            />
                            <ErrorMessage value={errors.licenseExpYear} />
                          </div>
                        </div>
                        <div className="col-12 mt-4 d-flex align-items-start">
                          <label className="custom_check ps-4">
                            <Controller
                              name="acceptTerms"
                              control={control}
                              render={({ field }) => (
                                <input
                                  title="Terms and Conditions"
                                  type="checkbox"
                                  {...field}
                                  checked={field.value}
                                  onChange={(e) => {
                                    field.onChange(e.target.checked);
                                    trigger("acceptTerms");
                                  }}
                                />
                              )}
                            />
                            <span className="checkmark" />
                          </label>
                          <span className="accrpt-set">
                            Accept{" "}
                            <Link
                              to="#"
                              onClick={() => {
                                setShowTerms(true);
                              }}
                            >
                              Terms and Conditions
                            </Link>
                          </span>
                        </div>
                        <ErrorMessage value={errors.acceptTerms} />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 login-btn"
                      type="submit"
                    >
                      Next
                    </button>
                  </form>
                )}
                {license === authLicenseOption.pre_License && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-set">
                          <label>Clincial Role</label>
                          <Controller
                            name="clinicalRoleTwo"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={
                                  clinicalRoleOption ? clinicalRoleOption : []
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("clinicalRoleTwo"); // Trigger validation onChange
                                }}
                                placeholder="Select One"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage value={errors.clinicalRoleTwo} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-set">
                          <label>Supervisor's Name</label>
                          {/* <input type="text" className="form-control" /> */}
                          <Controller
                            name="supervisorName"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your name"
                                {...field}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabet;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.supervisorName} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-set">
                          <label>Supervisor's License Number</label>
                          {/* <input type="text" className="form-control" /> */}
                          <Controller
                            name="licenseNumberTwo"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your license number"
                                {...field}
                                onKeyDown={(event) => {
                                  const regex = licneseNumberPattern;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={licenseMaxLength}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.licenseNumberTwo} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-set">
                          <label>Supervisor's License State</label>
                          <Controller
                            name="licenseStateTwo"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={stateListOption}
                                filter={
                                  stateListOption?.length > 0 ? true : false
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("licenseStateTwo");
                                }}
                                placeholder="Select One"
                                className="select w-100"
                              />
                            )}
                          />

                          <ErrorMessage value={errors.licenseStateTwo} />
                        </div>
                      </div>
                    </div>
                    <div className="form-set mb-0">
                      <label>Supervisor's License Expiration</label>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-set mb-0">
                            <Controller
                              name="licenseExpMonthTwo"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  value={field.value}
                                  options={
                                    watchlicenseExpYearTwo
                                      ? filteredMonthOptions(
                                          Number(watchlicenseExpYearTwo)
                                        )
                                      : monthOptions
                                  }
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    trigger("licenseExpMonthTwo");
                                  }}
                                  placeholder="Month"
                                  className="select w-100"
                                />
                              )}
                            />
                            <ErrorMessage value={errors.licenseExpMonthTwo} />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-set mb-0">
                            <Controller
                              name="licenseExpYearTwo"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  value={field.value}
                                  options={yearOptions}
                                  onChange={(e) => {
                                    field.onChange(e.value);
                                    if (
                                      Number(e.value) === Number(currentYear)
                                    ) {
                                      const month = filteredMonthOptions(
                                        Number(currentYear)
                                      ).map((val) => val.value);
                                      if (
                                        !month.includes(
                                          getValues("licenseExpMonthTwo")
                                        )
                                      )
                                        setValue("licenseExpMonthTwo", "");
                                      trigger("licenseExpMonthTwo");
                                    }
                                    trigger("licenseExpYearTwo");
                                  }}
                                  placeholder="Year"
                                  className="select w-100"
                                />
                              )}
                            />
                            <ErrorMessage value={errors.licenseExpYearTwo} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="licenese-Bottom mt-4">
                      <div className="col-12  d-flex align-items-start licenese-check">
                        <label className="custom_check ps-4">
                          <Controller
                            name="acceptTermsTwo"
                            control={control}
                            render={({ field }) => (
                              <input
                                title="Enter my pre-license info"
                                type="checkbox"
                                {...field}
                                checked={field.value}
                                onChange={(e) => {
                                  field.onChange(e.target.checked);
                                  setValue("preLicenseNumber", null);
                                  if (e.target.checked && !watchPreLicenses) {
                                    setValue("preLicenseNumber", "");
                                  }
                                  trigger("preLicenseNumber");
                                  trigger("acceptTermsTwo");
                                  trigger("preLicenseNumber");
                                }}
                              />
                            )}
                          />
                          <span className="checkmark" />
                        </label>
                        <span className="accrpt-set">
                          Enter my pre-license info
                        </span>
                      </div>
                      <ErrorMessage value={errors.acceptTermsTwo} />
                      <div className="col-12 licenese-input">
                        <div className="form-set">
                          <label>License Number</label>
                          <Controller
                            name="preLicenseNumber"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                disabled={!watchPreLicenses}
                                placeholder="Enter your license number"
                                {...field}
                                value={field.value ? field.value : ""}
                                onKeyDown={(event) => {
                                  const regex = licneseNumberPattern;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={licenseMaxLength}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.preLicenseNumber} />
                        </div>
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 login-btn"
                      type="submit"
                    >
                      Next
                    </button>
                  </form>
                )}
                {license === authLicenseOption.certified_License && (
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-12">
                        <div className="form-set">
                          <label>Clincial Role</label>
                          <Controller
                            name="clinicalRoleThree"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={
                                  clinicalRoleOption ? clinicalRoleOption : []
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("clinicalRoleThree"); // Trigger validation onChange
                                }}
                                placeholder="Select One"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage value={errors.clinicalRoleThree} />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-set">
                          <label>Credential Type</label>
                          <p>
                            Choose an alternate Credential, If you have one{" "}
                          </p>
                          <Controller
                            name="clinicalType"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={clinicalRoleTypeOption}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("clinicalType"); // Trigger validation onChange
                                }}
                                placeholder="Select One"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage value={errors.clinicalType} />
                        </div>
                      </div>
                      <p className="mb-1">
                        Don't see your certification listed here?
                      </p>
                      <p className="mb-1">
                        Please send your contact and certification information
                        to{" "}
                        <Link to="#" className="span-mail">
                          credentialing@turningwell.com
                        </Link>{" "}
                      </p>
                    </div>
                    <button
                      className="btn btn-primary w-100 login-btn"
                      type="submit"
                    >
                      Next
                    </button>
                  </form>
                )}
                {/* /Login Form */}
              </div>
              <div className="progress-blk five-steps align-center mt-3">
                <ul className="list-unstyled">
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="active">
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={credentialsProvider} alt="" />
        </div>
      </div>
      {/* /Login Section */}
      <TermsAndConditionsModel
        visible={showTerms}
        onCancel={() => {
          setShowTerms(false);
        }}
        title={"Terms and Conditions"}
      />
    </>
  );
};

export default CredentialsProvider;
