import React, { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import store from "../core/redux/store";
import { routes } from "../utils/routes/routes";

const Authenticate = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const auth = localStorage.getItem("token");
  const userDetails = useSelector((state) => state.common.userDetails);
  const twoFaEnabled = userDetails?.two_factor_authentication;
  const twoFaStatus = userDetails?.two_factor_authentication_status;
  const path = location?.pathname;
  const currentPath = path.split("/")[1];
  useEffect(() => {
    if (!auth) {
      if (currentPath === "provider" && !routes.verifyEmail) {
        return navigate(routes.providerLogin, { replace: true });
      } else if (currentPath === "client") {
        return navigate(routes.clientLogin, { replace: true });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [path]);
  return props.children;
};

export default Authenticate;
