import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  textLength,
  yearMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import {
  degreeSchema,
  educationSchema,
  yearBeganPractiseSchema,
  yearGraduatedSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { getData, postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: any;
}

const EducationModal = (props: props) => {
  const educationRef = useRef();
  const { type, open, data, edit_type } = props.visible;
  const { getDirectoryData, directoryData } = props;
  const userDetails = useSelector((state: any) => state.common.userDetails);
  const [educationDetails, setEducationDetails] = useState<any>();

  const initialValues = {
    education: "",
    degree: "",
    year_graduated: "",
    year_began_practice: "",
  };

  const educationModelSchema = Yup.object().shape({
    education: educationSchema,
    degree: degreeSchema,
    year_graduated: yearGraduatedSchema,
    year_began_practice: yearBeganPractiseSchema,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(educationModelSchema),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset(initialValues);
    educationRef.current.click();
    props.handleClose();
  };

  useMemo(() => {
    // if (educationDetails) {
    setValue("education", directoryData?.education?.school);
    setValue("degree", directoryData?.education?.degree);
    setValue("year_graduated", directoryData?.education?.year_graduated);
    setValue(
      "year_began_practice",
      directoryData?.education?.year_began_practice
    );
    // }
  }, [directoryData]);

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        education: {
          school: data?.education,
          degree: data?.degree,
          year_graduated: data?.year_graduated,
          year_began_practice: data?.year_began_practice,
        },
      };

      const response = await postData(
        `${end_points.userprofile_education.url}`,
        payload
      );

      if (response.status == 201) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <div
        className="modal fade credential-modal finances-modal"
        id="education-modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">Education and Years in Practice</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={educationRef}
                onClick={OnClose}
              />
            </div>
            <div className="modal-body">
              <form action="#" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="form-wrap">
                  <label>Education School</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    {...register("education")}
                  />
                  <ErrorMessage value={errors.education} />
                </div>
                <div className="form-wrap">
                  <label>Degree/Diploma:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder=""
                    {...register("degree")}
                    maxLength={textLength}
                  />
                  <ErrorMessage value={errors.degree} />
                </div>
                <div className="form-wrap">
                  <label>Year Graduated:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={""}
                    {...register("year_graduated")}
                    onKeyDown={(e) => {
                      const key = e.key;
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        " ",
                      ];
                      if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const year = parseInt(value, 10);
                      const currentYear = new Date().getFullYear();

                      if (!isNaN(year) && year > currentYear) {
                        setValue("year_graduated", currentYear.toString());
                      } else {
                        setValue("year_graduated", value);
                      }
                    }}
                    maxLength={yearMaxLength}
                  />
                  <ErrorMessage value={errors.year_graduated} />
                </div>
                <div className="form-wrap">
                  <label>Year Began Practice:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder={""}
                    {...register("year_began_practice")}
                    onKeyDown={(e) => {
                      const key = e.key;
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        " ",
                      ];
                      if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
                        e.preventDefault();
                      }
                    }}
                    onChange={(e) => {
                      const value = e.target.value;
                      const year = parseInt(value, 10);
                      const currentYear = new Date().getFullYear();

                      if (!isNaN(year) && year > currentYear) {
                        setValue("year_began_practice", currentYear.toString());
                      } else {
                        setValue("year_began_practice", value);
                      }
                    }}
                    maxLength={yearMaxLength}
                  />
                  <ErrorMessage value={errors.year_began_practice} />
                </div>
                <div className="form-wrap mb-0">
                  <button className="btn btn-primary w-100" type="submit">
                    {directoryData?.education?.degree !== "" &&
                    directoryData?.education?.degree !== undefined
                      ? "Update Now"
                      : "Add Now"}
                  </button>
                  <button
                    className="btn btn-cancel w-100"
                    type="button"
                    onClick={OnClose}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EducationModal;
