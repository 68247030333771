import { Paginator } from "primereact/paginator";
import React from "react";

interface CustomPaginatorProps {
  currentPage: number;
  totalPages: number;
  rows: number;
  totalRecords: number;
  onPageChange: (newPage: number) => void;
}

const CustomPaginator: React.FC<CustomPaginatorProps> = ({
  currentPage,
  totalPages,
  totalRecords,
  rows,
  onPageChange,
}) => {
  const handlePaginatorChange = (event: any) => {
    onPageChange(event.page + 1);
  };

  return (
    <>
      {totalPages ? (
        <>
          <Paginator
            first={(currentPage - 1) * rows}
            rows={rows}
            totalRecords={totalRecords}
            onPageChange={handlePaginatorChange}
          />
        </>
      ) : null}
    </>
  );
};

export default CustomPaginator;
