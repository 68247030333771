import { forgotPassword, logo } from "../../utils/imagepath";
import { emailSchema } from "./components/yupSchema";
import ErrorMessage from "../../components/errorMessage";
import { emailMaxLength } from "../../utils/patterns/regex.pattern";
import { translate } from "../../utils/translate/translate";
import { module } from "../../utils/translate/const";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { yup } from "../../utils/shared.module";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { routes } from "../../utils/routes/routes";

export const formSchema = yup.object({
  email: emailSchema,
});

const ForgotPassword = () => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    formState: { errors },
    trigger,
  } = useForm({
    defaultValues: { email: "" },
    resolver: yupResolver(formSchema),
  });
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const formData = new FormData();
      formData.append("email", data?.email);
      const response = await postData(
        end_points.forgot_password_mail?.url,
        formData
      );
      if (response?.status === 201) {
        navigate(routes.providerLogin);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-lg-12">
            <div className="login-wrap forget-set">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body">
                <div className="login-title">
                  <h4>
                    {translate(
                      "forgot_Password",
                      module.login,
                      "Forgot Password"
                    )}
                  </h4>
                  <p>
                    {translate(
                      "forgot_Password_Description",
                      module.login,
                      "Don't worry, happens to the best of us.Type your email to reset your password."
                    )}
                  </p>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-set">
                    <label>
                      {translate("email_Label", module.login, "Email")}
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder={translate(
                            "email_Placeholder",
                            module.login,
                            "Enter your email"
                          )}
                          {...field}
                          maxLength={emailMaxLength}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.email} />
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    {translate("send_Now", module.login, "Send Now")}
                  </button>
                </form>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={forgotPassword} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default ForgotPassword;
