import React, { forwardRef } from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';

interface ScreenshotModalProps {
  screenshot: string | null;
  control: Control<any>;
  errors: FieldErrors;
  trigger: any;
  onSubmit: () => void;
}

const ScreenshotModal = forwardRef<HTMLButtonElement, ScreenshotModalProps>(
  ({ screenshot, control, errors, trigger, onSubmit }, ref) => {
    return (
      <div
        className="modal custom-modal fade"
        id="screenshot_modal"
        data-keyboard="false"
        data-backdrop="static"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content profile-modal-content">
            <div className="modal-body profile-modal-body">
              {screenshot && (
                <div className="profile-modal-img">
                  <img
                    src={screenshot}
                    className="img-fluid"
                    alt="Screenshot preview"
                  />
                </div>
              )}

              <div className="profile-modal-form">
                <form onSubmit={onSubmit}>
                  <div className="form-wrap">
                    <div className="row align-items-center">
                      <div className="col-md-4">
                        <label htmlFor="screenshot-description">
                          Description
                        </label>
                      </div>
                      <div className="col-md-8">
                        <div className="profile-modal-input">
                          <Controller
                            name="description"
                            control={control}
                            render={({ field }) => (
                              <div>
                                <input
                                  {...field}
                                  id="screenshot-description"
                                  type="text"
                                  placeholder="Enter screenshot description"
                                  className={`form-control ${
                                    errors.description ? 'is-invalid' : ''
                                  }`}
                                  onBlur={() => trigger('description')}
                                  onChange={(e) => {
                                    field.onChange(e);
                                    trigger('description');
                                  }}
                                  maxLength={100}
                                  aria-describedby={
                                    errors.description
                                      ? 'description-error'
                                      : undefined
                                  }
                                />
                                {errors.description && (
                                  <small
                                    id="description-error"
                                    className="text-danger d-inline-flex mt-2"
                                    role="alert"
                                  >
                                    {errors.description.message as string}
                                  </small>
                                )}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile-modal-btn">
                    <button type="submit" className="btn btn-primary me-2">
                      Save Screenshot
                    </button>
                    <button
                      ref={ref}
                      type="button"
                      className="btn btn-secondary"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

ScreenshotModal.displayName = 'ScreenshotModal';

export default ScreenshotModal;
