import React, { useEffect, useState } from "react";
import Footer from "../../common/provider-footer";
import ProviderAccountSettings from "./account-settings";
import LicenseInsuranceSettings from "./license-insurance";
import ProviderParentalControls from "./parental-controls";
import ProviderSubscriptionPlans from "./subscription-plan";
import { Link, useLocation } from "react-router-dom";

const Settings = () => {
  const location = useLocation();
  const clickInsuranceButton = location.state?.from === "SubmitInformation";

  useEffect(() => {
    if (clickInsuranceButton) {
      const dropdownInsuranceButton = document.getElementById(
        "license-insurance-tab"
      );
      if (dropdownInsuranceButton) {
        dropdownInsuranceButton.click();
      }
    }
  }, [clickInsuranceButton]);
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>Settings</li>
                  </ul>
                </div>
              </div>
              <div className="tab-set tab-set-info">
                <nav className>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="account-settings-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#account-settings"
                      type="button"
                      role="tab"
                      aria-controls="account-settings"
                      aria-selected="true"
                    >
                      Account Settings
                    </button>
                    <button
                      className="nav-link"
                      id="license-insurance-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#license-insurance"
                      type="button"
                      role="tab"
                      aria-controls="license-insurance"
                      aria-selected="false"
                    >
                      License &amp; Insurance
                    </button>
                    <button
                      className="nav-link"
                      id="parental-controls-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#parental-controls"
                      type="button"
                      role="tab"
                      aria-controls="parental-controls"
                      aria-selected="false"
                    >
                      Parental Controls
                    </button>
                    <button
                      className="nav-link"
                      id="subscription-plan-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#subscription-plan"
                      type="button"
                      role="tab"
                      aria-controls="subscription-plan"
                      aria-selected="false"
                    >
                      Subscription Plan
                    </button>
                  </div>
                </nav>
                <div className="tab-content p-0" id="nav-tabContent">
                  <ProviderAccountSettings />
                  <LicenseInsuranceSettings />
                  <ProviderParentalControls />
                  <ProviderSubscriptionPlans />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Settings;
