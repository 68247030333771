import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  nameMaxLength,
  onlyAlphabet,
  personalInformationMaxLength,
  stringAllowedKeys,
} from "../../../../utils/patterns/regex.pattern";
import {
  appointmentModeSchema,
  designationSchema,
  nameSchema,
  personalStatementSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
}

const BasicModal = (props: props) => {
  const basicModalRef = useRef();
  const { designationsList, getDirectoryData } = props;
  const { type, open, data, userDetails } = props.visible;

  const psychiatristOptions = [
    { label: "Psychiatrist", value: "Psychiatrist" },
    { label: "Psychiatrist 02", value: "Psychiatrist 02" },
  ];

  const initialValues = {
    fullname: "",
    designation: "",
    appointment_mode: [],
    personal_statement: "",
  };

  const basicModelValidation = Yup.object().shape({
    fullname: nameSchema,
    designation: designationSchema,
    appointment_mode: appointmentModeSchema,
    personal_statement: personalStatementSchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicModelValidation),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        ...data,
      };
      const response = await putData(
        `${end_points.profile_about_personal_info.url}`,
        payload
      );
      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
        basicModalRef.current.click();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  const HandleMode = (value: string) => {
    if (getValues("appointment_mode")?.includes(value)) {
      setValue(
        "appointment_mode",
        getValues("appointment_mode")?.filter((it: any) => it !== value)
      );
    } else {
      setValue(
        "appointment_mode",
        getValues("appointment_mode")?.concat(value)
      );
    }
    trigger("appointment_mode");
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data)?.length > 0) {
      reset(data);
    }
  }, [data]);

  return (
    <>
      <div
        className="modal fade basic-modal"
        id="basic-modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">Basic Information</h5>
                <p>
                  Provide details about yourself and any other partinent
                  information.
                </p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={basicModalRef}
              />
            </div>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <label>Full Name</label>
                <Controller
                  name="fullname"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className={`form-control`}
                      placeholder="Full name"
                      {...register("fullname")}
                      onKeyDown={(event) => {
                        const regex = onlyAlphabet;
                        if (
                          !regex.test(event.key) &&
                          event.key !== "Backspace"
                        ) {
                          event.preventDefault();
                        }
                      }}
                      maxLength={nameMaxLength}
                    />
                  )}
                />

                <ErrorMessage value={errors.fullname} />
              </div>
              <div className="form-wrap">
                <label>Designation</label>
                <Controller
                  name="designation"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      value={field.value}
                      options={designationsList}
                      onChange={(e) => field.onChange(e.value)}
                      placeholder="Select"
                      className={`select w-100 ${errors.designation ? "is-invalid" : ""}`}
                    />
                  )}
                />
                <ErrorMessage value={errors.designation} />
              </div>
              <div className="form-wrap">
                <label>Appointment Mode</label>
                <Controller
                  name="appointment_mode"
                  control={control}
                  render={({ field }) => (
                    <div className="filter-div">
                      <ul className="filter-select">
                        <li onClick={() => HandleMode("in-person")}>
                          <Link
                            to="#"
                            className={
                              field.value?.includes("in-person") ? "active" : ""
                            }
                          >
                            {field.value?.includes("in-person") && (
                              <i className={`ti ti-check me-2`} />
                            )}
                            In-Person
                          </Link>
                        </li>
                        <li onClick={() => HandleMode("virtual-consultation")}>
                          <Link
                            to="#"
                            className={
                              field.value?.includes("virtual-consultation")
                                ? "active"
                                : ""
                            }
                          >
                            {field.value?.includes("virtual-consultation") && (
                              <i className={`ti ti-check me-2`} />
                            )}
                            Virtual Consultations
                          </Link>
                        </li>
                      </ul>
                      {errors.appointment_mode && (
                        <ErrorMessage value={errors.appointment_mode} />
                      )}
                    </div>
                  )}
                />
              </div>
              <div className="form-wrap form-wrap-textarea">
                <label>Personal Statement</label>
                <textarea
                  className={`form-control`}
                  placeholder="personal statement"
                  {...register("personal_statement")}
                  maxLength={personalInformationMaxLength}
                />
                <ErrorMessage value={errors.personal_statement} />
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary rounded w-100" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default BasicModal;
