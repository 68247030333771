import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  avatar01,
  avatarDefault,
  logo,
  logoWhite,
} from "../../utils/imagepath";
import { capitalizeFirstLetter } from "../../utils/constants/index.";
import { useSelector } from "react-redux";
import { removeAuthToken } from "../../core/redux/commonSlice";
import { useDispatch } from "react-redux";
import { routes } from "../../utils/routes/routes";

const ClientHeader = () => {
  const [headerClass, setHeaderClass] = useState("header");
  const [search, setSearch] = useState(false);
  const openSearch = () => {
    setSearch(!search);
  };
  const dispatch = useDispatch();
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const removeLoginToken = () => {
    dispatch(removeAuthToken());
  };
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const scrollThreshold = 100;

      if (scrollPosition > scrollThreshold) {
        setHeaderClass("header fixed");
      } else {
        setHeaderClass("header");
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const location = useLocation();
  const pathname: any = location?.pathname;

  return (
    <>
      {" "}
      {/* Header */}
      <header className={headerClass}>
        <div className="offcanvas-info">
          <div className="offcanvas-wrap">
            <div className="offcanvas-detail">
              <div className="offcanvas-head d-flex justify-content-between align-items-center mb-3">
                <a href={routes.providerIndex}>
                  <img src={logoWhite} className="mobile-logo" alt="logo-img" />
                </a>
                <div className="offcanvas-close">
                  <i className="ti ti-x" />
                </div>
              </div>
              <div className="mobile-menu fix mb-3" />
              <div className="offcanvas__contact">
                <div className="mt-4 d-none">
                  <Link to="#" className="btn  w-100 mb-2">
                    <i className="ti ti-lock me-2" />
                    Sign In
                  </Link>
                  <Link to="#" className="btn btn-linear-primary w-100">
                    <i className="ti ti-user-filled me-2" />
                    Join Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-overlay" />
        <div className="container">
          <div className="header-nav">
            <div className="main-menu-wrapper">
              <div className="navbar-logo">
                <a className="header-logo" href={routes.providerIndex}>
                  <img src={logo} className="logo" alt="Logo" />
                </a>
              </div>
              <nav id="mobile-menu">
                <ul className="main-nav">
                  <li>
                    <a href={routes.providerIndex}>Home</a>
                  </li>
                  <li className="has-submenu">
                    <Link to="#">
                      Directory
                      <i className="ti ti-chevron-down" />
                    </Link>
                  </li>
                  <li className="has-submenu">
                    <Link to="#">
                      Providers <i className="ti ti-chevron-down" />
                    </Link>
                    <ul className="submenu">
                      <li>
                        <Link to="#">List Your Practice</Link>
                      </li>
                      <li>
                        <Link to="#">Continued Education</Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Blog
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="#">
                      Events
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="header-btn ">
                <div className="searchbar">
                  <Link to="#" onClick={openSearch}>
                    <i className="ti ti-search" />
                  </Link>
                </div>
                <Link to="#" className="btn">
                  <i className="ti ti-wallet" />
                </Link>
                <div className="dropdown">
                  {pathname.includes("/client/index") ? (
                    <>
                      <Link
                        to="#"
                        className="btn dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i className="ti ti-bell" />
                        <span className="nofication-active"></span>
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link
                        to="#"
                        className="btn"
                        data-bs-toggle="dropdown"
                        aria-expanded="true"
                      >
                        <i className="ti ti-bell" />
                      </Link>
                    </>
                  )}
                  <div className="dropdown-menu notifications d-none">
                    <div className="topnav-dropdown-header">
                      <span className="notification-title">Notifications</span>
                      <Link to="#" className="clear-noti">
                        {" "}
                        Clear All{" "}
                      </Link>
                    </div>
                    <div className="noti-content">
                      <ul className="notification-list">
                        <li className="notification-message">
                          <Link to="activities.html">
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={avatar01} />
                              </span>
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">John Doe</span>{" "}
                                  added new task{" "}
                                  <span className="noti-title">
                                    Patient appointment booking
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    4 mins ago
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li className="notification-message">
                          <Link to="activities.html">
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={avatar01} />
                              </span>
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    Tarah Shropshire
                                  </span>
                                  changed the task name{" "}
                                  <span className="noti-title">
                                    Appointment booking with payment gateway
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    6 mins ago
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li className="notification-message">
                          <Link to="activities.html">
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={avatar01} />
                              </span>
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    Misty Tison
                                  </span>
                                  added{" "}
                                  <span className="noti-title">
                                    Domenic Houston
                                  </span>{" "}
                                  and{" "}
                                  <span className="noti-title">
                                    Claire Mapes
                                  </span>{" "}
                                  to project{" "}
                                  <span className="noti-title">
                                    Doctor available module
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    8 mins ago
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li className="notification-message">
                          <Link to="activities.html">
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={avatar01} />
                              </span>
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    Rolland Webber
                                  </span>
                                  completed task{" "}
                                  <span className="noti-title">
                                    Patient and Doctor video conferencing
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    12 mins ago
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                        <li className="notification-message">
                          <Link to="activities.html">
                            <div className="media d-flex">
                              <span className="avatar flex-shrink-0">
                                <img alt="" src={avatar01} />
                              </span>
                              <div className="media-body flex-grow-1">
                                <p className="noti-details">
                                  <span className="noti-title">
                                    Bernardo Galaviz
                                  </span>
                                  added new task{" "}
                                  <span className="noti-title">
                                    Private chat module
                                  </span>
                                </p>
                                <p className="noti-time">
                                  <span className="notification-time">
                                    2 days ago
                                  </span>
                                </p>
                              </div>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="topnav-dropdown-footer">
                      <Link to="activities.html">View all Notifications</Link>
                    </div>
                  </div>
                </div>
                <div className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="booking-user ">
                      <span className="user-name">
                        {capitalizeFirstLetter(userDetails?.first_name)}{" "}
                        {capitalizeFirstLetter(userDetails?.last_name)}
                      </span>
                      <span className="user-img d-flex align-items-center">
                        <img src={avatarDefault} alt="user" />
                        <i className="ti ti-caret-down-filled ms-1" />
                      </span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu p-2">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <i className="ti ti-settings me-1" />
                        Settings
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                        onClick={() => {
                          removeLoginToken();
                        }}
                      >
                        <i className="ti ti-logout me-1" />
                        Logout
                      </Link>
                    </li>
                  </ul>
                </div>
                <div className="header__hamburger d-lg-none my-auto">
                  <div className="sidebar-menu">
                    <i className="ti ti-menu-2" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* /Header */}
      <div
        className={`search-popup js-search-popup ${search ? "visible" : ""}`}
      >
        <Link
          to="#"
          className="close-button"
          id="search-close"
          aria-label="Close search"
          onClick={openSearch}
        >
          <i className="ti ti-x" />
        </Link>
        <div className="popup-inner">
          <div className="inner-container">
            <form className="search-form" id="search-form" action="#">
              <h3>What Are You Looking for?</h3>
              <div className="search-form-box flex">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Type a  Keyword...."
                  id="search-input"
                  aria-label="Type to search"
                  role="searchbox"
                  autoComplete="off"
                />
                <button type="submit" className="search-icon">
                  <i className="ti ti-search" />
                </button>
              </div>
              <h5>Popular Search</h5>
              <ul>
                <li>
                  <Link to="#">Depression </Link>
                </li>
                <li>
                  <Link to="#">blogs </Link>
                </li>
                <li>
                  <Link to="#">Mental </Link>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClientHeader;
