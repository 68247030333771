import React, { createContext, useState, useCallback, useContext } from "react";

interface SpinnerContextType {
  isLoading: boolean;
  showLoaderInner: () => void;
  hideLoaderInner: () => void;
}

interface Props {
  children: React.ReactNode;
}

export const SpinnerContext = createContext<SpinnerContextType>({
  isLoading: false,
  showLoaderInner: () => {},
  hideLoaderInner: () => {},
});

export const useSpinner = () => useContext(SpinnerContext);

let externalShowLoader: (msg: string) => void;
let externalHideLoader: () => void;

const SpinnerProvider: React.FC<Props> = ({ children }) => {
  const [loaderCount, setLoaderCount] = useState(0);
  const [loaderMsg, setLoaderMsg] = useState("");

  const showLoaderInner = useCallback((msg = "") => {
    msg && setLoaderMsg(msg);
    setLoaderCount((prevCount) => prevCount + 1);
  }, []);

  const hideLoaderInner = useCallback(() => {
    setTimeout(() => {
      // setLoaderCount((prevCount) => Math.max(prevCount - 1, 0));
      setLoaderCount(0);
      setLoaderMsg("");
    }, 500);
  }, []);

  externalShowLoader = showLoaderInner;
  externalHideLoader = hideLoaderInner;

  return (
    <SpinnerContext.Provider
      value={{
        isLoading: loaderCount > 0,
        showLoaderInner,
        hideLoaderInner,
      }}
    >
      {children}
      {loaderCount > 0 && (
        <>
          <div className="loading loaderInitialHeight flex-column">
            <div className="loaderInitial"></div>
            {loaderMsg && (
              <div className="mt-3">
                <h6>{loaderMsg}</h6>
              </div>
            )}
          </div>
        </>
      )}
    </SpinnerContext.Provider>
  );
};

// Export the functions for direct use
export const showLoader = (msg = "") =>
  externalShowLoader && externalShowLoader(msg);
export const hideLoader = () => externalHideLoader && externalHideLoader();

export default SpinnerProvider;
