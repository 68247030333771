import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  banner1,
  blog01,
  blog02,
  blog03,
  blog04,
  blog05,
  blog06,
  blog07,
  blog08,
  blog09,
  blog10,
  blog11,
  blog12,
  blog13,
  blog14,
  blog15,
} from "../../utils/imagepath";
import OwlCarousel from "react-owl-carousel";
import Progress from "../../components/progress/progress";

const ClientIndex = () => {
  const depressionSlider = {
    loop: false,
    margin: 24,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    autoplay: false,
    animateOut: "slideOutUp",
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
 

  return (
    <>
      {" "}
      {/*banner */}
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div
                className="banner-content wow fadeInUp"
                data-wow-delay="0.3s"
              >
                <ul>
                  <li>
                    <Link to="#">Depression</Link>
                  </li>
                  <li>
                    <Link to="#">Just Posted</Link>
                  </li>
                  <li>
                    <Link to="#">Olivia Rhye</Link>
                  </li>
                </ul>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Understanding Mental Health: A Guide for Beginners
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.9s">
                  Mental health encompasses emotional, psychological, and social
                  well-being. It affects how we think, feel, and act.
                  Understanding its importance is key to addressing mental
                  health disorders and promoting overall wellness.
                </p>
                <Link
                  to="#"
                  className="read-more wow fadeInUp text-links"
                  data-wow-delay="1.2s"
                >
                  Read More <i className="ti ti-arrow-right  ms-2" />
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="banner-img  wow zoomIn">
                <img src={banner1} alt="img" />
              </div>
              <div className="row">
                <div className="col-lg-8 col-12 ms-auto  wow fadeInUp">
                  <div className="banner-search">
                    <input type="text" placeholder="Search..." />
                    <Link to="#">
                      <i className="ti ti-search" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/banner */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-header">
                <h3>Latest Depression Blog</h3>
              </div>
            </div>
            <div className="depression-slider owl-dotdiv wow fadeInUp">
              <OwlCarousel {...depressionSlider}>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog01} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog02} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog03} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog01} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="section-header wow fadeInUp">
                <h3>Featured Depression Blogs</h3>
              </div>
            </div>
            <div className="depression-slider owl-dotdiv wow fadeInUp">
              <OwlCarousel {...depressionSlider}>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog04} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog05} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog06} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog04} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header">
                <h3>Recommended Depression blogs</h3>
              </div>
            </div>
            <div className="depression-slider owl-dotdiv wow fadeInUp">
              <OwlCarousel {...depressionSlider}>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog07} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog08} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog09} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog07} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header">
                <h3>Top Depression Blogs</h3>
              </div>
            </div>
            <div className="depression-slider owl-dotdiv wow fadeInUp">
              <OwlCarousel {...depressionSlider}>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog10} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog11} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog12} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog10} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header">
                <h3>Trending Depression Blogs</h3>
              </div>
            </div>
            <div className="depression-slider owl-dotdiv wow fadeInUp">
              <OwlCarousel {...depressionSlider}>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog13} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog14} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog15} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>{" "}
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
                <div className="blog-div">
                  <div className="blog-img">
                    <img src={blog13} alt="img" />
                  </div>
                  <div className="blog-content">
                    <h6>
                      April 30, 2024 <span>Depression</span>
                    </h6>
                    <h5>
                      <Link to="#">
                        Navigating Mental Health Challenges Strategies
                      </Link>
                    </h5>
                    <p>
                      An introductory video explaining the basics of mental
                      health, discussing its importance, and dispelling common
                      myths.
                    </p>
                  </div>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*join */}
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="join-div wow zoomIn">
                <h6>Join TurningWell</h6>
                <p>
                  Sign up now to get unrestricted access to TurningWell's online
                  mental health directory, resources, and more!
                </p>
                <Link to="#" className="btn btn-signup rounded">
                  Sign up now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/join */}
    </>
  );
};

export default ClientIndex;
