import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  programInstitutionSchema,
  programTypeSchema,
  yearGraduatedSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  onlyAlphabet,
  textLength,
} from "../../../../utils/patterns/regex.pattern";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import { useEffect, useMemo, useRef } from "react";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
}

const FellowshipsModal = (props: props) => {
  const fellowshipref = useRef();
  const { type, open, data, userDetails, edit_type } = props.visible;
  const { getDirectoryData } = props;
  const Data = data;
  const UserDetails = useSelector((state: any) => state.common.userDetails);

  const OnClose = () => {
    reset();
    fellowshipref.current.click();
    props.handleClose();
  };

  const initialValues = {
    program_type: "",
    program_institution: "",
    year_of_graduation: "",
  };

  const internshipSchema = Yup.object().shape({
    program_type: programTypeSchema,
    program_institution: programInstitutionSchema,
    year_of_graduation: yearGraduatedSchema,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(internshipSchema),
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (data: any) => {
    if (edit_type == "add") {
      try {
        showLoader();
        const payload = {
          fellow_ships: [
            {
              program_type: data?.program_type,
              program_institution: data?.program_institution,
              year_graduation: data?.year_of_graduation,
            },
          ],
        };

        const response = await postData(
          `${end_points.userprofile_fellowships.url}`,
          payload
        );
        if (response.status == 201) {
          getDirectoryData();
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    } else {
      try {
        showLoader();
        const payload = {
          fellow_ships: {
            program_type: data?.program_type,
            program_institution: data?.program_institution,
            year_graduation: data?.year_of_graduation,
          },
        };

        const response = await putData(
          `${end_points.userprofile_fellowships_update.url}/${Data?._id}`,
          payload
        );
        if (response.status == 201) {
          getDirectoryData();
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    }
  };

  useEffect(() => {
    if (edit_type == "edit") {
      setValue("program_type", data?.program_type);
      setValue("program_institution", data?.program_institution);
      setValue("year_of_graduation", data?.year_graduation);
    } else {
      reset(initialValues);
    }
  }, [data, edit_type]);

  return (
    <div
      className="modal fade credential-modal finances-modal"
      id="fellow-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Fellowships</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={fellowshipref}
              onClick={OnClose}
            />
          </div>
          <div className="modal-body">
            <form action="#" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <label>Program Type:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Orthopedic Surgery Residency"
                  {...register("program_type")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!onlyAlphabet.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage value={errors.program_type} />
              </div>
              <div className="form-wrap">
                <label>Program Institution:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="State University Medical Center"
                  {...register("program_institution")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!onlyAlphabet.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage value={errors.program_institution} />
              </div>
              <div className="form-wrap">
                <label>Year of Graduation:</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"2014"}
                  {...register("year_of_graduation")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    const year = parseInt(value, 10);
                    const currentYear = new Date().getFullYear();

                    if (!isNaN(year) && year > currentYear) {
                      e.target.value = currentYear.toString();
                    }
                  }}
                  maxLength={4}
                />
                <ErrorMessage value={errors.year_of_graduation} />
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  {Data !== undefined && Object.keys(Data).length
                    ? "Update Now"
                    : "Add Now"}
                </button>
                <button className="btn btn-cancel w-100" onClick={OnClose}>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FellowshipsModal;
