import React, { useState } from "react";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import { useSelector } from "react-redux";
import { postData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { toast } from "react-toastify";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";

const CardRegister = ({
  setCardSucessDetails = (data: any) => {},
  onSubmit = (date: any) => {},
  payload = {},
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState<any>("");
  const registerData = useSelector((state: any) => state.common.registerData);
  const planDetails = registerData.selectedPlan;
  const email = registerData?.provider?.email;
  const fullName =
    registerData?.locationProvider?.firstName +
    " " +
    registerData?.locationProvider?.lastName;

  // State management for form fields
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    priceId: "",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle input changes
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e: any) => {
    e.preventDefault();
    setErrorMessage("");
    if (!stripe || !elements) {
      return;
    }

    setIsSubmitting(true);
    showLoader(
      "Your payment is being processed. Please do not refresh or close the page until the process is complete."
    );

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      setIsSubmitting(false);
      hideLoader();
      return;
    }
    try {
      const { paymentMethod, error: paymentError } =
        await stripe.createPaymentMethod({
          type: "card",
          card: cardElement,
        });

      if (paymentError) {
        setErrorMessage(paymentError.message);
        setIsSubmitting(false);
        hideLoader();
        return;
      }
      const priceId = planDetails?.pricing_id;

      const data = {
        ...payload,
        name: fullName,
        email: email,
        plan_id: priceId,
        payment_method: paymentMethod.id,
      };
      showLoader(
        "Your payment is being processed. Please do not refresh or close the page until the process is complete."
      );

      // Step 1: Call backend to create customer, subscription, and initiate 3D Secure
      const response = await postData(end_points.createSubscriptionApi.url, {
        ...data,
      });
      if (response.status === 201) {
        const result = response.data?.data;

        if (result.clientSecret) {
          // Step 2: Confirm 3D Secure payment
          const { paymentIntent, error: confirmError } =
            await stripe.confirmCardPayment(result.clientSecret);
          if (confirmError) {
            hideLoader();
            hideLoader();
            // alert("Payment failed: " + confirmError.message);
            let errorMessage: string = `Payment failed: ${confirmError.message}`;
            toast.error(errorMessage);
            setIsSubmitting(false);
            return;
          } else if (paymentIntent && paymentIntent.status === "succeeded") {
            const confirmData: any = {
              name: fullName,
              email: email,
              stripeCustomerId: result.stripeCustomerId,
              stripeSubscriptionId: result.stripeSubscriptionId,
            };
            onSubmit(confirmData);
            setCardSucessDetails(confirmData);

            // cardElement.clear();
          }
        } else {
          toast.error(result.message);
        }
      }
    } catch (err) {
      console.error("Error:", err);
      toast.error("An unexpected error occurred.");
    } finally {
      setIsSubmitting(false);
      hideLoader();
      hideLoader();
    }
  };

  return (
    <>
      <div className="customStripe">
        <div id="card-element" className="mb-3 custom-stripe">
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: "16px",
                  color: "#424770",
                  "::placeholder": {
                    color: "#aab7c4",
                  },
                },
                invalid: {
                  color: "#9e2146",
                },
              },
            }}
          />
        </div>
        {errorMessage && <div className="text-danger">{errorMessage}</div>}
      </div>
      <button
        type="button"
        className="btn btn-primary w-100 login-btn"
        onClick={handleSubmit}
        disabled={!stripe || isSubmitting}
      >
        {isSubmitting ? "Processing..." : "Pay"}
      </button>
    </>
  );
};

export default CardRegister;
