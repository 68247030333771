import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import FeatureModule from "./feature-module/feature-module";
import { authRoutes, unAuthRoutes } from "./utils/routes/auth-path";
import UnAuthRoute from "./hooks/UnAuthRoute";
import Authenticate from "./hooks/AuntenticateRoute";
import { favicon } from "./utils/imagepath";

const auth = authRoutes;

const AppRouter: React.FC = () => {
  useEffect(() => {
    const faviconLink: any = document.getElementById(
      "favicon"
    ) as HTMLLinkElement;
    if (faviconLink) {
      faviconLink.href = favicon;
    } else {
      console.error("Favicon link element not found");
    }
  }, []);

  const [location, setLocation] = useState<any>({
    latitude: null,
    longitude: null,
  });
  const [error, setError] = useState<any>(null);
  const [permissionState, setPermissionState] = useState(""); // State to track permission status

  const getLocation = () => {
    console.log(navigator.geolocation, "navigator.geolocation");

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
          setError(null);
        },
        (error) => {
          switch (error.code) {
            case error.PERMISSION_DENIED:
              setError("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              setError("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              setError("The request to get user location timed out.");
              break;
            default:
              setError("An unknown error occurred.");
          }
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };
  const checkPermission = async () => {
    try {
      const result = await navigator.permissions.query({ name: "geolocation" });
      setPermissionState(result.state);

      result.onchange = () => {
        setPermissionState(result.state);
      };

      if (result.state === "granted") {
        getLocation();
      } else if (result.state === "prompt") {
        getLocation();
      } else if (result.state === "denied") {
        setError("Geolocation access denied by user.");
      }
    } catch (err) {
      setError("Error checking geolocation permission.");
    }
  };

  useEffect(() => {
    checkPermission();
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FeatureModule />}>
          {unAuthRoutes.map((item) => (
            <Route
              key={item.id}
              path={item.path}
              element={
                <UnAuthRoute>
                  <>{item.element}</>
                </UnAuthRoute>
              }
            />
          ))}
          {auth.map((item) => (
            <Route
              key={item.id}
              path={item.path}
              element={<Authenticate>{item.element}</Authenticate>}
            />
          ))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRouter;
