import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import ErrorMessage from "../../../../components/errorMessage";
import {
  agesSchema,
  ethnicitySchema,
  participantsSchema,
} from "../../../authentication/components/yupSchema";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";

interface Props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: any;
}

const CommunitiesModal = (props: Props) => {
  const communityRef = useRef();
  const { type, open, data, edit_type } = props.visible;
  const { getDirectoryData, directoryData } = props;
  const userDetails = useSelector((state: any) => state.common.userDetails);

  const initialValues = {
    participants: [],
    ages: [],
    ethnicity: [],
  };

  const validationSchema = Yup.object().shape({
    participants: participantsSchema,
    ages: agesSchema,
    ethnicity: ethnicitySchema,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: initialValues,
  });

  const [selectedParticipants, setSelectedParticipants] = useState<string[]>(
    []
  );
  const [selectedAges, setSelectedAges] = useState<string[]>([]);
  const [selectedEthnicity, setSelectedEthnicity] = useState<string[]>([]);

  const handleSelection = (item: string, type: string) => {
    let updatedSelection: string[] = [];

    if (type === "participants") {
      updatedSelection = selectedParticipants.includes(item)
        ? selectedParticipants.filter((p) => p !== item)
        : [...selectedParticipants, item];
      setSelectedParticipants(updatedSelection);
      setValue("participants", updatedSelection);
      trigger("participants");
    } else if (type === "ages") {
      updatedSelection = selectedAges.includes(item)
        ? selectedAges.filter((a) => a !== item)
        : [...selectedAges, item];
      setSelectedAges(updatedSelection);
      setValue("ages", updatedSelection);
      trigger("ages");
    } else if (type === "ethnicity") {
      updatedSelection = selectedEthnicity.includes(item)
        ? selectedEthnicity.filter((e) => e !== item)
        : [...selectedEthnicity, item];
      setSelectedEthnicity(updatedSelection);
      setValue("ethnicity", updatedSelection);
      trigger("ethnicity");
    }
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        communities_served: {
          participants: data.participants,
          age: data.ages,
          ethnicity_of_your_client: data.ethnicity,
        },
      };

      const response = await postData(
        `${end_points.userprofile_communities.url}`,
        payload
      );
      if (response.status === 201) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const OnClose = () => {
    reset();
    communityRef.current.click();
    props.handleClose();
  };

  useEffect(() => {
    console.log("DIRECTORY", directoryData?.communities_served);
    if (directoryData) {
      setSelectedParticipants(
        directoryData?.communities_served?.participants || []
      );
      setSelectedAges(directoryData?.communities_served?.age || []);
      setSelectedEthnicity(
        directoryData?.communities_served?.ethnicity_of_your_client || []
      );

      // Set the values for the form using react-hook-form
      setValue(
        "participants",
        directoryData?.communities_served?.participants || []
      );
      setValue("ages", directoryData?.communities_served?.age || []);
      setValue(
        "ethnicity",
        directoryData?.communities_served?.ethnicity_of_your_client || []
      );
    }
  }, [directoryData]);

  return (
    <div
      className="modal fade filter-modal"
      id="communities-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Communities Served</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={communityRef}
              onClick={OnClose}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>Participants</h6>
                    <ul className="filter-select-modal">
                      {["Individuals", "Team"].map((participant) => (
                        <li key={participant}>
                          <Link
                            to="#"
                            className={
                              selectedParticipants.includes(participant)
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleSelection(participant, "participants");
                            }}
                          >
                            <i className="ti ti-check me-2" />
                            {participant}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <ErrorMessage value={errors.participants} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>Ages</h6>
                    <ul className="filter-select-modal">
                      {[
                        "Infant",
                        "Toddler",
                        "Child (5-8)",
                        "Preteen (9-13)",
                        "Teen (13-18)",
                      ].map((age) => (
                        <li key={age}>
                          <Link
                            to="#"
                            className={
                              selectedAges.includes(age) ? "active" : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleSelection(age, "ages");
                            }}
                          >
                            <i className="ti ti-check me-2" />
                            {age}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <ErrorMessage value={errors.ages} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>Ethnicity of Your Clients</h6>
                    <ul className="filter-select-modal">
                      {["Asian", "Hispanic and Latino"].map((ethnicity) => (
                        <li key={ethnicity}>
                          <Link
                            to="#"
                            className={
                              selectedEthnicity.includes(ethnicity)
                                ? "active"
                                : ""
                            }
                            onClick={(e) => {
                              e.preventDefault();
                              handleSelection(ethnicity, "ethnicity");
                            }}
                          >
                            <i className="ti ti-check me-2" />
                            {ethnicity}
                          </Link>
                        </li>
                      ))}
                    </ul>
                    <ErrorMessage value={errors.ethnicity} />
                  </div>
                </div>
              </div>

              <div className="filter-modal-btns">
                <button className="btn btn-primary rounded" type="submit">
                  {directoryData?.communities_served !== undefined &&
                  Object.values(directoryData?.communities_served).flat()
                    .length > 1
                    ? "Update Specialities"
                    : "Add Specialitites"}
                </button>
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CommunitiesModal;
