import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import {
  SettingsLicenseOption,
  clinicalRoleTypeOption,
  professionOptions,
  selectHereOptions,
  selectOneOptions,
} from "../../../../utils/constants/index.";
import {
  filteredMonthOptions,
  licenseMaxLength,
  licneseNumberPattern,
  monthOptions,
  nameMaxLength,
  onlyAlphabet,
  onlyAplhaNumHyp,
  yearOptions,
} from "../../../../utils/patterns/regex.pattern";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { getData, postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { maxNpiNumber } from "../../../../utils/patterns/regex.pattern";
import ErrorMessage from "../../../../components/errorMessage";
import {
  clinicalRoleSchema,
  isLicenseAvailableSchema,
  otherDropDownSchema,
  preLicenseNumber,
  supervisorLicenseExpMonthSchema,
  supervisorLicenseExpYearSchema,
  supervisorLicenseSchema,
  supervisorLicenseStateSchema,
  supervisorSchema,
  acceptTermsSchema,
  clinicalTypeSchema,
  dropdownSchema,
  licenseExpMonthSchema,
  licenseExpYearSchema,
  licenseNumberSchema,
  licenseStateSchema,
  nameSchema,
} from "../../../authentication/components/yupSchema";
import { yup } from "../../../../utils/shared.module";
import { useSelector } from "react-redux";
import states from "../../../../global/stateList";
import { Dialog } from "primereact/dialog";

interface props {
  userDetails: any;
}

const initialValues = {
  credential_agree_terms: "",
  clinical_role: "",
  alternate_credential_type: "",
  credential_check: true,
  license_number: "",
  license_state: "",
  license_expiration_month: "",
  license_expiration_year: "",
  clinicalRoleTwo: "",
  licenseNumberTwo: "",
  licenseStateTwo: "",
  licenseExpMonthTwo: "",
  licenseExpYearTwo: "",
  supervisorName: "",
  supervisor_name: "",
  supervisor_license_number: "",
  pre_license_number: "",
  pre_license_info: false,
  other_dropdown: "",

  // other: "",
};

const formSchemaTwo = yup.object({
  supervisor_name: supervisorSchema,
  supervisor_license_number: supervisorLicenseSchema,
  license_state: supervisorLicenseStateSchema,
  license_expiration_month: supervisorLicenseExpMonthSchema,
  license_expiration_year: supervisorLicenseExpYearSchema,
  pre_license_number: preLicenseNumber,
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
});

const formSchema = yup.object({
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
  // dropdown: dropdownSchema,
  license_number: licenseNumberSchema,
  license_state: licenseStateSchema,
  license_expiration_month: licenseExpMonthSchema,
  license_expiration_year: licenseExpYearSchema,
  credential_check: acceptTermsSchema,
});

const formSchemaThree = yup.object({
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
  alternate_credential_type: clinicalTypeSchema,
});

const professions = [{ label: "Psychiatrist", value: "Psychiatrist" }];

const PrimaryCredentialModal = (props: any) => {
  const primaryModalRef = useRef();
  const { type, open, data, userDetails } = props.visible;
  const {
    primaryData,
    getDirectoryData,
    clinicalRoleOption,
    GetPrimaryCredentials,
  } = props;

  const registerData = useSelector((state: any) => state.common.registerData);
  const licensed =
    registerData?.credentialProvider?.license ||
    "i_am_licensed_my_license_information_is_below";
  const [license, setLicense] = useState(
    licensed ? licensed : SettingsLicenseOption.license
  );
  const getFormSchema: any = (license: string) => {
    if (license === SettingsLicenseOption.license) {
      return formSchema;
    } else if (license === SettingsLicenseOption.pre_License) {
      return formSchemaTwo;
    } else {
      return formSchemaThree;
    }
  };

  const {
    control,
    formState: { errors },
    register,
    trigger,
    setValue,
    getValues,
    reset,
    watch,
    handleSubmit,
    clearErrors,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(getFormSchema(license)),
  });
  const selectedYear = watch("license_expiration_year");

  const getStates = (country: any) => {
    const code =
      typeof country === "string" ? country.toLocaleLowerCase() : country.code;

    return states[code] || [];
  };
  let stateList = getStates(userDetails?.country ? userDetails?.country : "in");
  const stateListOption = stateList?.map((state: any) => {
    return { label: state, value: state };
  });

  const OnClose = () => {
    reset();
    primaryModalRef.current.click();
    props.handleClose();
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let payload = {};
      if (watch("credential_agree_terms") === SettingsLicenseOption?.license) {
        payload = {
          other_dropdown: watch("other_dropdown"),
          license_number: watch("license_number"),
          license_state: watch("license_state"),
          license_expiration_month: watch("license_expiration_month"),
          license_expiration_year: watch("license_expiration_year"),
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          credential_check: watch("credential_check"),
        };
      } else if (
        watch("credential_agree_terms") === SettingsLicenseOption?.pre_License
      ) {
        payload = {
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          supervisor_name: watch("supervisor_name"),
          supervisor_license_number: watch("supervisor_license_number"),
          license_state: watch("license_state"),
          license_expiration_month: watch("license_expiration_month"),
          license_expiration_year: watch("license_expiration_year"),
          pre_license_number: watch("pre_license_number"),
          pre_license_info: watch("pre_license_info"),
        };
      } else {
        payload = {
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          alternate_credential_type: watch("alternate_credential_type"),
        };
      }
      const response = await putData(
        `${end_points.settings_license.url}`,
        payload
      );
      if (response?.status < 400) {
        getDirectoryData();
        GetPrimaryCredentials();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    if (data?.primary_credential_setting?.credentials !== undefined) {
      reset({
        ...initialValues,
        ...data?.primary_credential_setting?.credentials[0],
      });
      setLicense(
        data?.primary_credential_setting?.credentials[0]?.credential_agree_terms
      );
      setValue(
        "supervisor_license_number",
        data?.primary_credential_setting?.credentials[0]?.license_number
      );
    }
  }, [data]);

  useEffect(() => {}, [watch()]);

  return (
    <div
      className="modal fade credential-modal finances-modal"
      id="credential-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Primary Credential</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={primaryModalRef}
              onClick={OnClose}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <Controller
              name="credential_agree_terms"
              control={control}
              render={({ field }) => (
                <div className="custom-radio">
                  {LicensedOptions?.map((item: any) => (
                    <div className="form-check">
                      <input
                        type="radio"
                        className="form-check-input"
                        id={item?.value}
                        name="optradio"
                        defaultValue={item?.value}
                        value={item?.value}
                        checked={item?.value === field?.value}
                        onChange={(e) => {
                          if (
                            primaryData?.credential_agree_terms !==
                            e.target.value
                          ) {
                            reset(initialValues);
                          } else {
                            reset({ ...initialValues, ...primaryData });
                          }
                          setValue("credential_agree_terms", e.target.value);
                          setLicense(e.target.value);
                          clearErrors();
                        }}
                      />
                      <label className="form-check-label" htmlFor="radio1">
                        {item?.label}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            /> */}
              <ErrorMessage value={errors.credential_agree_terms} />
              {license === SettingsLicenseOption.license && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Clincial Role</label>
                        <Controller
                          name="clinical_role"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={clinicalRoleOption}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("clinical_role"); // Trigger validation onChange
                              }}
                              onBlur={() => trigger("clinical_role")} // Trigger validation onBlur
                              placeholder="Select One"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.clinical_role} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>License Number</label>
                        <Controller
                          name="license_number"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your license number"
                              {...field}
                              onBlur={() => trigger("license_number")} // Trigger validation onBlur
                              onKeyDown={(event) => {
                                const regex = licneseNumberPattern;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={licenseMaxLength}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.license_number} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>License State</label>
                        <Controller
                          name="license_state"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={stateListOption}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("license_state");
                              }}
                              onBlur={() => trigger("license_state")} // Trigger validation onBlur
                              placeholder="Select One"
                              className="select w-100"
                            />
                          )}
                        />

                        <ErrorMessage value={errors.license_state} />
                      </div>
                    </div>
                  </div>
                  <div className="form-wrap form-wrap-date">
                    <label>License Expiration</label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-wrap mb-0">
                          <Controller
                            name="license_expiration_month"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={
                                  selectedYear
                                    ? filteredMonthOptions(Number(selectedYear))
                                    : monthOptions
                                }
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("license_expiration_month");
                                }}
                                onBlur={() =>
                                  trigger("license_expiration_month")
                                } // Trigger validation onBlur
                                placeholder="Month"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage
                            value={errors.license_expiration_month}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-wrap mb-0">
                          <Controller
                            name="license_expiration_year"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                value={field.value}
                                options={yearOptions}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                  trigger("license_expiration_year");
                                }}
                                onBlur={() =>
                                  trigger("license_expiration_year")
                                } // Trigger validation onBlur
                                placeholder="Year"
                                className="select w-100"
                              />
                            )}
                          />
                          <ErrorMessage
                            value={errors.license_expiration_year}
                          />
                        </div>
                      </div>
                      {/* <div className="col-12 mt-4 d-flex align-items-start">
                    <label className="custom_check ps-4">
                      <Controller
                        name="credential_check"
                        control={control}
                        render={({ field }) => (
                          <input
                            title="Terms and Conditions"
                            type="checkbox"
                            {...field}
                            checked={field.value}
                            onBlur={() => trigger("credential_check")}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              trigger("credential_check");
                            }}
                          />
                        )}
                      />
                      <span className="checkmark" />
                    </label>
                    <span className="accrpt-set">
                      Accept <Link to="#">Terms and Conditions</Link>
                    </span>
                  </div>
                  <ErrorMessage value={errors.credential_check} /> */}
                    </div>
                  </div>
                </>
              )}
              {license === SettingsLicenseOption.pre_License && (
                <>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Clinical Role</label>
                        <Controller
                          name="clinical_role"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              {...register("clinical_role")}
                              onSelect={() => trigger("clinical_role")}
                              onBlur={() => {}}
                              value={field.value}
                              onChange={(e) => {
                                setValue("clinical_role", e.value);
                              }}
                              options={clinicalRoleOption}
                              placeholder="Select"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.clinical_role} />
                      </div>
                    </div>
                    {/* <div className="col-12">
          <div className="mb-3">
            <label className="col-form-label">Other Dropdown</label>
            <Controller
              name="other"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...register("other")}
                  onSelect={() => trigger("other")}
                  value={field.value}
                  options={selectHereOptions}
                  placeholder="Select"
                  className="select w-100"
                />
              )}
            />
            <ErrorMessage value={errors.other} />
          </div>
        </div> */}
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Supervisor’s Name</label>
                        <Controller
                          name="other"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Supervisor name"
                              {...register("supervisor_name")}
                              onKeyDown={(event) => {
                                const regex = onlyAlphabet;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onBlur={() => trigger("supervisor_name")}
                              maxLength={nameMaxLength}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.supervisor_name} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Supervisor’s License Number</label>
                        <Controller
                          name="supervisor_license_number"
                          control={control}
                          render={({ field }) => (
                            <input
                              className="form-control"
                              placeholder="Supervisor license number"
                              {...register("supervisor_license_number")}
                              onKeyDown={(event) => {
                                const regex = onlyAplhaNumHyp;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onBlur={() =>
                                trigger("supervisor_license_number")
                              }
                              maxLength={maxNpiNumber}
                            />
                          )}
                        />
                        <ErrorMessage
                          value={errors.supervisor_license_number}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Supervisor’s License State</label>
                        <Controller
                          name="license_state"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              {...register("license_state")}
                              onSelect={() => trigger("license_state")}
                              onBlur={() => trigger("license_state")}
                              value={field.value}
                              options={stateListOption}
                              placeholder="Select"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.license_state} />
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-wrap">
                        <label>Supervisor’s License Expiration</label>
                        <div className="row">
                          <div className="col-md-6">
                            <div className="mb-3">
                              <Controller
                                name="license_expiration_month"
                                control={control}
                                render={({ field }) => (
                                  <Dropdown
                                    {...register("license_expiration_month")}
                                    onSelect={() =>
                                      trigger("license_expiration_month")
                                    }
                                    onBlur={() =>
                                      trigger("license_expiration_month")
                                    }
                                    value={field.value}
                                    options={monthOptions}
                                    placeholder="Select"
                                    className="select w-100"
                                  />
                                )}
                              />
                              <ErrorMessage
                                value={errors.license_expiration_month}
                              />
                            </div>
                          </div>
                          <div className="col-md-6">
                            <Controller
                              name="license_expiration_year"
                              control={control}
                              render={({ field }) => (
                                <Dropdown
                                  {...register("license_expiration_year")}
                                  onSelect={() =>
                                    trigger("license_expiration_year")
                                  }
                                  onBlur={() =>
                                    trigger("license_expiration_year")
                                  }
                                  value={field.value}
                                  options={yearOptions}
                                  placeholder="Select"
                                  className="select w-100"
                                />
                              )}
                            />
                            <ErrorMessage
                              value={errors.license_expiration_year}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-border mb-5">
                    <label className="custom_check w-100">
                      <Controller
                        name="pre_license_info"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="checkbox"
                            checked={field?.value}
                            onChange={(e) => {
                              setValue("pre_license_info", e.target.checked);
                              field.onChange(e.target.checked);
                              setValue("pre_license_number", null);
                              if (
                                e.target.checked &&
                                !watch("pre_license_info")
                              ) {
                                setValue("pre_license_number", "");
                              }
                              trigger("pre_license_number");
                              trigger("pre_license_info");
                              trigger("pre_license_number");
                            }}
                          />
                        )}
                      />
                      <span className="checkmark" />
                      Enter my pre-license info
                    </label>
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <label className="col-form-label pt-0">
                            License Number
                          </label>
                          <Controller
                            name="pre_license_number"
                            control={control}
                            render={({ field }) => (
                              <input
                                className="form-control"
                                {...register("pre_license_number")}
                                onKeyDown={(event) => {
                                  const regex = onlyAplhaNumHyp;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== "Backspace"
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                onBlur={() => trigger("pre_license_number")}
                                maxLength={maxNpiNumber}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.pre_license_number} />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              {license === SettingsLicenseOption.certified_License && (
                <>
                  <div className="row mb-4">
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Clincial Role</label>
                        <Controller
                          name="clinical_role"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={clinicalRoleOption}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("clinical_role"); // Trigger validation onChange
                              }}
                              onBlur={() => trigger("clinical_role")} // Trigger validation onBlur
                              placeholder="Select One"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.clinical_role} />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-wrap">
                        <label>Credential Type</label>
                        <p>Choose an alternate credential, If you have one </p>
                        <Controller
                          name="alternate_credential_type"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={clinicalRoleTypeOption}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("alternate_credential_type"); // Trigger validation onChange
                              }}
                              onBlur={() =>
                                trigger("alternate_credential_type")
                              } // Trigger validation onBlur
                              placeholder="Select One"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage
                          value={errors.alternate_credential_type}
                        />
                      </div>
                    </div>
                    <p>Don't see your certification listed here?</p>
                    <p>
                      Please send your contact and certification information to{" "}
                      <Link to="#" className="span-mail">
                        credentialing@turningwell.com
                      </Link>{" "}
                    </p>
                  </div>
                </>
              )}
              <div className="form-wrap mt-3">
                <button className="btn btn-primary w-100" type="submit">
                  Update Credential
                </button>
                <button
                  type="button"
                  className="btn btn-cancel w-100"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
            {/* <div className="custom-card mb-3 mb-md-0"></div> */}
          </div>
        </div>
      </div>
    </div>
  );
};
export default PrimaryCredentialModal;
