import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  personalInformationMaxLength,
  stringAllowedKeys,
} from "../../../../utils/patterns/regex.pattern";
import {
  appointmentModeSchema,
  designationSchema,
  emailSchema,
  nameSchema,
  personalStatementSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";
import { translate } from "../../../../utils/translate/translate";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
}

const EmailMdoal = (props: props) => {
  const emailRef = useRef();
  const { designationsList, getDirectoryData } = props;
  const { type, open, data, userDetails } = props.visible;

  const initialValues = {
    email: "",
  };

  const basicModelValidation = Yup.object().shape({
    email: emailSchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicModelValidation),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    emailRef.current.click();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        ...data,
      };
      const response = await putData(
        `${end_points.profile_about_email.url}`,
        payload
      );

      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data)?.length > 0) {
      reset(data);
    }
  }, [data]);

  return (
    <div
      className="modal fade phone-modal finances-modal"
      id="add-email"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header add-modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Update Email</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={emailRef}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
              <label>Email Address</label>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <input
                      type="text"
                      className="form-control"
                      placeholder={"Enter email"}
                      {...field}
                      onBlur={() => trigger("email")} // Trigger validation onBlur
                      maxLength={emailMaxLength}
                    />
                  )}
                />
                <ErrorMessage value={errors.email} />
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  {data?.email !== undefined && data?.email !== ""
                    ? "Update"
                    : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmailMdoal;
