import React, { useState, useEffect } from "react";
import FullCalendar, { EventClickArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

const FeaturedWorldWideFeed = () => {
  const [events, setEvents] = useState([
    {
      title: "Meeting",
      start: "2024-10-31T12:30:00",
      backgroundColor: "#aee6eb",
      textColor: "#FFFFFF",
    },
    {
      title: "Conference",
      start: "2024-10-25T09:00:00",
      backgroundColor: "#fa9a46",
      textColor: "#FFFFFF",
    },
    {
      title: "Long Event",
      start: "2024-10-22T10:00:00",
      end: "2024-10-24T16:00:00",
      backgroundColor: "#505bd9",
      textColor: "#FFFFFF",
    },
  ]);

  const handleDateClick = (arg: any) => {
    const title = prompt("Enter event title:");
    if (title) {
      setEvents([
        ...events,
        {
          title,
          start: arg.dateStr,
          backgroundColor: "#000000",
          textColor: "#FFFFFF",
        },
      ]);
    }
  };

  const handleEventClick = (clickInfo: EventClickArg) => {
    if (
      window.confirm(
        `Are you sure you want to delete the event '${clickInfo.event.title}'?`
      )
    ) {
      clickInfo.event.remove();
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk schedule-breadcrumb">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li className="f-500">TW 24/7 Stream</li>
                  </ul>
                </div>
                <p>
                  Easily schedule live streams with our new feature. Choose
                  dates and slots, enhancing national exposure at no cost.
                </p>
              </div>
            </div>
          </div>
          <div className="row coustom-row">
            <div
              style={{
                height: "20rem",
                width: "30rem",
                backgroundColor: "white",
              }}
            >
              <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                initialView="dayGridMonth"
                events={events}
                dateClick={handleDateClick}
                eventClick={handleEventClick}
                editable={true}
                selectable={true}
                headerToolbar={{
                  left: "prev,next today",
                  center: "title",
                  right: "",
                }}
                eventContent={(eventInfo) => (
                  <div
                    style={{
                      backgroundColor:
                        eventInfo.event.backgroundColor || "#ffffff",
                      color: eventInfo.event.textColor || "#000000",
                      padding: "5px",
                      borderRadius: "5px",
                      height: "inherit",
                    }}
                  >
                    {eventInfo.event.title}
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedWorldWideFeed;
