import React, { useCallback, useEffect, useState } from "react";
import { google, login, logo } from "../../utils/imagepath";
import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes/routes";
import ErrorMessage from "../../components/errorMessage";
import { emailMaxLength } from "../../utils/patterns/regex.pattern";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { yup } from "../../utils/shared.module";
import { emailSchema, passwordSchema } from "./components/yupSchema";
import { useDispatch } from "react-redux";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { getAuth, GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { initializeApp } from "firebase/app";

import {
  setRegisterData,
  setUserDetails,
  setLoginAuthToken,
} from "../../core/redux/commonSlice";
import { roleType, socialType } from "../../global";
import { useSelector } from "react-redux";
import AuthTypeModel from "./components/authTypeModel";

const formSchema = yup.object({
  password: yup.string().trim().required("Password is required"),
  //  passwordSchema,
  email: emailSchema,
});
const firebaseConfig = {
  apiKey: "AIzaSyDCXgxJcmYjr-pICHumbLedQ7qd-vZlDvk",
  authDomain: "turning-well-3ccb7.firebaseapp.com",
  projectId: "turning-well-3ccb7",
  storageBucket: "turning-well-3ccb7.appspot.com",
  messagingSenderId: "823835257216",
  appId: "1:823835257216:web:991311a58ae9c92278f965",
};
const Login = () => {
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showAuthType, setShowAuthType] = useState<boolean>(false);

  const dispatch = useDispatch();
  const registerData = useSelector((state: any) => state.common.registerData);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const navigation = useNavigate();
  const navigationPath = () => {
    navigation(routes.providerIndex);
    // window.location.reload();
  };
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const sucessCallback = (response: any) => {
    if (response.status === 201 || response.status === 200) {
      const res: any = response.data?.data;
      if (response.status === 201) {
        localStorage.setItem("token", res?.token);
        dispatch(setLoginAuthToken(res?.token));
        dispatch(setUserDetails(res?.user_details));
        localStorage.setItem("token", res.token);
        navigationPath();
      }
      // setRegisterUserId("");
      if (response.status === 200) {
        const updateData: any = {
          ...registerData,
          google_uid: {
            google_uid: res?.google_uid || null,
            isSocialLogin: true,
            socialType: socialType.google,
          },
          provider: {
            ...registerData?.provider,
            email: res?.email || "",
            username: res?.username || "",
          },
          locationProvider: {
            ...registerData?.locationProvider,
            firstName: res?.fullname || "",
          },
        };
        dispatch(setRegisterData(updateData));
        setShowAuthType(true);
        // navigate(routes.registerProvider);
      }
    }
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        email: data.email,
        password: data.password,
        isSocialLogin: false,
        type: roleType(),
        // "isSocialLogin":true,
        // "google_uid":"kfdjslkfjdsflksd"
      };
      const response = await postData(end_points.authLoginApi.url, payload);
      sucessCallback(response);
    } catch (error) {
    } finally {
      hideLoader();
      // navigate(routes.registerClient);
    }
  };
  const handleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      token && socialLogin(token);
    } catch (error) {}
  };
  const socialLogin = async (code: any) => {
    try {
      showLoader();
      const payload = {
        google_uid: code,
        isSocialLogin: true,
        type: roleType(),
      };
      const response = await postData(end_points.authLoginApi.url, payload);
      sucessCallback(response);
    } catch (error) {
    } finally {
      hideLoader();
      // navigate(routes.registerClient);
    }
  };

  useEffect(() => {
    dispatch(setRegisterData({}));
    dispatch(setUserDetails({}));
  }, []);
  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap forget-set">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body">
                <div className="login-title">
                  <h4>Welcome Back</h4>
                  <p>
                    Dive back into your world with a simple sign-in. Your next
                    adventure awaits - let's get started!
                  </p>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-set">
                    <label>Email</label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                          {...field}
                          onChange={(e) => {
                            field.onChange(e);
                            trigger("email");
                          }}
                          maxLength={emailMaxLength}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.email} />
                  </div>
                  <div className="form-set mb-1">
                    <label>Password</label>
                    <div className="pass-group position-relative cus-input-log">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control pass-input"
                            placeholder="Enter your password"
                            {...field}
                            onChange={(e) => {
                              field.onChange(e);
                              trigger("password");
                            }}
                          />
                        )}
                      />
                      <i
                        className={`toggle-password ti ${showPassword ? "ti-eye" : "ti-eye-closed"}`}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <ErrorMessage value={errors.password} />
                  </div>
                  <div className="row">
                    <div className="ms-auto col-md-6 col-sm-6">
                      <div className="text-md-end mt-3">
                        <Link
                          className="forgot-link"
                          to={routes.forgotPassword}
                        >
                          Forgot password?
                        </Link>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Login
                  </button>
                </form>
                <div className="register-link text-center">
                  <div className="login-or">
                    <span className="span-or">Or</span>
                  </div>
                  <div className="social-login">
                    <GoogleOAuthProvider
                      clientId={
                        "194354511270-orpgf1o6vo00o7dp25qsc2t0po4240gf.apps.googleusercontent.com"
                      }
                    >
                      <Link
                        to="#"
                        className="btn btn-google w-100"
                        onClick={handleLogin}
                      >
                        <img src={google} className="me-1" alt="img" />
                        Continue with Google
                      </Link>
                    </GoogleOAuthProvider>
                  </div>
                  <div className="btm-text text-center">
                    <p>
                      Don’t have an account?
                      <Link
                        className="forgot-link ms-2"
                        // onClick={() => {
                        //   setShowAuthType(true);
                        // }}
                        to={routes.registerProvider}
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                </div>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper w-50">
        <div className="login-img">
          <img src={login} alt="" />
        </div>
      </div>
      <AuthTypeModel
        visible={showAuthType}
        title="Choose your role"
        onCancel={() => {}}
      />
      {/* /Login Section */}
    </>
  );
};

export default Login;
