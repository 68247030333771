import React, { useState } from "react";
import { Link } from "react-router-dom";
import { download, infoIcon } from "../../utils/imagepath";

import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";
import { Calendar } from "primereact/calendar";

const ScheduleStream = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedSchedule, setSelectedSchedule] = useState(null);
  const [value, setValue] = useState([
    "Mental Health",
    "Well-being",
    "Anxiety",
  ]);
  const [date1, setDate1] = useState(new Date());
  const [date2, setDate2] = useState(new Date());

  const categories = [
    { label: "Category 1", value: "category1" },
    { label: "Category 2", value: "category2" },
  ];

  const subCategory = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
  ];

  const group = [
    { label: "Group 1", value: "group1" },
    { label: "Group 2", value: "group2" },
  ];
  const schedule = [
    { label: "None", value: "none" },
    { label: "Weekly", value: "weekly" },
  ];

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style mb-3">
                    <li>
                      <Link to="#">Videos</Link>
                    </li>
                    <li>
                      <Link to="#">Go Live</Link>
                    </li>
                    <li>Schedule Stream</li>
                  </ul>
                </div>
                <p>
                  Start your live session now and engage with your audience in
                  real-time.
                </p>
              </div>
              <div className="row flex-body d-flex row-gap-30 ">
                <div className="col-md-12">
                  <div className="custom-card mb-md-0">
                    <h3 className="title-h3">Step 1</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label">Schedule Post</label>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="mb-3 addon-info">
                                <div className="addons">
                                  <Calendar
                                    className="datetimepicker"
                                    value={date1}
                                    onChange={(e: any) => setDate1(e.value)}
                                  />
                                  <span className="addon-span">
                                    <i className="ti ti-chevron-down" />
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="mb-3 addon-info">
                                <div className="addons">
                                  <Calendar
                                    className="datetimepicker"
                                    value={date2}
                                    onChange={(e: any) => setDate2(e.value)}
                                  />
                                  <span className="addon-span">
                                    <i className="ti ti-chevron-down" />
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3 addon-info">
                            <label className="form-label">
                              Schedule: Weekly/Monthly Repeat
                            </label>
                            <Dropdown
                              options={schedule}
                              value={selectedSchedule}
                              onChange={(e) => setSelectedSchedule(e.value)}
                              placeholder="Select"
                              className="select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Video Title</label>
                            <input
                              type="text"
                              className="form-control"
                              defaultValue="Understanding Mental Health"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">
                              Video Description
                            </label>
                            <textarea
                              className="form-control"
                              defaultValue={
                                "Understanding Mental Health explores mental well-being, common disorders, and effective strategies for maintaining a balanced, healthy mind."
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Tags</label>
                            <Chips
                              className="w-100"
                              value={value}
                              onChange={(e: any) => setValue(e.value)}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Category</label>
                            <Dropdown
                              options={categories}
                              value={selectedCategory}
                              onChange={(e) => setSelectedCategory(e.value)}
                              placeholder="Select a Category"
                              className="select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Sub-Category</label>
                            <Dropdown
                              options={subCategory}
                              value={selectedSubCategory}
                              onChange={(e) => setSelectedSubCategory(e.value)}
                              placeholder="Select here"
                              className="select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Group</label>
                            <Dropdown
                              options={group}
                              value={selectedGroup}
                              onChange={(e) => setSelectedGroup(e.value)}
                              placeholder="Select here"
                              className="select w-100"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Co-Host</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="susanreynold@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 col-12">
                          <div className="mb-3 addon-info">
                            <label className="form-label">Moderator</label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Riyanreynold@gmail.com"
                            />
                          </div>
                        </div>
                        <div className="upload-wrapper">
                          <h3 className="title-h3 mb-2">Thumbnail</h3>
                          <p>
                            Your thumbnail is the first impression viewers will
                            have, so make it eye-catching and relevant.
                          </p>
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="mb-3 addon-info">
                                <div className="upload-div p-0">
                                  <div className="upload-file p-0 border-0 p-3">
                                    <input type="file" />
                                    <div className="upload-content">
                                      <h6>
                                        <img
                                          src={download}
                                          className="me-2"
                                          alt=""
                                        />
                                        Drag and Drop files here or{" "}
                                        <span className="ms-1">
                                          choose file
                                        </span>
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="settings-wrapper">
                          <div className="audience-blk block-border">
                            <h4>Audience Settings:</h4>
                            <h5>
                              This video is set to not made for kids
                              <span className="ms-0 ms-lg-2 d-inline-block">
                                Set by you
                              </span>
                            </h5>
                            <p>
                              Regardless of your location, you’re legally
                              required to comply with the Children’s Online
                              Privacy Protection Act (COPPA) and / or other
                              laws. You’re required to tell us whether your
                              videos are made for kids.{" "}
                            </p>
                            <div className="info-content-wrapper">
                              <h5>What’s content made for kids?</h5>
                              <div className="info-box-wrapper d-flex align-items-start">
                                <div className="icon-blk">
                                  <img src={infoIcon} alt="" />
                                </div>
                                <div>
                                  <p>
                                    Features like personalized ads and
                                    notifications won’t be available on videos
                                    made for kids. Videos that are set as made
                                    for kids by you are more likely to be
                                    recommended alongside other kids videos.
                                  </p>
                                  <Link
                                    to="#"
                                    className="d-inline-block learn-more"
                                  >
                                    Learn more
                                  </Link>
                                </div>
                              </div>
                              <ul className="list-unstyled d-flex align-items-center flex-wrap col-gap-60">
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Yes, it's made for kids
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" defaultChecked />
                                    <span className="checkmark" />
                                    No, it's made for kids
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="audience-blk block-border">
                            <h4 className="bg-transparent p-0 shadow-none">
                              Pay Per Video (For Non subscriber)
                            </h4>
                            <div className="accordion-collapse">
                              <h5 className="non-subscribers-content">
                                For non-subscribers, Set a pay-per-view fee to
                                allows access to live stream without committing
                                to a subscription.
                              </h5>
                            </div>
                            <div className="event-task">
                              <div className="event-task-input">
                                <h6>$</h6>
                                <input type="text" placeholder="49.99" />
                                <Link to="#">
                                  <i className="ti ti-info-circle-filled" />
                                </Link>
                              </div>
                              <div className="event-task-toggle">
                                <h6>Standard Event</h6>
                                <div className="active-switch ">
                                  <label className="switch">
                                    <input type="checkbox" defaultChecked />
                                    <span className="sliders round" />
                                  </label>
                                </div>
                                <h6>Special Event</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="custom-card">
                    <h3 className="title-h3">Step 2</h3>
                    <div className="row">
                      <div className="upload-wrapper m-0">
                        <div className="settings-wrapper">
                          <div className="audience-blk">
                            <h4 className="mb-2">Live Chat</h4>
                          </div>
                        </div>
                        <p className="live-chat">
                          Please choose the option below to engage in live chat
                          during the session.
                        </p>
                        <ul className="list-unstyled d-flex align-items-center flex-wrap row-gap-30">
                          <li>
                            <label className="custom_check style2">
                              <input type="checkbox" />
                              <span className="checkmark" />
                              Live Chat
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="go-live-btn">
                    <Link to="#" className="btn btn-primary rounded">
                      Go Live Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default ScheduleStream;
