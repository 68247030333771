import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import {
  uploadIcon,
  mediaCheck,
  media01Png,
  media02Png,
  media03Png,
  media04Png,
  mediaPdf,
  folderVideo,
  media01Svg,
  media02,
  media03,
  media04,
  media05,
  media06,
  mediaUpload,
  autoGenerate,
} from "../../../../utils/imagepath";
import {
  AltSchema,
  captionSchema,
  descriptionSchema,
  titleSchema,
  uploadFileSchema,
} from "../../../authentication/components/yupSchema";
import {
  acceptProfileTypes,
  validProfileImageTypes,
} from "../../../../utils/patterns/regex.pattern";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import {
  deleteData,
  getData,
  postData,
  putData,
} from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { FileUploader } from "react-drag-drop-files";
import moment from "moment";
import { ConfirmDialog } from "primereact/confirmdialog";
import { getCroppedImg, getImageDimensions } from "../../../../utils/constants/index.";
import Cropper from "react-easy-crop";
interface props {
  getDirectoryData: () => void;
  directoryData: any;
}

const gallerySchema = Yup.object().shape({
  uploadFile: uploadFileSchema,
  title: titleSchema,
  caption: captionSchema,
  altText: AltSchema,
  description: descriptionSchema,
});

const GalleryModal = (props: props) => {
  const { getDirectoryData } = props;
  const galleryRef = useRef();
  const [isEdit, setIsEdit] = useState({
    edit: false,
    type: "selection",
    activeTab: "upload",
    imageData: "",
  });
  const [files, setFiles] = useState([]);
  const [galleryItems, setGalleryItems] = useState([]);
  const [selectedImage, setSelectedImage] = useState<any>({});
  const [visible, setVisible] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [imageFile, setImageFile] = useState("");


  const urlToFile = async (imageUrl: any, fileName: any) => {
    const response = await fetch(imageUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    return file;
  };

  function convertToBase64(url) {
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      });
  }



  const onCropComplete = async (croppedArea: any, croppedAreaPixels: any) => {
    try {
      convertToBase64(selectedImage?.url).then(async (base64String) => {
        const croppedImageFile = await getCroppedImg(base64String, croppedAreaPixels);
        console.log("CROPPEDFILED", croppedImageFile)
      });

    } catch (e) {
      console.error(e);
    }
  };






  const OnClose = () => {
    setFiles([]);
    setIsEdit({
      edit: false,
      type: "selection",
      activeTab: "upload",
      imageData: "",
    });
    galleryRef.current.click();
  }; // State for multiple files

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(gallerySchema),
  });
  const GetGalleryList = async () => {
    const response = await getData(
      `${end_points?.profile_gallery_images_lists?.url}`
    );
    if (response?.status < 400) {
      setGalleryItems(response.data?.data);
    }
  };

  const onFileUpload = async () => {
    if (files?.length > 0) {
      const formData = new FormData();

      // Loop through the files array and append each file to the FormData object
      files.forEach((file, index) => {
        formData.append("gallery_image", file); // Use 'files[]' to allow array of files
      });

      try {
        showLoader();
        const response = await postData(
          `${end_points.profile_gallery_images_upload.url}`,
          formData
        );
        if (response?.status < 400) {
          GetGalleryList();
          // getDirectoryData();
          setFiles([]);
          setIsEdit({
            edit: false,
            type: "selection",
            activeTab: "media",
            imageData: "",
          });
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
      // Here you can handle file upload logic
    }
  };

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        gallery_image: data?.uploadFile,
      };
      const response = await postData("", payload);
      if (response?.data?.status === 200) {
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const handleFileChange = (newFiles: any) => {
    setFiles((prevFiles: any) => {
      let existingFiles = files.map((it: any) => it?.name);
      if (existingFiles?.includes(newFiles[0]?.name)) {
        return files;
      } else {
        return [...prevFiles, ...newFiles];
      }
    });
  };

  const removeFile = (fileName: any) => {
    setFiles((prevFiles) => prevFiles.filter((file) => file.name !== fileName));
  };
  const deletePlan = async (id: any) => {
    const url = `${end_points.profile_gallery_images_delete.url}/${id}`;
    if (id) {
      try {
        const response: any = await deleteData(url);
        if (response?.status === 201) {
          setVisible(false);
          GetGalleryList();
        }
      } catch (error) { }
    }
  };
  const accept = () => {
    if (selectedImage) {
      deletePlan(selectedImage._id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const GetImageDimension = (item: any) => {
    getImageDimensions(item?.url)
      .then((dimensionsString) => {
        setSelectedImage({
          ...item,
          dimensions: dimensionsString,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };
  useEffect(() => {
    if (selectedImage?.url !== undefined) {
      const file = urlToFile(selectedImage?.url, selectedImage?.file_name)
      console.log("selectedImga", selectedImage, file)

    }
  }, [selectedImage]);

  useEffect(() => {

    GetGalleryList();
  }, []);


  return (
    <>
      <div
        className="modal fade gallery-modal"
        id="gallery-modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header modal-header-tabs">
              <h5 className="modal-title">Upload Profile Photo</h5>
              <div className="modal-tabs">
                <ul className="nav nav-tabs" id="uploadTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Link
                      className={`nav-link ${isEdit?.activeTab === "upload" ? "active" : ""}`}
                      id="upload_tab"
                      data-bs-toggle="tab"
                      to="#upload-tab"
                      role="tab"
                      aria-controls="upload-tab"
                      aria-selected="true"
                      onClick={() =>
                        setIsEdit({ ...isEdit, activeTab: "upload" })
                      }
                    >
                      Upload File
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className={`nav-link ${isEdit?.activeTab === "media" ? "active" : ""}`}
                      id="media_tab"
                      data-bs-toggle="tab"
                      to="#media-tab"
                      role="tab"
                      aria-controls="media-tab"
                      aria-selected="true"
                      onClick={() =>
                        setIsEdit({ ...isEdit, activeTab: "media" })
                      }
                    >
                      Media Library
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={galleryRef}
                onClick={OnClose}
              />
            </div>
            <div className="modal-body">
              <div className="tab-content" id="uploadTabContent">
                {/* Upload File Tab */}
                {isEdit?.activeTab === "upload" && (
                  <div
                    className="tab-pane fade show active"
                    id="upload-tab"
                    role="tabpanel"
                    aria-labelledby="upload_tab"
                  >
                    <div className="form-upload">
                      <div className="row justify-content-center">
                        <div className="col-md-5">
                          <div className="form-upload-info">
                            <img src={uploadIcon} alt="Icon" />
                            <h6>
                              Drag &amp; drop files or
                              <label className="choose-file">
                                {/* Drag and Drop File Uploader for Multiple Files */}
                                <FileUploader
                                  handleChange={handleFileChange}
                                  name="uploadFiles"
                                  types={["JPEG", "PNG", "JPG"]}
                                  multiple={true} // Allow multiple files
                                />
                                <span>Browse</span>
                              </label>
                            </h6>
                            <FileUploader
                              handleChange={handleFileChange}
                              name="uploadFiles"
                              types={["JPEG", "PNG", "JPG"]}
                              multiple={true} // Allow multiple files
                            />

                            {/* Display selected files if any */}
                            <div className="row justify-content-center">
                              {files.map((file, index) => (
                                <div className="col-lg-3" key={index}>
                                  <div className="mb-3">
                                    <div className="upload-div upload2-div with-img-blk border-0 p-0 gallery-modal-img">
                                      <div className="upload-file">
                                        <div className="upload-content flex-column">
                                          <Link
                                            to="#"
                                            className="uplaod-close"
                                            onClick={() =>
                                              removeFile(file.name)
                                            }
                                          >
                                            <i className="ti ti-x" />
                                          </Link>
                                          {/* Display image preview or file icon */}
                                          {file.type?.startsWith("image/") && (
                                            <img
                                              src={URL.createObjectURL(file)}
                                              alt={file.name}
                                              className="w-100 h-100"
                                            />
                                          )}
                                          <p>{file.name}</p>
                                          <span className="auto-generate-cls">
                                            <img
                                              src={autoGenerate}
                                              alt="File icon"
                                            />
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>

                            <p>
                              Supported formats for profile image: JPEG, PNG,
                              JPG
                            </p>
                            <div className="form-upload-btn">
                              <button
                                className="btn btn-primary w-100 rounded"
                                onClick={onFileUpload}
                                disabled={!files?.length > 0}
                              >
                                Upload Files
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Media Library Tab */}
                {isEdit?.type === "selection" &&
                  isEdit?.activeTab === "media" && (
                    <div
                      className={`tab-pane fade show active`}
                      id="media-tab"
                      role="tabpanel"
                      aria-labelledby="media_tab"
                    >
                      <div className="row">
                        <div className="col-xl-9 col-lg-12 media-right">
                          <div className="media-select">
                            <div className="filter-div-modal">
                              <ul className="filter-select-modal">
                                {galleryItems?.length > 0 &&
                                  galleryItems?.map((it: any) => (
                                    <li>
                                      <Link
                                        to="#"
                                        onClick={() => GetImageDimension(it)}
                                        className={`gallery-modal-img-a ${selectedImage?._id === it?._id ? "active" : ""}`}
                                      >
                                        {selectedImage?._id === it?._id && (
                                          <img
                                            src={mediaCheck}
                                            className="media-check-icon"
                                            alt="Icon"
                                          />
                                        )}

                                        <img
                                          src={it?.url || media01Png}
                                          className="img-fluid gallery-modal-img"
                                          alt="Media"
                                        />

                                        {/* <div className="media-file">
                                          <img src={folderVideo} alt="Video" />
                                        </div> */}
                                      </Link>
                                    </li>
                                  ))}
                                {/* More media items here */}
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col-xl-3 col-lg-12 media-left">
                          <div className="media-grid">
                            <h2>Attachment Details</h2>
                            <div className="media-info">
                              <h6>{selectedImage?.file_name}</h6>
                              <ul className="nav">
                                {selectedImage?.createdAt !== undefined &&
                                  selectedImage?.createdAt !== null && (
                                    <li>
                                      {moment(selectedImage?.createdAt).format(
                                        "MMMM D, YYYY"
                                      )}
                                    </li>
                                  )}
                                <li>{selectedImage?.dimensions}</li>
                              </ul>
                              {selectedImage?.url !== undefined && (
                                <ul className="media-edit-nav nav">
                                  <li>
                                    <Link
                                      to="#"
                                      className="media-edit-link"
                                      onClick={() =>
                                        setIsEdit({
                                          ...isEdit,
                                          edit: true,
                                          type: "edit",
                                        })
                                      }
                                    >
                                      Edit Image
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="media-eelete-link"
                                      onClick={() => setVisible(true)}
                                    >
                                      Delete permanently
                                    </Link>
                                  </li>
                                </ul>
                              )}
                            </div>
                            <form onSubmit={handleSubmit(onSubmit)}>
                              <div className="media-form-wrap">
                                <label>Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mental health.png"
                                  {...register("title")}
                                />
                                {errors.title && (
                                  <p className="text-danger">
                                    {errors.title.message}
                                  </p>
                                )}
                              </div>
                              <div className="media-form-wrap">
                                <label>Caption</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Mental health course"
                                  {...register("caption")}
                                />
                                {errors.caption && (
                                  <p className="text-danger">
                                    {errors.caption.message}
                                  </p>
                                )}
                              </div>
                              <div className="media-form-wrap">
                                <label>Alt Text</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mental health.png"
                                  {...register("altText")}
                                />
                                {errors.altText && (
                                  <p className="text-danger">
                                    {errors.altText.message}
                                  </p>
                                )}
                              </div>
                              <div className="media-form-wrap">
                                <label>Description</label>
                                <textarea
                                  className="form-control"
                                  placeholder="Mental health course"
                                  {...register("description")}
                                />
                                {errors.description && (
                                  <p className="text-danger">
                                    {errors.description.message}
                                  </p>
                                )}
                              </div>
                              <div className="media-form-btn">
                                <button
                                  className="btn btn-primary rounded w-100"
                                  type="submit"
                                >
                                  Upload
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                      <div className="media-footer">
                        <p>Image Size: 5MB</p>
                      </div>
                    </div>
                  )}
                {isEdit?.type === "edit" && isEdit?.activeTab === "media" && (
                  <div
                    className="tab-pane fade show active"
                    id="media-tab2"
                    role="tabpanel"
                    aria-labelledby="media_tab2"
                  >
                    <div className="row">
                      <div className="col-xl-8 col-lg-12 d-flex">
                        <div className="media-icons-info w-100">
                          <ul className="nav">
                            <li>
                              <Link to="#" className="active">
                                <img src={media01Svg} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media02} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media03} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media04} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media05} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media06} alt="Icon" />
                              </Link>
                            </li>
                          </ul>
                          <div className="media-upload">
                            <img
                              src={selectedImage?.url}
                              className="img-fluid"
                              alt="Media"
                            />

                            <Cropper
                              image={selectedImage?.url}
                              crop={crop}
                              zoom={zoom}
                              aspect={4 / 3}
                              onCropChange={setCrop}
                              onCropComplete={onCropComplete}
                              onZoomChange={setZoom}
                            />

                          </div>
                          <div className="media-btns">
                            <Link
                              to="#"
                              className="btn btn-cancel"
                              onClick={() =>
                                setIsEdit({
                                  ...isEdit,
                                  edit: false,
                                  type: "selection",
                                })
                              }
                            >
                              Cancel
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Save
                            </Link>
                          </div>
                          <div className="media-footer-inner">
                            <p>Image Size: 5MB</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-12 d-flex">
                        <div className="media-grid w-100">
                          <div className="media-scale-head">
                            <h3>Scale Image</h3>
                            <p>Original dimensions 2000 * 1303</p>
                          </div>
                          <div className="media-scale-info">
                            <div className="media-nav">
                              <div className="media-scale-box">
                                <p>2000</p>
                              </div>
                              <div className="media-x">x</div>
                              <div className="media-scale-box">
                                <p>1800</p>
                              </div>
                              <div className="media-scale-btn">
                                <Link to="#" className="btn btn-primary">
                                  scale
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="media-scale-input">
                            <h4>Image Crop</h4>
                            <div className="media-input">
                              <p>Aspect ratio:</p>
                              <div className="media-form-control">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={"1400"}
                                  defaultValue={1400}
                                />
                              </div>
                              <div className="media-x">
                                <span>x</span>
                              </div>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="media-input">
                              <p>Selection:</p>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                              <div className="media-x">
                                <span>x</span>
                              </div>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Media Library Tab */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this image?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default GalleryModal;
