import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logoWhite, videoImg01, videoImg02, videoImg03, videoImg04, videoImg05 } from "../../../utils/imagepath";
import { routes } from "../../../utils/routes/routes";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import { end_points } from "../../../services/endpoints";
import { deleteData, getData } from "../../../services/api.service";
import { useDispatch } from "react-redux";
import { capitalizeFirstLetter, formatDateString } from "../../../utils/constants/index.";
import PrimeDataTable from "../../../components/data-table";
import { ConfirmDialog } from "primereact/confirmdialog";


const ManageVideos = () => {

  const [rows, setRows] = useState<number>(10);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);


  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item._id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    announceId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(announceId);
    } else {
      newSelectedRows.delete(announceId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const accept = () => {
    if (selectedUser) {
      deleteVideo(selectedUser._id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedUser(data);
    setVisible(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data._id)}
              onChange={(e) => handleRowCheckboxChange(e, data._id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Video",
      field: "title",
      key: "title",
      sortField: "title",
      body: (data: any) => {
        const shortDescription = data?.description || '';
        const charLimit = 80;
        const trimDescription =
          shortDescription.length > charLimit
            ? shortDescription.slice(0, charLimit)
            : shortDescription;
        return (
          <>
            <div className="media-blk d-flex">
              <div className="img-blk">
                <img src={data?.thumbnailUrl} className="img img-fluid" alt="" />
              </div>
              <div className="media-content">
                <h5>{capitalizeFirstLetter(data?.title)}</h5>
                <p>{`${trimDescription}${shortDescription.length > charLimit ? '...' : ''}`}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      header: "Restrictions",
      field: "age_restriction",
      key: "age_restriction",
      sortField: "age_restriction",
      body: (data: any) => (
        <>
            {data.age_restriction === "No, don't restrict my video to viewers over 18 only" ? 'Suitable for All Ages' : 'Made for Adults'}
        </>
    ),
    },

    {
      header: "Date Posted",
      field: "createdAt",
      key: "createdAt",
      sortField: "createdAt",
      body: (data: any) => (
        <>{formatDateString(data.createdAt)}
        </>
        )
    },
    {
      header: "Views",
      field: "total_views",
      key: "total_views",
      sortField: "total_views",
      body: (data: any) => (
        <>{data.total_views}
        </>
        )
    },
    {
      header: "Likes",
      field: "total_likes",
      key: "total_likes",
      sortField: "total_likes",
      body: (data: any) => (
        <>{data.total_likes}
        </>
        )
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="flex-style flex-icon">
              <a
              onClick={() => {
                handleStatusUpdate(data);
              }}>
                {data?.status === "active" ?
                (
                  <i className="ti ti-eye" />
                ):(
                  <i className="ti ti-eye-off" />
                )}
              </a>
              <a
              onClick={() => {
                handleDeleteClick(data);
              }}>
                <i className="ti ti-trash" />
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const handleStatusUpdate = async (data:any) => {
    try {
      let url = end_points.statusUpdateVideos.url;
      url += `/${data?._id}`;
      const response = await getData(url);
      if (response.status === 201) {
        getList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteVideo = async (id: number) => {
    const url = `${end_points.deleteAddedVideos.url}/${id}`;
    if (id) {
      try {
        const response:any = await deleteData(url);
        if (response?.status === 201) {
          setVisible(false);
          getList();
        }
      } catch (error) {
      }
    }
  };

  const getList = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.listAddedVideos.url;
      if (page) {
        url += `?page=${page}&limit=${limit}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.data);
        setTotalRecords(response?.data?.data?.total_records);
      }
    } catch (error) {
      console.error(error);
    }finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
  }, [currentPage, rows]);


  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-sm-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>
                      <Link to="#">Videos</Link>
                    </li>
                    <li>Manage Videos</li>
                  </ul>
                  <Link
                    to={routes.addVideo}
                    className="btn mb-2 mb-sm-0 btn-add-outline ms-sm-auto"
                  >
                    <i className="ti ti-plus me-2" />
                    Add Video
                  </Link>
                </div>
                <p>
                Manage Videos is an intuitive platform for organizing, editing, and sharing videos.
                </p>
              </div>
              <div className="custom-card">
                <div className="table-top-blk mb-20 p-0 flex-style">
                  <p>
                    Total Videos: <span>{totalRecords} Videos</span>
                  </p>
                  {/* <div className="ms-auto filter-div border-0 m-0 p-0">
                    <div className="d-flex align-items-center flex-wrap row-gap-2 dropdown-manage dropdown-manage">
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Sort by: Date Created{" "}
                          <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1" />
                                Descending
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Viewed
                              </Link>
                            </li>
                            <li>
                              <Link
                                to="#"
                                className="dropdown-item"
                              >
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Added
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="table-responsive">
                  <PrimeDataTable
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* /Delete */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this video?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default ManageVideos;
