import React, { useEffect, useState } from "react";
import { Link, useFetcher } from "react-router-dom";
import { Calendar } from "primereact/calendar";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  maxNpiNumber,
  onlyAplhaNumHyp,
  onlyNumber,
} from "../../../../utils/patterns/regex.pattern";
import ErrorMessage from "../../../../components/errorMessage";
import {
  certificationNumberSchema,
  insuranceExpiryDateSchema,
  licenseNumberSchema,
  npiNumberSchema,
} from "../../../authentication/components/yupSchema";
import { yup } from "../../../../utils/shared.module";
import moment from "moment";
import InputMask from "react-input-mask";
interface props {
  userDetails: any;
  npiFormData: any;
  GetParentDetails: () => void;
}

const initialValues = {
  npi: "",
  license_number: "",
  certificate_number: "",
  expiration_date: null,
};

const NpiProfessionalInsurance = (props: props) => {
  const currentYear = new Date().getFullYear();
  const currentMonth = String(new Date().getMonth() + 1).padStart(2, "0");
  const { userDetails, GetParentDetails } = props;
  const [isEditable, setIsEditable] = useState(false);
  const [date, setDate] = useState("");
  const [error, setError] = useState("");
  const [formDetails, setFormDetails] = useState(initialValues);

  const NpiFormSchema: any = yup.object().shape({
    npi: npiNumberSchema,
    license_number: licenseNumberSchema,
    certificate_number: certificationNumberSchema,
    expiration_date: yup.string().required("Expiration date is required"),
  });
  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    defaultValues: formDetails,
    resolver: yupResolver(NpiFormSchema),
  });

  const validateAndCorrectDate = (input) => {
    let [month, year] = input.split("/");
    if (!month || !year || month.length !== 2 || year.length !== 4) {
      return input;
    }
    let monthNum = Number(month);
    let yearNum = Number(year);
    if (monthNum > 12) {
      monthNum = 12;
    }
    if (monthNum < 10 && monthNum >= 1) {
      month = `0${monthNum}`;
    } else {
      month = String(monthNum);
    }
    if (yearNum < currentYear) {
      year = currentYear;
      if (monthNum < Number(currentMonth)) {
        month = Number(currentMonth) + 1;
      }
    }
    if (yearNum === currentYear) {
      if (monthNum < Number(currentMonth)) {
        month = Number(currentMonth) + 1;
      }
    }
    return `${month}/${year}`;
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const dateValue = validateAndCorrectDate(value);
    setDate(dateValue);
    console.log("dateValue", dateValue?.length);
    if (dateValue?.length == 7) {
      setValue("expiration_date", dateValue);
    } else {
      setValue("expiration_date", null);
    }
  };

  const onSubmit = async (data: any) => {
    let [month, year] = date.split("/");
    let formatedDate = `${year}/${month}`;
    try {
      const payload = {
        ...data,
        expiration_date: formatedDate,
      };
      const response = await putData(
        `${end_points.settings_professional_insurance.url}`,
        payload
      );
      if (response?.status < 400) {
        GetParentDetails();
        setIsEditable(false);
      }
    } catch (error) {
    } finally {
      reset();
    }
  };

  useEffect(() => {
    if (
      props?.npiFormData !== undefined &&
      props?.npiFormData !== null &&
      Object.keys(props?.npiFormData)?.length > 0
    ) {
      reset(props?.npiFormData);
      setDate(props?.npiFormData?.expiration_date);
      if (props?.npiFormData?.npi == undefined) {
        setIsEditable(true);
      }
    }
  }, [props?.npiFormData]);

  return (
    <div className="custom-card custom-card-info">
      <div className="card-head d-flex align-items-center">
        <h4>NPI &amp; Professional Insurance</h4>
        <div className="cta-blk ms-auto">
          {isEditable && (
            <Link
              to="#"
              className="btn btn-sm-cancel"
              onClick={() => {
                setIsEditable(false);
                setError("");
                setDate(props?.npiFormData?.expiration_date);
                reset();
              }}
            >
              Cancel
            </Link>
          )}

          <Link
            to="#"
            className="btn btn-sm-edit"
            onClick={() => {
              if (isEditable) {
                trigger().then((resp) => {
                  if (resp) {
                    onSubmit(getValues());
                  }
                });
              } else {
                setIsEditable(true);
              }
            }}
          >
            {isEditable ? "Save" : "Edit"}
          </Link>
        </div>
      </div>
      <form onSubmit={handleSubmit(onSubmit)} className="inline-form">
        <div className="inline-input">
          <div className="row align-items-center">
            <div className="col-md-6 pass-helper">
              <label className="col-form-label d-block mb-0 pb-0">
                NPI Number
              </label>
              <span>Your 10 digit National Provider Identifier</span>
            </div>
            <div className="col-md-6">
              <Controller
                name="npi"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control"
                    placeholder="NPI number"
                    {...register("npi")}
                    onKeyDown={(event) => {
                      const regex = onlyNumber;
                      if (!regex.test(event.key) && event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => trigger("npi")}
                    maxLength={maxNpiNumber}
                    disabled={!isEditable}
                  />
                )}
              />
              <ErrorMessage value={errors.npi} />
            </div>
          </div>
        </div>
        <div className="inline-input">
          <div className="row align-items-center">
            <div className="col-md-6">
              <label className="col-form-label">License Number</label>
            </div>
            <div className="col-md-6">
              <Controller
                name="license_number"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control"
                    placeholder="License number"
                    {...register("license_number")}
                    onKeyDown={(event) => {
                      const regex = onlyAplhaNumHyp;
                      if (!regex.test(event.key) && event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => trigger("license_number")}
                    maxLength={maxNpiNumber}
                    disabled={!isEditable}
                  />
                )}
              />
              <ErrorMessage value={errors.license_number} />
            </div>
          </div>
        </div>
        <div className="inline-input">
          <div className="row align-items-center">
            <div className="col-md-6">
              <label className="col-form-label">Certfication number</label>
            </div>
            <div className="col-md-6">
              <Controller
                name="certificate_number"
                control={control}
                render={({ field }) => (
                  <input
                    className="form-control"
                    placeholder="Certification number"
                    {...register("certificate_number")}
                    onKeyDown={(event) => {
                      const regex = onlyAplhaNumHyp;
                      if (!regex.test(event.key) && event.key !== "Backspace") {
                        event.preventDefault();
                      }
                    }}
                    onBlur={() => trigger("certificate_number")}
                    maxLength={maxNpiNumber}
                    disabled={!isEditable}
                  />
                )}
              />
              <ErrorMessage value={errors.certificate_number} />
            </div>
          </div>
        </div>
        <div className="inline-input">
          <div className="row align-items-center">
            <div className="col-md-6">
              <label className="col-form-label">
                Expiration Date (MM/YYYY)
              </label>
            </div>
            <div className="col-md-6">
              <Controller
                name="expiration_date"
                control={control}
                render={({ field }) => (
                  <InputMask
                    mask="99/9999"
                    value={date}
                    placeholder="MM/YYYY"
                    maskChar="_"
                    onChange={handleChange}
                    disabled={!isEditable}
                    onBlur={() => trigger("expiration_date")}
                  >
                    {(inputProps: any) => (
                      <input
                        {...inputProps}
                        type="text"
                        id="date-input"
                        className="form-control"
                        disabled={!isEditable}
                        onKeyDown={(e) => {
                          const { key } = e;
                          // / date?.length !== 7 &&
                          if (key === "ArrowRight" || key === "ArrowLeft") {
                            e.preventDefault();
                          }
                        }}
                      />
                    )}
                  </InputMask>
                )}
              />

              <ErrorMessage value={errors.expiration_date} />
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};
export default NpiProfessionalInsurance;
