import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Chips } from "primereact/chips";
import { blog26, blog24, blog25 } from "../../utils/imagepath";

const WrittenContent = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const categories = [
    { label: "Category 1", value: "category1" },
    { label: "Category 2", value: "category2" },
  ];

  const subCategory = [
    { label: "Option 1", value: "option1" },
    { label: "Option 2", value: "option2" },
  ];

  const group = [
    { label: "Group 1", value: "group1" },
    { label: "Group 2", value: "group2" },
  ];

  const [rangeText, setRangeText] = useState("");
  const [value, setValue] = useState([
    "Mental Health",
    "Well-being",
    "Anxiety",
  ]);

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Directory</Link>
              </li>
              <li>written content</li>
            </ul>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-12">
                      <div className="written-content">
                        <h6>
                          <span>Content Submission Guidelines:</span> When
                          submitting content for publication, please follow
                          these guidelines to ensure that your content is
                          suitable and meets our standards. These rules are
                          designed to maintain the quality and integrity of our
                          platform while providing a positive experience for all
                          users.
                        </h6>
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Subject</label>
                        <input
                          type="text"
                          placeholder="Enter subject"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="mb-3">
                        <label className="form-label">Category</label>
                        <Dropdown
                          options={categories}
                          value={selectedCategory}
                          onChange={(e) => setSelectedCategory(e.value)}
                          placeholder="Select a Category"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="mb-3">
                        <label className="form-label">Subcategory</label>
                        <Dropdown
                          options={subCategory}
                          value={selectedSubCategory}
                          onChange={(e) => setSelectedSubCategory(e.value)}
                          placeholder="Select here"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 col-12">
                      <div className="mb-3">
                        <label className="form-label">Group</label>
                        <Dropdown
                          options={group}
                          value={selectedGroup}
                          onChange={(e) => setSelectedGroup(e.value)}
                          placeholder="Select here"
                          className="select w-100"
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">keywords</label>
                        <Chips
                          className="w-100"
                          value={value}
                          onChange={(e: any) => setValue(e.value)}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Content</label>
                        <textarea
                          className="form-control"
                          placeholder="Enter Content"
                          defaultValue={""}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="mb-3">
                        <label className="form-label">Upload Photos</label>
                        <div className="upload-div">
                          <div className="upload-file">
                            <input type="file" />
                            <div className="upload-content">
                              <h6>
                                <i className="ti ti-upload me-2"> </i>Drag and
                                Drop files here or  <span className="ms-1">choose file</span>
                              </h6>
                            </div>
                          </div>
                          <div className="upload-para">
                            <h6>Note: You can select multiple photos here.</h6>
                            <h6>Maximum size: 5MB</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-9 col-12">
                      <div className="mb-3">
                        <label className="form-label">Insert Link</label>
                        <input
                          type="text"
                          placeholder="Enter Link"
                          className="form-control"
                        />
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="mb-3">
                        <label className="form-label">Cost per Link</label>
                        <div className="append-right">
                          <input
                            type="text"
                            placeholder="$10"
                            className="form-control"
                          />
                          <h6>
                            <i className="ti ti-info-circle-filled" />
                            <div className="cost-tooltip">
                              <h5>
                                <i className="ti ti-info-circle-filled me-2" />
                                Cost Per Link
                              </h5>
                              <p>
                                This is a fee that users must pay to submit or
                                promote their content through a link.
                              </p>
                            </div>
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="text-end mt-3">
                        <Link to="#" className="btn btn-submit">
                          Submit for review
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card">
                <div className="card-header">
                  <h4>Content Submitted</h4>
                  <div className="filter-div">
                    <div className="d-flex align-items-center flex-wrap row-gap-2">
                      <div className="icon-form">
                        <span className="form-icon">
                          <i className="ti ti-calendar" />
                        </span>
                        <DateRangePicker initialSettings={initialSettings}>
                          <input
                            type="text"
                            className="form-control bookingrange"
                            value={
                              rangeText ||
                              `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                            }
                            readOnly
                          />
                        </DateRangePicker>
                      </div>
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Descending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Viewed
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Added
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="card-body pt-2">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Image</th>
                          <th>Subject</th>
                          <th>Date written</th>
                          <th>Status</th>
                          <th />
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="danger-tr">
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog26} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Healthy Relationship with your..</td>
                          <td>Mental health encompasses emotional, ..</td>
                          <td>
                            <span className="badge-content-white dot-danger">
                              Revisions Requested
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog24} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Mental health Therapy</td>
                          <td>
                            Regular physical activity can boost mood and...
                          </td>
                          <td>
                            <span className="badge-content-white dot-posted">
                              Posted
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog25} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Healthy Relationship with your..</td>
                          <td>Mental health encompasses emotional, ..</td>
                          <td>
                            <span className="badge-content-white dot-review">
                              In Review
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr className="danger-tr">
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog26} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Healthy Relationship with your..</td>
                          <td>Mental health encompasses emotional, ..</td>
                          <td>
                            <span className="badge-content-white dot-danger">
                              Revisions Requested
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog24} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Mental health Therapy</td>
                          <td>
                            Regular physical activity can boost mood and...
                          </td>
                          <td>
                            <span className="badge-content-white dot-posted">
                              Posted
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <div className="table-avatar">
                              <Link to="#">
                                <img src={blog25} alt="" />
                              </Link>
                            </div>
                          </td>
                          <td>Healthy Relationship with your..</td>
                          <td>Mental health encompasses emotional, ..</td>
                          <td>
                            <span className="badge-content-white dot-review">
                              In Review
                            </span>
                          </td>
                          <td>
                            <div className="flex-style flex-icon">
                              <Link to="#">
                                <i className="ti ti-eye-off" />
                              </Link>
                              <Link to="#">
                                <i className="ti ti-trash" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="pagination-div">
                    <Link to="#" className="previous-next-link disabled">
                      <i className="ti ti-chevron-left me-1" />
                      Previous
                    </Link>
                    <ul>
                      <li>
                        <Link to="#" className="active">
                          1
                        </Link>
                      </li>
                      <li>
                        <Link to="#">2</Link>
                      </li>
                      <li>
                        <Link to="#">3</Link>
                      </li>
                      <li>
                        <Link to="#">...</Link>
                      </li>
                      <li>
                        <Link to="#">8</Link>
                      </li>
                      <li>
                        <Link to="#">9</Link>
                      </li>
                      <li>
                        <Link to="#">10</Link>
                      </li>
                    </ul>
                    <Link to="#" className="previous-next-link">
                      Next
                      <i className="ti ti-chevron-right ms-1" />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default WrittenContent;
