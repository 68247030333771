import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  personalInformationMaxLength,
  stringAllowedKeys,
} from "../../../../utils/patterns/regex.pattern";
import {
  appointmentModeSchema,
  availablitySchema,
  designationSchema,
  emailSchema,
  nameSchema,
  personalStatementSchema,
  phoneNumberSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef } from "react";
import { translate } from "../../../../utils/translate/translate";
import {
  editDoubleQuotes,
  userLine,
  vidiconLine,
} from "../../../../utils/imagepath";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
}

const AvailablityModal = (props: props) => {
  const availablityRef = useRef();
  const { designationsList, getDirectoryData } = props;
  const { type, open, data, userDetails } = props.visible;

  const initialValues = {
    in_person: false,
    virtual_consultation: false,
    intro: "",
  };

  const basicModelValidation = Yup.object().shape({
    intro: availablitySchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    availablityRef.current.click();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        availability: {
          ...data,
        },
      };
      const response = await postData(
        `${end_points.profile_about_availablity.url}`,
        payload
      );

      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data)?.length > 0) {
      reset(data);
    }
  }, [data]);

  return (
    <div
      className="modal fade phone-modal finances-modal"
      id="availability-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header add-modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Availability</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={availablityRef}
            />
          </div>

          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <div className="edit-availability-text edit-availability-info">
                  <ul>
                    <li>
                      <img src={userLine} alt="Icon" /> In-Person
                      <div className="active-switch">
                        <Controller
                          name="in_person"
                          control={control}
                          render={({ field }) => (
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="optradio"
                                checked={field?.value}
                                onChange={(e) => {
                                  setValue("in_person", e.target.checked);
                                }}
                              />
                              <span className="sliders round" />
                            </label>
                          )}
                        />
                      </div>
                    </li>
                    <li>
                      <img src={vidiconLine} alt="Icon" /> Virtual Consultations
                      <div className="active-switch">
                        <Controller
                          name="virtual_consultation"
                          control={control}
                          render={({ field }) => (
                            <label className="switch">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                name="optradio"
                                checked={field?.value}
                                onChange={(e) => {
                                  setValue(
                                    "virtual_consultation",
                                    e.target.checked
                                  );
                                }}
                              />
                              <span className="sliders round" />
                            </label>
                          )}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="form-wrap">
                <div className="about-edit-head about-clients-head">
                  <h4>
                    <img src={editDoubleQuotes} alt="Icon" /> Intro for new
                    clients
                  </h4>
                </div>
                <div className="availability-textarea">
                  <Controller
                    name="intro"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        className="form-control"
                        placeholder="Type Here"
                        defaultValue={""}
                        value={field.value}
                        maxLength={personalInformationMaxLength}
                        onChange={(e) => {
                          setValue("intro", e.target.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage value={errors.intro} />
                </div>
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailablityModal;
