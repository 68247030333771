import React, { useContext, useEffect, useRef, useState } from 'react';
import OT from '@opentok/client';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import VideoControls from './videoControl';
import ScreenshotModal from './screenshot';
import { end_points } from '../../../../../../services/end_point/end_points';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import {
  setPrescriptionDrugTableData,
  setSymtomsFlowData,
} from '../../../../../../core/redux/commonSlice';
import {
  ApiServiceContext,
  routes,
} from '../../../../../../utils/shared.module';
import { SocketContext } from '../../../../../../services/socket/socketioClient';

interface SessionDetails {
  _id: string;
  vonage_api_key: string;
  session_id: string;
  vonage_token: string;
  paramedic_id: string;
}

interface Subscriber {
  stream: OT.Stream;
  subscriber: OT.Subscriber | null;
  recorder: MediaRecorder | null;
  chunks: Blob[];
}

const VideoLayout: React.FC = () => {
  const subscriberContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateLayout = () => {
      const container = subscriberContainerRef.current;
      if (!container) return;

      const subscribers = Array.from(
        container.querySelectorAll('.subscriber-video-item'),
      );

      const reversedSubscribers = subscribers.reverse();

      reversedSubscribers.forEach((sub: Element, index: number) => {
        const subscriberElement = sub as HTMLElement;

        if (subscribers?.length === 1 && index === 0) {
          // Newest subscriber - main screen
          subscriberElement.style.width = '100%';
          subscriberElement.style.height = '100%';
          subscriberElement.style.position = 'absolute';
          subscriberElement.style.top = '0';
          subscriberElement.style.left = '0';
          subscriberElement.style.zIndex = '0';
        } else if (
          subscribers?.length >= 2 &&
          index > 0 &&
          subscribers?.length === index + 1
        ) {
          // Earlier subscriber - small popup bottom right
          subscriberElement.style.inset = '';
          subscriberElement.style.width = '110px';
          subscriberElement.style.height = '160px';
          subscriberElement.style.position = 'absolute';
          subscriberElement.style.bottom = '10px';
          subscriberElement.style.right = '10px';
          subscriberElement.style.zIndex = '1';
          subscriberElement.style.borderRadius = '16px';
          subscriberElement.style.objectFit = 'contain';
          subscriberElement.style.objectPosition = 'center';
          subscriberElement.style.display = 'flex';
          subscriberElement.style.alignItems = 'center';
          subscriberElement.style.justifyContent = 'center';
        }
      });
    };

    const observer = new MutationObserver(updateLayout);

    if (subscriberContainerRef.current) {
      observer.observe(subscriberContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    updateLayout();

    return () => observer.disconnect();
  }, []);

  return (
    <div
      id="subscriber"
      ref={subscriberContainerRef}
      style={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
      }}
    />
  );
};

const VideoCall: React.FC<any> = ({ onSuccessScreenShot = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { putData, postData } = useContext(ApiServiceContext);

  const [otSession, setOtSession] = useState<OT.Session | null>(null);
  const [subscribers, setSubscribers] = useState<Map<string, Subscriber>>(
    new Map(),
  );
  const [recordingChunks, setRecordingChunks] = useState<Blob[]>([]);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null,
  );
  const [isEndingCall, setIsEndingCall] = useState(false);
  const [isUploadingRecordings, setIsUploadingRecordings] = useState(false);
  const [allRecordingsStopped, setAllRecordingsStopped] = useState(false);
  const [screenshot, setScreenshot] = useState<string | null>(null);
  const [deviceType, setDeviceType] = useState<string>('');

  const patient_id: any = useSelector(
    (state: any) => state.common.editData?.patient_id,
  );
  const publisherRef = useRef<OT.Publisher | null>(null);
  const modalRef = useRef<HTMLButtonElement>(null);

  const sessionDetails = useSelector<any, SessionDetails>(
    (state) => state.common.sessionDetails,
  );
  const flowData: any = useSelector<any>((state) => state.common.flowData);
  const doctor_id: any = useSelector(
    (state: any) => state?.login?.userDetails?.id,
  );

  const appointmentId = sessionDetails?._id || flowData?._id;
  const paramedicId = sessionDetails?.paramedic_id || flowData?.paramedic_id;

  const screenShotValidation = yup.object().shape({
    description: yup.string().trim().required('Description is required'),
  });

  const { handleSocketEvents } = useContext(SocketContext);

  const {
    handleSubmit,
    control,
    trigger,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(screenShotValidation),
    defaultValues: { description: '' },
  });

  const callBack = (streamDevice: any = {}) => {
    try {
      let device: any = streamDevice || {};
      const { slug } = device || {};
      slug &&
        setDeviceType(
          slug === 'DERMASCOPE'
            ? 'dermoscope'
            : slug === 'AUTOSCOPE'
              ? 'autoscope'
              : '',
        );
    } catch (e) {}
  };

  useEffect(() => {
    if (doctor_id) {
      handleSocketEvents(
        'SteamDeviceCall',
        `CC_device_${doctor_id}`,
        '',
        callBack,
      );
    }
  }, [doctor_id, handleSocketEvents]);

  const stopAllRecordings = () => {
    return new Promise<void>((resolve) => {
      const updatedSubscribers = new Map(subscribers);
      let stoppedCount = 0;

      subscribers.forEach((subscriber, streamId) => {
        if (subscriber.recorder && subscriber.recorder.state !== 'inactive') {
          subscriber.recorder.onstop = () => {
            updatedSubscribers.set(streamId, {
              ...subscriber,
              isRecordingStopped: true,
            });
            stoppedCount++;

            if (stoppedCount === subscribers.size) {
              setSubscribers(updatedSubscribers);
              setAllRecordingsStopped(true);
              resolve();
            }
          };
          subscriber.recorder.stop();
        } else {
          stoppedCount++;
          if (stoppedCount === subscribers.size) {
            setAllRecordingsStopped(true);
            resolve();
          }
        }
      });

      if (subscribers.size === 0) {
        setAllRecordingsStopped(true);
        resolve();
      }
    });
  };

  const startRecording = (stream: MediaStream, streamId: string) => {
    try {
      const videoElement = document.querySelector(
        `#subscriber-${streamId} video`,
      ) as HTMLVideoElement;

      if (!videoElement?.srcObject) {
        throw new Error('No valid video source found');
      }

      const recorder = new MediaRecorder(videoElement.srcObject as MediaStream);
      setMediaRecorder(recorder);
      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          setRecordingChunks((prev) => [...prev, event.data]);
          setSubscribers((prev) => {
            const updatedMap = new Map(prev);
            const subscriber = updatedMap.get(streamId);
            if (subscriber) {
              updatedMap.set(streamId, {
                ...subscriber,
                chunks: [...subscriber.chunks, event.data],
              });
            }
            return updatedMap;
          });
        }
      };

      recorder.start(1000); // Record in 1-second chunks

      setSubscribers((prev) => {
        const updatedMap = new Map(prev);
        const subscriber = updatedMap.get(streamId);
        if (subscriber) {
          updatedMap.set(streamId, {
            ...subscriber,
            recorder,
          });
        }
        return updatedMap;
      });
    } catch (error) {
      console.error('Recording error:', error);
    }
  };
  const handleCallEnd = async () => {
    try {
      await Promise.all([
        putData(`${end_points.disconnectVideoCall.url}/${appointmentId}`, {
          paramedic_id: paramedicId,
          status: 'call decline',
        }),
        putData(`${end_points.videoCallEnd.url}/${appointmentId}`, {
          paramedic_id: paramedicId,
          // ...sessionDetails,
        }),
      ]);

      dispatch(setPrescriptionDrugTableData([]));
      dispatch(setSymtomsFlowData({}));
      navigate(routes.pastConsults.path);
    } catch (error) {
      console.error('Error during call end:', error);
      navigate(routes.pastConsults.path);
    }
  };
  const uploadRecordings = async (chunks: Blob[] = []) => {
    try {
      showLoader(
        'Please wait...Recorded videos are uploading. Do not refresh the page',
      );

      if (chunks?.length) {
        const videoBlob = new Blob(chunks, {
          type: 'video/webm',
        });
        const formData = new FormData();
        formData.append(
          'screenrecording_attachment',
          videoBlob,
          `call-recording.webm`,
        );

        await putData(
          `${end_points.vedioRecordingApi.url}/${appointmentId}`,
          formData,
        );
        await handleCallEnd();
      } else {
        const uploadPromises = Array.from(subscribers.values()).map(
          async (subscriber) => {
            const videoBlob = new Blob(subscriber.chunks, {
              type: 'video/webm',
            });
            const formData = new FormData();
            formData.append(
              'screenrecording_attachment',
              videoBlob,
              `call-recording-${subscriber.stream.streamId}.webm`,
            );

            return putData(
              `${end_points.vedioRecordingApi.url}/${appointmentId}`,
              formData,
            );
          },
        );

        await Promise.allSettled(uploadPromises);
        await handleCallEnd();
      }
    } catch (error) {
      console.error('Error sending video recordings:', error);
      await handleCallEnd();
    } finally {
      hideLoader('video');
    }
  };

  useEffect(() => {
    if (isEndingCall && subscribers.size > 0) {
      // Stop all recorders first
      subscribers.forEach((subscriber) => {
        if (subscriber.recorder && subscriber.recorder.state !== 'inactive') {
          subscriber.recorder.stop();
        }
      });

      // Upload recordings after stopping
      uploadRecordings();
    } else if (isEndingCall && subscribers.size === 0) {
      if (recordingChunks?.length) {
        uploadRecordings(recordingChunks);
      } else {
        handleCallEnd();
      }
    }
  }, [isEndingCall, subscribers.size, recordingChunks]);

  const endCall = () => {
    if (mediaRecorder && mediaRecorder.state !== 'inactive') {
      uploadRecordings();
      mediaRecorder.stop();
    }
    publisherRef?.current?.destroy();
    otSession?.disconnect();
    setIsEndingCall(true);
  };

  useEffect(() => {
    if (
      !sessionDetails?.vonage_api_key ||
      !sessionDetails?.session_id ||
      !sessionDetails?.vonage_token
    ) {
      console.error('Missing session credentials');
      return;
    }

    const session = OT.initSession(
      sessionDetails.vonage_api_key,
      sessionDetails.session_id,
    );
    setOtSession(session);

    const handleStreamCreated = (event: any) => {
      const streamId = event.stream.streamId;
      const subscriberContainer = document.createElement('div');
      subscriberContainer.id = `subscriber-${streamId}`;
      subscriberContainer.className = 'subscriber-video-item';
      document.getElementById('subscriber')?.appendChild(subscriberContainer);

      const subscriber = session.subscribe(
        event.stream,
        `subscriber-${streamId}`,
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        (error) => {
          if (error) {
            console.error('Subscription error:', error);
            return;
          }

          setSubscribers((prev) => {
            const updatedMap = new Map(prev);
            updatedMap.set(streamId, {
              stream: event.stream,
              subscriber,
              recorder: null,
              chunks: [],
            });
            return updatedMap;
          });

          startRecording(event.stream, streamId);
        },
      );
    };

    const handleStreamDestroyed = async (event: any) => {
      const streamId = event.stream.streamId;
      const subscriber = subscribers.get(streamId);

      if (subscriber?.recorder && subscriber.recorder.state !== 'inactive') {
        subscriber.recorder.stop();
      }

      document.querySelector(`#subscriber-${streamId}`)?.remove();

      if (!isEndingCall) {
        setSubscribers((prev) => {
          const updatedMap = new Map(prev);
          updatedMap.delete(streamId);
          return updatedMap;
        });
      }
    };

    session.on('streamCreated', handleStreamCreated);
    session.on('streamDestroyed', handleStreamDestroyed);

    session.connect(sessionDetails.vonage_token, (error) => {
      if (error) {
        console.error('Connection error:', error);
        return;
      }

      const publisher = OT.initPublisher(
        'publisher',
        {
          insertMode: 'append',
          width: '100%',
          height: '100%',
        },
        (pubError) => {
          if (pubError) {
            console.error('Publisher initialization error:', pubError);
          }
        },
      );

      session.publish(publisher, (pubError) => {
        if (pubError) {
          console.error('Publishing error:', pubError);
        }
      });

      publisherRef.current = publisher;
    });

    return () => {
      subscribers.forEach((subscriber) => {
        if (subscriber.recorder && subscriber.recorder.state !== 'inactive') {
          subscriber.recorder.stop();
        }
        subscriber.subscriber?.destroy();
      });
      session.disconnect();
    };
  }, [sessionDetails]);

  const takeScreenshot = () => {
    const lastSubscriberId = Array.from(subscribers.keys()).pop();
    const videoElement = document.querySelector(
      `#subscriber-${lastSubscriberId} video`,
    ) as HTMLVideoElement;

    if (!videoElement) {
      console.error('Video element not found');
      return;
    }

    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = videoElement.videoWidth;
    canvas.height = videoElement.videoHeight;
    context?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);

    setScreenshot(canvas.toDataURL('image/png'));
    if (document.fullscreenElement) {
      alert('PACS data has been captured. Exit fullscreen mode to view.');
    }
  };

  const handleScreenshotSubmit = async (data: { description: string }) => {
    if (!screenshot) return;

    try {
      showLoader();

      const formData = new FormData();
      formData.append(
        'screenshot_image',
        base64ToFile(screenshot, 'screenshot.png'),
      );
      formData.append('description', data?.description || '');
      formData.append('type', deviceType || '');

      let url =
        deviceType === 'autoscope'
          ? 'patient-details/autoscope/create'
          : deviceType === 'dermoscope'
            ? 'patient-details/dermoscope/create'
            : '';

      if (patient_id) {
        url += `/${patient_id}/${appointmentId}`;
      }

      if (url) {
        let keyName: string =
          deviceType === 'autoscope'
            ? 'autoscope_add_note'
            : 'dermoscope_add_note';
        const [response, responseNotes] = await Promise.all([
          putData(
            `${end_points.screenShotSave.url}/${appointmentId}`,
            formData,
          ),
          postData(url, {
            [keyName]: data?.description,
          }),
        ]);
        if (response?.status === 200) {
          if (modalRef?.current) modalRef.current?.click();
          onSuccessScreenShot();
          reset();
        }
      } else {
        const response = await putData(
          `${end_points.screenShotSave.url}/${appointmentId}`,
          formData,
        );
        if (response?.status === 200) {
          if (modalRef?.current) modalRef.current?.click();
          onSuccessScreenShot();
          reset();
        }
      }
    } finally {
      hideLoader();
    }
  };

  const base64ToFile = (base64String: string, filename: string): File => {
    const arr = base64String.split(',');
    const mime = arr[0].match(/:(.*?);/)?.[1] || 'image/png';
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  };
  // useEffect(() => {
  //   console.log(recordingChunks, 'recordingChunks');
  // }, [recordingChunks]);
  return (
    <>
      <div className="video-container">
        <div id="publisher" className="video-item" />
        <VideoLayout />
        <VideoControls
          onScreenshot={takeScreenshot}
          onEndCall={endCall}
          subscribers={subscribers?.size || 0}
          onToggleFullscreen={() => {
            const container = document.querySelector('.video-container');
            if (!document.fullscreenElement) {
              container?.requestFullscreen();
            } else {
              document.exitFullscreen();
            }
          }}
        />
      </div>

      <ScreenshotModal
        ref={modalRef}
        screenshot={screenshot}
        control={control}
        errors={errors}
        trigger={trigger}
        onSubmit={handleSubmit(handleScreenshotSubmit)}
      />
    </>
  );
};

export default VideoCall;
