import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { autoGenerate, cal, uploadFile } from "../../../utils/imagepath";
import { Editor } from "primereact/editor";
import { Calendar } from "primereact/calendar";
import moment from "moment";
import * as Yup from "yup";
import { Chips } from "primereact/chips";
import {
  personalInformationMaxLength,
  validImageTypes,
} from "../../../utils/patterns/regex.pattern";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../components/errorMessage";
import ImageCropper from "../../../components/imageCropper/imageCropper";
import { postData, putData } from "../../../services/api.service";
import { useSelector } from "react-redux";
import { end_points } from "../../../services/endpoints";
import {
  dateFormat_YYYY_MM_DD,
  formatTimeTo12Hour,
} from "../../../utils/constants/index.";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import { routes } from "../../../utils/routes/routes";
import { setEditData } from "../../../core/redux/commonSlice";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Title is required"),
  short_description: Yup.string().required("Description is required").trim(),
  begin_time: Yup.string().required("Start time is required"),
  end_time: Yup.string().required("End time is required"),
  feature_image: Yup.mixed()
    .test("image.value", "Please upload an image", (value: any) => {
      if (value == "") {
        return false;
      } else return true;
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    })
    .required("Please upload an image"),
  editor_content: Yup.string().trim().required("Editor content is required"),
  date_range: Yup.date().required("Event date is required"),
  attached_files: Yup.array()
    .of(
      Yup.mixed()
        .test(
          "is-url-or-file",
          "Unsupported file type. Only JPG, JPEG, PNG, PDF files are allowed",
          (file: any) => {
            // If it's a URL (existing file), skip validation
            if (
              typeof file === "string" &&
              (file.startsWith("http://") || file.startsWith("https://"))
            ) {
              return true;
            }
            // Otherwise, validate the file type
            return (
              file &&
              ["image/jpeg", "image/png", "application/pdf"].includes(file.type)
            );
          }
        )
        .test(
          "fileSize",
          "File size too large. Maximum size is 4MB",
          (file: any) => {
            // Skip validation if it's a URL
            if (
              typeof file === "string" &&
              (file.startsWith("http://") || file.startsWith("https://"))
            ) {
              return true;
            }
            // Otherwise, validate the file size
            return file && file.size <= 4194304; // 4MB limit
          }
        )
    )
    .min(1, "At least one file is required"),
  visibility: Yup.array()
    .min(1, "At least one visibility option must be selected")
    .required("Please select at least one visibility option"),
  tags: Yup.array()
    .min(1, "Please add at least one tag")
    .required("Tags are required"),
});

const AddAnnouncements = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      short_description: "",
      feature_image: null,
      begin_time: null,
      end_time: null,
      editor_content: "",
      date_range: null,
      attached_files: [],
      visibility: [],
      tags: [],
    },
  });

  const [rangeText, setRangeText] = useState("");
  const [chipValue, setChipValue] = useState<string[]>([]);
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);
  const editData = useSelector((state: any) => state.common.editData);
  const [remainingExistingFiles, setRemainingExistingFiles] = useState(
    editData?.upload_file || []
  );
  const [attachedFilesName, setAttachedFilesName] = useState(
    editData?.upload_file_names || []
  );

  const announcementId = editData?._id;

  const handleAttachmentChange = (e: any) => {
    const files = Array.from(e.target.files);

    // Validate and filter files
    const validFiles = files.filter((file: any) => {
      const isValidType = [
        "image/jpeg",
        "image/png",
        "application/pdf",
        "text/plain",
      ].includes(file.type);
      const isValidSize = file.size <= 4194304;
      return isValidType && isValidSize;
    });

    if (validFiles.length) {
      setUploadedFiles((prevFiles: any) => [...prevFiles, ...validFiles]); // Add new files
      setValue("attached_files", [
        ...remainingExistingFiles,
        ...uploadedFiles,
        ...validFiles,
      ]); // Update form state
      trigger("attached_files");
    }
  };

  const handleRemoveExistingFile = (index: any) => {
    // Remove the selected file from the remainingExistingFiles
    const updatedFiles = remainingExistingFiles.filter(
      (_: any, i: any) => i !== index
    );

    // Remove the corresponding file name from attachedFilesName
    const updatedFileNames = attachedFilesName.filter(
      (_: any, i: any) => i !== index
    );

    // Track the removed URL
    setRemovedUrls((prevUrls) => [
      ...prevUrls,
      remainingExistingFiles[index], // Add the removed file URL to the list
    ]);

    // Update state with the new file list and file names
    setRemainingExistingFiles(updatedFiles);
    setAttachedFilesName(updatedFileNames); // Update the file names state

    // Update form state with both new and remaining existing files
    setValue("attached_files", [...updatedFiles, ...uploadedFiles]);
    trigger("attached_files");
  };

  // Handle removal of new files
  const handleRemoveUploadedFile = (index: any) => {
    const updatedFiles = uploadedFiles.filter((_, i) => i !== index); // Remove the selected file
    setUploadedFiles(updatedFiles); // Update the state
    setValue("attached_files", [...remainingExistingFiles, ...updatedFiles]); // Update form state
    trigger("attached_files");
  };

  // Image Cropper
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [imageFile, setImageFile] = useState<any>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [cropperOpen, setCropperOpen] = useState(false);
  const [removedUrls, setRemovedUrls] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setImageFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageSrc(reader.result as string);
        setCropperOpen(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const onCropComplete = (croppedImage: string) => {
    const url = URL.createObjectURL(dataURLToBlob(croppedImage));
    setCroppedImageUrl(url);
    const croppedFile = dataURLToFile(croppedImage, imageFile.name);
    setValue("feature_image", croppedFile);
    trigger("feature_image");
  };

  const dataURLToBlob = (dataURL: string) => {
    const byteString = atob(dataURL.split(",")[1]);
    const mimeString = dataURL.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const dataURLToFile = (dataURL: string, filename: string) => {
    const blob = dataURLToBlob(dataURL);
    return new File([blob], filename, { type: "image/jpeg" });
  };

  const visibility = watch("visibility") || [];
  const handleCheckboxChange = (option: any, isChecked: any) => {
    const currentVisibility = visibility;

    if (isChecked) {
      // Add the option to the visibility array
      setValue("visibility", [...currentVisibility, option]);
    } else {
      // Remove the option from the visibility array
      setValue(
        "visibility",
        currentVisibility.filter(
          (visibilityOption: any) => visibilityOption !== option
        )
      );
    }
  };

  const handleStartDateChange = async (value: any) => {
    setValue("date_range", value);
    setValue("begin_time", null);
    setValue("end_time", null);
    trigger("date_range");
  };

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      let urls = announcementId
        ? end_points.update_announcement.url
        : end_points.add_announcement.url;
      if (announcementId) {
        urls += `/${announcementId}`;
      }
      const formData = new FormData();

      formData.append(`title`, data?.title);
      formData.append(`short_description`, data?.short_description);
      formData.append(`description`, data?.editor_content);
      formData.append("start_date", dateFormat_YYYY_MM_DD(data.date_range));
      // formData.append("end_date", moment(data.end_date).format("YYYY-MM-DD"));
      formData.append("start_time", formatTimeTo12Hour(data.begin_time));
      formData.append("end_time", formatTimeTo12Hour(data.end_time));
      data.visibility.forEach((item: any, index: number) => {
        formData.append(`visibility[${index}]`, item);
      });
      data.tags.forEach((item: any, index: number) => {
        formData.append(`tags[${index}]`, item);
      });
      if (data.feature_image) {
        formData.append("featured_image", data.feature_image);
      }

      data.attached_files.forEach((file: File) => {
        formData.append("upload_file", file);
      });

      if (announcementId) {
        removedUrls.forEach((url) => {
          formData.append("remove_url", url);
        });
      }
      const response = announcementId
        ? await putData(urls, formData)
        : await postData(urls, formData);
      if (response?.status === 201) {
        dispatch(setEditData(undefined));
        navigate(routes.announcements);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (announcementId) {
      setValue("title", editData.title || "");
      setValue("short_description", editData.short_description || "");
      const startTime = editData.start_time
        ? moment(editData.start_time, "HH:mm:ss").toDate()
        : null;
      const endTime = editData.end_time
        ? moment(editData.end_time, "HH:mm:ss").toDate()
        : null;

      setValue("begin_time", startTime || "");
      setValue("end_time", endTime || "");
      setValue("feature_image", editData.featured_image || "");
      setValue("editor_content", editData.description || "");
      const parsedDate = editData.start_date
        ? new Date(editData.start_date)
        : null;
      setValue("date_range", parsedDate);
      setValue("attached_files", [...editData.upload_file, ...uploadedFiles]);
      setRemainingExistingFiles(editData.upload_file || []);
      setAttachedFilesName(editData.upload_file_names || []);
      setValue("visibility", editData.visibility || []);
      setValue("tags", editData.tags || []);
      setChipValue(editData.tags);
    }
  }, [announcementId, editData, setValue]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <form id="addAnnoncementForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-blk">
                  <div className="d-flex flex-wrap align-items-start">
                    <ul className="flex-style">
                      <li>
                        <Link to="#">Announcements</Link>
                      </li>
                      {announcementId ? (
                        <li>Edit Announcements</li>
                      ) : (
                        <li>Add Announcements</li>
                      )}
                    </ul>
                  </div>
                  <p>
                    Lets you easily upload and organize your video content in a
                    streamlined interface.
                  </p>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="custom-card mb-3 mb-md-0">
                      <h3 className="title-h3">Announcements Details</h3>
                      <form>
                        <div className="row">
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Title</label>
                              <Controller
                                name="title"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Title"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[a-zA-Z\s]/.test(e.key) &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    maxLength={30}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      trigger("title");
                                    }}
                                    onBlur={() => trigger("title")}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.title} />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">
                                Featured Image
                              </label>
                              <div className="icon-forms">
                                <Controller
                                  name="feature_image"
                                  control={control}
                                  render={({ field }) => (
                                    <>
                                      <input
                                        type="text"
                                        placeholder={
                                          announcementId
                                            ? `${editData?.featured_image_name}`
                                            : "No file selected"
                                        }
                                        className="form-control"
                                        value={field.value?.name || ""}
                                        onClick={() =>
                                          document
                                            .getElementById("file-upload")
                                            ?.click()
                                        }
                                      />
                                      {/* Hidden file input */}
                                      <input
                                        type="file"
                                        id="file-upload"
                                        className="d-none"
                                        accept="image/jpeg, image/png, image/jpg, image/gif"
                                        onChange={handleFileChange}
                                      />
                                    </>
                                  )}
                                />
                                <span
                                  className="inputfile"
                                  onClick={() =>
                                    document
                                      .getElementById("file-upload")
                                      ?.click()
                                  }
                                >
                                  <i className="ti ti-upload" />
                                </span>
                                <ErrorMessage value={errors.feature_image} />
                                <span className="inputfile">
                                  <input type="file" />
                                  <i className="ti ti-upload" />
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Description</label>
                              <Controller
                                name="short_description"
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    {...field}
                                    className="form-control"
                                    maxLength={personalInformationMaxLength}
                                    onBlur={() => trigger("description")}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      trigger("description");
                                    }}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.short_description} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Editor</label>
                              <Controller
                                name="editor_content"
                                control={control}
                                render={({ field }) => (
                                  <Editor
                                    value={field.value}
                                    onTextChange={(e: any) => {
                                      setValue("editor_content", e.htmlValue);
                                      trigger("editor_content");
                                    }}
                                    style={{ minHeight: "200px" }}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.editor_content} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Date of Event
                              </label>
                              <div className="icon-form-custom">
                                <Controller
                                  name="date_range"
                                  control={control}
                                  render={({ field }) => (
                                    <Calendar
                                    appendTo="self"
                                      className="datetimepicker"
                                      value={field.value || null}
                                      placeholder="MM/DD/YYYY"
                                      minDate={new Date()}
                                      onChange={(e) => {
                                        handleStartDateChange(e.value);
                                        trigger("date_range");
                                      }}
                                      onBlur={() => trigger("date_range")}
                                    />
                                  )}
                                />
                                <span className="form-icon custom-form-icon-span">
                                  <img src={cal} alt="Calendar Icon" />
                                </span>
                                <ErrorMessage value={errors.date_range} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-1">
                              <label className="form-label">Time:</label>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="row align-items-center">
                              <div className="col-lg-5">
                                <div className="mb-3">
                                  <Controller
                                    name="begin_time"
                                    control={control}
                                    render={({ field }) => {
                                      const currentTime = new Date();
                                      const selectedDate =
                                        getValues("date_range");
                                      const isToday =
                                        selectedDate &&
                                        moment(selectedDate).isSame(
                                          moment(),
                                          "day"
                                        );

                                      return (
                                        <DatePicker
                                          selected={field.value}
                                          onChange={(time) => {
                                            field.onChange(time);
                                            setValue("begin_time", time);
                                            setValue("end_time", null);
                                          }}
                                          showTimeSelect
                                          showTimeSelectOnly
                                          timeIntervals={5} // Allows intervals of 5 minutes
                                          minTime={
                                            isToday
                                              ? currentTime
                                              : moment().startOf("day").toDate()
                                          } 
                                          maxTime={moment()
                                            .endOf("day")
                                            .toDate()} 
                                          timeCaption="Time"
                                          dateFormat="h:mm aa"
                                          placeholderText="Select Begin Time"
                                        />
                                      );
                                    }}
                                  />
                                  <ErrorMessage value={errors.begin_time} />
                                </div>
                              </div>
                              <div className="col-lg-1">
                                <div className="mb-3 text-center">
                                  <span>to</span>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="mb-3">
                                  <Controller
                                    name="end_time"
                                    control={control}
                                    render={({ field }) => (
                                      <DatePicker
                                        selected={field.value}
                                        onChange={(time: any) => {
                                          const beginTime =
                                            getValues("begin_time");
                                          if (beginTime && time < beginTime) {
                                            const newEndTime = new Date(
                                              beginTime.getTime() + 5 * 60000
                                            ); // 5 minutes = 5 * 60 * 1000 ms
                                            setValue("end_time", newEndTime);
                                          } else {
                                            field.onChange(time);
                                          }
                                        }}
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={5} // Allows intervals of 15 minutes
                                        timeCaption="Time"
                                        dateFormat="h:mm aa" // 12-hour format with AM/PM
                                        placeholderText="Select End Time"
                                      />
                                    )}
                                  />
                                  <ErrorMessage value={errors.end_time} />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="upload-wrapper my-4">
                              <h3 className="title-h3 mb-2">Attach File</h3>
                              <p>
                                Upload announcements in formats like JPG, PDF,
                                or text
                              </p>

                              <div className="row">
                                {/* File Input */}
                                <div className="col-lg-2">
                                  <div className="mb-3">
                                    <div className="upload-div upload2-div border-0 p-0 custom-upload">
                                      <div className="upload-file">
                                        <input
                                          type="file"
                                          multiple
                                          accept="image/jpeg, image/png, image/jpg, application/pdf, text/plain"
                                          onChange={handleAttachmentChange}
                                        />
                                        <div className="upload-content flex-column">
                                          <img src={uploadFile} alt="" />
                                          <p>Attach File</p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                {remainingExistingFiles.map(
                                  (fileUrl: any, index: any) => (
                                    <div className="col-lg-2" key={index}>
                                      <div className="mb-3">
                                        <div className="upload-div upload2-div with-img-blk border-0 p-0">
                                          <div className="upload-file">
                                            <div className="upload-content flex-column">
                                              <Link
                                                to={"#"}
                                                className="uplaod-close"
                                              >
                                                <i
                                                  className="ti ti-x"
                                                  onClick={() =>
                                                    handleRemoveExistingFile(
                                                      index
                                                    )
                                                  }
                                                />
                                              </Link>
                                              {/* Display image if it's an image URL */}
                                              {fileUrl.match(
                                                /\.(jpeg|jpg|png)$/
                                              ) ? (
                                                <img
                                                  src={fileUrl}
                                                  alt={attachedFilesName[index]}
                                                  className="w-100 h-100"
                                                />
                                              ) : (
                                                <img
                                                  src={autoGenerate}
                                                  alt="File icon"
                                                />
                                              )}
                                              <p>{attachedFilesName[index]}</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  )
                                )}

                                {/* Display newly uploaded files */}
                                {uploadedFiles.map((file, index) => (
                                  <div className="col-lg-2" key={index}>
                                    <div className="mb-3">
                                      <div className="upload-div upload2-div with-img-blk border-0 p-0">
                                        <div className="upload-file">
                                          <div className="upload-content flex-column">
                                            <Link
                                              to="#"
                                              className="uplaod-close"
                                              onClick={() =>
                                                handleRemoveUploadedFile(index)
                                              }
                                            >
                                              <i className="ti ti-x" />
                                            </Link>
                                            {file.type?.startsWith(
                                              "image/"
                                            ) && (
                                              <img
                                                src={URL.createObjectURL(file)}
                                                alt={file.name}
                                                className="w-100 h-100"
                                              />
                                            )}
                                            <p>{file.name}</p>
                                            <span className="auto-generate-cls">
                                              <img
                                                src={autoGenerate}
                                                alt="File icon"
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                                <ErrorMessage value={errors.attached_files} />
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="settings-wrapper ">
                              <div className="audience-blk block-border border-0 m-0 pb-4">
                                <h4>Announcements Visibility</h4>
                                <div className="info-content-wrapper">
                                  <ul className="list-unstyled d-flex align-items-center flex-wrap gap-30">
                                    {[
                                      "Site-Wide",
                                      "Followers",
                                      "Subscribers",
                                      "Providers",
                                      "Facilities",
                                      "Non-followers/subscribers",
                                    ].map((option) => {
                                      const isChecked =
                                        visibility.includes(option);
                                      return (
                                        <li key={option}>
                                          <label className="custom_check style2">
                                            <input
                                              type="checkbox"
                                              checked={isChecked}
                                              onChange={(e: any) => {
                                                handleCheckboxChange(
                                                  option,
                                                  e.target.checked
                                                );
                                                trigger("visibility");
                                              }}
                                            />
                                            <span className="checkmark" />
                                            {option}
                                          </label>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                  {/* Error Message for visibility */}
                                  <ErrorMessage value={errors.visibility} />
                                </div>
                              </div>

                              {/* Tags */}
                              <div className="mb-3">
                                <label className="form-label">Tags</label>
                                <Controller
                                  name="tags"
                                  control={control}
                                  render={({ field }) => (
                                    <Chips
                                      className="w-100"
                                      value={chipValue}
                                      placeholder="Type and press enter to add tag"
                                      onChange={(e: any) => {
                                        setChipValue(e.value);
                                        setValue("tags", e.value);
                                        trigger("tags");
                                      }}
                                    />
                                  )}
                                />
                                <ErrorMessage value={errors.tags} />
                              </div>
                              <div className="audience-blk block-border">
                                <div className="row">
                                  <div className="col-12">
                                    <div className="mt-3 text-end">
                                      <button
                                        type="submit"
                                        className="btn btn-submit add-video"
                                        form="addAnnoncementForm"
                                      >
                                        {announcementId ? "Update" : "Save"}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {cropperOpen && imageSrc && imageFile && (
                          <ImageCropper
                            src={imageSrc}
                            handleCrop={onCropComplete}
                            close={() => setCropperOpen(false)}
                            originalFile={imageFile}
                          />
                        )}
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddAnnouncements;
