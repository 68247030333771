import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo, verifyAccount } from "../../utils/imagepath";
import { getRegisterEmail, getRegisterUserId } from "../../global";
import OtpInput from "react-otp-input";
import { getData, postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { yup } from "../../utils/shared.module";
import ErrorMessage from "../../components/errorMessage";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { routes } from "../../utils/routes/routes";
import { onlyNumber } from "../../utils/patterns/regex.pattern";
const formSchema = yup.object().shape({
  otp: yup
    .string()
    .required("OTP is required")
    .matches(onlyNumber, "Otp must be a number")
    .length(4, "OTP must be 4 digits"),
});
const VerifyAccount = () => {
  const navigate = useNavigate();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const [otp, setOtp] = useState("");
  const [timer, setTimer] = useState(30); // Timer for resend code (30 seconds)
  const [isResendDisabled, setIsResendDisabled] = useState(true); // Disable resend initially
  const handleResendCode = async () => {
    setIsResendDisabled(true);
    setTimer(30);
    const response = await getData(end_points.api_endpoint.url);
    if (response.code === 200) {
    }
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        userId: getRegisterUserId(),
        otp: data.otp,
      };
      const response = await postData(end_points.verifyOtpApi.url, payload);
      if (response.code === 200) {
      }
    } catch (error) {
    } finally {
      hideLoader();
      navigate(routes.registerProvider);
    }
  };
  useEffect(() => {
    let countdown: NodeJS.Timeout;
    if (isResendDisabled && timer > 0) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    } else if (timer === 0) {
      setIsResendDisabled(false);
    }
    return () => clearInterval(countdown); // Cleanup interval
  }, [timer, isResendDisabled]);
  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="login-wrap">
                <div>
                  <div className="login-header">
                    <img src={logo} className="logo-blk" alt="" />
                  </div>
                  <div className="login-body ptres-md-0 pt-5">
                    <div className="login-title">
                      <h4>Verify Account</h4>
                      <p>
                        We send a 4-digit to{" "}
                        <span className="hightlight-span">
                          jacksmith@gmail.com
                        </span>
                      </p>
                    </div>
                    {/* Login Form */}
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="passcode-blk">
                        <Controller
                          name="otp"
                          control={control}
                          render={({ field }) => (
                            <OtpInput
                              {...field}
                              value={otp}
                              onChange={(e: any) => {
                                if (onlyNumber.test(e)) {
                                  setOtp(e);
                                  field.onChange(e);
                                }
                                trigger("otp");
                              }}
                              numInputs={4}
                              shouldAutoFocus
                              inputStyle={{
                                width: "3rem",
                                height: "3rem",
                                margin: "0 1rem",
                                fontSize: "2rem",
                                borderRadius: "4px",
                                border: "1px solid rgba(0,0,0,0.3)",
                              }}
                              // containerStyle="otp-container"
                              renderInput={(props) => (
                                <>
                                  <input {...props} />
                                  {/* <i className="ti ti-circle-filled" /> */}
                                </>
                              )}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.otp} className="ml-16" />
                      </div>
                      <button
                        className="btn btn-primary w-100 login-btn"
                        type="submit"
                      >
                        Confirm
                      </button>
                      <div className="register-link text-center">
                        <div className="btm-text text-center">
                          <p>
                            Yet to receive the code?{" "}
                            {isResendDisabled ? (
                              <Link className="resend-timer forgot-link" to="#">
                                Resend code in {timer}s
                              </Link>
                            ) : (
                              <Link
                                className="forgot-link"
                                to="#"
                                onClick={handleResendCode}
                              >
                                Resend code
                              </Link>
                            )}
                          </p>
                        </div>
                      </div>
                    </form>
                    {/* /Login Form */}
                  </div>
                </div>
                <div className="progress-blk two-steps align-center">
                  <ul className="list-unstyled">
                    <li className="completed">
                      <i className="ti ti-check" />
                    </li>
                    <li className="active">
                      <i className="ti ti-circle-filled" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={verifyAccount} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default VerifyAccount;
