import React, { useEffect, useRef, useState } from "react";
import ReactCrop, { Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const ImageCropper = (props: any) => {
  const closeAddModal = useRef<any>(null);
  const [crop, setCrop] = useState<Crop>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
    unit: "px",
  });
  const [cropImage, setCropImage] = useState<string | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [modalVisible, setModalVisible] = useState(true);
  const imageRef = useRef<HTMLImageElement>(null);

  const getCroppedImg = (
    image: HTMLImageElement,
    crop: Crop
  ): Promise<string> => {
    return new Promise((resolve) => {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;

      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");

      ctx?.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      resolve(canvas.toDataURL()); // Return the cropped image as a base64 string
    });
  };

  const makeClientCrop = async (crop: Crop) => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImage = await getCroppedImg(imageRef.current, crop);
      setCroppedImageUrl(croppedImage);
    }
  };

  const closeCropModal = () => {
    setModalVisible(false);
    props.close();
  };

  const handleCroppedOutput = () => {
    props.handleCrop(croppedImageUrl);
    closeCropModal();
  };

  useEffect(() => {
    // Set the initial cropImage state with the base64 string
    if (props.src) {
      setCropImage(props.src);
    }
  }, [props.src]);

  useEffect(() => {
    makeClientCrop(crop);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [crop]);

  useEffect(() => {
    if (!modalVisible) {
      // Reset states when the modal is closed
      setCroppedImageUrl(null);
      setCropImage(null);
      setCrop({ x: 0, y: 0, width: 0, height: 0, unit: "px" });
    }
  }, [modalVisible]);

  // HTML
  return (
    <>
      {modalVisible && (
        <div className="modal fade show d-block image-cropper-modal">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Crop Image</h5>
                <button
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  ref={closeAddModal}
                  onClick={closeCropModal}
                >
                  {/* <i className="fa fa-times" aria-hidden="true" /> */}
                </button>
              </div>
              <div className="modal-body">
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{
                    width: "100%",
                    overflow: "hidden",
                  }}
                >
                  {cropImage && (
                    <ReactCrop crop={crop} onChange={(c) => setCrop(c)}>
                      <img
                        src={cropImage}
                        ref={imageRef}
                        alt="Img"
                        style={{ maxWidth: "100%" }}
                      />
                    </ReactCrop>
                  )}
                </div>
                <div className="d-flex mt-2 justify-content-center">
                  {croppedImageUrl && (
                    <img
                      alt="Crop"
                      style={{ maxWidth: "100%" }}
                      src={croppedImageUrl}
                    />
                  )}
                </div>
                <div className="buttons d-flex justify-content-start align-items-center">
                  <button
                    type="button"
                    className="btn gradient-btn"
                    disabled={croppedImageUrl == null}
                    onClick={handleCroppedOutput}
                  >
                    Confirm
                  </button>
                  <button onClick={closeCropModal} className="btn btn-dull">
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCropper;
