import { Link, useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes/routes";
import { deleteData, getData } from "../../../services/api.service";
import { end_points } from "../../../services/endpoints";
import { useEffect, useState } from "react";
import PrimeDataTable from "../../../components/data-table";
import { capitalizeFirstLetter, formatDateString } from "../../../utils/constants/index.";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import { setEditData } from "../../../core/redux/commonSlice";
import { useDispatch } from 'react-redux';
import { ConfirmDialog } from "primereact/confirmdialog";

const Announcements = () => {
  const [rows, setRows] = useState<number>(5);
  const [listData, setListData] = useState<any[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<any>(0);
  const [selectedRows, setSelectedRows] = useState<Set<number>>(new Set());
  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [visible, setVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState<any>(null);

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = event.target.checked;
    setSelectAll(checked);

    if (checked) {
      const allRowIds = new Set(listData.map((item) => item._id));
      setSelectedRows(allRowIds);
    } else {
      setSelectedRows(new Set());
    }
  };

  const handleRowCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    announceId: number
  ) => {
    const newSelectedRows = new Set(selectedRows);
    if (event.target.checked) {
      newSelectedRows.add(announceId);
    } else {
      newSelectedRows.delete(announceId);
      setSelectAll(false);
    }
    setSelectedRows(newSelectedRows);
  };

  const accept = () => {
    if (selectedUser) {
      deletePlan(selectedUser._id);
    }
  };

  const reject = () => {
    setVisible(false);
  };

  const handleDeleteClick = (data: any) => {
    setSelectedUser(data);
    setVisible(true);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const columns = [
    {
      header: () => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              id="select-all"
              checked={selectAll}
              onChange={handleSelectAllChange}
            />
            <span className="box" />
          </label>
        </div>
      ),
      body: (data: any) => (
        <div className="table-checkbox">
          <label className="check-input">
            <input
              type="checkbox"
              checked={selectedRows.has(data._id)}
              onChange={(e) => handleRowCheckboxChange(e, data._id)}
            />
            <span className="box" />
          </label>
        </div>
      ),
      key: "checkbox",
      sortable: false,
    },
    {
      header: "Announcements",
      field: "title",
      key: "title",
      sortField: "title",
      body: (data: any) => {
        const shortDescription = data?.short_description || '';
        const charLimit = 80;
        const trimDescription =
          shortDescription.length > charLimit
            ? shortDescription.slice(0, charLimit)
            : shortDescription;
        return (
          <>
            <div className="media-blk d-flex">
              <div className="img-blk">
                <img src={data?.featured_image} className="img img-fluid" alt="" />
              </div>
              <div className="media-content">
                <h5>{capitalizeFirstLetter(data?.title)}</h5>
                {/* <div dangerouslySetInnerHTML={{ __html: `${Description}${words.length > 30 ? '...' : ''}` }} /> */}
                <p>{`${trimDescription}${shortDescription.length > charLimit ? '...' : ''}`}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      header: "Visibility",
      field: "visibility",
      key: "visibility",
      sortField: "visibility",
      body: (data: any) => (
        <>
            {capitalizeFirstLetter(data.visibility.join(", "))}
        </>
    ),
    },

    {
      header: "Date Posted",
      field: "createdAt",
      key: "createdAt",
      sortField: "createdAt",
      body: (data: any) => (
        <>{formatDateString(data.createdAt)}
        </>
        )
    },
    {
      header: "Views",
      field: "Views",
      key: "Views",
      sortField: "Views",
      body: (data: any) => (
        <>{0}
        </>
        )
    },
    {
      header: "",
      key: "action",
      field: "action",
      sortable: false,
      body: (data: any) => {
        return (
          <>
            <div className="flex-style flex-icon">
              <a
              onClick={() => {
                handleEdit(data);
              }}>
                <i className="ti ti-pencil" />
              </a>
              <a
              onClick={() => {
                handleStatusUpdate(data);
              }}>
                {data?.status === "active" ?
                (
                  <i className="ti ti-eye" />
                ):(
                  <i className="ti ti-eye-off" />
                )}
              </a>
              <a
              onClick={() => {
                handleDeleteClick(data);
              }}>
                <i className="ti ti-trash" />
              </a>
            </div>
          </>
        );
      },
    },
  ];

  const handleEdit = (data: any) => {
    dispatch(setEditData(data));
    navigate(routes.addAnnouncements)
  };

  const deletePlan = async (id: number) => {
    const url = `${end_points.delete_announcement.url}/${id}`;
    if (id) {
      try {
        const response:any = await deleteData(url);
        if (response?.status === 201) {
          setVisible(false);
          getList();
        }
      } catch (error) {
      }
    }
  };

  const getList = async (page: number = 1, limit: number = 5) => {
    try {
      showLoader();
      let url = end_points.list_announcement.url;
      if (page) {
        url += `?page=${page}&limit=${limit}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.data);
        setTotalRecords(response?.data?.data?.total_records);
      }
    } catch (error) {
      console.error(error);
    }finally {
      hideLoader();
    }
  };

  const handleStatusUpdate = async (data:any) => {
    try {
      let url = end_points.status_announcement.url;
      url += `/${data?._id}`;
      const response = await getData(url);
      if (response.status === 201) {
        getList();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getList(currentPage, rows);
    dispatch(setEditData(undefined));
  }, [currentPage, rows]);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <div className="d-sm-flex flex-wrap align-items-start">
              <ul className="flex-style">
                <li>Announcements</li>
              </ul>
              <Link
                to={routes.addAnnouncements}
                className="btn mb-2 mb-sm-0 btn-add-outline ms-sm-auto"
              >
                <i className="ti ti-plus me-2" />
                Add Announcements
              </Link>
            </div>
            <p>
              Lets you easily upload and organize your video content in a
              streamlined interface.
            </p>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="table-top-blk mb-20 p-0 flex-style">
                  <p>
                    Total Announcements: <span>{totalRecords || 0} Announcements</span>
                  </p>
                  {/* <div className="ms-auto filter-div border-0 p-0 m-0">
                    <div className="d-flex align-items-center flex-wrap row-gap-2 table-profile-head">
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Sort by: Date Created{" "}
                          <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="dropdown ms-2">
                        <Link
                          to="#"
                          className="dropdown-toggle "
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          Filter <i className="ti ti-chevron-down ms-2" />
                        </Link>
                        <div className="dropdown-menu dropdown-menu-start">
                          <ul>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Ascending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Descending
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Viewed
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="dropdown-item">
                                <i className="ti ti-circle-chevron-right me-1" />
                                Recently Added
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="table-responsive">
                  <PrimeDataTable
                    column={columns}
                    data={listData}
                    rows={rows}
                    setRows={setRows}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalRecords={totalRecords}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* /Delete */}
      <ConfirmDialog
        visible={visible}
        onHide={() => setVisible(false)}
        message="Are you sure you want to delete this announcement?"
        header="Delete Confirmation"
        icon="pi pi-exclamation-triangle"
        accept={accept}
        reject={reject}
      />
    </>
  );
};

export default Announcements;
