import React from "react";
import Dashboard from "../../feature-module/provider";
import { Navigate } from "react-router-dom";
import { routes } from "./routes";
import FinancialPerformance from "../../feature-module/provider/financial-performance";
import Analytics from "../../feature-module/provider/analytics";
import Notifications from "../../feature-module/provider/notifications";
import ProfilePerformance from "../../feature-module/provider/profile-performance";
import Review from "../../feature-module/provider/review";
import VideoPerformance from "../../feature-module/provider/video-performance";
import WrittenContent from "../../feature-module/provider/written-content";
import WrittenPerformance from "../../feature-module/provider/written-performance";
import BillingProvider from "../../feature-module/authentication/billing-provider";
import CredentialsProvider from "../../feature-module/authentication/credentials-provider";
import ForgotPassword from "../../feature-module/authentication/forgot-password";
import LocationProvider from "../../feature-module/authentication/location-provider";
import Login from "../../feature-module/authentication/login";
import RegisterClient from "../../feature-module/authentication/register-client";
import RegisterProvider from "../../feature-module/authentication/register-provider";
import ThankYou from "../../feature-module/authentication/thank-you";
import VerifyAccount from "../../feature-module/authentication/verify-account";
import Announcements from "../../feature-module/provider/announcements";
import AddAnnouncements from "../../feature-module/provider/announcements/add-announcements";
import GoLive from "../../feature-module/provider/go-live";
import Calendar from "../../feature-module/provider/calendar";
import Earning from "../../feature-module/provider/earning";
import GoliveNow from "../../feature-module/provider/golive-now";
import ScheduleStream from "../../feature-module/provider/schedule-stream";
import Settings from "../../feature-module/provider/settings/index";
import ResetPassword from "../../feature-module/authentication/reset-password";
import Events from "../../feature-module/provider/events";
import EmbedWidget from "../../feature-module/provider/embed-widget";
import Directory from "../../feature-module/provider/directory";
import AddVideo from "../../feature-module/provider/videoContent/add-video";
import ClientIndex from "../../feature-module/client";
import Group from "../../feature-module/client/group";
import Category from "../../feature-module/client/category";
import PsychopharmacologyVideos from "../../feature-module/client/psychopharmacology-videos";
import Subcategory from "../../feature-module/client/subcategory";
import EditAnnouncements from "../../feature-module/provider/announcements/edit-announcements";
import CardDetails from "../../feature-module/authentication/card-details";
import VerifyEmail from "../../feature-module/provider/verify-email";
import Billing from "../../feature-module/provider/billing";
import ScheduleWorldwideStream from "../../feature-module/provider/schedule-worldwide-stream";
import FeaturedWorldWideFeed from "../../feature-module/provider/featured-world-wide-feed";
import Search from "../../feature-module/frontend/search";
import FrontendIndex from "../../feature-module/frontend";
import FilteredCountryFeed from "../../feature-module/frontend/filtered-country-feed";
import ManageVideos from "../../feature-module/provider/videoContent/manage-videos";
import ViewProfile from "../../feature-module/frontend/view-profile";
import SingleBlog from "../../feature-module/frontend/single-blog";
import SearchTag from "../../feature-module/frontend/search-tag";
import FeaturedWorldWide from "../../feature-module/frontend/featured-world-wide";

export const authRoutes = [
  {
    id: 1,
    path: routes.thankYou,
    element: <ThankYou />,
  },
  {
    id: 2,
    path: routes.providerIndex,
    element: <Dashboard />,
  },
  {
    id: 3,
    path: routes.addVideo,
    element: <AddVideo />,
  },
  {
    id: 4,
    path: routes.analytics,
    element: <Analytics />,
  },
  {
    id: 5,
    path: routes.financialPerformance,
    element: <FinancialPerformance />,
  },
  {
    id: 6,
    path: routes.announcements,
    element: <Announcements />,
  },
  {
    id: 7,
    path: routes.financialPerformance,
    element: <FinancialPerformance />,
  },
  {
    id: 8,
    path: routes.manageVideos,
    element: <ManageVideos />,
  },
  {
    id: 9,
    path: routes.notifications,
    element: <Notifications />,
  },
  {
    id: 10,
    path: routes.profilePerformance,
    element: <ProfilePerformance />,
  },
  {
    id: 11,
    path: routes.review,
    element: <Review />,
  },
  {
    id: 12,
    path: routes.videoPerformance,
    element: <VideoPerformance />,
  },
  {
    id: 13,
    path: routes.writtenContent,
    element: <WrittenContent />,
  },
  {
    id: 14,
    path: routes.writtenPerformance,
    element: <WrittenPerformance />,
  },
  {
    id: 24,
    path: routes.addAnnouncements,
    element: <AddAnnouncements />,
  },
  {
    id: 25,
    path: routes.editAnnouncements,
    element: <EditAnnouncements />,
  },
  {
    id: 26,
    path: routes.goLive,
    element: <GoLive />,
  },
  {
    id: 27,
    path: routes.calendar,
    element: <Calendar />,
  },
  {
    id: 28,
    path: routes.earning,
    element: <Earning />,
  },
  {
    id: 29,
    path: routes.earning,
    element: <Earning />,
  },
  {
    id: 30,
    path: routes.goLiveNow,
    element: <GoliveNow />,
  },
  {
    id: 31,
    path: routes.scheduleStream,
    element: <ScheduleStream />,
  },
  {
    id: 32,
    path: routes.settings,
    element: <Settings />,
  },
  {
    id: 33,
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
  {
    id: 34,
    path: routes.events,
    element: <Events />,
  },
  {
    id: 35,
    path: routes.embedWidget,
    element: <EmbedWidget />,
  },
  {
    id: 36,
    path: routes.embedWidget,
    element: <EmbedWidget />,
  },
  {
    id: 37,
    path: routes.directory,
    element: <Directory />,
  },
  {
    id: 38,
    path: routes.clientIndex,
    element: <ClientIndex />,
  },
  {
    id: 39,
    path: routes.group,
    element: <Group />,
  },
  {
    id: 40,
    path: routes.category,
    element: <Category />,
  },
  {
    id: 41,
    path: routes.psychopharmacologyVideos,
    element: <PsychopharmacologyVideos />,
  },
  {
    id: 42,
    path: routes.subCategory,
    element: <Subcategory />,
  },
  {
    id: 43,
    path: routes.billing,
    element: <Billing />,
  },
  {
    id: 44,
    path: routes.verifyEmail,
    element: <VerifyEmail />,
  },
  {
    id: 45,
    path: routes.scheduleWorldwideStream,
    element: <ScheduleWorldwideStream />,
  },
  {
    id: 46,
    path: routes.viewProfile,
    element: <ViewProfile />,
  },
  {
    id: 47,
    path: routes.featuredWordWideFeed,
    element: <FeaturedWorldWideFeed />,
  },
  {
    id: 48,
    path: routes.search,
    element: <Search />,
  },
  {
    id: 49,
    path: routes.frontendIndex,
    element: <FrontendIndex />,
  },
  {
    id: 50,
    path: routes.filteredCountryFeed,
    element: <FilteredCountryFeed />,
  },
  {
    id: 51,
    path: routes.singleBlog,
    element: <SingleBlog />,
  },
  {
    id: 52,
    path: routes.searchTag,
    element: <SearchTag />,
  },
  {
    id: 53,
    path: routes.featuredWorldWide,
    element: <FeaturedWorldWide />,
  },
];
export const unAuthRoutes = [
  {
    id: 11,
    path: "/",
    element: <Navigate to={routes.providerLogin} />,
  },
  {
    id: 1,
    path: routes.providerLogin,
    element: <Login />,
  },
  {
    id: 2,
    path: routes.billingProvider,
    element: <BillingProvider />,
  },
  {
    id: 3,
    path: routes.credentialsProvider,
    element: <CredentialsProvider />,
  },
  {
    id: 4,
    path: routes.forgotPassword,
    element: <ForgotPassword />,
  },
  {
    id: 5,
    path: routes.locationProvider,
    element: <LocationProvider />,
  },

  {
    id: 6,
    path: routes.registerClient,
    element: <RegisterClient />,
  },
  {
    id: 7,
    path: routes.registerProvider,
    element: <RegisterProvider />,
  },
  // {
  //   id: 8,
  //   path: routes.thankYou,
  //   element: <ThankYou />,
  // },
  {
    id: 9,
    path: routes.verifyAccount,
    element: <VerifyAccount />,
  },
  {
    id: 10,
    path: routes.resetPassword,
    element: <ResetPassword />,
  },
  {
    id: 11,
    path: routes.cardIndex,
    element: <CardDetails />,
  },
];
