import { useSelector } from "react-redux";
import en from "../../assets/locale/en.json";
import { RootState } from "../../core/redux/store";

const translations: any = {
  en,
};

export const translate = (key: string, module: string, value: string) => {
  const currentLanguage = useSelector(
    (state: RootState) => state.language.language
  );
  return translations[currentLanguage || "en"][module][key] || value;
};
