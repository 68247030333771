import React, { useContext, useState } from 'react';
import { logoBig } from '../../../utils/imagepath';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { routes } from '../../../utils/routes';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import {
  email,
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyAlphabetwithoutSpace,
  passwordMaxLength,
  passwordPattern,
  userNameMinLength,
} from '../../../utils/patterns/regex.pattern';
import parsePhoneNumberFromString from 'libphonenumber-js';
import PhoneInputWithRef from '../../../utils/phoneInputWithRef';
import { end_points } from '../../../services/end_point/end_points';

const Register = () => {
  const navigate = useNavigate();
  const registerFormValidation = yup.object().shape({
    hospitalName: yup.string().trim().required('Hospital name is required'),
    organizationName: yup
      .string()
      .trim()
      .required('Organization name is required'),
    domainName: yup
      .string()
      .trim()
      .required('Domain name is required')
      .matches(onlyAlphabetwithoutSpace, 'Domain name allows only characters'),
    fullName: yup.string().trim().required('Full name is required'),
    userName: yup
      .string()
      .trim()
      .required('User name is required')
      .min(userNameMinLength, 'User name must be minimum 5 characters'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .trim()
      .matches(email, 'Please enter a valid email')
      .required('Email is required'),
    mobile: yup
      .string()
      .trim()
      .required('Mobile number is required')
      .test('is-valid-phone', 'Invalid phone number', (value) =>
        validatePhoneNumber(value),
      ),
    password: yup
      .string()
      .trim()
      .required('Enter new password')
      .matches(
        passwordPattern,
        'Password must be at least 5 to 10 characters long, contain one uppercase letter, one lowercase letter, one number, and one special character(~`!@#$%^&*()-_+={}[]|;:"<>,./?)',
      ),
    confirmPassword: yup
      .string()
      .trim()
      .required('Enter confirm password')
      .oneOf([yup.ref('password')], 'Password does not match'),
  });

  const validatePhoneNumber = (value: string) => {
    if (!value.startsWith('+')) {
      value = `+${value}`;
    }
    const phoneNumber = parsePhoneNumberFromString(value);
    return phoneNumber ? phoneNumber.isValid() : false;
  };

  const {
    handleSubmit,
    control,
    trigger,
    setFocus,
    setError,
    formState: { errors, isSubmitted, isValid },
  } = useForm({
    resolver: yupResolver(registerFormValidation),
    defaultValues: {
      hospitalName: '',
      organizationName: '',
      domainName: '',
      fullName: '',
      userName: '',
      email: '',
      mobile: '',
      password: '',
      confirmPassword: '',
    },
  });
  const formInvalid = isSubmitted && !isValid;
  const { postData } = useContext(ApiServiceContext);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [loading, setLoading] = useState<any>(false);
  const newtogglePasswordVisibility = () => {
    if (!passwordVisible && confirmpasswordVisible) {
      setConfirmPasswordVisible(false);
    }
    setPasswordVisible(!passwordVisible);
  };

  const confirmtogglePasswordVisibility = () => {
    if (!confirmpasswordVisible && passwordVisible) {
      setPasswordVisible(false);
    }
    setConfirmPasswordVisible(!confirmpasswordVisible);
  };

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const urls = end_points.tenantRegister.url;
      const payload = {
        hospital_name: data?.hospitalName,
        organization_name: data?.organizationName,
        domain_name: data?.domainName,
        fullname: data?.fullName,
        username: data?.userName,
        email: data?.email,
        mobile: `+${data?.mobile}`,
        password: data?.password,
      };
      const response = await postData(urls, payload);
      if (response?.status === 200) {
        const result = response.data.data;

        navigate(routes.login.path);
      } else if (response?.status === 422) {
        const errors = response?.data?.errors?.[0];
        const key =
          errors?.path === 'domain_name'
            ? 'domainName'
            : errors?.path === 'username'
              ? 'userName'
              : errors?.path;
        setFocus(key);
        setError(key, {
          type: 'manual',
          message: errors?.msg,
        });
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {/* Logo */}
      <div className="register-logo register-main-logo">
        <div className="white-logo">
          <img src={logoBig} alt="Logo" />
        </div>
      </div>
      {/* /Logo */}
      {/* Register */}
      <div className="w-100">
        <div className="content container-fluid pb-0">
          <div className="row">
            <div className="col-lg-12">
              <div className="login-box register-box">
                <div className="login-right">
                  <div className="login-right-wrap">
                    <div className="login-header">
                      <h2>Register</h2>
                    </div>
                    {/* Form */}
                    <form
                      className="login-form"
                      onSubmit={handleSubmit(onSubmit)}
                    >
                      <div className="form-wrap">
                        <Controller
                          name="hospitalName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="Hospital name"
                                className={`form-control ${
                                  errors.hospitalName ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('hospitalName')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('hospitalName');
                                }}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabet;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== 'Backspace'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                              />
                              {errors.hospitalName && (
                                <small className="text-danger d-inline-flex mt-2">
                                  {errors.hospitalName.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap">
                        <Controller
                          name="organizationName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="Organization name"
                                className={`form-control ${
                                  errors.organizationName ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('organizationName')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('organizationName');
                                }}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabet;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== 'Backspace'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                              />
                              {errors.organizationName && (
                                <small className="text-danger d-inline-flex mt-2">
                                  {errors.organizationName.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap">
                        <Controller
                          name="domainName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="Domain name"
                                className={`form-control ${
                                  errors.domainName ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('domainName')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('domainName');
                                }}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabetwithoutSpace;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== 'Backspace'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                              />
                              {errors.domainName && (
                                <small className="text-danger d-inline-flex mt-2">
                                  {errors.domainName.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap">
                        <Controller
                          name="fullName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="Full name"
                                className={`form-control ${
                                  errors.fullName ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('fullName')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('fullName');
                                }}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabet;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== 'Backspace'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                              />
                              {errors.fullName && (
                                <small className="text-danger d-inline-flex mt-2">
                                  {errors.fullName.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap">
                        <Controller
                          name="userName"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="User name"
                                className={`form-control ${
                                  errors.userName ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('userName')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('userName');
                                }}
                                onKeyDown={(event) => {
                                  const regex = onlyAlphabet;
                                  if (
                                    !regex.test(event.key) &&
                                    event.key !== 'Backspace'
                                  ) {
                                    event.preventDefault();
                                  }
                                }}
                                maxLength={nameMaxLength}
                                minLength={userNameMinLength}
                              />
                              {errors.userName && (
                                <small className="text-danger d-inline-flex mt-2">
                                  {errors.userName.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap">
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type="text"
                                placeholder="Email"
                                className={`form-control ${
                                  errors.email ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('email')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('email');
                                }}
                                maxLength={emailMaxLength}
                              />
                              {errors.email && (
                                <small className="text-danger">
                                  {errors.email.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div
                        className={`form-wrap ${formInvalid ? 'is-invalid' : ''}`}
                      >
                        <Controller
                          name="mobile"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div>
                              <PhoneInputWithRef
                                {...field}
                                placeholder="Enter phone number"
                                country={'in'}
                                value={field.value}
                                onBlur={() => trigger('mobile')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('mobile');
                                }}
                                inputProps={{
                                  name: 'mobile',
                                  required: true,
                                  // autoFocus: true,
                                  className: `form-control ${fieldState.invalid ? 'is-invalid' : ''}`,
                                }}
                              />
                              {fieldState.invalid && (
                                <small className="text-danger mt-2">
                                  {errors.mobile?.message}
                                </small>
                              )}
                            </div>
                          )}
                        />
                      </div>
                      <div className="form-wrap pass-group">
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type={passwordVisible ? 'text' : 'password'}
                                placeholder="Password"
                                className={`form-control ${
                                  errors.password ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('password')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('password');
                                }}
                                maxLength={passwordMaxLength}
                              />
                              <span
                                className={`fas ${passwordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${errors.password ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                                onClick={newtogglePasswordVisibility}
                              />
                              {errors.password && (
                                <>
                                  {errors.password.type === 'required' && (
                                    <small className="text-danger">
                                      {errors.password.message}
                                    </small>
                                  )}

                                  {errors.password.type === 'matches' && (
                                    <small className="text-danger">
                                      <ul className="custom-ul-react">
                                        <li>
                                          Password must be at least 5 to 10
                                          characters long.
                                        </li>
                                        <li>Contain one uppercase letter.</li>
                                        <li>Contain one lowercase letter.</li>
                                        <li>Contain one number.</li>
                                        <li>
                                          Contain one special character
                                          (~`!@#$%^&amp;*()-_+={}
                                          []|;:&quot;&lt;&gt;,./?)
                                        </li>
                                      </ul>
                                    </small>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="form-wrap pass-group">
                        <Controller
                          name="confirmPassword"
                          control={control}
                          render={({ field }) => (
                            <>
                              <input
                                {...field}
                                type={
                                  confirmpasswordVisible ? 'text' : 'password'
                                }
                                placeholder="Confirm password"
                                className={`form-control ${
                                  errors.confirmPassword ? 'is-invalid' : ''
                                }`}
                                onBlur={() => trigger('confirmPassword')}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger('confirmPassword');
                                }}
                                maxLength={passwordMaxLength}
                              />
                              <span
                                className={`fas ${confirmpasswordVisible ? 'fa-eye' : 'fa-eye-slash'} toggle-password ${errors.confirmPassword ? 'toggle-password-icon-right' : 'toggle-password-icon'}`}
                                onClick={confirmtogglePasswordVisibility}
                              />
                              {errors.confirmPassword && (
                                <small className="text-danger">
                                  {errors.confirmPassword.message}
                                </small>
                              )}
                            </>
                          )}
                        />
                      </div>
                      <div className="login-btn">
                        <button
                          className="btn btn-secondary"
                          type="submit"
                          disabled={loading}
                        >
                          {loading ? 'Loading...' : 'Register'}
                        </button>
                      </div>
                      <div className="login-forgot">
                        Already have an account?{' '}
                        <Link to={routes.login.path} className="forgot-link">
                          Login
                        </Link>
                      </div>
                      <div className="login-otp return-to-home">
                        <Link to={routes.welcome.path}>Return to home</Link>
                      </div>
                    </form>
                    {/* /Form */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Register */}
    </>
  );
};

export default Register;
