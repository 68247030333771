import React, { useContext, useEffect, useRef, useState } from 'react';
import JoinCall from './components/joinCall';
import { Link } from 'react-router-dom';
import PatientDetail from './components/patientDetail';
import SymptomsCall from './components/symptomsCall';
import VitalsCall from './components/vitalsCall';
import TeleExaminationCall from './components/teleExaminationCall';
import InvestigationCall from './components/investigationCall';
import DiagnosisCall from './components/diagnosisCall';
import PrescriptionCall from './components/prescriptionCall';
import AddDrugModel from './components/addDrugModel';
import PrescriptionModel from './components/prescriptionModel';
import DeleteDrugModel from './components/deleteDrugModel';
import MedicalHistoryModel from './medicalHistoryModel';
import {
  setEditData,
  setPastData,
} from '../../../../../core/redux/commonSlice';
import { routes } from '../../../../../utils/routes';
import {
  capitalizeFirstLetter,
  formatAppointmentStart,
} from '../../../../../utils/constant';
import { useDispatch, useSelector } from 'react-redux';
import PrimeDataTable from '../../../../../components/dataTable';
import { userRole } from '../../../../../assets/global';
import { SpinnerContext } from '../../../../../components/spinner/spinner';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { end_points } from '../../../../../services/end_point/end_points';
import PastHistoryMedicalConfirmationModel from './components/pastHistoryMedicalConfirmationModel';

const JoinConsultation = () => {
  const [showSymptoms, setShowSymptoms] = useState(true);
  const dispatch = useDispatch();
  const medicalRef = useRef<any>(null);
  const pastMedicalModelRef = useRef<any>(null);
  const [rows, setRows] = useState<number>(10);
  const [viewHeight, setViewHeight] = useState(window.innerHeight);
  const headerHeight: any =
    document.querySelector('.header')?.clientHeight || 100;

  const scrollHeight = viewHeight - (headerHeight + 50) || 500;
  const handleResize = () => {
    const newViewHeight = window.innerHeight;
    setViewHeight(newViewHeight);
  };
  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData } = useContext(ApiServiceContext);
  const [listData, setListData] = useState<any>([]);
  const id: any = useSelector(
    (state: any) => state?.common?.editData?.patient_id,
  );
  const [appointmentId, setAppitmentId] = useState<any>('');

  const [vitalBool, setVitalsBool] = useState<any>('');
  const getDoctorConsults = async (page: number = 1, limit: number = 10) => {
    try {
      showLoader();
      let url = end_points.pastConsultsDetailsListApi.url;
      url += `/${id}`;
      // if (page && limit) {
      //   url += `&page=${page}&limit=${limit}`;
      // }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data);
        setTotalRecords(response?.data?.totalRecord);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const pastConsultsColumns = [
    {
      header: 'Appointment Id',
      field: 'appointments.appointment_format_id',
      key: 'appointments.appointment_format_id',
      sortField: 'appointments.appointment_format_id',
      className: 'f-600',
      body: (data: any) => {
        return data && data?.appointments?.length > 0
          ? data?.appointments[0]?.appointment_format_id
          : '';
      },
    },
    {
      header: 'Patient Name',
      field: 'fullname',
      key: 'fullname',
      sortField: 'fullname',
    },
    {
      header: 'Consult Date',
      field: 'patient',
      key: 'patient',
      sortField: 'patient',
      body: (data: any) => {
        return data && data?.appointments?.length > 0
          ? formatAppointmentStart(data?.appointments[0]?.appointment_start)
          : '';
      },
    },
    {
      header: 'Status',
      field: 'status',
      key: 'status',
      sortField: 'status',
      body: (rowData: any) => {
        const id =
          rowData?.appointments?.[0] &&
          JSON.parse(JSON.stringify(rowData?.appointments?.[0]));
        return capitalizeFirstLetter(id?.appointment_status);
      },
    },
    {
      header: 'Prescription',
      field: 'prescription',
      key: 'prescription',
      sortField: 'prescription',
      sortable: false,
      body: (rowData: any) => {
        const id =
          rowData?.appointments?.[0] &&
          JSON.parse(JSON.stringify(rowData?.appointments?.[0]));
        return (
          <div className="data-btn">
            {id?.appointment_status === 'completed' ? (
              <Link
                to="#"
                className="btn btn-primary"
                data-bs-toggle="modal"
                data-bs-target="#prescription_preview"
                onClick={() => {
                  setAppitmentId(id);
                }}
              >
                Prescription
              </Link>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
    {
      header: 'Current Details',
      field: 'currentDetails',
      key: 'currentDetails',
      sortField: 'currentDetails',
      body: (rowData: any) => {
        const id =
          rowData?.appointments?.[0] &&
          JSON.parse(JSON.stringify(rowData?.appointments?.[0]));
        return (
          <div className="data-btn">
            {id?.appointment_status === 'completed' ? (
              <Link
                to={routes.appointmentDetails.path}
                className="btn btn-primary"
                onClick={() => {
                  const dat = { patient: rowData };
                  // dispatch(setEditData(dat));
                  dispatch(setPastData(dat));
                }}
              >
                Details
              </Link>
            ) : (
              <div>-</div>
            )}
          </div>
        );
      },
    },
  ];
  useEffect(() => {
    if (!showSymptoms && id) {
      getDoctorConsults(currentPage, rows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSymptoms, id]);
  const getMedicalStatus = async (id = '') => {
    try {
      showLoader();
      let url = end_points.getMedicalStatusApi.url;
      if (id) {
        url += `/${id}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        if (!response?.data?.data?.flag && pastMedicalModelRef?.current)
          pastMedicalModelRef.current.click();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };
  const onSuccessScreenShot = () => {
    setVitalsBool(true);
  };
  useEffect(() => {
    id && getMedicalStatus(id);
  }, [id]);
  return (
    <div className="page-wrapper">
      <div className="content container-fluid pb-0">
        {/* Join Details */}
        <div className="row">
          <div className="col-xl-3 col-lg-12 d-flex">
            <div
              className="card join-card w-100"
              style={{ height: scrollHeight }}
            >
              <JoinCall onSuccessScreenShot={onSuccessScreenShot} />
            </div>
          </div>
          <div className="col-xl-9 col-lg-12">
            <div className="join-scroll-right" style={{ height: scrollHeight }}>
              <PatientDetail
                setShowSymptoms={setShowSymptoms}
                showSymptoms={showSymptoms}
                medicalRef={medicalRef}
              />
              {showSymptoms ? (
                <div className="row">
                  <div className="col-md-12">
                    {/* Appointment Tabs */}
                    <div className="appointment-tabs appointment-details-tabs">
                      <ul
                        className="nav nav-tabs"
                        id="appointmentTab"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#symptoms_tab"
                            className="nav-link active"
                            id="symptoms-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="symptoms_tab"
                            aria-selected="true"
                          >
                            Symptoms
                          </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#vitals_tab"
                            className="nav-link"
                            id="vitals-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="vitals_tab"
                            aria-selected="false"
                            onClick={() => {
                              setVitalsBool(true);
                            }}
                          >
                            Vitals
                          </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#tele_examination"
                            className="nav-link"
                            id="tele-examination"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="tele_examination"
                            aria-selected="false"
                          >
                            Tele Examination
                          </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#investigation_tab"
                            className="nav-link"
                            id="investigation-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="investigation_tab"
                            aria-selected="false"
                          >
                            Investigation
                          </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#diagnosis_tab"
                            className="nav-link"
                            id="diagnosis-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="diagnosis_tab"
                            aria-selected="false"
                          >
                            Diagnosis
                          </Link>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Link
                            to="#prescription_tab"
                            className="nav-link"
                            id="prescription-tab"
                            data-bs-toggle="tab"
                            role="tab"
                            aria-controls="prescription_tab"
                            aria-selected="false"
                          >
                            Prescription
                          </Link>
                        </li>
                      </ul>
                    </div>
                    {/* /Appointment Tabs */}
                    {/* Appointment Content */}
                    <div className="card mb-0">
                      <div className="card-body pb-0">
                        <div className="tab-content" id="appointmentTabContent">
                          {/* Symptoms */}
                          <SymptomsCall />
                          {/* /Symptoms */}
                          {/* Vitals */}
                          <VitalsCall
                            vitalBool={vitalBool}
                            setVitalsBool={setVitalsBool}
                          />
                          {/* /Vitals */}
                          {/* Tele Eexamination */}
                          <TeleExaminationCall />
                          {/* /Tele Eexamination */}
                          {/* Investigation */}
                          <InvestigationCall />
                          {/* /Investigation */}
                          {/* Diagnosis */}
                          <DiagnosisCall />
                          {/* /Diagnosis */}
                          {/* Prescription */}
                          <PrescriptionCall />
                          {/* /Prescription */}
                        </div>
                      </div>
                    </div>
                    {/* /Appointment Content */}
                  </div>
                </div>
              ) : (
                <>
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="doctor-header">
                      <h4>{capitalizeFirstLetter('past')} Consults</h4>
                      <ul className="nav">
                        <li>
                          <Link
                            to={'#'}
                            className="btn btn-primary"
                            onClick={refresh}
                          >
                            Refresh
                          </Link>
                        </li>
                      </ul>
                    </div> */}
                      {/* Doctor Table */}
                      <PrimeDataTable
                        column={pastConsultsColumns}
                        data={listData}
                        rows={rows}
                        setRows={setRows}
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        totalRecords={totalRecords}
                        onRowClickSetState={false}
                        rowClassName={'highlight'}
                        type={userRole.Doctor}
                      />
                      {/* /Doctor Table */}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* /Join Details */}
        {/* <AddDrugModel /> */}
        <MedicalHistoryModel />
        <DeleteDrugModel />
        <div
          className="d-none"
          ref={pastMedicalModelRef}
          data-bs-toggle="modal"
          data-bs-target="#pastMedical_historyModel"
        ></div>
        <PastHistoryMedicalConfirmationModel
          onSuccess={() => {
            if (medicalRef?.current) medicalRef.current.click();
          }}
        />
        <PrescriptionModel
          appoitmentId={appointmentId}
          setAppoitmentId={setAppitmentId}
        />
      </div>
    </div>
  );
};

export default JoinConsultation;
