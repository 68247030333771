import React from "react";
import { logo, thankYou, thankYouGif } from "../../utils/imagepath";
import { setRegisterData } from "../../core/redux/commonSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes/routes";

const ThankYou = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gotoDashBoard = () => {
    dispatch(setRegisterData({}));
    navigate(routes.providerIndex);
  };
  return (
    <>
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="login-wrap justify-content-start">
                <div className="login-header">
                  <img src={logo} className="logo-blk" alt="" />
                </div>
                <div className="login-body text-center pt-0">
                  <div className="login-title">
                    <img src={thankYouGif} alt="" />
                    <h4>Congratulations!!</h4>
                    <p>
                      You've successfully completed your signup process. Now,
                      click here to view your profile.
                    </p>
                  </div>
                  {/* Login Form */}
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="button"
                    onClick={() => {
                      gotoDashBoard();
                    }}
                  >
                    View Your Profile
                  </button>
                  {/* /Login Form */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={thankYou} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default ThankYou;
