import { Link } from "react-router-dom";
import { editLinkIcon } from "../../../../utils/imagepath";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  textSchema,
  urlSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  characterLength_50,
  onlyAlphabet,
  onlyNumber,
} from "../../../../utils/patterns/regex.pattern";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: () => void;
}

const AddLink = (props: props) => {
  const { getDirectoryData } = props;
  const initialValues = {
    url: "",
    text_to_display: "",
  };

  const basicModelValidation = Yup.object().shape({
    url: urlSchema,
    text_to_display: textSchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicModelValidation),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    console.log(data);
    try {
      showLoader();
      const payload = {
        plan_id: "66fe46a4b67bedd4a5ffe349",
        url: data?.url,
        payment_status: "succeeded",
        deletedAt: null,
      };
      const response = await postData(
        `${end_points.profile_about_addlink_create.url}`,
        payload
      );
      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  return (
    <>
      <div
        className="modal fade credential-modal finances-modal"
        id="add-links"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">Add Links</h5>
                <p className="modal-title-text">
                  <span>Please note:</span> $9/month per link
                </p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div
              className="modal-body"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <form action="#">
                <div className="form-wrap-add">
                  <div className="form-wrap">
                    <div className="form-wrap-url">
                      <label>URL</label>
                      <Link
                        to="#"
                        type="submit"
                        onClick={handleSubmit(handleFormSubmit)}
                      >
                        Add
                      </Link>
                    </div>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your url link"
                      {...register("url")}
                      onKeyDown={(e) => {
                        const key = e.key;
                        if (onlyNumber.test(key)) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={characterLength_50}
                    />
                    <ErrorMessage value={errors.url} />
                  </div>
                  <div className="form-wrap">
                    <label>Text to display</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter your text to display"
                      {...register("text_to_display")}
                      onKeyDown={(e) => {
                        const key = e.key;
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          " ",
                        ];
                        if (
                          !onlyAlphabet.test(key) &&
                          !allowedKeys.includes(key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={characterLength_50}
                    />
                    <ErrorMessage value={errors.text_to_display} />
                  </div>
                </div>
                <div className="form-wrap">
                  <div className="edit-links-info">
                    <h4>Added Links</h4>
                    <ul>
                      <li>
                        <Link to="#">
                          <img src={editLinkIcon} alt="Icon" />
                          <span>lorem Ipsum link</span>
                        </Link>
                        <div className="links-icon">
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-trash" />
                          </Link>
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-pencil" />
                          </Link>
                        </div>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={editLinkIcon} alt="Icon" />
                          <span>resources, or documents</span>
                        </Link>
                        <div className="links-icon">
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-trash" />
                          </Link>
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-pencil" />
                          </Link>
                        </div>
                      </li>
                      <li>
                        <Link to="#">
                          <img src={editLinkIcon} alt="Icon" />
                          <span>enhance the informational </span>
                        </Link>
                        <div className="links-icon">
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-trash" />
                          </Link>
                          <Link to="#" className="edit-link-icon">
                            <i className="ti ti-pencil" />
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="form-wrap mb-0">
                  <button
                    className="btn btn-primary rounded w-100"
                    type="button"
                  >
                    Buy Now
                  </button>
                  <button
                    className="btn btn-cancel w-100"
                    type="button"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddLink;
