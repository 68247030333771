import React, { useEffect, useState } from "react";
import { viewIcon10 } from "../../../../utils/imagepath";
import { Link } from "react-router-dom";
import { getData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";

const insideList = (name = "", data = []) => {
  if (Array.isArray(data) && data?.length > 0) {
    return (
      <div className="view-special-grid">
        <h6>{name}</h6>
        <ul className="nav">
          {data?.map((item: any) => {
            return (
              <li key={item}>
                {" "}
                <Link to="#">{item}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else return null;
};
function Specialties() {
  const [list, setList] = useState<any>([]);

  const getList = async () => {
    const response = await getData(end_points.getSpecialitiesListApi.url);
    if (response.status === 200) {
      setList(response.data.data);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="specialties-tab"
      role="tabpanel"
      aria-labelledby="specialties_tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card view-special-card">
            <div className="card-body">
              <h4>
                <img src={viewIcon10} alt="Icon" />
                Clinical Specialties, Expertise, &amp; Experience
              </h4>

              {insideList(
                "Clinical Specialties",
                list?.clinical_specialties?.clinical_specialties
              )}
              {insideList(
                "Clinical Expertise",
                list?.clinical_specialties?.clinical_expertise
              )}
              {insideList(
                "Clinical Experience",
                list?.clinical_specialties?.clinical_experience
              )}
              {/* <div className="view-special-grid">
                <h6>Clinical Specialties</h6>
                <ul className="nav">
                  <li>
                    <Link to="#">Cardiology</Link>
                  </li>
                  <li>
                    <Link to="#">Dermatology</Link>
                  </li>
                </ul>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Specialties;
