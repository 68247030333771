import { useForm } from "react-hook-form";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import ErrorMessage from "../../../../components/errorMessage";
import {
  addIdentityAgeSchema,
  addIdentityFaithSchema,
  addIdentityGenderSchema,
  addIdentityRaceSchema,
  addIdentitySexualitySchema,
} from "../../../authentication/components/yupSchema";
import {
  ageMaxLength,
  onlyNumber,
} from "../../../../utils/patterns/regex.pattern";
import { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { Dropdown } from "primereact/dropdown";
import { toast } from "react-toastify";
import {
  genderOptions,
  sexualityOptions,
} from "../../../../utils/constants/index.";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: any;
  raceMyIdentity: any;
  faithMyIdentity: any;
}

const AddIdentitySchema = Yup.object().shape({
  age: addIdentityAgeSchema,
  gender: addIdentityGenderSchema,
  race: addIdentityRaceSchema,
  faith: addIdentityFaithSchema,
  sexuality: addIdentitySexualitySchema,
});

const IdentityModal = (props: props) => {
  const { getDirectoryData, directoryData, raceMyIdentity, faithMyIdentity } =
    props;

  const raceOptions = raceMyIdentity?.map((val: any) => ({
    label: val.name,
    value: val.name,
  }));
  const faithOptions = faithMyIdentity?.map((val: any) => ({
    label: val.name,
    value: val.name,
  }));

  raceOptions && console.log("race options", raceOptions);
  faithOptions && console.log("faith options", faithOptions);

  const identityRef = useRef<HTMLButtonElement | null>(null);

  const {
    register,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
    control,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(AddIdentitySchema),
  });

  const OnClose = () => {
    reset();
    if (identityRef.current) {
      identityRef.current.click();
    }
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    showLoader();
    try {
      const payload = {
        age: data?.age,
        gender: data?.gender,
        race: data?.race,
        faith: data?.faith,
        sexuality: data?.sexuality,
        other: "",
      };
      const response = await putData(
        end_points.profile_about_addidentity_update.url,
        payload
      );
      if (response.status == 200) {
        console.log("response data:", response);
        toast.success(response?.data?.message);
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
      reset();
    }
  };

  useEffect(() => {
    if (directoryData) {
      setValue("age", directoryData?.my_identity?.age);
      setValue("gender", directoryData?.my_identity?.gender);
      setValue("faith", directoryData?.my_identity?.faith);
      setValue("race", directoryData?.my_identity?.race);
      setValue("sexuality", directoryData?.my_identity?.sexuality);
    }
  }, [directoryData]);

  return (
    <>
      {" "}
      <div
        className="modal fade credential-modal finances-modal"
        id="identity-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">My Identity</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={identityRef}
                onClick={OnClose}
              />
            </div>
            <div className="modal-body">
              <form action="#" onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="form-wrap-add">
                  <div className="form-wrap">
                    <label>Age</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Age"
                      {...register("age", {
                        onChange: (e) => {
                          let value = parseInt(e.target.value);

                          if (value > 100) {
                            e.target.value = "100";
                          }

                          if (value < 1) {
                            e.target.value = "1";
                          }
                        },
                      })}
                      maxLength={ageMaxLength}
                      onKeyDown={(e) => {
                        const key = e.key;
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          " ",
                        ];
                        if (
                          !onlyNumber.test(key) &&
                          !allowedKeys.includes(key)
                        ) {
                          e.preventDefault();
                        }
                      }}
                    />
                    <ErrorMessage value={errors.age} />
                  </div>
                  <div className="form-wrap">
                    <label>Gender</label>
                    <Controller
                      name="gender"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={genderOptions && genderOptions}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder="Select"
                          className={`select w-100 ${errors.gender ? "is-invalid" : ""}`}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.gender} />
                  </div>
                  <div className="form-wrap">
                    <label>Race</label>
                    <Controller
                      name="race"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={raceOptions && raceOptions}
                          onChange={(e) => {
                            console.log(e.value, "e.value");

                            field.onChange(e.value);
                            trigger("race");
                          }}
                          filter={genderOptions?.length > 0 ? true : false}
                          placeholder="Select Country"
                          className="select w-100"
                          defaultValue={"US"}
                          appendTo="self"
                        />
                      )}
                    />
                    <ErrorMessage value={errors.race} />
                  </div>
                  <div className="form-wrap">
                    <label>Faith</label>
                    <Controller
                      name="faith"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={faithOptions && faithOptions}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder="Select"
                          className={`select w-100 ${errors.gender ? "is-invalid" : ""}`}
                          filter={true}
                          appendTo="self"
                        />
                      )}
                    />
                    <ErrorMessage value={errors.faith} />
                  </div>
                  <div className="form-wrap">
                    <label>Sexuality</label>
                    <Controller
                      name="sexuality"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={sexualityOptions && sexualityOptions}
                          onChange={(e) => field.onChange(e.value)}
                          placeholder="Select"
                          className={`select w-100 ${errors.gender ? "is-invalid" : ""}`}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.sexuality} />
                  </div>
                </div>
                <div className="form-wrap mb-0">
                  <button
                    className="btn btn-primary rounded w-100"
                    type="submit"
                  >
                    Save
                  </button>
                  <button className="btn btn-cancel w-100" onClick={OnClose}>
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default IdentityModal;
