import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { trendUp } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";

const WrittenPerformance = () => {
  const [profileView, setProfileView] = useState<any>({
    series: [
      {
        name: "New Users",
        data: [10, 40, 15, 51, 59, 40, 69, 91, 48],
      },
      {
        name: "Old Users",
        data: [20, 60, 45, 31, 59, 40, 29, 51, 28],
      },
    ],
    colors: ["#FCC69D", "#89D0CD"],
    chart: {
      height: 250,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  });
  const [engagementMetrics, setEngagementMetrics] = useState<any>({
    series: [
      {
        name: "Likes",
        data: [31, 40, 28, 51, 42, 109, 100, 40, 28, 51, 42, 109],
      },
      {
        name: "Comments",
        data: [22, 53, 64, 63, 68, 98, 23, 3, 21, 54, 65, 23],
      },
      {
        name: "Shares",
        data: [11, 32, 45, 32, 34, 52, 41, 65, 58, 52, 12, 25],
      },
    ],
    colors: ["#FCC69D", "#89D0CD", "#FCED8F"],
    chart: {
      height: 300,
      type: "line",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });
  const [subscribers, setSubscribers] = useState<any>({
    series: [17, 15],
    chart: {
      height: 300,
      type: "donut",
    },
    colors: ["#FCC69D", "#89D0CD"],
    labels: ["Subscribe", "Unsubscribe"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
        },
      },
    ],
  });
  const [favourites, setFavourites] = useState<any>({
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#89D0CD", "#89D0CD"],
    annotations: {
      xaxis: [
        {
          x: 500,
          borderColor: "#89D0CD",
          label: {
            borderColor: "#00E396",
            style: {
              color: "#333",
              background: "#00E396",
            },
          },
        },
      ],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: ["#01", "#02", "#03", "#04", "#05", "#06", "07"],
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
    },
  });
  const [selectedMonth1, setSelectedMonth1] = useState(null);
  const [selectedMonth2, setSelectedMonth2] = useState(null);
  const [selectedMonth3, setSelectedMonth3] = useState(null);
  const [selectedMonth4, setSelectedMonth4] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const monthOptions = [
    { label: "May 2024", value: "May 2024" },
    { label: "June 2024", value: "June 2024" },
  ];
  const yearOptions = [
    { label: "This Year", value: "This Year" },
    { label: "June 2024", value: "June 2024" },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Account Performance</Link>
              </li>
              <li>Written Content Performance</li>
            </ul>
          </div>
          <div className="row flex-body d-flex row-gap-30 chart-set">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Overview</h3>
                    <p>
                      Comprehensive data insights guiding strategic decisions
                      and business growth.
                    </p>
                  </div>
                  <div className="chat-select">
                    <Dropdown
                      value={selectedMonth1}
                      options={monthOptions}
                      onChange={(e) => setSelectedMonth1(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card-insights">
                        <p className="d-flex align-items-center justify-content-between">
                          Total View on Written Content
                          <Link to="#" className="ms-auto">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>3,671</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights danger-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Average Time on Page
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>
                            30<span>min</span>
                          </h3>
                          <p>
                            +6.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights warning-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Social Shares
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>4500</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-title">
                <h6>Traffic Sources</h6>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Profile View{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <div className="chat-select">
                    <Dropdown
                      value={selectedMonth2}
                      options={monthOptions}
                      onChange={(e) => setSelectedMonth2(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="profile-view">
                        <ReactApexChart
                          height={300}
                          options={profileView}
                          series={profileView.series}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-title">
                <h6>Written Content Engagement Metrics</h6>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Engagement Metrics{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <div className="chat-select">
                    <Dropdown
                      value={selectedMonth3}
                      options={monthOptions}
                      onChange={(e) => setSelectedMonth3(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="engagement-metrics">
                        <ReactApexChart
                          height={300}
                          options={engagementMetrics}
                          series={engagementMetrics.series}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 d-flex">
              <div className="custom-card flex-fill w-100">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Subscribers</h3>
                  </div>
                  <div className="chat-select">
                    <Dropdown
                      value={selectedMonth4}
                      options={monthOptions}
                      onChange={(e) => setSelectedMonth4(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="subscribers">
                        <ReactApexChart
                          height={300}
                          options={subscribers}
                          series={subscribers.series}
                          type="donut"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Top 10 articles/Blogs Performer
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <div className="chat-select ">
                    <Dropdown
                      value={selectedYear}
                      options={yearOptions}
                      onChange={(e) => setSelectedYear(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="faviorates">
                        <ReactApexChart
                          height={300}
                          options={favourites}
                          series={favourites.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* /Page Wrapper */}
      </div>
      {/* Page Wrapper */}
    </>
  );
};

export default WrittenPerformance;
