import React, { useContext, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { SpinnerContext } from "../spinner/spinner";
import CustomPaginator from "./custom-paginator";
import SortIcon from "./sort-icon";

interface Props {
  column: any;
  data: any;
  totalRecords: number;
  rowClassName?: string;
  currentPage: number;
  setCurrentPage: any;
  rows?: number;
  setRows?: any;
  onRowDoubleClick?: Function;
  onRowClickSetState?: boolean;
  type?: string;
  onClickNavigate?: Function;
  sortable?: boolean;
  footer?: any;
  setSearchQuery?: any;
}

const PrimeDataTable: React.FC<Props> = ({
  column,
  data = [],
  totalRecords,
  currentPage = 1,
  setCurrentPage,
  rows = 5,
  setRows,
  sortable = true,
  footer = null,
  setSearchQuery = () => {},
}) => {
  const { isLoading } = useContext(SpinnerContext);
  const totalPages = Math.ceil(totalRecords / rows);
  const onPageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  const customEmptyMessage = () => (
    <div className="text-center p-3">
      {!isLoading && <h5>No records found</h5>}
    </div>
  );

  const path = window.location.pathname;
  const customSortIcon = () => {
    return <SortIcon />;
  };

  return (
    <>
      <DataTable
        value={data}
        className="table datatable"
        totalRecords={totalRecords}
        paginator={false}
        emptyMessage={customEmptyMessage}
      >
        {column?.map((col: any, index: number) => (
          <Column
            key={col.field || index}
            field={col.field}
            header={col.header}
            body={col.body}
            sortable={
              sortable === false ? false : col.sortable === false ? false : true
            }
            sortField={col.sortField ? col.sortField : col.field}
            className={col.className ? col.className : ""}
          />
        ))}
      </DataTable>
      {path != "/repair-details" && (
        <CustomPaginator
          currentPage={currentPage}
          totalPages={totalPages}
          totalRecords={totalRecords}
          onPageChange={onPageChange}
          rows={rows}
        />
      )}
      {footer ? footer() : null}
    </>
  );
};

export default PrimeDataTable;
