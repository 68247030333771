import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import FullCalendarComponent from "./full-calendar";
import { Dialog } from "primereact/dialog";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import DatePicker from "react-datepicker";

const Calendar = () => {
  const [time1, setTime1] = useState(new Date());
  const [time2, setTime2] = useState(new Date());

  const [selectedOther, setSelectedOther] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showDialogue, setShowDialogue] = useState(false);
  const otherOptions = [
    { label: "Other", value: "Other" },
    { label: "Category", value: "Category" },
  ];

  const categoryOptions = [{ label: "Category", value: "Category" }];

  const subCategoryOptions = [{ label: "SubCategory", value: "SubCategory" }];

  const groupOptions = [{ label: "Group", value: "Group" }];

  const [rangeText, setRangeText] = useState("");

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="d-flex align-items-center justify-content-between flex-wrap">
            <div className="breadcrumb-blk">
              <ul className="flex-style m-0">
                <li>
                  <Link to="#">Directory </Link>
                </li>
                <li>Calendar</li>
              </ul>
            </div>
            <div className="calendar-list">
              <ul>
                <li>
                  <Link to="#" className="active">
                    <i className="ti ti-calendar-month" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ti ti-list" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-12 mb-4">
            <p className="calendar-para">
              Effortlessly manage your schedule with a comprehensive calendar,
              showcasing all your upcoming events, ensuring you stay organized
              and never miss an important moment.
            </p>
          </div>
          <div className="row flex-body d-flex row-gap-30 chart-set">
            {/* calendar */}
            <div className="col-md-12">
              <div className="custom-card">
                <div id="calendar-book">
                  <FullCalendarComponent setShowDialogue={setShowDialogue} />
                </div>
              </div>
            </div>
            {/* /calendar */}
          </div>
        </div>
        {/* /Page Wrapper */}
      </div>
      <Dialog
        draggable={false}
        header={
          <div className="modal-header">
            <div>
              <h5 className="modal-title">Add Event</h5>
              <p>
                Add Event is a simple tool for scheduling and managing events.
              </p>
            </div>
          </div>
        }
        visible={showDialogue}
        onHide={() => setShowDialogue(false)}
      >
        <div className="modal-body">
          <form>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="col-form-label">Title:</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Long Event"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="col-form-label">Category:</label>
                  <Dropdown
                    value={selectedOther}
                    options={otherOptions}
                    onChange={(e) => setSelectedOther(e.value)}
                    placeholder="Select"
                    className="select w-100"
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="col-form-label">Category</label>
                  <Dropdown
                    value={selectedCategory}
                    options={categoryOptions}
                    onChange={(e) => setSelectedCategory(e.value)}
                    placeholder="Select a Category"
                    className="select w-100"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="mb-3">
                  <label className="col-form-label">Sub-Category</label>
                  <Dropdown
                    value={selectedSubCategory}
                    options={subCategoryOptions}
                    onChange={(e) => setSelectedSubCategory(e.value)}
                    placeholder="Select a SubCategory"
                    className="select w-100"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-12">
                <div className="mb-3">
                  <label className="col-form-label">Group</label>
                  <Dropdown
                    value={selectedGroup}
                    options={groupOptions}
                    onChange={(e) => setSelectedGroup(e.value)}
                    placeholder="Select here"
                    className="select w-100"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-3">
                  <label className="col-form-label">Date Selector:</label>
                  <div className="icon-form cal-modal-input">
                    <span className="form-icon">
                      <i className="ti ti-calendar" />
                    </span>
                    <DateRangePicker initialSettings={initialSettings}>
                      <input
                        type="text"
                        className="form-control bookingrange"
                        value={
                          rangeText ||
                          `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                        }
                        readOnly
                      />
                    </DateRangePicker>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="mb-1">
                  <label className="col-form-label">Time:</label>
                </div>
              </div>
            </div>
            <div className="row align-items-center">
              <div className="col-lg-5">
                <div className="mb-3">
                  {/* <input
                    type="text"
                    placeholder="04:00 PM"
                    className="form-control timepicker"
                  /> */}
                  <DatePicker
                    className="form-control timepicker"
                    showTimeSelectOnly
                    selected={time1}
                    onChange={(time) => setTime1(time)}
                  />
                </div>
              </div>
              <div className="col-lg-1">
                <div className="mb-3">
                  <span>to</span>
                </div>
              </div>
              <div className="col-lg-6">
                <div className="mb-3">
                  <DatePicker
                    className="form-control timepicker"
                    showTimeSelectOnly
                    selected={time2}
                    onChange={(time) => setTime2(time)}
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-3">
                <label htmlFor="message-text" className="col-form-label">
                  Description:
                </label>
                <textarea
                  className="form-control"
                  placeholder="Write Description for event|"
                  defaultValue={""}
                />
              </div>
            </div>
          </form>
        </div>
      </Dialog>
    </>
  );
};

export default Calendar;
