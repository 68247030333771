import React, { useEffect, useRef, useState } from "react";
import {
  avatar02,
  avatar03,
  verified,
  avatar04,
  avatar06,
  companyLogo,
  comma,
  img02,
  img01,
  benefit01,
  benefit02,
  benefit03,
  benefit04,
  benefit05,
  benefit06,
  benefit07,
  benefit08,
  benefit09,
} from "../../utils/imagepath";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";

const FrontendIndex = () => {
  const facilitiesSlider = {
    loop: false,
		margin: 24,
		nav: false,
		dots: true,
		smartSpeed: 2000,
		autoplay: false,
		animateOut: 'slideOutUp',
		navText: [ '<i class="ti ti-arrow-left"></i>', '<i class="ti ti-arrow-right"></i>' ], 
		responsive: {
			0: {
				items: 1
			},
			550: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 2
			},
			1200: {
				items: 2.6
			}
		}
  };
  const featuredSlider = {
    loop: true,
		margin: 24,
		nav: true,
		dots: true,
		smartSpeed: 2000,
		autoplay: false,
		navText: [ '<i class="ti ti-arrow-left"></i>', '<i class="ti ti-arrow-right"></i>' ], 
		responsive: {
			0: {
				items: 1
			},
			550: {
				items: 1
			},
			768: {
				items: 2
			},
			992: {
				items: 2
			},
			1200: {
				items: 3
			}
		}
  };

  const [rightPosition, setRightPosition] = useState(0);
  const sliderRef = useRef<HTMLDivElement | null>(null);

  const updateSliderPadding = () => {
      const windowWidth = window.outerWidth;
      const containerWidth = document.querySelector('.container')?.clientWidth || 0;
      const fullWidth = windowWidth - containerWidth;
      const rightPositionValue = fullWidth / 2;
      setRightPosition(rightPositionValue);
  };

  useEffect(() => {
      // Initial padding setup
      updateSliderPadding();

      // Update padding on window resize
      const handleResize = () => updateSliderPadding();
      window.addEventListener('resize', handleResize);

      // Cleanup event listener on component unmount
      return () => {
          window.removeEventListener('resize', handleResize);
      };
  }, []);

  useEffect(() => {
      const sliderElement = sliderRef.current;

      const handleTranslate = () => {
          if (sliderElement) {
              sliderElement.style.paddingLeft = '0px';
          }
      };

      const handleTranslated = () => {
          updateSliderPadding();
      };

      // Assuming 'translate.owl.carousel' and 'translated.owl.carousel' are owl-carousel events.
      sliderElement?.addEventListener('translate.owl.carousel', handleTranslate);
      sliderElement?.addEventListener('translated.owl.carousel', handleTranslated);

      return () => {
          sliderElement?.removeEventListener('translate.owl.carousel', handleTranslate);
          sliderElement?.removeEventListener('translated.owl.carousel', handleTranslated);
      };
  }, []);

  return (
    <>
      {" "}
      {/*banner */}
      <section className="banner-section">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="banner-contents wow fadeInUp">
                <h1>
                  Join <span>TurningWell</span> to Connect With Patients All
                  Over the World
                </h1>
                <Link to="#" className="btn btn-primary rounded  ">
                  Become a Member
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/banner */}
      {/*blog */}
      <section className="fill-sign-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 mx-auto wow fadeInUp">
              <div className="fill-sign-div text-center">
                <h3>
                  Fill out our sign up form to quickly get a TurningWell profile
                  and start being searched on our global online database.
                </h3>
                <Link to="#" className="btn btn-primary rounded">
                  Join Turning Well
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="benefits-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-header wow fadeInUp">
                <h3>Discover the Benefits of Joining TurningWell</h3>
                <p>
                  As a provider or facility, becoming a member of Turning Well
                  offers you a multitude of advantages for a low, flat-rate fee
                  of ($$). Our platform is designed to help you grow your
                  practice and reach more patients. Here are some of the key
                  benefits
                </p>
              </div>
            </div>
            <div className="benefit-div wow fadeInUp">
              <ul>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit01} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>International Exposure</h6>
                      <p>
                        Get listed on our global directory and increase your
                        visibility to potential patients worldwide.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit02} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Content Sharing</h6>
                      <p>
                        Create and publish blogs that are featured across our
                        site, enhancing your online presence.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit03} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Video Uploads</h6>
                      <p>
                        Upload videos to build a following and potentially earn
                        revenue.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit04} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Announcements</h6>
                      <p>
                        Post updates about your practice or facility on our
                        local, national, and global feeds.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit05} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Live Interaction</h6>
                      <p>
                        Schedule live video feeds to engage with your Turning
                        Well audience in real-time.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit06} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Advertising Opportunities</h6>
                      <p>
                        Promote your practice or facility through various
                        advertising options.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit07} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Analytics</h6>
                      <p>
                        Monitor the performance of your profile and
                        advertisements directly from your dashboard.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit08} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>CME/CEU Courses</h6>
                      <p>
                        Offer continuing education courses and receive payment
                        through our platform.
                      </p>
                    </div>
                  </div>
                </li>
                <li>
                  <div className="benefits-path">
                    <div className="benefit-img">
                      <img src={benefit09} alt="" />
                    </div>
                    <div className="benefit-content">
                      <h6>Job Postings</h6>
                      <p>
                        Post job openings for your practice or facility and
                        access information about potential hires before the
                        interview process.
                      </p>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="joins-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 wow fadeInUp">
              <div className="blog-contents">
                <p>
                  Join TurningWell today to take advantage of the many benefits
                  of our platform that can enhance your practice and allow you
                  to connect with more patients.
                </p>
                <Link to="#" className="btn btn-white rounded">
                  Join Turning Well
                </Link>
              </div>
            </div>
            <div className="col-lg-6 col-12 wow zoomIn">
              <div className="blog-imgs">
                <img src={img02} alt="img" />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      {/*blog */}
      <section className="blog-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-12 wow fadeInUp">
              <div className="blog-imgs">
                <img src={img01} alt="img" />
              </div>
            </div>
            <div className="col-lg-6 col-12 wow zoomIn">
              <div className="blog-contents">
                <p>
                  Access your provider dashboard anytime to schedule
                  announcements, update your event calendar, post blog and video
                  content, schedule live feed videos, track metrics, and much
                  more!{" "}
                </p>
                <Link to="#" className="btn btn-primary rounded">
                  Sign up Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/blog */}
      <section className="blogvideo-section">
        <div className="container wow fadeInUp">
          <div className="row">
            <div className="col-12">
              <div className="section-header sectin-providers">
                <h3>What Providers and Facilities Say About TurningWell</h3>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="slider-service wow fadeInUp" ref={sliderRef}
            style={{ paddingLeft: `${rightPosition}px` }}>
            <div className="facilities-slider owl-navs owl-dotdivs">
            <OwlCarousel {...facilitiesSlider}>
              <div className="facility-service">
                <div className="facility-rating">
                  <ul className="rating">
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star" />
                    </li>
                  </ul>
                  <img src={companyLogo} alt="" />
                </div>
                <div className="facility-conetnt">
                  <p>
                    The TurningWell Dashboard has transformed my practice,
                    making patient care more efficient and allowing me to focus
                    on what matters most – my patients.
                  </p>
                </div>
                <div className="facility-user">
                  <div className="facility-users">
                    <div className="facility-users-img">
                      <img src={avatar02} alt="" />
                    </div>
                    <div className="facility-users-content">
                      <h6>Dr. Mark Anderson</h6>
                      <p>Clinical Psychologist</p>
                    </div>
                  </div>
                  <img src={comma} alt="" />
                </div>
              </div>
              <div className="facility-service">
                <div className="facility-rating">
                  <ul className="rating">
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star" />
                    </li>
                  </ul>
                  <img src={companyLogo} alt="" />
                </div>
                <div className="facility-conetnt">
                  <p>
                    The TurningWell Dashboard has transformed my practice,
                    making patient care more efficient and allowing me to focus
                    on what matters most – my patients.
                  </p>
                </div>
                <div className="facility-user">
                  <div className="facility-users">
                    <div className="facility-users-img">
                      <img src={avatar02} alt="" />
                    </div>
                    <div className="facility-users-content">
                      <h6>Dr. Mark Anderson</h6>
                      <p>Clinical Psychologist</p>
                    </div>
                  </div>
                  <img src={comma} alt="" />
                </div>
              </div>
              <div className="facility-service">
                <div className="facility-rating">
                  <ul className="rating">
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star" />
                    </li>
                  </ul>
                  <img src={companyLogo} alt="" />
                </div>
                <div className="facility-conetnt">
                  <p>
                    The TurningWell Dashboard has transformed my practice,
                    making patient care more efficient and allowing me to focus
                    on what matters most – my patients.
                  </p>
                </div>
                <div className="facility-user">
                  <div className="facility-users">
                    <div className="facility-users-img">
                      <img src={avatar02} alt="" />
                    </div>
                    <div className="facility-users-content">
                      <h6>Dr. Mark Anderson</h6>
                      <p>Clinical Psychologist</p>
                    </div>
                  </div>
                  <img src={comma} alt="" />
                </div>
              </div>
              <div className="facility-service">
                <div className="facility-rating">
                  <ul className="rating">
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star-filled" />
                    </li>
                    <li>
                      <i className="ti ti-star" />
                    </li>
                  </ul>
                  <img src={companyLogo} alt="" />
                </div>
                <div className="facility-conetnt">
                  <p>
                    The TurningWell Dashboard has transformed my practice,
                    making patient care more efficient and allowing me to focus
                    on what matters most – my patients.
                  </p>
                </div>
                <div className="facility-user">
                  <div className="facility-users">
                    <div className="facility-users-img">
                      <img src={avatar02} alt="" />
                    </div>
                    <div className="facility-users-content">
                      <h6>Dr. Mark Anderson</h6>
                      <p>Clinical Psychologist</p>
                    </div>
                  </div>
                  <img src={comma} alt="" />
                </div>
              </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
      </section>
      {/*faq */}
      <section className="faq-section">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="section-header text-center">
                <h3>FAQs About TurningWell</h3>
              </div>
            </div>
            <div className="col-lg-10 mx-auto">
              <div
                className="accordion accordion-flush border-top"
                id="accordionFaq"
              >
                <div
                  className="accordion-item show wow fadeInUp"
                  data-wow-delay="0.2s"
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapseOne"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Who Should Consider Joining TurningWell?
                      <span className="accordin-action">
                        <i className="ti ti-plus" />
                      </span>
                    </button>
                  </h2>
                  <div
                    id="faq-collapseOne"
                    className="accordion-collapse "
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body">
                      <p className="mb-0">
                        Turning Well members have access to comprehensive
                        analytics, allowing them to track how many potential
                        patients have reached out, viewed their directory
                        listing, profile, videos, blog content, and more.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item wow fadeInUp"
                  data-wow-delay="0.4s"
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapsetwo"
                      aria-expanded="false"
                      aria-controls="faq-collapsetwo"
                    >
                      How Much Does it Cost?
                      <span className="accordin-action">
                        <i className="ti ti-plus" />
                      </span>
                    </button>
                  </h2>
                  <div
                    id="faq-collapsetwo"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body">
                      <p className="mb-0">
                        Turning Well members have access to comprehensive
                        analytics, allowing them to track how many potential
                        patients have reached out, viewed their directory
                        listing, profile, videos, blog content, and more.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="accordion-item  wow fadeInUp"
                  data-wow-delay="0.6s"
                  style={{
                    visibility: "visible",
                    animationDelay: "0.6s",
                    animationName: "fadeInUp",
                  }}
                >
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button "
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#faq-collapsthree"
                      aria-expanded="false"
                      aria-controls="faq-collapsthree"
                    >
                      How can I tell my membership is worth it?
                      <span className="accordin-action">
                        <i className="ti ti-plus" />
                      </span>
                    </button>
                  </h2>
                  <div
                    id="faq-collapsthree"
                    className="accordion-collapse collapse show"
                    data-bs-parent="#accordionFaq"
                  >
                    <div className="accordion-body">
                      <p className="mb-0">
                        Turning Well members have access to comprehensive
                        analytics, allowing them to track how many potential
                        patients have reached out, viewed their directory
                        listing, profile, videos, blog content, and more.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/faq */}
      {/* related blogs */}
      <section className="local-section">
        <div className="container">
          <div className="row">
            <div className="col-12  wow fadeInUp">
              <div className="section-header d-flex align-items-center justify-content-between flex-wrap">
                <h3 className="mb-0">Featured Providers</h3>
              </div>
            </div>
            <div className="col-12  wow fadeInUp">
              <div className="featured-slider owl-navs">
              <OwlCarousel {...featuredSlider}>
                <div className="doctor-profile doctor-featured">
                  <div className="doctor-profile-imgname ">
                    <div className="doctor-profile-img">
                      <img src={avatar03} alt="img" />
                    </div>
                    <div className="doctor-profile-name">
                      <h4>
                        <Link to="#">
                          Dr. Jake Reynolds{" "}
                          <img src={verified} className="ms-1" alt="" />
                        </Link>
                      </h4>
                      <h3>Mental Health Therapist</h3>
                      <h5>San Francisco, California</h5>
                    </div>
                  </div>
                  <div className="doctor-profile-content">
                    <p>
                      Dr. Jake Reynolds is a clinical psychologist with over 10
                      years of experience in cognitive behavioural therapy.
                    </p>
                    <ul className="rating">
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star" />
                      </li>
                      <li>
                        <span>(104 Review)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="doctor-profile doctor-featured">
                  <div className="doctor-profile-imgname ">
                    <div className="doctor-profile-img">
                      <img src={avatar04} alt="img"  />
                    </div>
                    <div className="doctor-profile-name">
                      <h4>
                        <Link to="#">
                          Emily Carter, DDS
                          <img src={verified} className="ms-1" alt="" />
                        </Link>
                      </h4>
                      <h3>Dentist</h3>
                      <h5>Austin, Texas</h5>
                    </div>
                  </div>
                  <div className="doctor-profile-content">
                    <p>
                      Dr. Emily Carter is a clinical psychologist with over 12
                      years of experience in cognitive behavioural therapy.
                    </p>
                    <ul className="rating">
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star" />
                      </li>
                      <li>
                        <span>(21 Review)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="doctor-profile doctor-featured">
                  <div className="doctor-profile-imgname ">
                    <div className="doctor-profile-img">
                      <img src={avatar06} alt="img"  />
                    </div>
                    <div className="doctor-profile-name">
                      <h4>
                        <Link to="#">
                          Maria Thompson, PhD
                          <img src={verified} className="ms-1" alt="" />
                        </Link>
                      </h4>
                      <h3>Maria Thompson, PhD</h3>
                      <h5>San Francisco, California</h5>
                    </div>
                  </div>
                  <div className="doctor-profile-content">
                    <p>
                      Dr. Maria Thompson is a licensed professional counselor
                      with over 15 years of experience in mental health therapy.
                    </p>
                    <ul className="rating">
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star" />
                      </li>
                      <li>
                        <span>(45 Review)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="doctor-profile doctor-featured">
                  <div className="doctor-profile-imgname ">
                    <div className="doctor-profile-img">
                      <img src={avatar03} alt="img"  />
                    </div>
                    <div className="doctor-profile-name">
                      <h4>
                        <Link to="#">
                          Dr. Jake Reynolds{" "}
                          <img src={verified} className="ms-1" alt="" />
                        </Link>
                      </h4>
                      <h3>Mental Health Therapist</h3>
                      <h5>San Francisco, California</h5>
                    </div>
                  </div>
                  <div className="doctor-profile-content">
                    <p>
                      Dr. Jake Reynolds is a clinical psychologist with over 10
                      years of experience in cognitive behavioural therapy.
                    </p>
                    <ul className="rating">
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star-filled" />
                      </li>
                      <li>
                        <i className="ti ti-star" />
                      </li>
                      <li>
                        <span>(104 Review)</span>
                      </li>
                    </ul>
                  </div>
                </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* related blogs */}
      {/*join */}
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12 m-auto">
              <div className="join-div wow zoomIn">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <h6>
                      Sign up to see what TurningWell can do for your practice
                      today
                    </h6>
                    <Link to="#" className="btn btn-signup rounded">
                      Become a Member
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/join */}
    </>
  );
};

export default FrontendIndex;
