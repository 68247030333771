import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  autoGenerate,
  cal,
  clone,
  download,
  infoIcon,
  uploadFile,
  youtube,
} from "../../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";
import { Chips } from "primereact/chips";
import * as Yup from "yup";
import { validImageTypes } from "../../../utils/patterns/regex.pattern";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../components/errorMessage";
import { end_points } from "../../../services/endpoints";
import { getData, postData } from "../../../services/api.service";
import { Upload } from "tus-js-client";
import { Calendar } from "primereact/calendar";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import {
  dateFormat_YYYY_MM_DD,
  formatTimeTo12Hour,
} from "../../../utils/constants/index.";
import { toast } from "react-toastify";
import { routes } from "../../../utils/routes/routes";

const validationSchema = Yup.object().shape({
  title: Yup.string().trim().required("Video title is required"),
  description: Yup.string().trim().required("Video description is required"),
  tags: Yup.array()
    .min(1, "Please add at least one tag.")
    .required("Tags are required."),
  category: Yup.string().trim().required("Category is required"),
  subcategory: Yup.string().trim().required("Subcategory is required"),
  group: Yup.string().trim().required("Group is required"),
  upload_video: Yup.mixed()
    .test("fileSize", "File size should not exceed 100MB", (value: any) => {
      if (!value || !value.length || typeof value === "string") return true;
      return value.size <= 100 * 1024 * 1024;
    })
    .test("fileType", "Unsupported file format", (value: any) => {
      if (!value || !value.length || typeof value === "string") return true;
      return ["video/mp4", "video/avi", "video/wmv", "video/webm"].includes(
        value.type
      );
    })
    .required("Video is required"),
  thumbnail: Yup.mixed()
    .nullable() // Allow the value to be null
    .notRequired() // Make the field optional
    .test("image.value", "Please upload an image", (value: any) => {
      if (!value || value === "") {
        // Skip validation if the field is empty or not provided
        return true;
      }
      return value !== "";
    })
    .test(
      "image.type",
      "Invalid file format. Please upload an image file (svg, jpg, jpeg, png).",
      (value: any) => {
        if (value == "") {
          return false;
        }
        if (!value || !value.length || typeof value === "string") {
          // Skip validation if the field is empty
          return true;
        }
        return value && validImageTypes?.includes(value[0]?.type);
      }
    )
    .test("fileSize", "File size is too large", (value: any) => {
      if (value == "") {
        return false;
      }
      if (!value || !value.length || typeof value === "string") {
        // Skip validation if the field is empty
        return true;
      }
      return value && value[0].size <= 4194304;
    }),
  set_as_exclusive: Yup.boolean(),
  schedule_toggle: Yup.boolean(),
  schedule_date: Yup.date()
    .nullable()
    .when("schedule_toggle", {
      is: true,
      then: (schema) => schema.required("Schedule date is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  schedule_time: Yup.date()
    .nullable()
    .when("schedule_toggle", {
      is: true,
      then: (schema) => schema.required("Schedule time is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  checkboxGroup: Yup.array()
    .min(1, "At least one checkbox must be selected.")
    .required("At least one checkbox must be selected."),
  ageRestriction: Yup.string()
    .oneOf(
      [
        "Yes, restrict my video to viewers over 18",
        "No, don't restrict my video to viewers over 18 only",
      ],
      "Please select a valid option."
    )
    .required("Please select an age restriction option."),
});

const AddVideo = () => {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    setError,
    trigger,
    getValues,
    reset,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      title: "",
      description: "",
      tags: [],
      category: null,
      subcategory: null,
      group: null,
      schedule_toggle: false,
      schedule_date: null,
      schedule_time: null,
      thumbnail: null,
      checkboxGroup: [],
      ageRestriction: "No, don't restrict my video to viewers over 18 only",
    },
  });

  const [chipValue, setChipValue] = useState<string[]>([]);
  const [scheduleToggle, setScheduleToggle] = useState<boolean>(false);
  const [uploadedFileName, setUploadedFileName] = useState<string | null>(null);
  const [thumbnailFileName, setThumbnailFileName] = useState<string | null>(
    null
  );
  const [thumnailImage, setThumnailImage] = useState<any>([]);
  const [scheduleDate, setScheduleDate] = useState<Date | null>(null);
  const [minTime, setMinTime] = useState<any>(null);
  const [categoryListData, setCategoryListData] = useState<any>({});
  const [subCategoryListData, setSubCategoryListData] = useState<any>({});
  const [groupListData, setGroupListData] = useState<any>({});
  const [enableSubCat, setEnableSubCat] = useState<boolean>(false);
  const [libraryAPIKey, setLibraryAPIKey] = useState<any>("");
  const [libraryId, setLibraryId] = useState<any>("");
  const [videoId, setVideoId] = useState<any>("");
  const [videoFile, setVideoFile] = useState<any>(null);
  const [fullFormData, setFullFormData] = useState<any>();
  const [previewImage, setPreviewImage] = useState<any>(null);
  const [previewUrl, setPreviewUrl] = useState<any>(null);
  const [previewTitle, setPreviewTitle] = useState<any>(null);
  const [videoUploaded, setVideoUploaded] = useState<any>(false);
  const [videoPreview, setVideoPreview] = useState<any>(null);

  const handleVideoFileChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];

    if (file) {
      const fileURL = URL.createObjectURL(file);
      setValue("upload_video", file, { shouldValidate: true });
      setUploadedFileName(file.name);
      setVideoPreview(fileURL);
    } else {
      setUploadedFileName(null); // Clear the file name if no file is selected
    }
  };

  const handleThumbnail = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      setValue("thumbnail", file, { shouldValidate: true });
      setThumbnailFileName(file.name);
      setThumnailImage(file);
    } else {
      setThumbnailFileName(null);
      setThumnailImage([]);
    }
  };

  const handleScheduleToggleChange = (value: boolean) => {
    setScheduleToggle(value);
    if (!value) {
      setValue("schedule_date", null);
      setValue("schedule_time", null);
    }
  };

  const handleCopyLink = () => {
    const linkToCopy = previewUrl;
    navigator.clipboard
      .writeText(linkToCopy)
      .then(() => {
        toast.info("Link copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const onSubmit = async (data: any) => {
    showLoader();
    setFullFormData(data);
    handleCreateVideoId(data.title);
  };

  const [uploadProgress, setUploadProgress] = useState<any>(0);

  const expirationTime = 1730246536;

  const generateSha256Hash = async (
    libraryId: any,
    apiKey: any,
    expirationTime: any,
    videoId: any
  ) => {
    const data = libraryId + apiKey + expirationTime + videoId;
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(data);
    const hashBuffer = await crypto.subtle.digest("SHA-256", encodedData);

    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray
      .map((byte) => byte.toString(16).padStart(2, "0"))
      .join("");

    return hashHex;
  };

  useEffect(() => {
    uploadVideoToBunnyCDN();
  }, [libraryId, videoId]);

  // const uploadVideoToBunnyCDN = async () => {
  //   const endpoint = "https://video.bunnycdn.com/tusupload";
  //   const authorizationSignature = await generateSha256Hash(
  //     libraryId,
  //     libraryAPIKey,
  //     expirationTime,
  //     videoId
  //   );
  //   const file = fullFormData?.upload_video;
  //   const upload = new Upload(file, {
  //     endpoint: endpoint,
  //     chunkSize: 1024 * 1024, // 1 MB chunk size
  //     retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
  //     headers: {
  //       AuthorizationSignature: authorizationSignature,
  //       AuthorizationExpire: 1730246536 as any,
  //       VideoId: videoId,
  //       LibraryId: libraryId,
  //     },
  //     metadata: {
  //       filetype: file?.type,
  //       title: file?.name,
  //     },
  //     onError: function (error) {
  //       console.error("Upload failed:", error);
  //     },
  //     onProgress: function (bytesUploaded, bytesTotal) {
  //       const percentage: any = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
  //       setUploadProgress(percentage);
  //       updateProgress(Number(percentage));
  //     },
  //     onSuccess: function () {
  //       handleAddVideos();
  //     },
  //   });
  //   upload.start();
  // };

  const uploadVideoToBunnyCDN = async () => {
    if (libraryId) {
      const endpoint = "https://video.bunnycdn.com/tusupload";
      const authorizationSignature = await generateSha256Hash(
        libraryId,
        libraryAPIKey,
        expirationTime,
        videoId
      );
      const file = fullFormData?.upload_video;

      const checkVideoProcessingStatus = async (videoId: any) => {
        const response = await fetch(
          `https://video.bunnycdn.com/library/${libraryId}/videos/${videoId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              AccessKey: libraryAPIKey,
            },
          }
        );
        const data = await response.json();
        return data;
      };

      const pollProcessingStatus = () => {
        const interval = setInterval(async () => {
          const videoDetails = await checkVideoProcessingStatus(videoId);
          console.log("videoDetails.status", videoDetails.status);
          // Check if the video is fully processed
          if (videoDetails.status === 4) {
            clearInterval(interval);
            handleAddVideos();
            console.log("Video is fully processed and ready for playback.");
            // Notify the user or update your UI here
          } else {
            console.log("Video is still processing...");
          }
        }, 5000); // Poll every 5 seconds
      };

      const upload = new Upload(file, {
        endpoint: endpoint,
        chunkSize: 1024 * 1024, // 1 MB chunk size
        retryDelays: [0, 3000, 5000, 10000, 20000, 60000, 60000],
        headers: {
          AuthorizationSignature: authorizationSignature,
          AuthorizationExpire: 1730246536 as any,
          VideoId: videoId,
          LibraryId: libraryId,
        },
        metadata: {
          filetype: file?.type,
          title: file?.name,
        },
        onError: function (error) {
          hideLoader();
          console.error("Upload failed:", error);
        },
        onProgress: function (bytesUploaded, bytesTotal) {
          const percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
          showLoader(`Please wait, uploading video: ${percentage}%`);
        },
        onSuccess: function () {
          showLoader("Video is processing...");
          pollProcessingStatus();
        },
      });
      showLoader("Uploading video...");
      upload.start();
    }
  };

  const getCategoryDetails = async () => {
    try {
      let url = end_points.master_category.url;
      const response = await getData(url);
      if (response.status === 200) {
        const categoryList = response.data.data.map((category: any) => ({
          label: category.name,
          value: category._id,
        }));
        setCategoryListData(categoryList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getSubCategoryDetails = async (data: any) => {
    try {
      let url = end_points.master_subCategory.url;
      const payload = {
        category_id: data,
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const subCategoryList = response?.data?.data[0]?.sub_categories.map(
          (subcategory: any) => ({
            label: subcategory.sub_category_name,
            value: subcategory._id,
          })
        );
        setEnableSubCat(true);
        setSubCategoryListData(subCategoryList);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const getGroupDetails = async () => {
    try {
      let url = end_points.master_group.url;
      const response = await getData(url);
      if (response.status === 200) {
        const groupList = response?.data?.data.map((group: any) => ({
          label: group.name,
          value: group._id,
        }));
        setGroupListData(groupList);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCategoryDetails();
    getGroupDetails();
  }, []);

  const handleCreateVideoId = async (data: any) => {
    try {
      let url = end_points.uploadVideoBunny.url;
      const payload = {
        title: data,
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const data = response.data.data;
        setLibraryAPIKey(data.libraryAPIKey);
        setLibraryId(data.libraryId);
        setVideoId(data.videoId);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const handleAddVideos = async () => {
    try {
      let url = end_points.createVideo.url;

      const formData: any = new FormData();

      formData.append("title", fullFormData.title);
      formData.append("description", fullFormData.description);
      formData.append("category", fullFormData.category);
      formData.append("subcategory", fullFormData.subcategory);
      formData.append("group", fullFormData.group);
      fullFormData.tags.forEach((item: any, index: number) => {
        formData.append(`tags[${index}]`, item);
      });
      formData.append("video_id", videoId);
      fullFormData.checkboxGroup.forEach((item: any, index: number) => {
        formData.append(`audience_settings[${index}]`, item);
      });
      formData.append("age_restriction", fullFormData.ageRestriction);
      formData.append("set_as_exclusive", fullFormData.set_as_exclusive);
      formData.append("schedule_status", fullFormData.schedule_toggle);

      if (fullFormData.schedule_toggle) {
        formData.append(
          "schedule_post_date",
          dateFormat_YYYY_MM_DD(fullFormData.schedule_date)
        );
        formData.append(
          "schedule_post_time",
          formatTimeTo12Hour(fullFormData.schedule_time)
        );
      } else {
        formData.append("schedule_post_date", null);
        formData.append("schedule_post_time", null);
      }
      if (fullFormData.thumbnail) {
        formData.append("thumbnail", fullFormData.thumbnail);
      } else {
        formData.append("thumbnail", null);
      }
      const response = await postData(url, formData);
      if (response.status === 201) {
        const data = response.data.data;
        setPreviewImage(data.thumbnailUrl);
        setPreviewUrl(data.previewUrl);
        setPreviewTitle(data.title);
        setVideoUploaded(true);
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handleRemoveThumbnail = () => {
    setValue("thnumnail", null);
    setThumbnailFileName(null);
  };

  useEffect(() => {
    if (scheduleDate) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const isToday = scheduleDate.setHours(0, 0, 0, 0) === today.getTime();

      if (isToday) {
        const currentTime = new Date();
        setMinTime(currentTime);
      } else {
        setMinTime(null);
      }
    }
  }, [scheduleDate]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [videoUploaded]);
  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <form id="addVideoForm" onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-blk">
                  <div className="d-flex flex-wrap align-items-start">
                    <ul className="flex-style">
                      <li>
                        <Link to={routes.manageVideos}>Videos</Link>
                      </li>
                      <li>Add Videos</li>
                    </ul>
                  </div>
                  <p>
                    Lets you easily upload and organize your video content in a
                    streamlined interface.
                  </p>
                </div>
                <div className="row custom-row">
                  <div className="col-md-7 custom-col">
                    <div className="custom-card mb-3 mb-md-0 add-video-div">
                      <h3 className="title-h3">Video Details</h3>
                      <form>
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Video Title</label>
                              <Controller
                                name="title"
                                control={control}
                                render={({ field }) => (
                                  <input
                                    {...field}
                                    type="text"
                                    className="form-control"
                                    placeholder="Enter Title"
                                    onKeyDown={(e) => {
                                      if (
                                        !/[a-zA-Z\s]/.test(e.key) &&
                                        e.key !== "Backspace"
                                      ) {
                                        e.preventDefault();
                                      }
                                    }}
                                    maxLength={30}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      trigger("title");
                                    }}
                                    onBlur={() => trigger("title")}
                                    disabled={videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.title} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">
                                Video Description
                              </label>
                              <Controller
                                name="description"
                                control={control}
                                render={({ field }) => (
                                  <textarea
                                    maxLength={225}
                                    className="form-control"
                                    placeholder="Enter Video Description"
                                    {...field}
                                    onBlur={() => trigger("description")}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      trigger("description");
                                    }}
                                    disabled={videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.description} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Tags</label>
                              <Controller
                                name="tags"
                                control={control}
                                render={({ field }) => (
                                  <Chips
                                    className="w-100"
                                    value={chipValue}
                                    placeholder="Type and press enter to add tag"
                                    onChange={(e: any) => {
                                      setChipValue(e.value);
                                      setValue("tags", e.value);
                                      trigger("tags");
                                    }}
                                    disabled={videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.tags} />
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Category</label>
                              <Controller
                                name="category"
                                control={control}
                                render={({ field }) => (
                                  <Dropdown
                                    {...field}
                                    value={field.value}
                                    onBlur={() => trigger("category")}
                                    onChange={(e) => {
                                      console.log("e", e);
                                      field.onChange(e);
                                      getSubCategoryDetails(e.value);
                                      trigger("category");
                                    }}
                                    options={categoryListData}
                                    optionLabel="label"
                                    placeholder="Select category"
                                    className="select w-100"
                                    disabled={videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.category} />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Sub-Category</label>
                              <Controller
                                name="subcategory"
                                control={control}
                                render={({ field }) => (
                                  <Dropdown
                                    {...field}
                                    options={subCategoryListData}
                                    optionLabel="label"
                                    value={field.value}
                                    onChange={(e) => {
                                      field.onChange(e);
                                      trigger("subcategory");
                                    }}
                                    placeholder="Select subcategory"
                                    className="select w-100"
                                    disabled={!enableSubCat || videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.subcategory} />
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="mb-3">
                              <label className="form-label">Group</label>
                              <Controller
                                name="group"
                                control={control}
                                render={({ field }) => (
                                  <Dropdown
                                    options={groupListData}
                                    optionLabel="label"
                                    value={field.value}
                                    onChange={(e) => setValue("group", e.value)}
                                    placeholder="Select group"
                                    className="select w-100"
                                    disabled={videoUploaded}
                                  />
                                )}
                              />
                              <ErrorMessage value={errors.group} />
                            </div>
                          </div>

                          {/* <div className="col-12">
                            <div className="upload-wrapper my-1">
                              <label className="form-label">Video Upload</label>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="mb-3">
                                    <div className="upload-div p-0">
                                      <div className="upload-file p-0 border-0 p-3">
                                        <Controller
                                          name="upload_video"
                                          control={control}
                                          render={({ field }) => (
                                            <>
                                              <input
                                                type="file"
                                                accept="video/mp4, video/x-msvideo, video/x-ms-wmv, video/webm"
                                                onChange={handleVideoFileChange}
                                                disabled={videoUploaded}
                                              />
                                              <div className="upload-content">
                                                <h6>
                                                  <img
                                                    src={download}
                                                    className="me-2"
                                                    alt=""
                                                  />{" "}
                                                  Upload a video{" "}
                                                  <span className="ms-1">
                                                    choose file
                                                  </span>
                                                </h6>
                                                {uploadedFileName && (
                                                  <p className="uploaded-file-name mt-2">
                                                    Uploaded file:{" "}
                                                    {uploadedFileName}
                                                  </p>
                                                )}
                                              </div>
                                            </>
                                          )}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <ErrorMessage value={errors.upload_video} />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="upload-wrapper">
                            <h3 className="title-h3 mb-3">Thumbnail</h3>
                            <p>
                              Your thumbnail is the first impression viewers
                              will have, so make it eye-catching and relevant.
                            </p>
                            <div className="row">
                              <div className="col-12">
                                <div className="upload-files">
                                  <div className="row">
                                    <div className="col-lg-6">
                                      <div className="mb-3">
                                        <div className="upload-div upload2-div border-0 p-0">
                                          <div className="upload-file">
                                            <Controller
                                              name="thumbnail"
                                              control={control}
                                              render={({ field }) => (
                                                <>
                                                  <input
                                                    type="file"
                                                    accept="image/jpeg, image/png, image/jpg, image/gif"
                                                    onChange={handleThumbnail}
                                                    disabled={videoUploaded}
                                                  />
                                                </>
                                              )}
                                            />
                                            <div className="upload-content flex-column">
                                              <img src={uploadFile} alt="" />
                                              <p>Upload File</p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {!thumbnailFileName ? (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <div className="upload-div upload2-div with-img-blk border-0 p-0">
                                            <div className="upload-file">
                                              <div className="upload-content flex-column">
                                                <img
                                                  src={autoGenerate}
                                                  alt=""
                                                />
                                                <p>Auto Generated</p>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <div className="col-lg-6">
                                        <div className="mb-3">
                                          <div className="upload-div upload2-div with-img-blk border-0 p-0">
                                            <div className="upload-file">
                                              <div className="upload-content flex-column upload-content-index">
                                                <Link
                                                  to="#"
                                                  className="uplaod-close"
                                                  onClick={() =>
                                                    handleRemoveThumbnail()
                                                  }
                                                >
                                                  <i className="ti ti-x" />
                                                </Link>
                                                {thumnailImage.type?.startsWith(
                                                  "image/"
                                                ) && (
                                                  <img
                                                    src={URL.createObjectURL(
                                                      thumnailImage
                                                    )}
                                                    alt={thumnailImage.name}
                                                    className="w-100 h-100"
                                                  />
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="settings-wrapper">
                            <div className="audience-blk block-border">
                              <h4>Audience Settings:</h4>
                              <h5>
                                This video is set to not made for Under 18{" "}
                                <span className="ms-0 ms-lg-2 d-inline-block">
                                  Set by you
                                </span>
                              </h5>
                              <p>
                                Regardless of your location, you’re legally
                                required to comply with the Children’s Online
                                Privacy Protection Act (COPPA) and / or other
                                laws. You’re required to tell us whether your
                                videos are made for kids.{" "}
                              </p>
                              <div className="info-content-wrapper">
                                <h5>What’s content made for kids?</h5>
                                <div className="info-box-wrapper d-flex align-items-center">
                                  <div className="icon-blk">
                                    <img src={infoIcon} alt="" />
                                  </div>
                                  <p>
                                    Features like personalized ads and
                                    notifications won’t be available on videos
                                    made for kids. Videos that are set as made
                                    for kids by you are more likely to be
                                    recommended alongside other kids videos.{" "}
                                    <Link to="#">Learn more</Link>
                                  </p>
                                </div>
                                <ul className="list-unstyled">
                                  <li>
                                    <Controller
                                      name="checkboxGroup"
                                      control={control}
                                      render={({ field }) => (
                                        <label className="custom_check style2">
                                          <input
                                            type="checkbox"
                                            value="Under 18"
                                            onChange={(e) => {
                                              const checked = e.target.checked;
                                              const value = e.target.value;
                                              const currentValues =
                                                field.value || [];
                                              const newValues = checked
                                                ? [...currentValues, value]
                                                : currentValues.filter(
                                                    (v: string) => v !== value
                                                  );
                                              field.onChange(newValues);
                                            }}
                                            disabled={videoUploaded}
                                          />
                                          <span className="checkmark" />
                                          Under 18
                                        </label>
                                      )}
                                    />
                                  </li>
                                  <li>
                                    <Controller
                                      name="checkboxGroup"
                                      control={control}
                                      render={({ field }) => (
                                        <label className="custom_check style2">
                                          <input
                                            type="checkbox"
                                            value="18+"
                                            onChange={(e) => {
                                              const checked = e.target.checked;
                                              const value = e.target.value;
                                              const currentValues =
                                                field.value || [];
                                              const newValues = checked
                                                ? [...currentValues, value]
                                                : currentValues.filter(
                                                    (v: string) => v !== value
                                                  );
                                              field.onChange(newValues);
                                            }}
                                            disabled={videoUploaded}
                                          />
                                          <span className="checkmark" />
                                          18+
                                        </label>
                                      )}
                                    />
                                  </li>
                                </ul>
                              </div>
                              <ErrorMessage value={errors.checkboxGroup} />
                            </div>
                            <div className="accordion audience-blk block-border">
                              <h4
                                className="accordion-button bg-transparent p-0 shadow-none"
                                data-bs-toggle="collapse"
                                data-bs-target="#collapseOne"
                                aria-expanded="true"
                                aria-controls="collapseOne"
                              >
                                Age restriction (advanced)
                              </h4>
                              <div
                                id="collapseOne"
                                className="accordion-collapse collapse show"
                                data-bs-parent="#accordionExample"
                              >
                                <h5>
                                  Do you want to restrict your video to an adult
                                  audience?
                                </h5>
                                <p>
                                  Age-restricted video are not shown in certain
                                  areas of Turning. These videos may have
                                  limited or no ads monetization.{" "}
                                  <Link to="#">Learn More</Link>{" "}
                                </p>
                                <ul className="list-unstyled">
                                  <li>
                                    <Controller
                                      name="ageRestriction"
                                      control={control}
                                      render={({ field }) => (
                                        <label className="custom_check style2">
                                          <input
                                            type="radio"
                                            value="Yes, restrict my video to viewers over 18"
                                            checked={
                                              field.value ===
                                              "Yes, restrict my video to viewers over 18"
                                            }
                                            onChange={field.onChange}
                                            disabled={videoUploaded}
                                          />
                                          <span className="checkmark" />
                                          Yes, restrict my video to viewers over
                                          18
                                        </label>
                                      )}
                                    />
                                  </li>
                                  <li>
                                    <Controller
                                      name="ageRestriction"
                                      control={control}
                                      render={({ field }) => (
                                        <label className="custom_check style2">
                                          <input
                                            type="radio"
                                            value="No, don't restrict my video to viewers over 18 only"
                                            checked={
                                              field.value ===
                                              "No, don't restrict my video to viewers over 18 only"
                                            }
                                            onChange={field.onChange}
                                            disabled={videoUploaded}
                                          />
                                          <span className="checkmark" />
                                          No, don’t restrict my video to viewers
                                          over 18 only
                                        </label>
                                      )}
                                    />
                                  </li>
                                </ul>
                              </div>
                              <ErrorMessage value={errors.ageRestriction} />
                            </div>
                            <div className="audience-blk block-border d-flex">
                              <h4 className="mb-0">Set as Exclusive</h4>
                              <div className="active-switch ms-auto">
                                <Controller
                                  name="set_as_exclusive"
                                  control={control}
                                  defaultValue={true}
                                  render={({ field }) => (
                                    <label className="switch">
                                      <input
                                        type="checkbox"
                                        checked={
                                          field.value === true ? true : false
                                        }
                                        onChange={(e) =>
                                          field.onChange(e.target.checked)
                                        }
                                        disabled={videoUploaded}
                                      />
                                      <span className="sliders round" />
                                    </label>
                                  )}
                                />
                                <ErrorMessage value={errors.set_as_exclusive} />
                              </div>
                            </div>
                            <div className="audience-blk block-border">
                              <div className="d-flex">
                                <h5>Schedule Post</h5>
                                <div className="active-switch ms-auto">
                                  <Controller
                                    name="schedule_toggle"
                                    control={control}
                                    defaultValue={true}
                                    render={({ field }) => (
                                      <label className="switch">
                                        <input
                                          type="checkbox"
                                          checked={field.value}
                                          onChange={(e) => {
                                            field.onChange(e.target.checked);
                                            handleScheduleToggleChange(
                                              e.target.checked
                                            );
                                            trigger("schedule_toggle");
                                            trigger("schedule_date");
                                            trigger("schedule_time");
                                          }}
                                          disabled={videoUploaded}
                                        />
                                        <span className="sliders round" />
                                      </label>
                                    )}
                                  />
                                </div>
                              </div>
                              <div className="row align-items-center">
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <div className="addons">
                                      <Controller
                                        name="schedule_date"
                                        control={control}
                                        render={({ field }) => (
                                          <Calendar
                                            className="datetimepicker"
                                            value={field.value}
                                            placeholder="Select date"
                                            onChange={(e: any) => {
                                              field.onChange(e.value);
                                              setScheduleDate(e.value);
                                              trigger("schedule_date");
                                            }}
                                            disabled={
                                              !scheduleToggle || videoUploaded
                                            }
                                            minDate={new Date()}
                                          />
                                        )}
                                      />
                                      <span className="addon-span">
                                        <i className="ti ti-chevron-down" />
                                      </span>
                                      <ErrorMessage
                                        value={errors.schedule_date}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="mb-3">
                                    <div className="addons">
                                      <Controller
                                        name="schedule_time"
                                        control={control}
                                        render={({ field }) => (
                                          <Calendar
                                            placeholder="Select time"
                                            value={field.value}
                                            onChange={(e: any) => {
                                              field.onChange(e.value);
                                              trigger("schedule_time");
                                            }}
                                            timeOnly
                                            disabled={
                                              !scheduleToggle || videoUploaded
                                            }
                                            minDate={minTime}
                                          />
                                        )}
                                      />
                                      <span className="addon-span">
                                        <i className="ti ti-chevron-down" />
                                      </span>
                                      <ErrorMessage
                                        value={errors.schedule_time}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {!videoUploaded && (
                                <div className="row">
                                  <div className="col-12">
                                    <div className="mt-3">
                                      <button
                                        type="submit"
                                        form="addVideoForm"
                                        className="btn btn-submit add-video"
                                      >
                                        Save
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* <div className="col-md-5 custom-col">
                    <div className="custom-card mb-20">
                      <h3 className="title-h3">Preview</h3>
                      <div className="video-preview-blk">
                        <img
                          src={previewImage || videoPreview}
                          className="img img-fluid"
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="custom-card">
                      <div className="video-info-wrapper d-flex align-items-center">
                        <div className="video-details-info">
                          <p>Video Link:</p>
                          <Link to="#">{previewUrl || 'NA'}</Link>
                        </div>
                        <img
                          src={clone}
                          className="ms-auto"
                          alt=""
                          onClick={handleCopyLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="video-info-wrapper">
                        <div className="video-details-info">
                          <p>File Name:</p>
                          <Link to="#">{previewTitle || 'NA'}</Link>
                        </div>
                      </div>
                    </div>
                  </div> */}
                  <div className="col-md-5 custom-col">
                    <div className="custom-card mb-20">
                      <h3 className="title-h3">Preview</h3>
                      {uploadedFileName === null ? (
                        <>
                          <div className="video-imageupload">
                            <Controller
                              name="upload_video"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <input
                                    type="file"
                                    accept="video/mp4"
                                    onChange={handleVideoFileChange}
                                    disabled={videoUploaded}
                                  />
                                </>
                              )}
                            />
                            <div className="video-image-contents">
                              <img src={youtube} alt="" />
                              <h6>
                                Video will appear here{" "}
                                <a>Browse</a>
                              </h6>
                              <h5>Accepted file formats: MP4 only.</h5>
                            </div>
                          </div>
                          <ErrorMessage value={errors.upload_video} />
                        </>
                      ) : (
                        <div className="video-container">
                          <a className="uplaod-close" onClick={()=>{setUploadedFileName(null)}}>
                            <i className="ti ti-x" />
                          </a>
                          <video width="100%" controls>
                            <source src={videoPreview} />
                            Your browser does not support the video tag.
                          </video>
                        </div>
                      )}
                    </div>
                    <div className="custom-card">
                      <div className="video-info-wrapper d-flex align-items-center">
                        <div className="video-details-info">
                          <p>Video Link:</p>
                          <Link to="#">{previewUrl || "NA"}</Link>
                        </div>
                        <img
                          src={clone}
                          className="ms-auto"
                          alt=""
                          onClick={handleCopyLink}
                          style={{ cursor: "pointer" }}
                        />
                      </div>
                      <div className="video-info-wrapper">
                        <div className="video-details-info">
                          <p>File Name:</p>
                          <Link to="#">{previewTitle || "NA"}</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default AddVideo;
