import React, { useState } from "react";
import Footer from "../../common/provider-footer";
import { Link } from "react-router-dom";
import { authIcon } from "../../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";

const ProviderAccountSettings = () => {
  const [selectedSomethingElse, setSelectedSomethingElse] = useState(null),
    [visibility, setVisibility] = useState({
      currentPassword: false,
      newPassword: false,
      confirmNewPassword: false,
    });

  const togglePasswordVisibility = (value: any) => {
    setVisibility((prevState: any) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };
  const somethingElseOptions = [
    { label: "Something Else", value: "Something Else" },
  ];
  return (
    <div
      className="tab-pane fade show active"
      id="account-settings"
      role="tabpanel"
      aria-labelledby="account-settings-tab"
    >
      <div className="tw-facor-activate-card d-flex align-items-center">
        <div className="tw-factor-info d-flex align-items-center">
          <div className="img-blk">
            <img src={authIcon} className="img img-fluid" alt="" />
          </div>
          <div className="desc-blk">
            <h4>Two-Step Authentication</h4>
            <p>
              Add an extra layer of security to your account by enabling
              two-step authentication.
            </p>
          </div>
        </div>
        <Link to="#" className="btn ms-auto btn-activate">
          Activate
        </Link>
      </div>
      <div className="custom-card custom-card-info">
        <div className="card-head d-flex align-items-center">
          <h4>Personal Information</h4>
          <div className="cta-blk ms-auto">
            <Link to="#" className="btn btn-sm-cancel">
              Cancel
            </Link>
            <Link to="#" className="btn btn-sm-edit">
              Edit
            </Link>
          </div>
        </div>
        <form className="inline-form">
          <div className="inline-input">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="col-form-label">Username</label>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  defaultValue="Susan Reynolds"
                />
              </div>
            </div>
          </div>
          <div className="inline-input">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="col-form-label">Email</label>
              </div>
              <div className="col-md-6">
                <input
                  type="text"
                  className="form-control"
                  defaultValue="susab@turningwell.com"
                />
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="custom-card custom-card-info">
        <div className="card-head d-flex align-items-center">
          <h4>Password</h4>
          <div className="cta-blk ms-auto">
            <Link to="#" className="btn btn-sm-cancel">
              Cancel
            </Link>
            <Link to="#" className="btn btn-sm-edit">
              Save Changes
            </Link>
          </div>
        </div>
        <form className="inline-form">
          {/* Current Password */}
          <div className="inline-input">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="col-form-label">Current Password</label>
              </div>
              <div className="col-md-6">
                <div className="pass-group position-relative">
                  <input
                    type={visibility.currentPassword ? "text" : "password"}
                    className="form-control pass-input"
                    defaultValue="Turningwell$69"
                  />
                  <i
                    className={`toggle-password ti ${visibility.currentPassword ? "ti-eye" : "ti-eye-closed"}`}
                    onClick={() => togglePasswordVisibility("currentPassword")}
                  />
                </div>
              </div>
            </div>
          </div>

          {/* New Password */}
          <div className="inline-input">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="col-form-label">New Password</label>
              </div>
              <div className="col-md-6 pass-helper">
                <div className="pass-group position-relative">
                  <input
                    type={visibility.newPassword ? "text" : "password"}
                    className="form-control pass-input"
                    defaultValue="Turningwell$69"
                  />
                  <i
                    className={`toggle-password ti ${visibility.newPassword ? "ti-eye" : "ti-eye-closed"}`}
                    onClick={() => togglePasswordVisibility("newPassword")}
                  />
                </div>
                <span>Your new password must be more than 8 characters.</span>
              </div>
            </div>
          </div>

          {/* Confirm New Password */}
          <div className="inline-input">
            <div className="row align-items-center">
              <div className="col-md-6">
                <label className="col-form-label">Confirm New Password</label>
              </div>
              <div className="col-md-6">
                <div className="pass-group position-relative">
                  <input
                    type={visibility.confirmNewPassword ? "text" : "password"}
                    className="form-control pass-input"
                    defaultValue="Turningwell$69"
                  />
                  <i
                    className={`toggle-password ti ${visibility.confirmNewPassword ? "ti-eye" : "ti-eye-closed"}`}
                    onClick={() =>
                      togglePasswordVisibility("confirmNewPassword")
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className="deactivate-blk deactivate-blk-info">
        <h4>Deactivate Your Account</h4>
        <p>
          Instead of deleting your account, you have the option to deactivate
          it. This means your account will be hidden until you choose to
          reactivate it by logging back in.
        </p>
      </div>
      <div className="inline-input reason-inline-input">
        <div className="row align-items-center">
          <div className="col-md-6">
            <label className="col-form-label">
              What is the reason for deactivating your account?
            </label>
          </div>
          <div className="col-md-6">
            <Dropdown
              value={selectedSomethingElse}
              options={somethingElseOptions}
              onChange={(e) => setSelectedSomethingElse(e.value)}
              placeholder="Select"
              className="select w-100"
            />
          </div>
        </div>
      </div>
      <Link to="#" className="btn btn-deactivate">
        Deactivate Account
      </Link>
    </div>
  );
};
export default ProviderAccountSettings;
