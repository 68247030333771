import { Outlet, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../utils/routes/routes";
import ProviderFooter from "./common/provider-footer";
import ProviderHeader from "./common/provider-header";
import ProviderSidebar from "./common/provider-sidebar";
import ClientHeader from "./common/client-header";
import ClientFooter from "./common/client-footer";
import { useEffect } from "react";
import { setEditData } from "../core/redux/commonSlice";
import Progress from "../components/progress/progress";

const FeatureModule: React.FC = () => {
  const { miniSidebar, expandMenu, mobileSidebar } = useSelector(
    (state: any) => state.sidebar
  );
  const location = useLocation();
  const isResetPasswordPage = location.pathname?.includes("/reset-password");
  const isVerifyEmailPage = location.pathname?.includes("/verify-email");

  const withoutLayout =
    location.pathname === routes.providerLogin ||
    location.pathname === routes.registerClient ||
    location.pathname === routes.registerProvider ||
    location.pathname === routes.thankYou ||
    location.pathname === routes.verifyAccount ||
    location.pathname === routes.billingProvider ||
    location.pathname === routes.credentialsProvider ||
    location.pathname === routes.forgotPassword ||
    location.pathname === routes.cardIndex ||
    location.pathname === routes.locationProvider ||
    isResetPasswordPage ||
    isVerifyEmailPage;

  const clientPages =
    location.pathname === routes.clientIndex ||
    location.pathname === routes.category ||
    location.pathname === routes.group ||
    location.pathname === routes.psychopharmacologyVideos ||
    location.pathname === routes.subCategory;

  const frontendPages =
    location.pathname === routes.search ||
    location.pathname === routes.filteredCountryFeed ||
    location.pathname === routes.frontendIndex ||
    location.pathname === routes.singleBlog ||
    location.pathname === routes.searchTag ||
    location.pathname === routes.featuredWorldWide;

  const viewProfile = location.pathname === routes.viewProfile;

  const providerAuth = [
    routes.providerLogin,
    routes.registerClient,
    routes.registerProvider,
    routes.thankYou,
    routes.verifyAccount,
    routes.billingProvider,
    routes.credentialsProvider,
    routes.forgotPassword,
    routes.locationProvider,
    routes.resetPassword,
    routes.cardIndex,
  ];
  const locationPath = window.location.pathname.split("/");
  const path = window.location.pathname;
  const dispatch = useDispatch();
  // const isAuth =
  // locationPath[1] == "provider" && !providerAuth.includes(location.pathname);
  // ? require("../assets/provider/assets/css/admin.css")
  // : require("../assets/css/style.css");
  // providerAuth.includes(location.pathname) ||
  //   (isResetPasswordPage && require("../assets/css/style.css"));

  // locationPath[1] == "provider" && !providerAuth.includes(location.pathname)
  //   ? require("../assets/provider/assets/css/admin.css")
  //   : require("../assets/css/style.css");

  if (providerAuth.includes(location.pathname) || isResetPasswordPage) {
    require("../assets/provider/assets/css/provider-auth.css");
  } else if (
    locationPath[1] == "provider" &&
    !providerAuth.includes(location.pathname)
  ) {
    require("../assets/provider/assets/css/provider.css");
  } else if (
    locationPath[1] == "client" &&
    !providerAuth.includes(location.pathname)
  ) {
    require("../assets/client/css/client.css");
  } else if (frontendPages || viewProfile) {
    require("../assets/frontend/assets/css/style.css");
  }

  useEffect(() => {
    if (path != "/provider/add-announcements") {
      dispatch(setEditData(undefined));
    }
  }, [path]);

  return (
    <>
      <div
        className={`
        ${miniSidebar ? "mini-sidebar" : ""}
        ${expandMenu && miniSidebar ? "expand-menu" : ""}
        ${mobileSidebar ? "menu-opened slide-nav" : ""}
        `}
      >
        <div className={`main-wrapper ${withoutLayout ? "flex-login" : ""}`}>
          {!withoutLayout && !clientPages && !frontendPages && !viewProfile ? (
            <ProviderHeader />
          ) : (
            ""
          )}
          {clientPages || frontendPages ? <ClientHeader /> : ""}
          {!withoutLayout && !clientPages && !frontendPages && !viewProfile ? (
            <ProviderSidebar />
          ) : (
            ""
          )}
          <Outlet />
          {!withoutLayout && !clientPages && !frontendPages && !viewProfile  ? (
            <ProviderFooter />
          ) : (
            ""
          )}
          {clientPages || frontendPages || viewProfile ? <ClientFooter /> : ""}
          {clientPages || frontendPages || viewProfile ? <Progress /> : ""}
        </div>
      </div>
    </>
  );
};

export default FeatureModule;
