import React, { useState } from "react";

import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { trendUp } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";

const Earning = () => {
  const [earningView, setEarningView] = useState<any>({
    series: [
      {
        name: "Income",
        data: [10, 40, 15, 51, 59, 40, 69, 91, 48],
      },
      {
        name: "Expenses",
        data: [20, 60, 45, 31, 59, 40, 29, 51, 28],
      },
    ],
    colors: ["#FCC69D", "#89D0CD"],
    chart: {
      height: 250,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  });
  const [selectedYear, setSelectedYear] = useState(null);
  const yearOptions = [
    { label: "This Year", value: "This Year" },
    { label: "2024", value: "2024" },
    { label: "2023", value: "2023" },
  ];

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Directory </Link>
              </li>
              <li>Earning</li>
            </ul>
          </div>
          <div className="row flex-body d-flex row-gap-30 chart-set">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Earnings Statistics</h3>
                    <p>
                      Track earnings, view balance, and withdraw funds once
                      reaching $100.{" "}
                    </p>
                  </div>
                  <Link
                    to="#"
                    className="btn btn-sm ms-auto btn-outline-primary"
                  >
                    <i className="ti ti-upload me-2" />
                    Export
                  </Link>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="card-insights">
                        <p className="d-flex align-items-center justify-content-between">
                          Monthly Recurring Revenue
                          <Link to="#" className="ms-auto">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$1,671</h3>
                          <p>
                            +11.02%
                            <img
                              src={trendUp}
                              className="ms-1"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights danger-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Earning
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$3,671</h3>
                          <p>
                            +6.02%
                            <img
                              src={trendUp}
                              className="ms-1"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="card-insights warning-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Earning
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$90.00</h3>
                          <p>
                            +11.02%
                            <img
                              src={trendUp}
                              className="ms-1"
                              alt=""
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-title">
                <h6>Revenue Analytics</h6>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Earning{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <div className="chat-select">
                  <Dropdown
                      value={selectedYear}
                      options={yearOptions}
                      onChange={(e) => setSelectedYear(e.value)}
                      placeholder="Select"
                      className="w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="earning-view">
                        <ReactApexChart
                          height={300}
                          options={earningView}
                          series={earningView.series}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Transaction history</h3>
                  </div>
                  <Link
                    to="#"
                    className="btn btn-sm ms-auto btn-outline-primary rounded"
                  >
                    Download all
                  </Link>
                </div>
                <div className="table-responsive">
                  <table className="table table-transaction">
                    <thead>
                      <tr>
                        <th>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Description </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-1-2024</td>
                        <td>$50</td>
                        <td>Therapy Session</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-13-2024</td>
                        <td>$50</td>
                        <td>Counseling Session</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-16-2024</td>
                        <td>$120</td>
                        <td>Group Therapy </td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-17-2024</td>
                        <td>$50</td>
                        <td>Therapy Session</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-1-2024</td>
                        <td>$50</td>
                        <td>Therapy Session</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-13-2024</td>
                        <td>$50</td>
                        <td>Counseling Session</td>
                      </tr>
                      <tr>
                        <td>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                          </label>
                        </td>
                        <td className="text-dark">5-16-2024</td>
                        <td>$120</td>
                        <td>Group Therapy </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <Link to="#" className="btn btn-withdraw tooltips">
                Withdraw Amount
                <span className="tooltiptext">
                  Your profile is owned by [Facility Name]. <br />
                  Please contact your manager to withdraw your balance.
                </span>
              </Link>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Earning;
