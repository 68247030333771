import React, { useEffect, useState } from "react";
import { forgotPassword, logo } from "../../utils/imagepath";
import { postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmPasswordSchema, passwordSchema } from "./components/yupSchema";
import { yup } from "../../utils/shared.module";
import ErrorMessage from "../../components/errorMessage";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { toast } from "react-toastify";
import { routes } from "../../utils/routes/routes";
import { useNavigate } from "react-router-dom";

interface userDetails {
  user_id: string;
  user_email: string;
}
export const resetPasswordSchema = yup.object({
  new_password: passwordSchema,
  confirm_password: confirmPasswordSchema,
});
const intialValues = {
  userId: "",
  new_password: "",
  confirm_password: "",
};

const ResetPassword = () => {
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    reset,
    register,
  } = useForm({
    defaultValues: intialValues,
    resolver: yupResolver(resetPasswordSchema),
  });
  const [visibility, setVisibility] = useState({
      new_password: false,
      confirm_password: false,
    }),
    [userDetails, setUserDetails] = useState<userDetails>({
      user_email: "",
      user_id: "",
    });

  // Single function to toggle password visibility for both fields
  const togglePasswordVisibility = (value: any) => {
    setVisibility((prevState: any) => ({
      ...prevState,
      [value]: !prevState[value],
    }));
  };
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        userId: userDetails?.user_id,
        new_password: data?.new_password,
        confirm_password: data?.confirm_password,
      };
      const response = await postData(
        end_points.password_reset_mail.url,
        payload
      );
      if (response?.status === 201) {
        navigate(routes.providerLogin);
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  const GetUserDetails = async (encryptKey: any) => {
    try {
      const payload = {
        encryptURL: encryptKey,
      };
      const response = await postData(
        end_points.decrypt_user_details.url,
        payload
      );
      if (response.status < 400) {
        setUserDetails(response?.data?.data);
      }
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    const pathSegments = url.pathname.split("/");
    const userEncryptedDetail =
      pathSegments[pathSegments.indexOf("reset-password") + 1];
    if (
      userEncryptedDetail &&
      userEncryptedDetail !== undefined &&
      userEncryptedDetail !== null
    ) {
      GetUserDetails(userEncryptedDetail);
    }
  }, []);
  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap justify-content-start">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body ptres-md-0 pt-5">
                <div className="login-title">
                  <h4>Reset Password?</h4>
                  <p>
                    Don't worry, happens to the best of us.Type your email to
                    reset your password.
                  </p>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-set">
                    <label>New Password</label>
                    <div className="pass-group position-relative">
                      <Controller
                        name="new_password"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={visibility.new_password ? "text" : "password"}
                            className="form-control pass-input"
                            placeholder="Enter your new password"
                            {...register("new_password")}
                          />
                        )}
                      />
                      <i
                        className={`toggle-password ti ${visibility.new_password ? "ti-eye" : "ti-eye-closed"}`}
                        onClick={() => togglePasswordVisibility("new_password")}
                      />
                    </div>
                    <ErrorMessage value={errors.new_password} />
                  </div>
                  <div className="form-set">
                    <label>Confirm Password</label>
                    <div className="pass-group position-relative">
                      <Controller
                        name="confirm_password"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={
                              visibility.confirm_password ? "text" : "password"
                            }
                            className="form-control pass-input"
                            placeholder="Confirm password"
                            {...register("confirm_password")}
                          />
                        )}
                      />
                      <i
                        className={`toggle-password ti ${visibility.confirm_password ? "ti-eye" : "ti-eye-closed"}`}
                        onClick={() =>
                          togglePasswordVisibility("confirm_password")
                        }
                      />
                    </div>
                    <ErrorMessage value={errors.confirm_password} />
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                    disabled={
                      userDetails?.user_id === "" ||
                      userDetails?.user_id === undefined ||
                      userDetails?.user_id === null
                    }
                  >
                    Submit
                  </button>
                </form>
                {/* /Login Form */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={forgotPassword} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default ResetPassword;
