import React, { useContext, useEffect, useRef, useState } from 'react';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { ApiServiceContext, yup } from '../../utils/shared.module';
import ErrorMessage from '../errorMessage';
import { SpinnerContext } from '../spinner/spinner';
import { end_points } from '../../services/end_point/end_points';
import { chiefComplaintLength } from '../../utils/patterns/regex.pattern';
import {
  convertAvailableDoctorToOptions,
  dateFormat_DD_MM_YYYY,
  dateFormat_YYYY_MM_DD,
} from '../../utils/constant';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { scheduleType } from '../../assets/global';
import { setEditData } from '../../core/redux/commonSlice';
import { parse } from 'date-fns';
interface props {
  setSuccessModel: (success: string) => void;
  scheduleModel: any;
  setScheduleModel: any;
  scheduleRef: any;
  SuccessCall: Function;
}
const schema = yup.object().shape({
  date: yup.string().required('Appointment date is required'),
  selectedTime: yup
    .object()
    .shape({
      name: yup.string().required('Schedule time is required'),
      code: yup.string().required('Schedule time is required'),
    })
    .nullable()
    .required('Schedule time is required'),
  doctorName: yup
    .object()
    .shape({
      name: yup.string().required('Doctor name is required'),
      code: yup.string().required('Doctor name is required'),
      id: yup.string().required('Doctor name is required'),
    })
    .required('Doctor name is required'),
  chiefComplaint: yup
    .string()
    .trim()
    .max(chiefComplaintLength, 'Chief complaint must be at most 200 characters')
    .required('Chief complaint is required'),
});
const patientSchema = yup.object().shape({
  patientName: yup
    .object()
    .shape({
      name: yup.string().required('Please select a patient'),
      code: yup.string().required('Please select a patient'),
      id: yup.string().required('Please select a patient'),
    })
    .required('Please select a patient'),
});
const reScheduleSchema = yup.object().shape({
  date: yup.string().required('Appointment date is required'),

  doctorName: yup
    .object()
    .shape({
      name: yup.string().required('Doctor name is required'),
      code: yup.string().required('Doctor name is required'),
      id: yup.string().required('Doctor name is required'),
    })
    .required('Doctor name is required'),
  chiefComplaint: yup
    .string()
    .trim()
    .max(chiefComplaintLength, 'Chief complaint must be at most 200 characters')
    .required('Chief complaint is required'),
  schedule: yup.string().required('Schedule status needed'),
  selectedTime: yup
    .object()
    .shape({
      name: yup.string().required('Schedule time is required'),
      code: yup.string().required('Schedule time is required'),
    })
    .nullable()
    .required('Schedule time is required'),
  // .when('schedule', {
  //   is: (value: string) => value !== scheduleType.cancelled,
  //   then: (schema) => schema.required('Schedule time is required'),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
const cancelledSchema = yup.object().shape({
  date: yup.string().required('Appointment date is required'),

  doctorName: yup
    .object()
    .shape({
      name: yup.string().optional(),
      code: yup.string().optional(),
      id: yup.string().optional(),
    })
    .optional(),
  chiefComplaint: yup.string().trim().optional(),
  schedule: yup.string().required('Schedule status needed'),
  selectedTime: yup
    .object()
    .shape({
      name: yup.string().optional().nullable(),
      code: yup.string().optional().nullable(),
    })
    .optional()
    .nullable(),
  // .when('schedule', {
  //   is: (value: string) => value !== scheduleType.cancelled,
  //   then: (schema) => schema.required('Schedule time is required'),
  //   otherwise: (schema) => schema.notRequired(),
  // }),
});
let scheduleWatch: any = '';
const ScheduleModel: React.FC<props> = ({
  scheduleModel,
  setScheduleModel,
  scheduleRef,
  SuccessCall,
}) => {
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const editData = useSelector((state: any) => state?.common?.editData);
  const userId = useSelector((state: any) => state?.login?.userDetails?.id);
  const userDetails = useSelector((state: any) => state?.login?.userDetails);
  const { getData, postData, putData } = useContext(ApiServiceContext);
  const [doctorListOptions, setDoctorListOptions] = useState<any>([]);
  const [patientListOptions, setPatientListOptions] = useState<any>([
    {
      id: '678e20a4146240be6bf6caca',
      name: 'patient',
      code: 'patient',
    },
  ]);

  const [scheduleSlotListOptions, setScheduleSlotListOptions] = useState<any>(
    [],
  );
  const [chiefComplaintTextLength, setChiefComplainTextLength] =
    useState<number>(0);
  const dispatch = useDispatch();
  const is_Schedule: boolean =
    scheduleModel?.type === scheduleType.schedule ? true : false;
  const isCreateAppointment =
    scheduleModel?.mainType === 'CreateAppointment' || false;
  const mainSchema: any =
    scheduleWatch === scheduleType.cancelled
      ? cancelledSchema
      : is_Schedule
        ? isCreateAppointment
          ? schema.concat(patientSchema)
          : schema
        : reScheduleSchema;
  const defaultValues = {
    date: null,
    selectedTime: { name: '', code: '' },
    doctorName: { name: '', code: '', id: '' },
    chiefComplaint: '',
    schedule: 'pending',
    patientName: { name: '', code: '', id: '' },
  };
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    trigger,
    getValues,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(mainSchema),
    defaultValues: defaultValues,
  });
  const today = moment().toDate();
  const selectedDate: any = watch('date');

  scheduleWatch = watch('schedule');
  const doctor_details: any = watch('doctorName');
  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload: any = {
        doctor_id: data?.doctorName?.id,
        patient_id: editData?.patient_id || data?.patientName?.id,
        appointment_date: moment(data?.date).format('YYYY-MM-DD'),
        cheif_complaint: data?.chiefComplaint || '',
        created_by: userId || '',
        kiosk_id: userDetails?.kioskDetails?._id || '',
        front_desk_attender: userDetails?.id || '',
      };
      if (data?.selectedTime?.name) {
        payload.time_slot = data.selectedTime.name;
      }
      if (!is_Schedule) {
        payload.appointment_status = data.schedule;
      }
      let url = end_points.rescheduledAppointmentApi.url;
      url += `/${editData?.appointment_id}`;
      const response = await (is_Schedule
        ? postData(end_points.createAppointmentApi.url, payload)
        : putData(url, payload));
      if (response.status === 200) {
        SuccessCall();
        if (scheduleRef?.current) scheduleRef.current.click();
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };
  const getDoctorsList = async (
    selectedDate: any = '',
    doctorData: any = '',
  ) => {
    try {
      showLoader();
      let payload: any = {};
      if (selectedDate) {
        payload.selectedDate = dateFormat_DD_MM_YYYY(selectedDate);
      }
      const response = await postData(
        end_points.getAvailableDoctorsListApi.url,
        payload,
      );
      if (response.status === 200) {
        const options = convertAvailableDoctorToOptions(response?.data?.data);
        setDoctorListOptions(options);
        if (doctorData) {
          const doctor: any = options?.filter(
            (doc: any) => doc.id === doctorData._id,
          );
          setValue('doctorName', doctor?.[0]);
          trigger('doctorName');
        }
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };
  const getPatientList = async () => {
    try {
      showLoader();

      const response = await getData(end_points.getPatientListApi.url);
      if (response.status === 200) {
        const options = convertAvailableDoctorToOptions(
          response?.data?.data?.patientsList,
          'patient',
        );
        setPatientListOptions(options);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };
  const getScheduleSlot = async (
    doctorId: any,
    selectedDate: any,
    selectedSlot?: any,
    fromType = '',
  ) => {
    try {
      showLoader();
      let url = end_points.getScheduleSlotbasedonDoctorId.url;

      if (doctorId && selectedDate) {
        const date = dateFormat_YYYY_MM_DD(selectedDate);
        url += `?doctorid=${doctorId}&date=${date}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        const currentTime = new Date();
        const options = response?.data?.data?.map((timeslot: any) => {
          const endTimeStr = timeslot?.timeslot?.split(' - ')[1];
          const endTime = parse(endTimeStr, 'h:mm a', selectedDate);
          const isFutureTimeSlot = endTime > currentTime;

          return {
            name: timeslot?.timeslot,
            code: timeslot?.id,
            id: timeslot?.id,
            isSelected: timeslot?.is_selected === '1',
            valid: isFutureTimeSlot,
          };
        });
        const filteredOptions = options?.filter((option: any) => option.valid);
        if (editData && selectedSlot) {
          const selectedSlots = filteredOptions?.filter((slot: any) => {
            return slot.name === selectedSlot;
          });
          setValue('selectedTime', selectedSlots?.[0]);
          trigger('selectedTime');
        }
        setScheduleSlotListOptions(filteredOptions);
      } else {
        setScheduleSlotListOptions([]);
      }
    } catch (error) {
      console.warn(error);
    } finally {
      hideLoader();
    }
  };
  const slotOptionTemplate = (option: any) => {
    return (
      <div
        className={`option-item ${option.isSelected ? 'booked disabled' : 'available'}`}
      >
        <span className={`${option.isSelected ? 'p-tag p-tag-danger' : ''}`}>
          {option.name} {option.isSelected ? '(booked)' : ''}
        </span>
      </div>
    );
  };
  useEffect(() => {
    if (isCreateAppointment) {
      getPatientList();
    }
    if (editData) {
      setValue('chiefComplaint', editData.cheif_complaint);
      setChiefComplainTextLength(editData?.cheif_complaint?.length);
      setValue(
        'schedule',
        editData?.appointment_status !== scheduleType.delayed ||
          editData?.appointment_status !== scheduleType.waiting
          ? editData?.appointment_status
          : '',
      );
    }
    if (
      editData &&
      (scheduleModel?.type === scheduleType.delayed ||
        scheduleModel?.type === scheduleType.waiting)
    ) {
      if (editData.appointment_date) {
        const aptDate: any = moment(editData.appointment_date).toDate();
        setValue('date', aptDate);
      }
      setValue('doctorName', {
        code: editData?.doctorDetails?.fullname,
        name: editData?.doctorDetails?.fullname,
        id: editData?.doctorDetails?._id,
      });
      getScheduleSlot(editData?.doctorDetails?._id, editData.appointment_date);
    } else if (editData && scheduleModel?.type === scheduleType.reschedule) {
      if (editData.appointment_date) {
        const aptDate: any = moment(editData.appointment_date).toDate();
        setValue('date', aptDate);
      }

      // if (is_Schedule) {
      //   getDoctorsList(editData?.doctorDetails);
      //   getScheduleSlot(
      //     editData?.doctorDetails?._id,
      //     editData.appointment_date,
      //     editData?.time_slot,
      //     scheduleType.reschedule,
      //   );
      // } else {
      setValue('selectedTime', {
        code: editData.time_slot,
        name: editData.time_slot,
      });
      setValue('doctorName', {
        code: editData?.doctorDetails?.fullname,
        name: editData?.doctorDetails?.fullname,
        id: editData?.doctorDetails?._id,
      });
      trigger('doctorName');
      trigger('selectedTime');
      // }
    } else {
      setValue('schedule', 'Pending');
      trigger('schedule');
    }
    return () => {
      reset(defaultValues);
      setValue('date', null);
      clearErrors();
      setChiefComplainTextLength(0);
      // trigger('date');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editData, isCreateAppointment]);
  useEffect(() => {
    if (selectedDate && doctor_details?.id && is_Schedule) {
      getScheduleSlot(doctor_details?.id, selectedDate);
    }
    if (selectedDate) {
      getDoctorsList(selectedDate, '');
    }
  }, [selectedDate, doctor_details?.id]);
  return (
    <div
      className={`modal custom-modal fade`}
      id="schedule_modal"
      data-keyboard="false"
      data-backdrop="static"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-body">
            <div className="schedule-modal-header">
              <h4>
                {is_Schedule
                  ? 'Schedule Appointment'
                  : 'Reschedule Appointment'}
              </h4>
            </div>
            <div className="schedule-modal-form">
              <form onSubmit={handleSubmit(onSubmit)}>
                {scheduleModel?.model && isCreateAppointment && (
                  <div className="form-wrap">
                    <Controller
                      name="patientName"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={
                            is_Schedule
                              ? patientListOptions?.length > 0
                                ? patientListOptions
                                : []
                              : [field.value]
                          }
                          filter={patientListOptions?.length > 0 ? true : false}
                          filterInputAutoFocus
                          appendTo={'self'}
                          disabled={!is_Schedule}
                          onBlur={() => trigger('patientName')}
                          onChange={(event: any) => {
                            field.onChange(event);
                            trigger('patientName');
                            setValue('selectedTime', { name: '', code: '' });
                          }}
                          optionLabel="name"
                          placeholder="Select patient*"
                          className={`w-100 modal-dropdown ${errors.patientName ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    <ErrorMessage value={errors?.patientName?.name} />
                  </div>
                )}
                {scheduleModel?.model && (
                  <div className="form-wrap">
                    <Controller
                      name="date"
                      control={control}
                      render={({ field: { onChange, value } }) => (
                        <Calendar
                          appendTo="self"
                          key={scheduleModel?.model ? 'open' : 'closed'}
                          className="schedule-datepicker"
                          placeholder="Select appointment date*"
                          value={value ? value : null}
                          minDate={today}
                          onChange={(e) => {
                            onChange(e.value);
                            setValue('doctorName', {
                              code: '',
                              name: '',
                              id: '',
                            });
                            if (errors?.date) {
                              trigger('date');
                            }
                          }}
                          disabled={!is_Schedule}
                          inputClassName={errors?.date ? 'select-error' : ''}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.date} />
                  </div>
                )}
                {selectedDate && (
                  <div className="form-wrap">
                    <Controller
                      name="doctorName"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={
                            is_Schedule
                              ? doctorListOptions?.length > 0
                                ? doctorListOptions
                                : []
                              : [field.value]
                          }
                          disabled={!is_Schedule}
                          filter={doctorListOptions?.length > 0 ? true : false}
                          onBlur={() => trigger('doctorName')}
                          filterInputAutoFocus
                          appendTo={'self'}
                          onChange={(event: any) => {
                            field.onChange(event);
                            trigger('doctorName');
                            setValue('selectedTime', { name: '', code: '' });
                          }}
                          optionLabel="name"
                          placeholder="Select doctor*"
                          className={`w-100 modal-dropdown ${errors.doctorName ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    <ErrorMessage value={errors?.doctorName?.name} />
                  </div>
                )}
                {doctor_details?.name && (
                  <div className="form-wrap">
                    <Controller
                      name="selectedTime"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...field}
                          options={
                            is_Schedule ||
                            scheduleModel?.type === scheduleType.delayed ||
                            scheduleModel?.type === scheduleType.waiting
                              ? scheduleSlotListOptions?.length > 0
                                ? scheduleSlotListOptions
                                : []
                              : [field.value]
                          }
                          onBlur={() => trigger('selectedTime')}
                          disabled={
                            !is_Schedule &&
                            scheduleModel?.type !== scheduleType.delayed &&
                            scheduleModel?.type !== scheduleType.waiting
                          }
                          onChange={(event: any) => {
                            if (!event?.value?.isSelected) {
                              field.onChange(event);
                              trigger('selectedTime');
                            }
                          }}
                          optionLabel="name"
                          itemTemplate={slotOptionTemplate}
                          placeholder="Select slot*"
                          className={`w-100 modal-dropdown ${errors.selectedTime ? 'is-invalid' : ''}`}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.selectedTime?.code} />
                  </div>
                )}
                <div className="form-wrap">
                  <Controller
                    name="chiefComplaint"
                    control={control}
                    render={({ field }) => (
                      <textarea
                        {...field}
                        className={`form-control ${
                          errors.chiefComplaint ? 'is-invalid' : ''
                        }`}
                        placeholder="Please enter the chief complaint here*"
                        onBlur={() => trigger('chiefComplaint')}
                        disabled={!is_Schedule}
                        onChange={(event: any) => {
                          if (
                            event.target.value.length <= chiefComplaintLength
                          ) {
                            field.onChange(event.target.value);
                            setChiefComplainTextLength(
                              event.target.value.length,
                            );
                            trigger('chiefComplaint');
                          }
                        }}
                        maxLength={chiefComplaintLength}
                      />
                    )}
                  />
                  <ErrorMessage value={errors.chiefComplaint} />

                  <p className="textarea-text">
                    {chiefComplaintTextLength}/200
                  </p>
                </div>
                {/* <div className="form-wrap">
                  <div className="custom-radio">
                    <label className="custom_radio">
                      <input type="radio" name="schedule" defaultChecked />
                      <span className="checkmark" /> Pending
                    </label>
                    <label className="custom_radio">
                      <input type="radio" name="schedule" />
                      <span className="checkmark" /> Scheduled
                    </label>
                    <label className="custom_radio">
                      <input type="radio" name="schedule" />
                      <span className="checkmark" /> Cancelled
                    </label>
                  </div>
                </div> */}
                {!is_Schedule && (
                  <div className="form-wrap">
                    <Controller
                      name="schedule"
                      control={control}
                      render={({ field }) => (
                        <>
                          <div className="custom-radio pb-0">
                            {scheduleModel?.type !== scheduleType.delayed &&
                              scheduleModel?.type !== scheduleType.waiting && (
                                <label className="custom_radio pb-0">
                                  <input
                                    type="radio"
                                    name={field.name}
                                    value="pending"
                                    checked={field.value === 'pending'}
                                    onChange={() => field.onChange('pending')}
                                  />
                                  <span className="checkmark" /> Pending
                                </label>
                              )}
                            {/* {scheduleModel === scheduleType.delayed && (
                            <label className="custom_radio pb-0">
                              <input
                                type="radio"
                                name={field.name}
                                value="delayed"
                                disabled={false}
                                checked={field.value === 'delayed'}
                                // onChange={() => field.onChange('delayed')}
                              />
                              <span className="checkmark" /> Delayed
                            </label>
                          )} */}
                            <label className="custom_radio pb-0">
                              <input
                                type="radio"
                                name={field.name}
                                value="scheduled"
                                checked={field.value === 'scheduled'}
                                onChange={() => {
                                  field.onChange('scheduled');
                                  trigger('selectedTime');
                                }}
                              />
                              <span className="checkmark" /> Scheduled
                            </label>
                            <label className="custom_radio pb-0">
                              <input
                                type="radio"
                                name={field.name}
                                value="cancelled"
                                checked={field.value === 'cancelled'}
                                onChange={() => {
                                  field.onChange('cancelled');
                                  trigger('selectedTime');
                                  clearErrors();
                                }}
                              />
                              <span className="checkmark" /> Cancelled
                            </label>
                          </div>
                          <ErrorMessage value={errors?.schedule} />
                        </>
                      )}
                    />
                  </div>
                )}
                <div className="modal-form-btn">
                  <button
                    type="submit"
                    className="btn btn-secondary"
                    // data-bs-toggle="modal"
                    // data-bs-target="#success_modal"
                  >
                    {scheduleWatch === 'delayed' ||
                    scheduleWatch === 'cancelled'
                      ? 'Update'
                      : 'Schedule'}
                  </button>
                  <button
                    type="button"
                    className="btn btn-light"
                    data-bs-dismiss="modal"
                    ref={scheduleRef}
                    onClick={() => {
                      dispatch(setEditData(undefined));
                      setScheduleModel({ type: '', model: false });
                      reset(defaultValues);
                      setValue('date', null);
                      trigger('date');
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleModel;
