import React from "react";

const SingleBlog = () => {
  return (
    <>
      {" "}
      <div className="blogset">
        <div className="container">
          <div className="row">
            <div className="col-md-10 m-auto">
              <div className="blogset-divs">
                <div className="bloggset-head">
                  <h6>Published: April 30, 2024</h6>
                  <h3>Understanding Mental Health: A Guide for Beginners</h3>
                  <p>
                    Mental health encompasses emotional, psychological, and
                    social well-being. It affects how we think, feel, and act.
                    Understanding its importance is key to addressing mental
                    health disorders and promoting overall wellness.
                  </p>
                </div>
                <div className="bloggset-content">
                  <img src="assets/img/blog/blog-1.png" alt="img" />
                  <p>
                    Mental health refers to our emotional, psychological, and
                    social well-being. It influences how we think, feel, and
                    act, impacting our relationships, work, and how we cope with
                    stress. A balanced mental health state allows us to lead a
                    fulfilling life, while disturbances can lead to various
                    mental health disorders.
                  </p>
                  <h3>Components of Mental Health</h3>
                  <ul>
                    <li>
                      <p>
                        <b>Emotional Well-being: </b>This involves recognizing,
                        understanding, and managing our emotions. It also
                        includes resilience—the ability to recover from
                        setbacks.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Psychological Well-being:</b>This refers to our
                        thought processes, self-image, and outlook on life. A
                        positive psychological state contributes to a sense of
                        purpose and personal growth.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Social Well-being:</b> This concerns our
                        relationships and interactions with others. Healthy
                        social connections contribute to overall happiness and
                        support.
                      </p>
                    </li>
                  </ul>
                  <h3>Common Mental Health Disorders</h3>
                  <p>
                    Several common mental health disorders can affect
                    individuals, including:
                  </p>
                  <ul>
                    <li>
                      <p>
                        <b>Anxiety Disorders:</b> Characterized by excessive
                        fear or worry, affecting daily functioning.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Depression:</b> A mood disorder causing persistent
                        feelings of sadness and loss of interest.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Bipolar Disorder:</b> Involves mood swings ranging
                        from depressive lows to manic highs.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Schizophrenia:</b> A severe mental disorder that
                        affects thought processes, perceptions, and behaviors.
                      </p>
                    </li>
                  </ul>
                  <div className="row">
                    <div className="col-lg-6">
                      <img src="assets/img/blog/blog-2.png" alt="img" />
                    </div>
                    <div className="col-lg-6">
                      <img src="assets/img/blog/blog-3.png" alt="img" />
                    </div>
                  </div>
                  <h3>Taking Steps Toward Better Mental Health</h3>
                  <p>Here are a few basic steps to improve mental health:</p>
                  <ul>
                    <li>
                      <p>
                        <b>Stay Active:</b> Regular physical activity can boost
                        mood and reduce stress.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Connect with Others:</b> Building healthy
                        relationships can provide support and reduce feelings of
                        loneliness.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Seek Professional Help:</b> If you're experiencing
                        persistent emotional or psychological distress,
                        consulting a mental health professional is crucial.
                      </p>
                    </li>
                    <li>
                      <p>
                        <b>Practice Self-Care:</b> Activities like meditation,
                        journaling, and spending time in nature can improve your
                        mental state.
                      </p>
                    </li>
                  </ul>
                  <div className="conclusion-set">
                    <h5>Conclusion</h5>
                    <p className="mb-0">
                      Understanding mental health is the first step toward
                      fostering a healthier lifestyle. If you or someone you
                      know is struggling, remember that seeking help is a sign
                      of strength. Professional support, like that provided by
                      Dr. Susan Reynolds, can make a significant difference in
                      your journey toward better mental health.
                    </p>
                  </div>
                  <div className="profile-doctors">
                    <div className="d-flex align-items-center">
                      <div className="profile-doctors-img">
                        <img src="assets/img/profiles/avatar-01.png" alt="" />
                      </div>
                      <div className="profile-doctors-content">
                        <h5>Dr. Sarah Johnson</h5>
                        <h6>Clinical Psychologist</h6>
                        <p className="mb-0">
                          Dr. Sarah Johnson is a clinical psychologist with over
                          10 years of experience in cognitive behavioral
                          therapy.
                        </p>
                      </div>
                    </div>
                    <div className="profile-doctors-share">
                      <h6>Share:</h6>
                      <ul>
                        <li>
                          <a href="#">
                            <img src="assets/img/icons/facebook.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src="assets/img/icons/twitter.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src="assets/img/icons/instagram.png" alt="" />
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <img src="assets/img/icons/linkedin.png" alt="" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="blog-view">
        <div className="text-center">
          <h2>Latest Blogs</h2>
        </div>
        <div className="container">
          <div className="row">
            <div className="row">
              <div className="col-lg-4 col-12">
                <div className="blog-div releated-blog">
                  <div className="blog-img ">
                    <img src="assets/img/blog/blog-16.png" alt="img" />
                  </div>
                  <div className="blog-date">
                    <div className="banner-content">
                      <ul>
                        <li>
                          <a href="#">Mental</a>
                        </li>
                        <li>
                          <a href="#">April 19, 2024</a>
                        </li>
                        <li>
                          <a href="#">Jake Reynolds</a>
                        </li>
                      </ul>
                    </div>
                    <h5>
                      <a href="#">How Antidepressants Improve Mental Health</a>{" "}
                    </h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available but the majority have suffered alteration in
                      that some injected humour.
                    </p>
                    <a href="#" className="read-more text-links">
                      Read More <i className="ti ti-arrow-right  ms-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="blog-div releated-blog">
                  <div className="blog-img">
                    <img src="assets/img/blog/blog-17.png" alt="img" />
                  </div>
                  <div className="blog-date">
                    <div className="banner-content">
                      <ul>
                        <li>
                          <a href="#">Mental</a>
                        </li>
                        <li>
                          <a href="#">April 19, 2024</a>
                        </li>
                        <li>
                          <a href="#">Jake Reynolds</a>
                        </li>
                      </ul>
                    </div>
                    <h5>
                      <a href="#">Comparing Different Antidepressant Classes</a>{" "}
                    </h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available but the majority have suffered alteration in
                      that some injected humour.
                    </p>
                    <a href="#" className="read-more text-links">
                      Read More <i className="ti ti-arrow-right  ms-2" />
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-12">
                <div className="blog-div releated-blog">
                  <div className="blog-img ">
                    <img src="assets/img/blog/blog-18.png" alt="img" />
                  </div>
                  <div className="blog-date">
                    <div className="banner-content">
                      <ul>
                        <li>
                          <a href="#">Mental</a>
                        </li>
                        <li>
                          <a href="#">April 19, 2024</a>
                        </li>
                        <li>
                          <a href="#">Jake Reynolds</a>
                        </li>
                      </ul>
                    </div>
                    <h5>
                      <a href="#">
                        How to Recognize Signs of Anxiety and Depression
                      </a>{" "}
                    </h5>
                    <p>
                      There are many variations of passages of Lorem Ipsum
                      available but the majority have suffered alteration in
                      that some injected humour.
                    </p>
                    <a href="#" className="read-more text-links">
                      Read More <i className="ti ti-arrow-right  ms-2" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="join-div wow zoomIn"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <h6>Join TurningWell</h6>
                <p>
                  Sign up now to get unrestricted access to TurningWell's online
                  mental health directory, resources, and more!
                </p>
                <a href="#" className="btn btn-signup rounded">
                  Sign up now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SingleBlog;
