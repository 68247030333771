import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  avatar01,
  avatar02,
  avatar03,
  avatar04,
  avatarDefault,
  avatarProfile,
  awardIcon1,
  awardIcon2,
  awardIcon3,
  performanceIcon1,
  performanceIcon2,
  trendDown,
  trendUp,
  videoImg05,
  videoImg06,
  videoImg07,
  videoImg08,
} from "../../utils/imagepath";
import ReactApexChart from "react-apexcharts";
import { Scrollbars } from "react-custom-scrollbars-2";
import { routes } from "../../utils/routes/routes";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";
import { getData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { GetIdLabel, setLocalityLabel } from "../../utils/constants/index.";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import {
  capitalizeFirstLetter,
  formatDateString,
} from "../../utils/constants/index.";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setUserDirectoryDetails } from "../../core/redux/commonSlice";

const Dashboard = () => {
  const dispatch = useDispatch();
  const userDirectoryDetails = useSelector(
    (state: any) => state?.common?.userDirectoryDetails
  );
  const [chartOptions, setChartOptions] = useState<any>({
    series: [
      {
        name: "Open Issues",
        data: [44, 55, 57, 56, 61, 58, 63],
      },
    ],
    chart: {
      type: "bar",
      height: 300,
    },
    colors: ["#2268D1", "#0095FF"],
    plotOptions: {
      bar: {
        borderRadius: 8,
        borderRadiusApplication: "end",
        horizontal: false,
        columnWidth: "40%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
      ],
    },
    yaxis: {
      title: {
        text: "$ (thousands)",
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [selectedView, setSelectedView] = useState(null);
  const [selectedTimeframe, setSelectedTimeframe] = useState(null);
  const [directoryData, setDirectoryData] = useState<any>({});
  const [designationsList, setDesignationsList] = useState([]);

  const [listData, setListData] = useState<any[]>([]);
  const [rangeText, setRangeText] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const getDirectoryData = async () => {
    const response = await getData(`${end_points?.user_about_list?.url}`);
    if (response?.status < 400) {
      setDirectoryData(response?.data?.data[0]);
      dispatch(setUserDirectoryDetails(response?.data?.data[0]));
    }
  };
  const GetDesignations = async () => {
    try {
      showLoader();
      const response = await getData(
        `${end_points.mater_designation_list.url}`
      );
      if (response?.status < 400) {
        setDesignationsList(setLocalityLabel(response.data?.data));
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };
  const eventOptions = [
    { label: "My Events", value: "My Events" },
    { label: "--Select--", value: "--Select--" },
  ];

  const viewOptions = [
    { label: "Total Content Page Views", value: "Total Content Page Views" },
    { label: "--Select--", value: "--Select--" },
  ];

  const timeframeOptions = [
    { label: "All Time", value: "All Time" },
    { label: "--Select--", value: "--Select--" },
  ];

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const getList = async (page: number = 1, limit: number = 4) => {
    try {
      showLoader();
      let url = end_points.list_announcement.url;
      if (page) {
        url += `?page=${page}&limit=${limit}`;
      }
      const response = await getData(url);
      if (response.status === 200) {
        setListData(response?.data?.data?.data);
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getList();
    GetDesignations();
    getDirectoryData();
  }, []);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="flex-body d-flex flex-column row-gap-30">
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="alert-flex-wrapper">
                          {/* {!isChecked && (
                            <div className="alert-wrapper flex-style">
                              <div className="alert-info flex-style col-gap-10">
                                <i className="ti ti-info-circle" />
                                <p>
                                  Your account is managed by Dsquared Media.
                                  Please contact your account manager if you
                                  have any questions.
                                </p>
                              </div>
                              <label className="ms-lg-auto mt-2 mt-lg-0 custom_check">
                                <input
                                  type="checkbox"
                                  checked={isChecked}
                                  onChange={handleCheckboxChange}
                                />
                                <span className="checkmark" />
                              </label>
                            </div>
                          )} */}
                          {directoryData?.account_restricted && (
                            <div className="alert-wrapper flex-style alert-danger">
                              <div className="alert-info flex-style col-gap-10">
                                <i className="ti ti-alert-triangle" />
                                <p>
                                  Account restricted until medical license photo
                                  uploaded. Please submit to regain access.
                                </p>
                              </div>
                              <Link
                                to={routes.settings}
                                state={{
                                  from: "SubmitInformation",
                                  type: directoryData?.account_restricted,
                                }}
                                className="btn btn-primary ms-lg-auto mt-2 mt-lg-0 btn-danger rounded"
                              >
                                Submit Information
                              </Link>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-4 mb-4 mb-lg-0 d-flex">
                        <div className="profile-card text-center h-100 w-100">
                          <div className="top-profile-blk mx-auto justify-content-center">
                            <img
                              src={
                                userDirectoryDetails?.profile_image !==
                                  undefined &&
                                userDirectoryDetails?.profile_image !== ""
                                  ? userDirectoryDetails?.profile_image
                                  : avatarDefault
                              }
                              className="img-fluid mx-auto rounded avatar-lg provider-index-img"
                              alt="Avatar Profile"
                            />
                            <div className="profile-info-blk">
                              <h3>{directoryData?.fullname}</h3>
                              <p>
                                {GetIdLabel(
                                  designationsList,
                                  directoryData?.designation,
                                  "fullname"
                                )}
                              </p>
                              <ul>
                                {directoryData?.appointment_mode?.map(
                                  (item: any) => (
                                    <>
                                      <li>
                                        {item === "in-person" ? (
                                          <i className="ti ti-user" />
                                        ) : (
                                          <i className="ti ti-brand-zoom" />
                                        )}
                                        {item}
                                      </li>
                                    </>
                                  )
                                )}
                              </ul>
                            </div>
                          </div>
                          <div className="cta-blks">
                            <a
                              href={routes.viewProfile}
                              className="btn rounded btn-outline-primary"
                            >
                              View Profile
                            </a>
                            <Link
                              to={routes.directory}
                              className="btn rounded btn-primary"
                            >
                              Edit Profile
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8 d-flex">
                        <div className="custom-card position-relative custom-height-card px-0 w-100">
                          <div className="title-info d-flex mb-3 px-30 align-items-center">
                            <h3>My Announcements</h3>
                            <Link
                              to={routes.announcements}
                              className="btn btn-sm ms-auto btn-outline-primary"
                            >
                              See More
                            </Link>
                          </div>
                          {listData?.length > 0 ? (
                            <div className="table-responsive">
                              <table className="table">
                                <tbody>
                                  {listData.map((data: any) => {
                                    const shortDescription =
                                      data?.short_description || "";
                                    const charLimit = 50;
                                    const trimDescription =
                                      shortDescription.length > charLimit
                                        ? shortDescription.slice(0, charLimit)
                                        : shortDescription;

                                    return (
                                      <tr key={data.id}>
                                        <td className="media-linksset">
                                          <div className="media-blk d-flex">
                                            <div className="img-blk">
                                              <img
                                                src={data?.featured_image}
                                                className="img img-fluid"
                                                alt=""
                                              />
                                            </div>
                                            <div className="media-content">
                                              <h5>
                                                {capitalizeFirstLetter(
                                                  data?.title
                                                )}
                                              </h5>
                                              <p>{`${trimDescription}${shortDescription.length > charLimit ? "..." : ""}`}</p>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="text-center">
                                          {formatDateString(data.createdAt)}
                                        </td>
                                        <td className="text-end">
                                          <Link
                                            to="#"
                                            className="btn ms-auto btn-readmore btn-outline-primary"
                                          >
                                            Read More
                                          </Link>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </tbody>
                              </table>
                            </div>
                          ) : (
                            <div className="table-responsive no-records-responsive">
                              <table className="table no-records-table border-0">
                                <tbody>
                                  <tr className="border-0">
                                    <td
                                      className="no-records-td border-0"
                                      colSpan={3}
                                    >
                                      No records found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="custom-card">
                          <div className="top-title flex-style">
                            <div className="heading-blk">
                              <h3>Overview</h3>
                              <p>
                                Comprehensive data insights guiding strategic
                                decisions and business growth.
                              </p>
                            </div>
                            <div className="ms-lg-auto d-flex align-items-center flex-wrap gap-2 action-blk">
                              <div className="icon-form">
                                <span className="form-icon">
                                  <i className="ti ti-calendar" />
                                </span>
                                <DateRangePicker
                                  initialSettings={initialSettings}
                                >
                                  <input
                                    type="text"
                                    className="form-control bookingrange"
                                    value={
                                      rangeText ||
                                      `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                                    }
                                    readOnly
                                  />
                                </DateRangePicker>
                              </div>
                              <Link
                                to="#"
                                className="btn btn-sm btn-outline-primary"
                              >
                                See More
                              </Link>
                            </div>
                          </div>
                          <div className="d-flex flex-column row-gap-30">
                            <div className="row">
                              <div className="col-md-3">
                                <div className="card-insights">
                                  <p>
                                    Total Provider Main Page Views
                                    <Link to="#">
                                      <i className="ms-1 ti ti-info-circle" />
                                    </Link>
                                  </p>
                                  <div className="count-insights mt-auto">
                                    <h3>0</h3>
                                    <p>
                                      +0%
                                      <img
                                        src={trendUp}
                                        className="ms-1"
                                        alt=""
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card-insights info-skin">
                                  <p>
                                    Total Subscribers
                                    <Link to="#">
                                      <i className="ms-1 ti ti-info-circle" />
                                    </Link>
                                  </p>
                                  <div className="count-insights mt-auto">
                                    <h3>0</h3>
                                    <p>
                                      +0%
                                      <img
                                        src={trendUp}
                                        className="ms-1"
                                        alt=""
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card-insights warning-skin">
                                  <p>
                                    Total Followers
                                    <Link to="#">
                                      <i className="ms-1 ti ti-info-circle" />
                                    </Link>
                                  </p>
                                  <div className="count-insights mt-auto">
                                    <h3>0</h3>
                                    <p>
                                      +0%
                                      <img
                                        src={trendUp}
                                        className="ms-1"
                                        alt=""
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-3">
                                <div className="card-insights danger-skin">
                                  <p>
                                    Total Subscription Revenue{" "}
                                    <Link to="#">
                                      <i className="ms-1 ti ti-info-circle" />
                                    </Link>
                                  </p>
                                  <div className="count-insights mt-auto">
                                    <h3>0</h3>
                                    <p>
                                      +0%
                                      <img
                                        src={trendDown}
                                        className="ms-1"
                                        alt=""
                                      />
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insights-wrapper">
                              <div className="heading-blk flex-style">
                                <h4>Advertising revenue</h4>
                                <Link
                                  to="#"
                                  className="btn btn-sm ms-auto btn-outline-primary"
                                >
                                  See More
                                </Link>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="card-insights warning-skin">
                                    <p>
                                      Total Followers
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights">
                                    <p>
                                      Total Provider Main Page Views
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights danger-skin">
                                    <p>
                                      Total Subscription Revenue{" "}
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights info-skin">
                                    <p>
                                      Total Subscribers
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendDown}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="insights-wrapper">
                              <div className="heading-blk flex-style">
                                <h4>special event revenue</h4>
                                <Link
                                  to="#"
                                  className="btn btn-sm ms-auto btn-outline-primary"
                                >
                                  See More
                                </Link>
                              </div>
                              <div className="row">
                                <div className="col-md-3">
                                  <div className="card-insights">
                                    <p>
                                      Total Provider Main Page Views
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights danger-skin">
                                    <p>
                                      Total Subscription Revenue{" "}
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights info-skin">
                                    <p>
                                      Total Subscribers
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <div className="card-insights warning-skin">
                                    <p>
                                      Total Followers
                                      <Link to="#">
                                        <i className="ms-1 ti ti-info-circle" />
                                      </Link>
                                    </p>
                                    <div className="count-insights mt-auto">
                                      <h3>0</h3>
                                      <p>
                                        +0%
                                        <img
                                          src={trendUp}
                                          className="ms-1"
                                          alt=""
                                        />
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="custom-card">
                          <div className="top-title flex-style">
                            <div className="heading-blk">
                              <h3>Profile Performance</h3>
                              <p>
                                Check your profile's visibility &amp;
                                interactions for improvement.
                              </p>
                            </div>
                            <div className="ms-lg-auto action-blk">
                              <Link
                                to={routes.profilePerformance}
                                className="btn btn-sm ms-auto btn-outline-primary"
                              >
                                See More
                              </Link>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="card-count">
                                <div className="count-highlights flex-style">
                                  <h2>0</h2>
                                  <div className="icon-blk rounded align-center ms-auto">
                                    <img src={performanceIcon1} alt="" />
                                  </div>
                                </div>
                                <p>Total Directory Profile Views</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card-count perf-skin2">
                                <div className="count-highlights flex-style">
                                  <h2>0</h2>
                                  <div className="icon-blk rounded align-center ms-auto">
                                    <img src={performanceIcon1} alt="" />
                                  </div>
                                </div>
                                <p>Total Directory Forms Filled</p>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="card-count perf-skin3">
                                <div className="count-highlights flex-style">
                                  <h2>0</h2>
                                  <div className="icon-blk rounded align-center ms-auto">
                                    <img src={performanceIcon2} alt="" />
                                  </div>
                                </div>
                                <p>Total Directory Phone Inquiries</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-lg-6 mb-4 mb-lg-0">
                        <div className="custom-card h-100">
                          <div className="top-blk flex-style">
                            <div className="content-info">
                              <h3>Upcoming Events</h3>
                              <p>Stay tuned for exciting upcoming events!</p>
                            </div>
                            <Dropdown
                              className="chart-dropdown ms-lg-auto"
                              value={selectedEvent}
                              options={eventOptions}
                              onChange={(e) => setSelectedEvent(e.value)}
                              placeholder="Select"
                            />
                          </div>
                          <div className="blockquote-wrapper d-flex flex-column row-gap-16">
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                            <div className="blockquote-item">
                              <div className="blockquote-info">
                                <h5>
                                  Meet with John{" "}
                                  <span className="ms-1">
                                    May 16, 2024 at 06:00 PM{" "}
                                  </span>
                                </h5>
                                <p>
                                  Lorem ipsum dolor sit amet, consectetur
                                  adipiscing elit Lorem ipsum dolor sit amet,
                                  consectetur adipiscing elit
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-card mb-4">
                          <div className="top-blk flex-style">
                            <Dropdown
                              className="chart-dropdown"
                              value={selectedView}
                              options={viewOptions}
                              onChange={(e) => setSelectedView(e.value)}
                              placeholder="Select"
                            />
                            <Dropdown
                              className="chart-dropdown ms-lg-auto"
                              value={selectedTimeframe}
                              options={timeframeOptions}
                              onChange={(e) => setSelectedTimeframe(e.value)}
                              placeholder="Select"
                            />
                          </div>
                          <div className="row">
                            <div className="col-12">
                              <div id="chart-issues">
                                <ReactApexChart
                                  height={300}
                                  options={chartOptions}
                                  series={chartOptions.series}
                                  type="bar"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="custom-card">
                          <div className="top-blk flex-style">
                            <div className="content-info">
                              <h3>Notifications</h3>
                            </div>
                            <Link
                              to={routes.notifications}
                              className="btn btn-sm ms-lg-auto btn-outline-primary"
                            >
                              See More
                            </Link>
                          </div>
                          <div className="notification-wrapper">
                            <div className="notify-item flex-style">
                              <div className="notify-info flex-style">
                                <img
                                  src={avatar01}
                                  className="rounded"
                                  alt=""
                                />
                                <p>
                                  <strong>Danial Bose</strong> is started
                                  following you.
                                </p>
                              </div>
                              <span className="ms-auto">2m</span>
                            </div>
                            <div className="notify-item flex-style">
                              <div className="notify-info flex-style">
                                <img
                                  src={avatar02}
                                  className="rounded"
                                  alt=""
                                />
                                <p>
                                  <strong>Caroline</strong> commented on your
                                  blog “Take a Look Back at the Most Absurd
                                  Carpet Ever”
                                </p>
                              </div>
                              <span className="ms-auto">32m</span>
                            </div>
                            <div className="notify-item flex-style">
                              <div className="notify-info flex-style">
                                <img
                                  src={avatar03}
                                  className="rounded"
                                  alt=""
                                />
                                <p>
                                  <strong>Peter</strong> Parker liked you Video
                                </p>
                              </div>
                              <span className="ms-auto">2h</span>
                            </div>
                            <div className="notify-item flex-style">
                              <div className="notify-info flex-style">
                                <img
                                  src={avatar04}
                                  className="rounded"
                                  alt=""
                                />
                                <p>
                                  <strong>Katrina</strong> Kaif is started
                                  following you.
                                </p>
                              </div>
                              <span className="ms-auto">23m</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="custom-card">
                          <div className="top-blk flex-style">
                            <div className="content-info">
                              <h3>Reviews</h3>
                            </div>
                            <Link
                              to="#"
                              className="btn btn-sm ms-lg-auto btn-outline-primary"
                            >
                              See More
                            </Link>
                          </div>
                          <div className="rating-highlights">
                            <div className="row">
                              <div className="col-lg-6">
                                <div className="rating-patients mb-4 mb-lg-0 flex-style">
                                  <div className="count-start">
                                    <h2>4.8</h2>
                                    <ul className="rating">
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star" />
                                      </li>
                                    </ul>
                                  </div>
                                  <div className="info-end">
                                    <p>Insights from Patients</p>
                                    <h4>overall Rating</h4>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-6">
                                <div className="rating-clients flex-style">
                                  <div className="count-start">
                                    <h2>58</h2>
                                  </div>
                                  <div className="info-end">
                                    <p>Here From our Clients</p>
                                    <h4>Total Reviews</h4>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div
                                className="comments-wrapper"
                                style={{ height: "240px" }}
                              >
                                <Scrollbars autoHeight autoHeightMax={240}>
                                  <div className="comments-list">
                                    <ul className="rating">
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star" />
                                      </li>
                                    </ul>
                                    <p>
                                      Dr. Reynolds listened to my concerns and
                                      created a personalized treatment plan. I
                                      felt supported throughout my journey.
                                      Highly recommend!
                                    </p>
                                    <span className="flex-style">
                                      Less than 2 months ago
                                      <i className="mx-2 ti ti-point-filled" />
                                      Lisa
                                      <i className="mx-2 ti ti-point-filled" />
                                      Verified patient
                                    </span>
                                  </div>
                                  <div className="comments-list">
                                    <ul className="rating">
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star" />
                                      </li>
                                    </ul>
                                    <p>
                                      Dr. Reynolds listened to my concerns and
                                      created a personalized treatment plan. I
                                      felt supported throughout my journey.
                                      Highly recommend!
                                    </p>
                                    <span className="flex-style">
                                      Less than 2 months ago
                                      <i className="mx-2 ti ti-point-filled" />
                                      Lisa
                                      <i className="mx-2 ti ti-point-filled" />
                                      Verified patient
                                    </span>
                                  </div>
                                  <div className="comments-list">
                                    <ul className="rating">
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star" />
                                      </li>
                                    </ul>
                                    <p>
                                      Dr. Reynolds listened to my concerns and
                                      created a personalized treatment plan. I
                                      felt supported throughout my journey.
                                      Highly recommend!
                                    </p>
                                    <span className="flex-style">
                                      Less than 2 months ago
                                      <i className="mx-2 ti ti-point-filled" />
                                      Lisa
                                      <i className="mx-2 ti ti-point-filled" />
                                      Verified patient
                                    </span>
                                  </div>
                                  <div className="comments-list">
                                    <ul className="rating">
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star-filled" />
                                      </li>
                                      <li>
                                        <i className="ti ti-star" />
                                      </li>
                                    </ul>
                                    <p>
                                      Dr. Reynolds listened to my concerns and
                                      created a personalized treatment plan. I
                                      felt supported throughout my journey.
                                      Highly recommend!
                                    </p>
                                    <span className="flex-style">
                                      Less than 2 months ago
                                      <i className="mx-2 ti ti-point-filled" />
                                      Lisa
                                      <i className="mx-2 ti ti-point-filled" />
                                      Verified patient
                                    </span>
                                  </div>
                                </Scrollbars>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="container-fluid p-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="custom-card">
                          <div className="top-blk flex-style">
                            <div className="content-info">
                              <h3>
                                My Platform Distinctions, Awards, &amp; Rating
                              </h3>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="award-wrapper flex-style">
                                <div className="award-item text-center">
                                  <div className="shape-blk">
                                    <img
                                      src={awardIcon1}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    {/* <h3>1000</h3> */}
                                  </div>
                                  <div className="award-info">
                                    <p>Most Viewed Profile</p>
                                    <span>May 18, 2024</span>
                                  </div>
                                </div>
                                <div className="award-item text-center">
                                  <div className="shape-blk">
                                    <img
                                      src={awardIcon2}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    {/* <h3>#1</h3> */}
                                  </div>
                                  <div className="award-info">
                                    <p>Trending Video</p>
                                    <span>May 18, 2024</span>
                                  </div>
                                </div>
                                <div className="award-item text-center">
                                  <div className="shape-blk">
                                    <img
                                      src={awardIcon3}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    {/* <h3>5.0</h3> */}
                                  </div>
                                  <div className="award-info">
                                    <p>Best Rated Provider</p>
                                    <span>May 18, 2024</span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Dashboard;
