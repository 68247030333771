import React from "react";
import { Link } from "react-router-dom";
import {
  us,
  videoImg04,
  videoImg02,
  videoImg03,
  videoImg05,
  videoImg06,
  videoImg07,
  videoImg08,
  videoImg09,
  videoImg01,
  filter,
  avatar03,
  avatar04,
  avatar06,
  verified,
  es,
  br,
  fr,
  it,
  no,
  live01,
  live,
  filterFrontend,
  videoMp4,
} from "../../utils/imagepath";

const FilteredCountryFeed = () => {
  return (
    <>
      <div className="feed-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feeds-div">
                <h6>All Feeds</h6>
                <p>
                  Welcome to the All Feeds section, your comprehensive hub for
                  discovering and watching videos from various providers.
                </p>
                <div className="feed-input">
                  <input type="text" placeholder="Mental health" />
                  <i className="ti ti-search search-icon" />
                  <Link to="#" className="btn btn-primary rounded">
                    <img src={filterFrontend} className="me-2" alt="" />
                    Filter by
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feeds-video">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="flag-contents">
                <ul>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img src={us} className="me-2" alt="user" />
                      English
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img
                        src={es}
                        className="me-2"
                        alt="user"
                      />
                      Spanish
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img
                        src={br}
                        className="me-2"
                        alt="user"
                      />
                      Brazilian portuguese
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img
                        src={fr}
                        className="me-2"
                        alt="user"
                      />
                      French
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img
                        src={it}
                        className="me-2"
                        alt="user"
                      />
                      Italian
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      <img
                        src={no}
                        className="me-2"
                        alt="user"
                      />
                      Norweigan
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="feeds-head">
                <h6>Provider Filter</h6>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#subscription-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive text-end">
                    <i className="ti ti-heart text-white fs-24" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h5>Dr. Susan Reynold</h5>
                      <p className="text-white d-flex align-items-center">
                        <i className="ti ti-video me-2 fs-18" />
                        500 Viewers <span className="split-line split-flag" />{" "}
                        45m
                      </p>
                    </div>
                    <div className="video-play video-flag">
                      <span className="border-0">
                        <img src={us} alt="" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* related blogs */}
      <div className="local-section pt-0">
        <div className="container">
          <div className="row">
            <div className="col-12  wow fadeInUp">
              <div className="section-header d-flex align-items-center justify-content-between flex-wrap">
                <h3 className="mb-0">Featured Providers</h3>
                <Link to="#" className="viewalls">
                  View All
                </Link>
              </div>
            </div>
            <div className="col-12  wow fadeInUp">
              <div className="row">
                <div className="col-lg-4 col-md-12">
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname ">
                      <div className="doctor-profile-img">
                        <img
                          src={avatar03}
                          alt="img"
                        />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">
                            Dr. Jake Reynolds{" "}
                            <img
                              src={verified}
                              className="ms-1"
                              alt=""
                            />
                          </Link>
                        </h4>
                        <h3>Mental Health Therapist</h3>
                        <h5>San Francisco, California</h5>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Dr. Jake Reynolds is a clinical psychologist with over
                        10 years of experience in cognitive behavioural therapy.
                      </p>
                      <ul className="rating">
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star" />
                        </li>
                        <li>
                          <span>(104 Review)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname ">
                      <div className="doctor-profile-img">
                        <img
                          src={avatar04}
                          alt="img"
                        />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">
                            Emily Carter, DDS
                            <img
                              src={verified}
                              className="ms-1"
                              alt=""
                            />
                          </Link>
                        </h4>
                        <h3>Dentist</h3>
                        <h5>Austin, Texas</h5>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Dr. Emily Carter is a clinical psychologist with over 12
                        years of experience in cognitive behavioural therapy.
                      </p>
                      <ul className="rating">
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star" />
                        </li>
                        <li>
                          <span>(21 Review)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname ">
                      <div className="doctor-profile-img">
                        <img
                          src={avatar06}
                          alt="img"
                        />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">
                            Maria Thompson, PhD
                            <img
                              src={verified}
                              className="ms-1"
                              alt=""
                            />
                          </Link>
                        </h4>
                        <h3>Maria Thompson, PhD</h3>
                        <h5>San Francisco, California</h5>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Dr. Maria Thompson is a licensed professional counselor
                        with over 15 years of experience in mental health
                        therapy.
                      </p>
                      <ul className="rating">
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star-filled" />
                        </li>
                        <li>
                          <i className="ti ti-star" />
                        </li>
                        <li>
                          <span>(45 Review)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* related blogs */}
      <div className="feeds-video">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feeds-head">
                <h6>
                  Live Video
                  <Link
                    to="#"
                    className="btn btn-line me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#filter-modal"
                  >
                    <i className="ti ti-filter" />
                  </Link>
                </h6>
                <ul>
                  <li>
                    <Link to="#" className="active">
                      Mental Health
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Relationship</Link>
                  </li>
                  <li>
                    <Link to="#">Psychotherapy</Link>
                  </li>
                  <li>
                    <Link to="#">Fitness &amp; Exercise</Link>
                  </li>
                  <li>
                    <Link to="#">Mental Health</Link>
                  </li>
                  <li>
                    <Link to="#">Psychotherapy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>
                        Emily Wright <span className="split-line" />{" "}
                        <span className="badge badge-sponsored">Sponsored</span>
                      </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Morning running with sun...</h5>
                      <p>
                        Emily Wright <span className="split-line" />{" "}
                        <span className="badge badge-sponsored">Sponsored</span>
                      </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive d-flex align-items-center justify-content-between">
                    <img src={live01} alt="" />
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>45 min | May 26, 2024</h6>
                      <h5>Sleep Hygiene and Tips</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="join-section">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div
                    className="join-div wow zoomIn"
                    style={{ visibility: "visible", animationName: "zoomIn" }}
                  >
                    <h6>Join TurningWell</h6>
                    <p>
                      Sign up now to get unrestricted access to TurningWell's
                      online mental health directory, resources, and more!
                    </p>
                    <Link to="#" className="btn btn-signup rounded">
                      Sign up now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade modal-subscribe"
        id="subscription-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="subscrition-div">
                    <div className="subscrition-head">
                      <h5>Subscribe or Pay One-Time</h5>
                      <p>To Play Video You Need Subscribe or pay</p>
                      <p>One time fee of $9</p>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="subscrition-content">
                          <h4>Subscribe</h4>
                          <h3>$9.99</h3>
                          <span>Billed Monthly</span>
                          <Link to="#" className="btn btn-subscribe">
                            Subscribe
                          </Link>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="subscrition-content">
                          <h4>One-Time</h4>
                          <h3>$5.00</h3>
                          <span>Per video</span>
                          <Link to="#" className="btn btn-subscribe">
                            Pay Now
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="video-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="slider-video slider-video-modal owl-carousel">
                    <div className="slider-video-div">
                      <div className="live-status">
                        <img src={live} alt="img" />
                        <div className="views">
                          <span>
                            <i className="ti ti-eye me-2" />
                            11.k Views
                          </span>
                        </div>
                      </div>
                      <video className="doctor-treatment-video" controls>
                        <source src={videoMp4} type="video/mp4" />
                      </video>
                    </div>
                    <div className="slider-video-div">
                      <div className="live-status">
                        <img src={live} alt="img" />
                        <div className="views">
                          <span>
                            <i className="ti ti-eye me-2" />
                            11.k Views
                          </span>
                        </div>
                      </div>
                      <video className="doctor-treatment-video" controls>
                        <source src={videoMp4} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="video-information">
                    <div className="video-information-profile">
                      <div className="video-information-profile-img">
                        <img
                          src={avatar03}
                          alt="img"
                        />
                        <span className="online-stataus" />
                      </div>
                      <div className="video-information-profile-content">
                        <h4>
                          Jake Reynolds, ND{" "}
                          <i className="ti ti-rosette-discount-check ms-2" />
                        </h4>
                        <h5>Naturopathic Medicine</h5>
                        <h6>Portland, Oregon</h6>
                      </div>
                    </div>
                    <div className="video-information-data">
                      <ul>
                        <li>
                          <span>
                            <i className="ti ti-video me-2" />
                            40
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-news me-2" />
                            65
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-wifi me-2" />
                            101
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-heart me-2" />
                            1.2k
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-clock-hour-9 me-2" />
                            212min
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-content">
                      <h6>Understanding Mental Health.</h6>
                      <p>
                        Understanding Mental Health explores mental well-being,
                        common disorders, and effective strategies for
                        maintaining a balanced, healthy mind.
                      </p>
                    </div>
                    <div className="video-information-favorite">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="ti ti-heart me-2" />
                            Add to favorite
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-share me-2" />
                            Share
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-cartegory">
                      <h5>
                        Category: <span>Fitness &amp; Exercise</span>
                      </h5>
                      <ul>
                        <li>
                          <Link to="#">Mental Health</Link>
                        </li>
                        <li>
                          <Link to="#">Well-being</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade filter-modal"
        id="filter-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select the Filter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div">
                    <h6>Filter By</h6>
                    <ul className="filter-select">
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Men Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Women Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Non-Binary Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Transgender Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Virtual Appointments Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          In-Person Appointments Only
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-div">
                    <h6>Pick Date</h6>
                    <div className="row">
                      <div className="col-lg-3 col-12">
                        <div className="mb-3">
                          <div className="addon">
                            <input
                              type="text"
                              className="form-control datepicker"
                              placeholder="May 14, 2024"
                            />
                            <span>
                              <i className="ti ti-chevron-down" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="addon">
                          <input
                            type="text"
                            className="form-control datepicker"
                            placeholder="May 14, 2024"
                          />
                          <span>
                            <i className="ti ti-chevron-down" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6>Filter by length</h6>
                    <div className="row">
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html" defaultChecked />
                          <label htmlFor="html">0-5 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="htmls" />
                          <label htmlFor="htmls">5-10 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html1" />
                          <label htmlFor="html1">10-20 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html2" />
                          <label htmlFor="html2">20+ minutes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-btn">
                    <ul>
                      <li>
                        <Link to="#" className="btn btn-primary rounded">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="clear-link">
                          Clear Filter
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FilteredCountryFeed;
