import React, { useState } from "react";
import { logoWhite } from "../../utils/imagepath";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { Link } from "react-router-dom";


const ProfilePerformance = () => {
  const [rangeText, setRangeText] = useState("");

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <ul className="flex-style">
                  <li>
                    <Link to="#">Analytics</Link>
                  </li>
                  <li>Profile Performance</li>
                </ul>
                <p>
                  Track your profile activity: Calls made and appointments
                  requested. Keep an eye on your performance!
                </p>
              </div>
              <div className="tab-set">
                <nav>
                  <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <button
                      className="nav-link active"
                      id="nav-leads-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-leads"
                      type="button"
                      role="tab"
                      aria-controls="nav-leads"
                      aria-selected="true"
                    >
                      All Leads
                    </button>
                    <button
                      className="nav-link"
                      id="nav-calls-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-calls"
                      type="button"
                      role="tab"
                      aria-controls="nav-calls"
                      aria-selected="false"
                    >
                      Calls
                    </button>
                    <button
                      className="nav-link"
                      id="nav-emails-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#nav-emails"
                      type="button"
                      role="tab"
                      aria-controls="nav-emails"
                      aria-selected="false"
                    >
                      Emails
                    </button>
                  </div>
                </nav>
                <div className="tab-content pt-4 p-0" id="nav-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="nav-leads"
                    role="tabpanel"
                    aria-labelledby="nav-leads-tab"
                  >
                    <div className="custom-card mb-4 p-0">
                      <div className="table-top-blk flex-style table-profile-head">
                        <p>
                          Showing: <span>10 Leads</span>
                        </p>
                        <div className="ms-auto filter-div border-0 m-0 p-0">
                          <div className="d-flex align-items-center flex-wrap row-gap-2">
                            <div className="icon-form">
                              <span className="form-icon">
                                <i className="ti ti-calendar" />
                              </span>
                              <DateRangePicker
                                initialSettings={initialSettings}
                              >
                                <input
                                  type="text"
                                  className="form-control bookingrange"
                                  value={
                                    rangeText ||
                                    `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                                  }
                                  readOnly
                                />
                              </DateRangePicker>
                            </div>
                            <div className="dropdown ms-2">
                              <Link
                                to="#"
                                className="dropdown-toggle "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Filter <i className="ti ti-chevron-down ms-2" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-start">
                                <ul>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Ascending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Descending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Viewed
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Added
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table profile-table">
                          <thead>
                            <tr>
                              <th>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </th>
                              <th>Patient</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Messages</th>
                              <th className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-calls"
                    role="tabpanel"
                    aria-labelledby="nav-calls-tab"
                  >
                    <div className="custom-card mb-4 p-0">
                      <div className="table-top-blk flex-style table-profile-head">
                        <p>
                          Showing: <span>10 Leads</span>
                        </p>
                        <div className="ms-auto filter-div">
                          <div className="d-flex align-items-center flex-wrap row-gap-2">
                            <div className="icon-form">
                              <span className="form-icon">
                                <i className="ti ti-calendar" />
                              </span>
                              <DateRangePicker
                                initialSettings={initialSettings}
                              >
                                <input
                                  type="text"
                                  className="form-control bookingrange"
                                  value={
                                    rangeText ||
                                    `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                                  }
                                  readOnly
                                />
                              </DateRangePicker>
                            </div>
                            <div className="dropdown ms-2">
                              <Link
                                to="#"
                                className="dropdown-toggle "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Filter <i className="ti ti-chevron-down ms-2" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-start">
                                <ul>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Ascending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Descending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Viewed
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Added
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table profile-table">
                          <thead>
                            <tr>
                              <th>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </th>
                              <th>Patient</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Messages</th>
                              <th className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>561-699-4849 call from</p>
                                  <h5>Danny Donovan</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status1">
                                  <i className="ti ti-point-filled me-1" />
                                  Contacted
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="nav-emails"
                    role="tabpanel"
                    aria-labelledby="nav-emails-tab"
                  >
                    <div className="custom-card mb-4 p-0">
                      <div className="table-top-blk flex-style table-profile-head">
                        <p>
                          Showing: <span>10 Leads</span>
                        </p>
                        <div className="ms-auto filter-div">
                          <div className="d-flex align-items-center flex-wrap row-gap-2">
                            <div className="icon-form">
                              <span className="form-icon">
                                <i className="ti ti-calendar" />
                              </span>
                              <DateRangePicker
                                initialSettings={initialSettings}
                              >
                                <input
                                  type="text"
                                  className="form-control bookingrange"
                                  value={
                                    rangeText ||
                                    `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                                  }
                                  readOnly
                                />
                              </DateRangePicker>
                            </div>
                            <div className="dropdown ms-2">
                              <Link
                                to="#"
                                className="dropdown-toggle "
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Filter <i className="ti ti-chevron-down ms-2" />
                              </Link>
                              <div className="dropdown-menu dropdown-menu-start">
                                <ul>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Ascending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Descending
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Viewed
                                    </Link>
                                  </li>
                                  <li>
                                    <Link to="#" className="dropdown-item">
                                      <i className="ti ti-circle-chevron-right me-1" />
                                      Recently Added
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="table-responsive">
                        <table className="table custom-table profile-table">
                          <thead>
                            <tr>
                              <th>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </th>
                              <th>Patient</th>
                              <th>Date</th>
                              <th>Status</th>
                              <th>Messages</th>
                              <th className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Jack Charminder</p>
                                  <h5>Just messaged you</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status3">
                                  <i className="ti ti-point-filled me-1" />
                                  Pending
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <label className="ms-auto custom_check">
                                  <input type="checkbox" />
                                  <span className="checkmark" />
                                </label>
                              </td>
                              <td>
                                <div className="text-style">
                                  <p>Monty Panser</p>
                                  <h5>Request for appointment</h5>
                                </div>
                              </td>
                              <td>May 16,2024</td>
                              <td>
                                <span className="table-badge status2">
                                  <i className="ti ti-point-filled me-1" />
                                  Unable to Reach
                                </span>
                              </td>
                              <td>
                                <ul className="table-lists list-unstyled">
                                  <li>
                                    <i className="ti ti-message-2 me-2" />
                                    Send SMS
                                  </li>
                                  <li>
                                    <i className="ti ti-mail me-2" />
                                    Send E-mail
                                  </li>
                                </ul>
                              </td>
                              <td className="text-end">
                                <div className="flex-style flex-icon flex-view">
                                  <Link to="#">
                                    <i className="ti ti-eye-off" />
                                  </Link>
                                  <Link to="#">
                                    <i className="ti ti-trash" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pagination-div mt-40">
                <Link to="#" className="previous-next-link disabled">
                  <i className="ti ti-chevron-left me-1" />
                  Previous
                </Link>
                <ul>
                  <li>
                    <Link to="#" className="active">
                      1
                    </Link>
                  </li>
                  <li>
                    <Link to="#">2</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">3</Link>
                  </li>
                  <li>
                    <Link to="#">...</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">8</Link>
                  </li>
                  <li className="d-none d-sm-block">
                    <Link to="#">9</Link>
                  </li>
                  <li>
                    <Link to="#">10</Link>
                  </li>
                </ul>
                <Link to="#" className="previous-next-link">
                  Next
                  <i className="ti ti-chevron-right ms-1" />
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default ProfilePerformance;
