import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword, logo } from "../../utils/imagepath";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import {
  setLoginAuthToken,
  setRegisterData,
  setUserDetails,
} from "../../core/redux/commonSlice";
import { routes } from "../../utils/routes/routes";
import { getData, postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { yup } from "../../utils/shared.module";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CardRegister from "./components/stripeCard";
import {
  authLicenseOption,
  capitalizeWords,
} from "../../utils/constants/index.";
import getSymbolFromCurrency from "currency-symbol-map";
import { roleType, socialType } from "../../global";

const CardDetails = () => {
  const stripePromise = loadStripe(
    "pk_test_51Q3akf03ZZDvMIaFNo20nQTP9HMMxn1efFrSq3gkW1hYG4xEtyOiEUbe3jccceAUqIvmrGBzIkzvQOD85kzLK4y100Dth3yuQk"
  );

  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<string | null>(null);
  const registerData = useSelector((state: any) => state.common.registerData);
  const [cardSuccessDetails, setCardSucessDetails] = useState<any>(null);

  const [packages, setPackages] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({});
  const dispatch = useDispatch();
  const getLicenseDetails = (data: any) => {
    if (data?.license === authLicenseOption?.license) {
      // "clinical_role": "role one",
      // "license_number": "lic01",
      // "license_state": "state01",
      // "license_expiration_month": "December",
      // "license_expiration_year": "2025",
      // "credential_check": true,
      return {
        clinical_role: data.clinicalRole || "",
        credential_terms_check: data.acceptTerms || false,
        // other_dropdown: "test dropdown"||"",
        license_number: data.licenseNumber || "",
        license_state: data.licenseState || "",
        license_expiration_month: data.licenseExpMonth || "",
        license_expiration_year: data.licenseExpYear || "",
      };
    } else if (data?.license === authLicenseOption?.pre_License) {
      // "clinical_role": "role one"||"",
      //   "supervisor_name": "subbu"||"",
      //   "supervisor_license_number": "lic01"||"",
      //   "license_state": "state01"||"",
      //   "license_expiration_month": "December"||"",
      //   "license_expiration_year": "2025"||"",
      //   "pre_license_number": "123"||"",
      //   "pre_license_info": true
      return {
        clinical_role: data.clinicalRoleTwo || "",
        supervisor_name: data.supervisorName || "",
        pre_license_info: data.acceptTermsTwo || false,
        pre_license_number: data?.preLicenseNumber,
        supervisor_license_number: data.licenseNumberTwo || "",
        license_state: data.licenseStateTwo || "",
        license_expiration_month: data.licenseExpMonthTwo || "",
        license_expiration_year: data.licenseExpYearTwo || "",
      };
    } else if (data?.license === authLicenseOption?.certified_License) {
      return {
        clinical_role: data.clinicalRoleThree || "",
        alternate_credential_type: data.clinicalType || "",
      };
    }
  };
  let payload: any = {
    username: registerData?.provider?.username || "",
    password: registerData?.provider?.password || "",
    email: registerData?.provider?.email || "",
    first_name: registerData?.locationProvider?.firstName || "",
    last_name: registerData?.locationProvider?.lastName || "",
    user_role: roleType(),
    country: registerData?.provider?.country || "",
    mental_health_role: registerData?.provider?.mentalHealthRole || "",
    title: registerData?.locationProvider?.title || "",
    credential_type: registerData?.locationProvider?.credentials || "",

    phone: registerData?.locationProvider?.phoneNumber || "",
    credential_agree_terms: registerData?.credentialProvider?.license || "",
    license_details: getLicenseDetails(registerData?.credentialProvider) || "",

    // registerData.credentialProvider?.license
    credential_check: registerData?.locationProvider?.reuseCompanyName || false,

    company_name: registerData.locationProvider?.companyName || "",
    primary_address: registerData.locationProvider?.addressName || "",
    address: registerData.locationProvider?.addressName || "",
    latitude: "11.004556",
    longitude: "76.961632",
    // extension: registerData.locationProvider.extension || "",
  };
  if (
    registerData?.google_uid?.isSocialLogin &&
    registerData?.google_uid?.socialType === socialType.google
  ) {
    payload.google_uid = registerData?.google_uid?.google_uid || null;
  }

  const onSubmit = async (data: any) => {
    try {
      if (!data?.stripeCustomerId) {
        return;
      }
      // For Provider Role: Normal Register

      // {
      //     "username":"jacob one",
      //     "password":"123456",
      //     "email": "jacob_prv@yopmail.com",
      //     "first_name":"Jacob",
      //     "last_name":"jeez",
      //     "user_role":"provider",
      //     "country": "India",
      //     "mental_health_role": "",
      //     "title": "provider one",
      //     "credential_type": "test type",
      //     "credential_check": true,
      //     "company_name": "company one",
      //     "primary_address": "no. 7, Bakers street, India",
      //     "phone": "9025500888",
      //     "extension": "test one",
      //     "payment_status": "unpaid",
      //     "license_details": {
      //         "credentail_agree_terms": "agree one",
      //         "clinical_role": "role one",
      //         "other_dropdown": "test dropdown",
      //         "license_number": "lic01",
      //         "license_state": "state01",
      //         "license_expiration_month": "December",
      //         "license_expiration_year": "2025"
      //     }

      // }

      // For Provider role - Google Register/

      // {
      //     "googleToken": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImUwM2E2ODg3",
      //     "password":"123456"
      // }

      showLoader();
      let finalLoad: any = payload || {};
      finalLoad.stripe_customer_id = data?.stripeCustomerId || "";
      finalLoad.stripe_subscription_id = data?.stripeSubscriptionId || "";
      const response = await postData(end_points.authRegisterApi.url, payload);
      if (response?.data?.status === 201) {
        const res: any = response?.data?.data;
        dispatch(setLoginAuthToken(res?.token));
        dispatch(setUserDetails(res?.user_details));
        localStorage.setItem("token", res.token);
        setTimeout(() => {
          res?.token && navigate(routes.thankYou);
        }, 200);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const item: any = registerData?.selectedPlan || {};
  return (
    <>
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap p-4">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body pt-4">
                <div className="login-title">
                  <h4>Card Information</h4>
                  <p>
                    Include just one credential here so we can verify you.
                    Later, you will be able to add more.
                  </p>
                </div>
                {/* Login Form */}
                <div className="form-set">
                  <div className="account-plan-blk text-center">
                    <div className="flex-plan-info align-between">
                      <div className="left-info text-start">
                        <h5>{capitalizeWords(item?.name)}</h5>
                        <p>{item?.product_description}</p>
                      </div>
                      <div
                        className={`right-info ${item?.most_popuplar ? "text-end" : ""}`}
                      >
                        {item?.most_popuplar && (
                          <span className="plan-badge">Most Popular</span>
                        )}
                        <h3>
                          {getSymbolFromCurrency(item?.currency?.toUpperCase())}
                          {item?.amount || 0}
                          <span>/{item?.recurring_interval}</span>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <Elements stripe={stripePromise}>
                    <CardRegister
                      setCardSucessDetails={setCardSucessDetails}
                      onSubmit={onSubmit}
                      payload={payload}
                    />
                  </Elements>
                </div>
              </div>
              <div className="progress-blk five-steps align-center mt-3">
                <ul className="list-unstyled">
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="active">
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={forgotPassword} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default CardDetails;
