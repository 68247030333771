import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { viewIcon1, viewIcon11 } from "../../../../utils/imagepath";
import { getData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
const insideList = (name = "", data = []) => {
  if (Array.isArray(data) && data?.length > 0) {
    return (
      <div className="view-special-grid">
        <h6>{name}</h6>
        <ul className="nav">
          {data?.map((item: any) => {
            return (
              <li key={item}>
                {" "}
                <Link to="#">{item}</Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  } else return null;
};
const TreatmentModalities = () => {
  const [list, setList] = useState<any>([]);

  const getList = async () => {
    const response = await getData(end_points.treatmentModalityListApi.url);
    if (response.status === 200) {
      setList(response.data.data);
    }
  };
  useEffect(() => {
    getList();
  }, []);

  return (
    <div
      className="tab-pane fade"
      id="treatment-tab"
      role="tabpanel"
      aria-labelledby="treatment_tab"
    >
      <div className="row">
        <div className="col-md-12">
          <div className="card view-special-card">
            {/* <div className="card-body">
              <h4 className="view-therapies-head">
                <img src={viewIcon11} alt="Icon" />
                Therapies
              </h4>
              <div className="view-special-grid view-special-therapies">
                <ul className="nav view-nav-top">
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Cognitive Behavioral
                      Therapy (CBT)
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Cognitive Processing
                      Therapy (CPT)
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Faith-Based Therapies
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Internal Family
                      Systems(IFS)
                    </Link>
                  </li>
                </ul>
                <ul className="nav">
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Faith-Based Therapies
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Internal Family
                      Systems(IFS)
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Motivational Interviewing
                    </Link>
                  </li>
                  <li>
                    <Link to="#">
                      <i className="fas fa-check" /> Cognitive Behavioral
                      Therapy (CBT)
                    </Link>
                  </li>
                </ul>
              </div>
            </div> */}

            <div className="card-body">
              <h4>
                <img src={viewIcon11} alt="Icon" />
                Treatment Modality Specialty, Expertise, or Experience.
              </h4>
              {insideList(
                "Treatment Modality",
                list?.treatment_modality_specialty?.treatment_modality
              )}
              {insideList(
                "Treatment Expertise",
                list?.treatment_modality_specialty?.treatment_expertise
              )}
              {insideList(
                "Treatment Experience",
                list?.treatment_modality_specialty?.treatment_experience
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentModalities;
