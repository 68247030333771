import React from "react";
import { Link } from "react-router-dom";
import { blog23, table01, table02, table03, table04, videoImg16, videoImg17, videoImg18, viewSpeaker } from "../../../../utils/imagepath";

const Announcements = () => {
  return (
    <>
      <div
        className="tab-pane fade"
        id="announcements-tab"
        role="tabpanel"
        aria-labelledby="announcements_tab"
      >
        <div className="container">
          <div className="row annoucment-row">
            <div className="col-lg-8 col-md-12 annoucment-col">
              <div className="view-announcements-head">
                <h2>My Annoucments</h2>
              </div>
              <div className="view-announcements-table">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          Announcements <i className="fas fa-arrow-down" />
                        </th>
                        <th>
                          Date of Event <i className="fas fa-arrow-down" />
                        </th>
                        <th />
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table01}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instruct...</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 19, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table02}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Q&amp;A: COVID-19 Vaccination..</h5>
                              <p>
                                Join our live Q&amp;A session with Dr. Smith to
                                get the latest...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 18, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table03}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Fitness Class: Low-Impact..</h5>
                              <p>
                                Join our live fitness class designed for
                                seniors, featuring..
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 16, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table04}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instructor</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 14, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table03}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Fitness Class: Low-Impact..</h5>
                              <p>
                                Join our live fitness class designed for
                                seniors, featuring..
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 13, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table01}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instruct...</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 9, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table02}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Q&amp;A: COVID-19 Vaccination..</h5>
                              <p>
                                Join our live Q&amp;A session with Dr. Smith to
                                get the latest...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>May 19, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table04}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instructor</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 8, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table03}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Fitness Class: Low-Impact..</h5>
                              <p>
                                Join our live fitness class designed for
                                seniors, featuring..
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 3, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table01}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instruct...</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 9, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table02}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Q&amp;A: COVID-19 Vaccination..</h5>
                              <p>
                                Join our live Q&amp;A session with Dr. Smith to
                                get the latest...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 1, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table01}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instruct...</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 19, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table02}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Q&amp;A: COVID-19 Vaccination..</h5>
                              <p>
                                Join our live Q&amp;A session with Dr. Smith to
                                get the latest...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 18, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table03}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Fitness Class: Low-Impact..</h5>
                              <p>
                                Join our live fitness class designed for
                                seniors, featuring..
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 16, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table04}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instructor</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 14, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table01}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instruct...</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 19, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table02}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Q&amp;A: COVID-19 Vaccination..</h5>
                              <p>
                                Join our live Q&amp;A session with Dr. Smith to
                                get the latest...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 18, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table03}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Fitness Class: Low-Impact..</h5>
                              <p>
                                Join our live fitness class designed for
                                seniors, featuring..
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 16, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div className="media-blk">
                            <div className="img-blk">
                              <img
                                src={table04}
                                className="img-fluid"
                                alt="Table"
                              />
                            </div>
                            <div className="media-content">
                              <h5>Live Yoga Session with Instructor</h5>
                              <p>
                                Join us for a live yoga session led by our
                                instructor Jane Doe...
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>June 14, 2024</td>
                        <td className="text-end">
                          <Link
                            to="#"
                            data-bs-toggle="modal"
                            data-bs-target="#announcements-modal"
                            className="btn"
                          >
                            Read More
                          </Link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12 annoucment-col">
              <div className="view-suggest-videos">
                <h2>Suggested Videos</h2>
                <div className="view-suggest-main">
                  <div className="suggest-videos-info">
                    <Link
                      to="#"
                      className="video-div"
                      data-bs-toggle="modal"
                      data-bs-target="#video-modal"
                    >
                      <div className="video-img">
                        <img
                          src={videoImg16}
                          alt="img"
                          className="video-thumbnail"
                        />
                      </div>
                      <div className="video-content">
                        <div className="video-exclusive" />
                        <div className="video-name">
                          <div className="video-text">
                            <p>45 min | May 11, 2024</p>
                            <h5>Mental health Therapy</h5>
                          </div>
                          <div className="video-play">
                            <span>
                              <i className="ti ti-player-play" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="suggest-videos-info">
                    <Link
                      to="#"
                      className="video-div"
                      data-bs-toggle="modal"
                      data-bs-target="#video-modal"
                    >
                      <div className="video-img">
                        <img
                          src={videoImg17}
                          alt="img"
                          className="video-thumbnail"
                        />
                      </div>
                      <div className="video-content">
                        <div className="video-exclusive">
                          <p>
                            <img
                              src={viewSpeaker}
                              alt="Icon"
                            />
                            <b>Promote Video</b>
                          </p>
                          <span>Exclusive</span>
                        </div>
                        <div className="video-name">
                          <div className="video-text">
                            <p>45 min | May 11, 2024</p>
                            <h5>
                              Understanding Mental Health: A Guide to Well-being
                              and Resilience
                            </h5>
                          </div>
                          <div className="video-play">
                            <span>
                              <i className="ti ti-player-play" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="suggest-videos-info">
                    <Link
                      to="#"
                      className="video-div"
                      data-bs-toggle="modal"
                      data-bs-target="#video-modal"
                    >
                      <div className="video-img">
                        <img
                          src={videoImg18}
                          alt="img"
                          className="video-thumbnail"
                        />
                      </div>
                      <div className="video-content">
                        <div className="video-exclusive">
                          <p>
                            <img
                              src={viewSpeaker}
                              alt="Icon"
                            />
                            <b>Promote Video</b>
                          </p>
                        </div>
                        <div className="video-name">
                          <div className="video-text">
                            <p>45 min | May 11, 2024</p>
                            <h5>
                              Guided Imagery Relaxation: Harnessing the Power of
                              Visualization for Stress Relief and Mental Clarity
                            </h5>
                          </div>
                          <div className="video-play">
                            <span>
                              <i className="ti ti-player-play" />
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
                <h2>Suggested Blogs</h2>
                <div className="view-suggest-blog">
                  <div className="view-blog-slider owl-carousel">
                    <div className="view-blog-card">
                      <div className="view-blog-img">
                        <img
                          src={blog23}
                          className="img-fluid"
                          alt="Blog"
                        />
                      </div>
                      <div className="view-blog-text">
                        <ul className="nav">
                          <li>April 19, 2024</li>
                          <li>Mental</li>
                        </ul>
                        <h5>
                          <Link to="#">
                            Navigating Mental Health Challenges Strategies
                          </Link>
                        </h5>
                        <p>
                          Mental health challenges are a common part of the
                          human experience, affecting millions worldwide.
                          Whether dealing with anxiety.
                        </p>
                      </div>
                    </div>
                    <div className="view-blog-card">
                      <div className="view-blog-img">
                        <img
                          src={blog23}
                          className="img-fluid"
                          alt="Blog"
                        />
                      </div>
                      <div className="view-blog-text">
                        <ul className="nav">
                          <li>April 19, 2024</li>
                          <li>Mental</li>
                        </ul>
                        <h5>
                          <Link to="#">
                            Navigating Mental Health Challenges Strategies
                          </Link>
                        </h5>
                        <p>
                          Mental health challenges are a common part of the
                          human experience, affecting millions worldwide.
                          Whether dealing with anxiety.
                        </p>
                      </div>
                    </div>
                    <div className="view-blog-card">
                      <div className="view-blog-img">
                        <img
                          src={blog23}
                          className="img-fluid"
                          alt="Blog"
                        />
                      </div>
                      <div className="view-blog-text">
                        <ul className="nav">
                          <li>April 19, 2024</li>
                          <li>Mental</li>
                        </ul>
                        <h5>
                          <Link to="#">
                            Navigating Mental Health Challenges Strategies
                          </Link>
                        </h5>
                        <p>
                          Mental health challenges are a common part of the
                          human experience, affecting millions worldwide.
                          Whether dealing with anxiety.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Announcements;
