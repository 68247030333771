import React from "react";

const FeaturedWorldWide = () => {
  return (
    <>
      <div className="feed-sections">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feed-head">
                <h2>TurningWell's Featured Worldwide Feed</h2>
                <div>
                  <ul>
                    <li>
                      <a href="#">
                        Click here to book your featured live stream
                      </a>
                    </li>
                    <li>
                      <a href="#">TurningWell Stream Schedule</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="video-sets">
                <div className="video-settag">
                  <video src="assets/video/video.mp4" controls />
                </div>
                <div className="live-status">
                  <img src="assets/img/live-01.png" alt="img" />
                  <div className="views">
                    <span>
                      <i className="ti ti-eye me-2" />
                      11.1k Views
                    </span>
                  </div>
                </div>
                <div className="video-names">
                  <h3>Understanding Mental Health</h3>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="video-information video-name">
                <div className="video-information-profile">
                  <div className="d-flex align-items-center">
                    <div className="video-information-profile-img">
                      <img src="assets/img/profiles/avatar-02.png" alt="img" />
                    </div>
                    <div className="video-information-profile-content">
                      <h4>John Smith</h4>
                      <h5>210 Subscribe</h5>
                    </div>
                  </div>
                  <div className="video-chat-profile">
                    <ul>
                      <li>
                        <a
                          href="#"
                          data-bs-toggle="modal"
                          data-bs-target="#basic-modal"
                        >
                          <i className="ti ti-message-2" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="ti ti-message-circle" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="ti ti-user" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="ti ti-share" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="video-details">
                  <p>
                    Dr. John Smith is a skilled and dedicated dentist with
                    extensive experience in providing top-quality dental care.
                    With a focus on both preventive and restorative treatments,
                    Dr. Smith offers comprehensive dental services to enhance
                    his patients' oral health and well-being. His practice is
                    committed to using the latest techniques and technology to
                    deliver effective, patient-centered care in a comfortable
                    environment. <a href="#">View More</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="chat-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="chat-header">
                <div className="chat-header-img-name">
                  <div className="chat-header-img">
                    <img src="assets/img/profiles/avatar-01.png" alt="" />
                  </div>
                  <div className="chat-header-name">
                    <h6>
                      Danny <span className="chat-online" />
                    </h6>
                    <h5>Satus goes here</h5>
                  </div>
                </div>
                <div className="chat-header-search">
                  <ul>
                    <li>
                      <a href="#">
                        <i className="ti ti-search" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="ti ti-settings" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="chat-body">
                <div className="chat-body-path">
                  <div className="chat-day">
                    <h6>Monday, June 03, 2024</h6>
                  </div>
                  <div className="chat-paths sender">
                    <h5>
                      <i className="ti ti-checks" />
                      <span>Hey, how have you been feeling lately?</span>
                    </h5>
                    <h6>1 days ago</h6>
                  </div>
                  <div className="chat-paths receiver">
                    <div className="chat-paths-img">
                      <img src="assets/img/profiles/avatar-01.png" alt="" />
                    </div>
                    <div className="ms-3">
                      <div className="chat-paths-content">
                        <h4>John Smith</h4>
                        <h6>Hey, how have you been feeling lately?</h6>
                      </div>
                      <h6>1 days ago</h6>
                    </div>
                  </div>
                  <div className="chat-paths sender">
                    <h5>
                      <i className="ti ti-checks" />
                      <span>Hey, how have you been feeling lately?</span>
                    </h5>
                    <h6>1 days ago</h6>
                  </div>
                  <div className="chat-paths receiver">
                    <div className="chat-paths-img">
                      <img src="assets/img/profiles/avatar-01.png" alt="" />
                    </div>
                    <div className="ms-3">
                      <div className="chat-paths-content">
                        <h4>John Smith</h4>
                        <h6>Hey, how have you been feeling lately?</h6>
                      </div>
                      <h6>1 days ago</h6>
                    </div>
                  </div>
                  <div className="chat-day">
                    <h6>Monday, June 03, 2024</h6>
                  </div>
                  <div className="chat-paths sender">
                    <h5>
                      <i className="ti ti-checks" />
                      <span>Hey, how have you been feeling lately?</span>
                    </h5>
                    <h6>1 days ago</h6>
                  </div>
                  <div className="chat-paths receiver">
                    <div className="chat-paths-img">
                      <img src="assets/img/profiles/avatar-01.png" alt="" />
                    </div>
                    <div className="ms-3">
                      <div className="chat-paths-content">
                        <h4>John Smith</h4>
                        <h6>Hey, how have you been feeling lately?</h6>
                      </div>
                      <h6>1 days ago</h6>
                    </div>
                  </div>
                  <div className="chat-paths sender">
                    <h5>
                      <i className="ti ti-checks" />
                      <span>Hey, how have you been feeling lately?</span>
                    </h5>
                    <h6>1 days ago</h6>
                  </div>
                  <div className="chat-paths receiver">
                    <div className="chat-paths-img">
                      <img src="assets/img/profiles/avatar-01.png" alt="" />
                    </div>
                    <div className="ms-3">
                      <div className="chat-paths-content">
                        <h4>John Smith</h4>
                        <h6>Hey, how have you been feeling lately?</h6>
                      </div>
                      <h6>1 days ago</h6>
                    </div>
                  </div>
                </div>
                <div className="chat-footers">
                  <input type="text" placeholder="Write a message..." />
                  <div className="chat-attachements">
                    <input type="file" />
                    <span>
                      {" "}
                      <i className="ti ti-paperclip" />
                    </span>
                  </div>
                  <div className="chat-send">
                    <ul>
                      <li>
                        <a href="#" className="emoji">
                          <i className="ti ti-mood-happy" />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="cht-sentbtn">
                          <i className="ti ti-send" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="upcoming-events-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h5 className="upcoming-events">View Upcoming Livestream</h5>
            </div>
            <div className="col-lg-6  col-12">
              <div className="datepic mb-4" />
            </div>
            <div className="col-lg-6 col-12">
              <div className="upcoming-events-div">
                <h3>Upcoming events</h3>
                <div className="upcoming-set">
                  <div className="d-flex align-items-center">
                    <div className="upcoming-set-img">
                      <img src="assets/img/profiles/avatar-01.png" alt="" />
                    </div>
                    <div className="upcoming-content">
                      <h5>Stress Management Techniques</h5>
                      <ul>
                        <li>
                          <a href="#">Susan Reynold</a>
                        </li>
                        <li>
                          <span> May 27, 2024 - (11:30AM to 12:30PM)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="upcoming-btn">
                    <a href="#" className="btn btn-primary">
                      Create Account
                    </a>
                  </div>
                </div>
                <div className="upcoming-set">
                  <div className="d-flex align-items-center">
                    <div className="upcoming-set-img">
                      <img src="assets/img/profiles/avatar-02.png" alt="" />
                    </div>
                    <div className="upcoming-content">
                      <h5>Nutrition Tips for a Healthy Lifest...</h5>
                      <ul>
                        <li>
                          <a href="#">Susan Reynold</a>
                        </li>
                        <li>
                          <span> May 27, 2024 - (11:30AM to 12:30PM)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="upcoming-btn">
                    <a href="#" className="btn btn-primary">
                      Subscribe
                    </a>
                  </div>
                </div>
                <div className="upcoming-set">
                  <div className="d-flex align-items-center">
                    <div className="upcoming-set-img">
                      <img src="assets/img/profiles/avatar-03.png" alt="" />
                    </div>
                    <div className="upcoming-content">
                      <h5>Live Yoga Class: Beginner to Advanced</h5>
                      <ul>
                        <li>
                          <a href="#">Susan Reynold</a>
                        </li>
                        <li>
                          <span> May 27, 2024 - (11:30AM to 12:30PM)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="upcoming-btn">
                    <a href="#" className="btn btn-primary">
                      Add To Calendar
                    </a>
                  </div>
                </div>
                <div className="upcoming-set">
                  <div className="d-flex align-items-center">
                    <div className="upcoming-set-img">
                      <img src="assets/img/profiles/avatar-04.png" alt="" />
                    </div>
                    <div className="upcoming-content">
                      <h5>Breathwork and Relaxation</h5>
                      <ul>
                        <li>
                          <a href="#">Susan Reynold</a>
                        </li>
                        <li>
                          <span> May 27, 2024 - (11:30AM to 12:30PM)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="upcoming-btn">
                    <a href="#" className="btn btn-primary">
                      Create Account
                    </a>
                  </div>
                </div>
                <div className="upcoming-set">
                  <div className="d-flex align-items-center">
                    <div className="upcoming-set-img">
                      <img src="assets/img/profiles/avatar-06.png" alt="" />
                    </div>
                    <div className="upcoming-content">
                      <h5>Stress Management Techniques</h5>
                      <ul>
                        <li>
                          <a href="#">Susan Reynold</a>
                        </li>
                        <li>
                          <span> May 27, 2024 - (11:30AM to 12:30PM)</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="upcoming-btn">
                    <a href="#" className="btn btn-primary">
                      Subscribe
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="join-div wow zoomIn"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <h6>Join TurningWell</h6>
                <p>
                  Sign up now to get unrestricted access to TurningWell's online
                  mental health directory, resources, and more!
                </p>
                <a href="#" className="btn btn-signup rounded">
                  Sign up now
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade basic-modal"
        id="basic-modal"
        tabIndex={-1}
        style={{ display: "none" }}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head w-100">
                <div className="modal-information-profile">
                  <div className="d-flex align-items-center  flex-wrap">
                    <div className="modal-information-profile-img">
                      <img src="assets/img/profiles/avatar-06.png" alt="img" />
                    </div>
                    <div className="modal-information-profile-content">
                      <h4>Email Us</h4>
                      <h5>Dr. Susan Reynolds, MD</h5>
                    </div>
                  </div>
                  <div className="modal-chat-profile">
                    <img src={logo} alt="" />
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn-close modal-chat-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-wrap">
                      <label>Full Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Dr. Susan Reynolds"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-wrap">
                      <label>Email</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Email"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-wrap">
                      <label>Phone</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-wrap">
                      <label>Reason For Contact</label>
                      <select className="select">
                        <option>Select</option>
                        <option>Select</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-wrap">
                      <label>Message</label>
                      <textarea
                        className="form-control"
                        placeholder="Write your message here...."
                        defaultValue={""}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-wrap mb-0">
                  <button
                    className="btn btn-primary rounded w-100"
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="modal-note">
                      <p>
                        <b>Note:</b> Our email will be forwarded to the{" "}
                        <a href="#">Susan Reynolds</a>, and you'll also get a
                        copy for your records. TurningWell doesn't access or
                        keep your emails. Keep in mind, email isn't always
                        secure. Also, sending an email through this page doesn't
                        ensure that it will be received, read, or replied to, as
                        spam filters might block it.
                      </p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FeaturedWorldWide;
