export const routes = {
  // provider
  providerIndex: "/provider/index",
  addVideo: "/provider/add-video",
  analytics: "/provider/analytics",
  financialPerformance: "/provider/financial-performance",
  announcements: "/provider/announcements",
  addAnnouncements: "/provider/add-announcements",
  editAnnouncements: "/provider/edit-announcements",
  manageVideos: "/provider/manage-videos",
  notifications: "/provider/notifications",
  profilePerformance: "/provider/profile-performance",
  providerProfile: "/provider/provider-profile",
  review: "/provider/review",
  videoPerformance: "/provider/video-performance",
  writtenContent: "/provider/written-content",
  writtenPerformance: "/provider/written-performance",
  goLive: "/provider/go-live",
  calendar: "/provider/calendar",
  earning: "/provider/earning",
  goLiveNow: "/provider/golive-now",
  featuredWordWideFeed: "provider/featured-world-wide-feed",
  scheduleStream: "/provider/schedule-stream",
  settings: "/provider/settings",
  events: "/provider/events",
  embedWidget: "/provider/embed-widget",
  directory: "/provider/directory",
  billing: "/provider/billing-details",
  scheduleWorldwideStream: "/provider/schedule-worldwide-stream",

  // authentication
  billingProvider: "/provider/billing",
  credentialsProvider: "/provider/credentials",
  forgotPassword: "/provider/forgot-password",
  locationProvider: "/provider/location",
  providerLogin: "/provider/login",
  registerProvider: "/provider/register",
  thankYou: "/provider/thank-you",
  verifyAccount: "/provider/verify-account",
  resetPassword: "/provider/reset-password/:id?",
  verifyEmail: "/provider/verify-email/:id?",
  addEvents: "/provider/add-events",
  cardIndex: "/provider/card",
  registerClient: "/provider/register-client",
  clientLogin: "/client/login",

  // client
  clientIndex: "/client/index",
  category: "/client/category",
  group: "/client/group",
  psychopharmacologyVideos: "/client/psychopharmacology-videos",
  subCategory: "/client/subcategory",

  // frontend
  viewProfile: "/view-profile",
  filteredCountryFeed: "/filtered-country-feed",
  search: "/search",
  frontendIndex: "/index",
  singleBlog: "/single-blog",
  searchTag: "/search-tag",
  featuredWorldWide: "/featured-world-wide",

};
