import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import store from "../core/redux/store";
import { routes } from "../utils/routes/routes";

function UnAuthRoute({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const auth = store?.getState()?.common?.token;
    if (auth) navigate(routes.providerIndex);
  }, [location]);
  return children;
}

export default UnAuthRoute;
