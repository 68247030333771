import React from "react";
import { Link } from "react-router-dom";
import { article01, article02, article03, article04, article05, article06, article07, viewIcon12 } from "../../../../utils/imagepath";

const Articles = () => {
  return (
    <>
      {" "}
      <div
        className="tab-pane fade"
        id="articles-tab"
        role="tabpanel"
        aria-labelledby="articles_tab"
      >
        <div className="container">
          <div className="view-articles-section">
            <div className="row align-items-center">
              <div className="col-lg-6 col-md-12">
                <div className="view-articles-content">
                  <ul className="nav">
                    <li>Mental</li>
                    <li>Just Posted</li>
                    <li>April 30, 2024</li>
                  </ul>
                  <h4>Understanding Mental Health: A Guide for Beginners</h4>
                  <p>
                    Mental health encompasses emotional, psychological, and
                    social well-being. It affects how we think, feel, and act.
                    Understanding its importance is key to addressing mental
                    health disorders and promoting overall wellness.
                  </p>
                  <Link to="#">
                    <span>Read More</span>{" "}
                    <img
                      src={viewIcon12}
                      alt="Icon"
                    />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="view-articles-img">
                  <img
                    src={article01}
                    className="img-fluid"
                    alt="Article"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="view-latest-articles">
            <h2>Latest Articles</h2>
            <div className="row">
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article02}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Mental</li>
                      </ul>
                      <h5>
                        <span>
                          Navigating Mental Health Challenges Strategies
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article03}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Depression</li>
                      </ul>
                      <h5>
                        <span>
                          Brief overview of Mental Health Awareness Month and
                          its importance.
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article04}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Mental</li>
                      </ul>
                      <h5>
                        <span>
                          Strategies for Managing Stress in the Workplace
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article05}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Mental</li>
                      </ul>
                      <h5>
                        <span>
                          Navigating Mental Health Challenges Strategies
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article06}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Depression</li>
                      </ul>
                      <h5>
                        <span>
                          Brief overview of Mental Health Awareness Month and
                          its importance.
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 d-flex">
                <div className="w-100 view-latest-card">
                  <Link to="#">
                    <div className="view-blog-img">
                      <img
                        src={article07}
                        className="img-fluid"
                        alt="Article"
                      />
                    </div>
                    <div className="view-blog-text">
                      <ul className="nav">
                        <li>April 30, 2024</li>
                        <li>Mental</li>
                      </ul>
                      <h5>
                        <span>
                          Strategies for Managing Stress in the Workplace
                        </span>
                      </h5>
                      <p>
                        An introductory video explaining the basics of mental
                        health, discussing its importance, and dispelling common
                        myths.
                      </p>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Articles;
