import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import {
  SettingsLicenseOption,
  clinicalRoleTypeOption,
  professionOptions,
  selectHereOptions,
  selectOneOptions,
} from "../../../../utils/constants/index.";
import {
  filteredMonthOptions,
  licenseMaxLength,
  licneseNumberPattern,
  monthOptions,
  nameMaxLength,
  onlyAlphabet,
  onlyAplhaNumHyp,
  yearOptions,
} from "../../../../utils/patterns/regex.pattern";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { getData, postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { maxNpiNumber } from "../../../../utils/patterns/regex.pattern";
import ErrorMessage from "../../../../components/errorMessage";
import {
  clinicalRoleSchema,
  isLicenseAvailableSchema,
  otherDropDownSchema,
  preLicenseNumber,
  supervisorLicenseExpMonthSchema,
  supervisorLicenseExpYearSchema,
  supervisorLicenseSchema,
  supervisorLicenseStateSchema,
  supervisorSchema,
  acceptTermsSchema,
  clinicalTypeSchema,
  dropdownSchema,
  licenseExpMonthSchema,
  licenseExpYearSchema,
  licenseNumberSchema,
  licenseStateSchema,
  nameSchema,
} from "../../../authentication/components/yupSchema";
import { yup } from "../../../../utils/shared.module";
import { useSelector } from "react-redux";
import states from "../../../../global/stateList";

interface props {
  userDetails: any;
}

const initialValues = {
  credential_agree_terms: "",
  clinical_role: "",
  alternate_credential_type: "",
  credential_check: true,
  license_number: "",
  license_state: "",
  license_expiration_month: "",
  license_expiration_year: "",
  clinicalRoleTwo: "",
  licenseNumberTwo: "",
  licenseStateTwo: "",
  licenseExpMonthTwo: "",
  licenseExpYearTwo: "",
  supervisorName: "",
  supervisor_name: "",
  supervisor_license_number: "",
  pre_license_number: "",
  pre_license_info: false,
  other_dropdown: "",

  // other: "",
};

const LicensedOptions = [
  {
    value: "i_am_licensed_my_license_information_is_below",
    label: "I am licensed, my license information is below",
  },
  {
    value: "i_am_pre-licensed_or_under_supervision",
    label: "I am pre-licensed or under supervision",
  },
  {
    value: "i_have_a_certification_my_profession_had_no_license",
    label: "I have a certification, My profession had no license",
  },
];

const formSchemaTwo = yup.object({
  supervisor_name: supervisorSchema,
  supervisor_license_number: supervisorLicenseSchema,
  license_state: supervisorLicenseStateSchema,
  license_expiration_month: supervisorLicenseExpMonthSchema,
  license_expiration_year: supervisorLicenseExpYearSchema,
  pre_license_number: preLicenseNumber,
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
});

const formSchema = yup.object({
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
  // dropdown: dropdownSchema,
  license_number: licenseNumberSchema,
  license_state: licenseStateSchema,
  license_expiration_month: licenseExpMonthSchema,
  license_expiration_year: licenseExpYearSchema,
  credential_check: acceptTermsSchema,
});

const formSchemaThree = yup.object({
  credential_agree_terms: isLicenseAvailableSchema,
  clinical_role: clinicalRoleSchema,
  alternate_credential_type: clinicalTypeSchema,
});

const professions = [{ label: "Psychiatrist", value: "Psychiatrist" }];

const PrimaryCredentials = (props: any) => {
  const registerData = useSelector((state: any) => state.common.registerData);
  const licensed =
    registerData?.credentialProvider?.license ||
    "i_am_licensed_my_license_information_is_below";
  const [license, setLicense] = useState(
    licensed ? licensed : SettingsLicenseOption.license
  );
  const getFormSchema: any = (license: string) => {
    if (license === SettingsLicenseOption.license) {
      return formSchema;
    } else if (license === SettingsLicenseOption.pre_License) {
      return formSchemaTwo;
    } else {
      return formSchemaThree;
    }
  };
  const { userDetails, primaryData, GetParentDetails } = props;

  const {
    control,
    formState: { errors },
    register,
    trigger,
    setValue,
    getValues,
    reset,
    watch,
    clearErrors,
  } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(getFormSchema(license)),
  });
  const selectedYear = watch("license_expiration_year");
  const [clinicalRoleOption, setClinicalRoleOption] = useState<any>(null);
  const [isEditable, setIsEditable] = useState(false);

  const clinicalList = async () => {
    try {
      const response = await getData(end_points.masterClinicalRoleApi.url);
      if (response.status === 200) {
        const option = response?.data?.data?.map((val: any) => {
          return { label: val?.name || "", value: val?._id || "" };
        });
        setClinicalRoleOption(option);
      }
    } catch (error) {}
  };
  const getStates = (country: any) => {
    const code =
      typeof country === "string" ? country.toLocaleLowerCase() : country.code;

    return states[code] || [];
  };
  let stateList = getStates(userDetails?.country ? userDetails?.country : "in");
  const stateListOption = stateList?.map((state: any) => {
    return { label: state, value: state };
  });

  const onSubmit = async (data: any) => {
    try {
      let payload = {};
      if (watch("credential_agree_terms") === SettingsLicenseOption?.license) {
        payload = {
          other_dropdown: watch("other_dropdown"),
          license_number: watch("license_number"),
          license_state: watch("license_state"),
          license_expiration_month: watch("license_expiration_month"),
          license_expiration_year: watch("license_expiration_year"),
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          credential_check: watch("credential_check"),
        };
      } else if (
        watch("credential_agree_terms") === SettingsLicenseOption?.pre_License
      ) {
        payload = {
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          supervisor_name: watch("supervisor_name"),
          supervisor_license_number: watch("supervisor_license_number"),
          license_state: watch("license_state"),
          license_expiration_month: watch("license_expiration_month"),
          license_expiration_year: watch("license_expiration_year"),
          pre_license_number: watch("pre_license_number"),
          pre_license_info: watch("pre_license_info"),
        };
      } else {
        payload = {
          credential_agree_terms: watch("credential_agree_terms"),
          clinical_role: watch("clinical_role"),
          alternate_credential_type: watch("alternate_credential_type"),
        };
      }
      const response = await putData(
        `${end_points.settings_license.url}`,
        payload
      );
      if (response?.status < 400) {
        GetParentDetails();
        setIsEditable(false);
      }
    } catch (error) {
    } finally {
      reset();
      // showLoader();
      // hideLoader();
    }
  };

  useEffect(() => {
    if (
      primaryData !== undefined &&
      primaryData !== null &&
      Object.keys(primaryData)?.length > 0
    ) {
      reset({ ...initialValues, ...primaryData });
      setLicense(primaryData?.credential_agree_terms);
      setValue("supervisor_license_number", primaryData?.license_number);
    }
  }, [primaryData]);

  useEffect(() => {
    clinicalList();
  }, []);

  return (
    <>
      <div className="custom-card custom-card-info mb-md-0">
        <div className="card-head">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h4>Primary Credentials</h4>
              <p>
                Add details about your new credential below. Your profile won't
                change until we verify your information.
              </p>
            </div>
            <div className="col-md-6 d-flex text-end">
              <div className="cta-blk d-flex align-items-center ms-auto">
                {isEditable && (
                  <Link
                    to="#"
                    className="btn btn-sm-cancel"
                    onClick={() => {
                      reset();
                      setIsEditable(false);
                    }}
                  >
                    Cancel
                  </Link>
                )}
                <Link
                  to="#"
                  className="btn btn-sm-edit"
                  onClick={() => {
                    if (isEditable) {
                      trigger().then((resp) => {
                        if (resp) {
                          onSubmit(getValues());
                        }
                      });
                    } else {
                      setIsEditable(true);
                    }
                  }}
                >
                  {isEditable ? "Save Changes" : "Edit"}{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <form className="inline-form">
          <Controller
            name="credential_agree_terms"
            control={control}
            render={({ field }) => (
              <div className="custom-radio custom-radio-bottom">
                {LicensedOptions?.map((item: any) => (
                  <div className="form-check">
                    <input
                      type="radio"
                      className="form-check-input"
                      id={item?.value}
                      name="optradio"
                      defaultValue={item?.value}
                      value={item?.value}
                      checked={item?.value === field?.value}
                      onChange={(e) => {
                        if (
                          primaryData?.credential_agree_terms !== e.target.value
                        ) {
                          reset(initialValues);
                        } else {
                          reset({ ...initialValues, ...primaryData });
                        }
                        setValue("credential_agree_terms", e.target.value);
                        setLicense(e.target.value);
                        clearErrors();
                      }}
                      disabled={!isEditable}
                    />
                    <label className="form-check-label" htmlFor={item?.value}>
                      {item?.label}
                    </label>
                  </div>
                ))}
              </div>
            )}
          />
          <ErrorMessage value={errors.credential_agree_terms} />
          {license === SettingsLicenseOption.license && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">Clincial Role</label>
                    <Controller
                      name="clinical_role"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={clinicalRoleOption ? clinicalRoleOption : []}
                          onChange={(e) => {
                            field.onChange(e.value);
                            trigger("clinical_role"); // Trigger validation onChange
                          }}
                          onBlur={() => trigger("clinical_role")} // Trigger validation onBlur
                          placeholder="Select One"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.clinical_role} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">License Number</label>
                    <Controller
                      name="license_number"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your license number"
                          {...field}
                          onBlur={() => trigger("license_number")} // Trigger validation onBlur
                          onKeyDown={(event) => {
                            const regex = licneseNumberPattern;
                            if (
                              !regex.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={licenseMaxLength}
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.license_number} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">License State</label>
                    <Controller
                      name="license_state"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={stateListOption}
                          onChange={(e) => {
                            field.onChange(e.value);
                            trigger("license_state");
                          }}
                          onBlur={() => trigger("license_state")} // Trigger validation onBlur
                          placeholder="Select One"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />

                    <ErrorMessage value={errors.license_state} />
                  </div>
                </div>
              </div>
              <div>
                <label className="col-form-label">License Expiration</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-set mb-0">
                      <Controller
                        name="license_expiration_month"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            value={field.value}
                            options={
                              selectedYear
                                ? filteredMonthOptions(Number(selectedYear))
                                : monthOptions
                            }
                            onChange={(e) => {
                              field.onChange(e.value);
                              trigger("license_expiration_month");
                            }}
                            onBlur={() => trigger("license_expiration_month")} // Trigger validation onBlur
                            placeholder="Month"
                            className="select w-100"
                            disabled={!isEditable}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.license_expiration_month} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-set mb-0">
                      <Controller
                        name="license_expiration_year"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            value={field.value}
                            options={yearOptions}
                            onChange={(e) => {
                              field.onChange(e.value);
                              trigger("license_expiration_year");
                            }}
                            onBlur={() => trigger("license_expiration_year")} // Trigger validation onBlur
                            placeholder="Year"
                            className="select w-100"
                            disabled={!isEditable}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.license_expiration_year} />
                    </div>
                  </div>
                  {/* <div className="col-12 mt-4 d-flex align-items-start">
                    <label className="custom_check ps-4">
                      <Controller
                        name="credential_check"
                        control={control}
                        render={({ field }) => (
                          <input
                            title="Terms and Conditions"
                            type="checkbox"
                            {...field}
                            checked={field.value}
                            onBlur={() => trigger("credential_check")}
                            onChange={(e) => {
                              field.onChange(e.target.checked);
                              trigger("credential_check");
                            }}
                          />
                        )}
                      />
                      <span className="checkmark" />
                    </label>
                    <span className="accrpt-set">
                      Accept <Link to="#">Terms and Conditions</Link>
                    </span>
                  </div>
                  <ErrorMessage value={errors.credential_check} /> */}
                </div>
              </div>
            </>
          )}
          {license === SettingsLicenseOption.pre_License && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">Clinical Role</label>
                    <Controller
                      name="clinical_role"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...register("clinical_role")}
                          onSelect={() => trigger("clinical_role")}
                          onBlur={() => {}}
                          value={field.value}
                          onChange={(e) => {
                            setValue("clinical_role", e.value);
                          }}
                          options={clinicalRoleOption ? clinicalRoleOption : []}
                          placeholder="Select"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.clinical_role} />
                  </div>
                </div>
                {/* <div className="col-12">
          <div className="group-input">
            <label className="col-form-label">Other Dropdown</label>
            <Controller
              name="other"
              control={control}
              render={({ field }) => (
                <Dropdown
                  {...register("other")}
                  onSelect={() => trigger("other")}
                  value={field.value}
                  options={selectHereOptions}
                  placeholder="Select"
                  className="select w-100"
                />
              )}
            />
            <ErrorMessage value={errors.other} />
          </div>
        </div> */}
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">Supervisor’s Name</label>
                    <Controller
                      name="other"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Supervisor name"
                          {...register("supervisor_name")}
                          onKeyDown={(event) => {
                            const regex = onlyAlphabet;
                            if (
                              !regex.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={() => trigger("supervisor_name")}
                          maxLength={nameMaxLength}
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.supervisor_name} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">
                      Supervisor’s License Number
                    </label>
                    <Controller
                      name="supervisor_license_number"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          placeholder="Supervisor license number"
                          {...register("supervisor_license_number")}
                          onKeyDown={(event) => {
                            const regex = onlyAplhaNumHyp;
                            if (
                              !regex.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          onBlur={() => trigger("supervisor_license_number")}
                          maxLength={maxNpiNumber}
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.supervisor_license_number} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">
                      Supervisor’s License State
                    </label>
                    <Controller
                      name="license_state"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          {...register("license_state")}
                          onSelect={() => trigger("license_state")}
                          onBlur={() => trigger("license_state")}
                          value={field.value}
                          options={stateListOption}
                          placeholder="Select"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.license_state} />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="col-form-label">
                      Supervisor’s License Expiration
                    </label>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <Controller
                            name="license_expiration_month"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                {...register("license_expiration_month")}
                                onSelect={() =>
                                  trigger("license_expiration_month")
                                }
                                onBlur={() =>
                                  trigger("license_expiration_month")
                                }
                                value={field.value}
                                options={monthOptions}
                                placeholder="Select"
                                className="select w-100"
                                disabled={!isEditable}
                              />
                            )}
                          />
                          <ErrorMessage
                            value={errors.license_expiration_month}
                          />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <Controller
                          name="license_expiration_year"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              {...register("license_expiration_year")}
                              onSelect={() =>
                                trigger("license_expiration_year")
                              }
                              onBlur={() => trigger("license_expiration_year")}
                              value={field.value}
                              options={yearOptions}
                              placeholder="Select"
                              className="select w-100"
                              disabled={!isEditable}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.license_expiration_year} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-border">
                <label className="custom_check w-100">
                  <Controller
                    name="pre_license_info"
                    control={control}
                    render={({ field }) => (
                      <input
                        type="checkbox"
                        checked={field?.value}
                        onChange={(e) => {
                          setValue("pre_license_info", e.target.checked);
                          // trigger("pre_license_number");
                          field.onChange(e.target.checked);
                          setValue("pre_license_number", null);
                          if (e.target.checked && !watch("pre_license_info")) {
                            setValue("pre_license_number", "");
                          }
                          trigger("pre_license_number");
                          trigger("pre_license_info");
                          trigger("pre_license_number");
                        }}
                        disabled={!isEditable}
                      />
                    )}
                  />
                  <span className="checkmark" />
                  Enter my pre-license info
                </label>
                <div className="row">
                  <div className="col-md-12">
                    <div>
                      <label className="col-form-label pt-0">
                        License Number
                      </label>
                      <Controller
                        name="pre_license_number"
                        control={control}
                        render={({ field }) => (
                          <input
                            className="form-control"
                            disabled={!watch("pre_license_info")}
                            {...register("pre_license_number")}
                            onKeyDown={(event) => {
                              const regex = onlyAplhaNumHyp;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            onBlur={() => trigger("pre_license_number")}
                            maxLength={maxNpiNumber}
                            disabled={!isEditable}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.pre_license_number} />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
          {license === SettingsLicenseOption.certified_License && (
            <>
              <div className="row">
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">Clincial Role</label>
                    <Controller
                      name="clinical_role"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={clinicalRoleOption ? clinicalRoleOption : []}
                          onChange={(e) => {
                            field.onChange(e.value);
                            trigger("clinical_role"); // Trigger validation onChange
                          }}
                          onBlur={() => trigger("clinical_role")} // Trigger validation onBlur
                          placeholder="Select One"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.clinical_role} />
                  </div>
                </div>
                <div className="col-12">
                  <div className="group-input">
                    <label className="col-form-label">Credential Type</label>
                    <p className="col-form-label">
                      Choose an alternate Credential, If you have one{" "}
                    </p>
                    <Controller
                      name="alternate_credential_type"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          value={field.value}
                          options={clinicalRoleTypeOption}
                          onChange={(e) => {
                            field.onChange(e.value);
                            trigger("alternate_credential_type"); // Trigger validation onChange
                          }}
                          onBlur={() => trigger("alternate_credential_type")} // Trigger validation onBlur
                          placeholder="Select One"
                          className="select w-100"
                          disabled={!isEditable}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.alternate_credential_type} />
                  </div>
                </div>
                <p>Don't see your certification listed here?</p>
                <p>
                  Please send your contact and certification information to{" "}
                  <Link to="#" className="span-mail">
                    credentialing@turningwell.com
                  </Link>{" "}
                </p>
              </div>
            </>
          )}
        </form>
      </div>
    </>
  );
};
export default PrimaryCredentials;
