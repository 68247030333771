import React, { useState, useEffect, useRef } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

type props = {
  setShowDialogue: () => void;
};

const FullCalendarComponent = ({ setShowDialogue }: props) => {
  const [selectedOption, setSelectedOption] = useState("onsite");
  const [events, setEvents] = useState([
    {
      title: "Meeting",
      start: "2024-10-31T12:30:00",
      textColor: "#000",
    },
  ]);

  const handleCustomButtonClick = () => {
    setShowDialogue(true);
  };

  useEffect(() => {
    console.log(events);
  }, [events]);

  const selectRef = useRef(null);

  // Function to add custom class to event's <td>
  const handleEventDidMount = (info: any) => {
    // Access the element where the event is rendered
    const eventElement = info.el;

    // Find the closest <td> element containing the event
    const eventTdElement = eventElement.closest("td");

    // Add custom class to the <td>
    if (eventTdElement) {
      eventTdElement.classList.add("event-td-custom-class");
    }
  };

  return (
    <>
      <div className="position-relative">
        <select
          ref={selectRef}
          className="custom-select-position"
          value={selectedOption}
          onChange={(e) => setSelectedOption(e.target.value)}
        >
          <option value="onsite">Onsite</option>
          <option value="insite">Insite</option>
        </select>

        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          events={events}
          editable={true}
          selectable={true}
          headerToolbar={{
            left: "title,prev,today,next",
            right: "dayGridMonth,timeGridWeek,timeGridDay,customButton",
          }}
          customButtons={{
            customButton: {
              text: "+ Add Events",
              click: handleCustomButtonClick,
            },
          }}
          eventDidMount={handleEventDidMount} // Hook to add class to event's <td>
          eventContent={(eventInfo) => (
            <div
              style={{
                backgroundColor: eventInfo.event.backgroundColor || "#ffffff",
                color: eventInfo.event.textColor || "#000000",
                padding: "10px",
                borderRadius: "5px",
                width: "100%",
                height: "100%",
                boxSizing: "border-box",
              }}
            >
              {eventInfo.event.title}
            </div>
          )}
        />
      </div>
    </>
  );
};

export default FullCalendarComponent;
