import React, { useState } from "react";
import {
  videoImg13,
  videoImg01,
  videoImg08,
  videoImg09,
  videoImg05,
  videoImg06,
  blog16,
  videoImg04,
  avatar03,
  videoImg11,
  videoImg12,
  avatar04,
  avatar05,
  avatar06,
  videoImg02,
  videoImg03,
  videoImg07,
  live,
  avatar02,
  videoImg10,
  blog17,
  blog18,
  videoMp4,
} from "../../utils/imagepath";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import { Dropdown } from "primereact/dropdown";

const Group = () => {
  const bannerSlider = {
    loop: false,
    margin: 24,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  const videoSlider = {
    loop: true,
    margin: 24,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    autoplay: true,
    animateOut: "slideOutUp",
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 3.8,
      },
    },
  };
  const sliderVideoModal = {
    loop: false,
    margin: 24,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  const [selectedOption, setSelectedOption] = useState(null);

  const options = [
    { label: 'Set Miles Range', value: 'milesRange' },
    { label: 'Set Miles', value: 'setMiles' }
  ];

  return (
    <>
      {" "}
      {/*banner-head*/}
      <div className="banner-head">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-header-content">
                <ul>
                  <li>
                    <Link to="#banner" className="active scroll-link">
                      What is?
                    </Link>
                  </li>
                  <li>
                    <Link to="#categories" className="scroll-link">
                      Categories
                    </Link>
                  </li>
                  <li>
                    <Link to="#video" className="scroll-link">
                      Videos
                    </Link>
                  </li>
                  <li>
                    <Link to="#topics" className="scroll-link">
                      Topics
                    </Link>
                  </li>
                  <li>
                    <Link to="#providers" className="scroll-link">
                      Providers
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*banner-head */}
      {/*banner */}
      <section className="banner-group" id="banner">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="banner-groups-content">
                <ul>
                  <li>
                    <Link to="#" className="active">
                      Psychopharmacology
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Antidepressants</Link>
                  </li>
                  <li>
                    <Link to="#">
                      Selective Serotonin Reuptake Inhibitors (SSRIs)
                    </Link>
                  </li>
                </ul>
                <h1 className="wow fadeInUp" data-wow-delay="0.6s">
                  Selective Serotonin Reuptake Inhibitors (SSRIs)
                </h1>
                <p className="wow fadeInUp" data-wow-delay="0.9s">
                  Selective Serotonin Reuptake Inhibitors (SSRIs) are a class of
                  antidepressants commonly prescribed to treat depression,
                  anxiety disorders, and other mental health conditions. They
                  are known for their effectiveness and relatively favorable
                  side effect profile compared to older antidepressants.
                </p>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div
                className="banner-group-slider  wow zoomIn"
                data-wow-delay="0.3s"
              >
                <div className="banner-slider">
                  <OwlCarousel {...sliderVideoModal}>
                    <div className="video-div video-subscriptions">
                      <div className="video-img">
                        <img
                          src={videoImg10}
                          alt="img"
                          className="video-thumbnail"
                        />
                      </div>
                      <div className="video-content">
                        <div className="video-exclusive"></div>
                        <div className="video-name">
                          <div>
                            <h5>
                              What is Selective Serotonin Reuptake Inhibitors
                              (SSRIs)?
                            </h5>
                            <p>
                              Dr. Susan Reynolds<span>13 min</span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="video-play banner-play-center">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#video-modal"
                        >
                          <i className="ti ti-player-play" />
                        </Link>
                      </div>
                    </div>
                    <div className="video-div video-subscriptions">
                      <div className="video-img">
                        <img
                          src={videoImg13}
                          alt="img"
                          className="video-thumbnail"
                        />
                      </div>
                      <div className="video-player">
                        <video className="doctor-treatment-video" controls>
                          <source
                            src={videoMp4}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="video-content">
                        <div className="video-exclusive"></div>
                        <div className="video-name">
                          <div>
                            <h5>SSRI Mechanism Explained</h5>
                            <p>Dr. Susan Reynolds</p>
                          </div>
                        </div>
                      </div>
                      <div className="video-play banner-play-center">
                        <Link
                          to="#"
                          data-bs-toggle="modal"
                          data-bs-target="#video-modal"
                        >
                          <i className="ti ti-player-play" />
                        </Link>
                      </div>
                    </div>
                  </OwlCarousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/banner */}
      {/*blog video */}
      <section className="blogvideo-section" id="categories">
        <div className="container ">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header">
                <h3>
                  Recommended Selective Serotonin Reuptake Inhibitors (SSRIs)
                  Videos{" "}
                </h3>
                <p>
                  Get helpful resources and receive access to care for your
                  individualized needs.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="slider-service">
          <div className="video-slider wow fadeInUp">
            <OwlCarousel {...videoSlider}>
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>SSRI Mechanism Explained</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Morning running with sun...</h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </OwlCarousel>
          </div>
        </div>
      </section>
      {/*/blogvideo */}
      {/* related blogs */}
      <section className="releated-blogs" id="topics">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header d-flex align-items-center justify-content-between flex-wrap">
                <h3>Related Articles &amp; Blogs</h3>
                <Link to="#">
                  See more <i className="ti ti-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="row wow fadeInUp">
                <div className="col-lg-4 col-12">
                  <div className="blog-div releated-blog">
                    <div className="blog-img ">
                      <img src={blog16} alt="img" />
                    </div>
                    <div className="blog-date">
                      <div className="banner-content">
                        <ul>
                          <li>
                            <Link to="#">Mental</Link>
                          </li>
                          <li>
                            <Link to="#">April 19, 2024</Link>
                          </li>
                          <li>
                            <Link to="#">Jake Reynolds</Link>
                          </li>
                        </ul>
                      </div>
                      <h5>
                        <Link to="#">
                          How Antidepressants Improve Mental Health
                        </Link>{" "}
                      </h5>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available but the majority have suffered alteration in
                        that some injected humour.
                      </p>
                      <Link to="#" className="read-more text-links">
                        Read More <i className="ti ti-arrow-right  ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="blog-div releated-blog">
                    <div className="blog-img ">
                      <img src={blog17} alt="img" />
                    </div>
                    <div className="blog-date">
                      <div className="banner-content">
                        <ul>
                          <li>
                            <Link to="#">Mental</Link>
                          </li>
                          <li>
                            <Link to="#">April 19, 2024</Link>
                          </li>
                          <li>
                            <Link to="#">Jake Reynolds</Link>
                          </li>
                        </ul>
                      </div>
                      <h5>
                        <Link to="#">
                          Comparing Different Antidepressant Classes
                        </Link>{" "}
                      </h5>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available but the majority have suffered alteration in
                        that some injected humour.
                      </p>
                      <Link to="#" className="read-more text-links">
                        Read More <i className="ti ti-arrow-right  ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="blog-div releated-blog">
                    <div className="blog-img ">
                      <img src={blog18} alt="img" />
                    </div>
                    <div className="blog-date">
                      <div className="banner-content">
                        <ul>
                          <li>
                            <Link to="#">Mental</Link>
                          </li>
                          <li>
                            <Link to="#">April 19, 2024</Link>
                          </li>
                          <li>
                            <Link to="#">Jake Reynolds</Link>
                          </li>
                        </ul>
                      </div>
                      <h5>
                        <Link to="#">
                          Managing Side Effects of Antidepressants
                        </Link>{" "}
                      </h5>
                      <p>
                        There are many variations of passages of Lorem Ipsum
                        available but the majority have suffered alteration in
                        that some injected humour.
                      </p>
                      <Link to="#" className="read-more text-links">
                        Read More <i className="ti ti-arrow-right  ms-2" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* related blogs */}
      {/* Subscriptions blogs */}
      <section className="subscriptions-blogs" id="providers">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header d-flex align-items-center justify-content-between flex-wrap">
                <h3>My Subscriptions &amp; Followed Providers</h3>
                <Link to="#">
                  View All
                  <i className="ti ti-arrow-right" />
                </Link>
              </div>
            </div>
            <div className="col-12">
              <div className="row wow fadeInUp">
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg01}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            45 min <span className="split-line" /> May 11, 2024
                          </h6>
                          <h5>SSRI Mechanism Explained</h5>
                          <p>Dr. Susan Reynolds</p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg08}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            16 min <span className="split-line" /> May 24, 2024
                          </h6>
                          <h5>Wellness Routine Planning</h5>
                          <p>Emily Wright </p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg09}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            45 min <span className="split-line" /> May 11, 2024
                          </h6>
                          <h5>Morning running with sun...</h5>
                          <p>Emily Wright </p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg05}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            13 min <span className="split-line" /> May 26, 2024
                          </h6>
                          <h5>Healthy Relationship with your.</h5>
                          <p>John Cheema</p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg06}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            45 min <span className="split-line" /> May 11, 2024
                          </h6>
                          <h5>Mental health Therapy</h5>
                          <p>Dr. Susan Reynolds</p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="col-lg-4 col-12">
                  <Link
                    className="video-div video-subscriptions"
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={videoImg04}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-player">
                      <video className="doctor-treatment-video" controls>
                        <source
                          src={videoMp4}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive"></div>
                      <div className="video-name">
                        <div>
                          <h6>
                            13 min <span className="split-line" /> May 26, 2024
                          </h6>
                          <h5>
                            Healthy relationship with your mind, body, and soul.
                          </h5>
                          <p>John Cheema</p>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* subscriptions blogs */}
      {/* related blogs */}
      <section className="local-blogs">
        <div className="container">
          <div className="row">
            <div className="col-12  wow fadeInUp">
              <div className="section-header d-flex align-items-center justify-content-between flex-wrap">
                <h3>Local Recommendations</h3>
                <div className="d-flex align-items-center ">
                <Dropdown
                    value={selectedOption}
                    options={options}
                    onChange={(e) => setSelectedOption(e.value)}
                    placeholder="Select"
                    className="select client-select"
                  />
                  <Link to="#" className="ms-3">
                    View All
                    <i className="ti ti-arrow-right" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12  wow fadeInUp">
              <div className="row">
                <div className="col-lg-3 col-12">
                  <div className="doctor-profile">
                    <div className="doctor-profile-imgname">
                      <div className="doctor-profile-img">
                        <img src={avatar03} alt="img" />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">Dr. Jake Reynolds</Link>
                        </h4>
                        <span>Sponsored</span>
                        <h5>Clinical Psychologist</h5>
                        <h5>New York, NY</h5>
                        <ul className="rating">
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star" />
                          </li>
                          <li>
                            <span>(4)</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Dr. Jake Reynolds is a clinical psychologist with over
                        10 years of experience in cognitive behavioural therapy.
                      </p>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg11} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg12} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-follow">
                      <Link to="#" className="btn btn-line w-100">
                        Follow
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="doctor-profile">
                    <div className="doctor-profile-imgname">
                      <div className="doctor-profile-img">
                        <img src={avatar04} alt="img" />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">Dr. Emily Wright</Link>
                        </h4>
                        <span>Sponsored</span>
                        <h5>Licensed Therapist</h5>
                        <h5>New York, NY</h5>
                        <ul className="rating">
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star" />
                          </li>
                          <li>
                            <span>(4)</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Emily Wright is a licensed therapist with a focus on
                        family and relationship counselling. She has a
                        background.
                      </p>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg11} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg12} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-follow">
                      <Link to="#" className="btn btn-line w-100">
                        Follow
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="doctor-profile">
                    <div className="doctor-profile-imgname">
                      <div className="doctor-profile-img">
                        <img src={avatar05} alt="img" />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">Dr. Sierra Judkins</Link>
                        </h4>
                        <span>Sponsored</span>
                        <h5>Licensed Therapist</h5>
                        <h5>New York, NY</h5>
                        <ul className="rating">
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star" />
                          </li>
                          <li>
                            <span>(4)</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Sierra Judkins is a licensed therapist with a focus on
                        family and relationship counselling. She has a
                        background.
                      </p>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg11} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg12} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-follow">
                      <Link to="#" className="btn btn-line w-100">
                        Follow
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-12">
                  <div className="doctor-profile">
                    <div className="doctor-profile-imgname">
                      <div className="doctor-profile-img">
                        <img src={avatar06} alt="img" />
                      </div>
                      <div className="doctor-profile-name">
                        <h4>
                          <Link to="#">Dr. John Cheema</Link>
                        </h4>
                        <span>Sponsored</span>
                        <h5>Clinical Psychologist</h5>
                        <h5>New York, NY</h5>
                        <ul className="rating">
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star-filled" />
                          </li>
                          <li>
                            <i className="ti ti-star" />
                          </li>
                          <li>
                            <span>(4)</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="doctor-profile-content">
                      <p>
                        Dr. Jake Reynolds is a clinical psychologist with over
                        10 years of experience in cognitive behavioural therapy.
                      </p>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg11} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor">
                            <img src={videoImg12} alt="img" />
                            <h6>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#video-modal"
                              >
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-follow">
                      <Link to="#" className="btn btn-line w-100">
                        Follow
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* related blogs */}
      {/* related blogs */}
      {/* banner blogs */}
      <section className="banner-set wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-empty-div">
                <h6>Banner</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner blogs */}
      {/* related blogs */}
      <div className="feeds-video" id="video">
        <div className="container">
          <div className="row">
            <div className="col-12 wow fadeInUp">
              <div className="section-header">
                <h3>Psychiatry Videos</h3>
                <h6>Total 115 Results</h6>
              </div>
            </div>
          </div>
          <div className="row wow fadeInUp">
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Morning running with sun...</h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>45 min | May 26, 2024</h6>
                      <h5>Sleep Hygiene and Tips</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-4 col-12">
              <Link
                className="video-div video-subscriptions"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="loader">
                <div className="loader-inner ball-spin-fade-loader">
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                  <div />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner blogs */}
      <section className="subscriptions-blogs wow fadeInUp">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="banner-empty-div">
                <h6>Banner</h6>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* banner blogs */}
      <div className="search-popup js-search-popup ">
        <Link
          to="#"
          className="close-button"
          id="search-close"
          aria-label="Close search"
        >
          <i className="ti ti-x" />
        </Link>
        <div className="popup-inner">
          <div className="inner-container">
            <form className="search-form" id="search-form" action="#">
              <h3>What Are You Looking for?</h3>
              <div className="search-form-box flex">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Type a  Keyword...."
                  id="search-input"
                  aria-label="Type to search"
                  role="searchbox"
                  autoComplete="off"
                />
                <button type="submit" className="search-icon">
                  <i className="ti ti-search" />
                </button>
              </div>
              <h5>Popular Search</h5>
              <ul>
                <li>
                  <Link to="#">Depression </Link>
                </li>
                <li>
                  <Link to="#">blogs </Link>
                </li>
                <li>
                  <Link to="#">Mental </Link>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="video-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="slider-video slider-video-modal">
                    <OwlCarousel {...sliderVideoModal}>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source
                            src={videoMp4}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source
                            src={videoMp4}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="video-information">
                    <div className="video-information-profile">
                      <div className="video-information-profile-img">
                        <img src={avatar02} alt="img" />
                        <span className="online-stataus" />
                      </div>
                      <div className="video-information-profile-content">
                        <h4>
                          Jake Reynolds, ND{" "}
                          <i className="ti ti-rosette-discount-check ms-2" />
                        </h4>
                        <h5>Naturopathic Medicine</h5>
                        <h6>Portland, Oregon</h6>
                      </div>
                    </div>
                    <div className="video-information-data">
                      <ul>
                        <li>
                          <span>
                            <i className="ti ti-video me-2" />
                            40
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-news me-2" />
                            65
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-wifi me-2" />
                            101
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-heart me-2" />
                            1.2k
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-clock-hour-9 me-2" />
                            212min
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-content">
                      <h6>Understanding Mental Health.</h6>
                      <p>
                        Understanding Mental Health explores mental well-being,
                        common disorders, and effective strategies for
                        maintaining a balanced, healthy mind.
                      </p>
                    </div>
                    <div className="video-information-favorite">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="ti ti-heart me-2" />
                            Add to favorite
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-share me-2" />
                            Share
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-cartegory">
                      <h5>
                        Category: <span>Fitness &amp; Exercise</span>
                      </h5>
                      <ul>
                        <li>
                          <Link to="#">Mental Health</Link>
                        </li>
                        <li>
                          <Link to="#">Well-being</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Group;
