import React from "react";
import { logoWhite } from "../../utils/imagepath";

const ProviderFooter = () => {
  return (
    <>
      {" "}
      {/*Footer*/}
      <div className="footer provider-footer">
        <img src={logoWhite} alt="" />
        <h6>© 2024 TurningWell. All rights reserved</h6>
      </div>
      {/* /Footer */}
    </>
  );
};

export default ProviderFooter;
