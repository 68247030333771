import React from "react";
import { Link } from "react-router-dom";
import {
  videoImg01,
  videoImg02,
  videoImg03,
  videoImg04,
  videoImg05,
  videoImg06,
  videoImg07,
  videoImg08,
  videoImg09,
  live,
  avatar03,
  videoMp4,
  filter,
  filterFrontend,
} from "../../utils/imagepath";
import OwlCarousel from "react-owl-carousel";

const PsychopharmacologyVideos = () => {
  const sliderVideoModal = {
    loop: false,
    margin: 24,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  return (
    <>
      {" "}
      <div className="feed-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feeds-div">
                <h6>All Feeds</h6>
                <p>
                  Welcome to the All Feeds section, your comprehensive hub for
                  discovering and watching videos from various providers.
                </p>
                <div className="feed-input">
                  <input type="text" placeholder="Mental health" />
                  <i className="ti ti-search search-icon" />
                  <Link to="#" className="btn btn-primary rounded">
                    <img
                      src={filterFrontend}
                      className="me-2"
                      alt=""
                    />
                    Filter by
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="feeds-video">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feeds-head">
                <h6>
                  Psychopharmacology Feed{" "}
                  <Link
                    to="#"
                    className="btn btn-line me-2"
                    data-bs-toggle="modal"
                    data-bs-target="#filter-modal"
                  >
                    <i className="ti ti-filter" />
                  </Link>
                </h6>
                <ul>
                  <li>
                    <Link to="#" className="active">
                      Mental Health
                    </Link>
                  </li>
                  <li>
                    <Link to="#">Relationship</Link>
                  </li>
                  <li>
                    <Link to="#">Psychotherapy</Link>
                  </li>
                  <li>
                    <Link to="#">Fitness &amp; Exercise</Link>
                  </li>
                  <li>
                    <Link to="#">Mental Health</Link>
                  </li>
                  <li>
                    <Link to="#">Psychotherapy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynolds</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>
                        Emily Wright <span className="split-line" />{" "}
                        <span className="badge badge-sponsored">Sponsored</span>
                      </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg09} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Morning running with sun...</h5>
                      <p>
                        Emily Wright <span className="split-line" />{" "}
                        <span className="badge badge-sponsored">Sponsored</span>
                      </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>45 min | May 26, 2024</h6>
                      <h5>Sleep Hygiene and Tips</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg01} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg02} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive">
                    <span>Exclusive</span>
                  </div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>
                        Emily Wright <span className="split-line" />{" "}
                        <span className="badge badge-sponsored">Sponsored</span>
                      </p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg03} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg04} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>
                        Healthy relationship with your mind, body, and soul.
                      </h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg05} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        13 min <span className="split-line" /> May 26, 2024
                      </h6>
                      <h5>Healthy Relationship with your.</h5>
                      <p>John Cheema</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg06} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        45 min <span className="split-line" /> May 11, 2024
                      </h6>
                      <h5>Mental health Therapy</h5>
                      <p>Dr. Susan Reynold</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg07} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>
                        Morning running with the sun, energizing strides in the
                        dawn's embrace.
                      </h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
            <div className="col-lg-3 col-md-6 col-12">
              <Link
                to="#"
                className="video-div"
                data-bs-toggle="modal"
                data-bs-target="#video-modal"
              >
                <div className="video-img">
                  <img src={videoImg08} alt="img" className="video-thumbnail" />
                </div>
                <div className="video-content">
                  <div className="video-exclusive"></div>
                  <div className="video-name">
                    <div>
                      <h6>
                        16 min <span className="split-line" /> May 24, 2024
                      </h6>
                      <h5>Wellness Routine Planning</h5>
                      <p>Emily Wright</p>
                    </div>
                    <div className="video-play">
                      <span>
                        <i className="ti ti-player-play" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
          <div className="pagination-div">
            <Link to="#" className="previous-next-link disabled">
              <i className="ti ti-chevron-left me-1" />
              Previous
            </Link>
            <ul>
              <li>
                <Link to="#" className="active">
                  1
                </Link>
              </li>
              <li>
                <Link to="#">2</Link>
              </li>
              <li>
                <Link to="#">3</Link>
              </li>
              <li>
                <Link to="#">...</Link>
              </li>
              <li>
                <Link to="#">8</Link>
              </li>
              <li>
                <Link to="#">9</Link>
              </li>
              <li>
                <Link to="#">10</Link>
              </li>
            </ul>
            <Link to="#" className="previous-next-link">
              Next
              <i className="ti ti-chevron-right ms-1" />
            </Link>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="video-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-8 col-12">
                  <div className="slider-video slider-video-modal">
                    <OwlCarousel {...sliderVideoModal}>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source
                            src={videoMp4}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source
                            src={videoMp4}
                            type="video/mp4"
                          />
                        </video>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-4 col-12">
                  <div className="video-information">
                    <div className="video-information-profile">
                      <div className="video-information-profile-img">
                        <img src={avatar03} alt="img" />
                        <span className="online-stataus" />
                      </div>
                      <div className="video-information-profile-content">
                        <h4>
                          Jake Reynolds, ND{" "}
                          <i className="ti ti-rosette-discount-check ms-2" />
                        </h4>
                        <h5>Naturopathic Medicine</h5>
                        <h6>Portland, Oregon</h6>
                      </div>
                    </div>
                    <div className="video-information-data">
                      <ul>
                        <li>
                          <span>
                            <i className="ti ti-video me-2" />
                            40
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-news me-2" />
                            65
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-wifi me-2" />
                            101
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-heart me-2" />
                            1.2k
                          </span>
                        </li>
                        <li>
                          <span>
                            <i className="ti ti-clock-hour-9 me-2" />
                            212min
                          </span>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-content">
                      <h6>Understanding Mental Health.</h6>
                      <p>
                        Understanding Mental Health explores mental well-being,
                        common disorders, and effective strategies for
                        maintaining a balanced, healthy mind.
                      </p>
                    </div>
                    <div className="video-information-favorite">
                      <ul>
                        <li>
                          <Link to="#">
                            <i className="ti ti-heart me-2" />
                            Add to favorite
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-share me-2" />
                            Share
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-cartegory">
                      <h5>
                        Category: <span>Fitness &amp; Exercise</span>
                      </h5>
                      <ul>
                        <li>
                          <Link to="#">Mental Health</Link>
                        </li>
                        <li>
                          <Link to="#">Well-being</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade filter-modal"
        id="filter-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select the Filter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div">
                    <h6>Filter By</h6>
                    <ul className="filter-select">
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Men Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Women Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Non-Binary Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Transgender Providers Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          Virtual Appointments Only
                        </Link>
                      </li>
                      <li>
                        <Link to="#">
                          <i className="ti ti-check me-2" />
                          In-Person Appointments Only
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div className="filter-div">
                    <h6>Pick Date</h6>
                    <div className="row">
                      <div className="col-lg-3 col-12">
                        <div className="mb-3">
                          <div className="addon">
                            <input
                              type="text"
                              className="form-control datepicker"
                              placeholder="May 14, 2024"
                            />
                            <span>
                              <i className="ti ti-chevron-down" />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="addon">
                          <input
                            type="text"
                            className="form-control datepicker"
                            placeholder="May 14, 2024"
                          />
                          <span>
                            <i className="ti ti-chevron-down" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6>Filter by length</h6>
                    <div className="row">
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html" defaultChecked />
                          <label htmlFor="html">0-5 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="htmls" />
                          <label htmlFor="htmls">5-10 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html1" />
                          <label htmlFor="html1">10-20 minutes</label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-12">
                        <div className="filter-checks">
                          <input type="checkbox" id="html2" />
                          <label htmlFor="html2">20+ minutes</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-btn">
                    <ul>
                      <li>
                        <Link to="#" className="btn btn-primary rounded">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="clear-link">
                          Clear Filter
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="search-popup js-search-popup ">
        <Link
          to="#"
          className="close-button"
          id="search-close"
          aria-label="Close search"
        >
          <i className="ti ti-x" />
        </Link>
        <div className="popup-inner">
          <div className="inner-container">
            <form className="search-form" id="search-form" action="#">
              <h3>What Are You Looking for?</h3>
              <div className="search-form-box flex">
                <input
                  type="text"
                  className="search-input"
                  placeholder="Type a  Keyword...."
                  id="search-input"
                  aria-label="Type to search"
                  role="searchbox"
                  autoComplete="off"
                />
                <button type="submit" className="search-icon">
                  <i className="ti ti-search" />
                </button>
              </div>
              <h5>Popular Search</h5>
              <ul>
                <li>
                  <Link to="#">Depression </Link>
                </li>
                <li>
                  <Link to="#">blogs </Link>
                </li>
                <li>
                  <Link to="#">Mental </Link>
                </li>
              </ul>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default PsychopharmacologyVideos;
