import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  accountPerformance,
  advertising,
  announcements,
  avatar01,
  avatarDefault,
  calendar,
  directory,
  home,
  logout,
  notifications,
  reviews,
  settings,
  sidebar,
  videoContent,
  wallet,
  writtenContent,
} from "../../utils/imagepath";
import { routes } from "../../utils/routes/routes";
import { setExpandMenu } from "../../core/redux/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetter } from "../../utils/constants/index.";
import { removeAuthToken } from "../../core/redux/commonSlice";

const ProviderSidebar = () => {
  const [activeMenu, setActiveMenu] = useState<string | null>(null);
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const userDirectoryDetails = useSelector(
    (state: any) => state?.common?.userDirectoryDetails
  );
  const handleMenuClick = (menu: string) => {
    setActiveMenu(activeMenu === menu ? null : menu);
  };
  const dispatch = useDispatch();

  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  const location = useLocation();

  const pathname: any = location?.pathname;

  const removeLoginToken = () => {
    dispatch(removeAuthToken());
  };

  return (
    <>
      {/* Sidebar */}
      <div
        className="sidebar"
        id="sidebar"
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
      >
        <div className="sidebar-inner">
          <div
            id="sidebar-menu"
            className="sidebar-menu d-flex justify-between flex-column"
          >
            <div className="sidebar-menu p-0 w-100">
              <div className="d-none sidebar-search">
                <div className="addon-front">
                  <input type="text" placeholder="Search" />
                  <span>
                    <i className="ti ti-search" />
                  </span>
                </div>
              </div>
              <ul className="menu-top">
                <li>
                  <Link
                    to={routes.providerIndex}
                    className={`${pathname.includes("index") ? "active" : ""}`}
                  >
                    <img src={home} alt="" />
                    <span>Home</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "directory" ? "subdrop" : ""} 
                      ${pathname.includes("directory") ? "active" : ""}`}
                    onClick={() => handleMenuClick("directory")}
                  >
                    <img src={directory} alt="" />
                    <span>Directory</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display: activeMenu === "directory" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.directory}>
                        <i className="ti ti-edit" />
                        Edit Profile
                      </Link>
                    </li>
                    <li>
                      <a href={routes.viewProfile}>
                        <i className="ti ti-eye" />
                        View Profile
                      </a>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "videoContent" ? "subdrop" : ""}
                      ${
                        pathname.includes("add-video") ||
                        pathname.includes("go-live") ||
                        pathname.includes("manage-videos")
                          ? "active"
                          : ""
                      }
                      `}
                    onClick={() => handleMenuClick("videoContent")}
                  >
                    <img src={videoContent} alt="" />
                    <span>Video Content</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display: activeMenu === "videoContent" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.addVideo}>
                        <i className="ti ti-player-play " />
                        Add Video
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.goLive}>
                        <i className="ti ti-access-point" />
                        Go Live (Stream)
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.scheduleWorldwideStream}>
                        <i className="ti ti-clock-24" />
                        Schedule Worldwide Stream
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.manageVideos}>
                        <i className="ti ti-folders" />
                        Manage Video
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "writtenContent" ? "subdrop" : ""}
                      ${pathname.includes("written-content") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("writtenContent")}
                  >
                    <img src={writtenContent} alt="" />
                    <span>Written Content</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display:
                        activeMenu === "writtenContent" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.writtenContent}>
                        <i className="ti ti-pencil" />
                        Submit Content
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="ti ti-file-description" />
                        Submisson Request
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "announcements" ? "subdrop" : ""}
                      ${pathname.includes("announcements") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("announcements")}
                  >
                    <img src={announcements} alt="" />
                    <span>Announcements</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display:
                        activeMenu === "announcements" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.addAnnouncements}>
                        <i className="ti ti-pencil" />
                        Add Announcements
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.announcements}>
                        <i className="ti ti-calendar-time" />
                        View Announcements
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "reviews" ? "subdrop" : ""}
                      ${pathname.includes("reviews") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("reviews")}
                  >
                    <img src={reviews} alt="" />
                    <span>Reviews</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display: activeMenu === "reviews" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.review}>
                        <i className="ti ti-pencil" />
                        My Review
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="ti ti-file-description" />
                        Request Review
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <img src={reviews} alt="" />
                    <span>CEU/CME</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "calendar" ? "subdrop" : ""}
                       ${pathname.includes("calendar") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("calendar")}
                  >
                    <img src={calendar} alt="" />
                    <span>
                      Calendar
                      <span className="menu-arrow" />
                    </span>
                  </Link>
                  <ul
                    style={{
                      display: activeMenu === "calendar" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.calendar}>
                        <i className="ti ti-calendar-month" />
                        My Calendar
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        <i className="ti ti-calendar-time" />
                        Add Events
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "accountPerformance" ? "subdrop" : ""}
                      ${pathname.includes("accountPerformance") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("accountPerformance")}
                  >
                    <img src={accountPerformance} alt="" />
                    <span>Account Performance</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display:
                        activeMenu === "accountPerformance" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to={routes.profilePerformance}>
                        <i className="ti ti-article" />
                        Profile preformance
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.videoPerformance}>
                        <i className="ti ti-player-play" />
                        Video preformance
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.writtenPerformance}>
                        <i className="ti ti-file-description" />
                        Written Content preformance
                      </Link>
                    </li>
                    <li>
                      <Link to={routes.financialPerformance}>
                        <i className="ti ti-calendar-event" />
                        Financial preformance
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to={routes.notifications}>
                    <img src={notifications} alt="" />
                    <span>Notifications</span>
                  </Link>
                </li>
                <li className="submenu">
                  <Link
                    to="#"
                    className={`
                      ${activeMenu === "advertising" ? "subdrop" : ""}
                      ${pathname.includes("advertising") ? "active" : ""}
                      `}
                    onClick={() => handleMenuClick("advertising")}
                  >
                    <img src={advertising} alt="" />
                    <span>Advertising</span>
                    <span className="menu-arrow" />
                  </Link>
                  <ul
                    style={{
                      display: activeMenu === "advertising" ? "block" : "none",
                    }}
                  >
                    <li>
                      <Link to="#">
                        <i className="ti ti-rocket" />
                        Boost
                      </Link>
                    </li>
                  </ul>
                </li>
                <li>
                  <Link to="#">
                    <img src={wallet} alt="" />
                    <span>Wallet</span>
                  </Link>
                </li>
                <li>
                  <Link to={routes?.settings}>
                    <img src={settings} alt="" />
                    <span>Settings</span>
                  </Link>
                </li>
                <li>
                  <div className="featured-avaliable">
                    <h5>
                      New features available!{" "}
                      <Link to="#" className="ms-auto">
                        <i className="ti ti-x" />
                      </Link>
                    </h5>
                    <p>
                      Check your profile's visibility &amp; interactions for
                      improvement.
                    </p>
                    <img src={sidebar} alt="img" />
                  </div>
                </li>
              </ul>
            </div>
            <div className="profile-logout">
              <div className="profile-logout-img">
                <img
                  src={
                    userDirectoryDetails?.profile_image !== undefined &&
                    userDirectoryDetails?.profile_image !== ""
                      ? userDirectoryDetails?.profile_image
                      : avatarDefault
                  }
                  alt="user"
                />
              </div>
              <div className="profile-logout-content">
                <div className="profile-logout-name">
                  <h5>
                    {capitalizeFirstLetter(userDetails?.first_name)}{" "}
                    {capitalizeFirstLetter(userDetails?.last_name)}
                  </h5>
                  <h6>{userDetails?.email}</h6>
                </div>
                <div className="profile-logout-icon">
                  <Link
                    to={routes.providerLogin}
                    onClick={() => {
                      removeLoginToken();
                    }}
                  >
                    <img src={logout} alt="user" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Sidebar */}
    </>
  );
};

export default ProviderSidebar;
