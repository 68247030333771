import React from "react";
import { Link } from "react-router-dom";
import {
  avatar01,
  avatarDefault,
  br,
  es,
  fr,
  ind,
  it,
  logo,
  logoSmall,
  no,
  topheaderNotification,
  topheaderSearch,
  topheaderShare,
  topheaderVideo,
  topheaderWallet,
  us,
  vn,
} from "../../utils/imagepath";
import {
  setExpandMenu,
  setMiniSidebar,
  setMobileSidebar,
} from "../../core/redux/sidebarSlice";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "../../utils/routes/routes";
import { removeAuthToken } from "../../core/redux/commonSlice";
import { capitalizeFirstLetter } from "../../utils/constants/index.";

const ProviderHeader = () => {
  const dispatch = useDispatch();
  const removeLoginToken = () => {
    dispatch(removeAuthToken());
  };
  const miniSidebar = useSelector((state: any) => state.sidebar.miniSidebar);
  const mobileSidebar = useSelector(
    (state: any) => state.sidebar.mobileSidebar
  );
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const userDirectoryDetails = useSelector(
    (state: any) => state?.common?.userDirectoryDetails
  );

  const handleMiniSidebar = () => {
    dispatch(setMiniSidebar(!miniSidebar));
  };
  const handleMobileSidebar = () => {
    dispatch(setMobileSidebar(!mobileSidebar));
  };
  const onMouseEnter = () => {
    dispatch(setExpandMenu(true));
  };
  const onMouseLeave = () => {
    dispatch(setExpandMenu(false));
  };
  return (
    <>
      {" "}
      {/* Header */}
      <div className="header">
        {/* Logo */}
        <div className="header-left active">
          <Link to={routes.providerIndex} className="logo">
            <img src={logo} alt="Logo" />
          </Link>
          <Link to={routes.providerIndex} className="logo-small">
            <img src={logoSmall} alt="Logo" />
          </Link>
          <Link id="toggle_btn" to="#" onClick={handleMiniSidebar}>
            <span className="bar-icon">
              <i className="ti ti-chevron-left" />
            </span>
          </Link>
        </div>
        {/* /Logo */}
        <Link
          onClick={handleMobileSidebar}
          id="mobile_btn"
          className="mobile_btn"
          to="#"
        >
          <span className="bar-icon">
            <span />
            <span />
            <span />
          </span>
        </Link>
        <div className="header-user">
          <div className="nav user-menu">
            {/* Search */}
            <div className="nav-item nav-welcome me-auto">
              <h6>
                Welcome back, {capitalizeFirstLetter(userDetails?.first_name)}{" "}
                {capitalizeFirstLetter(userDetails?.last_name)}!
              </h6>
            </div>
            {/* /Search */}
            <div className="d-flex col-gap-24 align-items-center">
              <div className="head-links">
                <Link to="#" className="tooltips">
                  <img src={topheaderVideo} alt="" />
                  <span className="tooltiptext">Go live</span>
                </Link>
              </div>
              <div className="head-links">
                <Link to="#" className="tooltips">
                  <img src={topheaderWallet} alt="" />
                  <span className="tooltiptext">My Wallet:$22</span>
                </Link>
              </div>
              <div className="head-links">
                <Link to="#" className="tooltips">
                  <img src={topheaderShare} alt="" />
                  <span className="tooltiptext">Share Profile</span>
                </Link>
              </div>
              <div className="head-links">
                <Link to="#" className="tooltips">
                  <img src={topheaderSearch} alt="" />
                  <span className="tooltiptext">Search Profile</span>
                </Link>
              </div>
              <div className="head-links dropdown">
                <Link to="#" className="tooltips" data-bs-toggle="dropdown">
                  <img src={topheaderNotification} alt="" />
                  <span className="tooltiptext">Notifications</span>
                </Link>
                <div className="dropdown-menu dropdown-menu-end notification-dropdown">
                  <div className="topnav-dropdown-header">
                    <h4 className="notification-title">Notifications</h4>
                    <span>3</span>
                  </div>
                  <div className="noti-content">
                    <ul className="notification-list">
                      <li className="notification-message">
                        <div className="notification-content">
                          <div className="media d-flex">
                            <span className="avatar flex-shrink-0">
                              <img src={avatar01} alt="Profile" />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                Please upload medical license
                              </p>
                              <p className="noti-time">about 4 hours ago</p>
                            </div>
                          </div>
                          <div className="notification-link">
                            <Link to="#">Fix Now</Link>
                          </div>
                        </div>
                      </li>
                      <li className="notification-message">
                        <div className="notification-content">
                          <div className="media d-flex">
                            <span className="avatar flex-shrink-0">
                              <img src={avatar01} alt="Profile" />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                Please upload medical license
                              </p>
                              <p className="noti-time">about 4 hours ago</p>
                            </div>
                          </div>
                          <div className="notification-link">
                            <Link to="#">Fix Now</Link>
                          </div>
                        </div>
                      </li>
                      <li className="notification-message">
                        <div className="notification-content">
                          <div className="media d-flex">
                            <span className="avatar flex-shrink-0">
                              <img src={avatar01} alt="Profile" />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                Please upload medical license
                              </p>
                              <p className="noti-time">about 4 hours ago</p>
                            </div>
                          </div>
                          <div className="notification-link">
                            <Link to="#">Fix Now</Link>
                          </div>
                        </div>
                      </li>
                      <li className="notification-message">
                        <div className="notification-content">
                          <div className="media d-flex">
                            <span className="avatar flex-shrink-0">
                              <img src={avatar01} alt="Profile" />
                            </span>
                            <div className="media-body flex-grow-1">
                              <p className="noti-details">
                                Please upload medical license
                              </p>
                              <p className="noti-time">about 4 hours ago</p>
                            </div>
                          </div>
                          <div className="notification-link">
                            <Link to="#">Fix Now</Link>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="topnav-dropdown-footer">
                    <Link to="activities.html" className="view-link">
                      View All Notifications
                    </Link>
                  </div>
                </div>
              </div>
              <div className="flag-dropdown">
                <div className="dropdown">
                  <Link to="#" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="booking-user ms-0">
                      <span className="user-img d-flex align-items-center">
                        <img src={us} alt="user" />
                        <i className="ti ti-chevron-down ms-1" />
                      </span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu p-2">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={us} className="me-2" alt="user" />
                        English
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={es} className="me-2" alt="user" />
                        Spanish
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={br} className="me-2" alt="user" />
                        Brazilian portuguese
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={fr} className="me-2" alt="user" />
                        French
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={it} className="me-2" alt="user" />
                        Italian
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={no} className="me-2" alt="user" />
                        Norweigan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="dropdown">
                <Link to="#" data-bs-toggle="dropdown" aria-expanded="false">
                  <div className="booking-user ">
                    <span className="user-name">
                      {capitalizeFirstLetter(userDetails?.first_name)}{" "}
                      {capitalizeFirstLetter(userDetails?.last_name)}
                    </span>
                    <span className="user-img d-flex align-items-center">
                      <img
                        src={
                          userDirectoryDetails?.profile_image !== undefined &&
                          userDirectoryDetails?.profile_image !== ""
                            ? userDirectoryDetails?.profile_image
                            : avatarDefault
                        }
                        alt="user"
                      />
                      <i className="ti ti-caret-down-filled ms-1" />
                    </span>
                  </div>
                </Link>
                <ul className="dropdown-menu p-2">
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      Switch to Facility Admin
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to={routes?.settings}
                    >
                      Settings
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      Status
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to="#"
                    >
                      Embed Widget
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="dropdown-item d-flex align-items-center"
                      to={routes.providerLogin}
                      onClick={() => {
                        removeLoginToken();
                      }}
                    >
                      Logout
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Mobile Menu */}
        <div className="dropdown mobile-user-menu">
          <Link
            to="#"
            className="nav-link dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="fa fa-ellipsis-v" />
          </Link>
          <div className="dropdown-menu dropdown-menu-end">
            <Link className="dropdown-item" to="profile.html">
              My Profile
            </Link>
            <Link className="dropdown-item" to="profile-settings.html">
              Settings
            </Link>
            <Link className="dropdown-item" to={routes.providerLogin}>
              Logout
            </Link>
          </div>
        </div>
        {/* /Mobile Menu */}
      </div>
      {/* /Header */}
    </>
  );
};

export default ProviderHeader;
