import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logo, registerProvider } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { getData, postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { yup } from "../../utils/shared.module";
import {
  countrySchema,
  emailSchema,
  mentalHealthRoleSchema,
  passwordSchema,
  userNameSchema,
} from "./components/yupSchema";
import {
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyAlphabetwithoutSpace,
} from "../../utils/patterns/regex.pattern";
import ErrorMessage from "../../components/errorMessage";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { routes } from "../../utils/routes/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setRegisterData } from "../../core/redux/commonSlice";
const formSchema = yup.object({
  username: userNameSchema,
  password: passwordSchema,
  email: emailSchema,
  country: countrySchema,
  mentalHealthRole: mentalHealthRoleSchema,
});
const RegisterProvider = () => {
  const dispatch = useDispatch();
  const registerData = useSelector((state: any) => state.common.registerData);

  const [showPassword, setShowPassword] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  // setCountriesOption
  const [countryOption, setCountryOption] = useState<any>(null);
  const [mentalRoleOption, setMentalRoleOption] = useState<any>(null);

  const [selectedOption, setSelectedOption] = useState("");
  const navigate = useNavigate();
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
    setValue,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const togglePasswordVisibility = () => setShowPassword(!showPassword);

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        email: data.email,
      };
      const response = await postData(
        end_points.authEmailCheckApi.url,
        payload
      );
      if (response?.data?.status === 200) {
        const updateData: any = {
          ...registerData,
          provider: {
            ...registerData.provider,
            ...data,
          },
        };
        dispatch(setRegisterData(updateData));
        navigate(routes.locationProvider);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  useEffect(() => {
    if (registerData?.provider) {
      const { username, password, email, country, mentalHealthRole } =
        registerData.provider;
      setValue("country", country);
      setValue("mentalHealthRole", mentalHealthRole);
      setValue("email", email);
      setValue("username", username);
      setValue("password", password);
      trigger(["username", "password", "email", "country", "mentalHealthRole"]);
    }
  }, [registerData?.provider]);
  const CountryList = async () => {
    try {
      const response = await getData(end_points.countriesListApi.url);
      if (response.status === 200) {
        const option = response?.data?.map((val: any) => {
          return { label: val?.name, value: val?.code };
        });
        setCountryOption(option);
        !registerData?.provider?.country && setValue("country", "US");
      }
    } catch (error) {}
  };
  const roleList = async () => {
    try {
      const response = await getData(end_points.masterHealthRoleApi.url);
      if (response.status === 200) {
        const option = response?.data?.data?.map((val: any) => {
          return { label: val?.mental_health_role, value: val?._id };
        });
        setMentalRoleOption(option);
      }
    } catch (error) {}
  };

  useEffect(() => {
    CountryList();
    roleList();
  }, []);
  console.log("country option", countryOption);

  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body ptres-md-0">
                <div className="tab-style mb-3 d-none">
                  <Link to="#">Provider</Link>
                  <Link to="#">Facility</Link>
                </div>
                <div className="login-title">
                  <h4>Getting Started</h4>
                  <p>
                    Please provide your full name, valid email and create strong
                    password.
                  </p>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-set">
                    <label>Username</label>
                    <Controller
                      name="username"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your username"
                          {...field}
                          onChange={(e: any) => {
                            field.onChange(e);
                            trigger("username");
                          }}
                          onKeyDown={(event) => {
                            const regex = onlyAlphabetwithoutSpace;
                            if (
                              !regex.test(event.key) &&
                              event.key !== "Backspace"
                            ) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={nameMaxLength}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.username} />
                  </div>
                  <div className="form-set">
                    <label>Password</label>
                    <div className="pass-group position-relative">
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <input
                            type={showPassword ? "text" : "password"}
                            className="form-control pass-input"
                            placeholder="Enter your password"
                            {...field}
                            onChange={(e: any) => {
                              field.onChange(e);
                              trigger("password");
                            }}
                          />
                        )}
                      />
                      <i
                        className={`toggle-password ti ${showPassword ? "ti-eye" : "ti-eye-closed"}`}
                        onClick={togglePasswordVisibility}
                      />
                    </div>
                    <ErrorMessage value={errors.password} />
                  </div>
                  <div className="form-set">
                    <label>Email</label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your email"
                          {...field}
                          onChange={(e: any) => {
                            field.onChange(e);
                            trigger("email");
                          }}
                          maxLength={emailMaxLength}
                        />
                      )}
                    />
                    <ErrorMessage value={errors.email} />
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="form-set">
                        <label>Country</label>
                        <Controller
                          name="country"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={countryOption ? countryOption : []}
                              onChange={(e) => {
                                console.log(e.value, "e.value");

                                field.onChange(e.value);
                                trigger("country"); // Trigger validation onChange
                              }}
                              filter={countryOption?.length > 0 ? true : false}
                              placeholder="Select Country"
                              className="select w-100"
                              defaultValue={"US"}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.country} />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="form-set mb-0">
                        <label>Mental Health Role</label>
                        <Controller
                          name="mentalHealthRole"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={mentalRoleOption ? mentalRoleOption : []}
                              filter={
                                mentalRoleOption?.length > 0 ? true : false
                              }
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("mentalHealthRole"); // Trigger validation onChange
                              }}
                              placeholder="Select One"
                              className="select w-100"
                            />
                          )}
                        />

                        <ErrorMessage value={errors.mentalHealthRole} />
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Next
                  </button>
                </form>
                {/* /Login Form */}
              </div>
              <div className="progress-blk five-steps align-center mt-3">
                <ul className="list-unstyled">
                  <li className="active">
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={registerProvider} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default RegisterProvider;
