import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { phoneNumberSchema } from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { Dialog } from "primereact/dialog";
import { useEffect, useRef, useState } from "react";
import PhoneInputWithRef from "../../../../utils/phoneInputWithRef";
import { parsePhoneNumberFromString } from "libphonenumber-js";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
}

const PhoneModal = (props: props) => {
  const phoneModalRef = useRef();
  const { designationsList, getDirectoryData } = props;
  const { type, open, data, userDetails } = props.visible;
  const [phoneNumber, setPhoneNumber] = useState("");

  const initialValues: any = {
    phone: "",
  };

  const basicModelValidation = Yup.object().shape({
    phone: phoneNumberSchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
    watch,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(basicModelValidation),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    phoneModalRef.current.click();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        phone: parsePhoneNumberFromString(data?.phone)?.number,
      };

      const response = await putData(
        `${end_points.profile_about_phone.url}`,
        payload
      );

      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data)?.length > 0) {
      setPhoneNumber(
        data?.phone?.includes("+") ? data?.phone : `+${data?.phone}`
      );
      setValue(
        "phone",
        data?.phone?.includes("+") ? data?.phone : `+${data?.phone}`
      );
    }
  }, [data]);

  return (
    <div
      className="modal fade phone-modal finances-modal"
      id="add-phone"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header add-modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Update Phone</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={phoneModalRef}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <label>Phone Number</label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field, fieldState }) => (
                    <>
                      <PhoneInputWithRef
                        {...field}
                        placeholder="Enter your phone number*"
                        value={phoneNumber}
                        onBlur={() => trigger("phone")}
                        onChange={(event: any, country) => {
                          setPhoneNumber(
                            parsePhoneNumberFromString(`+${event}`)?.number
                          );
                          setValue(
                            "phone",
                            parsePhoneNumberFromString(`+${event}`)?.number
                          );
                          trigger("phone");
                        }}
                        inputProps={{
                          name: "phone",
                          required: true,
                          className: `form-control react-tel-input-modal ${fieldState.invalid ? "is-invalid" : ""}`,
                          style: {
                            width: "100%",
                          },
                        }}
                      />
                      {fieldState.invalid && (
                        <p className="text-danger d-inline-flex mt-2">
                          {errors.phone?.message}
                        </p>
                      )}
                    </>
                  )}
                />
                {/* <ErrorMessage value={errors.phone} /> */}
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  {data?.phone !== undefined && data?.phone !== ""
                    ? "Update"
                    : "Save"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PhoneModal;
