import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  avatarDefault,
  blog23,
  educationIcon,
  featuredImg,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  heart3Line,
  licensingIcon,
  live,
  longArrowIcon,
  mailLine,
  mailLine2,
  mapImg,
  moreFill,
  newsLine,
  phoneLine,
  phoneLine2,
  profileTag,
  rfidLine,
  therapiesIcon,
  timeLine,
  userFollow,
  userLine,
  videoImg16,
  videoImg17,
  videoImg18,
  videoMp4,
  videoOnLine,
  vidiconLine,
  viewEditLine,
  viewIcon1,
  viewIcon2,
  viewIcon3,
  viewIcon4,
  viewIcon5,
  viewIcon6,
  viewIcon7,
  viewIcon8,
  viewIcon9,
  viewProfile,
  viewShared,
  viewSpeaker,
} from "../../../utils/imagepath";

import OwlCarousel from "react-owl-carousel";
import { Dropdown } from "primereact/dropdown";
import Progress from "../../../components/progress/progress";
import Specialties from "./tabs/specialties";
import TreatmentModalities from "./tabs/treatment-modalities";
import Video from "./tabs/video";
import Articles from "./tabs/articles";
import Announcements from "./tabs/announcements";
import Events from "./tabs/events";
import Signup from "./modals/signup";
import Ratings from "./modals/ratings";
import Subscribe from "./modals/subscribe";
import Plans from "./modals/plans";
import { getData } from "../../../services/api.service";
import { end_points } from "../../../services/endpoints";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import FrontendHeader from "../../common/frontend-header";

const ViewProfile = () => {
  const gallerySlider = {
    loop: false,
    margin: 12,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    navContainer: ".gallery-nav-control",
    navText: [
      '<i class="fas fa-chevron-left"></i>',
      '<i class="fas fa-chevron-right"></i>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4,
      },
    },
  };
  const viewBlogSlider = {
    loop: false,
    margin: 0,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  const sliderVideo = {
    loop: false,
    margin: 24,
    nav: true,
    dots: false,
    smartSpeed: 2000,
    autoplay: false,
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 1,
      },
      992: {
        items: 1,
      },
      1200: {
        items: 1,
      },
    },
  };
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedRecent, setSelectedRecent] = useState(null);

  const copyLinkToProfile = () => {
    const fullUrl = window.location.href; // Get the full URL of the current page
    console.log(fullUrl, "fullUrl");
    navigator.clipboard.writeText(fullUrl);
    alert("Link copied to clipboard: " + fullUrl);
  };
  const locations = [
    { label: "Location 01", value: "Location 01" },
    { label: "Location 02", value: "Location 02" },
    { label: "Location 03", value: "Location 03" },
  ];

  const recents = [
    { label: "Recent 01", value: "Recent 01" },
    { label: "Recent 02", value: "Recent 02" },
    { label: "Recent 03", value: "Recent 03" },
  ];

  const [data, setData] = useState<any>("");
  const aboutList = async () => {
    try {
      showLoader();
      const response = await getData(end_points.user_about_list.url);
      if (response.data.status === 200) {
        setData(response.data?.data?.[0]);
      }
    } catch (err) {
    } finally {
      hideLoader();
    }
  };
  console.log(data, "data");

  useEffect(() => {
    aboutList();
  }, []);

  const chunkArray = (array: any, chunkSize: any) => {
    console.log(array, "array");

    const result = [];
    for (let i = 0; i < array?.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const insuranceList = (data: any) => {
    const insuranceMethods = data || [];
    const chunkedInsuranceMethods = chunkArray(insuranceMethods, 4);
    return (
      <>
        <div className="row">
          {chunkedInsuranceMethods?.map((chunk: any) => {
            return (
              <>
                <div className="col-md-4">
                  <div className="view-insurances-list">
                    {chunk?.length > 0 &&
                      chunk?.map((item: any, index: any) => (
                        <ul>
                          <li>{item}</li>
                        </ul>
                      ))}
                  </div>
                </div>
              </>
            );
          })}
        </div>
      </>
    );
  };
  return (
    <>
      {" "}
      <FrontendHeader />
      {/* Breadcrumb */}
      <div className="profile-breadcrumb">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="card view-profile-card">
                <div className="card-body">
                  <div className="view-profile-widget">
                    <div className="view-profile-tag">
                      <span>
                        <img src={featuredImg} alt="Tag" />
                      </span>
                    </div>
                    <Link
                      to="#"
                      className="view-profile-link"
                      data-bs-toggle="modal"
                      data-bs-target="#video-modal"
                    >
                      <div className="view-profile-img">
                        <div className="view-big-img">
                          <img
                            src={avatarDefault}
                            className="img-fluid"
                            alt="Profile"
                          />
                          <div className="view-profile-live">
                            <span>
                              <i className="fas fa-circle" /> Live
                            </span>
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="view-profile-details">
                      <div className="view-info-details">
                        <div className="view-profile-left">
                          <div className="view-profile-head">
                            <ul className="nav">
                              <li>
                                <h4>
                                  {data?.title || ""} {data?.fullname || ""},
                                  {data?.credential_type || ""}
                                  <img src={profileTag} alt="Icon" />
                                </h4>
                              </li>
                              <li>
                                <ul className="rating">
                                  <li>
                                    <i className="ti ti-star-filled" />
                                  </li>
                                  <li>
                                    <i className="ti ti-star-filled" />
                                  </li>
                                  <li>
                                    <i className="ti ti-star-filled" />
                                  </li>
                                  <li>
                                    <i className="ti ti-star-filled" />
                                  </li>
                                  <li>
                                    <i className="ti ti-star-filled" />
                                  </li>
                                  <li>
                                    <span>(0 reviews)</span>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </div>
                          <div className="view-profile-psychiatrist">
                            <p>
                              {data?.mental_health_roles_data
                                ?.mental_health_role || ""}
                            </p>
                            <p>
                              {data?.location?.primary_location?.address || ""}
                            </p>
                            <ul className="nav">
                              {data?.availability?.in_person && (
                                <li>
                                  <img src={userLine} alt="Icon" /> In-Person
                                </li>
                              )}
                              {data?.availability?.virtual_consultation && (
                                <li>
                                  <img src={vidiconLine} alt="Icon" /> Virtual
                                  Consultations
                                </li>
                              )}
                              {data?.phone && (
                                <li>
                                  <img src={phoneLine} alt="Icon" />{" "}
                                  {data?.phone}
                                </li>
                              )}
                            </ul>
                          </div>
                        </div>
                        <div className="view-profile-right">
                          <ul className="nav">
                            <li>
                              <Link
                                to="#"
                                className="view-right-link"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                <img src={moreFill} alt="Icon" />
                              </Link>
                              <div className="dropdown-menu">
                                <Link
                                  to="#"
                                  className="dropdown-item"
                                  onClick={() => {
                                    copyLinkToProfile();
                                  }}
                                >
                                  <img src={viewIcon6} alt="Icon" /> Copy Link
                                  to Profile
                                </Link>
                                <Link to="#" className="dropdown-item">
                                  <img src={viewIcon7} alt="Icon" /> Mute
                                </Link>
                                <Link to="#" className="dropdown-item">
                                  <img src={viewIcon8} alt="Icon" /> Block
                                </Link>
                                <Link to="#" className="dropdown-item">
                                  <img src={viewIcon9} alt="Icon" /> Report
                                </Link>
                              </div>
                            </li>
                            <li>
                              <Link
                                to={`mailto:${data?.email}`}
                                className="view-right-link"
                              >
                                <img src={mailLine} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="view-right-link">
                                <img src={userFollow} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#" className="btn btn-primary rounded">
                                Subscribe
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="view-profile-text">
                        <p>
                          {/* Dr. Susan Reynold is a clinical psychologist with over
                          10 years of experience in cognitive behavioral
                          therapy. She specializes in treating anxiety,
                          depression, and trauma-related disorders. */}
                          {data?.availability?.intro || ""}
                        </p>
                      </div>
                      <div className="view-profile-bottom">
                        <ul className="nav">
                          <li className="tooltips">
                            <img src={videoOnLine} alt="Icon" />{" "}
                            {data?.total_video}
                            <span className="tooltiptext">Total Videos</span>
                          </li>
                          <li className="tooltips">
                            <img src={newsLine} alt="Icon" /> {data?.total_blog}
                            <span className="tooltiptext">Total Blogs</span>
                          </li>
                          <li className="tooltips">
                            <img src={rfidLine} alt="Icon" />{" "}
                            {data?.live_stream}
                            <span className="tooltiptext">Total Stream</span>
                          </li>
                          <li className="tooltips">
                            <img src={heart3Line} alt="Icon" />{" "}
                            {data?.liked_video}
                            <span className="tooltiptext">Liked Videos</span>
                          </li>
                          <li className="tooltips">
                            <img src={timeLine} alt="Icon" />{" "}
                            {data?.min_watch || 0}min
                            <span className="tooltiptext">Min Watched</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Breadcrumb */}
      {/* Profile */}
      <div className="view-profile-section">
        <div className="container">
          {/* Profile Tabs */}
          <div className="row">
            <div className="col-md-12">
              <div className="view-profile-tabs">
                <ul className="nav nav-tabs" id="profileTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link active"
                      id="about_tab"
                      data-bs-toggle="tab"
                      to="#about-tab"
                      role="tab"
                      aria-controls="about-tab"
                      aria-selected="true"
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="specialties_tab"
                      data-bs-toggle="tab"
                      to="#specialties-tab"
                      role="tab"
                      aria-controls="specialties-tab"
                      aria-selected="true"
                    >
                      Specialties
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="treatment_tab"
                      data-bs-toggle="tab"
                      to="#treatment-tab"
                      role="tab"
                      aria-controls="treatment-tab"
                      aria-selected="true"
                    >
                      Treatment Modalities
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="video_tab"
                      data-bs-toggle="tab"
                      to="#video-tab"
                      role="tab"
                      aria-controls="video-tab"
                      aria-selected="true"
                    >
                      Video Content
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="articles_tab"
                      data-bs-toggle="tab"
                      to="#articles-tab"
                      role="tab"
                      aria-controls="articles-tab"
                      aria-selected="true"
                    >
                      Articles
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="announcements_tab"
                      data-bs-toggle="tab"
                      to="#announcements-tab"
                      role="tab"
                      aria-controls="announcements-tab"
                      aria-selected="true"
                    >
                      Announcements
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="events_tab"
                      data-bs-toggle="tab"
                      to="#events-tab"
                      role="tab"
                      aria-controls="events-tab"
                      aria-selected="true"
                    >
                      Events
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          {/* /Profile Tabs */}
          {/* Profile Content */}
          <div className="tab-content" id="profileTabContent">
            {/* About */}
            <div
              className="tab-pane fade show active"
              id="about-tab"
              role="tabpanel"
              aria-labelledby="about_tab"
            >
              <div className="row">
                <div className="col-lg-8 col-md-12">
                  <div className="view-about-text">
                    <h4>
                      About {data?.title || ""}
                      {data?.fullname || ""}
                    </h4>
                    <p>{data?.personal_statement || ""}</p>
                  </div>
                  <div className="view-about-info">
                    <h4>
                      <img src={therapiesIcon} alt="Icon" /> Therapies
                    </h4>
                    <div className="view-therapies-list">
                      <ul className="nav">
                        <li>
                          <i className="fas fa-check" /> Cognitive Behavioral
                          Therapy (CBT)
                        </li>
                        <li>
                          <i className="fas fa-check" /> Cognitive Processing
                          Therapy (CPT)
                        </li>
                        <li>
                          <i className="fas fa-check" /> Faith-Based Therapies
                        </li>
                        <li>
                          <i className="fas fa-check" /> Internal Family
                          Systems(IFS)
                        </li>
                        <li>
                          <i className="fas fa-check" /> Motivational
                          Interviewing
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="view-about-info view-education-info">
                    <h4>
                      <img src={educationIcon} alt="Icon" /> Education
                    </h4>
                    <div className="view-therapies-list">
                      {data?.education &&
                        Object.keys(data?.education).length > 0 && (
                          <div className="row">
                            <div className="col-md-8">
                              <ul className="nav">
                                <li>
                                  <span>Education:</span>{" "}
                                  {data?.education?.school || ""}
                                </li>
                                <li>
                                  <span>Year Graduated:</span>{" "}
                                  {data?.education?.year_graduated || ""}
                                </li>
                              </ul>
                            </div>
                            <div className="col-md-4">
                              <ul className="nav">
                                <li>
                                  <span>Degree/Diploma:</span>{" "}
                                  {data?.education?.degree || ""}
                                </li>
                                <li>
                                  <span>Year | Began Practice:</span>{" "}
                                  {data?.education?.year_began_practice || ""}
                                </li>
                              </ul>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="view-about-info">
                    <h4>
                      <img src={licensingIcon} alt="Icon" /> Licensing
                    </h4>
                    <div className="view-therapies-list">
                      <ul className="nav">
                        <li>
                          <i className="fas fa-check" /> Florida, Ohio, Oregon,
                          Wyoming
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="view-about-info view-fees-info">
                    <div className="view-fees-box">
                      <div className="view-fees-left">
                        <h3>Payment Information</h3>
                        <div className="view-therapies-list">
                          <ul className="nav">
                            {data?.finance?.payment_method?.map((item: any) => {
                              return (
                                <li key={item}>
                                  <i className="fas fa-check" /> {item}
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      <div className="view-fees-right">
                        <h3>Fees</h3>
                        <div className="view-therapies-list">
                          <ul className="nav">
                            <li>
                              <span>Individual Session Cost:</span> $
                              {data?.finance?.cost || 0} per session
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-about-info view-insurances-info">
                    <h4>Insurances Accepted</h4>
                    {insuranceList(data?.finance?.insurance_method)}

                    {/* <div className="col-md-4">
                        <div className="view-insurances-list">
                          <ul>
                            <li>UnitedHealthcare</li>
                            <li>LogoName</li>
                            <li>UnitedHealthcare</li>
                            <li>LogoName</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="view-insurances-list">
                          <ul>
                            <li>BlueCross BlueShield</li>
                            <li>LogoName</li>
                            <li>BlueCross BlueShield</li>
                            <li>LogoName</li>
                          </ul>
                        </div>
                      </div> */}
                    <div className="view-insurances-link">
                      <Link to="#">View All</Link>
                    </div>
                  </div>
                  <div className="view-location-info">
                    <div className="view-location-head">
                      <h4>Locations</h4>
                      <div className="view-location-select">
                        <Dropdown
                          id="locationDropdown"
                          className="select w-100"
                          value={selectedLocation}
                          options={locations}
                          onChange={(e) => setSelectedLocation(e.value)}
                          placeholder="Select your location"
                        />
                      </div>
                    </div>
                    <div className="view-location-map">
                      <div className="row">
                        <div className="col-xl-4 col-lg-12 system-col">
                          <div className="healthcare-card">
                            <h6>Memorial Healthcare System</h6>
                            <ul>
                              <li>
                                <img src={phoneLine2} alt="Icon" /> (555)
                                123-4567
                              </li>
                              <li>
                                <img src={mailLine2} alt="Icon" /> Email Me
                              </li>
                            </ul>
                            <p>
                              20801 Biscayne Blvd, Ste 201, Aventura, FL 33180
                            </p>
                            <Link to="#">
                              <span>Get Directions</span>
                              <img src={longArrowIcon} alt="Icon" />
                            </Link>
                          </div>
                        </div>
                        <div className="col-xl-8 col-lg-12">
                          <div className="view-map-card">
                            <img src={mapImg} className="img-fluid" alt="Map" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-gallery-info">
                    <div className="view-gallery-head">
                      <h4>Gallery</h4>
                      <div className="slide-nav-center">
                        <div className="owl-nav gallery-nav-control nav-control" />
                      </div>
                    </div>
                    <div className="gallery-slider">
                      {data?.gallery?.length > 0 && (
                        <OwlCarousel {...gallerySlider}>
                          {data?.gallery?.map((item: any) => {
                            return (
                              <div key={item?.id} className="view-gallery-img">
                                <a
                                  href={item?.url}
                                  className="image-popup"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <img
                                    src={item?.url}
                                    className="img-fluid"
                                    alt="Gallery"
                                  />
                                </a>
                              </div>
                            );
                          })}
                          {/* <div className="view-gallery-img">
                            <Link to={gallery01} className="image-popup">
                              <img
                                src={gallery01}
                                className="img-fluid"
                                alt="Gallery"
                              />
                            </Link>
                          </div> */}
                          {/* <div className="view-gallery-img">
                          <Link to={gallery02} className="image-popup">
                            <img
                              src={gallery02}
                              className="img-fluid"
                              alt="Gallery"
                            />
                          </Link>
                        </div>
                        <div className="view-gallery-img">
                          <Link to={gallery03} className="image-popup">
                            <img
                              src={gallery03}
                              className="img-fluid"
                              alt="Gallery"
                            />
                          </Link>
                        </div>
                        <div className="view-gallery-img">
                          <Link to={gallery04} className="image-popup">
                            <img
                              src={gallery04}
                              className="img-fluid"
                              alt="Gallery"
                            />
                          </Link>
                        </div>
                        <div className="view-gallery-img">
                          <Link to={gallery01} className="image-popup">
                            <img
                              src={gallery01}
                              className="img-fluid"
                              alt="Gallery"
                            />
                          </Link>
                        </div> */}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                  <div className="view-more-info">
                    <div className="view-more-head">
                      <h4>More Informations</h4>
                      <h6>Ages We served</h6>
                    </div>
                    {data?.communities_served?.age.length > 0 && (
                      <ul className="nav">
                        {data?.communities_served?.age?.map(
                          (val: any, index: any) => (
                            <li key={index}>
                              <i className="fas fa-check" /> {val}
                            </li>
                          )
                        )}
                      </ul>
                    )}
                  </div>
                  <div className="view-more-info">
                    <div className="view-more-head">
                      <h6>Languages Spoken</h6>
                    </div>
                    <ul className="nav">
                      <li>
                        <i className="fas fa-check" /> English
                      </li>
                      <li>
                        <i className="fas fa-check" /> Russian
                      </li>
                      <li>
                        <i className="fas fa-check" /> French
                      </li>
                    </ul>
                  </div>
                  <div className="view-more-info">
                    <div className="view-more-head">
                      <h6>Gender Preference</h6>
                    </div>
                    <ul className="nav">
                      <li>
                        <i className="fas fa-check" /> Male
                      </li>
                      <li>
                        <i className="fas fa-check" /> Female
                      </li>
                    </ul>
                  </div>
                  <div className="view-shared-info">
                    <h4>
                      <img src={viewShared} alt="Icon" /> Shared Experience
                    </h4>
                    {data?.shared_experience && (
                      <p>{data?.shared_experience}</p>
                    )}
                  </div>
                  <div className="view-reviews-info">
                    <div className="view-reviews-head">
                      <h4>Reviews</h4>
                      <div className="view-reviews-flex">
                        <div className="view-reviews-input">
                          <Dropdown
                            id="recentDropdown"
                            className="select w-100"
                            value={selectedRecent}
                            options={recents}
                            onChange={(e) => setSelectedRecent(e.value)}
                            placeholder="Most Recent"
                          />
                        </div>
                        <div className="view-reviews-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search here...."
                          />
                        </div>
                        <div className="view-reviews-input">
                          <Link
                            to="#"
                            className="btn btn-primary rounded"
                            class="btn btn-primary rounded" data-bs-toggle="modal" data-bs-target="#signup-modal"
                          >
                            Write A Review <img src={viewEditLine} alt="Icon" />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="view-profile-rating-info">
                          <div className="view-profile-rating">
                            <div className="view-count-start">
                              <h2>4.8</h2>
                              <ul className="rating">
                                <li>
                                  <i className="fas fa-star" />
                                </li>
                                <li>
                                  <i className="fas fa-star" />
                                </li>
                                <li>
                                  <i className="fas fa-star" />
                                </li>
                                <li>
                                  <i className="fas fa-star" />
                                </li>
                                <li>
                                  <i className="fas fa-star-half-stroke" />
                                </li>
                              </ul>
                            </div>
                            <div className="info-end">
                              <p>Insights from Patients</p>
                              <h4>Overall Rating</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-12 col-md-6">
                        <div className="view-profile-rating-info">
                          <div className="view-profile-rating">
                            <div className="view-count-start">
                              <h2>58</h2>
                            </div>
                            <div className="info-end">
                              <p>Here From our Clients</p>
                              <h4>Total Reviews</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="view-reviews-featured">
                    <div className="featured-ratings">
                      <ul className="rating">
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star-half-stroke" />
                        </li>
                      </ul>
                      <div className="featured-rating-tag">
                        <span>Featured</span>
                      </div>
                    </div>
                    <div className="featured-ratings-text">
                      <p>
                        Dr. Reynolds listened to my concerns and created a
                        personalized treatment plan. I felt supported throughout
                        my journey. Highly recommend!
                      </p>
                      <ul className="nav">
                        <li>Less than 2 months ago</li>
                        <li>Lisa</li>
                        <li>Verified patient</li>
                      </ul>
                    </div>
                    <div className="featured-response-provider">
                      <div className="featured-response-inner">
                        <h6>Response from Provider</h6>
                        <p>
                          Thank You Brandon, It was great experience working
                          with you
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="view-reviews-featured">
                    <div className="featured-ratings">
                      <ul className="rating">
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star-half-stroke" />
                        </li>
                      </ul>
                      <div className="featured-rating-tag">
                        <span>Featured</span>
                      </div>
                    </div>
                    <div className="featured-ratings-text">
                      <p>
                        After years of struggling with anxiety, I found relief
                        with Dr. Reynolds' help. She provided the guidance and
                        tools I needed to take control of my life.
                      </p>
                      <ul className="nav">
                        <li>April 1, 2024</li>
                        <li>Tony Starc</li>
                        <li>Verified patient</li>
                      </ul>
                    </div>
                  </div>
                  <div className="view-reviews-featured">
                    <div className="featured-ratings">
                      <ul className="rating">
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star-half-stroke" />
                        </li>
                      </ul>
                      <div className="featured-rating-tag">
                        <span>Featured</span>
                      </div>
                    </div>
                    <div className="featured-ratings-text">
                      <p>
                        Dr. Reynolds has a deep understanding of mental health.
                        Her compassionate approach and expertise made a huge
                        difference in my recovery.
                      </p>
                      <ul className="nav">
                        <li>March 16, 2024</li>
                        <li>Neha Kakar</li>
                        <li>Verified patient</li>
                      </ul>
                    </div>
                  </div>
                  <div className="view-reviews-featured">
                    <div className="featured-ratings">
                      <ul className="rating">
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="far fa-star" />
                        </li>
                        <li>
                          <i className="far fa-star" />
                        </li>
                      </ul>
                    </div>
                    <div className="featured-ratings-text">
                      <p>
                        It's rare to find a psychiatrist who genuinely cares.
                        Dr. Reynolds made me feel comfortable and respected.
                        She's a trustworthy partner in mental health.It's rare
                        to find a psychiatrist who genuinely cares. Dr. Reynolds
                        made me feel comfortable and respected. She's a
                        trustworthy partner in mental health.
                      </p>
                      <ul className="nav">
                        <li>March 24, 2024</li>
                        <li>Stella Smith</li>
                        <li>Verified patient</li>
                      </ul>
                    </div>
                  </div>
                  <div className="view-reviews-featured">
                    <div className="featured-ratings">
                      <ul className="rating">
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="fas fa-star" />
                        </li>
                        <li>
                          <i className="far fa-star" />
                        </li>
                        <li>
                          <i className="far fa-star" />
                        </li>
                        <li>
                          <i className="far fa-star" />
                        </li>
                      </ul>
                    </div>
                    <div className="featured-ratings-text">
                      <p>
                        "I was hesitant about virtual appointments, but Dr.
                        Reynolds made the process seamless. She was attentive
                        and thorough, even through the screen.
                      </p>
                      <ul className="nav">
                        <li>March 3, 2024</li>
                        <li>John Cheema</li>
                        <li>Verified patient</li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <div className="view-suggest-videos">
                    <h2>Suggested Videos</h2>
                    <div className="view-suggest-main">
                      <div className="suggest-videos-info">
                        <Link
                          to="#"
                          className="video-div"
                          data-bs-toggle="modal"
                          data-bs-target="#video-modal"
                        >
                          <div className="video-img">
                            <img
                              src={videoImg16}
                              alt="img"
                              className="video-thumbnail"
                            />
                          </div>
                          <div className="video-content">
                            <div className="video-exclusive" />
                            <div className="video-name">
                              <div className="video-text">
                                <p>45 min | May 11, 2024</p>
                                <h5>Mental health Therapy</h5>
                              </div>
                              <div className="video-play">
                                <span>
                                  <i className="ti ti-player-play" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="suggest-videos-info">
                        <Link
                          to="#"
                          className="video-div"
                          data-bs-toggle="modal"
                          data-bs-target="#video-modal"
                        >
                          <div className="video-img">
                            <img
                              src={videoImg17}
                              alt="img"
                              className="video-thumbnail"
                            />
                          </div>
                          <div className="video-content">
                            <div className="video-exclusive">
                              <p>
                                <img src={viewSpeaker} alt="Icon" />
                                <b>Promote Video</b>
                              </p>
                            </div>
                            <div className="video-name">
                              <div className="video-text">
                                <p>45 min | May 11, 2024</p>
                                <h5>
                                  Understanding Mental Health: A Guide to
                                  Well-being and Resilience
                                </h5>
                              </div>
                              <div className="video-play">
                                <span>
                                  <i className="ti ti-player-play" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                      <div className="suggest-videos-info">
                        <Link
                          to="#"
                          className="video-div"
                          data-bs-toggle="modal"
                          data-bs-target="#video-modal"
                        >
                          <div className="video-img">
                            <img
                              src={videoImg18}
                              alt="img"
                              className="video-thumbnail"
                            />
                          </div>
                          <div className="video-content">
                            <div className="video-exclusive">
                              <p>
                                <img src={viewSpeaker} alt="Icon" />
                                <b>Promote Video</b>
                              </p>
                            </div>
                            <div className="video-name">
                              <div className="video-text">
                                <p>45 min | May 11, 2024</p>
                                <h5>
                                  Guided Imagery Relaxation: Harnessing the
                                  Power of Visualization for Stress Relief and
                                  Mental Clarity
                                </h5>
                              </div>
                              <div className="video-play">
                                <span>
                                  <i className="ti ti-player-play" />
                                </span>
                              </div>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </div>
                    <h2>Suggested Blogs</h2>
                    <div className="view-suggest-blog">
                      <div className="view-blog-slider">
                        <OwlCarousel {...viewBlogSlider}>
                          <div className="view-blog-card">
                            <div className="view-blog-img">
                              <img
                                src={blog23}
                                className="img-fluid"
                                alt="Blog"
                              />
                            </div>
                            <div className="view-blog-text">
                              <ul className="nav">
                                <li>April 19, 2024</li>
                                <li>Mental</li>
                              </ul>
                              <h5>
                                <Link to="#">
                                  Navigating Mental Health Challenges Strategies
                                </Link>
                              </h5>
                              <p>
                                Mental health challenges are a common part of
                                the human experience, affecting millions
                                worldwide. Whether dealing with anxiety.
                              </p>
                            </div>
                          </div>
                          <div className="view-blog-card">
                            <div className="view-blog-img">
                              <img
                                src={blog23}
                                className="img-fluid"
                                alt="Blog"
                              />
                            </div>
                            <div className="view-blog-text">
                              <ul className="nav">
                                <li>April 19, 2024</li>
                                <li>Mental</li>
                              </ul>
                              <h5>
                                <Link to="#">
                                  Navigating Mental Health Challenges Strategies
                                </Link>
                              </h5>
                              <p>
                                Mental health challenges are a common part of
                                the human experience, affecting millions
                                worldwide. Whether dealing with anxiety.
                              </p>
                            </div>
                          </div>
                          <div className="view-blog-card">
                            <div className="view-blog-img">
                              <img
                                src={blog23}
                                className="img-fluid"
                                alt="Blog"
                              />
                            </div>
                            <div className="view-blog-text">
                              <ul className="nav">
                                <li>April 19, 2024</li>
                                <li>Mental</li>
                              </ul>
                              <h5>
                                <Link to="#">
                                  Navigating Mental Health Challenges Strategies
                                </Link>
                              </h5>
                              <p>
                                Mental health challenges are a common part of
                                the human experience, affecting millions
                                worldwide. Whether dealing with anxiety.
                              </p>
                            </div>
                          </div>
                        </OwlCarousel>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /About */}
            {/* Specialties */}
            <Specialties />
            {/* /Specialties */}
            {/* Treatment */}
            <TreatmentModalities />
            {/* /Treatment */}
            {/* Video */}
            <Video />
            {/* /Video */}
            {/* Articles */}
            <Articles />
            {/* /Articles */}
            {/* Announcements */}
            <Announcements />
            {/* /Announcements */}
            {/* Events */}
            <Events />
            {/* /Events */}
          </div>
          {/* /Profile Content */}
        </div>
      </div>
      {/* /Profile */}
      {/* Join */}
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12 m-auto">
              <div className="join-div">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <h6>Join TurningWell</h6>
                    <p>
                      Sign up now to get unrestricted access to TurningWell's
                      online mental health directory, resources, and more!
                    </p>
                    <Link to="#" className="btn btn-signup rounded">
                      Sign up now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/*/Join */}
      {/* Video Modal */}
      <div
        className="modal fade view-video-modal"
        id="video-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-8 col-12 d-flex">
                  <div className="slider-video slider-video-modal w-100">
                    <OwlCarousel {...sliderVideo}>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source src={videoMp4} type="video/mp4" />
                        </video>
                      </div>
                      <div className="slider-video-div">
                        <div className="live-status">
                          <img src={live} alt="img" />
                          <div className="views">
                            <span>
                              <i className="ti ti-eye me-2" />
                              11.k Views
                            </span>
                          </div>
                        </div>
                        <video className="doctor-treatment-video" controls>
                          <source src={videoMp4} type="video/mp4" />
                        </video>
                      </div>
                    </OwlCarousel>
                  </div>
                </div>
                <div className="col-lg-4 col-12 d-flex">
                  <div className="video-information w-100">
                    <div className="video-information-profile">
                      <div className="video-information-profile-img">
                        <img src={avatarDefault} alt="img" />
                        <span className="online-stataus" />
                      </div>
                      <div className="video-information-profile-content">
                        <h4>
                          Dr. Susan Reynolds
                          <img src={profileTag} alt="Icon" />
                        </h4>
                        <h5>Naturopathic Medicine</h5>
                        <h6>Portland, Oregon</h6>
                      </div>
                    </div>
                    <div className="video-information-data">
                      <ul>
                        <li className="tooltips">
                          <img src={viewIcon1} alt="Icon" /> 40
                          <span className="tooltiptext">Total Videos</span>
                        </li>
                        <li className="tooltips">
                          <img src={viewIcon2} alt="Icon" /> 65
                          <span className="tooltiptext">Total Blogs</span>
                        </li>
                        <li className="tooltips">
                          <img src={viewIcon3} alt="Icon" /> 100
                          <span className="tooltiptext">Total Stream</span>
                        </li>
                        <li className="tooltips">
                          <img src={viewIcon4} alt="Icon" /> 1.2k
                          <span className="tooltiptext">Liked Videos</span>
                        </li>
                        <li className="tooltips">
                          <img src={viewIcon5} alt="Icon" /> 212min
                          <span className="tooltiptext">Min Watched</span>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-content">
                      <h6>Understanding Mental Health.</h6>
                      <p>
                        Understanding Mental Health explores mental well-being,
                        common disorders, and effective strategies for
                        maintaining a balanced, healthy mind.
                      </p>
                    </div>
                    <div className="video-information-favorite">
                      <ul>
                        <li>
                          <Link to="#" className="fav-icon">
                            <i className="ti ti-heart" />
                            Add to favorite
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-share" />
                            Share
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div className="video-information-cartegory">
                      <h5>
                        Category: <span>Fitness &amp; Exercise</span>
                      </h5>
                      <ul>
                        <li>
                          <Link to="#">Mental Health</Link>
                        </li>
                        <li>
                          <Link to="#">Well-being</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                        <li>
                          <Link to="#">Anxiety</Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Video Modal */}
      {/* Signup Modal */}
      <Signup />
      {/* /Signup Modal */}
      {/* Ratings Modal */}
      <Ratings />
      {/* /Ratings Modal */}
      {/* Subscribe Modal */}
      <Subscribe />
      {/* /Subscribe Modal */}
      {/* Plans Modal */}
      <Plans />
      {/* /plans Modal */}
      <Progress />
    </>
  );
};

export default ViewProfile;
