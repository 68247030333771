import React, { useState } from "react";
import { Link } from "react-router-dom";
import { autoGenerate, uploadFile } from "../../../utils/imagepath";
import { Chips } from "primereact/chips";
import Footer from "../../common/provider-footer";

const EditAnnouncements = () => {
  const [value, setValue] = useState(["Live stream", "Job", "Cancellation"]);

  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>
                      <Link to="#">Announcements</Link>
                    </li>
                    <li>Edit Announcements</li>
                  </ul>
                </div>
                <p>
                  Lets you easily upload and organize your video content in a
                  streamlined interface.
                </p>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="custom-card mb-3 mb-md-0">
                    <h3 className="title-h3">Edit Announcements</h3>
                    <form>
                      <div className="row">
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <input
                              type="text"
                              defaultValue="Live Webinar: Managing Stress and Anxiety |"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="mb-3">
                            <label className="form-label">Description</label>
                            <textarea
                              className="form-control"
                              placeholder="To upload an announcement, providers can choose between three formats: JPG, PDF, or text. Announcements can be set for site-wide visibility."
                              defaultValue={""}
                            />
                          </div>
                        </div>
                        <div className="upload-wrapper my-4">
                          <h3 className="title-h3 mb-2">Upload File</h3>
                          <p>
                            Upload announcements in formats like JPG, PDF, or
                            text
                          </p>
                          <div className="row">
                            <div className="col-lg-2">
                              <div className="mb-3">
                                <div className="upload-div upload2-div border-0 p-0">
                                  <div className="upload-file">
                                    <input type="file" />
                                    <div className="upload-content flex-column">
                                      <img src={uploadFile} alt="" />
                                      <p>Upload File</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-2">
                              <div className="mb-3">
                                <div className="upload-div upload2-div with-img-blk border-0 p-0">
                                  <div className="upload-file">
                                    <div className="upload-content flex-column">
                                      <Link to="#" className="uplaod-close">
                                        <i className="ti ti-x" />
                                      </Link>
                                      <img src={autoGenerate} alt="" />
                                      <p>Announcements.pdf</p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="settings-wrapper ">
                          <div className="audience-blk block-border border-0 m-0 pb-4">
                            <h4>Announcements Visibility</h4>
                            <div className="info-content-wrapper">
                              <ul className="list-unstyled d-flex align-items-center flex-wrap gap-30">
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" defaultChecked />
                                    <span className="checkmark" />
                                    Site-Wide
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Followers
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Subscribers
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Providers
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Facilities
                                  </label>
                                </li>
                                <li>
                                  <label className="custom_check style2">
                                    <input type="checkbox" />
                                    <span className="checkmark" />
                                    Non-followers/subscribers
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <div className="mb-3">
                              <label className="form-label">Tags</label>
                              <Chips
                                className="w-100"
                                value={value}
                                onChange={(e: any) => setValue(e.value)}
                              />
                            </div>
                          </div>
                          <div className="audience-blk block-border">
                            <div className="row">
                              <div className="col-12">
                                <div className="mt-3">
                                  <Link
                                    to="#"
                                    className="btn btn-submit add-video"
                                  >
                                    Save
                                  </Link>
                                  <Link
                                    to="#"
                                    className="btn btn-outline-primary add-video"
                                  >
                                    Cancel
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EditAnnouncements;