import React, { useState } from "react";
import { createAccountClient, logo } from "../../utils/imagepath";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { routes } from "../../utils/routes/routes";
import ErrorMessage from "../../components/errorMessage";
import {
  emailMaxLength,
  nameMaxLength,
  onlyAlphabet,
} from "../../utils/patterns/regex.pattern";
import {
  emailSchema,
  firstNameSchema,
  nameSchema,
  passwordSchema,
  userNameSchema,
} from "./components/yupSchema";
import { yup } from "../../utils/shared.module";
import { setRegisterEmail } from "../../global";
import { useDispatch } from "react-redux";
import { setRegisterData } from "../../core/redux/commonSlice";
import { useSelector } from "react-redux";
const formSchema = yup.object({
  username: userNameSchema,
  password: passwordSchema,
  email: emailSchema,
  name: nameSchema,
});
const RegisterClient = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const registerData = useSelector((state: any) => state.common.registerData);

  const [showPassword, setShowPassword] = useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    trigger,
  } = useForm({
    resolver: yupResolver(formSchema),
  });
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const onSubmit = async (data: any) => {
    setRegisterEmail(data?.email);
    try {
      showLoader();
      const updateData: any = {
        ...registerData,
        client: {
          ...registerData.client,
          name: data?.name || "",
          email: data?.email || "",
          username: data?.username || "",
          password: data?.password || "",
        },
      };
      dispatch(setRegisterData(updateData));
      const payload = {};
      const response = await postData(end_points.api_endpoint.url, payload);
      if (response.code === 200) {
      }
    } catch (error) {
    } finally {
      hideLoader();
      navigate(routes.verifyAccount);
    }
  };
  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mx-auto">
              <div className="login-wrap">
                <div className="login-header">
                  <img src={logo} className="logo-blk" alt="" />
                </div>
                <div className="login-body">
                  <div className="login-title">
                    <h4>Create User Account</h4>
                    <p>
                      Please provide your full name, valid email and create
                      strong password.
                    </p>
                  </div>
                  {/* Login Form */}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="form-set">
                      <label>Name</label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your name"
                            {...field}
                            onBlur={() => trigger("name")} // Trigger validation onBlur
                            onKeyDown={(event) => {
                              const regex = onlyAlphabet;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={nameMaxLength}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.name} />
                    </div>
                    <div className="form-set">
                      <label>Email</label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your email"
                            {...field}
                            onBlur={() => trigger("email")} // Trigger validation onBlur
                            maxLength={emailMaxLength}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.email} />
                    </div>
                    <div className="form-set">
                      <label>Username</label>
                      <Controller
                        name="username"
                        control={control}
                        render={({ field }) => (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter your username"
                            {...field}
                            onBlur={() => trigger("username")} // Trigger validation onBlur
                            onKeyDown={(event) => {
                              const regex = onlyAlphabet;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={nameMaxLength}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.username} />
                    </div>
                    <div className="form-set mb-0">
                      <label>Password</label>
                      <div className="pass-group position-relative">
                        <Controller
                          name="password"
                          control={control}
                          render={({ field }) => (
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control pass-input"
                              placeholder="Enter your password"
                              {...field}
                              onBlur={() => trigger("password")} // Trigger validation onBlur
                            />
                          )}
                        />
                        <i
                          className={`toggle-password ti ${showPassword ? "ti-eye" : "ti-eye-closed"}`}
                          onClick={togglePasswordVisibility}
                        />
                        <ErrorMessage value={errors.password} />
                      </div>
                    </div>
                    <button
                      className="btn btn-primary w-100 login-btn"
                      type="submit"
                    >
                      Create Account
                    </button>
                  </form>
                  {/* /Login Form */}
                </div>
                <div className="progress-blk two-steps align-center">
                  <ul className="list-unstyled">
                    <li className="active">
                      <i className="ti ti-circle-filled" />
                    </li>
                    <li>
                      <i className="ti ti-circle-filled" />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={createAccountClient} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default RegisterClient;
