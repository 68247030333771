import React from "react";
import { Link } from "react-router-dom";
import { slotIcon, slotVerifyIcon } from "../../../utils/imagepath";
import { routes } from "../../../utils/routes/routes";
import Calendar from "./calendar";


const ScheduleWorldwideStream = () => {
  return (
    <>
        {/* Page Wrapper */}
        <div className="page-wrapper">
          <div className="content container-fluid">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcrumb-blk schedule-breadcrumb">
                  <div className="d-flex flex-wrap align-items-start">
                    <ul className="flex-style">
                      <li className="f-500">TW 24/7 Stream</li>
                    </ul>
                  </div>
                  <p>
                    Easily schedule live streams with our new feature. Choose
                    dates and slots, enhancing national exposure at no cost.
                  </p>
                </div>
              </div>
            </div>
            <div className="row custom-row">
              <div className="col-lg-7 col-md-12 custom-col d-flex">
                <div className="card stream-card w-100">
                  <div className="card-body">
                    <div className="stream-calendar">
                      <div id="calendar-book">
                      <Calendar />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-12 custom-col d-flex">
                <div className="card stream-card w-100">
                  <div className="card-body">
                    <div className="stream-header">
                      <h4>Choose a Time Slot</h4>
                    </div>
                    <div className="stream-box">
                      <div className="stream-box-scroll">
                        <div className="row">
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>06:00 AM</b> <span>$10.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>07:00 AM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>08:00 AM</b> <span>$5.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>09:00 AM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn disabled">
                                <b>10:00 AM</b>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>11:00 AM</b> <span>$20.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>12:00 PM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>01:00 PM</b> <span>$10.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>02:00 PM</b> <span>$20.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn disabled">
                                <b>03:00 PM</b>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>04:00 PM</b> <span>$25.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>05:00 PM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>06:00 PM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn active">
                                <span>$20.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>08:00 PM</b> <span>$20.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>09:00 PM</b> <span>$15.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>10:00 PM</b> <span>$10.00</span>
                              </button>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="stream-btn">
                              <button className="btn">
                                <b>11:00 PM</b> <span>$20.00</span>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="stream-add-btn">
                        <Link
                          to="#"
                          className="btn btn-primary rounded"
                          data-bs-toggle="modal"
                          data-bs-target="#add-slot"
                        >
                          Add a Slot
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
        {/* Add Slot Modal */}
        <div
          className="modal fade slot-modal"
          id="add-slot"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body">
                <form action="#">
                  <div className="add-slot-info">
                    <img src={slotIcon} alt="Icon" />
                    <h2>
                      <span>$10.00</span> has been deducted from your wallet
                      automatically.
                    </h2>
                    <p>
                      Effortlessly manage your payments with automated wallet
                      deductions. Enjoy secure, seamless transactions without
                      lifting a finger.
                    </p>
                  </div>
                  <div className="date-slot">
                    <ul className="nav">
                      <li>
                        <h6>Date</h6>
                        <p>May 29, 2024</p>
                      </li>
                      <li>
                        <h6>Timeslot</h6>
                        <p>07:00 PM</p>
                      </li>
                    </ul>
                  </div>
                  <div className="date-slot-btn">
                    <Link
                      to="#"
                      className="btn btn-primary rounded w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#verification-modal"
                    >
                      Book Another Slot
                    </Link>
                  </div>
                  <div className="configure-text">
                    <h6>Configure your May 29, 2024 Live Stream</h6>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Add Slot Modal */}
        {/* Verification Modal */}
        <div
          className="modal fade slot-modal"
          id="verification-modal"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-lg">
            <div className="modal-content">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body">
                <form action="settings.html">
                  <div className="add-slot-info verification-slot-header">
                    <img
                      src={slotVerifyIcon}
                      alt="Icon"
                    />
                    <h3>Enter Verification Code</h3>
                    <p>
                      A message with a 6-digit code has been sent to *** ***
                      ***56. This helps us keep your account secure by verifying
                      that it's really you.
                    </p>
                  </div>
                  <div className="verification-list">
                    <ul className="nav">
                      <li className="filled">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={6}
                        />
                      </li>
                      <li className="filled">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={2}
                        />
                      </li>
                      <li className="filling">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={5}
                        />
                      </li>
                      <li className="filling">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue={7}
                        />
                      </li>
                      <li>
                        <input type="text" className="form-control" />
                      </li>
                      <li>
                        <input type="text" className="form-control" />
                      </li>
                    </ul>
                  </div>
                  <div className="date-slot-btn">
                    <button className="btn btn-primary rounded w-100">
                      Submit
                    </button>
                  </div>
                  <div className="configure-text">
                    <Link to={routes.settings}>Resend code</Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {/* /Verification Modal */}
    </>
  );
};

export default ScheduleWorldwideStream;
