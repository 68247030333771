import React from "react";
import { Link } from "react-router-dom";
import { viewIcon13 } from "../../../../utils/imagepath";

const Events = () => {
  return (
    <>
      {" "}
      <div
        className="tab-pane fade"
        id="events-tab"
        role="tabpanel"
        aria-labelledby="events_tab"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="view-event-box active">
                <div className="view-event-content">
                  <h4>
                    Effective Stress Management Techniques for Improved
                    Well-Being
                  </h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (11:30AM to 12:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>Essential Nutrition Tips for a Healthy Lifestyle</h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (12:30PM to 01:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>
                    Comprehensive Live Yoga Class: Beginner to Advanced Levels
                  </h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (01:30PM to 02:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>
                    Breathwork and Relaxation Techniques for Stress Relief and
                    Inner Peace
                  </h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (02:30PM to 03:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>
                    Dive into Breathwork and Relaxation Techniques for Stress
                    Relief and Inner Peace
                  </h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (03:30PM to 04:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>
                    Comprehensive Live Yoga Class: Beginner to Advanced Levels
                  </h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (01:30PM to 02:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
              <div className="view-event-box">
                <div className="view-event-content">
                  <h4>Essential Nutrition Tips for a Healthy Lifestyle</h4>
                  <ul className="nav">
                    <li>
                      <Link to="#">Susan Reynold</Link>
                    </li>
                    <li>May 27, 2024 - (12:30PM to 01:30PM)</li>
                  </ul>
                </div>
                <div className="view-event-btn">
                  <Link
                    to="#"
                    className="btn"
                    data-bs-toggle="modal"
                    data-bs-target="#signup-modal"
                  >
                    <img
                      src={viewIcon13}
                      alt="Icon"
                    />{" "}
                    Add to Calendar
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
