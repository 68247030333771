import React from 'react'
import { ratingImg, viewIcon16 } from '../../../../utils/imagepath'

const Ratings = () => {
  return (
    <> <div className="modal fade rating-modal" id="ratings-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="rating-modal-header">
          <div className="rating-modal-content">
            <div className="rating-modal-icon">
              <img src={viewIcon16} alt="Icon" />	
            </div>
            <div className="rating-modal-text">
              <h4>Rate &amp; Review</h4>
              <p>Share your experience and help others find tranquility.</p>
            </div>
          </div>
          <div className="rating-close">
            <button type="button" className="btn" data-bs-dismiss="modal">
              <i className="ti ti-x" />
            </button>
          </div>
        </div>
        <div className="modal-body">
          <div className="rating-modal-info">
            <div className="rating-modal-img">
              <img src={ratingImg} alt="Rating" />		
            </div>
            <div className="rating-modal-ratings">
              <h4>Betsy McLeod</h4>
              <ul className="nav">
                <li>Give overall rating:</li>
                <li>
                  <ul className="nav overall-rating">
                    <li><i className="ti ti-star" /></li>
                    <li><i className="ti ti-star" /></li>
                    <li><i className="ti ti-star" /></li>
                    <li><i className="ti ti-star" /></li>
                    <li><i className="ti ti-star" /></li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="rating-textarea">
            <textarea className="form-control" placeholder="Write your detailed review here..." defaultValue={""} />
          </div>
          <div className="rating-modal-btns">
            <button type="button" className="btn btn-primary" data-bs-dismiss="modal">Save</button>	
            <button type="button" className="btn btn-cancel" data-bs-dismiss="modal">Cancel</button>	
          </div>
        </div>
      </div>
    </div>
  </div></>
  )
}

export default Ratings