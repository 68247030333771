import React from 'react';
import {
  endcallIcon,
  fullscreenIcon,
  screenshotIcon,
} from '../../../../../../utils/imagepath';
interface VideoControlsProps {
  onScreenshot: () => void;
  onEndCall: () => void;
  onToggleFullscreen: () => void;
  subscribers: number;
}

const VideoControls: React.FC<VideoControlsProps> = ({
  onScreenshot,
  onEndCall,
  onToggleFullscreen,
  subscribers,
}) => {
  return (
    <div className={`video-btn ${subscribers > 1 ? 'w-70' : 'w-100'}`}>
      <div className="video-btn-list">
        <ul className="nav d-flex justify-content-between">
          <li>
            <button
              className="btn"
              data-bs-toggle="modal"
              data-bs-target="#screenshot_modal"
              onClick={onScreenshot}
              aria-label="Take screenshot"
            >
              <img src={screenshotIcon} alt="Screenshot" />
            </button>
          </li>
        </ul>
      </div>

      <div className="video-btn-bottom">
        <ul className="nav">
          <li>
            <button
              className="btn btn-end-call"
              onClick={onEndCall}
              aria-label="End call"
            >
              <img src={endcallIcon} alt="End call" />
            </button>
          </li>
          <li>
            <button
              className="btn"
              onClick={onToggleFullscreen}
              aria-label="Toggle fullscreen"
            >
              <img src={fullscreenIcon} alt="Fullscreen" />
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default VideoControls;
