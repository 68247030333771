import React, { useState } from "react";
import { Link } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { logoWhite, trendDown, trendUp } from "../../utils/imagepath";

import { Dropdown } from "primereact/dropdown";

const Analytics = () => {
  const [profileView, setProfileView] = useState<any>({
    series: [
      {
        name: "New Users",
        data: [10, 40, 15, 51, 59, 40, 69, 91, 48],
      },
      {
        name: "Old Users",
        data: [20, 60, 45, 31, 59, 40, 29, 51, 28],
      },
    ],
    colors: ["#FCC69D", "#89D0CD"],
    chart: {
      height: 250,
      type: "line",
      zoom: {
        enabled: false,
      },
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },

    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
      ],
    },
  });
  const [callActions, setCallActions] = useState<any>({
    series: [
      {
        name: "Email",
        data: [44, 55, 57, 56, 61, 58, 63, 60, 66],
      },
      {
        name: "Calls",
        data: [76, 85, 101, 98, 87, 105, 91, 114, 94],
      },
      {
        name: "Request a call",
        data: [35, 41, 36, 26, 45, 48, 52, 53, 41],
      },
    ],
    colors: ["#FCC69D", "#89D0CD", "#FCED8F"],
    chart: {
      type: "bar",
      height: 350,
    },
    legend: {
      position: "top",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "35%",
        endingShape: "rounded",
      },
    },
    responsive: [
      {
        plotOptions: {
          bar: {
            columnWidth: "65%",
          },
        },
      },
    ],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: [
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
      ],
    },

    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return "$ " + val + " thousands";
        },
      },
    },
  });
  const [engagementMetrics, setEngagementMetrics] = useState<any>({
    series: [
      {
        name: "Likes",
        data: [31, 40, 28, 51, 42, 109, 100, 40, 28, 51, 42, 109],
      },
      {
        name: "Comments",
        data: [22, 53, 64, 63, 68, 98, 23, 3, 21, 54, 65, 23],
      },
      {
        name: "Shares",
        data: [11, 32, 45, 32, 34, 52, 41, 65, 58, 52, 12, 25],
      },
    ],
    colors: ["#FCC69D", "#89D0CD", "#FCED8F"],
    chart: {
      height: 300,
      type: "line",
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
  });
  const [subscribers, setSubscribers] = useState<any>({
    series: [17, 15],
    chart: {
      height: 300,
      type: "donut",
    },
    colors: ["#FCC69D", "#89D0CD"],
    labels: ["Subscribe", "Unsubscribe"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
        },
      },
    ],
  });
  const [favourites, setFavourites] = useState<any>({
    series: [
      {
        data: [400, 430, 448, 470, 540, 580, 690],
      },
    ],
    chart: {
      type: "bar",
      height: 350,
    },
    colors: ["#89D0CD", "#89D0CD"],
    annotations: {
      xaxis: [
        {
          x: 500,
          borderColor: "#89D0CD",
          label: {
            borderColor: "#00E396",
            style: {
              color: "#333",
              background: "#00E396",
            },
          },
        },
      ],
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: true,
    },
    xaxis: {
      categories: ["#01", "#02", "#03", "#04", "#05", "#06", "07"],
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
    },
    yaxis: {
      axisTicks: {
        show: true,
      },
    },
  });
  const [totalMinutes, setTotalMinutes] = useState<any>({
    series: [17, 15],
    chart: {
      height: 300,
      type: "donut",
    },
    colors: ["#FCC69D", "#89D0CD"],
    labels: ["Subscribe", "Unsubscribe"],
    legend: {
      position: "bottom",
    },
    dataLabels: {
      enabled: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 250,
          },
        },
      },
    ],
  });
  const [revenueGenerated, setRevenueGenerated] = useState<any>({
    series: [
      {
        name: "Subscriptions",
        data: [44, 55, 41, 67, 22, 43, 21, 49, 44, 55, 41, 67],
      },
      {
        name: "One -Time Payments",
        data: [11, 17, 15, 15, 21, 14, 15, 13, 44, 55, 41, 67],
      },
    ],
    colors: ["#FCED8F", "#89D0CD"],
    chart: {
      type: "bar",
      height: 300,
      stacked: true,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          legend: {
            position: "bottom",
            offsetX: -10,
            offsetY: 0,
          },
        },
      },
    ],
    xaxis: {
      categories: [
        "Jan",
        "Feb",
        "Mar",
        "April",
        "May",
        "Jun",
        "July",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    },
    fill: {
      opacity: 1,
    },
    legend: {
      position: "top",
    },
    dataLabels: {
      enabled: false,
    },
  });
  const [selectedMonth1, setSelectedMonth1] = useState(null);
  const [selectedMonth2, setSelectedMonth2] = useState(null);
  const [selectedMonth3, setSelectedMonth3] = useState(null);
  const [selectedMonth4, setSelectedMonth4] = useState(null);
  const [selectedMonth5, setSelectedMonth5] = useState(null);
  const [selectedMonth6, setSelectedMonth6] = useState(null);
  const [selectedMonth7, setSelectedMonth7] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedYear, setSelectedYear] = useState(null);

  const monthOptions = [
    { label: "May 2024", value: "May 2024" },
    { label: "June 2024", value: "June 2024" },
  ];

  const categoryOptions = [
    { label: "Favorites by Video", value: "Favorites by Video" },
    { label: "Health", value: "Health" },
  ];

  const yearOptions = [
    { label: "This Year", value: "This Year" },
    { label: "2024", value: "2024" },
  ];

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="breadcrumb-blk">
            <ul className="flex-style">
              <li>
                <Link to="#">Directory</Link>
              </li>
              <li>Analytics</li>
            </ul>
          </div>
          <div className="row flex-body d-flex row-gap-30 chart-set">
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Overview</h3>
                    <p>
                      Comprehensive data insights guiding strategic decisions
                      and business growth.
                    </p>
                  </div>
                  <Dropdown
                    value={selectedMonth1}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth1(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="card-insights">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Profile Views
                          <Link to="#" className="ms-auto">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>3,671</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-insights danger-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Income Generated{" "}
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>$10,750</h3>
                          <p>
                            -0.02%
                            <img src={trendDown} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-insights info-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Subscribers
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>150</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="card-insights warning-skin">
                        <p className="d-flex align-items-center justify-content-between">
                          Total Followers
                          <Link to="#">
                            <i className="ms-1 ti ti-info-circle" />
                          </Link>
                        </p>
                        <div className="count-insights mt-auto">
                          <h3>2,671</h3>
                          <p>
                            +11.02%
                            <img src={trendUp} className="ms-1" alt="" />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-title">
                <h6>Directory</h6>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Profile View{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedMonth2}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth2(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="profile-view">
                        <ReactApexChart
                          height={300}
                          options={profileView}
                          series={profileView.series}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Call Actions{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedMonth3}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth3(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="call-action">
                        <ReactApexChart
                          height={300}
                          options={callActions}
                          series={callActions.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="sub-title">
                <h6>Video</h6>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Engagement Metrics{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedMonth4}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth4(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="engagement-metrics">
                        <ReactApexChart
                          height={300}
                          options={engagementMetrics}
                          series={engagementMetrics.series}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 d-flex">
              <div className="custom-card flex-fill w-100">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Subscribers</h3>
                  </div>
                  <Dropdown
                    value={selectedMonth5}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth5(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="subscribers">
                        <ReactApexChart
                          height={300}
                          options={subscribers}
                          series={subscribers.series}
                          type="donut"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Top 10 Favorited Videos{" "}
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <div className="ms-lg-auto d-flex row-gap-10 flex-wrap">
                    <Dropdown
                      className="w-auto chart-dropdown"
                      value={selectedCategory}
                      options={categoryOptions}
                      onChange={(e) => setSelectedCategory(e.value)}
                      placeholder="Select"
                    />
                    <Dropdown
                      value={selectedYear}
                      options={yearOptions}
                      onChange={(e) => setSelectedYear(e.value)}
                      placeholder="Select"
                      className="ms-3 w-auto chart-dropdown"
                    />
                  </div>
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="faviorates">
                        <ReactApexChart
                          height={300}
                          options={favourites}
                          series={favourites.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-5 col-12 d-flex">
              <div className="custom-card flex-fill w-100">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>Total Minutes</h3>
                  </div>
                  <Dropdown
                    value={selectedMonth6}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth6(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="total-minutes">
                        <ReactApexChart
                          height={300}
                          options={totalMinutes}
                          series={totalMinutes.series}
                          type="donut"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-7 col-12">
              <div className="custom-card">
                <div className="top-blk flex-style">
                  <div className="content-info">
                    <h3>
                      Revenue Generated
                      <Link to="#">
                        <i className="ti ti-info-circle ms-1" />
                      </Link>
                    </h3>
                  </div>
                  <Dropdown
                    value={selectedMonth7}
                    options={monthOptions}
                    onChange={(e) => setSelectedMonth7(e.value)}
                    placeholder="Select"
                    className="ms-lg-auto w-auto chart-dropdown"
                  />
                </div>
                <div className="d-flex flex-column row-gap-30">
                  <div className="row">
                    <div className="col-md-12">
                      <div id="revenue-generated">
                        <ReactApexChart
                          height={300}
                          options={revenueGenerated}
                          series={revenueGenerated.series}
                          type="bar"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* /Page Wrapper */}
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default Analytics;
