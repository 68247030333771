import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  ApiServiceContext,
  yup,
  yupResolver,
} from '../../../../../../utils/shared.module';
import { Controller, useForm } from 'react-hook-form';
import { AutoComplete } from 'primereact/autocomplete';
import { SpinnerContext } from '../../../../../../components/spinner/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { end_points } from '../../../../../../services/end_point/end_points';
import { Tab } from 'bootstrap';
import { setSymtomsFlowData } from '../../../../../../core/redux/commonSlice';

interface SymptomsContext {
  chipValue: string[];
}

const investigationValidation = yup.object().shape({
  tests: yup
    .array()
    .of(yup.string())
    .test(
      'at-least-one-tests',
      'At least one test is required',
      function (value) {
        const context = this.options.context as SymptomsContext;
        const { chipValue } = context;
        return (
          (value && value.length > 0) || (chipValue && chipValue.length > 0)
        );
      },
    ),
});

const InvestigationCall = () => {
  const [chipValue, setChipValue] = useState<any>([]);
  const [filteredTests, setFilteredTests] = useState<any[]>([]);
  const [showAddNote, setShowAddNote] = useState(false);
  const [newTests, setNewTests] = useState('');
  const [searchTest, setSearchTest] = useState('');
  const { showLoader, hideLoader } = useContext(SpinnerContext);
  const { getData, postData } = useContext(ApiServiceContext);
  const [testListData, setTestListData] = useState<any>({});
  const editData: any = useSelector((state: any) => state?.common?.editData);
  const dispatch = useDispatch();
  const savedData: any = useSelector((state: any) => state?.common?.flowData);

  const {
    handleSubmit,
    control,
    trigger,
    setError,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm<any>({
    resolver: yupResolver(investigationValidation),
    defaultValues: {
      tests: [],
      newTests: '',
    },
    context: { chipValue },
  });

  const searchTests = (event: any) => {
    const filtered = testListData.filter((test: any) =>
      test.name.toLowerCase().startsWith(event.query.toLowerCase()),
    );
    setFilteredTests(
      filtered.length
        ? filtered
        : [{ name: 'No results found', code: '', disabled: true }],
    );
  };

  const handleAddNote = () => {
    setShowAddNote(true);
  };

  const handleSaveNewTest = () => {
    if (newTests && !chipValue.includes(newTests)) {
      setChipValue((prev: any) => [...prev, newTests]);
      setNewTests('');
      setShowAddNote(false);
    }
  };

  const newTestSubmit = async () => {
    try {
      showLoader();
      const payload = {
        name: newTests,
        status: 'active',
      };
      const response = await postData(end_points.newTestCreate.url, payload);
      if (response.status === 200) {
        handleSaveNewTest();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const onSubmit = async (data: any) => {
    if (chipValue.length === 0) {
      setError('tests', {
        type: 'manual',
        message: 'At least one test is required',
      });
      return;
    }
    clearErrors('tests');
    try {
      const transformedTests = chipValue.map((test: any) => ({ name: test }));
      showLoader();
      let url = end_points.onCallInvestigationTabAdd.url;
      url += `/${editData?.patient_id}/${editData?._id}`;
      const payload = {
        investigation: [
          {
            tests: transformedTests,
            investigation_add_note: newTests,
          },
        ],
      };
      const response = await postData(url, payload);
      if (response.status === 200) {
        const newData = { ...savedData, invest: payload };
        dispatch(setSymtomsFlowData(newData));
        const nextTab = document.querySelector('#diagnosis-tab') as HTMLElement;
        const nextTabLink = new Tab(nextTab);
        nextTabLink.show();
      }
    } catch (error: any) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    if (chipValue.length > 0) {
      trigger('tests');
    }
  }, [chipValue]);

  const getTestsList = async (search?: any) => {
    try {
      showLoader();
      const urls = end_points.getTestList.url;
      const response = await getData(urls);
      if (response?.status == 200) {
        const testList = response.data.data.map((test: any) => ({
          name: test.name,
          code: test.name,
        }));
        setTestListData(testList);
      }
    } catch (error: any) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getTestsList();
  }, []);
  useEffect(() => {
    const data = savedData?.invest?.investigation?.[0] || null;
    if (data) {
      let sss = data?.tests?.map((sym: any) => sym?.name);
      setChipValue(sss);
      if (data?.investigation_add_note) {
        setShowAddNote(true);
        setNewTests(data?.investigation_add_note);
      }
    }
  }, [savedData?.invest]);
  const handleKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission on Enter key
    }
  };
  return (
    <div
      className="tab-pane fade"
      id="investigation_tab"
      role="tabpanel"
      aria-labelledby="investigation-tab"
    >
      <div className="row">
        <form
          id="investigationForm"
          onSubmit={handleSubmit(onSubmit)}
          onKeyDown={handleKeyPress}
        >
          <div className="col-md-12">
            <div className="symptoms-card">
              <h4>Tests</h4>
              <div className="symptoms-search">
                <div className="investigation-input investigation-input-control">
                  <Controller
                    name="tests"
                    control={control}
                    render={({ field }) => (
                      <AutoComplete
                        value={searchTest}
                        suggestions={filteredTests}
                        completeMethod={searchTests}
                        field="name"
                        onBlur={() => trigger('tests')}
                        onKeyDown={(e: any) => {
                          if (
                            e.key === 'Enter' &&
                            e.target.value !== 'No results found' &&
                            !chipValue.includes(e.target.value)
                          ) {
                            setChipValue([...chipValue, e.target.value]);
                            setSearchTest('');
                          }
                        }}
                        onChange={(e) => {
                          setSearchTest(e.value);
                          trigger('tests');
                        }}
                        onSelect={(e) => {
                          if (!chipValue.includes(e.value.name)) {
                            setChipValue([...chipValue, e.value.name]);
                            setSearchTest('');
                          }
                        }}
                        itemTemplate={(item) => {
                          if (item.disabled) {
                            return (
                              <span
                                style={{ color: 'gray', pointerEvents: 'none' }}
                              >
                                {item.name}
                              </span>
                            );
                          }
                          return <span>{item.name}</span>;
                        }}
                        placeholder="Search for tests"
                        className="form-control"
                      />
                    )}
                  />
                  <i className="feather-search" />
                </div>
                <div className="symptoms-tags">
                  <ul className="nav">
                    {chipValue.map((symptom: any, index: any) => (
                      <li key={index}>
                        <h5>
                          {symptom}
                          <Link
                            to="#"
                            onClick={() => {
                              setChipValue(
                                chipValue.filter((s: any) => s !== symptom),
                              );
                            }}
                          >
                            <i className="fas fa-xmark" />
                          </Link>
                        </h5>
                      </li>
                    ))}
                  </ul>
                  {errors.tests && (
                    <small className="text-danger d-inline-flex mt-2">
                      {errors.tests.message}
                    </small>
                  )}
                </div>
              </div>
              <div className="add-investigation">
                <Link
                  to="#"
                  className="add-investigation-link"
                  onClick={handleAddNote}
                >
                  Add Note
                </Link>
                {showAddNote && (
                  <div className="add-symptoms-input">
                    <input
                      type="text"
                      value={newTests}
                      onChange={(e) => setNewTests(e.target.value)}
                      className="form-control"
                      placeholder="Add note"
                    />
                    {/* <Link to="#" onClick={newTestSubmit}>
                      Save
                    </Link> */}
                  </div>
                )}
              </div>
              <div className="symptoms-save text-start mt-4">
                <button
                  type="submit"
                  className="btn btn-secondary"
                  form="investigationForm"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InvestigationCall;
