import React from "react";
import {
  clone,
  logo200,
  logoDark,
  logoWhite,
  reviewHorizontial,
  reviewVertical,
} from "../../utils/imagepath";


const EmbedWidget = () => {
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>Embed Widget</li>
                  </ul>
                </div>
              </div>
              <div className="custom-card">
                <div className="card-head">
                  <h3>Embed Review With Widgets</h3>
                  <p>
                    To add Turningwell Reviews widget to your website, take the
                    two following steps:
                  </p>
                </div>
                <div className="widget-wrapper">
                  <div className="widget-item">
                    <h4>
                      1. Copy the &lt;script&gt; tag into the HEAD section of
                      your website.
                    </h4>
                    <div className="text-end mb-2">
                      <img src={clone} width="18px" alt="" />
                    </div>
                    <pre className="language-markup mb-0">
                      <code className="language-markup">
                        &lt;script type="text/javascript"
                        src="https://s3.amazonaws.com/top-local-agencies/js/upcity_widget.js"&gt;&lt;/script&gt;
                      </code>
                    </pre>
                  </div>
                  <div className="widget-item">
                    <h4>2. Choose which widget style</h4>
                    <div className="sub-head-wrapper">
                      <div className="sub-head-blk">
                        <h5>Pin Widget:</h5>
                        <p>
                          Copy the following &lt;div&gt; tag into the BODY where
                          you would like the widget to be displayed.
                        </p>
                        <img src={logo200} alt="" />
                      </div>
                      <div className="text-end mb-2">
                        <img src={clone} width="18px" alt="" />
                      </div>
                      <pre className="language-markup mb-0">
                        <code className="language-markup">
                          &lt;script type="text/javascript"
                          src="https://s3.amazonaws.com/top-local-agencies/js/upcity_widget.js"&gt;&lt;/script&gt;
                        </code>
                      </pre>
                    </div>
                    <div className="sub-head-wrapper">
                      <div className="sub-head-blk">
                        <h5>Logo Widget:</h5>
                        <p>
                          Copy the following &lt;div&gt; tag into the BODY where
                          you would like the widget to be displayed.
                        </p>
                        <img src={logoDark} alt="" />
                      </div>
                      <div className="text-end mb-2">
                        <img src={clone} width="18px" alt="" />
                      </div>
                      <pre className="language-markup mb-0">
                        <code className="language-markup">
                          &lt;script type="text/javascript"
                          src="https://s3.amazonaws.com/top-local-agencies/js/upcity_widget.js"&gt;&lt;/script&gt;
                        </code>
                      </pre>
                    </div>
                    <div className="sub-head-wrapper">
                      <div className="sub-head-blk">
                        <h5>Review Widget:</h5>
                        <p>
                          Copy the following &lt;div&gt; tag into the BODY where
                          you would like the widget to be displayed.
                        </p>
                        <img src={reviewVertical} alt="" />
                      </div>
                      <div className="text-end mb-2">
                        <img src={clone} width="18px" alt="" />
                      </div>
                      <pre className="language-markup mb-0">
                        <code className="language-markup">
                          &lt;script type="text/javascript"
                          src="https://s3.amazonaws.com/top-local-agencies/js/upcity_widget.js"&gt;&lt;/script&gt;
                        </code>
                      </pre>
                    </div>
                    <div className="sub-head-wrapper">
                      <div className="sub-head-blk">
                        <h5>Review Widget:</h5>
                        <p>
                          Copy the following &lt;div&gt; tag into the BODY where
                          you would like the widget to be displayed.
                        </p>
                        <img src={reviewHorizontial} alt="" />
                      </div>
                      <div className="text-end mb-2">
                        <img src={clone} width="18px" alt="" />
                      </div>
                      <pre className="language-markup mb-0">
                        <code className="language-markup">
                          &lt;script type="text/javascript"
                          src="https://s3.amazonaws.com/top-local-agencies/js/upcity_widget.js"&gt;&lt;/script&gt;
                        </code>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default EmbedWidget;
