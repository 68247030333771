import { useEffect, useRef, useState } from "react";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import ErrorMessage from "../../../../components/errorMessage";
import { Dialog } from "primereact/dialog";
import { Chips } from "primereact/chips";
import { yup } from "../../../../utils/shared.module";
import {
  maxLength_96,
  onlyAlphabet,
} from "../../../../utils/patterns/regex.pattern";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
  clinicalExpertiseData: any;
  directoryData: any;
}
const basicModelValidation = yup.object().shape({
  treatment_modality: yup.mixed().optional(),
  treatment_expertise: yup.mixed().optional(),
  treatment_experience: yup.mixed().optional(),
});
const OtherTreatmentModal = (props: props) => {
  const otherclicnalRef = useRef();
  const { getDirectoryData, directoryData } = props;
  const { edit_type } = props.visible;

  const {
    watch,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    reset,
    control,
    trigger,
    getValues,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(basicModelValidation),
    // defaultValues: initialValues,
  });
  const otherData: any =
    directoryData?.other_treatment_modality_specialty || "";
  const OnClose = () => {
    reset();
    otherclicnalRef.current.click();
    props.handleClose();
  };

  const [chipValue, setChipValue] = useState<string[]>([]);
  const [chipValueTwo, setChipValueTwo] = useState<string[]>([]);
  const [chipValueThree, setChipValueThree] = useState<string[]>([]);
  const [isError, setIsError] = useState<boolean>(false)


  const handleFormSubmit = async () => {
    let totalItemsLength = chipValue.length + chipValueTwo.length + chipValueThree.length
    if (totalItemsLength < 1) {
      setIsError(true)
    } else {
      setIsError(false);
      try {
        showLoader();
        const { _id, ...data } = directoryData?.treatment_modality_specialty;
        const payload = {
          ...data,
          other_treatment_modality_specialty: {
            treatment_modality: chipValue,
            treatment_expertise: chipValueTwo,
            treatment_experience: chipValueThree,
          },
        };

        const response = await putData(
          end_points.profile_about_treatment_update.url,
          payload
        );

        if (response.status == 201) {
          getDirectoryData();
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    }
  };

  useEffect(() => {
    setChipValue(otherData?.treatment_modality || []);
    setChipValueTwo(otherData?.treatment_expertise || []);
    setChipValueThree(otherData?.treatment_experience || []);
  }, [otherData]);
  return (
    <>
      <div
        className="modal fade finances-modal"
        id="other-treatment-modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">
                  Other Treatment Modality Specialty, Expertise, or Experience.
                </h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={otherclicnalRef}
                onClick={OnClose}
              />
            </div>
            <form
              className="modal-body"
              onSubmit={handleSubmit(handleFormSubmit)}
            >
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-wrap">
                    <label className="mb-2">
                      Other Treatment Speciality
                      {/* <span>(Choose at least one specialty)</span> */}
                    </label>
                    <div className="mb-3">
                      <Controller
                        name="treatment_modality"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            className="w-100"
                            value={chipValue}
                            placeholder="Type and press enter to add tag"
                            onKeyDown={(event) => {
                              const regex = onlyAlphabet;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={maxLength_96}
                            onChange={(e: any) => {
                              setChipValue(e.value);
                              setValue("treatment_modality", e.value);
                              trigger("treatment_modality");
                            }}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.treatment_modality} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="form-wrap">
                    <label className="mb-2">
                      Other Treatment Expertise
                      {/* <span>(Select at least one area of expertise)</span> */}
                    </label>
                    <div className="mb-3">
                      <Controller
                        name="treatment_expertise"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            className="w-100"
                            value={chipValueTwo}
                            placeholder="Type and press enter to add tag"
                            onKeyDown={(event) => {
                              const regex = onlyAlphabet;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={maxLength_96}
                            onChange={(e: any) => {
                              setChipValueTwo(e.value);
                              setValue("treatment_expertise", e.value);
                              trigger("treatment_expertise");
                            }}
                          />
                        )}
                      />
                      <ErrorMessage value={errors.treatment_expertise} />
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="form-wrap">
                    <label className="mb-2">
                      Other Treatment Experience
                      {/* <span>(Select at least one experience item)</span> */}
                    </label>
                    <div className="mb-3">
                      <Controller
                        name="treatment_experience"
                        control={control}
                        render={({ field }) => (
                          <Chips
                            className="w-100"
                            value={chipValueThree}
                            placeholder="Type and press enter to add tag"
                            onKeyDown={(event) => {
                              const regex = onlyAlphabet;
                              if (
                                !regex.test(event.key) &&
                                event.key !== "Backspace"
                              ) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={maxLength_96}
                            onChange={(e: any) => {
                              setChipValueThree(e.value);
                              setValue("treatment_experience", e.value);
                              trigger("treatment_experience");
                            }}
                          />
                        )}
                      />
                      {isError && (
                        <ErrorMessage value={{ message: "Please enter atleast one item of any type" }} />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="filter-modal-btns">
                <button className="btn btn-primary rounded" type="submit">
                  {edit_type === "Edit" ? "Update" : "Add"} Treatement
                  modalities
                </button>
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherTreatmentModal;
