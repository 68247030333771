import React from "react";
import { Link } from "react-router-dom";
import {
  profileVideo1,
  profileVideo10,
  profileVideo11,
  profileVideo12,
  profileVideo13,
  profileVideo14,
  profileVideo15,
  profileVideo16,
  profileVideo17,
  profileVideo18,
  profileVideo19,
  profileVideo2,
  profileVideo20,
  profileVideo21,
  profileVideo22,
  profileVideo23,
  profileVideo24,
  profileVideo25,
  profileVideo26,
  profileVideo3,
  profileVideo4,
  profileVideo5,
  profileVideo6,
  profileVideo7,
  profileVideo8,
  profileVideo9,
  viewIcon14,
  viewIcon15,
  viewSpeaker,
} from "../../../../utils/imagepath";
import OwlCarousel from "react-owl-carousel";

const Video = () => {
  const videoSlider = {
    loop: true,
    margin: 6,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    autoplay: true,
    animateOut: "slideOutUp",
    responsive: {
      0: {
        items: 1,
      },
      550: {
        items: 1,
      },
      768: {
        items: 2,
      },
      992: {
        items: 3,
      },
      1200: {
        items: 4.8,
      },
    },
  };
  return (
    <>
      {" "}
      <div
        className="tab-pane fade"
        id="video-tab"
        role="tabpanel"
        aria-labelledby="video_tab"
      >
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="view-video-filter">
                <div className="video-filter-search">
                  <form action="#">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search..."
                    />
                    <button type="submit" className="btn btn-primary">
                      <img src={viewIcon14} alt="Icon" />
                    </button>
                  </form>
                </div>
                <div className="view-video-dropdown">
                  <Link
                    to="#"
                    className="dropdown-toggle"
                    data-bs-toggle="dropdown"
                  >
                    <img src={viewIcon15} alt="Icon" /> Filter
                  </Link>
                  <div className="dropdown-menu dropdown-menu-end">
                    <Link to="#" className="dropdown-item">
                      Filter 01
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Filter 02
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Filter 03
                    </Link>
                    <Link to="#" className="dropdown-item">
                      Filter 04
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Top Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Top Videos</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Top Videos */}
        {/* Trending Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Trending Videos</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo6}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo7}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo8}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo9}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo10}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Trending Videos */}
        {/* Latest Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Latest Videos</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* Latest Videos */}
        {/* Bipolar Disorder Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Bipolar Disorder Videos</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo11}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Bipolar Insights</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo12}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Living with Bipolar</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo13}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Bipolar Wellness</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo14}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Bipolar Life</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo15}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Bipolar Journey</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Bipolar Disorder Videos */}
        {/* Meditation Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Mindfulness and Meditation</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo16}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo9}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo17}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo18}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Meditation Videos */}
        {/* Nutrition Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Nutrition and Wellness</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo19}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Healthy Eating Tips</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo20}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Nutritional Supplements</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo21}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Weight Management</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo22}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Holistic Nutrition</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo23}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Gut Health</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Nutrition Videos */}
        {/* Anxiety Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Anxiety and Stress Management</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo11}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Understanding Anxiety</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo12}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Stress Reduction Techniques</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo13}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Mindfulness Practices</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo14}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                        <span>Exclusive</span>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Coping Strategies for Panic...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo15}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Improving Sleep Quality</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Anxiety Videos */}
        {/* Relationships Videos */}
        <div className="view-video-section">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="view-video-head">
                  <h4>Relationships and Communication</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="view-video-main">
            <div className="video-sliders">
              <OwlCarousel {...videoSlider}>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Relationship Building</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo24}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Effective Communication</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo25}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Interpersonal Skills</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo26}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Family and Relationships</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo15}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Professional Relationships</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo1}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>45 min | May 11, 2024</p>
                          <h5>Mental health Therapy</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo2}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Morning running with sun...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo3}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>13 min | May 26, 2024</p>
                          <h5>Healthy Relationship with your..</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo4}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>42 min | May 27, 2024</p>
                          <h5>Guided Imagery Relaxation...</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
                <div className="suggest-videos-info">
                  <Link
                    to="#"
                    className="video-div"
                    data-bs-toggle="modal"
                    data-bs-target="#video-modal"
                  >
                    <div className="video-img">
                      <img
                        src={profileVideo5}
                        alt="img"
                        className="video-thumbnail"
                      />
                    </div>
                    <div className="video-content">
                      <div className="video-exclusive">
                        <p>
                          <img src={viewSpeaker} alt="Icon" />
                          <b>Promote Video</b>
                        </p>
                      </div>
                      <div className="video-name">
                        <div className="video-text">
                          <p>16 min | May 24, 2024</p>
                          <h5>Studies of normal psychology</h5>
                        </div>
                        <div className="video-play">
                          <span>
                            <i className="ti ti-player-play" />
                          </span>
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              </OwlCarousel>
            </div>
          </div>
        </div>
        {/* /Relationships Videos */}
      </div>
    </>
  );
};

export default Video;
