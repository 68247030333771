import React from 'react';
import { Link } from 'react-router-dom';
import {
  defaultProfileTwoImage,
  patient01,
} from '../../../../../../utils/imagepath';
import { useSelector } from 'react-redux';
import {
  calculateAge,
  capitalizeFirstLetter,
} from '../../../../../../utils/constant';
interface apprprops {
  setShowSymptoms: any;
  showSymptoms: boolean;
  medicalRef: any;
}
const PatientDetail: React.FC<apprprops> = ({
  setShowSymptoms,
  showSymptoms,
  medicalRef,
}) => {
  const editData: any = useSelector((state: any) => state?.common?.editData);

  return (
    <div className="row">
      <div className="col-lg-6 col-md-12 d-flex">
        <div className="card w-100">
          <div className="card-body pb-0">
            <div className="patient-card-header">
              <h4>Patient Details</h4>
            </div>
            <div className="patient-details">
              <div className="patient-details-img">
                <img
                  src={
                    editData?.patientDetails?.profile_image
                      ? editData?.patientDetails?.profile_image
                      : defaultProfileTwoImage
                  }
                  className="img-fluid"
                  alt="Doctor"
                />
              </div>
              <div className="patient-details-content">
                <h6>
                  {capitalizeFirstLetter(editData?.patientDetails?.fullname)}
                </h6>
                <p>
                  {capitalizeFirstLetter(editData?.patientDetails?.gender)} |{' '}
                  {calculateAge(editData?.patientDetails?.dob)} |{' '}
                  {editData?.patientDetails?.mobile}
                </p>
                <p>Blood Group : {editData?.patientDetails?.bloodgroup}</p>
                <p className="patient-details-text">
                  Allergies :{' '}
                  {editData?.patientDetails?.allergies != ''
                    ? editData?.patientDetails?.allergies
                    : 'NA'}
                </p>
              </div>
            </div>
            <div className="patient-details-btn">
              <ul className="nav">
                <li>
                  <Link
                    to={'#'}
                    className="btn btn-primary"
                    data-bs-toggle="modal"
                    data-bs-target="#medical_history"
                    ref={medicalRef}
                  >
                    Medical History
                  </Link>
                </li>
                <li>
                  <Link
                    to={'#'}
                    className="btn btn-primary"
                    onClick={() => {
                      setShowSymptoms(false);
                    }}
                  >
                    Past Consults
                  </Link>
                </li>
                {!showSymptoms && (
                  <li>
                    <Link
                      to={'#'}
                      className="btn btn-primary"
                      onClick={() => {
                        setShowSymptoms(true);
                      }}
                    >
                      Back to {showSymptoms ? 'Consult' : 'Symptoms'}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-6 col-md-12 d-flex">
        <div className="card w-100">
          <div className="card-body pb-0">
            <div className="patient-card-header">
              <h4>Appointment Details</h4>
            </div>
            <div className="appointment-details-list join-details-list">
              <ul className="nav">
                <li>
                  <p>
                    <span>Appointment ID</span>{' '}
                    {editData?.appointment_format_id}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Appointment Type</span>{' '}
                    {capitalizeFirstLetter(editData?.appointment_type)}
                  </p>
                </li>
                <li>
                  <p>
                    <span>Cheif Complaint</span>{' '}
                    {editData?.cheif_complaint != ''
                      ? editData?.cheif_complaint
                      : 'NA'}
                  </p>
                </li>
              </ul>
            </div>
            {/* <div className="certificate-list">
              <ul className="nav">
                <li>
                  <Link to={'#'}>Medical Certificate</Link>
                </li>
                <li>
                  <Link to={'#'}>Fitness Certificate</Link>
                </li>
              </ul>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PatientDetail;
