// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-wrapper-auth {
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
    margin: 0 0 0 260px;
    padding: 60px 0 0;
    position: relative;
    left: 0;
    -webkit-transition: all 0.5s ease;
    -ms-transition: all 0.5s ease;
    transition: all 0.5s ease;
  }
  @media (max-width: 991.98px) {
    .page-wrapper-auth {
      margin: 0;
      padding: 70px 0 0;
    }
  }
  .page-wrapper-auth .content {
    padding: 24px;
    padding-bottom: 0;
  }
  @media (max-width: 991.98px) {
    .page-wrapper-auth .content {
      padding: 15px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/custom.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC;IACjC,6BAA6B;IAC7B,yBAAyB;IACzB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;IAClB,OAAO;IACP,iCAAiC;IACjC,6BAA6B;IAC7B,yBAAyB;EAC3B;EACA;IACE;MACE,SAAS;MACT,iBAAiB;IACnB;EACF;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE;MACE,aAAa;IACf;EACF","sourcesContent":[".page-wrapper-auth {\r\n    -webkit-transition: all 0.5s ease;\r\n    -ms-transition: all 0.5s ease;\r\n    transition: all 0.5s ease;\r\n    margin: 0 0 0 260px;\r\n    padding: 60px 0 0;\r\n    position: relative;\r\n    left: 0;\r\n    -webkit-transition: all 0.5s ease;\r\n    -ms-transition: all 0.5s ease;\r\n    transition: all 0.5s ease;\r\n  }\r\n  @media (max-width: 991.98px) {\r\n    .page-wrapper-auth {\r\n      margin: 0;\r\n      padding: 70px 0 0;\r\n    }\r\n  }\r\n  .page-wrapper-auth .content {\r\n    padding: 24px;\r\n    padding-bottom: 0;\r\n  }\r\n  @media (max-width: 991.98px) {\r\n    .page-wrapper-auth .content {\r\n      padding: 15px;\r\n    }\r\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
