import { Dialog } from "primereact/dialog";
import { useForm } from "react-hook-form";
import { end_points } from "../../../../services/endpoints";
import { putData } from "../../../../services/api.service";
import { useMemo, useRef } from "react";
import { hideLoader, showLoader } from "../../../../components/spinner/spinner";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { sharedExperienceSchema } from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import {
  onlyAlphabet,
  onlyNumber,
  personalInformationMaxLength,
} from "../../../../utils/patterns/regex.pattern";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: any;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  getSharedExperience: () => void;
}

const SharedExperienceModal = (props: props) => {
  const sharedModalref = useRef();
  const { type, open, data, userDetails, edit_type } = props.visible;
  const { getDirectoryData, getSharedExperience } = props;
  const SharedExperienceSchema = Yup.object().shape({
    shared_experience: sharedExperienceSchema,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(SharedExperienceSchema),
  });

  useMemo(() => {
    if (data) {
      setValue("shared_experience", data?.shared_experience);
    }
  }, [data]);

  const OnClose = () => {
    reset();
    sharedModalref.current.click();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    showLoader();
    const payload = {
      shared_experience: data.shared_experience,
    };
    try {
      const response = await putData(
        `${end_points.userprofile_sharedexperience_update.url}`,
        payload
      );
      if (response?.status == 201) {
        getSharedExperience();
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  return (
    <>
      <div
        className="modal fade finances-modal"
        id="shared-experience--modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">Shared Experience</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={OnClose}
                ref={sharedModalref}
              />
            </div>
            <div className="modal-body">
              <form action="" onSubmit={handleSubmit(handleFormSubmit)}>
                <textarea
                  {...register("shared_experience")}
                  value={watch("shared_experience")}
                  className="form-control w-100"
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                      ".",
                      ",",
                    ];
                    if (
                      !onlyAlphabet.test(key) &&
                      !allowedKeys.includes(key) &&
                      !onlyNumber.test(key)
                    ) {
                      e.preventDefault();
                    }
                  }}
                  style={{ marginBottom: "2rem" }}
                  maxLength={personalInformationMaxLength}
                ></textarea>
                <ErrorMessage value={errors.shared_experience} />
                <div className="form-wrap mb-0">
                  <button
                    className="btn btn-primary w-100"
                    type="submit"
                    style={{ marginTop: "1rem" }}
                  >
                    {data ? "Update Now" : "Add Now"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SharedExperienceModal;
