import React, { useContext, useEffect, useState } from 'react';
import { capitalizeWords } from '../../../../../utils/constant';
import AudioPlayer from '../joinConsultation/components/audioPlayer';
import { Link } from 'react-router-dom';
import { NormalVitals } from '../joinConsultation/components/vitalsCall';
import PacsModelPopup from '../joinConsultation/components/pacsModelPopup';
import { ApiServiceContext } from '../../../../../utils/shared.module';
import { end_points } from '../../../../../services/end_point/end_points';
interface vitlasProps {
  details: any;
  patient_Id: any;
  appoitmentId: any;
}
const Vitals: React.FC<vitlasProps> = ({
  details = {},
  patient_Id = '',
  appoitmentId = '',
}) => {
  const { getData } = useContext(ApiServiceContext);
  const [imageView, setImageView] = useState<any>('');
  const [scopeData, setScopeData] = useState<any>({
    autoscope: '',
    dermoscope: '',
  });

  const {
    deletedAt,
    _id,
    createdAt,
    updatedAt,
    others,
    stethoscope_details,
    pacs,
    ...rest
  } = details;
  const { stethoscope, stethoscope_audio_file, screenshot, ...pacsList } =
    pacs || {};
  let pacsDetails = pacsList && pacsList;
  let history: any = rest;

  if (others) {
    if (others && others?.average_heart_rate) {
      const stethoscope_Details: any = {
        average_heart_rate: others.average_heart_rate || '',
        rr_max: others?.rr_max || '',
        rr_min: others?.rr_min || '',
        br: others?.br || '',
        hrv: others?.hrv || '',
      };

      history = { ...history, Stethoscope: stethoscope_Details };
    }
    if (others?.systolic_blood_pressure) {
      const blood_pressure_Details: any = {
        systolic_blood_pressure: others?.systolic_blood_pressure || '',
        diastolic_pressure: others?.diastolic_pressure || '',
        // data_points: others?.data_points || '',
        // measuring_time: others?.measuring_time || '',
      };

      history = { ...history, blood_pressure: blood_pressure_Details };
    }
    if (others?.blood_oxygen) {
      const blood_oxygen_Details: any = {
        blood_oxygen: others?.blood_oxygen || '',
        heart_rate: others?.heart_rate || '',
      };

      history = { ...history, blood_oxygen: blood_oxygen_Details };
    }
    if (others?.sugar_value) {
      const blood_glucose_Details: any = {
        sugar_value: others?.sugar_value || '',
        glucose_sum: others?.glucose_sum || '',
        bg_count: others?.bg_count || '',
      };

      history = { ...history, blood_glucose: blood_glucose_Details };
    }
  }
  const getScopeData = async () => {
    try {
      let url = end_points.getPatientAutoScopeApi.url;
      let dermaUrl = end_points.getPatientDermoScopeApi.url;
      if (patient_Id) {
        url += `/${patient_Id}/${appoitmentId}`;
        dermaUrl += `/${patient_Id}/${appoitmentId}`;
      }
      const [response, responseDerma] = await Promise.all([
        getData(url),
        getData(dermaUrl),
      ]);

      if (response?.status === 200) {
        let newSymptomsNotes = {
          autoscope: Array.isArray(response?.data?.data?.autoscope)
            ? response?.data?.data?.autoscope?.[
                ((response?.data?.data?.autoscope || [])?.length || 1) - 1
              ]?.autoscope_add_note || ''
            : '',
          dermoscope: Array.isArray(responseDerma?.data?.data?.dermoscope)
            ? responseDerma?.data?.data?.dermoscope?.[
                ((responseDerma?.data?.data?.dermoscope || [])?.length || 1) - 1
              ]?.dermoscope_add_note || ''
            : '',
        };
        setScopeData({ ...newSymptomsNotes });
      }
    } catch (error) {}
  };
  useEffect(() => {
    patient_Id && getScopeData();
  }, [patient_Id]);
  return (
    <div
      className="tab-pane fade"
      id="vitals_tab"
      role="tabpanel"
      aria-labelledby="vitals-tab"
    >
      <div className="row">
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>Vitals</h4>
            {/* <div className="vitals-info">
              <div className="vitals-head">
                <div className="row">
                  <div className="col">
                    <h5>Device Name</h5>
                  </div>
                  <div className="col">
                    <h5>Value</h5>
                  </div>
                </div>
              </div>
            </div> */}
            <div className="vitals-info">
              <div className="vitals-head">
                <div className="row">
                  <div className="col">
                    <h5>Device Name</h5>
                  </div>
                  <div className="col">
                    <h5>Value</h5>
                  </div>
                </div>
              </div>
              <div className="vitals-body vitals-body-info">
                {history && Object.keys(history)?.length > 0 ? (
                  Object.keys(history).map((key) => {
                    const name = key?.replace('_', ' ');
                    if (name === 'spirometer') {
                      let spirometerParsed: any =
                        history[key] && JSON.parse(history[key]);
                      const spirometerValue =
                        spirometerParsed?.value &&
                        JSON.parse(spirometerParsed?.value);

                      let keys =
                        (spirometerValue &&
                          Object.keys(spirometerValue)?.map((key) => key)) ||
                        [];

                      return (
                        <NormalVitals
                          spirometerValue={spirometerValue}
                          keys={keys}
                        />
                      );
                    } else
                      return (
                        <>
                          <div className="row align-items-center" key={key}>
                            <div className="col device-list">
                              <h6 className="mb-0"> {capitalizeWords(name)}</h6>
                            </div>
                            {typeof history[key] === 'object' ? (
                              Object.keys(history[key])?.map((val) => (
                                <div
                                  className="row align-items-center"
                                  key={val}
                                >
                                  <div className="col">
                                    <div className="device-list pe-0">
                                      {capitalizeWords(
                                        (val || '')?.replaceAll('_', ' '),
                                      )}
                                    </div>
                                  </div>

                                  <div className="col">
                                    <div className="device-list">
                                      <p className="mb-0">
                                        {' '}
                                        {history[key][val]}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : history[key] ? (
                              <div className="col value-text">
                                {history[key]}
                              </div>
                            ) : null}
                          </div>
                          <hr />
                        </>
                      );
                  })
                ) : (
                  <div className="row">
                    <div className="col">No records found</div>
                  </div>
                )}
              </div>
            </div>
            <></>
          </div>
        </div>
        <div className="col-md-6">
          <div className="symptoms-card vitals-card">
            <h4>PACS</h4>
            <div className="vitals-table">
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Device Name</th>
                      <th>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {(typeof stethoscope === 'string' ||
                      typeof stethoscope_audio_file === 'string') && (
                      <tr>
                        <td>Stethoscope</td>
                        <td className="value-link">
                          <AudioPlayer
                            url={stethoscope_audio_file || stethoscope}
                          />
                          {/* <a href={stethoscope} target="blank">
                            play
                          </a> */}
                        </td>
                      </tr>
                    )}
                    {screenshot && (
                      <tr>
                        <td>Screenshot</td>
                        <td className="value-link">
                          <a href={screenshot} target="blank">
                            View
                          </a>
                        </td>
                      </tr>
                    )}
                    {pacsDetails &&
                      Object.keys(pacsDetails)?.length > 0 &&
                      Object.keys(pacsDetails).map((key) => {
                        const name = key?.replace('_', '');
                        console.log(name, 'labelname');

                        return (
                          <tr key={key}>
                            <td>
                              <>
                                {name === 'ecg' || name === 'ecg_image'
                                  ? 'ECG'
                                  : capitalizeWords(name)}
                                <div className="pt-2">
                                  {scopeData[name] && <>Note</>}
                                </div>
                              </>
                            </td>

                            <td className="value-link">
                              <Link
                                to="#"
                                data-bs-target="#PacsModelPopupID"
                                data-bs-toggle="modal"
                                onClick={() => {
                                  setImageView(pacsDetails[key]);
                                }}
                              >
                                View
                              </Link>
                              <div className="pt-2">
                                {scopeData[name] && <p>{scopeData[name]}</p>}
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                    {!stethoscope &&
                      !screenshot &&
                      pacsDetails &&
                      Object.keys(pacsDetails)?.length === 0 && (
                        <tr>
                          <td>No records found</td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <PacsModelPopup srcValue={imageView} />
    </div>
  );
};

export default Vitals;
