import { Link } from "react-router-dom";
import {
  artboardLine,
  articleLine,
  avatarDefault,
  avatarProfile,
  bankCardLine,
  bookmark2Line,
  editCalendarIcon,
  editDoubleQuotes,
  editLinkIcon,
  editMailIcon,
  editMapIcon,
  editPhoneIcon,
  facilityIcon,
  folderVideo,
  galleryImg,
  identityIcon,
  image2Line,
  media01Png,
  media01Svg,
  media02,
  media02Png,
  media03,
  media03Png,
  media04,
  media04Png,
  media05,
  media06,
  mediaCheck,
  mediaPdf,
  mediaUpload,
  sharedIcon,
  shieldStarLine,
  stethoscopeIcon,
  uploadIcon,
  userLine,
  verifiedEmail,
  verifiedIcon,
  vidiconLine,
} from "../../../utils/imagepath";
import Footer from "../../common/provider-footer";
import BasicModal from "./modals/basic-modal";
import FinanceModal from "./modals/finance-modal";
import EducationModal from "./modals/education-modal";
import InternshipModal from "./modals/internship-modal";
import ResidencyModal from "./modals/residency-modal";
import FellowshipsModal from "./modals/fellowshipsModal";
import BoardCirtificationModal from "./modals/board-cirtification-modal";
import ClinicalModal from "./modals/clinical-modal";
import CommunitiesModal from "./modals/communities-modal";
import GalleryModal from "./modals/Gallery-modal";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getData, putData, postData } from "../../../services/api.service";
import { end_points } from "../../../services/endpoints";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import {
  genderOptions,
  GetIdLabel,
  setLocalityLabel,
} from "../../../utils/constants/index.";
import SharedExperienceModal from "./modals/shared-experience";
import EmailModal from "./modals/email-modal";
import PhoneModal from "./modals/phone-modal";
import parsePhoneNumberFromString from "libphonenumber-js";
import AvailablityModal from "./modals/availablity-modal";
import { useDispatch } from "react-redux";
import { setUserDirectoryDetails } from "../../../core/redux/commonSlice";
import OtherClinicalModel from "./modals/otherClinicalModel";
import PrimaryCredentialModal from "./modals/primaryCredentialModal";
import TreatmentModal from "./modals/treatment-modal";
import OtherTreatmentModal from "./modals/otherTreatmentModal";
import AddLink from "./modals/add-link";
import LocationModal from "./modals/location-modal";
import IdentityModal from "./modals/identity-modal";
import { Dropdown } from "primereact/dropdown";
import Autocomplete from "react-google-autocomplete";

const Directory = () => {
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const dispatch = useDispatch();
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const fileInputRef = useRef<any>(null);

  const handleLinkClick = (event: any) => {
    event.preventDefault();
    fileInputRef.current.click();
  };

  const [directoryData, setDirectoryData] = useState<any>({});
  const [clinicalExpertiseData, setClinicalExpertiseData] = useState({
    clinical_specality: [],
    clinical_expertice: [],
    clinical_experience: [],
  });
  const [sharedExperience, setSharedExperience] = useState<any>();
  const [emailVerified, setEmailVerified] = useState<any>({});
  const [visible, setVisible] = useState({
    type: "",
    open: false,
    data: {},
    userDetails,
    edit_type: "",
  });
  const [designationsList, setDesignationsList] = useState([]);
  const [primaryCredentialData, setPrimarycredentialData] = useState({});
  const [clinicalRoleOption, setClinicalRoleOption] = useState<any>(null);
  const [financialInsurance, setFinancialInsurance] = useState<any>([]);
  const [faithMyIdentity, setFaithMyIdentity] = useState();
  const [raceMyIdentity, setRaceMyIdentity] = useState();
  const [addressDetails, setAddressDetails] = useState({
    street_address: "",
    address_line_two: "",
    city: "",
    state: "",
    zip: "",
    lat: null,
    lng: null,
  });

  const HandleClose = () => {
    setVisible({
      type: "",
      open: false,
      data: {},
      userDetails,
      edit_type: "",
    });
  };
  const otherClinicalBool: boolean =
    directoryData?.other_clinical_specialties?.clinical_specialties?.length ||
    directoryData?.other_clinical_specialties?.clinical_expertise?.length ||
    directoryData?.other_clinical_specialties?.clinical_experience?.length;

  const otherTreatmentBool: boolean =
    directoryData?.other_treatment_modality_specialty?.treatment_modality
      ?.length ||
    directoryData?.other_treatment_modality_specialty?.treatment_expertise
      ?.length ||
    directoryData?.other_treatment_modality_specialty?.treatment_experience
      ?.length;

  const GetPrimaryCredentials = async () => {
    const response = await getData(
      `${end_points?.get_primary_credentials?.url}`
    );
    if (response?.status < 400) {
      setPrimarycredentialData(response.data?.data);
    }
  };

  const getDirectoryData = async () => {
    const response = await getData(`${end_points?.user_about_list?.url}`);
    if (response?.status === 200) {
      setDirectoryData(response?.data?.data[0]);
      dispatch(setUserDirectoryDetails(response?.data?.data[0]));
    }
  };

  const GetDesignations = async () => {
    try {
      showLoader();
      const response = await getData(
        `${end_points.mater_designation_list.url}`
      );
      if (response?.status < 400) {
        setDesignationsList(setLocalityLabel(response.data?.data));
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const GetFinancialInsurance = async () => {
    try {
      showLoader();
      const response = await getData(
        `${end_points.master_insurance_methods.url}`
      );
      if (response?.status < 400) {
        console.log("INSURANCERESP", response);
        setFinancialInsurance(setLocalityLabel(response.data?.data));
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };
  const GetClinicalData = async () => {
    const [response1, response2, response3] = await Promise.all([
      getData(`${end_points.master_clinical_specialties.url}`),
      getData(`${end_points.master_clinical_expertise.url}`),
      getData(`${end_points.master_clinical_experience.url}`),
    ]);
    if (response1 && response2 && response3) {
      setClinicalExpertiseData({
        clinical_expertice: response2?.data?.data,
        clinical_specality: response1?.data?.data,
        clinical_experience: response3?.data?.data,
      });
    }
  };

  const getSharedExperience = async () => {
    try {
      const response = await getData(
        `${end_points.userprofile_sharedexperience.url}`
      );
      if (response.status == 200) {
        setSharedExperience(response?.data?.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleFileChange = async (event: any) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const formData = new FormData();
      formData.append("profile_image", selectedFile);
      try {
        showLoader();
        const response = await putData(
          `${end_points.profile_image_upload.url}`,
          formData
        );
        if (response?.status < 400) {
          getDirectoryData();
        }
      } catch (error) {
      } finally {
        hideLoader();
      }
      // Here you can handle file upload logic
    }
  };

  const clinicalList = async () => {
    try {
      const response = await getData(end_points.masterClinicalRoleApi.url);
      if (response.status === 200) {
        const option = response?.data?.data?.map((val: any) => {
          return { label: val?.name || "", value: val?._id || "" };
        });
        setClinicalRoleOption(option);
      }
    } catch (error) {}
  };

  const getEmailVerify = async () => {
    try {
      const response = await getData(end_points.verifyEmailStatus.url);
      if (response.status === 200) {
        console.log("Responsssss", response.data.data);
        setEmailVerified(response.data.data);
      }
    } catch (error) {}
  };

  const getFaithMyIdentity = async () => {
    try {
      const response = await getData(end_points.master_myIdentity_getfaith.url);
      if (response?.status == 200) {
        setFaithMyIdentity(response?.data?.data);
      }
    } catch (error) {}
  };
  const getRacesMyIdentity = async () => {
    try {
      const response = await getData(end_points.master_myIdentity_getrace.url);
      if (response?.status == 200) {
        setRaceMyIdentity(response?.data?.data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    clinicalList();
    GetClinicalData();
    GetFinancialInsurance();
    getDirectoryData();
    GetDesignations();
    getDirectoryData();
    GetPrimaryCredentials();
    getSharedExperience();
    getEmailVerify();
    getFaithMyIdentity();
    getRacesMyIdentity();
  }, []);

  const handleGenerateEmail = async () => {
    try {
      showLoader();
      let urls = end_points.generateEmail.url;
      const payload = {
        email: userDetails?.email,
      };

      const response = await postData(urls, payload);
      if (response?.status === 201) {
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  //Personal Statement
  const shortDescription = directoryData?.personal_statement || "";
  const charLimit = 100;
  const trimDescription =
    shortDescription.length > charLimit
      ? shortDescription.slice(0, charLimit)
      : shortDescription;

  //Availability Intro for new clients
  const introDescription = directoryData?.availability?.intro || "";
  const introcharLimit = 40;
  const trimIntroDescription =
    introDescription.length > introcharLimit
      ? introDescription.slice(0, introcharLimit)
      : introDescription;

  directoryData && console.log("directory", directoryData);

  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style">
                    <li>
                      <Link to="#">Directory</Link>
                    </li>
                    <li>Edit Profile</li>
                  </ul>
                </div>
              </div>
              <div className="tab-content" id="directoryTabContent">
                <div
                  className="tab-pane fade show active"
                  id="about-tab"
                  role="tabpanel"
                  aria-labelledby="about_tab"
                >
                  <div className="row custom-row">
                    <div className="col-md-7 custom-col">
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="about-edit-info">
                            <div className="about-edit">
                              <div className="about-edit-img">
                                <div>
                                  {/* Hidden file input */}
                                  <input
                                    type="file"
                                    ref={fileInputRef}
                                    style={{ display: "none" }}
                                    onChange={handleFileChange}
                                  />

                                  {/* Link that triggers file input */}
                                  <img
                                    src={
                                      directoryData?.profile_image !==
                                        undefined &&
                                      directoryData?.profile_image !== ""
                                        ? directoryData?.profile_image
                                        : avatarDefault
                                    }
                                    alt="Profile"
                                  />
                                  <Link to="#" onClick={handleLinkClick}>
                                    <i className="ti ti-pencil" />
                                  </Link>
                                </div>
                              </div>
                              <div className="about-edit-content">
                                <h4>{directoryData?.fullname}</h4>
                                <p>
                                  {GetIdLabel(
                                    designationsList,
                                    directoryData?.designation,
                                    "fullname"
                                  )}
                                </p>
                                <ul className="nav">
                                  {directoryData?.appointment_mode?.map(
                                    (item: any) => (
                                      <>
                                        <li>
                                          {item === "in-person" ? (
                                            <img src={userLine} alt="Icon" />
                                          ) : (
                                            <img src={vidiconLine} alt="Icon" />
                                          )}
                                          {item}
                                        </li>
                                      </>
                                    )
                                  )}
                                </ul>
                              </div>
                            </div>
                            <div className="about-edit-icon">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#basic-modal"
                                onClick={() => {
                                  setVisible({
                                    type: "personal_statement",
                                    open: true,
                                    data: {
                                      fullname: directoryData?.fullname,
                                      designation: directoryData?.designation,
                                      appointment_mode:
                                        directoryData?.appointment_mode,
                                      personal_statement:
                                        directoryData?.personal_statement,
                                    },
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-pencil custom-hl" />
                              </Link>
                            </div>
                          </div>

                          <div className="edit-personal-statement">
                            <h4 className="edit-title">
                              <img src={articleLine} alt="Icon" /> Personal
                              Statement
                            </h4>
                            <div className="edit-personal-text">
                              <p>{`${trimDescription}${shortDescription.length > charLimit ? "..." : ""}`}</p>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#basic-modal"
                                onClick={() => {
                                  setVisible({
                                    type: "personal_statement",
                                    open: true,
                                    data: {
                                      fullname: directoryData?.fullname,
                                      designation: directoryData?.designation,
                                      appointment_mode:
                                        directoryData?.appointment_mode,
                                      personal_statement:
                                        directoryData?.personal_statement,
                                    },
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <h4 className="edit-title mb-20">
                            <img src={image2Line} alt="Icon" /> Gallery
                          </h4>
                          <div className="edit-gallery-info">
                            <h4>Gallery Photos</h4>
                            {/* <Dropdown
                              options={genderOptions}
                              onChange={(e: any) => {
                                console.log(e.value, "e.value");
                              }}
                              filter={true}
                              placeholder="Select Country"
                              className="select w-100"
                              defaultValue={"US"}
                            /> */}
                            <div className="gallery-para">
                              <p>
                                Invite potential clients into your therapy space
                                and showcase your unique character through
                                photos.
                              </p>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#gallery-modal"
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                            <div className="edit-gallery-img">
                              <img
                                src={galleryImg}
                                className="img-fluid"
                                alt="Gallery"
                              />
                            </div>
                          </div>
                          <div className="my-identity">
                            <div className="about-edit-head">
                              <h4>
                                <img src={identityIcon} alt="Icon" /> My
                                Identity
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#identity-modal"
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <p>
                              Personal info not shown on your profile:
                              <span>
                                Age, gender, race, faith, sexuality, other.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="finances-info">
                        <h2 className="finances-title">Finances</h2>
                        <div className="card directory-card">
                          <div className="card-body">
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={bankCardLine} alt="Icon" /> Fees
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#finances-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "finance_modal",
                                      open: true,
                                      data: {
                                        ...directoryData?.finance,
                                      },
                                      userDetails,
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="individual-cost">
                                <h6>Individual Session Cost:</h6>
                                <p>
                                  ${directoryData?.finance?.cost} per session
                                </p>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={bankCardLine} alt="Icon" /> Payment
                                  Method
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#finances-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "finance_modal",
                                      open: true,
                                      data: {
                                        ...directoryData?.finance,
                                      },
                                      userDetails,
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="individual-cost">
                                <div className="row">
                                  {directoryData?.finance?.payment_method?.map(
                                    (val: any, index: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={shieldStarLine} alt="Icon" />{" "}
                                  Insurance
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#finances-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "finance_modal",
                                      open: true,
                                      data: {
                                        ...directoryData?.finance,
                                      },
                                      userDetails,
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="individual-cost">
                                <h6>In-Network Plans</h6>
                                <div className="row">
                                  {directoryData?.finance?.insurance_method?.map(
                                    (val: any, index: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                                <div className="out-of-network">
                                  <h6>Out-of-Network</h6>
                                  <p>
                                    I accept clients claiming on their OON
                                    benefits.
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="finances-info">
                        <h2 className="finances-title">Qualifications</h2>
                        <div className="card directory-card">
                          <div className="card-body">
                            <div className="about-edit-head about-edit-head-two">
                              <h4>
                                <img src={bookmark2Line} alt="Icon" /> Primary
                                Credential
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#credential-modal"
                                onClick={() => {
                                  setVisible({
                                    type: "primaryCred",
                                    open: true,
                                    data: primaryCredentialData,
                                    userDetails,
                                    edit_type: "clinical",
                                  });
                                }}
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                            <div className="qualification-box">
                              <div className="qualification-details">
                                <div className="qualification-icon">
                                  <img src={verifiedIcon} alt="Icon" />
                                </div>
                                <div className="qualification-text">
                                  <h6>Verified By Delray Brain Science</h6>
                                  <p>Licensed by State of Florida / ME78266</p>
                                  <p>Raul Rodriguez</p>
                                </div>
                              </div>
                              <div className="qualification-right">
                                <p>
                                  <i className="ti ti-pencil" />
                                </p>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="individual-cost">
                                <div className="row">
                                  <div className="col-md-12">
                                    <ul>
                                      <li>
                                        <span>Mental Health Role:</span>{" "}
                                        {
                                          primaryCredentialData
                                            ?.mental_health_roles
                                            ?.mental_health_role
                                        }
                                      </li>
                                      {primaryCredentialData
                                        ?.primary_credential_setting
                                        ?.credentials[0]
                                        ?.credential_agree_terms ===
                                        "i_have_a_certification_my_profession_had_no_license" && (
                                        <li>
                                          <span>Credential Type:</span>
                                          {primaryCredentialData
                                            ?.primary_credential_setting
                                            ?.credentials[0]
                                            ?.alternate_credential_type !==
                                          undefined
                                            ? primaryCredentialData
                                                ?.primary_credential_setting
                                                ?.credentials[0]
                                                ?.alternate_credential_type
                                            : primaryCredentialData?.license_details !==
                                                undefined
                                              ? primaryCredentialData
                                                  ?.license_details[0]
                                                  ?.alternate_credential_type
                                              : ""}
                                        </li>
                                      )}
                                      {primaryCredentialData
                                        ?.primary_credential_setting
                                        ?.credentials[0] !== undefined &&
                                        primaryCredentialData
                                          ?.primary_credential_setting
                                          ?.credentials[0]
                                          ?.credential_agree_terms !==
                                          "i_have_a_certification_my_profession_had_no_license" && (
                                          <li>
                                            <span>License:</span>
                                            {primaryCredentialData
                                              ?.primary_credential_setting
                                              ?.credentials !== undefined
                                              ? primaryCredentialData
                                                  ?.primary_credential_setting
                                                  ?.credentials[0]
                                                  ?.license_state
                                              : ""}
                                          </li>
                                        )}
                                      {primaryCredentialData
                                        ?.primary_credential_setting
                                        ?.credentials[0] !== undefined &&
                                        primaryCredentialData
                                          ?.primary_credential_setting
                                          ?.credentials[0]
                                          ?.credential_agree_terms !==
                                          "i_have_a_certification_my_profession_had_no_license" && (
                                          <li>
                                            <span>License Number:</span>
                                            {primaryCredentialData
                                              ?.primary_credential_setting
                                              ?.credentials !== undefined
                                              ? primaryCredentialData
                                                  ?.primary_credential_setting
                                                  ?.credentials[0]
                                                  ?.license_number
                                              : ""}
                                          </li>
                                        )}
                                      {primaryCredentialData
                                        ?.primary_credential_setting
                                        ?.credentials[0] !== undefined &&
                                        primaryCredentialData
                                          ?.primary_credential_setting
                                          ?.credentials[0]
                                          ?.credential_agree_terms !==
                                          "i_have_a_certification_my_profession_had_no_license" && (
                                          <li>
                                            <span>
                                              License Expiration Date:
                                            </span>
                                            {primaryCredentialData?.license_details !==
                                            undefined
                                              ? primaryCredentialData
                                                  ?.license_details[0]
                                                  ?.license_expiration_date
                                              : ""}
                                          </li>
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={artboardLine} alt="Icon" />{" "}
                                  Education and Years in Practice
                                </h4>
                                <Link
                                  data-bs-toggle="modal"
                                  data-bs-target="#education-modal"
                                  to={"#"}
                                  onClick={() => {
                                    setVisible({
                                      ...visible,
                                      type: "education",
                                      open: true,
                                      data: {},
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              {directoryData?.education !== undefined &&
                                directoryData?.education?.school !== "" && (
                                  <div className="individual-cost">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <ul>
                                          <li>
                                            <span>Education: </span>{" "}
                                            {directoryData?.education?.school}
                                          </li>
                                          <li>
                                            <span>Degree/Diploma:</span>
                                            {directoryData?.education?.degree}
                                          </li>
                                          <li>
                                            <span>Year Graduated:</span>
                                            {
                                              directoryData?.education
                                                ?.year_graduated
                                            }
                                          </li>
                                          <li>
                                            <span>Year | Began Practice: </span>
                                            {
                                              directoryData?.education
                                                ?.year_began_practice
                                            }
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={artboardLine} alt="Icon" />{" "}
                                  Internship
                                </h4>
                                <div className="internship-add">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#internship-modal"
                                    onClick={() => {
                                      setVisible({
                                        ...visible,
                                        type: "internship",
                                        open: true,
                                        data: {},
                                        edit_type: "add",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-plus" />
                                  </Link>
                                </div>
                              </div>
                              <div className="individual-cost">
                                <div className="row">
                                  {directoryData?.internship?.map(
                                    (val: any, index: number) => (
                                      <div className="col-md-12">
                                        <ul
                                          key={index}
                                          className="individual-type-list"
                                        >
                                          <div className="d-flex">
                                            <li>
                                              <span>
                                                Program Type&nbsp;{index + 1}:
                                              </span>
                                              {val?.program_type}{" "}
                                            </li>
                                            <Link
                                              className="pencil-icon-link"
                                              to="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#internship-modal"
                                              onClick={() => {
                                                setVisible({
                                                  ...visible,
                                                  type: "internship",
                                                  open: true,
                                                  data: val,
                                                  edit_type: "edit",
                                                });
                                              }}
                                            >
                                              <i className="ti ti-pencil" />
                                            </Link>
                                          </div>
                                          <li>
                                            <span>Program Institution:</span>
                                            {val?.program_institution}{" "}
                                          </li>
                                          <li>
                                            <span>Year of Graduation:</span>
                                            {val?.year_graduation}
                                          </li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={artboardLine} alt="Icon" />{" "}
                                  Residency
                                </h4>
                                <div className="internship-add">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#residency-modal"
                                    onClick={() => {
                                      setVisible({
                                        ...visible,
                                        type: "residency",
                                        open: true,
                                        data: {},
                                        edit_type: "add",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-plus" />
                                  </Link>
                                </div>
                              </div>
                              <div className="individual-cost">
                                <div className="row">
                                  <div className="col-md-12">
                                    {directoryData?.residency?.map(
                                      (val: any, index: any) => (
                                        <ul
                                          className="individual-type-list"
                                          key={index}
                                        >
                                          <div className="d-flex">
                                            <li>
                                              <span>
                                                Program Type&nbsp;{index + 1}:{" "}
                                              </span>{" "}
                                              {val?.program_type}{" "}
                                            </li>
                                            <Link
                                              className="pencil-icon-link"
                                              to="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#residency-modal"
                                              onClick={() => {
                                                setVisible({
                                                  ...visible,
                                                  type: "residency",
                                                  open: true,
                                                  data: val,
                                                  edit_type: "edit",
                                                });
                                              }}
                                            >
                                              <i className="ti ti-pencil" />
                                            </Link>
                                          </div>
                                          <li>
                                            <span>Program Institution:</span>
                                            {val?.program_institution}
                                          </li>
                                          <li>
                                            <span>Year of Graduation: </span>
                                            {val?.year_graduation}
                                          </li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={artboardLine} alt="Icon" />{" "}
                                  Fellowships
                                </h4>
                                <div className="internship-add">
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#fellow-modal"
                                    onClick={() => {
                                      setVisible({
                                        ...visible,
                                        type: "fellowship",
                                        open: true,
                                        data: {},
                                        edit_type: "add",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-plus" />
                                  </Link>
                                </div>
                              </div>
                              <div className="individual-cost">
                                <div className="row">
                                  <div className="col-md-12">
                                    {directoryData?.fellow_ships?.map(
                                      (val: any, index: any) => (
                                        <ul
                                          className="individual-type-list"
                                          key={index}
                                        >
                                          <div className="d-flex">
                                            <li>
                                              <span>
                                                Program Type&nbsp;{index + 1}:{" "}
                                              </span>{" "}
                                              {val?.program_type}{" "}
                                            </li>
                                            <Link
                                              className="pencil-icon-link"
                                              to="#"
                                              data-bs-toggle="modal"
                                              data-bs-target="#fellow-modal"
                                              onClick={() => {
                                                setVisible({
                                                  ...visible,
                                                  type: "fellowship",
                                                  open: true,
                                                  data: val,
                                                  edit_type: "edit",
                                                });
                                              }}
                                            >
                                              <i className="ti ti-pencil" />
                                            </Link>
                                          </div>
                                          <li>
                                            <span>Program Institution:</span>
                                            {val?.program_institution}
                                          </li>
                                          <li>
                                            <span>Year of Graduation: </span>
                                            {val?.year_graduation}
                                          </li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="about-edit-head about-edit-head-three">
                            <h4>
                              <img src={bookmark2Line} alt="Icon" /> Board
                              Certifications &amp; Additional Credentials
                            </h4>
                            <div className="internship-add">
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#additional-modal"
                                onClick={() => {
                                  setVisible({
                                    ...visible,
                                    type: "board-certification",
                                    open: true,
                                    data: {},
                                    edit_type: "add",
                                  });
                                }}
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                          </div>
                          {directoryData?.broad_certificate?.map(
                            (val: any, index: any) => (
                              <div
                                className="individual-cost individual-cost-two"
                                key={index}
                              >
                                <div className="d-flex justify-content-between">
                                  <h6>
                                    Certification: {val?.certificate_name}
                                  </h6>{" "}
                                  &nbsp;
                                  <Link
                                    className="pencil-icon-link"
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#additional-modal"
                                    onClick={() => {
                                      setVisible({
                                        ...visible,
                                        type: "board-certification",
                                        open: true,
                                        data: val,
                                        edit_type: "edit",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-pencil" />
                                  </Link>
                                </div>
                                <p>Specialty: {val?.specialty}</p>
                                <p>Year Obtained: {val?.year_obtained}</p>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className="finances-info">
                        <h2 className="finances-title">
                          Clinical Specialties, Expertise, &amp; Experience
                        </h2>
                        <div className="card directory-card">
                          <div className="card-body">
                            <div className="finances-details">
                              <div className="about-edit-head about-clinical-head">
                                <h4>
                                  <img src={bookmark2Line} alt="Icon" />{" "}
                                  Clinical Specialties, Expertise, &amp;
                                  Experience
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#clinical-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "clinical",
                                      open: true,
                                      data: {},
                                      userDetails,
                                      edit_type: "clinical",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="clinical-text">
                                <h6>Clinical Specialties</h6>
                                <div className="row">
                                  {directoryData?.clinical_specialties?.clinical_specialties?.map(
                                    (it: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li>{it}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="clinical-text">
                                <h6>Clinical Expertise</h6>
                                <div className="row">
                                  {directoryData?.clinical_specialties?.clinical_expertise?.map(
                                    (it: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li>{it}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="clinical-text">
                                <h6>Clinical Experience</h6>
                                <div className="row">
                                  {directoryData?.clinical_specialties?.clinical_experience?.map(
                                    (it: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li>{it}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="about-edit-head about-clinical-other">
                                <h4>
                                  <img src={bookmark2Line} alt="Icon" /> Other
                                  Clinical Specialties, Expertise, &amp;
                                  Experience
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#other-clinical-modal"
                                  onClick={() => {
                                    let val = otherClinicalBool
                                      ? "Edit"
                                      : "Add";
                                    setVisible({
                                      type: "Other-clinical",
                                      open: true,
                                      data: {},
                                      userDetails,
                                      edit_type: val,
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              {otherClinicalBool ? (
                                <>
                                  {/* {directoryData?.other_clinical_specialties
                                    ?.clinical_specialties?.length > 0 && (  )} */}
                                  <div className="clinical-text">
                                    <h6>Clinical Specialties</h6>
                                    <div className="row">
                                      {directoryData?.other_clinical_specialties?.clinical_specialties?.map(
                                        (it: any) => (
                                          <div className="col-md-6">
                                            <ul>
                                              <li>{it}</li>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>

                                  <div className="clinical-text">
                                    <h6>Clinical Expertise</h6>
                                    <div className="row">
                                      {directoryData?.other_clinical_specialties?.clinical_expertise?.map(
                                        (it: any) => (
                                          <div className="col-md-6">
                                            <ul>
                                              <li>{it}</li>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>

                                  <div className="clinical-text">
                                    <h6>Clinical Experience</h6>
                                    <div className="row">
                                      {directoryData?.other_clinical_specialties?.clinical_experience?.map(
                                        (it: any) => (
                                          <div className="col-md-6">
                                            <ul>
                                              <li>{it}</li>
                                            </ul>
                                          </div>
                                        )
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="clinical-personal-text">
                                  <p>
                                    Include "Other Clinical Specialties,
                                    Expertise, &amp; Experience" for areas not
                                    already listed
                                  </p>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#other-clinical-modal"
                                    onClick={() => {
                                      setVisible({
                                        type: "Other-clinical",
                                        open: true,
                                        data: {},
                                        userDetails,
                                        edit_type: "Add",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-plus" />
                                  </Link>
                                </div>
                              )}
                              {/* otherClinicalSpecialties */}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="finances-info">
                        <h2 className="finances-title">More Info</h2>
                        <div className="card directory-card">
                          <div className="card-body">
                            <div className="finances-details">
                              <div className="about-edit-head">
                                <h4>
                                  <img src={stethoscopeIcon} alt="Icon" />{" "}
                                  Communities Served
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#communities-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "communities",
                                      open: true,
                                      data: {},
                                      userDetails,
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="communities-text">
                                <h6>Participants</h6>
                                <div className="row">
                                  <div className="col-md-12">
                                    {directoryData?.communities_served?.participants?.map(
                                      (val: any, index: any) => (
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="communities-text">
                                <h6>Ages</h6>
                                <div className="row">
                                  <div className="col-md-6">
                                    {directoryData?.communities_served?.age?.map(
                                      (val: any, index: any) => (
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="communities-text">
                                <h6>Groups</h6>
                                <div className="row">
                                  <div className="col-md-12">
                                    <ul>
                                      <li>Aviation Professionals</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                              <div className="communities-text">
                                <h6>Ethnicity of Your Clients</h6>
                                <div className="row">
                                  <div className="col-md-6">
                                    {directoryData?.communities_served?.ethnicity_of_your_client?.map(
                                      (val: any, index: any) => (
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="communities-text">
                                <h6>Languages Spoken (Besides English)</h6>
                                <div className="row">
                                  <div className="col-md-6">
                                    <ul>
                                      <li>English</li>
                                      <li>French</li>
                                    </ul>
                                  </div>
                                  <div className="col-md-6">
                                    <ul>
                                      <li>Russian</li>
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="finances-details">
                            <div className="about-edit-head about-shared-head">
                              <h4>
                                <img src={sharedIcon} alt="Icon" /> Shared
                                Experience
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#shared-experience--modal"
                                onClick={() => {
                                  setVisible({
                                    ...visible,
                                    type: "sharedExperience",
                                    open: true,
                                    data: directoryData,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="shared-text">
                              <p>{sharedExperience && sharedExperience}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="finances-info">
                        <h2 className="finances-title">Treatment Modalities</h2>
                        <div className="card directory-card">
                          <div className="card-body">
                            <div className="finances-details">
                              <div className="about-edit-head about-treatment-head">
                                <h4>
                                  <img src={bookmark2Line} alt="Icon" />{" "}
                                  Treatment Modality Specialty, Expertise, or
                                  Experience.
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#treatment-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "treatment",
                                      open: true,
                                      data: {},
                                      userDetails,
                                      edit_type: "treatment",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              <div className="clinical-text">
                                <h6>Treatment Modality</h6>
                                <div className="row">
                                  {directoryData?.treatment_modality_specialty?.treatment_modality?.map(
                                    (val: any, index: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="clinical-text">
                                <h6>Treatment Expertise</h6>
                                <div className="row">
                                  {directoryData?.treatment_modality_specialty?.treatment_expertise?.map(
                                    (val: any, index: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="clinical-text">
                                <h6>Treatment Experience</h6>
                                <div className="row">
                                  {directoryData?.treatment_modality_specialty?.treatment_experience?.map(
                                    (val: any, index: any) => (
                                      <div className="col-md-6">
                                        <ul>
                                          <li key={index}>{val}</li>
                                        </ul>
                                      </div>
                                    )
                                  )}
                                </div>
                              </div>
                              <div className="about-edit-head about-modality-head">
                                <h4>
                                  <img src={bookmark2Line} alt="Icon" /> Other
                                  Treatment Modality Specialty, Expertise, or
                                  Experience.
                                </h4>
                                <Link
                                  to="#"
                                  data-bs-toggle="modal"
                                  data-bs-target="#other-treatment-modal"
                                  onClick={() => {
                                    setVisible({
                                      type: "Other-treatment",
                                      open: true,
                                      data: {},
                                      userDetails,
                                      edit_type: "edit",
                                    });
                                  }}
                                >
                                  <i className="ti ti-pencil" />
                                </Link>
                              </div>
                              {otherTreatmentBool ? (
                                <>
                                  <div className="clinical-text">
                                    <h6>Treatment modalities</h6>
                                    <div className="row">
                                      <div className="col-md-6">
                                        {directoryData?.other_treatment_modality_specialty?.treatment_modality?.map(
                                          (val: any, index: any) => (
                                            <ul>
                                              <li key={index}>{val}</li>
                                            </ul>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="clinical-text">
                                    <h6>Treatment expertise</h6>
                                    <div className="row">
                                      <div className="col-md-6">
                                        {directoryData?.other_treatment_modality_specialty?.treatment_expertise?.map(
                                          (val: any, index: any) => (
                                            <ul>
                                              <li key={index}>{val}</li>
                                            </ul>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="clinical-text">
                                    <h6>Treatment experience</h6>
                                    <div className="row">
                                      <div className="col-md-6">
                                        {directoryData?.other_treatment_modality_specialty?.treatment_experience?.map(
                                          (val: any, index: any) => (
                                            <ul>
                                              <li key={index}>{val}</li>
                                            </ul>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <div className="clinical-personal-text">
                                  <p>
                                    Include "Other Clinical Specialties,
                                    Expertise, &amp; Experience" for areas not
                                    already listed
                                  </p>
                                  <Link
                                    to="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#other-treatment-modal"
                                    onClick={() => {
                                      setVisible({
                                        type: "Other-treatment",
                                        open: true,
                                        data: {},
                                        userDetails,
                                        edit_type: "Add",
                                      });
                                    }}
                                  >
                                    <i className="ti ti-plus" />
                                  </Link>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-5 custom-col">
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="finances-details">
                            <div className="about-edit-head about-phone-head">
                              <h4>
                                <img src={editPhoneIcon} alt="Icon" /> Phone
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add-phone"
                                onClick={() => {
                                  setVisible({
                                    type: "phone",
                                    open: true,
                                    data: {
                                      phone: directoryData?.phone,
                                    },
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="about-phone-text">
                              <p className="mw-100">
                                All calls will be forwarded to &nbsp; <br />
                                {/* {parsePhoneNumberFromString(
                                    `${directoryData?.phone}`
                                  )?.formatInternational()} */}
                                {directoryData?.phone
                                  ? directoryData?.phone?.includes("+")
                                    ? directoryData?.phone
                                    : `+${directoryData?.phone}`
                                  : ""}
                              </p>
                            </div>
                            <div className="about-edit-head about-phone-head">
                              <h4>
                                <img src={editMailIcon} alt="Icon" /> Email
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add-email"
                                onClick={() => {
                                  setVisible({
                                    type: "email",
                                    open: true,
                                    data: {
                                      email: directoryData?.email,
                                    },
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="about-email-text">
                              <p className="mw-100">
                                All emails will be sent to <br />
                                {directoryData?.email
                                  ? directoryData?.email
                                  : ""}
                              </p>
                              {emailVerified.mail_verified === false ? (
                                <Link
                                  to="#"
                                  onClick={() => {
                                    handleGenerateEmail();
                                  }}
                                >
                                  Verify your email to ensure delivery
                                </Link>
                              ) : (
                                <img src={verifiedEmail} alt="Icon" />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="finances-details">
                            <div className="about-edit-head about-phone-head">
                              <h4>
                                <img src={editMapIcon} alt="Icon" /> Primary
                                Location
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#location-modal"
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="about-phone-text">
                              <p>
                                {
                                  directoryData?.location?.primary_location
                                    ?.address
                                }
                              </p>
                            </div>
                            <div className="about-edit-head about-phone-head">
                              <h4>
                                <img src={editMapIcon} alt="Icon" /> Secondary
                                Location
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#location-modal"
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="about-email-text">
                              <p>
                                {
                                  directoryData?.location?.secondary_location
                                    ?.address
                                }
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="finances-details">
                            <div className="about-edit-head about-phone-head">
                              <h4>
                                <img src={editCalendarIcon} alt="Icon" />{" "}
                                Availability
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#availability-modal"
                                onClick={() => {
                                  setVisible({
                                    type: "availablity",
                                    open: true,
                                    data: directoryData?.availability,
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="edit-availability-info">
                              <p>
                                Are you accepting new clients? Be sure to keep
                                your availability up to date.
                              </p>
                              <ul>
                                {directoryData?.availability?.in_person && (
                                  <li>
                                    <img src={userLine} alt="Icon" /> In-Person
                                  </li>
                                )}
                                {directoryData?.availability
                                  ?.virtual_consultation && (
                                  <li>
                                    <img src={vidiconLine} alt="Icon" /> Virtual
                                    Consultations
                                  </li>
                                )}
                              </ul>
                            </div>
                            <div className="about-edit-head about-clients-head">
                              <h4>
                                <img src={editDoubleQuotes} alt="Icon" /> Intro
                                for new clients
                              </h4>
                            </div>
                            <div className="clients-text">
                              <p>{`${trimIntroDescription}${introDescription.length > charLimit ? "..." : ""}`}</p>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#availability-modal"
                                onClick={() => {
                                  setVisible({
                                    type: "availablity",
                                    open: true,
                                    data: directoryData?.availability,
                                    userDetails,
                                    edit_type: "edit",
                                  });
                                }}
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card directory-card">
                        <div className="card-body">
                          <div className="finances-details">
                            <div className="about-edit-head about-add-links">
                              <h4>
                                <img src={editCalendarIcon} alt="Icon" /> Add
                                Links
                              </h4>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add-links"
                              >
                                <i className="ti ti-pencil" />
                              </Link>
                            </div>
                            <div className="edit-links-info">
                              <p>
                                The section allows you to seamlessly incorporate
                                hyperlinks into your text.{" "}
                              </p>
                              <ul>
                                <li>
                                  <Link to="#">
                                    <img src={editLinkIcon} alt="Icon" />
                                    <span>lorem Ipsum link</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={editLinkIcon} alt="Icon" />
                                    <span>resources, or documents</span>
                                  </Link>
                                </li>
                                <li>
                                  <Link to="#">
                                    <img src={editLinkIcon} alt="Icon" />
                                    <span>enhance the informational </span>
                                  </Link>
                                </li>
                              </ul>
                            </div>
                            <div className="clinical-personal-text">
                              <p>Add New Url links</p>
                              <Link
                                to="#"
                                data-bs-toggle="modal"
                                data-bs-target="#add-links"
                              >
                                <i className="ti ti-plus" />
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="facility-tab"
                  role="tabpanel"
                  aria-labelledby="facility_tab"
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="facility-search">
                        <div className="facility-input">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search By Name/Address"
                          />
                        </div>
                        <div className="facility-btn">
                          <button className="btn btn-primary rounded">
                            <img src={facilityIcon} alt="Icon" /> Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="facility-table">
                        <h4>Request sent to facility</h4>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th />
                                <th>Provider Name</th>
                                <th>Subscribers</th>
                                <th>Followers</th>
                                <th>Request Date</th>
                                <th>Status</th>
                                <th />
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td />
                                <td>
                                  <Link to="#" className="facility-name">
                                    James Carter
                                  </Link>
                                </td>
                                <td>123</td>
                                <td>1,500</td>
                                <td>August 22, 2024</td>
                                <td>
                                  <span className="table-badge status5">
                                    <i className="ti ti-point-filled" />
                                    Pending Approvel
                                  </span>
                                </td>
                                <td>
                                  <Link to="#" className="facility-link">
                                    <i className="fas fa-ellipsis-vertical" />
                                  </Link>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      {/* Basic Modal */}
      <BasicModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
      />
      {/* /Basic Modal */}
      {/* Finances Modal */}
      <FinanceModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
        financialInsurance={financialInsurance}
      />
      {/* /Finances Modal */}
      {/* Credential Modal */}

      <PrimaryCredentialModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
        clinicalExpertiseData={clinicalExpertiseData}
        directoryData={directoryData}
        clinicalRoleOption={clinicalRoleOption}
        GetPrimaryCredentials={GetPrimaryCredentials}
      />

      {/* /Credential Modal */}
      {/* Education Modal */}

      <EducationModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
        directoryData={directoryData}
      />

      {/* /Education Modal */}
      {/* Internship Modal */}

      <InternshipModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
      />

      {/* /Internship Modal */}
      {/* Residency Modal */}

      <ResidencyModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
      />

      {/* /Residency Modal */}
      {/* Fellowships Modal */}

      <FellowshipsModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
      />

      {/* /Fellowships Modal */}
      {/* Board Modal */}

      <BoardCirtificationModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
      />

      {/* /Board Modal */}
      {/* Clinical Modal */}

      <ClinicalModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
        clinicalExpertiseData={clinicalExpertiseData}
        directoryData={directoryData}
      />

      <OtherClinicalModel
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
        clinicalExpertiseData={clinicalExpertiseData}
        directoryData={directoryData}
      />
      {/* /Clinical Modal */}
      {/* Communities Modal */}

      <CommunitiesModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
        directoryData={directoryData}
      />

      {/* /Communities Modal */}
      {/* Phone Modal */}
      {/* /Phone Modal */}
      {/* shared experience modal */}
      <SharedExperienceModal
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
        getSharedExperience={getSharedExperience}
      />

      {/* treatment modal */}

      <TreatmentModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
        clinicalExpertiseData={clinicalExpertiseData}
        directoryData={directoryData}
      />

      <OtherTreatmentModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
        clinicalExpertiseData={clinicalExpertiseData}
        directoryData={directoryData}
      />
      {/* Email Modal */}

      <EmailModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
      />

      <PhoneModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
      />

      {/* /Email Modal */}
      {/* Location Modal */}
      <LocationModal
        visible={visible}
        handleClose={HandleClose}
        directoryData={directoryData}
        getDirectoryData={getDirectoryData}
      />
      {/* /Location Modal */}
      {/* Gallery Modal */}
      <GalleryModal
        getDirectoryData={getDirectoryData}
        directoryData={directoryData}
      />
      {/* /Gallery Modal */}
      {/* Gallery Modal */}
      <div
        className="modal fade gallery-modal"
        id="media-upload-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header modal-header-tabs">
              <h5 className="modal-title">Upload Profile Photo</h5>
              <div className="modal-tabs">
                <ul className="nav nav-tabs" id="uploadTab" role="tablist">
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link"
                      id="upload_tab2"
                      data-bs-toggle="tab"
                      to="#upload-tab2"
                      role="tab"
                      aria-controls="upload-tab2"
                      aria-selected="true"
                    >
                      Upload File
                    </Link>
                  </li>
                  <li className="nav-item" role="presentation">
                    <Link
                      className="nav-link active"
                      id="media_tab2"
                      data-bs-toggle="tab"
                      to="#media-tab2"
                      role="tab"
                      aria-controls="media-tab2"
                      aria-selected="true"
                    >
                      Media Library
                    </Link>
                  </li>
                </ul>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form action="#">
                <div className="tab-content" id="uploadTabContent">
                  <div
                    className="tab-pane fade"
                    id="upload-tab2"
                    role="tabpanel"
                    aria-labelledby="upload_tab2"
                  >
                    <div className="form-upload">
                      <div className="row justify-content-center">
                        <div className="col-md-5">
                          <div className="form-upload-info">
                            <img src={uploadIcon} alt="Icon" />
                            <h6>
                              Drag &amp; drop files or
                              <label className="choose-file">
                                <input type="file" />
                                <span>Browse</span>
                              </label>
                            </h6>
                            <p>
                              Supported formates for profile image: JPEG,
                              PNG,JPG
                            </p>
                            <div className="form-upload-btn">
                              <button
                                className="btn btn-primary w-100 rounded"
                                type="submit"
                              >
                                Upload File
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade show active"
                    id="media-tab2"
                    role="tabpanel"
                    aria-labelledby="media_tab2"
                  >
                    <div className="row">
                      <div className="col-xl-8 col-lg-12 d-flex">
                        <div className="media-icons-info w-100">
                          <ul className="nav">
                            <li>
                              <Link to="#" className="active">
                                <img src={media01Svg} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media02} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media03} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media04} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media05} alt="Icon" />
                              </Link>
                            </li>
                            <li>
                              <Link to="#">
                                <img src={media06} alt="Icon" />
                              </Link>
                            </li>
                          </ul>
                          <div className="media-upload">
                            <img
                              src={mediaUpload}
                              className="img-fluid"
                              alt="Media"
                            />
                          </div>
                          <div className="media-btns">
                            <Link to="#" className="btn btn-cancel">
                              Cancel
                            </Link>
                            <Link to="#" className="btn btn-primary">
                              Save
                            </Link>
                          </div>
                          <div className="media-footer-inner">
                            <p>Image Size: 5MB</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-4 col-lg-12 d-flex">
                        <div className="media-grid w-100">
                          <div className="media-scale-head">
                            <h3>Scale Image</h3>
                            <p>Original dimensions 2000 * 1303</p>
                          </div>
                          <div className="media-scale-info">
                            <div className="media-nav">
                              <div className="media-scale-box">
                                <p>2000</p>
                              </div>
                              <div className="media-x">x</div>
                              <div className="media-scale-box">
                                <p>1800</p>
                              </div>
                              <div className="media-scale-btn">
                                <Link to="#" className="btn btn-primary">
                                  scale
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="media-scale-input">
                            <h4>Image Crop</h4>
                            <div className="media-input">
                              <p>Aspect ratio:</p>
                              <div className="media-form-control">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder={"1400"}
                                  defaultValue={1400}
                                />
                              </div>
                              <div className="media-x">
                                <span>x</span>
                              </div>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                            <div className="media-input">
                              <p>Selection:</p>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                              <div className="media-x">
                                <span>x</span>
                              </div>
                              <div className="media-form-control">
                                <input type="text" className="form-control" />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Gallery Modal */}
      {/* Add Links Modal */}
      <AddLink
        visible={visible}
        handleClose={HandleClose}
        getDirectoryData={getDirectoryData}
        directoryData={directoryData}
      />
      {/* /Add Links Modal */}
      {/* Availability Modal */}

      <AvailablityModal
        visible={visible}
        handleClose={HandleClose}
        designationsList={designationsList}
        getDirectoryData={getDirectoryData}
      />

      {/* /Availability Modal */}
      <IdentityModal
        visible={visible}
        getDirectoryData={getDirectoryData}
        handleClose={HandleClose}
        directoryData={directoryData}
        faithMyIdentity={faithMyIdentity}
        raceMyIdentity={raceMyIdentity}
      />
    </>
  );
};

export default Directory;
