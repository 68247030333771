import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  insurancePaymentMethodSchema,
  outOfNetworkSchema,
  paymentMethodSchema,
  sessionCostSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import {
  onlyNumber,
  pricePattern,
  sessionMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: any;
  financialInsurance?: any;
}

const FinanceModal = (props: props) => {
  const financeModalRef = useRef();
  const userDetails = useSelector((state: any) => state.common.userDetails);
  const { type, open, data, edit_type } = props.visible;
  const { getDirectoryData, financialInsurance } = props;

  const initialValues = {
    cost: "",
    payment_method: [],
    insurance_method: [],
    out_of_network: false,
  };

  const financeModalSchema = Yup.object().shape({
    cost: sessionCostSchema,
    payment_method: paymentMethodSchema,
    insurance_method: insurancePaymentMethodSchema,
    out_of_network: outOfNetworkSchema,
  });

  const OnClose = () => {
    reset(initialValues);
    financeModalRef.current.click();
    props.handleClose();
  };

  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
    watch,
    trigger,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(financeModalSchema),
    defaultValues: initialValues,
  });

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        finance: {
          cost: data?.cost,
          payment_method: data?.payment_method,
          insurance_method: data?.insurance_method,
          out_of_network: data?.out_of_network,
        },
      };
      const response = await postData(
        `${end_points.userprofile_finance_create.url}`,
        payload
      );
      if (response.status == 201) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handlePriceChange = (e: any) => {
    let value = e.target.value;
    if (value === "" || /^\d*\.?\d{0,2}$/.test(value)) {
      const parts = value.split(".");
      if (parts.length > 2) {
        value = parts[0] + "." + parts[1];
      }
      setValue("cost", value);
      trigger("cost");
    }
  };

  useEffect(() => {
    if (data !== undefined && Object.keys(data)?.length > 0) {
      if (data?.cost !== "") {
        reset(data);
      }
    }
  }, [data]);

  useEffect(() => {
    console.log("FORMVALUES", watch(), data, financialInsurance);
  }, [watch()]);

  return (
    <div
      className="modal fade finances-modal"
      id="finances-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Finances</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={financeModalRef}
              onClick={OnClose}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <label>Individual Session Cost</label>
                <div className="dollar-input">
                  <Controller
                    name="cost"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="text"
                        className="form-control"
                        value={value}
                        placeholder="49.99"
                        {...register("cost")}
                        onChange={handlePriceChange}
                        onKeyDown={(e) => {
                          const key = e.key;
                          const allowedKeys = [
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            ".",
                          ];
                          if (
                            !onlyNumber.test(key) &&
                            !allowedKeys.includes(key)
                          ) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={sessionMaxLength}
                      />
                    )}
                  />
                  <span className="dollar-sym">$</span>
                </div>
                <ErrorMessage value={errors.cost} />
              </div>

              <div className="form-wrap">
                <h4>Payment Method</h4>
                <Controller
                  name="payment_method"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ul className="nav payment-method-list payment-method-nav">
                      {[
                        "Cash",
                        "Mastercard",
                        "Check",
                        "UPi",
                        "Net banking",
                      ].map((method) => (
                        <li key={method}>
                          <label className="custom_check">
                            <input
                              type="checkbox"
                              value={method}
                              checked={value?.includes(method)}
                              onChange={(e) => {
                                const newValue = e.target.checked
                                  ? [...value, method]
                                  : value.filter((v) => v !== method);
                                onChange(newValue);
                              }}
                            />
                            <span className="checkmark" /> {method}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                />
                <ErrorMessage value={errors.payment_method} />
              </div>

              <div className="form-wrap">
                <h4>Insurance Payment Method</h4>
                <Controller
                  name="insurance_method"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <ul className="nav payment-method-list payment-method-nav">
                      {financialInsurance !== undefined &&
                        financialInsurance?.length > 0 &&
                        financialInsurance?.map((method: any) => (
                          <li key={method?.value}>
                            <label className="custom_check">
                              <input
                                type="checkbox"
                                value={method?.label}
                                checked={value.includes(method?.label)}
                                onChange={(e) => {
                                  const newValue = e.target.checked
                                    ? [...value, method?.label]
                                    : value.filter((v) => v !== method?.label);
                                  onChange(newValue);
                                }}
                              />
                              <span className="checkmark" /> {method?.label}
                            </label>
                          </li>
                        ))}
                    </ul>
                  )}
                />
                <ErrorMessage value={errors.insurance_method} />
              </div>

              <div className="form-wrap">
                <h6>Out-of-Network</h6>
                <label className="custom_check">
                  <Controller
                    name="out_of_network"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <input
                        type="checkbox"
                        {...register("out_of_network")}
                        checked={value}
                        onChange={(e: any) => {
                          setValue("out_of_network", e.target.checked);
                          trigger("out_of_network");
                        }}
                      />
                    )}
                  />
                  <span className="checkmark" /> I accept clients claiming on
                  their OON benefits.
                </label>
                <ErrorMessage value={errors.out_of_network} />
              </div>

              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinanceModal;
