import { userRole } from '../assets/global';

interface Route {
  path: string;
  activeLink: string;
}
export const routes: Record<string, Route> = {
  welcome: { path: '/admin/welcome', activeLink: 'welcome' },
  frontDeskWelcome: {
    path: `/${userRole.FrontDesk}/welcome`,
    activeLink: 'welcome',
  },
  dashboard: { path: '/admin/dashboard', activeLink: 'dashboard' },
  emergencyList: {
    path: '/doctor/emergency/list',
    activeLink: 'emergency/list',
  },
  emergencyDetail: {
    path: '/doctor/emergency/detail',
    activeLink: 'emergency/detail',
  },

  // authentication routes
  login: { path: '/admin/login', activeLink: 'login' },
  frontDesklogin: { path: `/${userRole.FrontDesk}/login`, activeLink: 'login' },
  confirmPassword: {
    path: '/admin/confirm-password',
    activeLink: 'confirm-password',
  },
  frontDeskconfirmPassword: {
    path: `/${userRole.FrontDesk}/confirm-password`,
    activeLink: 'confirm-password',
  },
  otp: { path: '/admin/otp', activeLink: 'otp' },
  frontDeskotp: { path: `/${userRole.FrontDesk}/otp`, activeLink: 'otp' },
  register: { path: '/admin/register', activeLink: 'register' },
  frontDeskregister: {
    path: `/${userRole.FrontDesk}/register`,
    activeLink: 'register',
  },
  forgotPassword: {
    path: '/admin/forgot-password',
    activeLink: 'forgot-password',
  },
  frontDeskforgotPassword: {
    path: `/${userRole.FrontDesk}/forgot-password`,
    activeLink: 'forgot-password',
  },
  forgotPasswordOtp: {
    path: '/admin/forgot-password-otp',
    activeLink: 'forgot-password-otp',
  },
  frontDeskforgotPasswordOtp: {
    path: `/${userRole.FrontDesk}/forgot-password-otp`,
    activeLink: 'forgot-password-otp',
  },
  loginOtp: { path: '/admin/login-otp', activeLink: 'login-otp' },
  frontDeskloginOtp: {
    path: `/${userRole.FrontDesk}/login-otp`,
    activeLink: 'login-otp',
  },
  doctorLogin: {
    path: '/doctor/login',
    activeLink: 'login',
  },
  doctorconfirmPassword: {
    path: '/doctor/confirm-password',
    activeLink: 'confirm-password',
  },
  doctorotp: {
    path: '/doctor/otp',
    activeLink: 'otp',
  },
  doctorforgotPassword: {
    path: '/doctor/forgot-password',
    activeLink: 'forgot-password',
  },
  doctorloginOtp: {
    path: '/doctor/login-otp',
    activeLink: 'login-otp',
  },
  doctorforgotPasswordOtp: {
    path: '/doctor/forgot-password-otp',
    activeLink: 'forgot-password-otp',
  },
  doctorWelcome: {
    path: '/doctor/welcome',
    activeLink: 'welcome',
  },
  // doctors routes
  doctorsAssociate: {
    path: '/admin/doctors-associate',
    activeLink: 'doctors-associate',
  },
  doctorCreate: { path: '/admin/doctor-create', activeLink: 'doctor-create' },

  doctorsList: { path: '/admin/doctors-list', activeLink: 'doctors-list' },
  doctorEdit: { path: '/admin/doctor-edit', activeLink: 'doctor-edit' },
  frontDeskDoctorsList: {
    path: `/${userRole.FrontDesk}/doctors-list`,
    activeLink: 'doctors-list',
  },

  // kiosk routes
  kioskAssociate: {
    path: '/admin/kiosk-associate',
    activeLink: 'kiosk-associate',
  },
  kioskCreate: { path: '/admin/kiosk-create', activeLink: 'kiosk-create' },
  kioskEdit: { path: '/admin/kiosk-edit', activeLink: 'kiosk-edit' },
  kioskList: {
    path: '/admin/kiosk-list',
    activeLink: 'kiosk-list',
  },
  kioskUserList: {
    path: '/admin/kiosk-user-list',
    activeLink: 'kiosk-user-list',
  },

  // frontdesk routes
  frontDeskAssociate: {
    path: '/admin/front-desk-associate',
    activeLink: 'front-desk-associate',
  },
  frontDeskCreate: {
    path: '/admin/front-desk-create',
    activeLink: 'front-desk-create',
  },
  frontDeskList: {
    path: '/admin/front-desk-list',
    activeLink: 'front-desk-list',
  },
  frontDeskEdit: {
    path: '/admin/front-desk-edit',
    activeLink: 'front-desk-edit',
  },

  frontDeskView: {
    path: '/admin/front-desk-view',
    activeLink: 'front-desk-view',
  },
  frontDeskCalendar: {
    path: `/${userRole.FrontDesk}/doctor-slot-calendar`,
    activeLink: 'doctor-slot-calendar',
  },

  // paramedic routes
  paramedicView: {
    path: '/admin/paramedic-view',
    activeLink: 'paramedic-view',
  },
  paramedicAssociate: {
    path: '/admin/paramedic-associate',
    activeLink: 'paramedic-associate',
  },
  paramedicCreate: {
    path: '/admin/paramedic-create',
    activeLink: 'paramedic-create',
  },
  paramedicList: {
    path: '/admin/paramedic-list',
    activeLink: 'paramedic-list',
  },
  paramedicEdit: {
    path: '/admin/paramedic-edit',
    activeLink: 'paramedic-edit',
  },
  paramedicReAssociate: {
    path: '/admin/paramedic-re-associate',
    activeLink: 'paramedic-re-associate',
  },

  frontDeskPatientList: {
    path: `/${userRole.FrontDesk}/patient-list`,
    activeLink: 'patient-list',
  },
  frontDeskPastConsult: {
    path: `/${userRole.FrontDesk}/past-consult`,
    activeLink: 'past-consult',
  },
  frontDeskTodayConsult: {
    path: `/${userRole.FrontDesk}/today-consult`,
    activeLink: 'today-consult',
  },
  frontDeskPatientRegistration: {
    path: `/${userRole.FrontDesk}/patient-registration`,
    activeLink: 'patient-registration',
  },
  criticalPatientList: {
    path: '/doctor/critical-patient-list',
    activeLink: 'critical-patient-list',
  },

  logout: { path: '/logout', activeLink: 'logout' },
  doctorScheduledSlots: {
    path: '/admin/doctor-scheduled-slots',
    activeLink: '/admin/doctor-scheduled-slots',
  },
  // common
  successComponent: { path: '/success&Update', activeLink: 'success&Update' },

  //  doctor flow

  // auth
  doctorDashboard: {
    path: '/doctor/dashboard',
    activeLink: 'dashboard',
  },
  doctorNotification: {
    path: '/doctor/notification',
    activeLink: 'notification',
  },
  adminNotification: {
    path: '/admin/notification',
    activeLink: 'notification',
  },
  frontDeskNotification: {
    path: '/front-desk/notification',
    activeLink: 'notification',
  },
  pastConsults: { path: '/doctor/past-consults', activeLink: 'past-consults' },
  scheduledSlots: {
    path: '/doctor/scheduled-slots',
    activeLink: 'scheduled-slots',
  },
  appointmentDetails: {
    path: '/doctor/appointment-details',
    activeLink: 'appointment-details',
  },
  joinConsult: {
    path: '/doctor/joinConsultation',
    activeLink: 'joinConsultation',
  },
  videoCall: {
    path: '/doctor/videoCall',
    activeLink: 'videoCall',
  },
  videoCallTwo: {
    path: '/doctor/videoCallTwo',
    activeLink: 'videoCall',
  },
};
