import React, { useEffect, useState } from "react";
import { logo, logoWhite } from "../../utils/imagepath";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { end_points } from "../../services/endpoints";
import { postData } from "../../services/api.service";
import { routes } from "../../utils/routes/routes";
import { Link, useNavigate } from "react-router-dom";

const VerifyEmail = () => {
  const [emailResponse, setEmailResponse] = useState<any>(false);

  const handleVerifyEmail = async () => {
    try {
      showLoader();
      let urls = end_points.verifyEmail.url;

      const fullUrl = window.location.href;

      const token = fullUrl.split("/").pop();
      const payload = {
        encryptURL: token,
      };

      const response = await postData(urls, payload);
      if (response?.status === 201) {
        setEmailResponse(true);
      } else {
        setEmailResponse(false);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    handleVerifyEmail();
  }, []);

  return (
    <>
      <div className="verify-wrapper">
        {/* Verify Header */}
        <div className="verify-header">
          <div className="container">
            <div className="verify-logo">
              <Link to="#">
                <img src={logo} className="img-fluid" alt="Logo" />
              </Link>
            </div>
          </div>
        </div>
        {/* /Verify Header */}
        {/* Verify Section */}
        <section className="verify-section">
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-6">
                <div className="card verify-card">
                  <div className="card-body">
                    {emailResponse && (
                      <i className="fas fa-circle-check text-success" />
                    )}
                    {!emailResponse && (
                      <i className="fas fa-circle-xmark text-danger" />
                    )}
                    <h4>Verify Email</h4>
                    <p>Your email has been successfully verified</p>
                    <Link
                      to={routes.providerLogin}
                      className="btn btn-primary rounded"
                    >
                      Back to login
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* /Verify Section */}
        {/*Footer*/}
        <div className="footer">
          <img src={logoWhite} alt="" />
          <h6>© 2024 TurningWell. All rights reserved</h6>
        </div>
        {/* /Footer */}
      </div>
    </>
  );
};

export default VerifyEmail;
