import React, { createContext, useEffect, useState, useCallback } from 'react';

interface SpinnerContextType {
  isLoading: boolean;
  showLoader: (type?: string) => void;
  hideLoader: (type?: string) => void;
}

interface Props {
  children: React.ReactNode;
}

export const SpinnerContext = createContext<SpinnerContextType>({
  isLoading: false,
  showLoader: () => {},
  hideLoader: () => {},
});

const SpinnerProvider: React.FC<Props> = ({ children }) => {
  const [loaderCount, setLoaderCount] = useState(0);
  const [loaderMsg, setLoaderMsg] = useState('');

  const showLoader = useCallback((msg = '') => {
    msg && setLoaderMsg(msg);
    setLoaderCount((prevCount) => prevCount + 1);
  }, []);

  const hideLoader = useCallback((msg = '') => {
    setTimeout(() => {
      if (loaderMsg && !msg && loaderCount < 2) {
        setLoaderMsg('');
        setLoaderCount(0);
      }
      if (msg === 'video') {
        setLoaderMsg('');
        setLoaderCount(0);
      } else setLoaderCount((prevCount) => Math.max(prevCount - 1, 0));
    }, 500);
  }, []);

  return (
    <SpinnerContext.Provider
      value={{
        isLoading: loaderCount > 0,
        showLoader,
        hideLoader,
      }}
    >
      {children}
      {loaderCount > 0 && (
        <div className="loading loaderInitialHeight">
          <div className="loaderInitial"></div>
          {loaderMsg && (
            <div className="loader-content">
              <h6>{loaderMsg}</h6>
            </div>
          )}
        </div>
      )}
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
