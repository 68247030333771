export const setRegisterEmail=(value:string)=>value&&localStorage.setItem('email',value)
export const getRegisterEmail=()=>localStorage.getItem('email')


export const setRegisterUserId=(value:string)=>value&&localStorage.setItem('user_id',value)
export const getRegisterUserId=()=>localStorage.getItem('user_id')

export const roleType =()=> window.location.pathname.split("/")[1]

export enum socialType {
    google = "Google"
}

