import React from "react";
import AppRouter from "./app.router";
import store from "./core/redux/store";
import "./assets/provider/assets/icons/feather/css/iconfont.css";
import "./assets/provider/assets/icons/fontawesome/css/fontawesome.min.css";
import "./assets/provider/assets/icons/fontawesome/css/all.min.css";
import "./assets/provider/assets/tabler-icons/tabler-icons.css";
import { Provider } from "react-redux";

import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-daterangepicker/daterangepicker.css";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "react-toastify/dist/ReactToastify.css";

// import "./assets/css/style.css";
// import "./assets/provider/assets/css/admin.css";
import "./index.css";
import "./custom.css";
import SpinnerProvider from "./components/spinner/spinner";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function App() {
  return (
    <>
      <Provider store={store}>
        <ToastContainer autoClose={2000}/>
        <SpinnerProvider>
          <AppRouter />
        </SpinnerProvider>
      </Provider>
    </>
  );
}

export default App;
