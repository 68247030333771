import React, { useState } from "react";

import { Link } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { routes } from "../../utils/routes/routes";
import { avatar04, avatarProfile, cal, filter } from "../../utils/imagepath";
import { Calendar } from "primereact/calendar";

const Events = () => {
  const [selectedOther, setSelectedOther] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [time1, setTime1] = useState(null);
  const [time2, setTime2] = useState(null);

  const otherOptions = [
    { label: "Other", value: "Other" },
    { label: "Category", value: "Category" },
  ];

  const categoryOptions = [{ label: "Category", value: "Category" }];

  const subCategoryOptions = [{ label: "SubCategory", value: "SubCategory" }];

  const groupOptions = [{ label: "Group", value: "Group" }];

  const [rangeText, setRangeText] = useState("");

  const initialSettings = {
    endDate: moment(),
    ranges: {
      Today: [moment(), moment()],
      Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
      "Last 7 Days": [moment().subtract(6, "days"), moment()],
      "Last 30 Days": [moment().subtract(29, "days"), moment()],
      "This Month": [moment().startOf("month"), moment().endOf("month")],
      "Last Month": [
        moment().subtract(1, "month").startOf("month"),
        moment().subtract(1, "month").endOf("month"),
      ],
    },
    startDate: moment().subtract(6, "days"),
    timePicker: false,
    locale: {
      format: "MM/DD/YYYY",
    },
  };


  return (
    <>
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="breadcrumb-blk">
                  <ul className="flex-style m-0">
                    <li>Events</li>
                  </ul>
                  <p className="calendar-para">
                    Discover a variety of events from new providers, including
                    local, national, and global events.
                  </p>
                </div>
                <div className="calendar-list">
                  <ul>
                    <li>
                      <Link to="#">
                        <i className="ti ti-calendar-month" />
                      </Link>
                    </li>
                    <li>
                      <Link to="#" className="active">
                        <i className="ti ti-list" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="d-flex mb-4 align-items-center">
                <Link
                  to={routes.addVideo}
                  className="btn btn-add-outline"
                  data-bs-toggle="modal"
                  data-bs-target="#addevents"
                >
                  <i className="ti ti-plus me-2" />
                  Add Event
                </Link>
                <div className="ms-auto filter-div">
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle px-3"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      <img src={filter} className="me-2" alt="" /> Filter{" "}
                      <i className="ti ti-chevron-down ms-2" />
                    </Link>
                    <div className="dropdown-menu dropdown-menu-end filter-sets">
                      <h5>Filter</h5>
                      <ul>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            All Event
                          </label>
                        </li>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            Local
                          </label>
                        </li>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            National
                          </label>
                        </li>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            Global
                          </label>
                        </li>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            In Person
                          </label>
                        </li>
                        <li>
                          <label className="ms-auto custom_check">
                            <input type="checkbox" />
                            <span className="checkmark" />
                            Online Events
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="event-wrapper">
                <div className="event-item event-info d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>May</h5>
                    <p>25</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Psychopharmacology Updates</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-info-badge">
                      <i className="ti ti-point-filled me-1" />
                      Unable to Reach
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item event-warn d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>May</h5>
                    <p>21</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatar04} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Mindfulness Techniques for Stress Relief</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-warn-badge">
                      <i className="ti ti-point-filled me-1" />
                      Video Release
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>June</h5>
                    <p>04</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Nurse License Defense Panel Discussion</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge">
                      <i className="ti ti-point-filled me-1" />
                      Post Release
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item event-success d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>July</h5>
                    <p>05</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Fitness and Nutrition Tips</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-success-badge">
                      <i className="ti ti-point-filled me-1" />
                      TW Stream
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item event-info d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>May</h5>
                    <p>25</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Psychopharmacology Updates</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-info-badge">
                      <i className="ti ti-point-filled me-1" />
                      Unable to Reach
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item event-warn d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>May</h5>
                    <p>21</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatar04} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Mindfulness Techniques for Stress Relief</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-warn-badge">
                      <i className="ti ti-point-filled me-1" />
                      Video Release
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>June</h5>
                    <p>04</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Nurse License Defense Panel Discussion</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge">
                      <i className="ti ti-point-filled me-1" />
                      Post Release
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
                <div className="event-item event-success d-flex flex-wrap align-items-center">
                  <div className="event-timing">
                    <h5>July</h5>
                    <p>05</p>
                  </div>
                  <div className="event-info d-flex align-items-center me-auto gap-3">
                    <div className="img-blk">
                      <img src={avatarProfile} alt="" />
                    </div>
                    <div className="event-desc">
                      <h5>Fitness and Nutrition Tips</h5>
                      <p>
                        <span>Susan Reynolds</span>
                        <span>2:00 PM to 3:00 PM</span>
                      </p>
                    </div>
                  </div>
                  <div className="event-type">
                    <span className="event-badge event-success-badge">
                      <i className="ti ti-point-filled me-1" />
                      TW Stream
                    </span>
                  </div>
                  <Link to="#" className="btn btn-submit ms-auto add-video">
                    Edit Event
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Page Wrapper */}
      <div
        className="modal fade customized-modal"
        id="addevents"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div>
                <h5 className="modal-title">Add Event</h5>
                <p>
                Add Event is A Simple Tool For Scheduling And Managing Events.
                </p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <form>
                <div className="row">
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Title:</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Long Event"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Category:</label>
                      <Dropdown
                        value={selectedOther}
                        options={otherOptions}
                        onChange={(e) => setSelectedOther(e.value)}
                        placeholder="Select"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Category</label>
                      <Dropdown
                        value={selectedCategory}
                        options={categoryOptions}
                        onChange={(e) => setSelectedCategory(e.value)}
                        placeholder="Select a Category"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Sub-Category</label>
                      <Dropdown
                        value={selectedSubCategory}
                        options={subCategoryOptions}
                        onChange={(e) => setSelectedSubCategory(e.value)}
                        placeholder="Select a SubCategory"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="mb-3">
                      <label className="col-form-label">Group</label>
                      <Dropdown
                        value={selectedGroup}
                        options={groupOptions}
                        onChange={(e) => setSelectedGroup(e.value)}
                        placeholder="Select here"
                        className="select w-100"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-3">
                      <label className="col-form-label">Date Selector:</label>
                      <div className="icon-form cal-style2">
                        <DateRangePicker initialSettings={initialSettings}>
                          <input
                            type="text"
                            className="form-control bookingrange"
                            value={
                              rangeText ||
                              `${initialSettings.startDate.format("MM/DD/YYYY")} - ${initialSettings.endDate.format("MM/DD/YYYY")}`
                            }
                            readOnly
                          />
                        </DateRangePicker>
                        <span className="form-icon">
                          <img src={cal} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="mb-1">
                      <label className="col-form-label">Time:</label>
                    </div>
                  </div>
                </div>
                <div className="row align-items-center">
                  <div className="col-lg-5">
                    <div className="mb-3">
                      <Calendar
                        placeholder="00:00"
                        value={time1}
                        onChange={(e: any) => setTime1(e.value)}
                        timeOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-1">
                    <div className="mb-3">
                      <span>to</span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="mb-3">
                      <Calendar
                        placeholder="00:00"
                        value={time2}
                        onChange={(e: any) => setTime2(e.value)}
                        timeOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <div className="d-flex active-switch ms-auto">
                      <p className="me-2">In person</p>
                      <label className="switch">
                        <input type="checkbox" />
                        <span className="sliders round" />
                      </label>
                      <p className="ms-2">Online Event</p>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-3">
                    <label htmlFor="message-text" className="col-form-label">
                      Description:
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Write Description for event|"
                      defaultValue={""}
                    />
                  </div>
                </div>
              </form>
            </div>
            <div className="modal-footer flex-wrap">
              <button type="button" className="btn btn-primary rounded w-100">
                Create
              </button>
              <button
                type="button"
                className="btn btn-cancel  w-100"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Events;
