import moment from "moment";

export const professionOptions = [
  { label: "Dentist", value: "dentist" },
  { label: "Doctor", value: "doctor" },
  { label: "Nurse", value: "nurse" },
  { label: "Therapist", value: "therapist" },
];
export const selectHereOptions = [{ label: "Group", value: "Group" }];

export const selectOneOptions = [{ label: "Group", value: "Group" }];

export const monthOptions = [
  { label: "Month", value: "Month" },
  { label: "Group", value: "Group" },
];

export const yearOptions = [
  { label: "Year", value: "Year" },
  { label: "Group", value: "Group" },
];

export const authLicenseOption = {
  license: "i_am_licensed_my_license_information_is_below",
  pre_License: "i_am_pre-licensed_or_under_supervision",
  certified_License: "i_have_a_certification_my_profession_had_no_license",
};

export const clinicalRoleTypeOption = [
  {
    label: "PhD",
    value: "PhD",
  },
  {
    label: "MA",
    value: "MA",
  },
  {
    label: "LCSW",
    value: "LCSW",
  },
];
export const SettingsLicenseOption = {
  license: "i_am_licensed_my_license_information_is_below",
  pre_License: "i_am_pre-licensed_or_under_supervision",
  certified_License: "i_have_a_certification_my_profession_had_no_license",
};
export const formatTimeTo12Hour = (timeString) => {
  const date = new Date(timeString);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";

  hours = hours % 12;
  hours = hours ? hours : 12;
  hours = hours < 10 ? "0" + hours : hours;
  minutes = minutes < 10 ? "0" + minutes : minutes;

  const formattedTime = `${hours}:${minutes} ${ampm}`;
  return formattedTime;
};

export const capitalizeFirstLetter = (str) => {
  if (typeof str === "string") {
    return str
      ? str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase()
      : str;
  } else return str;
};

export const dateFormat_YYYY_MM_DD = (date) =>
  date ? moment(date).format("YYYY-MM-DD") : date;

export const dateFormat_DD_MM_YYYY = (date) =>
  moment(date).format("DD-MM-YYYY");

export function capitalizeWords(str = "") {
  if (typeof str === "string") {
    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");
  } else return str;
}

export const formatDateString = (dateString) => {
  const date = new Date(dateString);
  const options = { month: "short", day: "2-digit", year: "numeric" };

  return date.toLocaleDateString("en-US", options);
  // Output: "Oct 04, 2024"
};

export const setLocalityLabel = (items) =>
  items.map((value) => ({
    label: value.name,
    value: value?._id !== undefined ? value?._id : value?.id,
  }));

export const GetIdLabel = (array, id, key) => {
  if (array !== undefined && array?.length > 0) {
    let temp = array?.filter((it) => it?.value === id);
    return temp[0]?.label !== undefined ? temp[0]?.label : "";
  } else {
    return "";
  }
};

export function getImageDimensions(url) {
  return new Promise((resolve, reject) => {
    const img = new Image();

    // Set the image source to the URL
    img.src = url;

    // Wait for the image to load
    img.onload = () => {
      // Return the width and height as a dynamic string
      resolve(`${img.naturalWidth} by ${img.naturalHeight} pixels.`);
    };

    // Handle error if the image fails to load
    img.onerror = (error) => {
      reject("Error loading image");
    };
  });
}

export const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
  { label: "Non-Binary", value: "Non-Binary" },
  { label: "Other", value: "Other" },
];

export const getCroppedImg = (imageSrc, crop) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageSrc;
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      const { width: cropWidth, height: cropHeight, x, y } = crop;
      canvas.width = cropWidth;
      canvas.height = cropHeight;

      ctx.drawImage(
        image,
        x, // Start x in source image
        y, // Start y in source image
        cropWidth, // Source width to crop
        cropHeight, // Source height to crop
        0, // Start x in canvas
        0, // Start y in canvas
        cropWidth, // Destination width in canvas
        cropHeight // Destination height in canvas
      );

      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        const croppedImageUrl = URL.createObjectURL(blob);
        resolve(croppedImageUrl); // You can resolve as blob, base64, etc.
      }, "image/jpeg");
    };
    image.onerror = (error) => reject(error);
  });
};

export const sexualityOptions = [
  { label: "Heterosexual", value: "heterosexual" },
  { label: "Homosexual", value: "homosexual" },
  { label: "Bisexual", value: "bisexual" },
  { label: "Pansexual", value: "pansexual" },
  { label: "Asexual", value: "asexual" },
  { label: "Queer", value: "queer" },
  { label: "Other", value: "Other" },
];
