import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  clinicalExperienceSchema,
  clinicalExpertiseSchema,
  clinicalSpecialitySchema,
  TreatmentExperienceSchema,
  TreatmentExpertiseSchema,
  TreatmentModalitySchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
  clinicalExpertiseData: any;
  directoryData: any;
}

const TreatmentModal = (props: props) => {
  const clinicalRef = useRef();
  const { clinicalExpertiseData, getDirectoryData, directoryData } = props;
  const { type, open, data, userDetails, edit_type } = props.visible;

  const basicModelValidation = Yup.object().shape({
    treatment_modality: TreatmentModalitySchema,
    treatment_expertise: TreatmentExpertiseSchema,
    treatment_experience: TreatmentExperienceSchema,
  });

  const {
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    register,
    trigger,
    getValues,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(basicModelValidation),
  });

  const OnClose = () => {
    reset();
    clinicalRef.current.click();
    props.handleClose();
  };

  const handleSelection = (item: any, type: any) => {
    console.log(
      "ELSEPART",
      getValues("other_clinical_specialties")?.clinical_experience?.includes(
        item
      ),
      item,
      type
    );
    let updatedSelection;

    if (edit_type === "treatment") {
      if (type === "specialities") {
        updatedSelection = watch("treatment_modality").includes(item)
          ? watch("treatment_modality").filter((s) => s !== item)
          : [...watch("treatment_modality"), item];
        setValue("treatment_modality", updatedSelection);
      } else if (type === "expertise") {
        updatedSelection = watch("treatment_expertise").includes(item)
          ? watch("treatment_expertise").filter((e) => e !== item)
          : [...watch("treatment_expertise"), item];
        setValue("treatment_expertise", updatedSelection);
      } else if (type === "experience") {
        updatedSelection = watch("treatment_experience").includes(item)
          ? watch("treatment_experience").filter((exp) => exp !== item)
          : [...watch("treatment_experience"), item];
        setValue("treatment_experience", updatedSelection);
      }
    }
  };

  const handleFormSubmit = async (data: any) => {
    console.log("submit treatment data", data);

    try {
      showLoader();
      const payload = {
        treatment_modality: data?.treatment_modality,
        treatment_expertise: data?.treatment_expertise,
        treatment_experience: data?.treatment_experience,
        other_treatment_modality_specialty: {
          treatment_modality:
            directoryData?.other_treatment_modality_specialty
              ?.treatment_modality,
          treatment_expertise:
            directoryData?.other_treatment_modality_specialty
              ?.treatment_expertise,
          treatment_experience:
            directoryData?.other_treatment_modality_specialty
              ?.treatment_experience,
        },
      };

      const response = await putData(
        `${end_points.profile_about_treatment_update.url} `,
        payload
      );

      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    console.log(
      "directoryData",
      directoryData?.clinical_specialties !== undefined &&
        Object.values(directoryData?.clinical_specialties).flat().length
    );
    let temp = {
      clinical_type: edit_type,
      treatment_modality:
        directoryData?.treatment_modality_specialty?.treatment_modality !==
        undefined
          ? directoryData?.treatment_modality_specialty?.treatment_modality
          : [],
      treatment_expertise:
        directoryData?.treatment_modality_specialty?.treatment_expertise !==
        undefined
          ? directoryData?.treatment_modality_specialty?.treatment_expertise
          : [],
      treatment_experience:
        directoryData?.treatment_modality_specialty?.treatment_experience !==
        undefined
          ? directoryData?.treatment_modality_specialty?.treatment_experience
          : [],
      other_treatment_specialties: {
        treatment_modality:
          directoryData?.other_treatment_modality_specialty
            ?.treatment_modality !== undefined
            ? directoryData?.other_treatment_modality_specialty
                ?.treatment_modality
            : [],
        treatment_expertise:
          directoryData?.other_treatment_modality_specialty
            ?.treatment_expertise !== undefined
            ? directoryData?.other_treatment_modality_specialty
                ?.treatment_expertise
            : [],
        treatment_experience:
          directoryData?.other_treatment_modality_specialty
            ?.clinical_experience !== undefined
            ? directoryData?.other_treatment_modality_specialty
                ?.treatment_experience
            : [],
      },
    };
    reset(temp);
  }, [directoryData]);

  console.log("treatment errors", errors);

  return (
    <div
      className="modal fade filter-modal"
      id="treatment-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Treatment Modality Specialty, Expertise, or Experience.
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={clinicalRef}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Treatment Modality
                      <span>(Choose at least one treatment modality)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_specality.map(
                        (specialty: any) => (
                          <li
                            key={specialty?._id}
                            {...register("treatment_modality")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "treatment"
                                  ? watch("treatment_modality").includes(
                                      specialty?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_specialties.includes(
                                        specialty?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(
                                  specialty?.name,
                                  "specialities"
                                );
                                if (edit_type === "treatment") {
                                  trigger("treatment_modality");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {specialty?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.treatment_modality} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Treatment Expertise
                      <span>(Select at least one treatment expertise)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_expertice.map(
                        (expertise: any) => (
                          <li
                            key={expertise?._id}
                            {...register("treatment_expertise")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "treatment"
                                  ? watch("treatment_expertise").includes(
                                      expertise?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_expertise.includes(
                                        expertise?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(expertise?.name, "expertise");
                                if (edit_type === "treatment") {
                                  trigger("treatment_expertise");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {expertise?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.treatment_expertise} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Treatment Experience
                      <span>(Select at least one treatment experience)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_experience.map(
                        (experience: any) => (
                          <li
                            key={experience?._id}
                            {...register("treatment_experience")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "treatment"
                                  ? watch("treatment_experience").includes(
                                      experience?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_experience.includes(
                                        experience?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(experience?.name, "experience");
                                if (edit_type === "treatment") {
                                  trigger("treatment_experience");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {experience?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.treatment_experience} />
                  </div>
                </div>
              </div>

              <div className="filter-modal-btns">
                <button className="btn btn-primary rounded" type="submit">
                  {directoryData?.clinical_specialties !== undefined &&
                  Object.values(
                    directoryData?.treatment_modality_specialty
                  ).flat().length > 1
                    ? "Update"
                    : "Add"}{" "}
                  Treatement modalities
                </button>
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TreatmentModal;
