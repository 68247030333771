export interface States {
    [key: string]: string[]
  }
  
  const states: States = {
    af: [
      'Badakhshan',
      'Badghis',
      'Balkh',
      'Daykundi Province',
      'Farah',
      'Faryab Province',
      'Helmand',
      'Herat',
      'Kandahar',
      'Kunduz',
      'Nangarhar',
      'Panjshir',
      'Parwan',
      'Samangan',
      'Sar-e Pul',
      'Uruzgan',
      'Velayat-e Khowst',
      'Velayat-e Nurestan',
      'Wardak',
      'Wilayat-e Baghlan',
      'Wilayat-e Bamyan',
      'Wilayat-e Ghazni',
      'Wilayat-e Ghor',
      'Wilayat-e Jowzjan',
      'Wilayat-e Kabul',
      'Wilayat-e Kapisa',
      'Wilayat-e Kunar',
      'Wilayat-e Laghman',
      'Wilayat-e Logar',
      'Wilayat-e Nimroz',
      'Wilayat-e Paktika',
      'Wilayat-e Paktiya',
      'Wilayat-e Takhar',
      'Wilayat-e Zabul',
    ],
    al: [
      'Qarku i Beratit',
      'Qarku i Dibres',
      'Qarku i Durresit',
      'Qarku i Elbasanit',
      'Qarku i Fierit',
      'Qarku i Gjirokastres',
      'Qarku i Korces',
      'Qarku i Kukesit',
      'Qarku i Lezhes',
      'Qarku i Shkodres',
      'Qarku i Tiranes',
      'Qarku i Vlores',
    ],
    dz: [
      'Adrar',
      'Annaba',
      'El Bayadh',
      'El Oued',
      'El Tarf',
      'Illizi',
      'Oran',
      'Oum el Bouaghi',
      "Wilaya d' Alger",
      'Wilaya de Ain Defla',
      'Wilaya de Ain Temouchent',
      'Wilaya de Batna',
      'Wilaya de Bechar',
      'Wilaya de Bejaia',
      'Wilaya de Biskra',
      'Wilaya de Blida',
      'Wilaya de Bordj Bou Arreridj',
      'Wilaya de Bouira',
      'Wilaya de Boumerdes',
      'Wilaya de Chlef',
      'Wilaya de Constantine',
      'Wilaya de Djelfa',
      'Wilaya de Ghardaia',
      'Wilaya de Guelma',
      'Wilaya de Jijel',
      'Wilaya de Khenchela',
      'Wilaya de Laghouat',
      "Wilaya de M'Sila",
      'Wilaya de Mascara',
      'Wilaya de Medea',
      'Wilaya de Mila',
      'Wilaya de Mostaganem',
      'Wilaya de Naama',
      'Wilaya de Ouargla',
      'Wilaya de Relizane',
      'Wilaya de Saida',
      'Wilaya de Setif',
      'Wilaya de Sidi Bel Abbes',
      'Wilaya de Skikda',
      'Wilaya de Souk Ahras',
      'Wilaya de Tamanrasset',
      'Wilaya de Tebessa',
      'Wilaya de Tiaret',
      'Wilaya de Tindouf',
      'Wilaya de Tipaza',
      'Wilaya de Tissemsilt',
      'Wilaya de Tizi Ouzo',
      'Wilaya de Tlemcen',
    ],
    ad: ['Andorra la Vella', 'Canillo', 'Encamp', 'Escaldes-Engordany', 'La Massana', 'Ordino', 'Sant Julia de Loria'],
    ao: [
      'Bengo Province',
      'Benguela',
      'Cabinda',
      'Cuanza Norte Province',
      'Cunene Province',
      'Huambo',
      'Huila Province',
      'Kuando Kubango',
      'Kwanza Sul',
      'Luanda Province',
      'Lunda Norte Province',
      'Lunda Sul',
      'Malanje Province',
      'Moxico',
      'Namibe Province',
      'Provincia do Bie',
      'Provincia do Uige',
      'Zaire',
    ],
    ai: [
      'Blowing Point',
      'East End',
      'George Hill',
      'Island Harbour',
      'North Hill',
      'North Side',
      'Sandy Ground',
      'Sandy Hill',
      'South Hill',
      'Stoney Ground',
      'The Farrington',
      'The Quarter',
      'The Valley',
      'West End',
    ],
    ag: [
      'Barbuda',
      'Parish of Saint George',
      'Parish of Saint John',
      'Parish of Saint Mary',
      'Parish of Saint Paul',
      'Parish of Saint Peter',
      'Parish of Saint Philip',
      'Redonda',
    ],
    ar: [
      'Catamarca Province',
      'Chaco Province',
      'Chubut Province',
      'Ciudad Autonoma de Buenos Aires',
      'Cordoba Province',
      'Corrientes Province',
      'Entre Rios Province',
      'Formosa Province',
      'Jujuy Province',
      'La Pampa Province',
      'La Rioja Province',
      'Mendoza Province',
      'Misiones Province',
      'Neuquen Province',
      'Provincia de Buenos Aires',
      'Rio Negro Province',
      'Salta Province',
      'San Juan Province',
      'San Luis Province',
      'Santa Cruz Province',
      'Santa Fe Province',
      'Santiago del Estero Province',
      'Tierra del Fuego Province',
      'Tucuman Province',
    ],
    am: [
      'Aragatsotni Marz',
      'Ararati Marz',
      'Armaviri Marz',
      "Geghark'unik'i Marz",
      "Kotayk'i Marz",
      'Lorru Marz',
      'Shiraki Marz',
      "Syunik'i Marz",
      'Tavushi Marz',
      "Vayots' Dzor",
      'Yerevan',
    ],
    aw: [],
    au: [
      'Australian Capital Territory',
      'Northern Territory',
      'New South Wales',
      'Queensland',
      'South Australia',
      'Tasmania',
      'Victoria',
      'Western Australia',
    ],
    at: [
      'Burgenland',
      'Kaernten',
      'Niederoesterreich',
      'Oberoesterreich',
      'Salzburg',
      'Steiermark',
      'Tirol',
      'Vorarlberg',
      'Wien',
    ],
    az: [
      'Absheron Rayon',
      'Aghdam Rayon',
      'Aghdash Rayon',
      'Aghjabadi Rayon',
      'Aghstafa Rayon',
      'Aghsu Rayon',
      'Astara District',
      'Baku City',
      'Balakan Rayon',
      'Barda Rayon',
      'Beylagan Rayon',
      'Bilasuvar Rayon',
      'Dashkasan Rayon',
      'Fizuli Rayon',
      'Gadabay Rayon',
      'Ganja City',
      'Gobustan Rayon',
      'Goranboy Rayon',
      'Goychay Rayon',
      'Goygol Rayon',
      'Hajigabul Rayon',
      'Imishli Rayon',
      'Ismayilli Rayon',
      'Jabrayil District',
      'Jalilabad',
      'Kalbajar District',
      'Khachmaz Rayon',
      'Khizi Rayon',
      'Khojavend District',
      'Kurdamir Rayon',
      'Lacin Rayon',
      'Lankaran Rayon',
      'Lankaran Sahari',
      'Lerik Rayon',
      'Masally District',
      'Mingacevir City',
      'Naftalan City',
      'Nakhchivan',
      'Nakhchivan Autonomous Republic',
      'Neftchala Rayon',
      'Oghuz Rayon',
      'Qabala Rayon',
      'Qakh Rayon',
      'Qazakh Rayon',
      'Quba Rayon',
      'Qubadli Rayon',
      'Qusar Rayon',
      'Saatly Rayon',
      'Sabirabad Rayon',
      'Salyan Rayon',
      'Samukh Rayon',
      'Shabran Rayon',
      'Shaki city',
      'Shaki Rayon',
      'Shamakhi Rayon',
      'Shamkir Rayon',
      'Shirvan',
      'Shusha',
      'Siazan Rayon',
      'Sumqayit City',
      'Susa Rayon',
      'Tartar Rayon',
      'Tovuz Rayon',
      'Ujar Rayon',
      'Xankandi Sahari',
      'Xocali Rayon',
      'Yardymli Rayon',
      'Yevlakh City',
      'Yevlakh Rayon',
      'Zangilan Rayon',
      'Zaqatala Rayon',
      'Zardab Rayon',
    ],
    bs: [
      'Acklins Island District',
      'Berry Islands District',
      'Bimini District',
      'Black Point District',
      'Cat Island',
      'Central Abaco District',
      'Central Andros District',
      'Central Eleuthera District',
      'City of Freeport District',
      'Crooked Island and Long Cay District',
      'East Grand Bahama District',
      'Exuma District',
      'Grand Cay District',
      'Harbour Island District',
      'Hope Town District',
      'Inagua',
      'Long Island',
      'Mangrove Cay',
      'Mayaguana District',
      "Moore's Island District",
      'New Providence District',
      'North Abaco District',
      'North Andros District',
      'North Eleuthera District',
      'Ragged Island District',
      'Rum Cay',
      'San Salvador District',
      'South Abaco District',
      'South Andros',
      'South Eleuthera',
      'Spanish Wells District',
      'West Grand Bahama District',
    ],
    bh: ['Capital Governorate', 'Muharraq Governorate', 'Northern Governorate', 'Southern Governorate'],
    bd: [
      'Barisal Division',
      'Chittagong',
      'Dhaka Division',
      'Khulna Division',
      'Mymensingh Division',
      'Rajshahi Division',
      'Rangpur Division',
      'Sylhet Division',
    ],
    bb: [
      'Christ Church',
      'Saint Andrew',
      'Saint George',
      'Saint James',
      'Saint John',
      'Saint Joseph',
      'Saint Lucy',
      'Saint Michael',
      'Saint Peter',
      'Saint Philip',
      'Saint Thomas',
    ],
    by: [
      'Brest Oblast',
      'Gomel Oblast',
      'Grodno Oblast',
      'Horad Minsk',
      'Minsk Oblast',
      'Mogilyov Oblast',
      'Vitebsk Oblast',
    ],
    be: ['Bruxelles-Capitale', 'Flanders', 'Wallonia'],
    bz: [
      'Belize District',
      'Cayo District',
      'Corozal District',
      'Orange Walk District',
      'Stann Creek District',
      'Toledo District',
    ],
    bj: [
      'Alibori',
      'Atakora Department',
      'Atlantique Department',
      'Borgou Department',
      'Collines Department',
      "Departement de l'Oueme",
      'Donga',
      'Kouffo Department',
      'Littoral',
      'Mono',
      'Plateau Department',
      'Zou Department',
    ],
    bm: [
      'Devonshire Parish',
      'Hamilton',
      'Paget Parish',
      'Pembroke Parish',
      'Saint George',
      "Saint George's Parish",
      'Sandys Parish',
      "Smith's Parish",
      'Southampton Parish',
      'Warwick Parish',
    ],
    bt: [
      'Bumthang Dzongkhag',
      'Chhukha Dzongkhag',
      'Dagana Dzongkhag',
      'Gasa',
      'Haa Dzongkhag',
      'Lhuentse Dzongkhag',
      'Mongar Dzongkhag',
      'Paro Dzongkhag',
      'Pemagatshel Dzongkhag',
      'Punakha Dzongkhag',
      'Samdrup Jongkhar Dzongkhag',
      'Samtse Dzongkhag',
      'Sarpang Dzongkhag',
      'Thimphu Dzongkhag',
      'Trashi Yangste',
      'Trashigang Dzongkhag',
      'Trongsa Dzongkhag',
      'Tsirang Dzongkhag',
      'Wangdue Phodrang Dzongkhag',
      'Zhemgang Dzongkhag',
    ],
    bo: [
      'Departamento de Chuquisaca',
      'Departamento de Cochabamba',
      'Departamento de La Paz',
      'Departamento de Oruro',
      'Departamento de Pando',
      'Departamento de Potosi',
      'Departamento de Santa Cruz',
      'Departamento de Tarija',
      'El Beni',
    ],
    ba: ['Brcko', 'Federation of Bosnia and Herzegovina', 'Republika Srpska'],
    bw: [
      'Central District',
      'Ghanzi District',
      'Kgalagadi District',
      'Kgatleng District',
      'Kweneng District',
      'North East District',
      'North West District',
      'South East District',
      'Southern District',
    ],
    br: [
      'Acre',
      'Alagoas',
      'Amapa',
      'Amazonas',
      'Bahia',
      'Ceara',
      'Espirito Santo',
      'Federal District',
      'Goias',
      'Maranhao',
      'Mato Grosso',
      'Mato Grosso do Sul',
      'Minas Gerais',
      'Para',
      'Paraiba',
      'Parana',
      'Pernambuco',
      'Piaui',
      'Rio de Janeiro',
      'Rio Grande do Norte',
      'Rio Grande do Sul',
      'Rondonia',
      'Roraima',
      'Santa Catarina',
      'Sao Paulo',
      'Sergipe',
      'Tocantins',
    ],
    bn: ['Belait District', 'Brunei and Muara District', 'Temburong District', 'Tutong District'],
    bg: [
      'Blagoevgrad',
      'Burgas',
      'Gabrovo',
      'Haskovo',
      'Lovech',
      'Oblast Dobrich',
      'Oblast Kardzhali',
      'Oblast Kyustendil',
      'Oblast Montana',
      'Oblast Pleven',
      'Oblast Razgrad',
      'Oblast Ruse',
      'Oblast Shumen',
      'Oblast Silistra',
      'Oblast Sliven',
      'Oblast Smolyan',
      'Oblast Stara Zagora',
      'Oblast Targovishte',
      'Oblast Veliko Tarnovo',
      'Oblast Vidin',
      'Oblast Vratsa',
      'Oblast Yambol',
      'Pazardzhik',
      'Pernik',
      'Plovdiv',
      'Sofia',
      'Sofia-Grad',
      'Varna',
    ],
    bf: [
      'Boucle du Mouhoun Region',
      'Cascades Region',
      'Centre',
      'Centre-Est',
      'Centre-Nord',
      'Centre-Ouest',
      'Centre-Sud',
      'Est',
      'High-Basins Region',
      'Nord',
      'Plateau-Central',
      'Sahel',
      'Southwest Region',
    ],
    bi: [
      'Bubanza Province',
      'Bujumbura Mairie Province',
      'Bujumbura Rural Province',
      'Bururi Province',
      'Cankuzo Province',
      'Cibitoke Province',
      'Gitega Province',
      'Karuzi Province',
      'Kayanza Province',
      'Kirundo Province',
      'Makamba Province',
      'Muramvya Province',
      'Muyinga Province',
      'Ngozi Province',
      'Province de Mwaro',
      'Rumonge Province',
      'Rutana Province',
      'Ruyigi Province',
    ],
    kh: [
      'Banteay Meanchey',
      'Battambang',
      'Kampong Cham',
      'Kampong Chhnang',
      'Kampong Spe',
      'Kampong Thom',
      'Kampot',
      'Kandal',
      'Kep',
      'Koh Kong',
      'Kratie',
      'Mondolkiri',
      'Otar Meanchey',
      'Pailin',
      'Phnom Penh',
      'Preah Vihear',
      'Prey Veng',
      'Pursat',
      'Ratanakiri',
      'Siem Reap',
      'Sihanoukville',
      'Stung Treng',
      'Svay Rieng',
      'Takeo',
      'Tboung Khmum',
    ],
    cm: [
      'Adamaoua Region',
      'Centre Region',
      'East Region',
      'Far North Region',
      'Littoral Region',
      'North Region',
      'North-West Region',
      'South Region',
      'South-West Region',
      'West Region',
    ],
    ca: [
      'Alberta',
      'British Columbia',
      'Manitoba',
      'New Brunswick/Nouveau-Brunswick',
      'Newfoundland and Labrador',
      'Northwest Territories',
      'Nova Scotia',
      'Nunavut',
      'Ontario',
      'Prince Edward Island',
      'Quebec',
      'Saskatchewan',
      'Yukon',
    ],
    cv: [
      'Concelho da Boa Vista',
      'Concelho da Brava',
      'Concelho da Praia',
      'Concelho da Ribeira Brava',
      'Concelho da Ribeira Grande',
      'Concelho de Ribeira Grande de Santiago',
      'Concelho de Santa Catarina',
      'Concelho de Santa Catarina do Fogo',
      'Concelho de Santa Cruz',
      'Concelho de Sao Domingos',
      'Concelho de Sao Miguel',
      'Concelho de Sao Salvador do Mundo',
      'Concelho de Sao Vicente',
      'Concelho do Maio',
      'Concelho do Paul',
      'Concelho do Porto Novo',
      'Concelho do Sao Filipe',
      'Concelho do Tarrafal',
      'Concelho do Tarrafal de Sao Nicola',
      'Concelho dos Mosteiros',
      'Sal Municipality',
      'Sao Lourenco dos Orgaos',
    ],
    ky: ['Bodden Town', 'East End', 'George Town', 'North Side', 'Sister Island', 'West Bay'],
    cf: [
      'Bamingui-Bangoran',
      'Basse-Kotto',
      'Commune de Bangui',
      'Haut-Mbomo',
      'Haute-Kotto',
      'Lobaye',
      'Mambere-Kadei',
      'Mbomo',
      'Ombella-Mpoko',
      'Ouaka',
      'Ouham',
      "Prefecture de l'Ouham-Pende",
      'Prefecture de la Kemo',
      'Prefecture de la Nana-Grebizi',
      'Prefecture de la Nana-Mambere',
      'Prefecture de la Sangha-Mbaere',
      'Vakaga',
    ],
    td: [
      'Barh el Gazel',
      'Batha Region',
      'Borkou Region',
      'Chari-Baguirmi Region',
      'Ennedi-Est',
      'Ennedi-Ouest',
      'Guera Region',
      'Hadjer-Lamis',
      'Kanem Region',
      'Lac Region',
      'Logone Occidental Region',
      'Logone Oriental Region',
      'Mandoul',
      'Mayo-Kebbi East Region',
      'Mayo-Kebbi West Region',
      'Moyen-Chari Region',
      'Ouaddai Region',
      "Region de la Ville de N'Djamena",
      'Salamat Region',
      'Sila',
      'Tandjile Region',
      'Tibesti Region',
      'Wadi Fira Region',
    ],
    cl: [
      'Antofagasta',
      'Atacama',
      'Aysen',
      'Coquimbo',
      'Los Lagos',
      'Maule',
      'Region de Arica y Parinacota',
      'Region de la Araucania',
      'Region de Los Rios',
      'Region de Magallanes y de la Antartica Chilena',
      'Region de Tarapaca',
      'Region de Valparaiso',
      'Region del Biobio',
      "Region del Libertador General Bernardo O'Higgins",
      'Region Metropolitana de Santiago',
    ],
    cn: [
      'Anhui Sheng',
      'Beijing Shi',
      'Chongqing Shi',
      'Fujian Sheng',
      'Gansu Sheng',
      'Guangdong Sheng',
      'Guangxi Zhuangzu Zizhiq',
      'Guizhou Sheng',
      'Hainan Sheng',
      'Hebei Sheng',
      'Heilongjiang Sheng',
      'Henan Sheng',
      'Hubei Sheng',
      'Hunan Sheng',
      'Inner Mongolia Autonomous Region',
      'Jiangsu Sheng',
      'Jiangxi Sheng',
      'Jilin Sheng',
      'Liaoning Sheng',
      'Ningxia Huizu Zizhiq',
      'Qinghai Sheng',
      'Shaanxi',
      'Shandong Sheng',
      'Shanghai Shi',
      'Shanxi Sheng',
      'Sichuan Sheng',
      'Tianjin Shi',
      'Tibet Autonomous Region',
      'Xinjiang Uygur Zizhiq',
      'Yunnan Sheng',
      'Zhejiang Sheng',
    ],
    cx: [],
    cc: [],
    co: [
      'Amazonas',
      'Departamento de Antioquia',
      'Departamento de Arauca',
      'Departamento de Bolivar',
      'Departamento de Boyaca',
      'Departamento de Caldas',
      'Departamento de Casanare',
      'Departamento de Cordoba',
      'Departamento de Cundinamarca',
      'Departamento de La Guajira',
      'Departamento de Narino',
      'Departamento de Norte de Santander',
      'Departamento de Risaralda',
      'Departamento de Santander',
      'Departamento de Sucre',
      'Departamento de Tolima',
      'Departamento del Atlantico',
      'Departamento del Caqueta',
      'Departamento del Cauca',
      'Departamento del Cesar',
      'Departamento del Choco',
      'Departamento del Guainia',
      'Departamento del Guaviare',
      'Departamento del Huila',
      'Departamento del Magdalena',
      'Departamento del Meta',
      'Departamento del Putumayo',
      'Departamento del Valle del Cauca',
      'Departamento del Vaupes',
      'Departamento del Vichada',
      'Distrito Capital de Bogota',
      'Providencia y Santa Catalina, Departamento de Archipielago de San Andres',
      'Quindio Department',
    ],
    km: ['Grande Comore', 'Moheli', 'Ndzuwani'],
    cg: [
      'Commune de Brazzaville',
      'Cuvette',
      'Cuvette-Ouest',
      'Lekoumo',
      'Likouala',
      'Plateaux',
      'Pointe-Noire',
      'Pool',
      'Region de la Bouenza',
      'Region du Kouilo',
      'Region du Niari',
      'Sangha',
    ],
    ck: [
      'Aitutaki',
      'Ati',
      "Ma'uke",
      'Mangaia',
      'Manihiki',
      'Mitiaro',
      'Palmerston',
      'Penrhyn',
      'Pukapuka',
      'Rakahanga',
      'Rarotonga',
    ],
    cr: [
      'Provincia de Alajuela',
      'Provincia de Cartago',
      'Provincia de Guanacaste',
      'Provincia de Heredia',
      'Provincia de Limon',
      'Provincia de Puntarenas',
      'Provincia de San Jose',
    ],
    ci: [
      'Abidjan',
      'Bas-Sassandra',
      'Comoe',
      'Denguele',
      'Goh-Djiboua',
      'Lacs',
      'Lagunes',
      'Montagnes',
      'Sassandra-Marahoue',
      'Savanes',
      'Vallee du Bandama',
      'Woroba',
      'Yamoussoukro Autonomous District',
      'Zanzan',
    ],
    hr: [
      'Bjelovarsko-Bilogorska Zupanija',
      'Brodsko-Posavska Zupanija',
      'Dubrovacko-Neretvanska Zupanija',
      'Grad Zagreb',
      'Istarska Zupanija',
      'Karlovacka Zupanija',
      'Koprivnicko-Krizevacka Zupanija',
      'Krapinsko-Zagorska Zupanija',
      'Licko-Senjska Zupanija',
      'Megimurska Zupanija',
      'Osjecko-Baranjska Zupanija',
      'Pozesko-Slavonska Zupanija',
      'Primorsko-Goranska Zupanija',
      'Sibensko-Kninska Zupanija',
      'Sisacko-Moslavacka Zupanija',
      'Splitsko-Dalmatinska Zupanija',
      'Varazdinska Zupanija',
      'Viroviticko-Podravska Zupanija',
      'Vukovarsko-Srijemska Zupanija',
      'Zadarska Zupanija',
      'Zagreb County',
    ],
    cu: [
      'Artemisa',
      'La Habana',
      'Las Tunas',
      'Municipio Especial Isla de la Juventud',
      'Provincia de Camagueey',
      'Provincia de Ciego de Avila',
      'Provincia de Cienfuegos',
      'Provincia de Guantanamo',
      'Provincia de Holguin',
      'Provincia de Matanzas',
      'Provincia de Pinar del Rio',
      'Provincia de Sancti Spiritus',
      'Provincia de Santiago de Cuba',
      'Provincia de Villa Clara',
      'Provincia Granma',
      'Provincia Mayabeque',
    ],
    cy: [
      'Eparchia Ammochosto',
      'Eparchia Keryneias',
      'Eparchia Larnakas',
      'Eparchia Lefkosias',
      'Eparchia Lemeso',
      'Eparchia Pafo',
    ],
    cz: [
      'Hlavni mesto Praha',
      'Jihocesky kraj',
      'Jihomoravsky kraj',
      'Karlovarsky kraj',
      'Kraj Vysocina',
      'Kralovehradecky kraj',
      'Liberecky kraj',
      'Moravskoslezsky kraj',
      'Olomoucky kraj',
      'Pardubicky kraj',
      'Plzensky kraj',
      'Stredocesky kraj',
      'Ustecky kraj',
      'Zlinsky kraj',
    ],
    cd: [
      'Bas Uele',
      'Haut Uele',
      'Haut-Lomani',
      'Ituri',
      'Kasai District',
      'Kasai-Central',
      'Kinshasa City',
      'Kwango',
      'Kwil',
      'Lomami',
      'Lualaba',
      'Mai Ndombe',
      'Mongala',
      "Province de l'Equateur",
      'Province du Haut-Katanga',
      'Province du Kasai Oriental',
      'Province du Kongo Central',
      'Province du Maniema',
      'Province du Nord-Kiv',
      'Province du Nord-Ubangi',
      'Province du Sud-Ubangi',
      'Sankur',
      'South Kivu Province',
      'Tanganika',
      'Tshopo',
      'Tshuapa',
    ],
    dk: ['North Denmark Region', 'Region Hovedstaden', 'Region Midtjylland', 'Region Sjaelland', 'Region Syddanmark'],
    dj: ['Ali Sabieh Region', 'Arta Region', 'Dikhil', 'Djibouti Region', 'Obock', 'Tadjourah'],
    dm: [
      'Saint Andrew',
      'Saint David',
      'Saint George',
      'Saint John',
      'Saint Joseph',
      'Saint Luke',
      'Saint Mark',
      'Saint Patrick',
      'Saint Paul',
      'Saint Peter',
    ],
    do: [
      'Distrito Nacional',
      'Provincia de Azua',
      'Provincia de Baoruco',
      'Provincia de Barahona',
      'Provincia de Dajabon',
      'Provincia de El Seibo',
      'Provincia de Elias Pina',
      'Provincia de Hato Mayor',
      'Provincia de Hermanas Mirabal',
      'Provincia de Independencia',
      'Provincia de La Altagracia',
      'Provincia de La Romana',
      'Provincia de La Vega',
      'Provincia de Monsenor Nouel',
      'Provincia de Monte Cristi',
      'Provincia de Monte Plata',
      'Provincia de Pedernales',
      'Provincia de Peravia',
      'Provincia de Puerto Plata',
      'Provincia de San Cristobal',
      'Provincia de San Jose de Ocoa',
      'Provincia de San Juan',
      'Provincia de San Pedro de Macoris',
      'Provincia de Santiago',
      'Provincia de Santiago Rodriguez',
      'Provincia de Santo Domingo',
      'Provincia de Valverde',
      'Provincia Duarte',
      'Provincia Espaillat',
      'Provincia Maria Trinidad Sanchez',
      'Provincia Sanchez Ramirez',
      'Samana Province',
    ],
    ec: [
      'Provincia de Bolivar',
      'Provincia de Cotopaxi',
      'Provincia de El Oro',
      'Provincia de Esmeraldas',
      'Provincia de Francisco de Orellana',
      'Provincia de Galapagos',
      'Provincia de Imbabura',
      'Provincia de Loja',
      'Provincia de Los Rios',
      'Provincia de Manabi',
      'Provincia de Morona-Santiago',
      'Provincia de Napo',
      'Provincia de Pichincha',
      'Provincia de Santa Elena',
      'Provincia de Santo Domingo de los Tsachilas',
      'Provincia de Sucumbios',
      'Provincia de Zamora-Chinchipe',
      'Provincia del Azuay',
      'Provincia del Canar',
      'Provincia del Carchi',
      'Provincia del Chimborazo',
      'Provincia del Guayas',
      'Provincia del Pastaza',
      'Provincia del Tungurahua',
    ],
    eg: [
      'As Suways',
      'Beheira Governorate',
      'Cairo Governorate',
      'Damietta Governorate',
      'Ismailia Governorate',
      'Kafr ash Shaykh',
      'Muhafazat ad Daqahliyah',
      'Muhafazat al Fayyum',
      'Muhafazat al Gharbiyah',
      'Muhafazat al Iskandariyah',
      'Muhafazat al Jizah',
      'Muhafazat al Minufiyah',
      'Muhafazat al Minya',
      'Muhafazat al Qalyubiyah',
      'Muhafazat al Uqsur',
      'Muhafazat al Wadi al Jadid',
      'Muhafazat ash Sharqiyah',
      'Muhafazat Aswan',
      'Muhafazat Asyut',
      'Muhafazat Bani Suwayf',
      "Muhafazat Bur Sa'id",
      'Muhafazat Matruh',
      'Muhafazat Qina',
      "Muhafazat Shamal Sina'",
      'Muhafazat Suhaj',
      'Red Sea Governorate',
      'South Sinai Governorate',
    ],
    sv: [
      'Departamento de Ahuachapan',
      'Departamento de Cabanas',
      'Departamento de Chalatenango',
      'Departamento de Cuscatlan',
      'Departamento de La Libertad',
      'Departamento de La Paz',
      'Departamento de La Union',
      'Departamento de Morazan',
      'Departamento de San Miguel',
      'Departamento de San Salvador',
      'Departamento de San Vicente',
      'Departamento de Santa Ana',
      'Departamento de Sonsonate',
      'Departamento de Usulutan',
    ],
    gq: [
      'Provincia de Annobon',
      'Provincia de Bioko Norte',
      'Provincia de Bioko Sur',
      'Provincia de Centro Sur',
      'Provincia de Kie-Ntem',
      'Provincia de Litoral',
      'Provincia de Wele-Nzas',
    ],
    er: [
      'Anseba Region',
      'Debub Region',
      'Gash-Barka Region',
      'Maekel Region',
      'Northern Red Sea Region',
      'Southern Red Sea Region',
    ],
    ee: [
      'Harjumaa',
      'Hiiumaa',
      'Ida-Virumaa',
      'Jarvamaa',
      'Jogevamaa',
      'Laane-Virumaa',
      'Laeaenemaa',
      'Paernumaa',
      'Polvamaa',
      'Raplamaa',
      'Saaremaa',
      'Tartumaa',
      'Valgamaa',
      'Viljandimaa',
      'Vorumaa',
    ],
    et: [
      'Adis Abeba Astedader',
      'Afar Region',
      'Amhara Region',
      'Benishangul-Gumuz Region',
      'Dire Dawa Region',
      'Gambela Region',
      'Harari Region',
      'Oromiya Region',
      'Somali Region',
      "Southern Nations, Nationalities, and People's Region",
      'Tigray Region',
    ],
    fk: [],
    fo: ['Eysturoyar sysla', 'Nordoyar sysla', 'Sandoyar Sysla', 'Streymoyar Sysla', 'Suduroyar sysla', 'Vaga Sysla'],
    fm: ['Chuuk', 'Kosrae', 'Pohnpei', 'Yap'],
    fj: ['Central Division', 'Eastern Division', 'Northern Division', 'Rotuma', 'Western Division'],
    fi: [
      'Etela-Pohjanmaa',
      'Etelae-Karjala',
      'Kainu',
      'Kanta-Haeme',
      'Keski-Pohjanmaa',
      'Keski-Suomi',
      'Kymenlaakso',
      'Lapland',
      'Paijanne-Tavastland',
      'Pirkanmaa',
      'Pohjanmaa',
      'Pohjois-Karjala',
      'Pohjois-Pohjanmaa',
      'Pohjois-Savo',
      'Satakunta',
      'Southern Savonia',
      'Uusimaa',
      'Varsinais-Suomi',
    ],
    fr: [
      'Auvergne-Rhone-Alpes',
      'Bourgogne-Franche-Comte',
      'Bretagne',
      'Centre-Val de Loire',
      'Corse',
      'Grand-Est',
      'Hauts-de-France',
      'Ile-de-France',
      'Normandy',
      'Nouvelle-Aquitaine',
      'Occitanie',
      'Pays de la Loire',
      "Provence-Alpes-Cote d'Azur",
    ],
    gf: ['Guyane'],
    pf: ['Iles Australes', 'Iles du Vent', 'Iles Marquises', 'Iles Sous-le-Vent', 'Iles Tuamotu-Gambier'],
    tf: [
      'Archipel des Crozet',
      'Archipel des Kerguelen',
      "Iles Eparses de l'ocean Indien",
      'Iles Saint-Paul et Nouvelle-Amsterdam',
      'La Terre-Adelie',
    ],
    ga: [
      'Estuaire',
      "Province de l'Ogooue-Ivindo",
      "Province de l'Ogooue-Lolo",
      "Province de l'Ogooue-Maritime",
      'Province de la Ngounie',
      'Province de la Nyanga',
      'Province du Haut-Ogooue',
      'Province du Moyen-Ogooue',
      'Province du Woleu-Ntem',
    ],
    gm: ['Banjul', 'Central River', 'Lower River Division', 'North Bank', 'Upper River', 'West Coast'],
    ge: [
      'Abkhazia',
      'Ajaria',
      'Guria',
      'Imereti',
      "K'alak'i T'bilisi",
      'Kakheti',
      'Kvemo Kartli',
      'Mtskheta-Mtianeti',
      'Racha-Lechkhumi and Kvemo Svaneti',
      'Samegrelo-Zemo Svanetis Mkhare',
      'Samtskhe-Javakheti',
      'Shida Kartli',
    ],
    de: [
      'Baden-Wuerttemberg Region',
      'Bavaria',
      'Brandenburg',
      'Bremen',
      'Free and Hanseatic City of Hamburg',
      'Hessen',
      'Land Berlin',
      'Lower Saxony',
      'Mecklenburg-Western Pomerania',
      'Nordrhein-Westfalen',
      'Rheinland-Pfalz',
      'Saarland',
      'Saxony',
      'Saxony-Anhalt',
      'Schleswig-Holstein',
      'Thuringia',
    ],
    gh: [
      'Ashanti Region',
      'Brong-Ahafo Region',
      'Central Region',
      'Eastern Region',
      'Greater Accra Region',
      'Northern Region',
      'Upper East Region',
      'Upper West Region',
      'Volta Region',
      'Western Region',
    ],
    gi: [],
    gb: ['England', 'Northern Ireland', 'Scotland', 'Wales'],
    gr: [
      'Attica',
      'Central Greece',
      'Central Macedonia',
      'Crete',
      'Eastern Macedonia and Thrace',
      'Epirus',
      'Ionian Islands',
      'Mount Athos',
      'North Aegean',
      'Peloponnese',
      'South Aegean',
      'Thessaly',
      'Western Greece',
      'Western Macedonia',
    ],
    gl: ['Kujalleq', 'Qaasuitsup', 'Qeqqata', 'Sermersooq'],
    gd: [
      'Carriacou and Petite Martinique',
      'Saint Andrew',
      'Saint David',
      'Saint George',
      'Saint John',
      'Saint Mark',
      'Saint Patrick',
    ],
    gp: ['Guadeloupe'],
    gt: [
      'Departamento de Alta Verapaz',
      'Departamento de Baja Verapaz',
      'Departamento de Chimaltenango',
      'Departamento de Chiquimula',
      'Departamento de El Progreso',
      'Departamento de Escuintla',
      'Departamento de Guatemala',
      'Departamento de Huehuetenango',
      'Departamento de Izabal',
      'Departamento de Jalapa',
      'Departamento de Jutiapa',
      'Departamento de Quetzaltenango',
      'Departamento de Retalhule',
      'Departamento de Sacatepequez',
      'Departamento de San Marcos',
      'Departamento de Santa Rosa',
      'Departamento de Solola',
      'Departamento de Suchitepequez',
      'Departamento de Totonicapan',
      'Departamento de Zacapa',
      'Departamento del Peten',
      'Departamento del Quiche',
    ],
    gn: [
      'Boke Region',
      'Conakry Region',
      'Faranah Region',
      'Kankan Region',
      'Kindia Region',
      'Labe Region',
      'Mamou Region',
      'Nzerekore Region',
    ],
    gw: ['Bafata', 'Biombo', 'Bissau Region', 'Bolama', 'Cacheu Region', 'Gab', 'Oio Region', 'Quinara', 'Tombali'],
    gy: [
      'Barima-Waini Region',
      'Cuyuni-Mazaruni Region',
      'Demerara-Mahaica Region',
      'East Berbice-Corentyne Region',
      'Essequibo Islands-West Demerara Region',
      'Mahaica-Berbice Region',
      'Pomeroon-Supenaam Region',
      'Potaro-Siparuni Region',
      'Upper Demerara-Berbice Region',
      'Upper Takutu-Upper Essequibo Region',
    ],
    ht: [
      'Centre',
      "Departement de l'Artibonite",
      "Departement de l'Ouest",
      'Departement de Nippes',
      'Departement du Nord-Est',
      'Grandans',
      'Nord',
      'Nord-Ouest',
      'Sud',
      'Sud-Est',
    ],
    hn: [
      'Departamento de Atlantida',
      'Departamento de Choluteca',
      'Departamento de Colon',
      'Departamento de Comayagua',
      'Departamento de Copan',
      'Departamento de Cortes',
      'Departamento de El Paraiso',
      'Departamento de Francisco Morazan',
      'Departamento de Gracias a Dios',
      'Departamento de Intibuca',
      'Departamento de Islas de la Bahia',
      'Departamento de La Paz',
      'Departamento de Lempira',
      'Departamento de Ocotepeque',
      'Departamento de Olancho',
      'Departamento de Santa Barbara',
      'Departamento de Valle',
      'Departamento de Yoro',
    ],
    hk: [
      'Central and Western District',
      'Eastern',
      'Islands District',
      'Kowloon City',
      'Kwai Tsing',
      'Kwun Tong',
      'North',
      'Sai Kung District',
      'Sha Tin',
      'Sham Shui Po',
      'Southern',
      'Tai Po District',
      'Tsuen Wan District',
      'Tuen Mun',
      'Wan Chai',
      'Wong Tai Sin',
      'Yau Tsim Mong',
      'Yuen Long District',
    ],
    hu: [
      'Bacs-Kiskun county',
      'Baranya county',
      'Bekes County',
      'Borsod-Abauj Zemplen county',
      'Budapest',
      'Csongrad megye',
      'Fejer megye',
      'Gyor-Moson-Sopron megye',
      'Hajdu-Bihar',
      'Heves megye',
      'Jasz-Nagykun-Szolnok',
      'Komarom-Esztergom',
      'Nograd megye',
      'Pest megye',
      'Somogy megye',
      'Szabolcs-Szatmar-Bereg',
      'Tolna megye',
      'Vas megye',
      'Veszprem megye',
      'Zala megye',
    ],
    is: [
      'Austurland',
      'Hoefudborgarsvaedi',
      'Nordurland Eystra',
      'Nordurland Vestra',
      'Sudurland',
      'Sudurnes',
      'Vestfirdir',
      'Vesturland',
    ],
    in: [
      'Andaman and Nicobar Islands',
      'Andhra Pradesh',
      'Arunachal Pradesh',
      'Assam',
      'Bihar',
      'Chandigarh',
      'Chhattisgarh',
      'Dadra and Nagar Haveli and Daman and Diu',
      'Delhi',
      'Gujarat',
      'Goa',
      'Haryana',
      'Himachal Pradesh',
      'Jammu and Kashmir',
      'Jharkhand',
      'Karnataka',
      'Kerala',
      'Ladhak',
      'Lakshadweep',
      'Madhya Pradesh',
      'Maharashtra',
      'Manipur',
      'Meghalaya',
      'Mizoram',
      'Nagaland',
      'Odisha',
      'Puducherry',
      'Punjab',
      'Rajasthan',
      'Sikkim',
      'Tamil Nadu',
      'Telangana',
      'Tripura',
      'Uttarakhand',
      'Uttar Pradesh',
      'West Bengal',
    ],
    id: [
      'Banten',
      'Daerah Istimewa Yogyakarta',
      'Daerah Khusus Ibukota Jakarta',
      'Jawa Barat',
      'Jawa Timur',
      'Kepulauan Bangka Belitung',
      'Nanggroe Aceh Darussalam Province',
      'North Kalimantan',
      'Propinsi Bengkul',
      'Provinsi Bali',
      'Provinsi Gorontalo',
      'Provinsi Jambi',
      'Provinsi Jawa Tengah',
      'Provinsi Kalimantan Barat',
      'Provinsi Kalimantan Selatan',
      'Provinsi Kalimantan Tengah',
      'Provinsi Kalimantan Timur',
      'Provinsi Kepulauan Ria',
      'Provinsi Lampung',
      'Provinsi Maluk',
      'Provinsi Maluku Utara',
      'Provinsi Nusa Tenggara Timur',
      'Provinsi Papua',
      'Provinsi Papua Barat',
      'Provinsi Ria',
      'Provinsi Sulawesi Barat',
      'Provinsi Sulawesi Selatan',
      'Provinsi Sumatera Barat',
      'Sulawesi Tengah',
      'Sulawesi Tenggara',
      'Sulawesi Utara',
      'Sumatera Selatan',
      'Sumatera Utara',
      'West Nusa Tenggara',
    ],
    ir: [
      'Alborz',
      'Bushehr',
      'East Azerbaijan',
      'Fars',
      'Hormozgan',
      'Isfahan',
      'Kerman',
      'Khuzestan',
      'Ostan-e Ardabil',
      'Ostan-e Azarbayjan-e Gharbi',
      'Ostan-e Chahar Mahal va Bakhtiari',
      'Ostan-e Gilan',
      'Ostan-e Golestan',
      'Ostan-e Hamadan',
      'Ostan-e Ilam',
      'Ostan-e Kermanshah',
      'Ostan-e Khorasan-e Jonubi',
      'Ostan-e Khorasan-e Razavi',
      'Ostan-e Khorasan-e Shomali',
      'Ostan-e Kohgiluyeh va Bowyer Ahmad',
      'Ostan-e Kordestan',
      'Ostan-e Lorestan',
      'Ostan-e Markazi',
      'Ostan-e Mazandaran',
      'Ostan-e Qazvin',
      'Ostan-e Semnan',
      'Ostan-e Tehran',
      'Ostan-e Zanjan',
      'Qom',
      'Sistan and Baluchestan',
      'Yazd',
    ],
    iq: [
      'An Najaf',
      'Dihok',
      'Diyala',
      'Muhafazat al Anbar',
      'Muhafazat al Basrah',
      'Muhafazat al Muthanna',
      'Muhafazat al Qadisiyah',
      'Muhafazat Arbil',
      'Muhafazat as Sulaymaniyah',
      'Muhafazat Babil',
      'Muhafazat Baghdad',
      'Muhafazat Dhi Qar',
      "Muhafazat Karbala'",
      'Muhafazat Kirkuk',
      'Muhafazat Maysan',
      'Muhafazat Ninawa',
      'Muhafazat Salah ad Din',
      'Muhafazat Wasit',
    ],
    ie: ['Connaught', 'Leinster', 'Munster', 'Ulster'],
    il: ['Central District', 'Haifa', 'Jerusalem', 'Northern District', 'Southern District', 'Tel Aviv District'],
    it: [
      'Abruzzo',
      'Basilicata',
      'Calabria',
      'Campania',
      'Emilia-Romagna',
      'Friuli Venezia Giulia',
      'Lazio',
      'Liguria',
      'Lombardia',
      'Marche',
      'Molise',
      'Piemonte',
      'Puglia',
      "Regione Autonoma Valle d'Aosta",
      'Sardegna',
      'Sicilia',
      'Toscana',
      'Trentino-Alto Adige',
      'Umbria',
      'Veneto',
    ],
    jm: [
      'Clarendon',
      'Kingston',
      'Manchester',
      'Parish of Hanover',
      'Parish of Saint Ann',
      'Portland',
      'Saint Andrew',
      'Saint Catherine',
      'Saint Elizabeth',
      'Saint James',
      'Saint Mary',
      'Saint Thomas',
      'Trelawny',
      'Westmoreland',
    ],
    jp: [
      'Aichi-ken',
      'Akita',
      'Aomori-ken',
      'Chiba-ken',
      'Ehime',
      'Fukui',
      'Fukuoka Prefecture',
      'Fukushima-ken',
      'Gif',
      'Gunma-ken',
      'Hiroshima-ken',
      'Hokkaido',
      'Hyogo',
      'Ibaraki',
      'Ishikawa-ken',
      'Iwate',
      'Kagawa-ken',
      'Kagoshima-ken',
      'Kanagawa',
      'Kochi Prefecture',
      'Kumamoto',
      'Kyoto Prefecture',
      'Mie-ken',
      'Miyagi-ken',
      'Miyazaki',
      'Nagano-ken',
      'Nagasaki Prefecture',
      'Nara-ken',
      'Niigata-ken',
      'Oita Prefecture',
      'Okayama-ken',
      'Okinawa',
      'Osaka-f',
      'Saga-ken',
      'Saitama-ken',
      'Shiga',
      'Shimane-ken',
      'Shizuoka',
      'Tochigi',
      'Tokushima-ken',
      'Tokyo Prefecture',
      'Tottori',
      'Toyama-ken',
      'Wakayama',
      'Yamagata-ken',
      'Yamaguchi',
      'Yamanashi',
    ],
    jo: [
      'Ajloun',
      'Al Karak',
      'Al Mafraq',
      'Amman Governorate',
      'Irbid',
      'Jerash',
      "Muhafazat al 'Aqabah",
      "Muhafazat al Balqa'",
      'Muhafazat at Tafilah',
      "Muhafazat az Zarqa'",
      "Muhafazat Ma'an",
      'Muhafazat Madaba',
    ],
    kz: [
      "Aktyubinskaya Oblast'",
      'Almaty Oblysy',
      'Almaty Qalasy',
      'Aqmola Oblysy',
      'Astana Qalasy',
      'Atyrau Oblysy',
      'Baikonur',
      'East Kazakhstan',
      "Mangistauskaya Oblast'",
      'North Kazakhstan',
      'Pavlodar Oblysy',
      'Qaraghandy Oblysy',
      'Qostanay Oblysy',
      'Qyzylorda Oblysy',
      'South Kazakhstan',
      'West Kazakhstan',
      'Zhambyl Oblysy',
    ],
    ke: [
      'Baringo District',
      'Bomet District',
      'Bungoma District',
      'Busia District',
      'Embu District',
      'Garissa District',
      'Homa Bay District',
      'Isiolo District',
      'Kajiado District',
      'Kakamega District',
      'Kericho District',
      'Kiambu District',
      'Kilifi District',
      'Kirinyaga District',
      'Kisii District',
      'Kisum',
      'Kitui District',
      'Kwale District',
      'Laikipia District',
      'Lam',
      'Machakos District',
      'Makueni District',
      'Mandera District',
      'Marakwet District',
      'Marsabit District',
      'Mer',
      'Migori District',
      'Mombasa District',
      "Murang'a District",
      'Nairobi Province',
      'Nakuru District',
      'Nandi South District',
      'Narok District',
      'Nyamira District',
      'Nyandarua District',
      'Nyeri District',
      'Samburu District',
      'Siaya District',
      'Taita Taveta District',
      'Tana River District',
      'Tharaka District',
      'Trans Nzoia District',
      'Turkana District',
      'Uasin Gish',
      'Vihiga District',
      'Wajir District',
      'West Pokot District',
    ],
    ki: ['Gilbert Islands', 'Line Islands', 'Phoenix Islands'],
    kp: [
      'Chagang-do',
      'Hambuk',
      'Hamnam',
      'Hwanghae-bukto',
      'Hwanghae-namdo',
      'Kangwon-do',
      "P'yongan-bukto",
      "P'yongan-namdo",
      'Pyongyang',
      'Rason',
      'Ryanggang',
    ],
    kr: [
      'Busan',
      'Chungcheongbuk-do',
      'Chungcheongnam-do',
      'Daeg',
      'Daejeon',
      'Gangwon-do',
      'Gwangj',
      'Gyeonggi-do',
      'Gyeongsangbuk-do',
      'Gyeongsangnam-do',
      'Incheon',
      'Jeju-do',
      'Jeollabuk-do',
      'Jeollanam-do',
      'Sejong-si',
      'Seoul',
      'Ulsan',
    ],
    kw: [
      'Al Asimah Governorate',
      'Muhafazat al Ahmadi',
      'Muhafazat al Farwaniyah',
      "Muhafazat al Jahra'",
      'Muhafazat Hawalli',
      'Muhafazat Mubarak al Kabir',
    ],
    kg: [
      'Batken',
      "Chuyskaya Oblast'",
      'Gorod Bishkek',
      'Issyk-Kul Region',
      'Jalal-Abad oblast',
      'Naryn oblast',
      'Osh City',
      'Osh Oblasty',
      'Talas',
    ],
    la: [
      'Attap',
      'Bolikhamxai',
      'Champasak',
      'Houaphan',
      'Khammouan',
      'Khoueng Bokeo',
      'Khoueng Oudomxai',
      'Khoueng Phongsali',
      'Khoueng Savannakhet',
      'Khoueng Xekong',
      'Louangnamtha',
      'Luang Prabang Province',
      'Salavan',
      'Vientiane Prefecture',
      'Vientiane Province',
      'Xaignabouli',
      'Xaisomboun Province',
      'Xiangkhouang',
    ],
    lv: [
      'Adazu Novads',
      'Aglonas Novads',
      'Aizkraukles Rajons',
      'Aizputes Novads',
      'Aknistes Novads',
      'Alojas Novads',
      'Alsungas Novads',
      'Aluksnes Novads',
      'Amatas Novads',
      'Apes Novads',
      'Auces Novads',
      'Babites Novads',
      'Baldones Novads',
      'Baltinavas Novads',
      'Balvu Novads',
      'Bauskas Novads',
      'Beverinas Novads',
      'Brocenu Novads',
      'Burtnieku Novads',
      'Carnikavas Novads',
      'Cesu Novads',
      'Cesvaines Novads',
      'Ciblas Novads',
      'Dagdas Novads',
      'Daugavpils',
      'Daugavpils municipality',
      'Dobeles Rajons',
      'Dundagas Novads',
      'Durbes Novads',
      'Engures Novads',
      'Erglu Novads',
      'Garkalne Municipality',
      'Grobinas Novads',
      'Gulbenes Rajons',
      'Iecavas Novads',
      'Ikskiles Novads',
      'Ilukstes Novads',
      'Incukalna Novads',
      'Jaunjelgavas Novads',
      'Jaunpiebalgas Novads',
      'Jaunpils Novads',
      'Jekabpils',
      'Jekabpils Municipality',
      'Jelgava',
      'Jelgavas Rajons',
      'Jurmala',
      'Kandavas Novads',
      'Karsavas Novads',
      'Keguma Novads',
      'Kekavas Novads',
      'Kocenu Novads',
      'Kokneses Novads',
      'Kraslavas Rajons',
      'Krimuldas Novads',
      'Krustpils Novads',
      'Kuldigas Rajons',
      'Lielvardes Novads',
      'Liepaja',
      'Ligatnes Novads',
      'Limbazu Rajons',
      'Livanu Novads',
      'Lubanas Novads',
      'Ludzas Rajons',
      'Madona Municipality',
      'Malpils Novads',
      'Marupes Novads',
      'Mazsalacas Novads',
      'Mersraga Novads',
      'Nauksenu Novads',
      'Neretas Novads',
      'Nicas Novads',
      'Ogres novads',
      'Olaines Novads',
      'Ozolnieku Novads',
      'Pargaujas Novads',
      'Pavilostas Novads',
      'Plavinu Novads',
      'Preili Municipality',
      'Priekules Novads',
      'Priekuli Municipality',
      'Raunas Novads',
      'Rezekne',
      'Rezeknes Novads',
      'Riebinu Novads',
      'Riga',
      'Rojas Novads',
      'Ropazu Novads',
      'Rucavas Novads',
      'Rugaju Novads',
      'Rujienas Novads',
      'Rundales Novads',
      'Salacgrivas Novads',
      'Salas Novads',
      'Salaspils Novads',
      'Saldus Municipality',
      'Saulkrastu Novads',
      'Sejas Novads',
      'Siguldas Novads',
      'Skriveru Novads',
      'Skrundas Novads',
      'Smiltenes Novads',
      'Stopinu Novads',
      'Strencu Novads',
      'Talsi Municipality',
      'Tervetes Novads',
      'Tukuma Rajons',
      'Vainodes Novads',
      'Valka Municipality',
      'Valmiera District',
      'Varaklanu Novads',
      'Varkavas Novads',
      'Vecpiebalgas Novads',
      'Vecumnieku Novads',
      'Ventspils',
      'Ventspils Municipality',
      'Viesites Novads',
      'Vilakas Novads',
      'Vilanu Novads',
      'Zilupes Novads',
    ],
    lb: [
      'Beyrouth',
      'Mohafazat Aakkar',
      'Mohafazat Baalbek-Hermel',
      'Mohafazat Beqaa',
      'Mohafazat Liban-Nord',
      'Mohafazat Liban-Sud',
      'Mohafazat Mont-Liban',
      'Mohafazat Nabatiye',
    ],
    ls: [
      'Berea',
      'Butha-Buthe',
      'Leribe District',
      'Mafeteng District',
      'Maser',
      "Mohale's Hoek District",
      'Mokhotlong',
      "Qacha's Nek",
      'Quthing',
      'Thaba-Tseka',
    ],
    lr: [
      'Bomi County',
      'Bong County',
      'Gbarpolu County',
      'Grand Bassa County',
      'Grand Cape Mount County',
      'Grand Gedeh County',
      'Grand Kru County',
      'Lofa County',
      'Margibi County',
      'Maryland County',
      'Montserrado County',
      'Nimba County',
      'River Cess County',
      'River Gee County',
      'Sinoe County',
    ],
    ly: [
      'Al Jufrah',
      'Al Kufrah',
      'Al Marj',
      'Al Marqab',
      'Darnah',
      'Jabal al Gharbi District',
      'Murzuq',
      'Sha`biyat Nalut',
      "Sha'biyat al Butnan",
      "Sha'biyat al Jabal al Akhdar",
      "Sha'biyat al Jafarah",
      "Sha'biyat al Wahat",
      "Sha'biyat an Nuqat al Khams",
      "Sha'biyat az Zawiyah",
      "Sha'biyat Banghazi",
      "Sha'biyat Ghat",
      "Sha'biyat Misratah",
      "Sha'biyat Sabha",
      "Sha'biyat Wadi al Hayat",
      "Sha'biyat Wadi ash Shati'",
      'Surt',
      'Tripoli',
    ],
    li: [
      'Balzers',
      'Eschen',
      'Gamprin',
      'Mauren',
      'Planken',
      'Ruggell',
      'Schaan',
      'Schellenberg',
      'Triesen',
      'Triesenberg',
      'Vaduz',
    ],
    lt: [
      'Alytus County',
      'Kaunas County',
      'Klaipeda County',
      'Marijampole County',
      'Panevezys',
      'Siauliai County',
      'Taurage County',
      'Telsiu apskritis',
      'Utena County',
      'Vilniaus apskritis',
    ],
    lu: [
      "Canton d'Echternach",
      "Canton d'Esch-sur-Alzette",
      'Capellen',
      'Clervaux',
      'Diekirch',
      'Grevenmacher',
      'Luxembourg',
      'Mersch',
      'Redange',
      'Remich',
      'Vianden',
      'Wiltz',
    ],
    mo: [],
    mk: [
      'Aerodrom',
      'Berovo',
      'Bitola',
      'Bogdanci',
      'Bogovinje',
      'Bosilovo',
      'Brvenica',
      'Butel',
      'Cair',
      'Cesinovo-Oblesevo',
      'Debar',
      'Debarca',
      'Demir Hisar',
      'Demir Kapija',
      'Dolneni',
      'Drugovo',
      'Gazi Baba',
      'Gevgelija',
      'Gostivar',
      'Grad Skopje',
      'Gradsko',
      'Ilinden',
      'Jegunovce',
      'Karbinci',
      'Kavadarci',
      'Kisela Voda',
      'Kratovo',
      'Kriva Palanka',
      'Kumanovo',
      'Lozovo',
      'Makedonska Kamenica',
      'Makedonski Brod',
      'Mogila',
      'Negotino',
      'Novaci',
      'Novo Selo',
      'Ohrid',
      'Opstina Aracinovo',
      'Opstina Caska',
      'Opstina Centar',
      'Opstina Centar Zupa',
      'Opstina Cucer-Sandevo',
      'Opstina Delcevo',
      'Opstina Dojran',
      'Opstina Gjorce Petrov',
      'Opstina Karpos',
      'Opstina Kicevo',
      'Opstina Kocani',
      'Opstina Konce',
      'Opstina Krivogastani',
      'Opstina Krusevo',
      'Opstina Lipkovo',
      'Opstina Mavrovo i Rostusa',
      'Opstina Pehcevo',
      'Opstina Probistip',
      'Opstina Radovis',
      'Opstina Rankovce',
      'Opstina Sopiste',
      'Opstina Staro Nagoricane',
      'Opstina Stip',
      'Opstina Studenicani',
      'Opstina Vevcani',
      'Opstina Vranestica',
      'Opstina Vrapciste',
      'Opstina Zelino',
      'Oslomej',
      'Petrovec',
      'Plasnica',
      'Prilep',
      'Resen',
      'Rosoman',
      'Saraj',
      'Struga',
      'Strumica',
      'Suto Orizari',
      'Sveti Nikole',
      'Tearce',
      'Tetovo',
      'Valandovo',
      'Vasilevo',
      'Veles',
      'Vinica',
      'Zajas',
      'Zelenikovo',
      'Zrnovci',
    ],
    mg: [
      'Alaotra Mangoro Region',
      "Amoron'i Mania Region",
      'Analamanga Region',
      'Analanjirofo Region',
      'Androy Region',
      'Anosy Region',
      'Atsimo-Andrefana Region',
      'Atsimo-Atsinanana Region',
      'Atsinanana Region',
      'Betsiboka Region',
      'Boeny Region',
      'Bongolava Region',
      'Diana Region',
      'Haute Matsiatra Region',
      'Ihorombe Region',
      'Itasy Region',
      'Melaky Region',
      'Menabe Region',
      'Sava Region',
      'Sofia Region',
      'Vakinankaratra Region',
      'Vatovavy Fitovinany Region',
    ],
    mw: ['Central Region', 'Northern Region', 'Southern Region'],
    my: [
      'Johor',
      'Kedah',
      'Kelantan',
      'Kuala Lumpur',
      'Labuan',
      'Melaka',
      'Negeri Sembilan',
      'Pahang',
      'Perak',
      'Perlis',
      'Pulau Pinang',
      'Putrajaya',
      'Sabah',
      'Sarawak',
      'Selangor',
      'Terengganu',
    ],
    mv: [
      'Alifu Atholh',
      'Baa Atholh',
      'Dhaalu Atholh',
      'Faafu Atholh',
      'Gaafu Alifu Atholh',
      'Gaafu Dhaalu Atholh',
      'Gnaviyani Atholh',
      'Haa Alifu Atholh',
      'Haa Dhaalu Atholh',
      'Kaafu Atoll',
      'Laamu Atholh',
      'Lhaviyani Atholh',
      'Meemu Atholh',
      'Noonu Atoll',
      'Raa Atholh',
      'Seenu Atholh',
      'Shaviyani Atholh',
      'Southern Ari Atoll',
      'Thaa Atholh',
      'Vaavu Atholh',
    ],
    ml: [
      'Bamako Region',
      'Gao Region',
      'Kayes Region',
      'Kidal Region',
      'Koulikoro Region',
      'Mopti Region',
      'Segou Region',
      'Sikasso Region',
      'Tombouctou Region',
    ],
    mt: [
      'Attard',
      'Balzan',
      'Birkirkara',
      'Birzebbuga',
      'Bormla',
      'Dingli',
      'Ghajnsielem',
      'Hal Gharghur',
      'Hal Ghaxaq',
      'Haz-Zabbar',
      'Haz-Zebbug',
      'Il-Belt Valletta',
      'Il-Birg',
      'Il-Fgura',
      'Il-Fontana',
      'Il-Furjana',
      'Il-Gudja',
      'Il-Gzira',
      'Il-Hamrun',
      'Il-Kalkara',
      'Il-Marsa',
      'Il-Mellieha',
      'Il-Mosta',
      'Il-Munxar',
      'Il-Qala',
      'Il-Qrendi',
      'In-Nadur',
      'In-Naxxar',
      'Ir-Rabat',
      'Is-Siggiewi',
      'Is-Swieqi',
      'Ix-Xaghra',
      'Ix-Xewkija',
      'Ix-Xghajra',
      'Iz-Zebbug',
      'Iz-Zejtun',
      'Iz-Zurrieq',
      'Kirkop',
      'L-Gharb',
      'L-Ghasri',
      'L-Iklin',
      'L-Imdina',
      'L-Imgarr',
      'L-Imqabba',
      'L-Imsida',
      'L-Imtarfa',
      'L-Isla',
      'Lija',
      'Luqa',
      'Marsaskala',
      'Marsaxlokk',
      'Paola',
      'Pembroke',
      'Qormi',
      'Safi',
      'Saint John',
      "Saint Julian's",
      'Saint Lawrence',
      'Saint Lucia',
      "Saint Paul's Bay",
      'Saint Venera',
      'Sannat',
      "Ta' Kercem",
      "Ta' Xbiex",
      'Tal-Pieta',
      'Tarxien',
      'Tas-Sliema',
      'Victoria',
    ],
    mh: [
      'Ailinginae Atoll',
      'Ailinglaplap Atoll',
      'Ailuk Atoll',
      'Arno Atoll',
      'Aur Atoll',
      'Bikar Atoll',
      'Bikini Atoll',
      'Bokak Atoll',
      'Ebon Atoll',
      'Enewetak Atoll',
      'Erikub Atoll',
      'Jabat Island',
      'Jaluit Atoll',
      'Jemo Island',
      'Kili Island',
      'Kwajalein Atoll',
      'Lae Atoll',
      'Lib Island',
      'Likiep Atoll',
      'Majuro Atoll',
      'Maloelap Atoll',
      'Mejit Island',
      'Mili Atoll',
      'Namdrik Atoll',
      'Namu Atoll',
      'Rongelap Atoll',
      'Rongrik Atoll',
      'Taka Atoll',
      'Ujae Atoll',
      'Ujelang Atoll',
      'Utrik Atoll',
      'Wotho Atoll',
      'Wotje Atoll',
    ],
    mq: ['Martinique'],
    mr: [
      'Adrar',
      'Assaba',
      'Brakna',
      'Dakhlet Nouadhibo',
      'Gorgol',
      'Guidimaka',
      'Hodh ech Chargui',
      'Hodh El Gharbi',
      'Inchiri',
      'Nouakchott Nord',
      'Nouakchott Ouest',
      'Nouakchott Sud',
      'Tagant',
      'Tiris Zemmour',
      'Wilaya du Trarza',
    ],
    mu: [
      'Agalega Islands',
      'Black River District',
      'Cargados Carajos',
      'Flacq District',
      'Grand Port District',
      'Moka District',
      'Pamplemousses District',
      'Plaines Wilhems District',
      'Port Louis District',
      'Riviere du Rempart District',
      'Rodrigues',
      'Savanne District',
    ],
    yt: [
      'Acoua',
      'Bandraboua',
      'Bandrele',
      'Boueni',
      'Chiconi',
      'Chirongui',
      'Dembeni',
      'Dzaoudzi',
      'Kani-Keli',
      'Koungo',
      "M'Tsangamouji",
      'Mamoudzo',
      'Mtsamboro',
      'Ouangani',
      'Pamandzi',
      'Sada',
      'Tsingoni',
    ],
    mx: [
      'Ciudad de Mexico',
      'Estado de Aguascalientes',
      'Estado de Baja California',
      'Estado de Baja California Sur',
      'Estado de Campeche',
      'Estado de Chiapas',
      'Estado de Chihuahua',
      'Estado de Coahuila de Zaragoza',
      'Estado de Colima',
      'Estado de Durango',
      'Estado de Guanajuato',
      'Estado de Guerrero',
      'Estado de Hidalgo',
      'Estado de Jalisco',
      'Estado de Mexico',
      'Estado de Michoacan de Ocampo',
      'Estado de Morelos',
      'Estado de Nayarit',
      'Estado de Nuevo Leon',
      'Estado de Oaxaca',
      'Estado de Puebla',
      'Estado de Queretaro',
      'Estado de Quintana Roo',
      'Estado de San Luis Potosi',
      'Estado de Sinaloa',
      'Estado de Sonora',
      'Estado de Tabasco',
      'Estado de Tamaulipas',
      'Estado de Tlaxcala',
      'Estado de Veracruz-Llave',
      'Estado de Yucatan',
      'Estado de Zacatecas',
    ],
    md: [
      'Anenii Noi',
      'Basarabeasca',
      'Briceni',
      'Cantemir',
      'Cimislia',
      'Criuleni',
      'Donduseni',
      'Falesti',
      'Floresti',
      'Gagauzia',
      'Glodeni',
      'Hincesti',
      'Ialoveni',
      'Leova',
      'Municipiul Balti',
      'Municipiul Bender',
      'Municipiul Chisina',
      'Nisporeni',
      'Orhei',
      'Raionul Cahul',
      'Raionul Calarasi',
      'Raionul Causeni',
      'Raionul Drochia',
      'Raionul Dubasari',
      'Raionul Edinet',
      'Raionul Ocnita',
      'Raionul Soroca',
      'Raionul Stefan Voda',
      'Raionul Straseni',
      'Raionul Ungheni',
      'Rezina',
      'Riscani',
      'Singerei',
      'Soldanesti',
      'Taraclia',
      'Telenesti',
      'Unitatea Teritoriala din Stinga Nistrului',
    ],
    mc: ['Commune de Monaco'],
    mn: [
      'Arhangay Aymag',
      'Bayan-OElgiy Aymag',
      'Bayanhongor Aymag',
      'Bulgan',
      'Darhan-Uul Aymag',
      'Dornod Aymag',
      'Dornogovi Aymag',
      'Dundgovi Aymag',
      'Dzavhan Aymag',
      'Govi-Altay Aymag',
      'Govi-Sumber',
      'Hentiy Aymag',
      'Hovd',
      'Hovsgol Aymag',
      'OEmnoegovi Province',
      'OEvoerhangay Aymag',
      'Orhon Aymag',
      'Selenge Aymag',
      'Suhbaatar Aymag',
      'Tov Aymag',
      'Ulaanbaatar Hot',
      'Uvs Aymag',
    ],
    ms: ['Parish of Saint Anthony', 'Parish of Saint Georges', 'Parish of Saint Peter'],
    ma: [
      'Beni Mellal-Khenifra',
      'Casablanca-Settat',
      'Dakhla-Oued Ed-Dahab',
      'Draa-Tafilalet',
      'Fes-Meknes',
      'Guelmim-Oued Noun',
      'Laayoune-Sakia El Hamra',
      'Marrakesh-Safi',
      'Oriental',
      'Rabat-Sale-Kenitra',
      'Souss-Massa',
      'Tanger-Tetouan-Al Hoceima',
    ],
    mz: [
      'Cabo Delgado Province',
      'Cidade de Maputo',
      'Gaza Province',
      'Inhambane Province',
      'Manica Province',
      'Maputo Province',
      'Nampula',
      'Niassa Province',
      'Provincia de Zambezia',
      'Sofala Province',
      'Tete',
    ],
    mm: [
      'Ayeyawady Region',
      'Bago Region',
      'Chin State',
      'Kachin State',
      'Kayah State',
      'Kayin State',
      'Magway Region',
      'Mandalay Region',
      'Mon State',
      'Nay Pyi Taw',
      'Rakhine State',
      'Sagaing Region',
      'Shan State',
      'Taninthayi Region',
      'Yangon Region',
    ],
    na: [
      'Erongo',
      'Hardap',
      'Karas',
      'Kavango East Region',
      'Kavango West Region',
      'Khomas',
      'Kunene',
      'Ohangwena',
      'Omaheke',
      'Omusati',
      'Oshana',
      'Oshikoto',
      'Otjozondjupa',
      'Zambezi Region',
    ],
    nr: [
      'Aiwo District',
      'Anabar District',
      'Anetan District',
      'Anibare District',
      'Baiti District',
      'Boe District',
      'Buada District',
      'Denigomodu District',
      'Ewa District',
      'Ijuw District',
      'Meneng District',
      'Nibok District',
      'Uaboe District',
      'Yaren',
    ],
    np: ['Madhya Pashchimanchal', 'Madhyamanchal', 'Pashchimanchal', 'Patheka', 'Purwanchal', 'Sudur Pashchimanchal'],
    nl: [
      'Provincie Drenthe',
      'Provincie Flevoland',
      'Provincie Friesland',
      'Provincie Gelderland',
      'Provincie Groningen',
      'Provincie Limburg',
      'Provincie Noord-Brabant',
      'Provincie Noord-Holland',
      'Provincie Overijssel',
      'Provincie Utrecht',
      'Provincie Zeeland',
      'Provincie Zuid-Holland',
    ],
    an: [],
    nc: ['Province des iles Loyaute', 'Province Nord', 'Province Sud'],
    nz: [
      'Auckland',
      'Bay of Plenty',
      'Canterbury',
      'Chatham Islands',
      'Gisborne',
      "Hawke's Bay",
      'Manawatu-Wanganui',
      'Marlborough',
      'Nelson',
      'Northland',
      'Otago',
      'Southland',
      'Taranaki',
      'Tasman',
      'Waikato',
      'Wellington',
      'West Coast',
    ],
    ni: [
      'Costa Caribe Sur',
      'Departamento de Boaco',
      'Departamento de Carazo',
      'Departamento de Chinandega',
      'Departamento de Chontales',
      'Departamento de Esteli',
      'Departamento de Granada',
      'Departamento de Jinotega',
      'Departamento de Leon',
      'Departamento de Madriz',
      'Departamento de Managua',
      'Departamento de Masaya',
      'Departamento de Matagalpa',
      'Departamento de Nueva Segovia',
      'Departamento de Rio San Juan',
      'Departamento de Rivas',
      'North Atlantic Autonomous Region (RAAN)',
    ],
    ne: ['Agadez', 'Diffa', 'Dosso Region', 'Maradi', 'Niamey', 'Tahoua', 'Tillaberi Region', 'Zinder'],
    ng: [
      'Abia State',
      'Adamawa State',
      'Akwa Ibom State',
      'Anambra State',
      'Bauchi State',
      'Bayelsa State',
      'Benue State',
      'Borno State',
      'Cross River State',
      'Delta State',
      'Ebonyi State',
      'Edo',
      'Ekiti State',
      'Enugu State',
      'Federal Capital Territory',
      'Gombe State',
      'Imo State',
      'Jigawa State',
      'Kaduna State',
      'Kano State',
      'Katsina State',
      'Kebbi State',
      'Kogi State',
      'Kwara State',
      'Lagos State',
      'Nasarawa State',
      'Niger State',
      'Ogun State',
      'Ondo State',
      'Osun State',
      'Oyo State',
      'Plateau State',
      'Rivers State',
      'Sokoto State',
      'Taraba State',
      'Yobe State',
      'Zamfara State',
    ],
    nu: [],
    nf: [],
    mp: ['Northern Islands Municipality', 'Rota Municipality', 'Saipan Municipality', 'Tinian Municipality'],
    no: [
      'Akershus fylke',
      'Aust-Agder fylke',
      'Buskerud fylke',
      'Finnmark Fylke',
      'Hedmark fylke',
      'Hordaland Fylke',
      'More og Romsdal fylke',
      'Nord-Trondelag Fylke',
      'Nordland Fylke',
      'Oppland fylke',
      'Oslo County',
      'Ostfold fylke',
      'Rogaland Fylke',
      'Sogn og Fjordane Fylke',
      'Sor-Trondelag Fylke',
      'Telemark fylke',
      'Troms Fylke',
      'Vest-Agder Fylke',
      'Vestfold fylke',
    ],
    gg: [
      'Alderney',
      'Castel',
      'Forest',
      'Saint Andrew',
      'Saint Martin',
      'Saint Peter Port',
      'Saint Pierre du Bois',
      'Saint Sampson',
      'Saint Saviour',
      'Torteval',
      'Vale',
    ],
    om: [
      'Al Batinah North Governorate',
      'Al Batinah South Governorate',
      'Ash Sharqiyah South',
      'Az Zahirah',
      'Muhafazat ad Dakhiliyah',
      'Muhafazat al Buraymi',
      'Muhafazat al Wusta',
      'Muhafazat Masqat',
      'Muhafazat Shamal ash Sharqiyah',
      'Muhafazat Zufar',
      'Musandam',
    ],
    pk: [
      'Azad Kashmir',
      'Balochistan',
      'Federally Administered Tribal Areas',
      'Gilgit-Baltistan',
      'Islamabad',
      'Khyber Pakhtunkhwa Province',
      'Punjab Province',
      'Sindh',
    ],
    pw: [
      'Aimeliik',
      'Airai',
      'Angaur',
      'Hatohobei',
      'Kayangel',
      'Koror',
      'Melekeok',
      'Ngaraard',
      'Ngarchelong',
      'Ngardma',
      'Ngatpang',
      'Ngchesar',
      'Ngeremlengui',
      'Ngiwal',
      'Peleli',
      'Sonsorol',
    ],
    ps: ['Gaza Strip', 'West Bank'],
    pa: [
      'Embera-Wounaan',
      'Guna Yala',
      'Ngoebe-Bugle',
      'Panama Oeste',
      'Provincia de Bocas del Toro',
      'Provincia de Chiriqui',
      'Provincia de Cocle',
      'Provincia de Colon',
      'Provincia de Herrera',
      'Provincia de Los Santos',
      'Provincia de Panama',
      'Provincia de Veraguas',
      'Provincia del Darien',
    ],
    pg: [
      'Bougainville',
      'Central Province',
      'Chimbu Province',
      'East New Britain Province',
      'East Sepik Province',
      'Eastern Highlands Province',
      'Enga Province',
      'Gulf Province',
      'Hela',
      'Jiwaka',
      'Madang Province',
      'Manus Province',
      'Milne Bay Province',
      'Morobe Province',
      'National Capital District',
      'New Ireland Province',
      'Northern Province',
      'Southern Highlands Province',
      'West New Britain Province',
      'West Sepik Province',
      'Western Highlands Province',
      'Western Province',
    ],
    py: [
      'Asuncion',
      'Departamento Central',
      'Departamento de Alto Paraguay',
      'Departamento de Boqueron',
      'Departamento de Caaguaz',
      'Departamento de Caazapa',
      'Departamento de Canindey',
      'Departamento de Concepcion',
      'Departamento de Itapua',
      'Departamento de la Cordillera',
      'Departamento de Misiones',
      'Departamento de Neembuc',
      'Departamento de Paraguari',
      'Departamento de Presidente Hayes',
      'Departamento de San Pedro',
      'Departamento del Alto Parana',
      'Departamento del Amambay',
      'Departamento del Guaira',
    ],
    pe: [
      'Amazonas',
      'Ancash',
      'Apurimac',
      'Arequipa',
      'Ayacucho',
      'Cajamarca',
      'Callao',
      'Cusco',
      'Departamento de Moquegua',
      'Huancavelica',
      'Ica',
      'Junin',
      'La Libertad',
      'Lambayeque',
      'Lima',
      'Loreto',
      'Madre de Dios',
      'Pasco',
      'Piura',
      'Provincia de Lima',
      'Puno',
      'Region de Huanuco',
      'Region de San Martin',
      'Tacna',
      'Tumbes',
      'Ucayali',
    ],
    ph: [
      'Autonomous Region in Muslim Mindanao',
      'Bicol',
      'Cagayan Valley',
      'Calabarzon',
      'Caraga',
      'Central Luzon',
      'Central Visayas',
      'Cordillera Administrative Region',
      'Davao',
      'Eastern Visayas',
      'Ilocos',
      'Mimaropa',
      'National Capital Region',
      'Negros Island Region',
      'Northern Mindanao',
      'Soccsksargen',
      'Western Visayas',
      'Zamboanga Peninsula',
    ],
    pn: [],
    pl: [
      'Dolnoślaskie',
      'Kujawsko-Pomorskie',
      'Lubelskie',
      'Lubuskie',
      'Mazowieckie',
      'Małopolskie',
      'Opolskie',
      'Podkarpackie',
      'Podlaskie',
      'Pomorskie',
      'Warmińsko-Mazurskie',
      'Wielkopolskie',
      'Zachodniopomorskie',
      'Łódzkie',
      'Ślaskie',
      'Świetokrzyskie',
    ],
    pt: [
      'Azores',
      'Distrito da Guarda',
      'Distrito de Aveiro',
      'Distrito de Beja',
      'Distrito de Braga',
      'Distrito de Braganca',
      'Distrito de Castelo Branco',
      'Distrito de Coimbra',
      'Distrito de Evora',
      'Distrito de Faro',
      'Distrito de Leiria',
      'Distrito de Lisboa',
      'Distrito de Portalegre',
      'Distrito de Santarem',
      'Distrito de Setubal',
      'Distrito de Viana do Castelo',
      'Distrito de Vila Real',
      'Distrito de Vise',
      'Distrito do Porto',
      'Madeira',
    ],
    qa: [
      'Al Wakrah',
      'Baladiyat ad Dawhah',
      'Baladiyat al Khawr wa adh Dhakhirah',
      'Baladiyat ar Rayyan',
      'Baladiyat ash Shamal',
      "Baladiyat az Za'ayin",
      'Baladiyat Umm Salal',
    ],
    re: ['Reunion'],
    ro: [
      'Arad',
      'Bihor',
      'Bucuresti',
      'Constanta',
      'Covasna',
      'Dolj',
      'Giurgi',
      'Gorj',
      'Harghita',
      'Hunedoara',
      'Ilfov',
      'Judetul Alba',
      'Judetul Arges',
      'Judetul Baca',
      'Judetul Bistrita-Nasaud',
      'Judetul Botosani',
      'Judetul Braila',
      'Judetul Brasov',
      'Judetul Buza',
      'Judetul Calarasi',
      'Judetul Caras-Severin',
      'Judetul Cluj',
      'Judetul Dambovita',
      'Judetul Galati',
      'Judetul Ialomita',
      'Judetul Iasi',
      'Judetul Maramures',
      'Judetul Mehedinti',
      'Judetul Mures',
      'Judetul Neamt',
      'Judetul Salaj',
      'Judetul Sibi',
      'Judetul Timis',
      'Judetul Valcea',
      'Olt',
      'Prahova',
      'Satu Mare',
      'Suceava',
      'Teleorman',
      'Tulcea',
      'Vaslui',
      'Vrancea',
    ],
    ru: [
      'Altayskiy Kray',
      "Amurskaya Oblast'",
      "Arkhangel'skaya Oblast'",
      "Astrakhanskaya Oblast'",
      'Bashkortostan',
      "Belgorodskaya Oblast'",
      "Bryanskaya Oblast'",
      'Chechenskaya Respublika',
      "Chelyabinskaya Oblast'",
      'Chukotskiy Avtonomnyy Okrug',
      'Chuvashskaya Respublika',
      'Dagestan',
      "Irkutskaya Oblast'",
      "Ivanovskaya Oblast'",
      'Kabardino-Balkarskaya Respublika',
      "Kaliningradskaya Oblast'",
      'Kalmykiya',
      "Kaluzhskaya Oblast'",
      'Kamtchatski Kray',
      'Karachayevo-Cherkesiya',
      "Kemerovskaya Oblast'",
      'Khabarovskiy Kray',
      'Khanty-Mansiyskiy Avtonomnyy Okrug-Yugra',
      "Kirovskaya Oblast'",
      'Komi',
      "Kostromskaya Oblast'",
      'Krasnodarskiy Kray',
      'Krasnoyarskiy Kray',
      "Kurganskaya Oblast'",
      "Kurskaya Oblast'",
      "Leningradskaya Oblast'",
      "Lipetskaya Oblast'",
      "Magadanskaya Oblast'",
      'Moscow Oblast',
      'Moskva',
      "Murmanskaya Oblast'",
      'Nenetskiy Avtonomnyy Okrug',
      "Nizhegorodskaya Oblast'",
      'North Ossetia',
      "Novgorodskaya Oblast'",
      "Novosibirskaya Oblast'",
      "Omskaya Oblast'",
      "Orenburgskaya Oblast'",
      "Orlovskaya Oblast'",
      "Penzenskaya Oblast'",
      'Perm Krai',
      'Primorskiy Kray',
      "Pskovskaya Oblast'",
      'Respublika Adygeya',
      'Respublika Altay',
      'Respublika Buryatiya',
      'Respublika Ingushetiya',
      'Respublika Kareliya',
      'Respublika Khakasiya',
      'Respublika Mariy-El',
      'Respublika Mordoviya',
      'Respublika Sakha (Yakutiya)',
      'Respublika Tyva',
      "Rostovskaya Oblast'",
      "Ryazanskaya Oblast'",
      "Sakhalinskaya Oblast'",
      "Samarskaya Oblast'",
      'Sankt-Peterburg',
      "Saratovskaya Oblast'",
      "Smolenskaya Oblast'",
      "Stavropol'skiy Kray",
      "Sverdlovskaya Oblast'",
      "Tambovskaya Oblast'",
      'Tatarstan',
      "Tomskaya Oblast'",
      "Tul'skaya Oblast'",
      "Tverskaya Oblast'",
      "Tyumenskaya Oblast'",
      'Udmurtskaya Respublika',
      'Ulyanovsk Oblast',
      "Vladimirskaya Oblast'",
      "Volgogradskaya Oblast'",
      "Vologodskaya Oblast'",
      "Voronezhskaya Oblast'",
      'Yamalo-Nenetskiy Avtonomnyy Okrug',
      "Yaroslavskaya Oblast'",
      "Yevreyskaya Avtonomnaya Oblast'",
      "Zabaykal'skiy Kray",
    ],
    rw: ['Eastern Province', 'Kigali Province', 'Northern Province', 'Southern Province', 'Western Province'],
    gs: [],
    sh: ['Ascension', 'Saint Helena', 'Tristan da Cunha'],
    kn: [
      'Christ Church Nichola Town',
      'Saint Anne Sandy Point',
      'Saint George Basseterre',
      'Saint George Gingerland',
      'Saint James Windward',
      'Saint John Capesterre',
      'Saint John Figtree',
      'Saint Mary Cayon',
      'Saint Paul Capesterre',
      'Saint Paul Charlestown',
      'Saint Peter Basseterre',
      'Saint Thomas Lowland',
      'Saint Thomas Middle Island',
      'Trinity Palmetto Point',
    ],
    lc: [
      'Anse-la-Raye',
      'Canaries',
      'Castries',
      'Choiseul',
      'Dennery',
      'Gros-Islet',
      'Laborie',
      'Micoud',
      'Soufriere',
      'Vieux-Fort',
    ],
    pm: ['Commune de Saint-Pierre', 'Miquelon-Langlade'],
    vc: [
      'Grenadines',
      'Parish of Charlotte',
      'Parish of Saint Andrew',
      'Parish of Saint David',
      'Parish of Saint George',
      'Parish of Saint Patrick',
    ],
    ws: [
      "A'ana",
      'Aiga-i-le-Tai',
      'Atua',
      "Fa'asaleleaga",
      "Gaga'emauga",
      'Gagaifomauga',
      'Palauli',
      "Satupa'itea",
      'Tuamasaga',
      "Va'a-o-Fonoti",
      'Vaisigano',
    ],
    sm: [
      'Castello di Acquaviva',
      'Castello di Borgo Maggiore',
      'Castello di Domagnano',
      'Castello di Faetano',
      'Castello di Fiorentino',
      'Castello di Montegiardino',
      'Castello di San Marino Citta',
      'Chiesanuova',
      'Serravalle',
    ],
    st: ['Principe', 'Sao Tome'],
    sa: [
      'Al Jawf',
      'Al Madinah al Munawwarah',
      'Al Mintaqah ash Sharqiyah',
      'Al-Qassim Province',
      "Hai'l Region",
      'Jizan Region',
      'Makkah Province',
      "Mintaqat 'Asir",
      'Mintaqat al Bahah',
      'Mintaqat al Hudud ash Shamaliyah',
      'Mintaqat ar Riyad',
      'Mintaqat Najran',
      'Mintaqat Tabuk',
    ],
    sn: [
      'Dakar',
      'Diourbel',
      'Fatick',
      'Kaolack',
      'Kolda',
      'Louga',
      'Matam',
      'Region de Kaffrine',
      'Region de Kedougo',
      'Region de Sedhio',
      'Region de Thies',
      'Saint-Louis',
      'Tambacounda',
      'Ziguinchor',
    ],
    sc: [
      'Anse aux Pins',
      'Anse Boilea',
      'Anse Etoile',
      'Anse Royale',
      'Au Cap',
      'Baie Lazare',
      'Baie Sainte Anne',
      'Beau Vallon',
      'Bel Air',
      'Bel Ombre',
      'Cascade',
      'English River',
      'Glacis',
      'Grand Anse Mahe',
      'Grand Anse Praslin',
      'Inner Islands',
      'Les Mamelles',
      'Mont Buxton',
      'Mont Fleuri',
      'Plaisance',
      'Pointe Larue',
      'Port Glaud',
      'Roche Caiman',
      'Saint Louis',
      'Takamaka',
    ],
    sl: ['Eastern Province', 'Northern Province', 'Southern Province', 'Western Area'],
    sg: [
      'Central Singapore Community Development Council',
      'North East Community Development Region',
      'North West Community Development Council',
      'South East Community Development Council',
      'South West Community Development Council',
    ],
    sk: ['Banska Bystrica', 'Bratislava', 'Kosice', 'Nitra', 'Presov', 'Trencin', 'Trnava', 'Zilina'],
    si: [
      'Ankaran',
      'Beltinci',
      'Benedikt',
      'Bistrica ob Sotli',
      'Bloke',
      'Bohinj',
      'Borovnica',
      'Brda',
      'Brezovica',
      'Cankova',
      'Celje',
      'Cerklje na Gorenjskem',
      'Cerknica',
      'Cerkno',
      'Cerkvenjak',
      'Cirkulane',
      'Destrnik',
      'Dobje',
      'Dobrepolje',
      'Dobrna',
      'Dobrova-Polhov Gradec',
      'Dobrovnik',
      'Dol pri Ljubljani',
      'Dolenjske Toplice',
      'Dornava',
      'Dravograd',
      'Duplek',
      'Gorenja Vas-Poljane',
      'Gorje',
      'Gornja Radgona',
      'Gornji Grad',
      'Gornji Petrovci',
      'Grad',
      'Grosuplje',
      'Hajdina',
      'Hodos',
      'Horjul',
      'Hrastnik',
      'Hrpelje-Kozina',
      'Idrija',
      'Ig',
      'Ilirska Bistrica',
      'Izola',
      'Jesenice',
      'Jezersko',
      'Kamnik',
      'Kanal',
      'Kobilje',
      'Komen',
      'Komenda',
      'Koper',
      'Kostanjevica na Krki',
      'Kostel',
      'Kozje',
      'Kranj',
      'Kranjska Gora',
      'Kungota',
      'Kuzma',
      'Lenart',
      'Lendava',
      'Litija',
      'Ljubno',
      'Ljutomer',
      'Log-Dragomer',
      'Logatec',
      'Lovrenc na Pohorj',
      'Lukovica',
      'Makole',
      'Maribor',
      'Markovci',
      'Medvode',
      'Mestna Obcina Ljubljana',
      'Mestna Obcina Novo mesto',
      'Metlika',
      'Miren-Kostanjevica',
      'Mirna',
      'Mislinja',
      'Mokronog-Trebelno',
      'Moravske Toplice',
      'Mozirje',
      'Murska Sobota',
      'Muta',
      'Naklo',
      'Nazarje',
      'Nova Gorica',
      'Obcina Ajdovscina',
      'Obcina Apace',
      'Obcina Bled',
      'Obcina Bovec',
      'Obcina Braslovce',
      'Obcina Brezice',
      'Obcina Crensovci',
      'Obcina Crna na Koroskem',
      'Obcina Crnomelj',
      'Obcina Divaca',
      'Obcina Domzale',
      'Obcina Gorisnica',
      'Obcina Hoce-Slivnica',
      'Obcina Ivancna Gorica',
      'Obcina Jursinci',
      'Obcina Kidricevo',
      'Obcina Kobarid',
      'Obcina Kocevje',
      'Obcina Krizevci',
      'Obcina Krsko',
      'Obcina Lasko',
      'Obcina Loska Dolina',
      'Obcina Loski Potok',
      'Obcina Luce',
      'Obcina Majsperk',
      'Obcina Menges',
      'Obcina Mezica',
      'Obcina Miklavz na Dravskem Polj',
      'Obcina Mirna Pec',
      'Obcina Moravce',
      'Obcina Ormoz',
      'Obcina Podcetrtek',
      'Obcina Poljcane',
      'Obcina Race-Fram',
      'Obcina Radece',
      'Obcina Ravne na Koroskem',
      'Obcina Razkrizje',
      'Obcina Recica ob Savinji',
      'Obcina Rence-Vogrsko',
      'Obcina Rogaska Slatina',
      'Obcina Rogasovci',
      'Obcina Ruse',
      'Obcina Salovci',
      'Obcina Semic',
      'Obcina Sempeter-Vrtojba',
      'Obcina Sencur',
      'Obcina Sentilj',
      'Obcina Sentjernej',
      'Obcina Sentjur',
      'Obcina Sentrupert',
      'Obcina Sezana',
      'Obcina Skocjan',
      'Obcina Skofja Loka',
      'Obcina Skofljica',
      'Obcina Smarje pri Jelsah',
      'Obcina Smarjeske Toplice',
      'Obcina Smartno ob Paki',
      'Obcina Smartno pri Litiji',
      'Obcina Sodrazica',
      'Obcina Solcava',
      'Obcina Sostanj',
      'Obcina Sredisce ob Dravi',
      'Obcina Starse',
      'Obcina Store',
      'Obcina Straza',
      'Obcina Sveti Andraz v Slovenskih Goricah',
      'Obcina Sveti Jurij ob Scavnici',
      'Obcina Sveti Tomaz',
      'Obcina Tisina',
      'Obcina Tolmin',
      'Obcina Trnovska vas',
      'Obcina Trzic',
      'Obcina Turnisce',
      'Obcina Velike Lasce',
      'Obcina Verzej',
      'Obcina Zalec',
      'Obcina Zavrc',
      'Obcina Zelezniki',
      'Obcina Zetale',
      'Obcina Ziri',
      'Obcina Zirovnica',
      'Obcina Zrece',
      'Obcina Zuzemberk',
      'Odranci',
      'Oplotnica',
      'Osilnica',
      'Pesnica',
      'Piran',
      'Pivka',
      'Podlehnik',
      'Podvelka',
      'Polzela',
      'Postojna',
      'Prebold',
      'Preddvor',
      'Prevalje',
      'Ptuj',
      'Puconci',
      'Radenci',
      'Radlje ob Dravi',
      'Radovljica',
      'Ribnica',
      'Ribnica na Pohorj',
      'Rogatec',
      'Selnica ob Dravi',
      'Sevnica',
      'Slovenj Gradec',
      'Slovenska Bistrica',
      'Slovenske Konjice',
      'Sveta Ana',
      'Sveta Trojica v Slovenskih Goricah',
      'Sveti Jurij v Slovenskih Goricah',
      'Tabor',
      'Trbovlje',
      'Trebnje',
      'Trzin',
      'Velenje',
      'Velika Polana',
      'Videm',
      'Vipava',
      'Vitanje',
      'Vodice',
      'Vojnik',
      'Vransko',
      'Vrhnika',
      'Vuzenica',
      'Zagorje ob Savi',
    ],
    sb: [
      'Central Province',
      'Choiseul',
      'Guadalcanal Province',
      'Honiara',
      'Isabel Province',
      'Makira-Ulawa Province',
      'Malaita Province',
      'Rennell and Bellona',
      'Temotu Province',
      'Western Province',
    ],
    so: [
      'Gobolka Awdal',
      'Gobolka Bakool',
      'Gobolka Banaadir',
      'Gobolka Bari',
      'Gobolka Bay',
      'Gobolka Galguduud',
      'Gobolka Gedo',
      'Gobolka Hiiraan',
      'Gobolka Jubbada Dhexe',
      'Gobolka Jubbada Hoose',
      'Gobolka Mudug',
      'Gobolka Nugaal',
      'Gobolka Sanaag',
      'Gobolka Shabeellaha Dhexe',
      'Gobolka Shabeellaha Hoose',
      'Gobolka Sool',
      'Gobolka Togdheer',
      'Gobolka Woqooyi Galbeed',
    ],
    za: [
      'Eastern Cape',
      'Free State',
      'Gauteng',
      'Limpopo',
      'Mpumalanga',
      'Province of KwaZulu-Natal',
      'Province of North West',
      'Province of Northern Cape',
      'Western Cape',
    ],
    es: [
      'Andalucia',
      'Aragon',
      'Canary Islands',
      'Cantabria',
      'Castilla y Leon',
      'Castilla-La Mancha',
      'Catalunya',
      'Ceuta',
      'Comunidad de Madrid',
      'Comunitat Autonoma de les Illes Balears',
      'Comunitat Valenciana',
      'Euskal Autonomia Erkidegoa',
      'Extremadura',
      'Galicia',
      'La Rioja',
      'Melilla',
      'Navarra',
      'Principality of Asturias',
      'Region de Murcia',
    ],
    lk: [
      'Central Province',
      'Eastern Province',
      'North Central Province',
      'North Western Province',
      'Northern Province',
      'Province of Sabaragamuwa',
      'Province of Uva',
      'Southern Province',
      'Western Province',
    ],
    sd: [
      'Al Jazirah State',
      'Al Qadarif State',
      'Blue Nile',
      'Central Darfur State',
      'East Darfur',
      'Kassala State',
      'Khartoum',
      'North Darfur',
      'North Kordofan',
      'Northern',
      'Red Sea',
      'River Nile',
      'Sennar',
      'South Darfur',
      'South Kordofan',
      'West Darfur',
      'West Kordofan State',
      'White Nile',
    ],
    sr: [
      'Distrikt Brokopondo',
      'Distrikt Commewijne',
      'Distrikt Coronie',
      'Distrikt Marowijne',
      'Distrikt Nickerie',
      'Distrikt Para',
      'Distrikt Paramaribo',
      'Distrikt Saramacca',
      'Distrikt Sipaliwini',
      'Distrikt Wanica',
    ],
    sj: ['Jan Mayen', 'Svalbard'],
    sz: ['Hhohho District', 'Lubombo District', 'Manzini District', 'Shiselweni District'],
    se: [
      'Blekinge',
      'Dalarna',
      'Gaevleborgs laen',
      'Gotland',
      'Halland',
      'Jaemtlands laen',
      'Joenkoepings laen',
      'Kalmar',
      'Kronoberg',
      'Norrbotten',
      'OErebro laen',
      'OEstergoetlands laen',
      'Skane laen',
      'Soedermanlands laen',
      'Stockholm',
      'Uppsala',
      'Vaermlands laen',
      'Vaesterbottens laen',
      'Vaesternorrlands laen',
      'Vaestmanlands laen',
      'Vaestra Goetalands laen',
    ],
    ch: [
      'Canton de Berne',
      'Canton de Fribourg',
      'Canton de Vaud',
      'Canton du Valais',
      'Geneve',
      'Jura',
      'Kanton Aarga',
      'Kanton Appenzell Ausserrhoden',
      'Kanton Appenzell Innerrhoden',
      'Kanton Basel-Landschaft',
      'Kanton Basel-Stadt',
      'Kanton Glarus',
      'Kanton Graubunden',
      'Kanton Luzern',
      'Kanton Nidwalden',
      'Kanton Obwalden',
      'Kanton Schaffhausen',
      'Kanton Schwyz',
      'Kanton Solothurn',
      'Kanton St. Gallen',
      'Kanton Thurga',
      'Kanton Uri',
      'Kanton Zug',
      'Kanton Zurich',
      'Neuchatel',
      'Ticino',
    ],
    sy: [
      'Al-Hasakah Governorate',
      'Aleppo Governorate',
      'Ar-Raqqah Governorate',
      'As-Suwayda Governorate',
      'Damascus Governorate',
      'Daraa Governorate',
      'Deir ez-Zor Governorate',
      'Hama Governorate',
      'Homs Governorate',
      'Idlib Governorate',
      'Latakia Governorate',
      'Muhafazat Rif Dimashq',
      'Quneitra Governorate',
      'Tartus Governorate',
    ],
    tw: ['Fukien', 'Kaohsiung', 'Taipei', 'Taiwan'],
    tj: [
      'Districts of Republican Subordination',
      'Dushanbe',
      'Viloyati Khatlon',
      'Viloyati Mukhtori Kuhistoni Badakhshon',
      'Viloyati Sughd',
    ],
    tz: [
      'Arusha Region',
      'Coast Region',
      'Dar es Salaam Region',
      'Dodoma Region',
      'Geita Region',
      'Iringa Region',
      'Kagera Region',
      'Katavi Region',
      'Kigoma Region',
      'Kilimanjaro Region',
      'Lindi Region',
      'Manyara Region',
      'Mara Region',
      'Mbeya Region',
      'Morogoro Region',
      'Mtwara Region',
      'Mwanza Region',
      'Njombe Region',
      'Pemba North Region',
      'Pemba South Region',
      'Rukwa Region',
      'Ruvuma Region',
      'Shinyanga Region',
      'Simiyu Region',
      'Singida Region',
      'Tabora Region',
      'Tanga Region',
      'Zanzibar Central/South Region',
      'Zanzibar North Region',
      'Zanzibar Urban/West Region',
    ],
    th: [
      'Bangkok',
      'Changwat Amnat Charoen',
      'Changwat Ang Thong',
      'Changwat Bueng Kan',
      'Changwat Buri Ram',
      'Changwat Chachoengsao',
      'Changwat Chai Nat',
      'Changwat Chaiyaphum',
      'Changwat Chanthaburi',
      'Changwat Chiang Rai',
      'Changwat Chon Buri',
      'Changwat Chumphon',
      'Changwat Kalasin',
      'Changwat Kamphaeng Phet',
      'Changwat Kanchanaburi',
      'Changwat Khon Kaen',
      'Changwat Krabi',
      'Changwat Lampang',
      'Changwat Lamphun',
      'Changwat Loei',
      'Changwat Lop Buri',
      'Changwat Mae Hong Son',
      'Changwat Maha Sarakham',
      'Changwat Mukdahan',
      'Changwat Nakhon Nayok',
      'Changwat Nakhon Pathom',
      'Changwat Nakhon Phanom',
      'Changwat Nakhon Ratchasima',
      'Changwat Nakhon Sawan',
      'Changwat Nakhon Si Thammarat',
      'Changwat Nan',
      'Changwat Narathiwat',
      'Changwat Nong Bua Lamph',
      'Changwat Nong Khai',
      'Changwat Nonthaburi',
      'Changwat Pathum Thani',
      'Changwat Pattani',
      'Changwat Phangnga',
      'Changwat Phatthalung',
      'Changwat Phayao',
      'Changwat Phetchabun',
      'Changwat Phetchaburi',
      'Changwat Phichit',
      'Changwat Phitsanulok',
      'Changwat Phra Nakhon Si Ayutthaya',
      'Changwat Phrae',
      'Changwat Prachin Buri',
      'Changwat Prachuap Khiri Khan',
      'Changwat Ranong',
      'Changwat Ratchaburi',
      'Changwat Rayong',
      'Changwat Roi Et',
      'Changwat Sa Kaeo',
      'Changwat Sakon Nakhon',
      'Changwat Samut Prakan',
      'Changwat Samut Sakhon',
      'Changwat Samut Songkhram',
      'Changwat Saraburi',
      'Changwat Satun',
      'Changwat Si Sa Ket',
      'Changwat Sing Buri',
      'Changwat Songkhla',
      'Changwat Sukhothai',
      'Changwat Suphan Buri',
      'Changwat Surat Thani',
      'Changwat Surin',
      'Changwat Tak',
      'Changwat Trang',
      'Changwat Trat',
      'Changwat Ubon Ratchathani',
      'Changwat Udon Thani',
      'Changwat Uthai Thani',
      'Changwat Uttaradit',
      'Changwat Yala',
      'Changwat Yasothon',
      'Chiang Mai Province',
      'Phuket Province',
    ],
    tg: ['Centrale', 'Kara', 'Maritime', 'Plateaux', 'Savanes'],
    tk: ['Ataf', 'Fakaofo', 'Nukunon'],
    to: ['Eua', 'Ha`apai', 'Niuas', 'Tongatap', "Vava'"],
    tt: [
      'Arima',
      'Chaguanas',
      'City of Port of Spain',
      'Couva-Tabaquite-Talparo',
      'Diego Martin',
      'Mayaro',
      'Penal/Debe',
      'Point Fortin',
      'Princes Town',
      'San Fernando',
      'San Juan/Laventille',
      'Sangre Grande',
      'Siparia',
      'Tobago',
      'Tunapuna/Piarco',
    ],
    tn: [
      'Gafsa Governorate',
      'Gouvernorat de Beja',
      'Gouvernorat de Ben Arous',
      'Gouvernorat de Bizerte',
      'Gouvernorat de Gabes',
      'Gouvernorat de Jendouba',
      'Gouvernorat de Kairouan',
      'Gouvernorat de Kasserine',
      'Gouvernorat de Kebili',
      'Gouvernorat de Kef',
      "Gouvernorat de l'Ariana",
      'Gouvernorat de Mahdia',
      'Gouvernorat de Medenine',
      'Gouvernorat de Monastir',
      'Gouvernorat de Nabeul',
      'Gouvernorat de Sfax',
      'Gouvernorat de Sidi Bouzid',
      'Gouvernorat de Siliana',
      'Gouvernorat de Sousse',
      'Gouvernorat de Tozeur',
      'Gouvernorat de Tunis',
      'Gouvernorat de Zaghouan',
      'Manouba',
      'Tataouine',
    ],
    tr: [
      'Adana',
      'Adiyaman',
      'Afyonkarahisar',
      'Agri',
      'Aksaray',
      'Amasya',
      'Ankara',
      'Antalya',
      'Ardahan',
      'Artvin',
      'Aydin',
      'Balikesir',
      'Bartin',
      'Batman',
      'Bayburt',
      'Bilecik',
      'Bingoel',
      'Bitlis',
      'Bol',
      'Burdur',
      'Bursa',
      'Canakkale',
      'Cankiri',
      'Corum',
      'Denizli',
      'Diyarbakir',
      'Duezce',
      'Edirne',
      'Elazig',
      'Erzincan',
      'Erzurum',
      'Eskisehir',
      'Gaziantep',
      'Giresun',
      'Guemueshane',
      'Hakkari',
      'Hatay',
      'Igdir',
      'Isparta',
      'Istanbul',
      'Izmir',
      'Kahramanmaras',
      'Karabuek',
      'Karaman',
      'Kars',
      'Kastamon',
      'Kayseri',
      'Kilis',
      'Kirikkale',
      'Kirklareli',
      'Kirsehir',
      'Kocaeli',
      'Konya',
      'Kuetahya',
      'Malatya',
      'Manisa',
      'Mardin',
      'Mersin',
      'Mugla',
      'Mus',
      'Nevsehir',
      'Nigde',
      'Ord',
      'Osmaniye',
      'Rize',
      'Sakarya',
      'Samsun',
      'Sanliurfa',
      'Siirt',
      'Sinop',
      'Sirnak',
      'Sivas',
      'Tekirdag',
      'Tokat',
      'Trabzon',
      'Tunceli',
      'Usak',
      'Van',
      'Yalova',
      'Yozgat',
      'Zonguldak',
    ],
    tm: ['Ahal', 'Ashgabat', 'Balkan', 'Dasoguz Welayaty', 'Lebap', 'Mary'],
    tc: [],
    tv: ['Funafuti', 'Nanumanga', 'Nanumea', 'Niutao', 'Nui', 'Nukufeta', 'Nukulaelae', 'Vaitup'],
    ug: ['Central Region', 'Eastern Region', 'Northern Region', 'Western Region'],
    ua: [
      "Cherkas'ka Oblast'",
      "Chernihivs'ka Oblast'",
      "Chernivets'ka Oblast'",
      "Dnipropetrovska Oblast'",
      "Donets'ka Oblast'",
      'Gorod Sevastopol',
      "Ivano-Frankivs'ka Oblast'",
      "Kharkivs'ka Oblast'",
      "Khersons'ka Oblast'",
      "Khmel'nyts'ka Oblast'",
      "Kirovohrads'ka Oblast'",
      'Kyiv Oblast',
      "L'vivs'ka Oblast'",
      "Luhans'ka Oblast'",
      'Misto Kyyiv',
      "Mykolayivs'ka Oblast'",
      "Odes'ka Oblast'",
      "Poltavs'ka Oblast'",
      'Republic of Crimea',
      "Rivnens'ka Oblast'",
      "Sums'ka Oblast'",
      "Ternopil's'ka Oblast'",
      "Vinnyts'ka Oblast'",
      "Volyns'ka Oblast'",
      'Zakarpattia Oblast',
      "Zaporiz'ka Oblast'",
      "Zhytomyrs'ka Oblast'",
    ],
    ae: ['`Ajman', 'Abu Zaby', 'Al Fujayrah', 'Ash Shariqah', 'Dubai', "Ra's al Khaymah", 'Umm al Qaywayn'],
    us: [
      'Alabama',
      'Alaska',
      'Arizona',
      'Arkansas',
      'California',
      'Colorado',
      'Connecticut',
      'Delaware',
      'District of Columbia',
      'Florida',
      'Georgia',
      'Hawaii',
      'Idaho',
      'Illinois',
      'Indiana',
      'Iowa',
      'Kansas',
      'Kentucky',
      'Louisiana',
      'Maine',
      'Maryland',
      'Massachusetts',
      'Michigan',
      'Minnesota',
      'Mississippi',
      'Missouri',
      'Montana',
      'Nebraska',
      'Nevada',
      'New Hampshire',
      'New Jersey',
      'New Mexico',
      'New York',
      'North Carolina',
      'North Dakota',
      'Ohio',
      'Oklahoma',
      'Oregon',
      'Pennsylvania',
      'Rhode Island',
      'South Carolina',
      'South Dakota',
      'Tennessee',
      'Texas',
      'Utah',
      'Vermont',
      'Virginia',
      'Washington',
      'West Virginia',
      'Wisconsin',
      'Wyoming',
    ],
    uy: [
      'Departamento de Artigas',
      'Departamento de Canelones',
      'Departamento de Cerro Largo',
      'Departamento de Colonia',
      'Departamento de Durazno',
      'Departamento de Flores',
      'Departamento de Florida',
      'Departamento de Lavalleja',
      'Departamento de Maldonado',
      'Departamento de Montevideo',
      'Departamento de Paysand',
      'Departamento de Rio Negro',
      'Departamento de Rivera',
      'Departamento de Rocha',
      'Departamento de Salto',
      'Departamento de San Jose',
      'Departamento de Soriano',
      'Departamento de Tacuarembo',
      'Departamento de Treinta y Tres',
    ],
    uz: [
      'Andijan',
      'Bukhara Province',
      'Fergana',
      'Jizzakh Province',
      'Karakalpakstan',
      'Namangan Province',
      'Navoiy Province',
      'Qashqadaryo Province',
      'Samarqand Viloyati',
      'Sirdaryo',
      'Surxondaryo Viloyati',
      'Toshkent Shahri',
      'Toshkent Viloyati',
      'Xorazm Viloyati',
    ],
    vu: ['Malampa Province', 'Penama Province', 'Sanma Province', 'Shefa Province', 'Tafea Province', 'Torba Province'],
    ve: [
      'Delta Amacuro',
      'Dependencias Federales',
      'Distrito Capital',
      'Estado Amazonas',
      'Estado Anzoategui',
      'Estado Apure',
      'Estado Aragua',
      'Estado Barinas',
      'Estado Bolivar',
      'Estado Carabobo',
      'Estado Cojedes',
      'Estado Falcon',
      'Estado Guarico',
      'Estado Lara',
      'Estado Merida',
      'Estado Monagas',
      'Estado Nueva Esparta',
      'Estado Portuguesa',
      'Estado Sucre',
      'Estado Tachira',
      'Estado Trujillo',
      'Estado Vargas',
      'Estado Yaracuy',
      'Estado Zulia',
      'Miranda',
    ],
    vn: [
      'An Giang',
      'Bà Rịa-Vũng Tàu',
      'Bình Dương',
      'Bình Phước',
      'Bình Thuận',
      'Bình Định',
      'Bạc Liêu',
      'Bắc Giang',
      'Bắc Kạn',
      'Bắc Ninh',
      'Bến Tre',
      'Cao Bằng',
      'Cà Mau',
      'Da Nang',
      'Gia Lai',
      'Haiphong',
      'Hanoi',
      'Ho Chi Minh City',
      'Hà Giang',
      'Hà Nam',
      'Hà Tây',
      'Hà Tĩnh',
      'Hòa Bình',
      'Hưng Yên',
      'Hải Dương',
      'Hậu Giang',
      'Khánh Hòa',
      'Kiên Giang',
      'Kon Tum',
      'Lai Châu',
      'Long An',
      'Lào Cai',
      'Lâm Đồng',
      'Lạng Sơn',
      'Nam Định',
      'Nghệ An',
      'Ninh Bình',
      'Ninh Thuận',
      'Phú Thọ',
      'Phú Yên',
      'Quảng Bình',
      'Quảng Nam',
      'Quảng Ngãi',
      'Quảng Ninh',
      'Quảng Trị',
      'Sóc Trăng',
      'Sơn La',
      'Thanh Hóa',
      'Thái Bình',
      'Thái Nguyên',
      'Thừa Thiên-Huế',
      'Tiền Giang',
      'Trà Vinh',
      'Tuyên Quang',
      'Tây Ninh',
      'Vĩnh Long',
      'Vĩnh Phúc',
      'Yên Bái',
      'Điện Biên',
      'Đắk Lắk',
      'Đắk Nông',
      'Đồng Nai',
      'Đồng Tháp'
    ],
    vg: [],
    vi: ['Saint Croix Island', 'Saint John Island', 'Saint Thomas Island'],
    wf: ['Alo', 'Sigave', 'Uvea'],
    eh: [],
    ye: [
      'Al Bayda Governorate',
      'Al Mahrah',
      'Al Mahwit',
      'Amanat Al Asimah',
      'Ibb',
      'Muhafazat `Amran',
      "Muhafazat 'Adan",
      'Muhafazat Abyan',
      "Muhafazat ad Dali'",
      'Muhafazat al Hudaydah',
      'Muhafazat al Jawf',
      'Muhafazat Dhamar',
      'Muhafazat Hadramawt',
      'Muhafazat Hajjah',
      'Muhafazat Lahij',
      "Muhafazat Ma'rib",
      'Muhafazat Raymah',
      "Muhafazat Sa'dah",
      'Muhafazat Ta`izz',
      'Sanaa',
      'Shabwah',
      'Socotra',
    ],
    zm: [
      'Central Province',
      'Copperbelt Province',
      'Eastern Province',
      'Luapula Province',
      'Lusaka Province',
      'Muchinga Province',
      'North-Western Province',
      'Northern Province',
      'Southern Province',
      'Western Province',
    ],
    zw: [
      'Bulawayo Province',
      'Harare Province',
      'Manicaland Province',
      'Mashonaland Central Province',
      'Mashonaland East Province',
      'Mashonaland West Province',
      'Masvingo Province',
      'Matabeleland North Province',
      'Matabeleland South Province',
      'Midlands Province',
    ],
  };
  
  export default states;