import React from "react";
import { avatar03, companyLogo, verified, avatar04, avatar05, avatar02, avatar06, videoImg11, videoImg12, videoImg15 } from "../../utils/imagepath";
import { Link } from "react-router-dom";

const Search = () => {
  return (
    <>
      {" "}
      <div className="feed-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="feeds-div">
                <h6>Find Providers Near You</h6>
                <div className="row">
                  <div className="col-lg-4 col-md-12">
                    <div className="filter-inputs">
                      <select className="select">
                        <option value="O1">Select Catergies</option>
                        <option value="O2">Catergies</option>
                      </select>
                    </div>
                    {/* <div class="filter-inputs">
            <select class="js-select2" multiple="multiple">
              <option value="O1" data-badge="">Option1</option>
              <option value="O2" data-badge="">Option2</option>
              <option value="O3" data-badge="">Option3</option>
              <option value="O4" data-badge="">Option4</option>
              <option value="O5" data-badge="">Option5</option>
              <option value="O6" data-badge="">Option6</option>
              <option value="O7" data-badge="">Option7</option>
              <option value="O8" data-badge="">Option8</option>
              <option value="O9" data-badge="">Option9</option>
              <option value="O10" data-badge="">Option10</option>
              <option value="O11" data-badge="">Option11</option>
              <option value="O12" data-badge="">Option12</option>
              <option value="O13" data-badge="">Option13</option>
            </select>
          </div> */}
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="filter-inputs">
                      <input
                        type="text"
                        placeholder="City, State, or Zipcode"
                      />
                    </div>
                  </div>
                  <div className="col-lg-2 col-md-12">
                    <div className="filter-inputs">
                      <Link
                        to="#"
                        className="btn btn-primary rounded w-100"
                      >
                        <i className="ti ti-search me-2" />
                        Search
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="search-header">
              <h3>15 results for Top Featured Providers</h3>
              <div className="search-filter">
                <div className="all-filter">
                  <Link
                    to="#"
                    data-bs-toggle="modal"
                    data-bs-target="#filter-modal"
                  >
                    <i className="ti ti-adjustments-horizontal me-2" />
                    All Filters
                  </Link>
                </div>
                <div className="status-online-dropdown">
                  <div className="dropdown">
                    <Link
                      to="#"
                      className="dropdown-toggle"
                      data-bs-toggle="dropdown"
                    >
                      <span className="status status-success" />
                      Online
                      <i className="ti ti-chevron-down ms-auto" />
                    </Link>
                    <ul className="dropdown-menu mt-2 p-3">
                      <li>
                        <Link
                          to="#"
                          className="dropdown-item rounded-1"
                        >
                          Offline
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-12">
            <div className="filters-div">
              <div className="filter-path pt-0">
                <div className="search-filter">
                  <div className="all-filter">
                    <Link
                      to="#"
                      data-bs-toggle="modal"
                      data-bs-target="#filter-modal"
                    >
                      <i className="ti ti-adjustments-horizontal me-2" />
                      All Filters
                    </Link>
                  </div>
                  <div className="status-online-dropdown">
                    <div className="dropdown">
                      <Link
                        to="#"
                        className="dropdown-toggle"
                        data-bs-toggle="dropdown"
                      >
                        Sort by latest
                        <i className="ti ti-chevron-down ms-auto" />
                      </Link>
                      <ul className="dropdown-menu mt-2 p-3">
                        <li>
                          <Link
                            to="#"
                            className="dropdown-item rounded-1"
                          >
                            Offline
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-rating"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Ratings
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content  collapse show"
                  id="filter-rating"
                >
                  <div className="rating-filter custom_radio">
                    <ul>
                      <li>
                        <input
                          type="radio"
                          id="featured-2"
                          name="featured"
                          defaultChecked
                        />
                        <label htmlFor="featured-2">
                          <ul className="rating">
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <span className="mb-0">5 Star (80)</span>
                            </li>
                          </ul>
                        </label>
                      </li>
                      <li>
                        <input type="radio" id="featured-1" name="featured" />
                        <label htmlFor="featured-1">
                          <ul className="rating">
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <span className="mb-0">4 Star (180)</span>
                            </li>
                          </ul>
                        </label>
                      </li>
                      <li>
                        <input type="radio" id="featured-3" name="featured" />
                        <label htmlFor="featured-3">
                          <ul className="rating">
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <span className="mb-0">3 Star (480)</span>
                            </li>
                          </ul>
                        </label>
                      </li>
                      <li>
                        <input type="radio" id="featured-4" name="featured" />
                        <label htmlFor="featured-4">
                          <ul className="rating">
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <span className="mb-0">2 Star (80)</span>
                            </li>
                          </ul>
                        </label>
                      </li>
                      <li>
                        <input type="radio" id="featured-5" name="featured" />
                        <label htmlFor="featured-5">
                          <ul className="rating">
                            <li>
                              <i className="ti ti-star-filled" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <i className="ti ti-star" />
                            </li>
                            <li>
                              <span className="mb-0">1 Star (120)</span>
                            </li>
                          </ul>
                        </label>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapseOne"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Filter By
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapseOne"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Men Providers Only</Link>
                    </li>
                    <li>
                      <Link to="#">Women Providers Only</Link>
                    </li>
                    <li>
                      <Link to="#">
                        Non-Binary Providers Only
                      </Link>
                    </li>
                    <li>
                      <Link to="#">
                        Transgender Providers Only
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapsetwo"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Conditions
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapsetwo"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Addiction</Link>
                    </li>
                    <li>
                      <Link to="#">Alcohol Abuse</Link>
                    </li>
                    <li>
                      <Link to="#">Trauma</Link>
                    </li>
                    <li>
                      <Link to="#">Anger</Link>
                    </li>
                    <li>
                      <Link to="#">Anxiety</Link>
                    </li>
                    <li>
                      <Link to="#">Autism</Link>
                    </li>
                    <li>
                      <Link to="#">Bipolar Disorder</Link>
                    </li>
                    <li>
                      <Link to="#">Dementia</Link>
                    </li>
                    <li>
                      <Link to="#">Grief</Link>
                    </li>
                    <li>
                      <Link to="#">Depression</Link>
                    </li>
                    <li>
                      <Link to="#">Dual Diagnosis</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapsethree"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    consultation type
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapsethree"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Online</Link>
                    </li>
                    <li>
                      <Link to="#">In-Person</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapsefour"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    consultation type
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapsefour"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Aetna</Link>
                    </li>
                    <li>
                      <Link to="#">American Behavioral</Link>
                    </li>
                    <li>
                      <Link to="#">Beech Street</Link>
                    </li>
                    <li>
                      <Link to="#">Beacon Health Options </Link>
                    </li>
                    <li>
                      <Link to="#">Ambetter</Link>
                    </li>
                    <li>
                      <Link to="#">PHCS Network</Link>
                    </li>
                    <li>
                      <Link to="#">Sunshine Health</Link>
                    </li>
                    <li>
                      <Link to="#">Oscar Health</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapsefive"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Age
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapsefive"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Infant</Link>
                    </li>
                    <li>
                      <Link to="#">Toddler</Link>
                    </li>
                    <li>
                      <Link to="#">Child (5-8)</Link>
                    </li>
                    <li>
                      <Link to="#">Preteen (9-13)</Link>
                    </li>
                    <li>
                      <Link to="#">Teen (13-18)</Link>
                    </li>
                    <li>
                      <Link to="#">Young Adult (18-25)</Link>
                    </li>
                    <li>
                      <Link to="#">Adult (25-65)</Link>
                    </li>
                    <li>
                      <Link to="#">Elder (65+) </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapsesix"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Faith
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapsesix"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Christian</Link>
                    </li>
                    <li>
                      <Link to="#">Buddhist</Link>
                    </li>
                    <li>
                      <Link to="#">Hindu</Link>
                    </li>
                    <li>
                      <Link to="#">Jewish</Link>
                    </li>
                    <li>
                      <Link to="#">Muslim</Link>
                    </li>
                    <li>
                      <Link to="#">Non-religious</Link>
                    </li>
                    <li>
                      <Link to="#">Sikh</Link>
                    </li>
                    <li>
                      <Link to="#">Mormon</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-path">
                <div className="filter-heads">
                  <h3
                    data-bs-toggle="collapse"
                    data-bs-target="#filter-collapseseven"
                    aria-expanded="false"
                    aria-controls="faq-collapseOne"
                  >
                    Indended Audience
                    <i className="ti ti-chevron-down" />
                  </h3>
                </div>
                <div
                  className="filters-content filters-scrolls"
                  id="filter-collapseseven"
                >
                  <ul className="filter-sel-path">
                    <li>
                      <Link to="#">Christian</Link>
                    </li>
                    <li>
                      <Link to="#">Buddhist</Link>
                    </li>
                    <li>
                      <Link to="#">Hindu</Link>
                    </li>
                    <li>
                      <Link to="#">Jewish</Link>
                    </li>
                    <li>
                      <Link to="#">Muslim</Link>
                    </li>
                    <li>
                      <Link to="#">Non-religious</Link>
                    </li>
                    <li>
                      <Link to="#">Sikh</Link>
                    </li>
                    <li>
                      <Link to="#">Mormon</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-9">
            <div className="row">
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar03} />
                      <span className="online-status" />
                    </div>
                    <div className="doctor-profile-img-content">
                      <span>Affiliated with</span>
                      <img src={companyLogo} alt="" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="profile-no mt-3">
                            <span>
                              <i className="ti ti-phone me-2" />
                              (555) 123-4567
                            </span>
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg12}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg15}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar04} />
                      <span className="online-status" />
                    </div>
                    <div className="doctor-profile-img-content">
                      <span>Affiliated with</span>
                      <img src={companyLogo} alt="" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                            <div className="follow-btns">
                              <div className="follow-btnsview">
                                <Link
                                  to="#"
                                  className="btn btn-primary-line mb-3 follows"
                                >
                                  Follow
                                </Link>
                              </div>
                              <div className="follow-contentsnew none">
                                <Link
                                  to="#"
                                  className="btn btn-primary-line rounded unfollows"
                                >
                                  <i className="ti ti-user-check" />
                                </Link>
                                <Link
                                  to="#"
                                  className="btn btn-primary rounded m-2"
                                >
                                  Subscribe
                                </Link>
                              </div>
                            </div>
                          </div>
                          <div className="profile-no mt-3">
                            <span>
                              <i className="ti ti-phone me-2" />
                              (555) 123-4567
                            </span>
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg12}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar05} />
                      <span className="online-status" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="profile-no mt-3">
                            <span>
                              <i className="ti ti-phone me-2" />
                              (555) 123-4567
                            </span>
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar04} />
                      <span className="online-status" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="profile-no mt-3">
                            <span>
                              <i className="ti ti-phone me-2" />
                              (555) 123-4567
                            </span>
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities m-0 p-0 border-0">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="banner-empty-div mb-4">
                  <h6>Banner</h6>
                </div>
              </div>
              <div className="col-12">
                <div className="search-head">
                  <h6>All Mental Health Providers</h6>
                  <p>Total 115 Results</p>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar02} />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="doctor-profile-img-content text-start">
                            <span>Affiliated with</span>
                            <img src={companyLogo} alt="" />
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment,{" "}
                          </p>
                          <Link
                            to="#"
                            className="readmore-link"
                          >
                            Read More
                          </Link>
                          <p />
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities p-0 m-0 border-0">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar05} />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="doctor-profile-img-content text-start">
                            <span>Affiliated with</span>
                            <img src={companyLogo} alt="" />
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment,{" "}
                          </p>
                          <Link
                            to="#"
                            className="readmore-link"
                          >
                            Read More
                          </Link>
                          <p />
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities p-0 m-0 border-0">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar03} />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="doctor-profile-img-content text-start">
                            <span>Affiliated with</span>
                            <img src={companyLogo} alt="" />
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment,{" "}
                          </p>
                          <Link
                            to="#"
                            className="readmore-link"
                          >
                            Read More
                          </Link>
                          <p />
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities p-0 m-0 border-0">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar06} />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="doctor-profile-img-content">
                            <span>Affiliated with</span>
                            <img src={companyLogo} alt="" />
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg12}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg15}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar04} />
                      <span className="online-status" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                            <Link
                              to="#"
                              className="btn btn-primary-line mb-3"
                            >
                              Follow
                            </Link>
                          </div>
                          <div className="doctor-profile-img-content">
                            <span>Affiliated with</span>
                            <img src={companyLogo} alt="" />
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg12}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12">
                <div className="doctor-profile-set">
                  <div>
                    <div className="doctor-profile-img">
                      <img src={avatar05} />
                      <span className="online-status" />
                    </div>
                  </div>
                  <div className="doctor-profile doctor-featured">
                    <div className="doctor-profile-imgname flex-column">
                      <div className="doctor-profile-name m-0">
                        <div className="d-flex justify-content-between flex-wrap">
                          <div>
                            <span className="badge badge-featured">
                              Featured
                            </span>
                            <h4>
                              <Link to="#">
                                Dr. Jake Reynolds{" "}
                                <img
                                  src={verified}
                                  className="ms-1"
                                  alt=""
                                />
                              </Link>
                            </h4>
                            <h3>Mental Health Therapist</h3>
                            <h5>San Francisco, California</h5>
                            <ul className="rating">
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star-filled" />
                              </li>
                              <li>
                                <i className="ti ti-star" />
                              </li>
                              <li>
                                <span className="mb-0">(58 review)</span>
                              </li>
                            </ul>
                          </div>
                          <div className="profile-no mt-3">
                            <span>
                              <i className="ti ti-phone me-2" />
                              (555) 123-4567
                            </span>
                          </div>
                        </div>
                        <div className="doctor-profile-content">
                          <p>
                            Dr. John Chemma is a clinical psychologist with over
                            10 years of experience in cognitive behavioral
                            therapy. He specializes in treating anxiety,
                            depression, and trauma-related disorders, providing
                            compassionate and evidence-based care to his
                            patients. Dr. John Chemma takes a personalized
                            approach to therapy, tailoring his methods to suit
                            each individual's unique challenges and goals. He is
                            also known for his skill in creating a safe and
                            supportive therapeutic environment, where patients
                            feel comfortable exploring their....
                            <Link
                              to="#"
                              className="readmore-link"
                            >
                              Read More
                            </Link>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="doctor-profile-specialities">
                      <h6>Specialities</h6>
                      <ul>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Addiction Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Relationship Counseling
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Family Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Behavioral Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Child Therapy
                          </h6>
                        </li>
                        <li>
                          <h6>
                            <i className="ti ti-check me-2" />
                            Trauma Therapy
                          </h6>
                        </li>
                      </ul>
                    </div>
                    <div className="doctor-profile-video">
                      <ul>
                        <li>
                          <div className="video-doctor video-doctor-content">
                            <img
                              src={videoImg11}
                              alt="img"
                            />
                            <h6>
                              <Link to="#">
                                <i className="ti ti-player-play" />
                              </Link>
                            </h6>
                            <h4>
                              Managing Anxiety - Abried Video Explaning Anxiety
                            </h4>
                          </div>
                        </li>
                        <li>
                          <Link to="#" className="see-moreset">
                            See More Vidoes <i className="ti ti-arrow-right" />
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="pagination-divs">
              <ul>
                <li>
                  <Link to="#">
                    <i className="ti ti-chevrons-left" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ti ti-chevron-left" />
                  </Link>
                </li>
                <li>
                  <Link to="#" className="active">
                    1
                  </Link>
                </li>
                <li>
                  <Link to="#">2</Link>
                </li>
                <li>
                  <Link to="#">3</Link>
                </li>
                <li>
                  <Link to="#">...</Link>
                </li>
                <li>
                  <Link to="#">10</Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ti ti-chevron-right" />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <i className="ti ti-chevrons-right" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <section className="join-section">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div
                className="join-div wow zoomIn"
                style={{ visibility: "visible", animationName: "zoomIn" }}
              >
                <h6>Join TurningWell</h6>
                <p>
                  Sign up now to get unrestricted access to TurningWell's online
                  mental health directory, resources, and more!
                </p>
                <Link
                  to="#"
                  className="btn btn-signup rounded"
                >
                  Sign up now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade filter-modal"
        id="filter-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Select the Filter</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-rating"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      I am looking For
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-rating">
                      <ul className="filter-select">
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Psychiatry
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Indivital Thearpy
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Group Thearpy
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Medication Management
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Inpatient Mental Health Treatment
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-filters"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Filter By
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-filters">
                      <ul className="filter-select">
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Men Providers Only
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Women Providers Only
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Non-Binary Providers Only
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Transgender Providers Only
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Virtual Appointments Only
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            In-Person Appointments Only
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-filters1"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Consulation Type
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-filters1">
                      <ul className="filter-select">
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Online
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            In-Person
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-dates"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Mental Health Issues
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-dates">
                      <div className="row">
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input
                              type="checkbox"
                              id="Addiction"
                              defaultChecked
                            />
                            <label htmlFor="Addiction">Addiction</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Abuse" />
                            <label htmlFor="Abuse">Alcohol Abuse</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Anger" />
                            <label htmlFor="Anger">Anger</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Anxiety" />
                            <label htmlFor="Anxiety">Anxiety</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Autism" />
                            <label htmlFor="Autism">Autism</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Bipolar" />
                            <label htmlFor="Bipolar">Bipolar Disorder</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Dementia" />
                            <label htmlFor="Dementia">Dementia</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Depression" />
                            <label htmlFor="Depression">Depression</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="DrugAbuse" />
                            <label htmlFor="DrugAbuse">Drug Abuse</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="DrugDiagnosis" />
                            <label htmlFor="DrugDiagnosis">DrugDiagnosis</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="EatingDisorder" />
                            <label htmlFor="EatingDisorder">
                              Eating Disorder
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="GamblingAddiction" />
                            <label htmlFor="GamblingAddiction">
                              Gambling Addiction
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="GenderIssues" />
                            <label htmlFor="GenderIssues">Gender Issues</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Grief" />
                            <label htmlFor="Grief">Grief</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="LearningDisorder" />
                            <label htmlFor="LearningDisorder">
                              Learning Disabilities
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Hoarding" />
                            <label htmlFor="Hoarding">Hoarding</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Postpartum" />
                            <label htmlFor="Postpartum">
                              Postpartum &amp; Pregency
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Parting" />
                            <label htmlFor="Parting">Parenting</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="perDisorder" />
                            <label htmlFor="perDisorder">
                              Personality Disabilities
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="infertility" />
                            <label htmlFor="infertility">Infertility</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sexaddition" />
                            <label htmlFor="sexaddition">Sex Addicition</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sleep" />
                            <label htmlFor="sleep">Sleep Disorder</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sex" />
                            <label htmlFor="sex">Sexual Identity </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Stress" />
                            <label htmlFor="Stress">Stress</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="suic" />
                            <label htmlFor="suic">
                              Suicidal Thoughts/Ideation{" "}
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="obes" />
                            <label htmlFor="obes">Obsessive Complusive </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-insurance"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Mental Health Issues
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-insurance">
                      <div className="row">
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input
                              type="checkbox"
                              id="Addiction1"
                              defaultChecked
                            />
                            <label htmlFor="Addiction1">Aetna</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Abuse1" />
                            <label htmlFor="Abuse1">Amettar</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Anger1" />
                            <label htmlFor="Anger1">American Behaiour</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Anxiety1" />
                            <label htmlFor="Anxiety1">Amerigroup</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Autism1" />
                            <label htmlFor="Autism1">Anthem | Elevance</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Bipolar1" />
                            <label htmlFor="Bipolar1">Avmed</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Dementia1" />
                            <label htmlFor="Dementia1">Baycare</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Depression1" />
                            <label htmlFor="Depression1">
                              Beacon Health Option
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="DrugAbuse1" />
                            <label htmlFor="DrugAbuse1">Beech Street</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="DrugDiagnosis1" />
                            <label htmlFor="DrugDiagnosis1">
                              Beacon Health Systems
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="EatingDisorder1" />
                            <label htmlFor="EatingDisorder1">
                              Blue Cross &amp; Blue Shields
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="GamblingAddiction1" />
                            <label htmlFor="GamblingAddiction1">ComPsych</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="GenderIssues1" />
                            <label htmlFor="GenderIssues1">Coventry</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Grief1" />
                            <label htmlFor="Grief1">E4Health</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="LearningDisorder1" />
                            <label htmlFor="LearningDisorder1">
                              Empire Blue Cross Blue Shield
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Hoarding1" />
                            <label htmlFor="Hoarding1">Florida Blue</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Postpartum1" />
                            <label htmlFor="Postpartum1">GHI</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Parting1" />
                            <label htmlFor="Parting1">Health Advocate</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="perDisorder1" />
                            <label htmlFor="perDisorder1">Humana</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="infertility1" />
                            <label htmlFor="infertility1">Life Works</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sexaddition1" />
                            <label htmlFor="sexaddition1">Megallan</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sleep1" />
                            <label htmlFor="sleep1">Mediaid</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="sex1" />
                            <label htmlFor="sex1">Medicare</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="Stress1" />
                            <label htmlFor="Stress1">
                              MHNet Behaviour Health
                            </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="suic1" />
                            <label htmlFor="suic1">Sunshine Health </label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="obes" />
                            <label htmlFor="obes">TRICARE</label>
                          </div>
                        </div>
                        <div className="col-lg-3 col-12">
                          <div className="filter-checks">
                            <input type="checkbox" id="UMR" />
                            <label htmlFor="UMR">UMR</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-age"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Consulation Type
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-age">
                      <ul className="filter-select">
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Infant
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Toddler
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Child(5-8)
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Preteen(9-13)
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Teen(13-18)
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Young Adult(18-25)
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" /> Adult(25-65)
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" /> Elder(65+)
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-div">
                    <h6
                      className="w-100 d-flex align-items-center justify-content-between"
                      data-bs-toggle="collapse"
                      data-bs-target="#modal-faith"
                      aria-expanded="false"
                      aria-controls="faq-collapseOne"
                    >
                      Consulation Type
                      <i className="ti ti-chevron-down ms-auto" />
                    </h6>
                    <div className="collapse show" id="modal-faith">
                      <ul className="filter-select">
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Christian
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Buddhist
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Hindu
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Jewish
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Muslim
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" />
                            Non Religious
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" /> Sikh
                          </Link>
                        </li>
                        <li>
                          <Link to="#">
                            <i className="ti ti-check me-2" /> Mormon
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="filter-btn">
                    <ul>
                      <li>
                        <Link to="#" className="btn btn-primary rounded">
                          Apply
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="clear-link">
                          Clear Filter
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Search;
