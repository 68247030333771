import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  clinicalExperienceSchema,
  clinicalExpertiseSchema,
  clinicalSpecialitySchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { Dialog } from "primereact/dialog";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
  };
  handleClose: () => void;
  designationsList: Array<object>;
  getDirectoryData: () => void;
  clinicalExpertiseData: any;
  directoryData: any;
}

const ClinicalModal = (props: props) => {
  const clinicalRef = useRef();
  const { clinicalExpertiseData, getDirectoryData, directoryData } = props;
  const { type, open, data, userDetails, edit_type } = props.visible;

  const initialValues = {
    clinical_type: edit_type,
    clinical_specialties:
      directoryData?.clinical_specialties?.clinical_specialties !== undefined
        ? directoryData?.clinical_specialties?.clinical_specialties
        : [],
    clinical_expertise:
      directoryData?.clinical_specialties?.clinical_expertise !== undefined
        ? directoryData?.clinical_specialties?.clinical_expertise
        : [],
    clinical_experience:
      directoryData?.clinical_specialties?.clinical_experience !== undefined
        ? directoryData?.clinical_specialties?.clinical_experience
        : [],
    other_clinical_specialties: {
      clinical_specialties:
        directoryData?.other_clinical_specialties?.clinical_specialties !==
        undefined
          ? directoryData?.other_clinical_specialties?.clinical_specialties
          : [],
      clinical_expertise:
        directoryData?.other_clinical_specialties?.clinical_expertise !==
        undefined
          ? directoryData?.other_clinical_specialties?.clinical_expertise
          : [],
      clinical_experience:
        directoryData?.other_clinical_specialties?.clinical_experience !==
        undefined
          ? directoryData?.other_clinical_specialties?.clinical_experience
          : [],
    },
  };

  const basicModelValidation = Yup.object().shape({
    clinical_specialties: clinicalSpecialitySchema,
    clinical_expertise: clinicalExpertiseSchema,
    clinical_experience: clinicalExperienceSchema,
  });

  const {
    watch,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    trigger,
    getValues,
    register,
  } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(basicModelValidation),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    clinicalRef.current.click();
    props.handleClose();
  };

  const handleSelection = (item: any, type: any) => {
    console.log(
      "ELSEPART",
      getValues("other_clinical_specialties")?.clinical_experience?.includes(
        item
      ),
      item,
      type
    );
    let updatedSelection;

    if (edit_type === "clinical") {
      if (type === "specialities") {
        updatedSelection = watch("clinical_specialties").includes(item)
          ? watch("clinical_specialties").filter((s) => s !== item)
          : [...watch("clinical_specialties"), item];
        setValue("clinical_specialties", updatedSelection);
      } else if (type === "expertise") {
        updatedSelection = watch("clinical_expertise").includes(item)
          ? watch("clinical_expertise").filter((e) => e !== item)
          : [...watch("clinical_expertise"), item];
        setValue("clinical_expertise", updatedSelection);
      } else if (type === "experience") {
        updatedSelection = watch("clinical_experience").includes(item)
          ? watch("clinical_experience").filter((exp) => exp !== item)
          : [...watch("clinical_experience"), item];
        setValue("clinical_experience", updatedSelection);
      }
    } else {
      if (type === "specialities") {
        updatedSelection = getValues(
          "other_clinical_specialties"
        )?.clinical_specialties?.includes(item)
          ? getValues(
              "other_clinical_specialties"
            )?.clinical_specialties.filter((s) => s !== item)
          : [
              ...getValues("other_clinical_specialties")?.clinical_specialties,
              item,
            ];
        setValue(
          "other_clinical_specialties.clinical_specialties",
          updatedSelection
        );
      } else if (type === "expertise") {
        updatedSelection = getValues(
          "other_clinical_specialties"
        )?.clinical_expertise?.includes(item)
          ? getValues("other_clinical_specialties")?.clinical_expertise.filter(
              (s) => s !== item
            )
          : [
              ...getValues("other_clinical_specialties")?.clinical_expertise,
              item,
            ];
        setValue(
          "other_clinical_specialties.clinical_expertise",
          updatedSelection
        );
      } else if (type === "experience") {
        updatedSelection = getValues(
          "other_clinical_specialties"
        )?.clinical_experience?.includes(item)
          ? getValues("other_clinical_specialties")?.clinical_experience.filter(
              (s) => s !== item
            )
          : [
              ...getValues("other_clinical_specialties")?.clinical_experience,
              item,
            ];
        setValue(
          "other_clinical_specialties.clinical_experience",
          updatedSelection
        );
      }
    }
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        ...data,
      };
      const response = await putData(
        `${end_points.profile_about_clinical.url} `,
        payload
      );

      if (response?.status < 400) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
    } finally {
      reset();
      hideLoader();
    }
  };

  useEffect(() => {
    console.log(
      "directoryData",
      directoryData?.clinical_specialties !== undefined &&
        Object.values(directoryData?.clinical_specialties).flat().length
    );
    let temp = {
      clinical_type: edit_type,
      clinical_specialties:
        directoryData?.clinical_specialties?.clinical_specialties !== undefined
          ? directoryData?.clinical_specialties?.clinical_specialties
          : [],
      clinical_expertise:
        directoryData?.clinical_specialties?.clinical_expertise !== undefined
          ? directoryData?.clinical_specialties?.clinical_expertise
          : [],
      clinical_experience:
        directoryData?.clinical_specialties?.clinical_experience !== undefined
          ? directoryData?.clinical_specialties?.clinical_experience
          : [],
      other_clinical_specialties: {
        clinical_specialties:
          directoryData?.other_clinical_specialties?.clinical_specialties !==
          undefined
            ? directoryData?.other_clinical_specialties?.clinical_specialties
            : [],
        clinical_expertise:
          directoryData?.other_clinical_specialties?.clinical_expertise !==
          undefined
            ? directoryData?.other_clinical_specialties?.clinical_expertise
            : [],
        clinical_experience:
          directoryData?.other_clinical_specialties?.clinical_experience !==
          undefined
            ? directoryData?.other_clinical_specialties?.clinical_experience
            : [],
      },
    };
    reset(temp);
  }, [directoryData]);

  useEffect(() => {
    console.log("watch", watch());
  }, [watch]);

  return (
    <div
      className="modal fade filter-modal"
      id="clinical-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-xl">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">
              Clinical Specialties, Expertise, &amp; Experience
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={clinicalRef}
            />
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Clinical Specialties
                      <span>(Choose at least one specialty)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_specality.map(
                        (specialty: any) => (
                          <li
                            key={specialty?._id}
                            {...register("clinical_specialities")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "clinical"
                                  ? watch("clinical_specialties").includes(
                                      specialty?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_specialties.includes(
                                        specialty?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(
                                  specialty?.name,
                                  "specialities"
                                );
                                if (edit_type === "clinical") {
                                  trigger("clinical_specialties");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {specialty?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.clinical_specialties} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Clinical Expertise
                      <span>(Select at least one area of expertise)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_expertice.map(
                        (expertise: any) => (
                          <li
                            key={expertise?._id}
                            {...register("clinical_expertise")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "clinical"
                                  ? watch("clinical_expertise").includes(
                                      expertise?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_expertise.includes(
                                        expertise?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(expertise?.name, "expertise");
                                if (edit_type === "clinical") {
                                  trigger("clinical_expertise");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {expertise?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.clinical_expertise} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12">
                  <div className="filter-div-modal">
                    <h6>
                      Clinical Experience
                      <span>(Select at least one experience item)</span>
                    </h6>
                    <ul className="filter-select-modal">
                      {clinicalExpertiseData?.clinical_experience.map(
                        (experience: any) => (
                          <li
                            key={experience?._id}
                            {...register("clinical_experience")}
                          >
                            <Link
                              to="#"
                              className={
                                edit_type === "clinical"
                                  ? watch("clinical_experience").includes(
                                      experience?.name
                                    )
                                    ? "active"
                                    : ""
                                  : getValues(
                                        "other_clinical_specialties"
                                      )?.clinical_experience.includes(
                                        experience?.name
                                      )
                                    ? "active"
                                    : ""
                              }
                              onClick={(e) => {
                                e.preventDefault();
                                handleSelection(experience?.name, "experience");
                                if (edit_type === "clinical") {
                                  trigger("clinical_experience");
                                }
                              }}
                            >
                              <i className="ti ti-check me-2" />
                              {experience?.name}
                            </Link>
                          </li>
                        )
                      )}
                    </ul>
                    <ErrorMessage value={errors.clinical_experience} />
                  </div>
                </div>
              </div>

              <div className="filter-modal-btns">
                <button className="btn btn-primary rounded" type="submit">
                  {directoryData?.clinical_specialties !== undefined &&
                  Object.values(directoryData?.clinical_specialties).flat()
                    .length > 1
                    ? "Update"
                    : "Add"}{" "}
                  Specialties
                </button>
                <button
                  className="btn btn-cancel"
                  type="button"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClinicalModal;
