import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { hideLoader, showLoader } from "../../../components/spinner/spinner";
import { getData, putData } from "../../../services/api.service";
import { end_points } from "../../../services/endpoints";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ProviderParentalControls = () => {
  const userDetails = useSelector((state: any) => state?.common?.userDetails);
  const [selectedFriendsGroup, setSelectedFriendsGroup] = useState("");
  const [ageGroup, setAgeGroup] = useState("");

  const friendsGroupOptions = [
    { label: "Friends", value: "Friends" },
    { label: "Group", value: "Group" },
  ];

  const AgeGroupOptions = [
    {
      label: "13+ (Suitable for ages 13 and older)",
      value: "13+ (Suitable for ages 13 and older)",
    },
    {
      label: "9+ (Suitable for ages 9 and older)",
      value: "9+ (Suitable for ages 9 and older)",
    },
    { label: "18+ (Category)", value: "18+ (Category)" },
    {
      label: "All Ages (Suitable for everyone)",
      value: "all ages (Suitable for everyone)",
    },
  ];

  const GetParentDetails = async () => {
    try {
      const response = await getData(
        `${end_points.settings_details_listing?.url}`
      );

      if (response?.status < 400) {
        setAgeGroup(response.data.data[0]?.parental_settings?.age_group);
        setSelectedFriendsGroup(
          response.data.data[0]?.parental_settings?.parental_settings_access
        );
      }
    } catch (error) {
    } finally {
    }
  };
  const onSubmit = async (age_Group: string, parental_settings: string) => {
    try {
      const payload = {
        parental_settings: {
          age_group: age_Group,
          parental_settings_access: parental_settings,
        },
      };
      const response = await putData(
        `${end_points.settings_parental_control.url}`,
        payload
      );
      if (response?.status < 400) {
        toast.success(response?.data?.message);
        GetParentDetails();
      }
    } catch (error) {
    } finally {
      //  showLoader();
      // hideLoader();
    }
  };

  useEffect(() => {
    GetParentDetails();
  }, [userDetails]);
  return (
    <div
      className="tab-pane fade"
      id="parental-controls"
      role="tabpanel"
      aria-labelledby="parental-controls-tab"
    >
      <div className="custom-card custom-card-info">
        <div className="card-head d-flex align-items-center">
          <h4>Parental Controls</h4>
        </div>
        <div className="parental-blk">
          <h5>Allowed Experiences</h5>
          <p>
            Choose the <span>highest age guidelines</span> of experiences this
            account can join.
          </p>
          <p>
            All experiences are still searchable Experiences without age
            guidelines are currently treated as 13+.
          </p>
          <ul>
            {AgeGroupOptions.map((item) => (
              <li key={item.value}>
                <label className="custom_check">
                  <input
                    type="checkbox"
                    checked={ageGroup === item.value}
                    value={item.value}
                    onChange={(e) => {
                      onSubmit(e.target.value, selectedFriendsGroup);
                    }}
                  />
                  <span className="checkmark" />
                  {item.label}
                </label>
              </li>
            ))}
          </ul>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="group-input group-input-inner mb-0">
              <label className="col-form-label">
                Whose private servers can this account join through links?
              </label>
              <Dropdown
                value={selectedFriendsGroup}
                options={friendsGroupOptions}
                onChange={(e) => {
                  onSubmit(ageGroup, e.value);
                }}
                placeholder="Select"
                className="select w-100"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProviderParentalControls;
