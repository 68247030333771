import React from 'react'

const SearchTag = () => {
  return (
    <><div className="feed-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="feeds-div">
            <h6>Search Tag</h6>
            <p>Welcome to the All Feeds section, your comprehensive hub for discovering and watching videos from various providers.</p>
            <div className="feed-input">
              <input type="text" placeholder="Mental health" />
              <i className="ti ti-search search-icon" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="feeds-video">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="feeds-head">
            <ul>
              <li>
                <a href="#" className="active">Mental Health</a>
              </li>
              <li>
                <a href="#">Relationship</a>
              </li>
              <li>
                <a href="#">Psychotherapy</a>
              </li>
              <li>
                <a href="#">Anxiety</a>
              </li>
              <li>
                <a href="#">Fitness &amp; Exercise</a>
              </li>
              <li>
                <a href="#">Mental Health</a>
              </li>
              <li>
                <a href="#">Psychotherapy</a>
              </li>
            </ul>
            <h5 className="filter-search"><a href="#" className="btn btn-line " data-bs-toggle="modal" data-bs-target="#filter-modal"><i className="ti ti-adjustments-horizontal me-2" />Filter</a></h5>
          </div>
        </div>
      </div>
    </div>
    <div className="announcement-paths">
      <div className="container">
        <div className="row">
          <div className="announments-heads">
            <h2>Announcements</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="depression-slider owl-carousel owl-dotdiv">
              <div className="ann-profiles">
                <div className="ann-profiles-img">
                  <img src="assets/img/profiles/avatar-01.png" alt="" />
                  <span>Dr. Jake Reynolds</span>
                </div>
                <div className="ann-profiles-content">
                  <h5>Event on Mental Health</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <h6>May 16, 2024 at 06:00 PM </h6>
                </div>
              </div>
              <div className="ann-profiles ann-warning ">
                <div className="ann-profiles-img">
                  <img src="assets/img/profiles/avatar-02.png" alt="" />
                  <span>Dr. Jake Reynolds</span>
                </div>
                <div className="ann-profiles-content">
                  <h5>Event on Mental Health</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <h6>May 16, 2024 at 06:00 PM </h6>
                </div>
              </div>
              <div className="ann-profiles ann-pending ">
                <div className="ann-profiles-img">
                  <img src="assets/img/profiles/avatar-03.png" alt="" />
                  <span>Dr. Jake Reynolds</span>
                </div>
                <div className="ann-profiles-content">
                  <h5>Event on Mental Health</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <h6>May 16, 2024 at 06:00 PM </h6>
                </div>
              </div>
              <div className="ann-profiles">
                <div className="ann-profiles-img">
                  <img src="assets/img/profiles/avatar-01.png" alt="" />
                  <span>Dr. Jake Reynolds</span>
                </div>
                <div className="ann-profiles-content">
                  <h5>Event on Mental Health</h5>
                  <p>Lorem ipsum dolor sit amet, consectetur elit Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                  <h6>May 16, 2024 at 06:00 PM </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Top Videos */}
    <div className="announcement-paths">
      <div className="container">
        <div className="row">
          <div className="announments-heads">
            <h2>Live Feeds</h2>
          </div>
        </div>
      </div>
      <div className="slider-service">
        <div className="video-feed owl-carousel owl-dotdiv-slider">
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-04.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-02.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-03.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-04.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-05.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
          <a href="#" className="video-div" data-bs-toggle="modal" data-bs-target="#subscription-modal">
            <div className="video-img">
              <img src="assets/img/video-img/video-img-06.png" alt="img" className="video-thumbnail" />
            </div>
            <div className="video-content">
              <div className="video-exclusive text-end">
                <i className="ti ti-heart text-white fs-24" />
              </div>
              <div className="video-name">
                <div>
                  <h5>Dr. Susan Reynold</h5>
                  <p className="text-white d-flex align-items-center"><i className="ti ti-video me-2 fs-18" />500 Viewers <span className="split-line split-flag" /> 45m</p>
                </div>
                <div className="video-play video-flag">
                  <span className="border-0"><img src="assets/img/flags/us.png" alt="" /></span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
    {/* /Top Videos */}
    <div className="announcement-paths">
      <div className="container">
        <div className="row">
          <div className="announments-heads">
            <h2>Blogs</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-4 col-12">
            <div className="blog-div releated-blog">
              <div className="blog-img ">
                <img src="assets/img/blog/blog-16.png" alt="img" />
              </div>
              <div className="blog-date">
                <div className="banner-content">
                  <ul>
                    <li>
                      <a href="#">Mental</a>
                    </li>
                    <li>
                      <a href="#">April 19, 2024</a>
                    </li>
                    <li>
                      <a href="#">Jake Reynolds</a>
                    </li>
                  </ul>
                </div>
                <h5><a href="#">How Antidepressants Improve Mental Health</a> </h5>
                <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration
                  in that some injected humour.</p>
                <a href="#" className="read-more text-links">Read More <i className="ti ti-arrow-right  ms-2" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="blog-div releated-blog">
              <div className="blog-img">
                <img src="assets/img/blog/blog-17.png" alt="img" />
              </div>
              <div className="blog-date">
                <div className="banner-content">
                  <ul>
                    <li>
                      <a href="#">Mental</a>
                    </li>
                    <li>
                      <a href="#">April 19, 2024</a>
                    </li>
                    <li>
                      <a href="#">Jake Reynolds</a>
                    </li>
                  </ul>
                </div>
                <h5><a href="#">Comparing Different Antidepressant Classes</a> </h5>
                <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration
                  in that some injected humour.</p>
                <a href="#" className="read-more text-links">Read More <i className="ti ti-arrow-right  ms-2" /></a>
              </div>
            </div>
          </div>
          <div className="col-lg-4 col-12">
            <div className="blog-div releated-blog">
              <div className="blog-img ">
                <img src="assets/img/blog/blog-18.png" alt="img" />
              </div>
              <div className="blog-date">
                <div className="banner-content">
                  <ul>
                    <li>
                      <a href="#">Mental</a>
                    </li>
                    <li>
                      <a href="#">April 19, 2024</a>
                    </li>
                    <li>
                      <a href="#">Jake Reynolds</a>
                    </li>
                  </ul>
                </div>
                <h5><a href="#">How to Recognize Signs of Anxiety and Depression</a> </h5>
                <p>There are many variations of passages of Lorem Ipsum available but the majority have suffered alteration
                  in that some injected humour.</p>
                <a href="#" className="read-more text-links">Read More <i className="ti ti-arrow-right  ms-2" /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Top Videos */}
    <div className="announcement-paths">
      <div className="container">
        <div className="row">
          <div className="announments-heads">
            <h2>Videos</h2>
          </div>
        </div>
      </div>
      <div className="slider-service">
        <div className="video-feed owl-carousel owl-dotdiv-slider">
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video11.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>45 min  |  May 11, 2024</p>
                    <h5>
                      Bipolar Insights
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video12.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>16 min  |  May 24, 2024</p>
                    <h5>
                      Living with Bipolar
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video13.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>13 min  |  May 26, 2024</p>
                    <h5>
                      Bipolar Wellness
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video14.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>42 min  |  May 27, 2024</p>
                    <h5>
                      Bipolar Life
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video15.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>16 min  |  May 24, 2024</p>
                    <h5>
                      Bipolar Journey
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div className="suggest-videos-info">
            <a href="javascript:void(0);" className="video-div" data-bs-toggle="modal" data-bs-target="#video-modal">
              <div className="video-img">
                <img src="assets/img/profile-video/profile-video1.png" alt="img" className="video-thumbnail" />
              </div>
              <div className="video-content">
                <div className="video-exclusive">
                </div>
                <div className="video-name">
                  <div className="video-text">
                    <p>45 min  |  May 11, 2024</p>
                    <h5>
                      Mental health Therapy
                    </h5>
                  </div>
                  <div className="video-play">
                    <span><i className="ti ti-player-play" /></span>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
    {/* /Top Videos */}
    <div className="announcement-paths p-0">
      <div className="container">
        <div className="row">
          <div className="announments-heads">
            <h2>Events</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Effective Stress Management Techniques for Improved Well-Being</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (11:30AM to 12:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Essential Nutrition Tips for a Healthy Lifestyle</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (12:30PM to 01:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Comprehensive Live Yoga Class: Beginner to Advanced Levels</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (01:30PM to 02:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Breathwork and Relaxation Techniques for Stress Relief and Inner Peace</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (02:30PM to 03:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Dive into Breathwork and Relaxation Techniques for Stress Relief and Inner Peace</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (03:30PM to 04:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Comprehensive Live Yoga Class: Beginner to Advanced Levels</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (01:30PM to 02:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
            <div className="view-event-box">
              <div className="view-event-content">
                <h4>Essential Nutrition Tips for a Healthy Lifestyle</h4>
                <ul className="nav">
                  <li>
                    <a href="javascript:void(0);">Susan Reynold</a>
                  </li>
                  <li>May 27, 2024 - (12:30PM to 01:30PM)</li>
                </ul>
              </div>
              <div className="view-event-btn">
                <a href="javascript:void(0);" className="btn" data-bs-toggle="modal" data-bs-target="#signup-modal">
                  <img src="assets/img/profile-icons/view-icon13.svg" alt="Icon" /> Add to Calendar
                </a>
              </div>	
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <section className="join-section">
    <div className="container">
      <div className="row">
        <div className="col-12">
          <div className="join-div" style={{visibility: 'visible', animationName: 'zoomIn'}}>
            <h6>Join TurningWell</h6>
            <p>Sign up now to get unrestricted access to TurningWell's online mental health directory,
              resources, and more!</p>
            <a href="#" className="btn btn-signup rounded">Sign up now</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <div className="modal fade" id="video-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-8 col-12">
              <div className="slider-video slider-video-modal owl-carousel">
                <div className="slider-video-div">
                  <div className="live-status">
                    <img src="assets/img/live.png" alt="img" />
                    <div className="views">
                      <span><i className="ti ti-eye me-2" />11.k Views</span>
                    </div>
                  </div>
                  <video className="doctor-treatment-video" controls>
                    <source src="assets/video/video.mp4" type="video/mp4" />
                  </video>
                </div>
                <div className="slider-video-div">
                  <div className="live-status">
                    <img src="assets/img/live.png" alt="img" />
                    <div className="views">
                      <span><i className="ti ti-eye me-2" />11.k Views</span>
                    </div>
                  </div>
                  <video className="doctor-treatment-video" controls>
                    <source src="assets/video/video.mp4" type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-12">
              <div className="video-information">
                <div className="video-information-profile">
                  <div className="video-information-profile-img">
                    <img src="assets/img/profiles/avatar-03.png" alt="img" />			
                    <span className="online-stataus" />
                  </div>
                  <div className="video-information-profile-content">
                    <h4>Jake Reynolds, ND <i className="ti ti-rosette-discount-check ms-2" /></h4>
                    <h5>Naturopathic Medicine</h5>
                    <h6>Portland, Oregon</h6>
                  </div>
                </div>
                <div className="video-information-data">
                  <ul>
                    <li>
                      <span><i className="ti ti-video me-2" />40</span>
                    </li>
                    <li>
                      <span><i className="ti ti-news me-2" />65</span>
                    </li>
                    <li>
                      <span><i className="ti ti-wifi me-2" />101</span>
                    </li>
                    <li>
                      <span><i className="ti ti-heart me-2" />1.2k</span>
                    </li>
                    <li>
                      <span><i className="ti ti-clock-hour-9 me-2" />212min</span>
                    </li>
                  </ul>
                </div>
                <div className="video-information-content">
                  <h6>Understanding Mental Health.</h6>
                  <p>Understanding Mental Health explores mental well-being, common disorders, and effective strategies for maintaining a balanced, healthy mind.</p>
                </div>
                <div className="video-information-favorite">
                  <ul>
                    <li>
                      <a href="#"><i className="ti ti-heart me-2" />Add to favorite</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-share me-2" />Share</a>
                    </li>
                  </ul>
                </div>
                <div className="video-information-cartegory">
                  <h5>Category: <span>Fitness &amp; Exercise</span></h5>
                  <ul>
                    <li>
                      <a href="#">Mental Health</a>
                    </li>
                    <li>
                      <a href="#">Well-being</a>
                    </li>
                    <li>
                      <a href="#">Anxiety</a>
                    </li>
                    <li>
                      <a href="#">Anxiety</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="modal fade filter-modal" id="filter-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title">Select the Filter</h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-lg-12">
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-rating" aria-expanded="false" aria-controls="faq-collapseOne">
                  I am looking For
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-rating">
                  <ul className="filter-select">
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Psychiatry</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Indivital Thearpy</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Group Thearpy</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Medication Management</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Inpatient Mental Health Treatment</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-filters" aria-expanded="false" aria-controls="faq-collapseOne">
                  Filter By
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-filters">
                  <ul className="filter-select">
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Men Providers Only</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Women Providers Only</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Non-Binary Providers Only</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Transgender Providers Only</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Virtual Appointments Only</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />In-Person Appointments Only</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-filters1" aria-expanded="false" aria-controls="faq-collapseOne">
                  Consulation Type
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-filters1">
                  <ul className="filter-select">
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Online</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />In-Person</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-dates" aria-expanded="false" aria-controls="faq-collapseOne">
                  Mental Health Issues
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-dates">
                  <div className="row">
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Addiction" defaultChecked />
                        <label htmlFor="Addiction">Addiction</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Abuse" />
                        <label htmlFor="Abuse">Alcohol Abuse</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Anger" />
                        <label htmlFor="Anger">Anger</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Anxiety" />
                        <label htmlFor="Anxiety">Anxiety</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Autism" />
                        <label htmlFor="Autism">Autism</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Bipolar" />
                        <label htmlFor="Bipolar">Bipolar Disorder</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Dementia" />
                        <label htmlFor="Dementia">Dementia</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Depression" />
                        <label htmlFor="Depression">Depression</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="DrugAbuse" />
                        <label htmlFor="DrugAbuse">Drug Abuse</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="DrugDiagnosis" />
                        <label htmlFor="DrugDiagnosis">DrugDiagnosis</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="EatingDisorder" />
                        <label htmlFor="EatingDisorder">Eating Disorder</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="GamblingAddiction" />
                        <label htmlFor="GamblingAddiction">Gambling Addiction</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="GenderIssues" />
                        <label htmlFor="GenderIssues">Gender Issues</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Grief" />
                        <label htmlFor="Grief">Grief</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="LearningDisorder" />
                        <label htmlFor="LearningDisorder">Learning Disabilities</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Hoarding" />
                        <label htmlFor="Hoarding">Hoarding</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Postpartum" />
                        <label htmlFor="Postpartum">Postpartum &amp; Pregency</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Parting" />
                        <label htmlFor="Parting">Parenting</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="perDisorder" />
                        <label htmlFor="perDisorder">Personality Disabilities</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="infertility" />
                        <label htmlFor="infertility">Infertility</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sexaddition" />
                        <label htmlFor="sexaddition">Sex Addicition</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sleep" />
                        <label htmlFor="sleep">Sleep Disorder</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sex" />
                        <label htmlFor="sex">Sexual Identity </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Stress" />
                        <label htmlFor="Stress">Stress</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="suic" />
                        <label htmlFor="suic">Suicidal Thoughts/Ideation </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="obes" />
                        <label htmlFor="obes">Obsessive Complusive </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-insurance" aria-expanded="false" aria-controls="faq-collapseOne">
                  Mental Health Issues
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-insurance">
                  <div className="row">
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Addiction1" defaultChecked />
                        <label htmlFor="Addiction1">Aetna</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Abuse1" />
                        <label htmlFor="Abuse1">Amettar</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Anger1" />
                        <label htmlFor="Anger1">American Behaiour</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Anxiety1" />
                        <label htmlFor="Anxiety1">Amerigroup</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Autism1" />
                        <label htmlFor="Autism1">Anthem | Elevance</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Bipolar1" />
                        <label htmlFor="Bipolar1">Avmed</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Dementia1" />
                        <label htmlFor="Dementia1">Baycare</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Depression1" />
                        <label htmlFor="Depression1">Beacon Health Option</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="DrugAbuse1" />
                        <label htmlFor="DrugAbuse1">Beech Street</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="DrugDiagnosis1" />
                        <label htmlFor="DrugDiagnosis1">Beacon Health Systems</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="EatingDisorder1" />
                        <label htmlFor="EatingDisorder1">Blue Cross &amp; Blue Shields</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="GamblingAddiction1" />
                        <label htmlFor="GamblingAddiction1">ComPsych</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="GenderIssues1" />
                        <label htmlFor="GenderIssues1">Coventry</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Grief1" />
                        <label htmlFor="Grief1">E4Health</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="LearningDisorder1" />
                        <label htmlFor="LearningDisorder1">Empire Blue Cross Blue Shield</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Hoarding1" />
                        <label htmlFor="Hoarding1">Florida Blue</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Postpartum1" />
                        <label htmlFor="Postpartum1">GHI</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Parting1" />
                        <label htmlFor="Parting1">Health Advocate</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="perDisorder1" />
                        <label htmlFor="perDisorder1">Humana</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="infertility1" />
                        <label htmlFor="infertility1">Life Works</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sexaddition1" />
                        <label htmlFor="sexaddition1">Megallan</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sleep1" />
                        <label htmlFor="sleep1">Mediaid</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="sex1" />
                        <label htmlFor="sex1">Medicare</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="Stress1" />
                        <label htmlFor="Stress1">MHNet Behaviour Health</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="suic1" />
                        <label htmlFor="suic1">Sunshine Health </label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="obes" />
                        <label htmlFor="obes">TRICARE</label>
                      </div>
                    </div>
                    <div className="col-lg-3 col-12">
                      <div className="filter-checks">
                        <input type="checkbox" id="UMR" />
                        <label htmlFor="UMR">UMR</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-age" aria-expanded="false" aria-controls="faq-collapseOne">
                  Consulation Type
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-age">
                  <ul className="filter-select">
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Infant</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Toddler</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Child(5-8)</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Preteen(9-13)</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Teen(13-18)</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Young Adult(18-25)</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" /> Adult(25-65)</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" /> Elder(65+)</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-div">
                <h6 className="w-100 d-flex align-items-center justify-content-between" data-bs-toggle="collapse" data-bs-target="#modal-faith" aria-expanded="false" aria-controls="faq-collapseOne">
                  Consulation Type
                  <i className="ti ti-chevron-down ms-auto" />
                </h6>
                <div className="collapse show" id="modal-faith">
                  <ul className="filter-select">
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Christian</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Buddhist</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Hindu</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Jewish</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Muslim</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" />Non Religious</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" /> Sikh</a>
                    </li>
                    <li>
                      <a href="#"><i className="ti ti-check me-2" /> Mormon</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="filter-btn">
                <ul>
                  <li>
                    <a href="#" className="btn btn-primary rounded">Apply</a>
                  </li>
                  <li>
                    <a href="#" className="clear-link">Clear Filter</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* Signup Modal */}
  <div className="modal fade signup-modal" id="signup-modal" tabIndex={-1} aria-hidden="true">
    <div className="modal-dialog modal-dialog-centered modal-xl">
      <div className="modal-content">
        <div className="modal-body">
          <div className="signup-info">
            <div className="signup-close">
              <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="signup-left">
              <div className="signup-head">
                <h4>Sign up for Turning Well!</h4>
                <h6>It’s completely FREE to become a member. Sign up now to:</h6>
                <ul className="nav">
                  <li>
                    <i className="fas fa-check" /> access your personal dashboard, calendar, and events
                  </li>
                  <li>
                    <i className="fas fa-check" /> follow your favorite providers
                  </li>
                  <li>
                    <i className="fas fa-check" /> watch and read free content
                  </li>
                  <li>
                    <i className="fas fa-check" /> save and store videos
                  </li>
                  <li>
                    <i className="fas fa-check" /> access live events
                  </li>
                  <li>
                    <i className="fas fa-check" /> subscribe to your favorite providers for a no-ad experience and unlimited content
                  </li>
                </ul>
              </div>
              <div className="view-signup-btn">
                <a href="javascript:void(0);" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#ratings-modal">
                  Sign Up with Phone or Email
                </a>
              </div>
              <div className="login-or">
                <span className="or-line" />
                <span className="span-or">OR</span>
              </div>
              <div className="signup-app-btn">
                <a href="javascript:void(0);" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#ratings-modal">
                  <img src="assets/img/profile-icons/google-icon.svg" alt="Icon" /> Sign in with Google
                </a>
                <a href="javascript:void(0);" className="btn btn-primary w-100" data-bs-toggle="modal" data-bs-target="#ratings-modal">
                  <img src="assets/img/profile-icons/facebook-icon.svg" alt="Icon" /> Sign in with Facebook
                </a>
              </div>
              <div className="signup-already-account">
                <p>Already have an account? 
                  <a href="javascript:void(0);">Login</a>
                </p>
              </div>
            </div>
            <div className="signup-right">
              <div className="signup-img">
                <img src="assets/img/img-03.png" className="img-fluid" alt="Signup" />
              </div>
              <div className="signup-text">
                <h5>Sneak Peek of Your Dashboard</h5>
                <p>Here's a quick look at what you'll find on your dashboard. Connect with providers, track your activities, and access all the essential tools and information you need.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  {/* /Signup Modal */}</>
  )
}

export default SearchTag