import React from "react";
import { facebookIcon, googleIcon, img03 } from "../../../../utils/imagepath";
import { Link } from "react-router-dom";

const Signup = () => {
  return (
    <>
      <div
        className="modal fade signup-modal"
        id="signup-modal"
        tabIndex={-1}
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-xl">
          <div className="modal-content">
            <div className="modal-body">
              <div className="signup-info">
                <div className="signup-close">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div className="signup-left">
                  <div className="signup-head">
                    <h4>Sign up for Turning Well!</h4>
                    <h6>
                      It’s completely FREE to become a member. Sign up now to:
                    </h6>
                    <ul className="nav">
                      <li>
                        <i className="fas fa-check" /> access your personal
                        dashboard, calendar, and events
                      </li>
                      <li>
                        <i className="fas fa-check" /> follow your favorite
                        providers
                      </li>
                      <li>
                        <i className="fas fa-check" /> watch and read free
                        content
                      </li>
                      <li>
                        <i className="fas fa-check" /> save and store videos
                      </li>
                      <li>
                        <i className="fas fa-check" /> access live events
                      </li>
                      <li>
                        <i className="fas fa-check" /> subscribe to your
                        favorite providers for a no-ad experience and unlimited
                        content
                      </li>
                    </ul>
                  </div>
                  <div className="view-signup-btn">
                    <Link
                      to="#"
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#ratings-modal"
                    >
                      Sign Up with Phone or Email
                    </Link>
                  </div>
                  <div className="login-or">
                    <span className="or-line" />
                    <span className="span-or">OR</span>
                  </div>
                  <div className="signup-app-btn">
                    <Link
                      to="#"
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#ratings-modal"
                    >
                      <img
                        src={googleIcon}
                        alt="Icon"
                      />{" "}
                      Sign in with Google
                    </Link>
                    <Link
                      to="#"
                      className="btn btn-primary w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#ratings-modal"
                    >
                      <img
                        src={facebookIcon}
                        alt="Icon"
                      />{" "}
                      Sign in with Facebook
                    </Link>
                  </div>
                  <div className="signup-already-account">
                    <p>
                      Already have an account?
                      <Link to="#">Login</Link>
                    </p>
                  </div>
                </div>
                <div className="signup-right">
                  <div className="signup-img">
                    <img
                      src={img03}
                      className="img-fluid"
                      alt="Signup"
                    />
                  </div>
                  <div className="signup-text">
                    <h5>Sneak Peek of Your Dashboard</h5>
                    <p>
                      Here's a quick look at what you'll find on your dashboard.
                      Connect with providers, track your activities, and access
                      all the essential tools and information you need.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
