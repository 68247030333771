import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { forgotPassword, logo } from "../../utils/imagepath";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { setRegisterData } from "../../core/redux/commonSlice";
import { routes } from "../../utils/routes/routes";
import { getData, postData } from "../../services/api.service";
import { end_points } from "../../services/endpoints";
import { capitalizeWords } from "../../utils/constants/index.";
import getSymbolFromCurrency from "currency-symbol-map";
import { yup } from "../../utils/shared.module";
import ErrorMessage from "../../components/errorMessage";

const formSchema = yup.object().shape({
  selectedPlan: yup.string().required("You must select a plan"),
  promoCode: yup.string().optional(), // Add validation rules if needed
});
const BillingProvider = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<any | null>(null);
  const registerData = useSelector((state: any) => state.common.registerData);

  const [packages, setPackages] = useState([]);
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    trigger,
    getValues,
  } = useForm({
    resolver: yupResolver(formSchema),
  });

  const onSubmit = () => {
    try {
      showLoader();
      const updateData: any = {
        ...registerData,
        selectedPlan: {
          ...registerData.selectedPlan,
          ...selectedPlan,
        },
      };
      dispatch(setRegisterData(updateData));
    } catch (error) {
    } finally {
      hideLoader();
      navigate(routes.cardIndex);
    }
  };

  const getAvailablePackages = async () => {
    try {
      showLoader();
      let url = end_points.getAvailablePackagesApi.url;
      if (url) {
        url += `/subscription`;
        // https://dev-tw-apigateway.dreamstechnologies.com/api/stripe/product/subscription
        // https://dev-tw-apigateway.dreamstechnologies.com/api/stripe/product?plan_category=subscription
      }
      const response = await getData(url);
      if (response.data?.status === 200) {
        setPackages(response.data?.data);
      }
    } catch (error) {
    } finally {
      hideLoader();
    }
  };

  useEffect(() => {
    getAvailablePackages();
    if (registerData?.selectedPlan?.pricing_id) {
      setValue("selectedPlan", registerData?.selectedPlan?.pricing_id);
      setSelectedPlan(registerData?.selectedPlan);
    }
  }, []);
  return (
    <>
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap">
              <div className="login-header">
                <img src={logo} className="logo-blk" alt="" />
              </div>
              <div className="login-body pt-4">
                <div className="login-title">
                  <h4>Billing Information</h4>
                  <p>
                    Include just one credential here so we can verify you.
                    Later, you will be able to add more.
                  </p>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  {packages?.length > 0 &&
                    packages.map((item: any) => {
                      return (
                        <>
                          <div className="form-set">
                            <div className="account-plan-blk text-center">
                              <div className="flex-plan-info align-between">
                                <div className="left-info text-start">
                                  <h5>{capitalizeWords(item.name)}</h5>
                                  <p>{item?.product_description}</p>
                                </div>
                                <div
                                  className={`right-info ${item?.most_popuplar ? "text-end" : ""}`}
                                >
                                  {item?.most_popuplar && (
                                    <span className="plan-badge">
                                      Most Popular
                                    </span>
                                  )}
                                  <h3>
                                    {getSymbolFromCurrency(
                                      item?.currency?.toUpperCase()
                                    )}
                                    {item?.amount || 0}
                                    <span>/{item?.recurring_interval}</span>
                                  </h3>
                                </div>
                              </div>
                              <Link
                                to="#"
                                className={`btn btn-outline-primary ${selectedPlan?.pricing_id === item?.pricing_id ? "selected" : ""}`}
                                onClick={() => {
                                  setSelectedPlan(item);
                                  setValue("selectedPlan", item?.pricing_id);
                                  trigger("selectedPlan");
                                }}
                              >
                                Select your Plan
                              </Link>
                              <Link to="#" className="link-style">
                                To discover the benefits you'll receive, please
                                click the link.
                              </Link>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  <ErrorMessage value={errors.selectedPlan} />

                  {/* <div className="form-set">
                    <div className="account-plan-blk text-center">
                      <div className="flex-plan-info align-between">
                        <div className="left-info text-start">
                          <h5>Enhanced Account</h5>
                          <p>Everything you needed</p>
                        </div>
                        <div className="right-info text-end">
                          <span className="plan-badge">Most Popular</span>
                          <h3>
                            $29.95<span>/month</span>
                          </h3>
                        </div>
                      </div>
                      <Link to="#" className="btn btn-outline-primary">
                        Select your Plan
                      </Link>
                      <Link to="#" className="link-style">
                        To discover the benefits you'll receive, please click
                        the link.
                      </Link>
                    </div>
                  </div> */}
                  <div className="form-set mb-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control border-0"
                        placeholder="Promo Code"
                      />
                      <button className="btn-coupon" type="button">
                        Apply
                      </button>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Next
                  </button>
                </form>
                {/* /Login Form */}
              </div>
              <div className="progress-blk five-steps align-center mt-3">
                <ul className="list-unstyled">
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="active">
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={forgotPassword} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default BillingProvider;
