import React from "react";

interface ErrorMessageProps {
  value?: { message?: string } | { value?: { message?: string } } | any;
  className?: string;
}

const ErrorMessage: React.FC<ErrorMessageProps> = ({ value, className }) => {
  return value?.message ? (
    <p className={`text-danger mt-2 d-block ${className}`}>{value?.message}</p>
  ) : value?.value?.message ? (
    <p className={`text-danger mt-2 d-block ${className}`}>
      {value?.value?.message}
    </p>
  ) : null;
};

export default ErrorMessage;
