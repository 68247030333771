import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  cirtificationNameSchema,
  cirtificationSpecialitySchema,
  yearObtainedSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { getData, postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import {
  onlyAlphabet,
  textLength,
} from "../../../../utils/patterns/regex.pattern";
import { useEffect, useState, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";

interface Props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
}

const BoardCirtificationModal: React.FC<Props> = (props) => {
  const boardModalRef = useRef();
  const { type, open, data, edit_type } = props.visible;
  const { getDirectoryData } = props;
  const Data = data;
  const userDetails = useSelector((state: any) => state.common.userDetails);

  const initialValues = {
    cirtification_name: "",
    speciality: "",
    year_obtained: "",
  };

  const boardCirtificationSchema = Yup.object().shape({
    cirtification_name: cirtificationNameSchema,
    speciality: cirtificationSpecialitySchema,
    year_obtained: yearObtainedSchema,
  });

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onBlur",
    resolver: yupResolver(boardCirtificationSchema),
    defaultValues: initialValues,
  });

  const OnClose = () => {
    reset();
    // boardModalRef.current.click();
    props.handleClose;
  };

  const handleFormSubmit = async (data: any) => {
    if (edit_type == "add") {
      try {
        showLoader();
        const payload = {
          broad_certificate: [
            {
              certificate_name: data?.cirtification_name,
              specialty: data?.speciality,
              year_obtained: data?.year_obtained,
            },
          ],
        };
        const response = await postData(
          `${end_points.userprofile_board_cirtification.url}`,
          payload
        );
        if (response.status === 201) {
          getDirectoryData();
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    } else {
      try {
        showLoader();
        const payload = {
          broad_certificate: {
            certificate_name: data?.cirtification_name,
            specialty: data?.speciality,
            year_obtained: data?.year_obtained,
          },
        };
        const response = await putData(
          `${end_points.userprofile_board_cirtification_update.url}/${Data?._id}`,
          payload
        );
        if (response.status === 201) {
          getDirectoryData();
          OnClose();
        }
      } catch (error) {
        console.error(error);
      } finally {
        hideLoader();
      }
    }
  };

  useMemo(() => {
    if (edit_type === "edit" && Data) {
      setValue("cirtification_name", Data?.certificate_name);
      setValue("speciality", Data?.specialty);
      setValue("year_obtained", Data?.year_obtained);
    }
  }, [edit_type, Data, setValue]);

  return (
    <div
      className="modal fade credential-modal finances-modal"
      id="additional-modal"
      tabIndex={-1}
      aria-hidden="true"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
    >
      <div className="modal-dialog modal-dialog-centered modal-md">
        <div className="modal-content">
          <div className="modal-header">
            <div className="modal-sub-head">
              <h5 className="modal-title">Board Certifications</h5>
            </div>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              ref={boardModalRef}
            />
          </div>

          <div className="modal-body">
            <form action="#" onSubmit={handleSubmit(handleFormSubmit)}>
              <div className="form-wrap">
                <label>Certification Name</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Orthopedic Surgery Residency"
                  {...register("cirtification_name")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!onlyAlphabet.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                />
                <ErrorMessage value={errors.cirtification_name} />
              </div>
              <div className="form-wrap">
                <label>Specialty</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="State University Medical Center"
                  {...register("speciality")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!onlyAlphabet.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                  maxLength={textLength}
                />
                <ErrorMessage value={errors.speciality} />
              </div>
              <div className="form-wrap">
                <label>Year Obtained</label>
                <input
                  type="text"
                  className="form-control"
                  placeholder={"2014"}
                  {...register("year_obtained")}
                  onKeyDown={(e) => {
                    const key = e.key;
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      " ",
                    ];
                    if (!/^\d$/.test(key) && !allowedKeys.includes(key)) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    const value = e.target.value;
                    const year = parseInt(value, 10);
                    const currentYear = new Date().getFullYear();

                    if (!isNaN(year) && year > currentYear) {
                      e.target.value = currentYear.toString();
                    }
                  }}
                  maxLength={4}
                />
                <ErrorMessage value={errors.year_obtained} />
              </div>
              <div className="form-wrap mb-0">
                <button className="btn btn-primary w-100" type="submit">
                  {Data !== undefined && Object.keys(Data).length
                    ? "Update Now"
                    : "Add Now"}
                </button>
                <button
                  className="btn btn-cancel w-100"
                  type="button"
                  onClick={OnClose}
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BoardCirtificationModal;
