import React, { useState } from "react";
import { Dialog } from "primereact/dialog";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../utils/routes/routes";

export default function AuthTypeModel({
  visible = false,
  onCancel = () => {},
  title = "",
}) {
  const [selectedRole, setSelectedRole] = useState("provider");

  const handleRoleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedRole(event.target.value);
  };
  const navigate = useNavigate();
  const handleContinue = () => {
    if (selectedRole) {
      if (selectedRole === "provider") {
        navigate(routes.registerProvider);
      }
    }
  };

  return (
    <div className="card appropriate-card flex justify-content-center">
      <Dialog
        visible={visible}
        style={{ width: "50vw" }}
        closable={false}
        header={
          <div className="appropriate-card-head">
            <h2>
              Kindly continue to create an account. Select the role that is
              appropriate for you.
            </h2>
          </div>
        }
      >
        <div className="appropriate-card-info">
          <div className="appropriate-card-radio custom-radio">
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="radio1"
                name="role"
                value="provider"
                checked={selectedRole === "provider"}
                onChange={handleRoleChange}
              />
              <label className="form-check-label" for="radio1">
                Provider
              </label>
            </div>
            <div className="form-check">
              <input
                type="radio"
                className="form-check-input"
                id="radio2"
                name="role"
                value="facility"
                checked={selectedRole === "facility"}
                onChange={handleRoleChange}
              />
              <label className="form-check-label" for="radio2">
                Facility
              </label>
            </div>
          </div>

          {/* Spacer to push button to the bottom */}
          <div className="mt-auto d-flex justify-content-center appropriate-btn">
            <button className="btn btn-primary" onClick={handleContinue}>
              Continue
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
