import React from "react";

import { Link } from "react-router-dom";
import { time, wifi } from "../../utils/imagepath";
import { routes } from "../../utils/routes/routes";

const GoLive = () => {
  return (
    <>
      {" "}
      {/* Page Wrapper */}
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="breadcrumb-blk">
                <div className="d-flex flex-wrap align-items-start">
                  <ul className="flex-style mb-3">
                    <li>
                      <Link to="#">Videos</Link>
                    </li>
                    <li>Go Live</li>
                  </ul>
                </div>
                <p>
                  Start your live session now and engage with your audience in
                  real-time.
                </p>
              </div>
              <div className="row flex-body d-flex row-gap-30 ">
                <div className="col-md-6 col-12">
                  <div className="custom-card mb-md-0">
                    <div className="go-live-set">
                      <h6>
                        <img
                          src={wifi}
                          className="me-2"
                          alt=""
                        />
                        Go Live Now
                      </h6>
                      <p>
                        Connect with your audience in real-time and share
                        memorable moments effortlessly.
                      </p>
                      <Link
                        to={routes.goLiveNow}
                        className="btn btn-primary rounded"
                      >
                        Start Streaming
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="custom-card mb-md-0">
                    <div className="go-live-set">
                      <h6>
                        <img
                          src={time}
                          className="me-2"
                          alt=""
                        />
                        Schedule Stream
                      </h6>
                      <p>
                        Prepare for your next broadcast by scheduling it in
                        advance with Turningwell.
                      </p>
                      <Link
                        to={routes.scheduleStream}
                        className="btn btn-primary rounded"
                      >
                        Schedule Stream
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      {/* /Page Wrapper */}
    </>
  );
};

export default GoLive;
