import React, { useEffect, useState } from "react";
import { locationProvider, logo } from "../../utils/imagepath";
import { Dropdown } from "primereact/dropdown";
import { postData, yup, yupResolver } from "../../utils/shared.module";
import { Controller, useForm } from "react-hook-form";
import { hideLoader, showLoader } from "../../components/spinner/spinner";
import { end_points } from "../../services/endpoints";
import ErrorMessage from "../../components/errorMessage";
import {
  addressMaxLength,
  nameMaxLength,
  onlyAlphabet,
  onlyNumber,
} from "../../utils/patterns/regex.pattern";
import {
  addressSchema,
  companyNameSchema,
  credentialsSchema,
  extensionSchema,
  firstNameSchema,
  lastNameSchema,
  phoneNumberSchema,
  titleSchema,
} from "./components/yupSchema";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes/routes";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setRegisterData } from "../../core/redux/commonSlice";
import PhoneInputWithRef from "../../utils/phoneInputWithRef";
import { clinicalRoleTypeOption } from "../../utils/constants/index.";

const formSchema = yup.object({
  // title: titleSchema,
  firstName: firstNameSchema,
  lastName: lastNameSchema,
  credentials: credentialsSchema,
  // companyName: companyNameSchema,
  addressName: addressSchema,
  // extension: extensionSchema,
  phoneNumber: phoneNumberSchema,
});
const formSchemaCompany = yup.object({
  companyName: companyNameSchema,
  // addressName: addressSchema,
  // extension: extensionSchema,
  // phoneNumber: phoneNumberSchema,
});
const LocationProvider = () => {
  const dispatch = useDispatch();
  const registerData = useSelector((state: any) => state.common.registerData);
  const getSelectedCountry = () => {
    return registerData?.provider?.country
      ? registerData?.provider?.country?.toLowerCase()
      : "in";
  };
  const [selectedOption1, setSelectedOption1] = useState(null);
  const [reuseCompanyName, setReuseCompanyName] = useState(false);
  const getSchema = (valid: any) => {
    let schema;
    if (valid) {
      schema = formSchema.concat(formSchemaCompany);
    } else {
      schema = formSchema;
    }
    return schema;
  };
  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted, isValid },
    trigger,
    getValues,
    clearErrors,
  } = useForm({
    resolver: yupResolver(getSchema(reuseCompanyName)),
  });
  const navigate = useNavigate();
  const formInvalid = isSubmitted && !isValid;

  const onSubmit = async (data: any) => {
    try {
      showLoader();
      const updateData: any = {
        ...registerData,
        locationProvider: {
          ...registerData.locationProvider,
          ...data,
          reuseCompanyName: reuseCompanyName,
        },
      };
      dispatch(setRegisterData(updateData));
    } catch (error) {
    } finally {
      hideLoader();
      navigate(routes.credentialsProvider);
    }
  };
  useEffect(() => {
    if (registerData?.locationProvider) {
      const {
        title,
        firstName,
        lastName,
        credentials,
        companyName,
        addressName,
        extension,
        phoneNumber,
        reuseCompanyName,
      } = registerData.locationProvider;
      // setValue("title", title);
      setValue("firstName", firstName);
      setValue("lastName", lastName);
      setValue("credentials", credentials);
      if (reuseCompanyName) {
        setValue("companyName", companyName);
        setValue("addressName", addressName);
        setValue("extension", extension);
        trigger(["companyName", "addressName", "extension"]);
      }
      setValue("phoneNumber", phoneNumber);
      trigger([
        // "title",
        "firstName",
        "lastName",
        "credentials",
        // "extension",
        "phoneNumber",
      ]);
      setReuseCompanyName(reuseCompanyName);
    }
  }, [registerData?.locationProvider]);

  return (
    <>
      {" "}
      {/* Login Section */}
      <div className="login-content w-50">
        <div className="row">
          <div className="col-md-12 mx-auto">
            <div className="login-wrap">
              <div className="login-body pt-0">
                <div className="login-header pb-4">
                  <img src={logo} className="logo-blk" alt="" />
                </div>
                <div className="login-title">
                  <h4>Name &amp; Location</h4>
                </div>
                {/* Login Form */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-6 d-none">
                      <div className="form-set">
                        <label>Title</label>
                        <Controller
                          name="title"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={[
                                { label: "Mr.", value: "Mr." },
                                { label: "Ms.", value: "Ms." },
                                { label: "Mrs.", value: "Mrs." },
                                { label: "Miss.", value: "Miss." },
                              ]}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("title");
                              }}
                              placeholder="Select one"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.title} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-set">
                        <label>First Name</label>
                        <Controller
                          name="firstName"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your firstname"
                              {...field}
                              onKeyDown={(event) => {
                                const regex = onlyAlphabet;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e: any) => {
                                field.onChange(e);
                                trigger("firstName");
                              }}
                              maxLength={nameMaxLength}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.firstName} />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-set">
                        <label>Last Name</label>
                        <Controller
                          name="lastName"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your lastname"
                              {...field}
                              onKeyDown={(event) => {
                                const regex = onlyAlphabet;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              onChange={(e: any) => {
                                field.onChange(e);
                                trigger("lastName");
                              }}
                              maxLength={nameMaxLength}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.lastName} />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      {/* <div className="form-set">
                        <label>Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your phone"
                        />
                      </div> */}
                      <div
                        className={`form-set ${formInvalid ? "is-invalid" : ""}`}
                      >
                        <label>Phone</label>
                        <Controller
                          name="phoneNumber"
                          control={control}
                          render={({ field, fieldState }) => (
                            <div>
                              <PhoneInputWithRef
                                {...field}
                                placeholder="Enter your phone number*"
                                country={getSelectedCountry()}
                                value={field.value}
                                onChange={(event: any) => {
                                  field.onChange(event);
                                  trigger("phoneNumber");
                                }}
                                inputProps={{
                                  name: "phoneNumber",
                                  required: true,
                                  className: `form-control ${fieldState.invalid ? "is-invalid" : ""}`,
                                }}
                              />
                              {fieldState.invalid && (
                                <p className="text-danger d-inline-flex mt-2">
                                  {errors.phoneNumber?.message}
                                </p>
                              )}
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-12">
                      <div className="form-set">
                        <label>Credentials</label>
                        <Controller
                          name="credentials"
                          control={control}
                          render={({ field }) => (
                            <Dropdown
                              value={field.value}
                              options={clinicalRoleTypeOption}
                              onChange={(e) => {
                                field.onChange(e.value);
                                trigger("credentials"); // Trigger validation onChange
                              }}
                              placeholder="Select one"
                              className="select w-100"
                            />
                          )}
                        />
                        <ErrorMessage value={errors.credentials} />
                      </div>
                    </div>
                    <div className="col-md-6 d-none">
                      <div className="form-set">
                        <label>Extension</label>
                        <Controller
                          name="extension"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Extension here"
                              {...field}
                              onKeyDown={(event) => {
                                const regex = onlyNumber;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={10}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.extension} />
                      </div>
                    </div>
                  </div>
                  <div className="form-set">
                    <div className="btm-blk mt-2">
                      <label className="custom_check custom_check_info">
                        <input
                          type="checkbox"
                          checked={reuseCompanyName}
                          onChange={() => {
                            setReuseCompanyName((prev) => !prev);
                            if (reuseCompanyName) {
                              setValue("companyName", "");
                              trigger(["companyName"]);
                            } else {
                              clearErrors(["companyName"]);
                            }
                          }}
                        />
                        <span className="checkmark mt-1" />
                        Use a company name instead of my real name for the
                        listing title
                      </label>
                    </div>
                  </div>
                  <div id="check-select">
                    {reuseCompanyName && (
                      <div className="form-set">
                        <label>Company Name</label>
                        <Controller
                          name="companyName"
                          control={control}
                          render={({ field }) => (
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter your company name"
                              {...field}
                              onKeyDown={(event) => {
                                const regex = onlyAlphabet;
                                if (
                                  !regex.test(event.key) &&
                                  event.key !== "Backspace"
                                ) {
                                  event.preventDefault();
                                }
                              }}
                              maxLength={nameMaxLength}
                            />
                          )}
                        />
                        <ErrorMessage value={errors.companyName} />
                      </div>
                    )}

                    <div className="form-set highlight-blk">
                      <h4>Primary Location</h4>
                      <div className="card-box">
                        <div className="form-set mb-0">
                          <label>Address Lookup</label>
                          <Controller
                            name="addressName"
                            control={control}
                            render={({ field }) => (
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter your company address"
                                {...field}
                                maxLength={addressMaxLength}
                              />
                            )}
                          />
                          <ErrorMessage value={errors.addressName} />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="btn btn-primary w-100 login-btn"
                    type="submit"
                  >
                    Next
                  </button>
                </form>
                {/* /Login Form */}
              </div>
              <div className="progress-blk five-steps align-center mt-3">
                <ul className="list-unstyled">
                  <li className="completed">
                    <i className="ti ti-check" />
                  </li>
                  <li className="active">
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                  <li>
                    <i className="ti ti-circle-filled" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Login Section */}
      {/* Login image */}
      <div className="login-img-wrapper">
        <div className="login-img">
          <img src={locationProvider} alt="" />
        </div>
      </div>
      {/* /Login Section */}
    </>
  );
};

export default LocationProvider;
