import React from "react";
import {
  br,
  es,
  flag01,
  fr,
  it,
  logo,
  logoWhite,
  menu01,
  menu02,
  menu03,
  menu04,
  menu05,
  menu06,
  menu07,
  menu08,
  menu09,
  menuArrow,
  menuImg,
  no,
} from "../../utils/imagepath";
import { Link } from "react-router-dom";
import { routes } from "../../utils/routes/routes";

const FrontendHeader = () => {
  return (
    <>
      {" "}
      {/* Header */}
      <header className="header">
        <div className="offcanvas-info">
          <div className="offcanvas-wrap">
            <div className="offcanvas-detail">
              <div className="offcanvas-head d-flex justify-content-between align-items-center mb-3">
                <Link to={routes.frontendIndex}>
                  <img src={logoWhite} className="mobile-logo" alt="logo-img" />
                </Link>
                <div className="offcanvas-close">
                  <i className="ti ti-x" />
                </div>
              </div>
              <div className="mobile-menu fix mb-3" />
              <div className="offcanvas__contact">
                <div className="mt-4 d-none">
                  <Link to="#" className="btn  w-100 mb-2">
                    <i className="ti ti-lock me-2" />
                    Sign In
                  </Link>
                  <Link to="#" className="btn btn-linear-primary w-100">
                    <i className="ti ti-user-filled me-2" />
                    Join Us
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="offcanvas-overlay" />
        <div className="header-nav">
          <div className="main-menu-wrapper">
            <div className="navbar-logo">
              <Link className="header-logo" to={routes.frontendIndex}>
                <img src={logo} className="logo" alt="Logo" />
              </Link>
            </div>
            <nav id="mobile-menu">
              <ul className="main-nav">
                <li>
                  <Link to={routes.frontendIndex}>Home</Link>
                </li>
                <li className="has-submenu megamenu-main">
                  <Link to="#">
                    Discover <i className="ti ti-chevron-down" />
                  </Link>
                  <ul className="submenu megamenu-full-width">
                    <li>
                      <div className="megamenu-info">
                        <div className="megamenu-flex">
                          <div className="megamenu-left">
                            <div className="row">
                              <div className="col-md-4">
                                <div className="menu-info">
                                  <h4>Directory</h4>
                                  <p>
                                    <Link to="#">
                                      Search by Location
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Providers
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Categories
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Reviewed
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="menu-info">
                                  <h4>Video</h4>
                                  <p>
                                    <Link to="#">
                                      Top Viewed Videos
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Liked Videos
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Providers
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Top Reviewed
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="menu-info">
                                  <h4>Featured Worldwide Feed</h4>
                                  <p>
                                    <Link to="#">
                                      Today's Schedule
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Upcoming Schedule
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      TW Stream
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="megamenu-right">
                            <div className="menu-right">
                              <div className="menu-info">
                                <h4>Search by In-Network Providers</h4>
                                <div className="menu-icons-info">
                                  <div className="menu-icons">
                                    <img src={menu01} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu02} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu03} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu04} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu05} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu06} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu07} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu08} alt="Icon" />
                                  </div>
                                  <div className="menu-icons">
                                    <img src={menu09} alt="Icon" />
                                  </div>
                                  <div></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu megamenu-main">
                  <Link to="#">
                    Find Help <i className="ti ti-chevron-down" />
                  </Link>
                  <ul className="submenu megamenu-full-width">
                    <li>
                      <div className="megamenu-info">
                        <div className="megamenu-flex">
                          <div className="megamenu-left">
                            <h2>Directory</h2>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="menu-info menu-help">
                                  <p>
                                    <Link to="#">
                                      Psychopharmacology Treatment
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Psychotherapy
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Nutrition
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Spirituality
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Mindfulness, Meditation, &amp; Sleep
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Fitness &amp; Exercise
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="menu-info">
                                  <p>
                                    <Link to="#">
                                      Preventative &amp; Primary Care Medicine
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Integrative, Alternative, &amp;
                                      Complementary Medicine
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Spa &amp; Wellness
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Lifestyle Management
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Relationships
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                  <p>
                                    <Link to="#">
                                      Addictive &amp; Ineffective Behaviors
                                      <i className="ti ti-arrow-right" />
                                    </Link>
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="menu-sub">
                              <h5>Sub-Categories</h5>
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="menu-info">
                                    <p>
                                      <Link to="#">
                                        Provider Directory
                                        <i className="ti ti-arrow-right" />
                                      </Link>
                                    </p>
                                    <p>
                                      <Link to="#">
                                        Live Events
                                        <i className="ti ti-arrow-right" />
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="menu-info">
                                    <p>
                                      <Link to="#">
                                        Video Content
                                        <i className="ti ti-arrow-right" />
                                      </Link>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="megamenu-right">
                            <div className="menu-right">
                              <div className="menu-img">
                                <img
                                  src={menuImg}
                                  className="img-fluid"
                                  alt="Menu"
                                />
                              </div>
                              <div className="menu-content">
                                <h6>
                                  Do not neglect the idea of Factual Knowledge
                                  in the Works done Perspective..
                                </h6>
                                <Link to="#">
                                  Read More <img src={menuArrow} alt="Icon" />
                                </Link>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu">
                  <Link to="#">
                    Events <i className="ti ti-chevron-down" />
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="#">Events 01</Link>
                    </li>
                    <li>
                      <Link to="#">Events 02</Link>
                    </li>
                  </ul>
                </li>
                <li className="has-submenu">
                  <Link to="#">
                    Resources <i className="ti ti-chevron-down" />
                  </Link>
                  <ul className="submenu">
                    <li>
                      <Link to="#">Resources 01</Link>
                    </li>
                    <li>
                      <Link to="#">Resources 02</Link>
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <div className="header-btn">
              <div className="flag-dropdown">
                <div className="dropdown">
                  <Link
                    to="#"
                    data-bs-toggle="dropdown"
                    aria-expanded="true"
                    className="show"
                  >
                    <div className="booking-user ms-0">
                      <span className="user-img d-flex align-items-center">
                        <img src={flag01} alt="Flag" />
                        <i className="ti ti-chevron-down ms-1" />
                      </span>
                    </div>
                  </Link>
                  <ul className="dropdown-menu p-2">
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={flag01} className="me-2" alt="Flag" />
                        English
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={es} className="me-2" alt="Flag" />
                        Spanish
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={br} className="me-2" alt="Flag" />
                        Brazilian portuguese
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={fr} className="me-2" alt="Flag" />
                        French
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={it} className="me-2" alt="Flag" />
                        Italian
                      </Link>
                    </li>
                    <li>
                      <Link
                        className="dropdown-item d-flex align-items-center"
                        to="#"
                      >
                        <img src={no} className="me-2" alt="Flag" />
                        Norweigan
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="searchbars">
                <Link to="#">
                  <i className="ti ti-search" />
                </Link>
              </div>
              <div className="signin">
                <Link to="#">Sign In</Link>
                <Link to="#" className="btn btn-primary rounded text-white">
                  Join
                </Link>
              </div>
              <div className="header__hamburger d-lg-none my-auto">
                <div className="sidebar-menu">
                  <i className="ti ti-menu-2" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {/* /Header */}
    </>
  );
};

export default FrontendHeader;
