import parsePhoneNumberFromString from "libphonenumber-js";

import moment from "moment";

export const alphaNumeric = /^[a-zA-Z0-9 ]+$/;
export const onlyAlphabet = /^[A-Za-z\s]+$/;
export const onlyAlphabetwithoutSpace = /^[A-Za-z-_.]+$/;
export const onlyNumber = /^[0-9]*$/;
export const userNameRegex = /^[a-zA-Z0-9@. ]+$/;
export const dateTextSlashRegex =
  /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/;
export const monthYearSlashRegex = /^(0[1-9]|1[0-2])\/\d{4}$/;
export const stringAllowedKeys = ["Backspace", "Arrowleft", "Arrowright", " "];

export const email =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/;
export const passwordPattern =
  /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~`!@#$%^&*()\-_=+\{\}\[\]|\\;:"<>,./?]).{5,10}$/;
export const licneseNumberPattern = /^(?!-)[A-Za-z0-9-]{1,12}(?<!-)$/;
export const onlyAplhaNumHyp = /^[A-Za-z0-9-]{1,10}$/;
export const pricePattern = /^\d*\.?\d{0,2}$/;

// global
export const characterLength_50 = 50;

export const addressMaxLength = 100;
export const nameMaxLength = 30;
export const userNameMinLength = 5;
export const firstNameMinLength = 3;
export const maxLength_3 = 3;

export const emailMaxLength = 96;
export const passwordMinLength = 5;
export const passwordMaxLength = 10;
export const pincodeMaxLength = 6;
export const aadharMaxLength = 12;
export const registerNoMaxLength = 10;
export const chiefComplaintLength = 200;
export const kioskCodeMaxLength = 10;
export const minNpiNumber = 10;
export const maxNpiNumber = 10;
export const personalInformationMaxLength = 255;
export const licenseMaxLength = 12;
export const yearMaxLength = 4;
export const textLength = 25;
export const sessionMaxLength = 6;
export const maxLength_96 = 96;
export const ageMaxLength = 3;

// message
export const validMessage = {
  onlyAlphabet: "Only alphabets and spaces are allowed",
  nameMaxLength: "Maximum characters reached",
  email: "Please enter your valid email address",
};

export const validatePhoneNumber = (value: string) => {
  if (!value.startsWith("+")) {
    value = `+${value}`;
  }
  const phoneNumber = parsePhoneNumberFromString(value);
  return phoneNumber ? phoneNumber.isValid() : false;
};

export const monthOptions = [
  { label: "January", value: "1" },
  { label: "February", value: "2" },
  { label: "March", value: "3" },
  { label: "April", value: "4" },
  { label: "May", value: "5" },
  { label: "June", value: "6" },
  { label: "July", value: "7" },
  { label: "August", value: "8" },
  { label: "September", value: "9" },
  { label: "October", value: "10" },
  { label: "November", value: "11" },
  { label: "December", value: "12" },
];

const currentMonth = moment().month();
export const filteredMonthOptions = (selectedYear: number) => {
  if (selectedYear === currentYear) {
    return monthOptions.slice(currentMonth);
  }
  return monthOptions;
};
export const currentYear = moment().year();
export const yearOptions = Array.from({ length: 25 }, (v, i) => {
  const year = currentYear + i;
  return { label: year.toString(), value: year.toString() };
});

export const validImageTypes = [
  "image/png",
  "image/jpeg",
  "image/svg",
  "image/gif",
  "image/jpg",
];
export const validProfileImageTypes: string[] = [
  "image/png",
  "image/jpeg",
  "image/jpg",
];

export const acceptProfileTypes = validProfileImageTypes.join(",");
