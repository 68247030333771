import { showLoader, hideLoader } from "../../../../components/spinner/spinner";
import { useEffect, useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  textLength,
  yearMaxLength,
} from "../../../../utils/patterns/regex.pattern";
import {
  degreeSchema,
  educationSchema,
  primaryLocationSchema,
  secondaryLocationSchema,
  yearBeganPractiseSchema,
  yearGraduatedSchema,
} from "../../../authentication/components/yupSchema";
import ErrorMessage from "../../../../components/errorMessage";
import { getData, postData, putData } from "../../../../services/api.service";
import { end_points } from "../../../../services/endpoints";
import { useSelector } from "react-redux";
import { Dialog } from "primereact/dialog";
import Autocomplete from "react-google-autocomplete";

interface props {
  visible: {
    type: string;
    open: boolean;
    data?: object;
    userDetails?: any;
    edit_type?: string;
  };
  handleClose: () => void;
  getDirectoryData: () => void;
  directoryData: any;
}

const LocationModal = (props: props) => {
  const locationModalRef = useRef<any>();
  const { type, open, data, edit_type } = props.visible;
  const { getDirectoryData, directoryData } = props;
  const userDetails = useSelector((state: any) => state.common.userDetails);
  const [primaryAddress, setPrimaryAddress] = useState("");
  const [secondaryAddress, setSecondaryAddress] = useState("");

  const initialValues = {
    primary_location: {
      address: "",
      latitude: "",
      longitude: "",
      street_address: "",
      address_line_two: "",
      city: "",
      state: "",
      zip: "",
    },
    secondary_location: {
      address: "",
      latitude: "",
      longitude: "",
      street_address: "",
      address_line_two: "",
      city: "",
      state: "",
      zip: "",
    },
  };

  const educationModelSchema = Yup.object().shape({
    primary_location: primaryLocationSchema,
    secondary_location: secondaryLocationSchema,
  });

  const {
    register,
    formState: { errors },
    control,
    handleSubmit,
    reset,
    getValues,
    setValue,
    trigger,
  } = useForm({
    resolver: yupResolver(educationModelSchema),
    defaultValues: initialValues,
  });
  const OnClose = () => {
    if (directoryData?.location !== undefined) {
      reset({
        primary_location: directoryData?.location?.primary_location,
        secondary_location: directoryData?.location?.secondary_location,
      });
      setPrimaryAddress(directoryData?.location?.primary_location?.address);
      setSecondaryAddress(
        directoryData?.location?.secondary_location?.address !== undefined
          ? directoryData?.location?.secondary_location?.address
          : ""
      );
    } else {
      reset(initialValues);
      setPrimaryAddress("");
      setSecondaryAddress("");
    }
    locationModalRef.current.click();
    props.handleClose();
  };

  const handleFormSubmit = async (data: any) => {
    try {
      showLoader();
      const payload = {
        location: data,
      };

      const response = await postData(
        `${end_points.profile_about_location.url}`,
        payload
      );
      if (response.status == 201) {
        getDirectoryData();
        OnClose();
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoader();
    }
  };

  const handlePlaceSelected = (place: any, type: string) => {
    const lat = place.geometry.location.lat();
    const lng = place.geometry.location.lng();
    let street_address = "";
    let address_line_two = "";
    let city = "";
    let state = "";
    let zip = "";
    console.log("PLACE", place, lat, lng)


    place.address_components.forEach((component: any) => {
      const componentType = component.types[0];
      if (
        ["street_address", "route", "political", "neighborhood"]?.includes(
          componentType
        )
      ) {
        street_address = component.long_name;
      } else if (
        ["sublocality_level_2", "sublocality_level_1", "sublocality"]?.includes(
          componentType
        )
      ) {
        address_line_two = component.long_name;
      } else if (componentType === "locality") {
        city = component.long_name;
      } else if (componentType === "administrative_area_level_1") {
        state = component.long_name; // Use short_name for state abbreviation
      } else if (componentType === "postal_code") {
        zip = component.long_name;
      }
    });
    let addressObj = {
      address: place?.formatted_address,
      latitude: lat,
      longitude: lng,
      street_address: street_address,
      address_line_two: address_line_two,
      city: city,
      state: state,
      zip: zip !== "" && zip !== undefined ? Number(zip) : "",
    };

    // Update state with address details
    if (type === "primary") {
      reset({
        primary_location: addressObj,
        secondary_location: getValues("secondary_location"),
      });
      setPrimaryAddress(place.formatted_address);
    } else {
      reset({
        secondary_location: addressObj,
        primary_location: getValues("primary_location"),
      });
      setSecondaryAddress(place?.formatted_address);
    }
  };

  useEffect(() => {
    if (directoryData !== undefined) {
      if (directoryData?.location !== undefined) {
        reset({
          primary_location: directoryData?.location?.primary_location,
          secondary_location: directoryData?.location?.secondary_location,
        });
        setPrimaryAddress(directoryData?.location?.primary_location?.address);
        setSecondaryAddress(
          directoryData?.location?.secondary_location?.address
        );
      }
    }
  }, [directoryData]);

  return (
    <>
      <div
        className="modal fade phone-modal finances-modal"
        id="location-modal"
        tabIndex={-1}
        aria-hidden="true"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
      >
        <div className="modal-dialog modal-dialog-centered modal-md">
          <div className="modal-content">
            <div className="modal-header add-modal-header">
              <div className="modal-sub-head">
                <h5 className="modal-title">Location</h5>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                ref={locationModalRef}
                onClick={OnClose}
              />
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(handleFormSubmit)}>
                <div className="form-wrap location-form-wrap">
                  <label>Primary Location</label>

                  <Controller
                    name="primary_location.address"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
                        className="form-control"
                        onPlaceSelected={(data: any) =>
                          handlePlaceSelected(data, "primary")
                        }
                        placeholder=""
                        options={{
                          types: ["address"], // You can restrict the type of places returned
                        }}
                        value={primaryAddress}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            reset({
                              secondary_location:
                                getValues("secondary_location"),
                              primary_location: initialValues?.primary_location,
                            });
                          }
                          setPrimaryAddress(e.target.value);
                        }}
                      />
                    )}
                  />
                  <ErrorMessage value={errors.primary_location?.address} />
                </div>
                <div className="form-wrap location-form-wrap">
                  <label>Secondary Location</label>
                  <Controller
                    name="secondary_location.address"
                    control={control}
                    render={({ field }) => (
                      <Autocomplete
                        apiKey={REACT_APP_GOOGLE_MAPS_API_KEY}
                        className="form-control"
                        onPlaceSelected={(data: any) =>
                          handlePlaceSelected(data, "secondary")
                        }
                        placeholder=""
                        options={{
                          types: ['address'], // You can restrict the type of places returned
                        }}
                        value={secondaryAddress}
                        onChange={(e) => {
                          if (e.target.value === "") {
                            reset({
                              secondary_location:
                                initialValues?.secondary_location,
                              primary_location: getValues("primary_location"),
                            });
                          }
                          setSecondaryAddress(e.target.value);
                        }}
                      />
                    )}
                  />
                  {/** TO GET REGION,CITY,ZIPCODE */}
                  {/** ['(regions)'] */}
                  <ErrorMessage value={errors.secondary_location?.address} />
                </div>
                <div className="form-wrap mb-0">
                  <button className="btn btn-primary w-100" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LocationModal;
